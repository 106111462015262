var render = function render(){var _vm=this,_c=_vm._self._c;return _c('WLateralSectionLayout',{staticClass:"pl-3 nps-page",scopedSlots:_vm._u([{key:"lateral-section",fn:function(){return [_c('NpsScoreWidget',{attrs:{"campaign":_vm.campaign,"subtitle":_vm.widgetSubtitle,"cols":12}}),_vm._v(" "),(_vm.isMonoPlace && !_vm.isNetworkMonoPlace)?_c('NpsPlaceRankingWidget',{attrs:{"campaign":_vm.campaign,"subtitle":_vm.widgetSubtitle,"cols":12}}):_vm._e(),_vm._v(" "),(_vm.campaign && _vm.nisMixin_canUseNisFeature([_vm.campaign]))?_c('TransactionalUnitToEarnWidget',{attrs:{"campaign":_vm.campaign,"voterProportions":_vm.nisVoterProportions,"summary":_vm.nisSummary}}):_vm._e()]},proxy:true},{key:"main-section",fn:function(){return [(_vm.campaign && _vm.nisMixin_canUseNisFeature([_vm.campaign]))?_c('NpsRepartitionWidget',{attrs:{"campaign":_vm.campaign,"subtitle":_vm.widgetSubtitle,"nisVoterProportions":_vm.nisVoterProportions,"nisSummary":_vm.nisSummary}}):_c('NpsRepartitionWidget',{attrs:{"campaign":_vm.campaign,"subtitle":_vm.widgetSubtitle}}),_vm._v(" "),_c('NpsEvolution',{attrs:{"campaign":_vm.campaign,"subtitle":_vm.widgetSubtitle}}),_vm._v(" "),(
        (
          _vm.campaign?.hasTextAnalysisEnabled ||
          _vm.campaign?.hasVerbatimAnalysisEnabled
        ) &&
        _vm.hasAccessToNewPlatformStatisticVerbatimAnalysis
      )?_c('v-row',{attrs:{"no-gutters":""}},[_c('TopicsVerbatimTopicsWidget',{attrs:{"positive":true,"indicator":{ type: 'nps' },"campaign":_vm.campaign}}),_vm._v(" "),_c('TopicsVerbatimTopicsWidget',{attrs:{"positive":false,"indicator":{ type: 'nps' },"campaign":_vm.campaign}})],1):(_vm.isDemoUser)?_c('v-row',{attrs:{"no-gutters":""}},[_c('NpsPositiveTopicsWidget',{attrs:{"campaign":_vm.campaign,"subtitle":_vm.widgetSubtitle}}),_vm._v(" "),_c('NpsNegativeTopicsWidget',{attrs:{"campaign":_vm.campaign,"subtitle":_vm.widgetSubtitle}})],1):_vm._e(),_vm._v(" "),(!_vm.isNetworkMonoPlace && _vm.hasAccessToNewPlatformRankingTab)?_c('NpsRankingTable',{attrs:{"campaign":_vm.campaign,"subtitle":_vm.widgetSubtitle}}):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }