<template>
  <WStatsWidget
    class="ranking-table-widget"
    :cols="12"
    :title="title || $t('widget_place_ranking_title')"
    :subtitle="displayedSubtitle"
    contentWidth="100%"
  >
    <template #options>
      <w-drop-down-menu
        data-html2canvas-ignore
        v-if="!loading"
        :items="exportItems"
        icon="mdi-download">
      </w-drop-down-menu>
    </template>
    <template #content>
      <RankingTablePlace
        ref="table"
        :scope="defaultRanking"
        :staticColumns="staticColumns"
        :customRankingRelatedIndicator="customRankingRelatedIndicator"
        :campaign="campaign"
        @tableLoading="onTableLoading"
      />
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import RankingTablePlace  from '@platform/pages/dashboard/Ranking/RankingTablePlace'
  import WidgetMixin from './widget_mixin'

  export default {
    name: "RankingTableWidget",
    components: { RankingTablePlace },
    props: {
      title: { type: String },
      staticColumns: { required: true },
      customRankingRelatedIndicator: { required: true },
      campaign: { required: false }
    },
    mixins: [
      WidgetMixin
    ],
    data() {
      return {
        loading: true,
        exportItems: []
      }
    },
    computed: {
      ...mapGetters([
        'defaultRanking',
        'currentLexicon'
      ]),
    },
    methods: {
      onTableLoading(loading) {
        this.loading = loading
      }
    },
    watch: {
      loading() {
        this.exportItems = [
          { title: 'PDF', onClick: this.$refs.table.exportToPdf },
          { title: 'Excel', onClick: this.$refs.table.exportToExcel }
        ]
      }
    }
  }
</script>
