<template>
  <div class="nis-roi-satisfaction-cell">
    <div v-if="nisItem.nbReview" class="d-flex align-center ga-2">
      <v-tooltip
        top
        max-width="300"
        :disabled="scoreTooltip === undefined"
      >
        <template #activator="{ on }">
          <span v-on="on">
            <span class="fb-15 c-primary text-no-wrap score">
              {{ `+${nisItem.nisRoiSatisfaction} %` }}
            </span>
          </span>

        </template>
        <span v-if="scoreTooltip">
          {{ scoreTooltip }}
        </span>
      </v-tooltip>
      <div
        class="evolution"
      >
        <w-color-sign
          :value="nisItem.nisRoiSatisfactionEvolution"
          :decimals="1"

        />
      </div>
      <v-progress-linear
        v-if="showBar"
        :value="nisItem.nisRoiSatisfactionBarScore"
        rounded
        color
        :height="12"
        :style="{minWidth: barWidth, maxWidth: barWidth}"
      />
      <span
        v-if="showNbReview"
        class="f-12 ml-2 lh-12 c-lightgrey text-no-wrap"
      >
        {{ translatedNbReview }}
      </span>
    </div>
    <span v-else class="f-12 c-lightgrey">
      {{ $t('nil') }}
    </span>
  </div>
</template>

<script>
  export default {
    name: "NisRoiSatisfactionCell",
    props: {
      nisItem: { type: Object, required: true },
      showBar: { type: Boolean, default: true },
      barWidth: { type: String, default: '300px' },
      showNbReview: { type: Boolean, default: true },
      scoreTooltip: { type: String }
    },
    computed: {
      translatedNbReview() {
        return this.$t('nbReview_without_google_logo', {
          nbReview: this.nisItem.nbReview?.toLocaleString(),
        })
      }
    }
  }
</script>

<style lang="stylus">
  .nis-roi-satisfaction-cell
    .v-progress-linear__determinate
      background: var(--gradient-green) !important
    .v-progress-linear__background
      background: var(--mercury) !important
    .score
      width: 60px
      display: flex
    .evolution
      width: 40px
</style>
