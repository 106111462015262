<template>
  <div v-if="nisItem.nisOpportunity > 0" class="d-flex align-center ga-1">
    <WIcon icon="smiley-happy" class="smiley-icon f-16 top-0"/>
    <span class="f-14 c-primary text-no-wrap">{{ `${actualSatisfaction} %` }}</span>
    <v-icon
      small
      class="c-oslogrey ml-1"
    >
      mdi-arrow-right
    </v-icon>
    <span class="fb-14 c-primary text-no-wrap">{{ `${satisfactionGoal} %` }}</span>
    <v-tooltip
      transition="none"
      top
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          small
          class="c-primary"
        >
          mdi-magnify
        </v-icon>
      </template>
      <div class="d-flex align-center ga-1">
        <span class="f-12 c-white">
          {{ topCompetitorsBasedOn }}
        </span>
        <span class="fb-12 c-white">
          {{ topCompetitorPlaceGroup }}
        </span>
      </div>

      <div
        v-for="topCompetitor in topCompetitors"
        class="d-flex align-center flex-nowrap ga-1"
      >
        <span class="f-12 c-white text-no-wrap">
          {{ topCompetitor.placeName }}:
        </span>
        <span class="fb-12 c-white text-no-wrap">
          {{ topCompetitor.satisfactionRate }}%
        </span>
      </div>
    </v-tooltip>
  </div>
  <span v-else class="f-12 c-lightgrey">
    {{ $t('nil') }}
  </span>
</template>

<script>
  import _upperFirst from 'lodash/upperFirst'

  export default {
    name: 'NisSatisfactionGoalCell',
    props: {
      nisItem: { type: Object, required: true }
    },
    computed: {
      actualSatisfaction() {
        return this.nisItem.nisSatisfactionRates.actual
      },
      satisfactionGoal() {
        return this.nisItem.nisSatisfactionRates.toReach
      },
      topCompetitorsBasedOn() {
        return this.$t('nis_top_competitors_based_on')
      },
      topCompetitorPlaceGroup() {
        return this.nisItem.nisPlaceReferencesGroupCategoryName === null ?
          this.$t('lexicon_.general_.network') :
          `${_upperFirst(this.nisItem.nisPlaceReferencesGroupCategoryName)}: ${this.nisItem.nisPlaceReferencesGroupName}`
      },
      topCompetitors() {
        return this.nisItem.nisPlaceReferences
      }
    }
  }
</script>
