<template>
  <v-dialog
    class="import-dialog"
    v-model="dialog"
    width="600px"
    @keydown.escape="dialog = false"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        class="ml-2"
        v-bind="attrs"
        v-on="on"
      >
        Import
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Import</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          color="error"
          @click="dialog = false"
          :ripple="false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-stepper v-model="step" vertical>
          <v-stepper-step :complete="step > 1" step="1" @click="discard">
            {{ fileName || "Choix du fichier" }}
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-file-input v-model="file" accept=".xlsx" truncate-length="15" hide-details></v-file-input>

            <v-btn class="mt-3" color="success" small :disabled="!file" @click="fileUpdateCheck" :loading="checkResultLoading">
              Vérifier le fichier
            </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="step > 2" step="2">
            <div>
              Liste des modifications

              <v-btn v-show="checkResult" icon color="primary" @click="exportAnnotedExcel" :ripple="false">
                <v-icon small>
                  mdi-download
                </v-icon>
              </v-btn>
            </div>
          </v-stepper-step>

          <v-stepper-content step="2">
            <div>
              Lignes avec des modifications:  {{ totalRowWithModification }}
              <br />
              Lignes avec des erreurs: {{ totalRowWithError }}
            </div>

            <div>
              <v-btn @click="applyModifications" :loading="applyModificationsLoading" class="mt-3" color="success" small v-show="totalRowWithModification > 0 && totalRowWithError == 0">
                Appliquer les modifications
              </v-btn>

              <v-btn @click="discard"  class="mt-3" color="error" small v-show="totalRowWithError > 0">
                Corriger le fichier
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-step :complete="step == 3" step="3">
            Points de vente modifiés
          </v-stepper-step>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "ImportDialog",
    data() {
      return {
        applyModificationsLoading: false,
        checkResultLoading: false,
        checkResult: null,
        summary: null,
        modifications: null,
        errors: null,
        file: null,
        dialog: null,
        mode: 'modification',
        step: 1
      }
    },
    computed: {
      ...mapGetters([
        'currentCampaignPreferencesCampaignId'
      ]),
      fileName() {
        if (this.file) {
          return this.file.name
        }
      },
      totalRowWithModification() {
        return this?.summary?.rowWithModification || 0
      },
      totalRowWithError() {
        return this?.summary?.rowWithError || 0
      }
    },
    watch: {
      dialog() {
        this.discard()
      }
    },
    methods: {
      discard() {
        this.applyModificationsLoading = false
        this.checkResultLoading = false
        this.annotedExcel = null
        this.summary = null
        this.modifications = null
        this.file = null

        this.step = 1
      },
      async fileUpdateCheck() {
        try {
          this.checkResultLoading = true
          console.log("ici", this.currentCampaignPreferencesCampaignId)
          const data = (await this.$api.wizville.conversations.checkImportFileConversationPreFilledMessages(this.currentCampaignPreferencesCampaignId, this.file))
          console.log(data)
          this.annotedExcel = data.annotedExcel
          this.summary = data.summary
          this.modifications = data.modifications
          this.errors = data.errors
          this.step = 2
        } catch(e) {
          this.$store.dispatch('notifyError', { message:  "Le fichier n'a pas pu être traité" })
          this.step = 1
        }

        this.checkResultLoading = false
      },
      exportAnnotedExcel() {
        if (this.annotedExcel) {
          this.$textToFile(`modifications_${this.file.name}`, this.annotedExcel, { base64: true, binary: true })
        }
      },
      async applyModifications() {
        try {
          this.applyModificationsLoading = true
          const data = (await this.$api.wizville.places.applyModifications(this.modifications))
          this.applyModificationsLoading = false

          if (data.status === "ok") {
            this.$store.dispatch('notifySuccess')
          } else {
            this.$store.dispatch('notifyError')
          }

          this.step = 3
        } catch(e) {
          this.$store.dispatch('notifyError', { message:  "Impossible d'appliquer les modifications" })
        }

        this.applyModificationsLoading = false
        this.$emit("refreshTable")
      }
    }
  }
</script>

<style lang="stylus" scoped>
 
</style>
