<template>
  <WStatsWidget
    class="widget-lm-place-review-table"
    :title="title"
    :cols="12"
    contentWidth="100%"
  >
    <template #subtitle>
      <HeaderSubtitle/>
    </template>

    <template #content>
      <v-data-table
        :headers="headers"
        :items="reviews"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalReviews"
        :footer-props="{
          'items-per-page-options': [30, 100],
          'items-per-page-text': ''
        }"
        fixedHeader
        height="400px"
      >
        <template #item.name="{ item }">
          <div class="f-12 nowrap">
            <a :href="item.link" target="_blank">
              {{ item.name }}
            </a>
          </div>
        </template>

        <template #item.createdAt="{ item }">
          <WDate :date="item.createdAt" font="f-12" />
        </template>

        <template #item.score="{ item }">
          <v-rating
            class="w-rating d-none d-sm-flex"
            background-color="grey lighten-2"
            readonly
            :value="Math.round(item.score * 2) / 2"
            half-increments
            length="5"
            size="20"
            :color="$colors.gold"
          ></v-rating>
        </template>

        <template #item.content="{ item }">
          <div class="f-12 lh-16 py-1">
            {{ item.content }}
          </div>
        </template>

        <template v-slot:footer.prepend>
          <v-text-field
            v-model="searchField"
            :placeholder="capitalize($t('search'))"
            prepend-icon="mdi-magnify"
            type="text"
            class="f-12 search-field"
            @keydown.enter="onSearch"
            hide-details
            dense
          />
        </template>
      </v-data-table>
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { capitalize } from 'inflection'
  import _snakeCase from 'lodash/snakeCase'
  import HeaderSubtitle from '../shared/HeaderSubtitle'

  export default {
    name: "WidgetLmPlaceReviewTable",
    components: {
      HeaderSubtitle
    },
    props: {
      active: {
        required: false
      }
    },
    data() {
      return {
        searchField: null,
        search: null,
        options: null,
        headers: [
          { text: this.$t('lm_place'), value: 'lmPlacesName' },
          { text: 'Date', value: 'createdAt' },
          { text: this.$t('rankingTableHeaders.avgScore'), value: 'score' },
          { text: '', value: 'content', sortable: false }
        ],
        loading: true
      }
    },
    computed: {
      ...mapGetters([
        "currentDashboard",
        "lmPlaceReviewFilteredRequest",
        "placeNameByLmGmbPlaceId",
        'dashboardFilterDates',
        'lrmDashboardFilterRequest',
        "datesScope",
        'currentDashboardBasedTable',
        'lmGmbPlaceIdsScope',
        'currentLexicon',
        'isMonoPlace'
      ]),
      title() {
        const title = this.$t('competitors_filter_feedbacks')

        if (this.isMonoPlace &&
            this.active?.isUserItem === false
          ) {
          return `${title} - ${this.active.name}`
        }

        return title
      },
      lmPlaceIds() {
        return this.active?.id ? [this.active.id] : this.lmGmbPlaceIdsScope
      }
    },
    asyncComputed: {
      reviews: {
        async get() {
          this.loading = true
          let reviews = []

          if (this.options) {
            let reviewsRequest = this.lrmDashboardFilterRequest.select({
              [this.currentDashboardBasedTable]: [{
                lrm_place_reviews: { params: {
                  date_begin: this.datesScope.dateBegin,
                  date_end: this.datesScope.dateEnd,
                  lm_place_ids: this.lmPlaceIds,
                  search: this.search,
                  options: this.options
                }}
              }]
            })

            reviews = (await this.$resolve(reviewsRequest)).first()?.lrmPlaceReviews?.lmPlaceReviews || {}
            reviews = reviews?.map(review => {
              return {
                lmPlacesName: this.placeNameByLmGmbPlaceId[review.lmPlaceId] || review.lmPlacesName,
                createdAt: review.createdAt,
                content: review.content,
                score: review.score,
                id: review.id,
                link: review.lmPlacesUrl
              }
            }) || []
          }

          this.loading = false
          return reviews
        },
        default: [],
        watch: [ "search", "options" ]
      },
      totalReviews: {
        async get() {
          let result = 0

          if (this.options) {
            let request = this.lrmDashboardFilterRequest.select({
              [this.currentDashboardBasedTable]: [{
                lrm_place_total_reviews: { params: {
                  date_begin: this.datesScope.dateBegin,
                  date_end: this.datesScope.dateEnd,
                  lm_place_ids: this.lmPlaceIds,
                  search: this.search,
                  options: this.options
                }}
              }]
            })

            result = (await this.$resolve(request))?.first()?.lrmPlaceTotalReviews?.lmPlaceReviews[0]?.countId || 0
          }

          return result
        },
        default: 0
      },
    },
    methods: {
      capitalize,
      onSearch() {
        this.options.page = 1
        this.search = this.searchField
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .search-field
    max-width: 300px
</style>
