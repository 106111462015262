<template>
  <WStatsWidget
    v-if="topic === undefined"
    :title="title"
    :cols="cols"
    :loading="true"
    :empty="false"
  />
  <ScoreWidget
    v-else
    :title="title"
    :indicator="topic?.score"
    :evolution="topic?.evolution"
    :displayEvolution="true"
    :nbReview="topic?.nbReview"
    :cols="cols"
    :displayRank="false"
    :displayStars="topic && !topicsMixin_isDisplayTypeScale(topic)"
    :displayTooltipWithText="tooltipText"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import ScoreWidget from '@statistics/shared/widgets/ScoreWidget'
import TopicsMixin from '@statistics/shared/topics_mixin'

export default {
  name: 'TopicScoreWidget',
  mixins: [
    TopicsMixin,
  ],
  components: {
    ScoreWidget,
  },
  props: {
    topic: { type: Object },
    cols: { type: Number, default: 3 },
  },
  computed: {
    ...mapGetters([
      'dashboardFilterRequest'
    ]),
    title() {
      return this.topic ?
             `${this.$t('average_mark')} - ${this.topic?.name}` :
             this.$t('average_mark')
    },
    tooltipText() {
      return this.topic && this.topicsMixin_isDisplayTypeScale(this.topic) ?
             this.topicsMixin_productLabelsTooltip(this.topic.rateLabelsHash) :
             ''
    }
  }
}
</script>
