<template>
  <WStatsWidget
    v-if="campaign === undefined || campaignIndicator === undefined"
    :title="$t('repartition_widget_title')"
    :cols="12"
    height="200px"
    :loading="true"
    :empty="false"
  />
  <RepartitionWidget
    v-else
    :title="title"
    :subtitle="displayedSubtitle"
    :campaign="campaign"
    :nbPromoters="campaignIndicator.nbPromoters"
    :nbNeutrals="campaignIndicator.nbNeutrals"
    :nbDetractors="campaignIndicator.nbDetractors"
    :promotersLabel="nisVoterProportions && nisSummary ? label(nisVoterProportions.nbPromoters) : undefined"
    :neutralsLabel="nisVoterProportions && nisSummary ? label(nisVoterProportions.nbNeutrals) : undefined"
    :detractorsLabel="nisVoterProportions && nisSummary ? label(nisVoterProportions.nbDetractors) : undefined"
    :promotersTooltip="promotersTooltip"
    :neutralsTooltip="neutralsTooltip"
    :detractorsTooltip="detractorsTooltip"
    :promoters-legend="promotersLegend"
    :neutrals-legend="neutralsLegend"
    :detractors-legend="detractorsLegend"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import RepartitionWidget from "@statistics/shared/widgets/RepartitionWidget"
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import NisMixin from '@statistics/shared/nis_mixin'

  export default {
    name: "CampaignIndicatorRepartitionWidget",
    mixins: [
      WidgetMixin,
      NisMixin,
    ],
    components: {
      RepartitionWidget,
    },
    props: {
      campaign: { type: Object },
      campaignIndicator: { type: Object },
      nisVoterProportions: { type: Object, default: null },
      nisSummary: { type: Object, default: null },
    },
    methods: {
      label(count) {
        return this.$options.filters.toPercent(
          count / (this.nisVoterProportions.nbPromoters + this.nisVoterProportions.nbNeutrals + this.nisVoterProportions.nbDetractors)
        ).toString()
      },
      tooltip(count, transactionalUnitValue) {
        const revenue = this.nisMixin_transactionalUnit([this.campaign]).currency ?
                        transactionalUnitValue.toLocaleString(
                          this.$i18n.locale,
                          {
                            style: 'currency',
                            currency: this.nisMixin_transactionalUnit([this.campaign]).currency,
                            maximumFractionDigits: 2
                          }
                        ) :
                        transactionalUnitValue.toFixed(1).replaceAll('.', ',')
        if (this.isLcl) {
          return this.$t(`campaign_indicator_repartition_widget_${this.nisMixin_transactionalUnit([this.campaign]).nisColumnName}_label_lcl`, {
            count,
            percentage: this.label(count, transactionalUnitValue),
            transactional_unit_value: revenue
          })
        } else {
          return this.$t(`campaign_indicator_repartition_widget_${this.nisMixin_transactionalUnit([this.campaign]).nisColumnName}_label`, {
            count,
            percentage: this.label(count, transactionalUnitValue),
            transactional_unit_value: revenue
          })
        }
      }
    },
    computed: {
      ...mapGetters([
        'datesScope',
        'dashboardFilterRequest'
      ]),
      title() {
        return this.campaignIndicator.indicatorType == 'campaignScoreIndicator' ?
               `${this.$t('repartition_widget_title')} - ${this.campaignIndicator.formulaToName}` :
               `${this.$t('repartition_widget_title')} - ${this.campaignIndicator.name}`
      },
      promotersTooltip() {
        return this.nisVoterProportions && this.nisSummary ?
          this.tooltip(
            this.nisVoterProportions.nbPromoters,
            this.nisMixin_currentTransactionalUnitData(this.campaign, this.nisSummary).promoters
          ) : undefined
      },
      neutralsTooltip() {
        return this.nisVoterProportions && this.nisSummary ?
          this.tooltip(
            this.nisVoterProportions.nbNeutrals,
            this.nisMixin_currentTransactionalUnitData(this.campaign, this.nisSummary).neutrals
          ) : undefined
      },
      detractorsTooltip() {
        return this.nisVoterProportions && this.nisSummary ?
          this.tooltip(
            this.nisVoterProportions.nbDetractors,
            this.nisMixin_currentTransactionalUnitData(this.campaign, this.nisSummary).detractors
          ) : undefined
      },
      promotersLegend() {
        return this.$helpers.i18n.scoreRange(this.campaign?.avgScoreScale?.promoters)
      },
      neutralsLegend() {
        return this.$helpers.i18n.scoreRange(this.campaign?.avgScoreScale?.neutrals)
      },
      detractorsLegend() {
        return this.$helpers.i18n.scoreRange(this.campaign?.avgScoreScale?.detractors)
      }
    }
  }
</script>


this.campaign?.avgScoreScale?.max
