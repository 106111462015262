<template>
  <div class="vl">
    <a href="https://wizville.fr">
      <img
        id="logo"
        src="//wizville.fr/wp-content/uploads/2016/03/logo-wizville-mobile-retina.png"
      />
    </a>
    <div id="container">
      <h1>{{ $t('not_found.title') }}</h1>

      <!-- eslint-disable-next-line -->
      <p v-html="$t('not_found.description')"></p>

      <div id="buttons">
        <a
          id="produit"
          href="https://wizville.fr/produit/"
          class="button"
        >
          {{ $t('not_found.product') }}
        </a>
        <a
          id="ressources"
          href="https://wizville.fr/ressources/"
          class="button"
        >
          {{ $t('not_found.resources') }}
        </a><br>
        <a
          id="back"
          href="javascript:history.back()"
        >
          {{ $t('not_found.link_to_back') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default{
}
</script>

<!-- eslint-disable max-len -->
<style lang='stylus' scoped>
a, span, h1, h2, h3, h4,h5, h6, p, b{
  font-family: 'freight', arial, 'sans-serif' !important;
  color: #4D4D4D;
}
.vl:after{
  content: "";
  background-image: url('https://storage.googleapis.com/wizville-public-eu/public/404-wizville.png');
  background-position: right top;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 0;
}
@media (max-width: 800px) {
  .bg-img:after{
    opacity: 0.3;
  }
  .button{
    padding: 10px 20px !important;
  }
}
h1{
  font-weight: 300;
}
.button{
  background: #65c095;
  padding: 10px 25px;
  color: #fff;
  border-radius: 3px !important;
  display: inline-block;
}
.back{
  width: 100%;
  border: 1px solid #65c095;
  display: inline-block;
}
#buttons{
  width: 230px;
}
.button:hover{
  opacity: 0.7;
}
#ressources{
  float: right;
}
#back{
  width: 100%;
  border: 1px solid #65c095;
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px !important;
  color: #65c095;
  text-align: center;
  margin-top: 10px;
}
#logo{
  width: 150px;
  margin-left: 10%;
  margin-top: 5%;
}
#container{
  position: absolute;
  left: 10%;
  top: 50%;
  z-index: 1;
}
.vl{
  width:100%;
  height: 60vh;
}
p{
  line-height: 28px;
}
a{
  text-decoration: none;
}
</style>
<!-- eslint-enable max-len -->
