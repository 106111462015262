import CampaignPreferencesLayout from '@platform/layouts/CampaignPreferencesLayout'
import SmartBenchmark from '@platform/pages/campaign_preferences/SmartBenchmark'
import NetImpactScore from '@platform/pages/campaign_preferences/NetImpactScore'
import Achievement from '@platform/pages/campaign_preferences/Achievement'
import TextAnalysisPreference from '@platform/pages/campaign_preferences/TextAnalysisPreference'
import AutomatedMessages from '@platform/pages/campaign_preferences/AutomatedMessages'
import ProductImportPreference from '@platform/pages/campaign_preferences/ProductImportPreference'
import CampaignTranslations from '@platform/pages/campaign_preferences/CampaignTranslations'

import SmartAnswers from '@platform/pages/campaign_preferences/SmartAnswers'
import store from '@platform/store'

async function loadCampaignPreferences(to, from, next) {
  await store.dispatch("campaignPreferencesContext", {
    campaignId: to.params.campaignId
  })

  await store.dispatch("surveyContext",
    to.params.campaignId
  )

  next()
}

export default {
  path: '/campaign_preferences/:campaignId',
  component: CampaignPreferencesLayout,

  meta: {
    beforeEach: loadCampaignPreferences
  },

  children: [
    {
      path: 'smart_benchmark/:campaignSmartBenchmarkPreferencesId?',
      name: 'SmartBenchmarkPreferences',
      component: SmartBenchmark,

      props: (route) => ({
        campaignSmartBenchmarkPreferencesId: Number(route.params.campaignSmartBenchmarkPreferencesId)
      })
    },
    {
      path: 'net_impact_score',
      name: 'NetImpactScore',
      component: NetImpactScore
    },
    {
      path: 'achievement',
      name: 'Achievement',
      component: Achievement
    },
    {
      path: 'text_analysis_preference',
      name: 'TextAnalysisPreference',
      component: TextAnalysisPreference
    },
    {
      path: 'automated_messages',
      name: 'AutomatedMessages',
      component: AutomatedMessages
    },
    {
      path: 'smart_answers',
      name: 'SmartAnswers',
      component: SmartAnswers
    },
    {
      path: 'product_feeds/:page(settings|history)',
      name: 'ProductImportPreference',
      component: ProductImportPreference,
      props: (route) => ({
        page: route.params.page,
        campaignId: Number(route.params.campaignId)
      })
    },
    {
      path: 'campaign_translations/:feature?',
      name: 'CampaignTranslations',
      component: CampaignTranslations,
      props: route => ({ feature: route.params.feature })
    }
  ]
}
