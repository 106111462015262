<template>
  <v-main
    class="voter-third-party-transfer-layout"
    :style="mainStyle"
  >
    <router-view />
  </v-main>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'VoterThirdPartyTransferLayout',
    computed: {
      ...mapGetters([
        'currentVoterThirdPartyTransferBackgroundImageUrl'
      ]),
      mainStyle() {
        return {
          minWidth: '100%',
          minHeight: '100vh',
          backgroundPosition: 'bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundImage: `url('${encodeURI(this.currentVoterThirdPartyTransferBackgroundImageUrl)}')`
        }
      },
    }
  }
</script>
