<template>
  <v-card outlined>
    <v-form
      ref="form"
      :disabled="!campaignAchievementPreference.enabled"
      v-model="isValid"
    >
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="12">
        <v-row no-gutters>
          <v-card-title>
            <h4 class="f-16 c-primary">{{ $t('achivements_settings_activation') }}</h4>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-btn-toggle
                  v-model="campaignAchievementPreference.enabled"
                  mandatory
                  color="info"
                  flat
                  outlined
                >
                  <v-btn @click="setChange" :value="false" class="v-btn v-size--default ">
                    <span>OFF</span>
                  </v-btn>
                    <v-btn @click="setChange" :value="true" class="v-btn v-size--default">
                    <span>ON</span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field outlined dense
                      :label="$t('achivements_settings_minimum_feedbacks_count')"
                      background-color="#eef1f5"
                      type="number"
                      min="5"
                      max="100"
                      :rules="feedbacksCountRules"
                      v-model="campaignAchievementPreference.minimumFeedbacksCount"
                      v-bind="attrs"
                      v-on="on"
                      class="minimum-feedbacks-count-input"
                      @input="setChange"
                      hide-details="auto"
                    >
                    </v-text-field>
                  </template>
                  <span>
                    {{ $t('achivements_settings_minimum_feedbacks_count_tooltip') }}
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-text-field outlined dense
                :label="$t('achivements_settings_top_progression_threshold_rate')"
                background-color="#eef1f5"
                :prefix="$t('achivements_settings_top_progression_threshold_rate_prefix')"
                suffix="%"
                type="number"
                min="1"
                max="50"
                :rules="topProgressionThresholdRateRules"
                v-model="campaignAchievementPreference.topProgressionThresholdRate"
                class="top-progression-threshold-rate"
                @input="setChange"
                hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <div class="d-flex align-center">
              <v-checkbox
                v-model="retroactivity"
                :disabled="!hasChanged"
                :label="$t('achivements_settings_retroactivity_label')"
                @change="onRetroactivityChanged"
              />
              <v-select 
                class="ml-2"
                v-if="retroactivity"
                v-model="retroactivityDelay"
                :items="retroactivityDelays"
                item-text="label"
                item-value="value"
                :label="$t('achivements_settings_retroactivity_delay_label')"
                prepend-inner-icon="mdi-history"
                background-color="#eef1f5"
                outlined
                dense
                hide-details="auto"
                @change="onRetroactivityDelayChanged"
              />
            </div>
            <v-row no-gutters>
              <v-col>
                <v-card-text>
                  <v-btn
                    :disabled="!isValid || !hasChanged"
                    color="primary"
                    @click="saveCampaignAchievementPreference"
                  >
                  {{ $t("achivements_settings_save") }}
                  </v-btn>
                </v-card-text>
              </v-col>
              <v-col>
                <v-card-text class="text-align-right" >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }" v-if="!isProduction()">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        
                        @click="sendNotifications"
                      >
                        <v-icon>
                          mdi-send
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Envoyer les notifications de partage de succès aux users devant les recevoir aujourd’hui
                    </span>
                  </v-tooltip>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
        </v-row>
      </v-col>
    </v-row>
    </v-form>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "Form",
    components: {},
    data() {
      return {
        isValid: false,
        hasChanged: false,
        retroactivity: false,
        retroactivityDelay: 1,
        retroactivityDelays: [
          {label: this.$t('achivements_settings_delay_1_mois'), value: 1},
          {label: this.$t('achivements_settings_delay_2_mois'), value: 2},
          {label: this.$t('achivements_settings_delay_3_mois'), value: 3}
        ],
        feedbacksCountRules: [
          v => !!v || this.$t('achivements_settings_minimum_feedbacks_count_not_null'),
          v => (Number.isInteger(Number(v)) && (v >= 5) && (v <= 100)) || this.$t('achivements_settings_minimum_feedbacks_count_wrong_value')
        ],
        topProgressionThresholdRateRules: [
          v => !!v || this.$t('achivements_settings_top_progression_threshold_rate_not_null'),
          v => (Number.isInteger(Number(v)) && (v >= 1) && (v <= 50)) || this.$t('achivements_settings_top_progression_threshold_rate_wrong_value')
        ]
      }
    },
    props: {
      campaignAchievementPreference: { required: true }
    },
    computed: {
      ...mapGetters([
        'currentCampaignPreferencesCampaignId'
      ])
    },
    methods: {
      isProduction() {
        console.log(this.$production, this.$production == true)
        return this.$production
      },
      onRetroactivityChanged(val){
        const retroactivityDelay  = val ? this.retroactivityDelay : 0
        
        this.$emit('retroactivityDelayChanged', retroactivityDelay)
      },
      onRetroactivityDelayChanged(val) {
        this.$emit('retroactivityDelayChanged', val)
      },
      setChange() {
        console.log(this.$production)
        this.hasChanged = true
      },
      async saveCampaignAchievementPreference() {

        const newCampaignAchievementPreference = {...this.campaignAchievementPreference}

        newCampaignAchievementPreference.topProgressionThresholdRate = this.$options.filters.fromPercent(this.campaignAchievementPreference.topProgressionThresholdRate)

        if (confirm(this.$t('achivements_settings_confirmation_question'))) {
          const response = await this.$api.wizville.campaignPreferences.editCampaignAchievementPreference(
            this.currentCampaignPreferencesCampaignId, newCampaignAchievementPreference, this.retroactivity, this.retroactivityDelay
          )
        
          if (response.status === "ok") {
            this.$store.dispatch('notifySuccess', {
              message: this.$t('achivements_settings_parameters_changed')
            })
            this.$emit("campaignAchievementPreferenceSaved")
            this.hasChanged = false
          }
        }
      },
      async sendNotifications() {
        if (confirm(this.$t('achivements_settings_send_notif_question'))) {
          const response = await this.$api.wizville.campaignPreferences.sendCampaignAchievementNotification(
            this.currentCampaignPreferencesCampaignId
          )
        
          if (response.status === "ok") {
            this.$store.dispatch('notifySuccess', {
              message: this.$t('achivements_settings_send_notif_ok')
            })
          }
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
</style>
