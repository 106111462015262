<template>
  <v-card class="unasigned-google-brand-places ma-4">
    <v-card-title class="d-flex align-center">
      <h4 class="f-16 c-primary">
        <span>{{ $t('unasigned_google_brand_places_title') }}</span>
        <span v-if="!loading">{{ `(${unasignedGoogleBrandPlaces.length})` }}</span>
      </h4>
      <v-btn
        icon
        class="ml-auto"
        @click="exportPlaces"
      >
        <v-icon class="f-20 c-primary">mdi-download</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-show="!loading"
        :headers="headers"
        :items="unasignedGoogleBrandPlaces"
        mobile-breakpoint="0"
        class="v-datatable-without-y-borders"
        :sort-by="['name']"
        :sort-desc="[true]"
        fixed-header
        height="420px"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item.name="{ item }">
          <div class="d-flex align-center ga-1">
            <span
              class="fb-14"
            >
              {{ item.name }}
            </span>
            <span
              v-if="item.storeCode"
              class="f-14"
            >
              {{ `(${item.storeCode})` }}
            </span>
          </div>
        </template>
        <template v-slot:item.googleName="{ item }">
          <div class="d-flex align-center ga-1">
            <span
              class="f-12"
            >
              {{ item.googleName }}
            </span>
            <v-icon
              class="f-14"
              @click="copyGoogleName(item)"
            >
              mdi-content-copy
            </v-icon>
            <a
              :href="item.url"
              class="link"
              target="_blank"
            >
              <v-icon
                class="f-14"
              >
                mdi-open-in-new
              </v-icon>
            </a>
          </div>
        </template>
      </v-data-table>
      <v-skeleton-loader v-show="loading" type="paragraph" />
    </v-card-text>
  </v-card>
</template>
<script>
  import dayjs from 'dayjs'
  import _uniq from 'lodash/uniq'
  import { mapGetters } from 'vuex'

  import exportToExcel from '@shared/helpers/export-to-excel.js'

  export default {
    name: "UnasignedGoogleBrandPlacesTable",
    data() {
      return {
        loading: true
      }
    },
    computed: {
      ...mapGetters([
        'currentUser',
        'currentLexicon'
      ]),
      headers() {
        return [
          {
            text: this.$t('unasigned_google_brand_place_name'),
            value: 'name',
            sortable: false
          },
          {
            text: this.$t('unasigned_google_brand_place_google_name'),
            value: 'googleName',
            sortable: false,
            cellClass: 'f-12'
          },
          {
            text: this.$t('google_brand_account_account_name'),
            value: 'googleBrandAccountName',
            sortable: false,
            cellClass: 'f-12'
          },
          {
            text: this.$t('unasigned_google_brand_place_address'),
            value: 'address',
            sortable: false,
            cellClass: 'f-12'
          },
          {
            text: this.$t('unasigned_google_brand_place_unasigned_reason'),
            value: 'unasignedReason',
            sortable: false,
            cellClass: 'f-12'
          }
        ]
      }
    },
    asyncComputed: {
      unasignedGoogleBrandPlaces: {
        default: [],
        async get() {
          this.loading = true
          let req = this.$basedRequest().select({
            unasigned_google_brand_places: [
              { 'location_name': { as: 'name' } },
              { 'google_name': { as: 'googleName' } },
              { 'google_brand_accounts_account_name': { as: 'googleBrandAccountName' } },
              { 'address': { as: 'address' } },
              { 'url': { as: 'url' } },
              { 'store_code': { as: 'storeCode' } },
              { 'unasigned_reason': { as: 'unasignedReason' } },
            ]
          }).where({
            'brands_id': this.currentUser.brandId,
            place_id: { 'is_null': {} }
          })

          let result = (await this.$resolve(req))?.data?.unasignedGoogleBrandPlaces || []

          result = Object.values(result).map(unasignedGoogleBrandPlace => {
            if (unasignedGoogleBrandPlace.unasignedReason !== 'no_match') {
              unasignedGoogleBrandPlace.unasignedReason = `no_match, ${unasignedGoogleBrandPlace.unasignedReason}`
            }

            return unasignedGoogleBrandPlace
          })

          this.loading = false
          return result
        }
      }
    },
    methods: {
      _uniq,
      exportPlaces() {
        if (this.unasignedGoogleBrandPlaces && this.unasignedGoogleBrandPlaces.length) {
          const formatedData = this.unasignedGoogleBrandPlaces.map((unasignedGoogleBrandPlaces) => {
            const { id, ...filteredUnasignedGoogleBrandPlaces } = unasignedGoogleBrandPlaces

            return filteredUnasignedGoogleBrandPlaces
          })

          const headers = Object.keys(formatedData[0]).map((key, index) =>
            this.$t(`public_platform_google_places_export_${key}`)
          )

          let sheetName = 'unasigned_locations'
          let fileName = `${dayjs().locale(this.$i18n.locale).format('LL LTS')}_${sheetName}`

          exportToExcel(fileName, sheetName, formatedData, headers)
        }
      },
      copyGoogleName(item) {
        const _this = this
        this.$copyText(item.googleName).then(function (e) {
          _this.$store.dispatch('notifySuccess', {
            message: _this.$t(
              'flash_message_google_name_successfully_copied',
            ),
            timeout: 5000
          })
        }, function (e) {
          _this.$store.dispatch('notifyError', {
            message: _this.$t(
              'flash_message_google_name_failed_to_copy',
            ),
          })
        })
      }
    }
  }
</script>
