<template>
  <div class="d-flex flex-column flex-section dashboards-list">
    <v-list class="pb-0 pt-0">
      <v-list-item @click="$emit('hideDashboardList')" class="fb-15 bg-palegrey">
        <v-list-item-icon class="ml-1">
          <v-icon>mdi-chevron-left</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("dashboard_sidebar_back") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <dashboards-form>
    </dashboards-form>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"
  import DashboardsForm from "../../shared/DashboardsForm"

  export default {
    name: "DashboardsList",
    components: {
      DashboardsForm
    },
  }
</script>

<style lang="stylus">
  .dashboards-list
    .flex-section
      flex: 0 1 auto
      min-height: 0px
</style>

