import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  data() {
    return {
      loading: false,
      isEditingMode: false
    }
  },
  computed: {
    ...mapGetters([
      'claimAchievementsEvent',
      'currentEditingPlaceCampaignAchievementId',
      'currentDashboardCampaignIds',
      'placeIds'
    ]),
    nbAchievements() {
      return this.achievements?.length
    }
  },
  asyncComputed: {
    achievements: {
      async get() {
        this.loading = true
        let request = this.$basedRequest().select({
          place_campaign_achievements: [
            "id", "topics_name", "created_at", "month", "score", "top_progression_threshold_rate"
        ]}).where({
          campaign_id: this.currentDashboardCampaignIds,
          place_campaigns_place_id: this.placeIds
        }).order([['topics_name', 'asc'], ['month', 'desc']])

        if (this.isEditingMode) {
          request = request.where({ id: this.currentEditingPlaceCampaignAchievementId })
        } else {
          request = request.where({
            claimed_at: null,
            created_at: { ">": dayjs().subtract(3, 'month') }
        })}
        const result = await this.$resolve(request)

        this.loading = false

        return result.data.placeCampaignAchievements
      },
      default: undefined,
      watch: ['claimAchievementsEvent', 'currentEditingPlaceCampaignAchievementId']
    }
  }
}
