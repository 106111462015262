<template>
  <div class="dashboard-stats-page pl-3 pt-3">
    <WStatsSection
      v-for="dashboardStatsSection of dashboardStatsSections"
      :key="dashboardStatsSection.position"
      :show="showDashboardSection(dashboardStatsSection.type)"
      :title="dashboardStatsSectionTitle(dashboardStatsSection.type)"
    >
      <template #content>
        <DashboardStatsActionsSection
          v-if="dashboardStatsSection.type === 'DashboardStatsActionsSection'"
        />
        <DashboardStatsMainIndicatorsSection
          v-if="dashboardStatsSection.type === 'DashboardStatsMainIndicatorsSection'"
          @onIndicatorsMounted="onIndicatorsMounted"
        />
      </template>
    </WStatsSection>
  </div>
</template>

<script>
  import DashboardStatsActionsSection from "./DashboardStatsActionsSection"
  import DashboardStatsMainIndicatorsSection from "./DashboardStatsMainIndicatorsSection"

  import { mapGetters } from 'vuex'

  export default {
    name: "DashboardStatsPage",
    components: {
      DashboardStatsActionsSection,
      DashboardStatsMainIndicatorsSection
    },
    data() {
      return {
        showDashboardStatsMainIndicatorsSection: false
      }
    },
    props: {
      dashboardStatsPage: {
        type: Object,
        required: true
      },
    },
    computed: {
      ...mapGetters([
        'currentDashboardInsatisfactionNotificationEnabled'
      ]),
      dashboardStatsSections() {
        return (this.dashboardStatsPage?.dashboardStatsSections?.sort((a, b) => (a.position - b.position)) || [])
      }
    },
    methods: {
      showDashboardSection(type) {
        if (type === 'DashboardStatsMainIndicatorsSection') {
          return this.showDashboardStatsMainIndicatorsSection
        }
        if (type === 'DashboardStatsActionsSection' && !this.currentDashboardInsatisfactionNotificationEnabled) {
          return false
        }
        return true
      },
      dashboardStatsSectionTitle(type) {
        if (type === 'DashboardStatsActionsSection') {
          return this.$t('statistics_actions_section_title')
        } else if (type === 'DashboardStatsMainIndicatorsSection') {
          return this.$t('statistics_main_indicators_section_title')
        } else if (type === 'DashboardStatsSecondaryIndicatorsSection') {
          return this.$t('statistics_secondary_indicators_section_title')
        } else if (type === 'DashboardStatsSocialIndicatorsSection') {
          return this.$t('statistics_social_indicators_section_title')
        } else if (type === 'DashboardStatsTopicsSection') {
          return this.$t('statistics_topics_section_title')
        } else {
          return type
        }
      },
      onIndicatorsMounted(value) {
        if (value) {
          this.showDashboardStatsMainIndicatorsSection = value
        }
      },
    }
  }
</script>

<style lang="stylus" scoped>

</style>
