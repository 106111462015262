<template>
  <WStatsWidget
    :class="treatActionsWidgetClass"
    :border="this.$colors.borderblue"
    :cols="4"
    contentWidth="100%"
  >
    <template #content>
      <WStatsNumber
        :number="statusProperties[treatStatus].number"
        :title="$t(statusProperties[treatStatus].title)"
        :emoji="statusProperties[treatStatus].emoji"
        :isLoading="isLoading"
        :linkTo="statusProperties[treatStatus].linkTo"
      >
        <template #emoji>
          {{ statusProperties[treatStatus].emoji }}
        </template>
      </WStatsNumber>
    </template>
  </WStatsWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import VoterRequestsMixin from "../../../shared/voter_requests_mixin"

export default {
  name: 'TreatActionsWidget',
  mixins: [
    VoterRequestsMixin
  ],
  props: {
    treatStatus: { required: true, type: String },
  },
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapGetters([
      'dashboardFilterRequest',
      'dashboardFilterReady',
      'currentUser',
    ]),
    treatActionsWidgetClass() {
      return `w-stats-${this.treatStatus}-widget`
    },
    statusProperties() {
      const treatStatusCount = this.fetchTreatStatusCount
      return {
        new_feedbacks: {
          title: treatStatusCount == 1 ? 'statistics_widget_insatisfaction_title' : 'statistics_widget_insatisfactions_title',
          emoji: (treatStatusCount ? '🙁' : '🙂'),
          number: treatStatusCount,
          linkTo: {
            name: 'Feedbacks',
            params: { status: 'treat', subStatus: 'new_feedbacks', voterUniqueId: null }
          }
        },
        new_messages: {
          title: 'statistics_widget_conversations_title',
          emoji: "✉️",
          number: treatStatusCount,
          linkTo: {
            name: 'Feedbacks',
            params: { status: 'treat', subStatus: 'new_messages', voterUniqueId: null }
          }
        },
        pending_validations: {
          title: 'statistics_widget_pending_validations_title',
          emoji: "",
          number: treatStatusCount,
          linkTo: {
            name: 'Feedbacks',
            params: { status: 'treat', subStatus: 'pending_validations', voterUniqueId: null }
          }
        }
      }
    }
  },
  asyncComputed: {
    fetchTreatStatusCount: {
      async get() {
        if (this.dashboardFilterReady) {
          const count = await this.getCount(
            'treat',
            this.treatStatus,
            { assignation: { value: 'me' } }
          )
          this.isLoading = false
          return count
        } else {
          return null
        }
      },
      default: null
    },
  }
}
</script>
