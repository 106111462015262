<template>
  <div class="net-impact-score">
    <v-row no-gutters class="pa-3">
      <Form/>
    </v-row>
    <v-row no-gutters class="pa-3">
      <Summaries/>
    </v-row>
  </div>
</template>

<script>
  import Summaries from './Summaries'
  import Form from './Form'

  export default {
    name: "NetImpactScore",
    components: {
      Summaries,
      Form
    }
  }
</script>
