import { i18n } from '@i18n/setup'

export class GroupSemantic {
  constructor(groupSemantic) {
    this.groupSemantic = groupSemantic
  }

  humanReadable(semantic) {
    const locale = (this.groupSemantic || {})[i18n.locale]
    return (locale || {})[semantic] || i18n.t(`group_semantic.${semantic}`)
  }
}
