<template>
  <WLateralSectionLayout class="verbatim-zoom">
    <template #lateral-section>
      <SentimentWeigthWidget
        :subtitle="widgetSubtitle"
        :zoomedData="zoomedData"
        :groupZoomedData="groupZoomedData"
        :networkZoomedData="networkZoomedData"
      />
      <SentimentScoreWidget
        :subtitle="widgetSubtitle"
        :zoomedData="zoomedData"
        :groupZoomedData="groupZoomedData"
        :networkZoomedData="networkZoomedData"
      />
    </template>

    <template #main-section>
      <SentimentReviewsWidget
        :campaign="campaign"
        :zoomedData="zoomedData"
      />
      <SentimentRepartitionWidget
        :subtitle="widgetSubtitle"
        :zoomedData="zoomedData"
      />
      <SentimentEvolutionWidget
        :campaign="campaign"
        :zoomedData="zoomedData"
      />
    </template>
  </WLateralSectionLayout>
</template>

<script>
  import SentimentRepartitionWidget from './SentimentRepartitionWidget'
  import SentimentWeigthWidget from './SentimentWeigthWidget'
  import SentimentScoreWidget from './SentimentScoreWidget'
  import SentimentEvolutionWidget from './SentimentEvolutionWidget'
  import SentimentReviewsWidget from './SentimentReviewsWidget'

  export default {
    name: "VerbatimZoom",
    props: [ 'campaign', 'zoomedData', 'groupZoomedData', 'networkZoomedData', 'widgetSubtitle' ],
    components: {
      SentimentRepartitionWidget,
      SentimentWeigthWidget,
      SentimentScoreWidget,
      SentimentEvolutionWidget,
      SentimentReviewsWidget
    }
  }
</script>
