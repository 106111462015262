<template>
  <div class="products-list">
    <WTreeSelect
      v-if="productsData" 
      :tree="productTree"
      v-model="selectedProductIds" 
    >
      <template #option="{ item }">
        {{ productById[item.value].name }} <span class="pl-1 c-lightgrey">{{ productById[item.value].code }}</span>
      </template>
    </WTreeSelect>

    <v-skeleton-loader v-else type="text,text,text" class="pa-3 pt-4" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "ProductsList",
  props: [ 'value', 'searchValue', 'campaign', 'selectedProductCategories', 'isExistingTranslations' ],
  data() {
    return {
      limit: 100
    }
  },
  computed: {
    ...mapGetters([
      'currentDashboardProductCampaignById'
    ]),
    currentCampaignProductStorage() {
      return this.currentDashboardProductCampaignById[this.campaign.id]?.productStorage
    },
    selectedProductIds: {
      get()  { 
        return this.value.map(v => v.id)
      },
      set(productIds) { 
        const selectedProducts = productIds.map(productId => {
          return { 
            name: this.value.find(v => v.id == productId)?.name || this.productById[productId]?.name,
            id: productId
          }
        })

        this.$emit('input', selectedProducts)
      }
    },
    productTree() {
      return this.productsData?.map(product => product.id)
    },
    productById() {
      return this.productsData?.reduce((h, product) => {
        h[product.id] = product
        return h
      }, {})
    },
    basedProductRequest() {
      let request = this.$basedRequest().where({
        campaign_id: this.campaign.id
      })

      if (this.selectedProductCategories?.length > 0) {
        request = request.where({ product_category_id: this.selectedProductCategories.map(c => c.id) })
      }
      
      let code_or_name_field = this.currentCampaignProductStorage !== 'legacy' ? 'code_or_name' : 'legacy_code_or_name'
      if (this.searchValue?.length > 2) {
        request = request.where({ [code_or_name_field]: { search: this.searchValue } })
      }

      return request
    },
  },
  asyncComputed: {
    async productsData() {
      if (this.selectedProductCategories?.length > 0 || this.searchValue?.length > 2) {
        let product_name_field = this.currentCampaignProductStorage !== 'legacy' ? 'name' : 'legacy_name'
        const request = this.basedProductRequest.select({
          products: [
            { [product_name_field]: { as: 'name' }},
            'id',
            'code'
          ]
        })

        const products = (await this.$resolve(request.limit(this.limit)))?.data?.products

        return products
      } else {
        return []
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
