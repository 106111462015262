<template>
  <RankingTableWidget
    :title="title"
    :staticColumns="staticColumns"
    :customRankingRelatedIndicator="customRankingRelatedIndicator"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import _capitalize from "lodash/capitalize"
  import RankingTableWidget  from '@statistics/shared/widgets/RankingTableWidget'

  export default {
    name: "SocialRankingTableWidget",
    props: [ 'source' ],
    components: { RankingTableWidget },
    computed: {
      ...mapGetters([
        'currentLexicon'
      ]),
      avgScoreScale() {
        return { max: 5 }
      },
      sourceName() {
        return _capitalize(this.source)
      },
      customRankingRelatedIndicator() {
        return `avg_voters_${this.source}`
      },
      sourceTitle() {
        return this.$t(`source_${this.source}`)
      },
      staticColumns() {
        return {
          'g_place_name': {},
          [this.customRankingRelatedIndicator]: {
            text: `${this.$t('average_mark')} - ${this.sourceTitle}`,
            avgScoreScale: this.avgScoreScale
          },
          [`${this.customRankingRelatedIndicator}_answer_repartition`]: {
            hideNbReview: true,
            hideScore: true,
            avgScoreScale: this.avgScoreScale
          }
        }
      },
      title() {
        return `${this.$t('competitors_widget_ranking_title')} ${this.currentLexicon.translate('places', 'places')} - ${this.sourceTitle}`
      }
    }
  }
</script>

<style lang="stylus" scoped>
 
</style>
