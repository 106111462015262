<template>
  <div class="basic-w-tree-select">
    <v-card v-click-outside="onClickOutside">
      <v-form v-model="formIsValid" ref="userForm" @input="emitPlaceGroupFormIsValid">
        <v-card-text class="pa-0">
          <div @click="toggleDisplayWTreeSelect">
            <v-text-field
              ref="placeGroupTextFieldRef"
              outlined
              dense
              clearable
              hide-details="auto"
              :error-messages="errorMessages"
              v-model="searchValue"
              @click:clear="onSearch"
              @focus="onFocusPlaceGroupTextfield"
              @keydown="onKeydown"
              :rules="[placeGroupOrPlaceIdsRule]"
            >
              <template v-slot:label>
                <span class="f-13">{{ $t('collaborator_sidebar_place_groups') }}</span>
              </template>
              <template v-slot:append>
                <v-icon
                  :class="{
                    'rotate-up': displayWTreeSelect,
                    'c-primary': displayWTreeSelect,
                    'rotate-down': !displayWTreeSelect
                  }">
                  mdi-menu-down
                </v-icon>
              </template>
            </v-text-field>
          </div>
          <div v-if="selectedPlaceGroupsOrPlaceIds?.length > 0 && labelById">
            <template v-for="(selectedPlaceGroupOrPlaceId, idx) in selectedPlaceGroupsOrPlaceIds">
              <v-chip
                class="mt-1 mx-1 f-12"
                close
                column
                dense
                @click:close="removeSelectedPlaceGroupOrPlaceId(idx)"
              >
                <span>
                  <v-icon small class="pr-1 mr-1">{{ placeGroupsOrPlacesIcon(selectedPlaceGroupOrPlaceId) }}</v-icon>

                  {{ labelById[selectedPlaceGroupOrPlaceId] }}
                </span>
              </v-chip>
            </template>
            <hr class="border-bodygrey mt-1" />
          </div>
          <WTreeSelect
            :tree="tree"
            v-model="selectedPlaceGroupsOrPlaceIds"
            :searchedOptions="searchedPlaceGroupsOrPlaces"
            v-if="tree && displayWTreeSelect"
          >
            <template #option="{ item }">
              <span>{{ labelById[item.value] }}</span>
            </template>
          </WTreeSelect>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
  import _debounce from "lodash/debounce"

  export default {
    name: 'PlaceGroupWTreeSelect',
    props: ['placeGroupsList', 'value', 'errorMessages'],
    data() {
      return {
        formIsValid: false,
        searchedPlaceGroupsOrPlaces: undefined,
        selectedPlaceGroupsOrPlaceIds: [],
        searchValue: "",
        placeGroupTextFieldFocusData: false,
        displayWTreeSelect: false,
      }
    },
    methods: {
      emitPlaceGroupFormIsValid() {
        this.$emit('updatePlaceGroupFormIsValid', this.formIsValid)
      },
      resetForm() {
        this.$refs.userForm.reset()
        this.$refs.userForm.resetValidation()
      },
      validateForm() {
        this.isValid = this.$refs.userForm.validate()
        return this.formIsValid
      },
      onFocusPlaceGroupTextfield() {
        this.placeGroupTextFieldFocusData = true
      },
      removeSelectedPlaceGroupOrPlaceId(index) {
        this.selectedPlaceGroupsOrPlaceIds.splice(index, 1)
      },
      placeGroupsOrPlacesIcon(item) {
        if (item.includes("brand")) {
          return "mdi-map-marker-multiple-outline"
        } else if (item.includes("place")) {
          return "mdi-map-marker"
        } else {
          return "mdi-web"
        }
      },
      placeGroupOrPlaceIdsRule() {
        return this.selectedPlaceGroupsOrPlaceIds.length != 0 || this.$t('collaborator_sidebar_errors_required_field')
      },
      toggleDisplayWTreeSelect() {
        if (this.placeGroupTextFieldFocusData) {
          this.displayWTreeSelect = !this.displayWTreeSelect
        }
      },
      onClickOutside() {
        this.displayWTreeSelect = false
      },
      onKeydown(event) {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
      this.onSearch()
    },
      onSearch: _debounce(function() {
        this.displayWTreeSelect = true
        if (this.searchValue?.length > 2) {
          const matchPatterns = this.searchValue.split(" ").map(v => this.$unaccent(v)).filter(v => v !== "").map(v => new RegExp(`${v}`, 'ig'))
          const matchPattern = new RegExp(`${this.searchValue}`, 'ig')
          const searchedPlaceGroupsOrPlaces = []

          for (const [productGroupId, label] of Object.entries(this.labelById)) {
            if (matchPatterns.every(matchPattern => this.$unaccent(label)?.match(matchPattern))) {
              searchedPlaceGroupsOrPlaces.push(productGroupId)
            }
          }

          this.searchedPlaceGroupsOrPlaces = searchedPlaceGroupsOrPlaces
        } else {
          this.searchedPlaceGroupsOrPlaces = null
        }
      }, 200)
    },
    computed: {
      tree() {
        let tree = this.placeGroupsList?.tree
        if (tree) {        
          const allPlacesObject = { allPlaces: tree?.allPlaces }

          delete tree.allPlace
          tree = this.$helpers.tree.deepSort(tree, v => this.labelById[v])
          tree = { ...allPlacesObject, ...tree }
          return tree
        }
      },
      labelById() {
        return this.placeGroupsList?.labelById
      },
    },
    watch: {
      selectedPlaceGroupsOrPlaceIds() {
        this.$refs.userForm.validate()
        this.$emit('input', this.selectedPlaceGroupsOrPlaceIds)
      },
      value() {
        this.selectedPlaceGroupsOrPlaceIds = this.value
      }
        
    }
  }
</script>

<style lang="stylus" scoped>
  .rotate-up
    transition: transform 0.3s
    transform: rotate(180deg)

  .rotate-down
    transition: transform 0.3s
    transform: rotate(0deg)

  .basic-w-tree-select
    .v-text-field__details
      margin-bottom: 0px !important
</style>
