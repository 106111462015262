<template>
  <v-row
    no-gutters
    class="achievement-actions"
  >
    <v-col cols="12">
      <v-card
        outlined
      >
        <v-card-title class="justify-left">
          <h4 class="f-16 c-primary">{{ $t('achievements_settings_action_types') }}</h4>
        </v-card-title>
        <v-card-text>
          <div v-if="!this.loading">
            <div class="py-1">
              <Action
                @editingActionTemplate="onEditingActionTemplate"
                @saved="reloadAchievements"
                :hideButtons="editingActionTemplate"
              />
            </div>
            <div
              v-for="(achievementActionTemplate, index) in achievementActionTemplates"
              :key="achievementActionTemplate.id"
              class="py-1"
              :class="{ 'bg-bodygrey': (index + 1) % 2 }"
            >
              <Action
                :achievementActionTemplate="achievementActionTemplate"
                @editingActionTemplate="onEditingActionTemplate"
                :hideButtons="editingActionTemplate || isAddingActionTemplate"
                @saved="reloadAchievements"
                @deleted="reloadAchievements"
              />
            </div>
          </div>
          <v-skeleton-loader v-else type="paragraph" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'

  import Action from './Action'
  import Form from './Form'

  export default {
    name: 'ActionTemplates',
    components: {
      Action,
      Form
    },
    data () {
      return {
        editingActionTemplate: false,
        reload: 0,
        loading: true,
        isAddingActionTemplate: false
      }
    },
    computed: {
      ...mapGetters([
        'currentUser',
        'currentUserId',
        'currentCampaignPreferencesCampaignId',
        'currentUser'
      ]),
    },
    asyncComputed: {
      achievementActionTemplates: {
        async get() {
          this.loading = true

          const result = await this.getAchievementActionTemplates()

          this.loading = false

          return result.data.achievementActionTemplates
        },
        default: [],
        watch: ['reload']
      }
    },
    methods: {
      onAddActionTemplateClicked(value) {
        this.isAddingActionTemplate = true
      },
      onEditingActionTemplate(value) {
        this.editingActionTemplate = value
      },
      reloadAchievements() {
        this.reload = this.reload + 1
      },
      async getAchievementActionTemplates() {
        let request = this.$basedRequest().select({
          achievement_action_templates: ["id", "name", "emoji"]
        }).where({
          campaign_id: this.currentCampaignPreferencesCampaignId
        }).order(
          ['id', 'desc']
        )

        return (await this.$resolve(request))
      },
    }
  }
</script>

<style lang="stylus" scoped>
  .chip-new-action, .chip-action
    cursor: pointer !important
</style>
