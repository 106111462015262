<template>
  <div
    @click="goToVoter"
    class="voter d-flex rounded-lg px-4 py-2"
    :class="isCurrent ? 'border-accent' : 'border-mercury'"
    :key="otherVoter.uniqueId"
  >
    <VoterItem
      style="width: 100%"
      :voter="otherVoter"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import VoterItem from '../../VoterList/VoterItem'
import VoterMixin from '../../voter_mixin'

export default {
  name: 'Voter',
  components: {
    VoterItem
  },
  mixins: [
    VoterMixin,
  ],
  props: {
    otherVoter: { required: true },
    isCurrent: { required: false, default: false }
  },
  computed: {
    ...mapGetters([
    ])
  },
  methods: {
    goToVoter() {
      this.voterMixin_goToVoter(this.otherVoter.uniqueId)
    }
  }
}
</script>

<style lang="stylus">
</style>
