<template>
  <div class="qcm-question">
    <QcmQuestionHistogram :question="question" :showTitle="this.showTitle"/>
    <QcmQuestionOtherTable :question="question" v-if="question.choiceOther" class="pt-3 mt-2 mb-1 text-align-left" />
  </div>
</template>

<script>  
  import QcmQuestionHistogram from "./QcmQuestionHistogram"
  import QcmQuestionOtherTable from "./QcmQuestionOtherTable"

  const colors = ["#FFB800", "#9FBAB8", "#B27B59", "#DB3572", "#88C788", "#BA0022", "#2E1C4D", "#C48754", "#4EBDB7", "#4F64B6", "#C1CC75", "#DBA951", "#B96785", "#874B8B", "#4BAEE4", "#6F63BA", "#2699A6", "#9D8B52", "#7DBD96", "#278DDA", "#B473BB", "#98BBB8", "#98A255", "#E8DF81", "#BD7953", "#E2BCA4"]

  export default {
    name: "QcmQuestion",
    props: {
      question: null, 
      showTitle: {
        default: true
      }
    },
    components: {
      QcmQuestionHistogram,
      QcmQuestionOtherTable
    }
  }
</script>

<style lang="stylus" scoped>
 
</style>
