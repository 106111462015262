<template>
  <div
    class="voter-third-third-party-transfer"
  >
    <w-timeline-element
      right
    >
      <template v-slot:avatar>
        <CompanyAvatar/>
      </template>

      <template v-slot:title>
        <div class="d-flex">
          <span
            class="text-truncate fb-14 c-primary"
          >
            {{ currentUserBrandName }}
          </span>
          <v-tooltip top>
            <template #activator="{on}">
              <span class="fb-14 c-primary" v-on="on">{{ createdAt }}</span>
            </template>
            {{ createdAtTooltip }}
          </v-tooltip>
        </div>
      </template>

      <template v-slot:content>
        <div
          class="d-flex pa-4 rounded-lg bg-palegrey"
        >
          <div
            class="d-flex flex-column ga-3"
            :style="{ width: '100%' }"
          >

            <div class="d-flex align-center ga-1">
              <v-icon class="f-16 c-primary">mdi-account</v-icon>
              <span class="c-primary fb-14">
                {{ title }}
              </span>
            </div>
            <pre
              v-if="transferDatas.transferComment"
              class="c-darkergrey f-14 content"
            >
             {{ transferDatas.transferComment }}
            </pre>

            <v-divider></v-divider>
            <div class="d-flex flex-row align-center">
              <div>
                <v-icon small>mdi-account</v-icon>
                <span class="f-12">
                  {{ voterTransfer.transferUserEmail }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </w-timeline-element>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CompanyAvatar from '../../CompanyAvatar'

  export default {
    name: "voterTransfer",
    props: {
      voterTransfer: { required: true },
      voter: { required: true }
    },
    components: {
      CompanyAvatar
    },
    computed: {
      ...mapGetters([
        'currentUserBrandName'
      ]),
      createdAt() {
        return this.$t('voter_timeline_element_created_at', { date: this.$helpers.dates.short(this.voterTransfer.createdAt, false) })
      },
      createdAtTooltip() {
        return this.$helpers.dates.short(this.voterTransfer.createdAt, true)
      },
      title() {
        return this.$t("feedbacks_voter_transfer_timeline_title", { groupName: this.voterTransfer.userGroupName })
      },
      transferDatas() {
        if (this.voterTransfer.type === 'VoterTransfer') {
          return {
            transferDate: this.voterTransfer.createdAt,
            transferComment: this.voterTransfer.comment
          }
        } else if (this.voterTransfer.type === 'Insatisfaction') {
          return {
            transferDate: this.voterTransfer.transferDate,
            transferComment: this.voterTransfer.transferComment
          }
        }
      }
    },
  }
</script>

<style lang='stylus'>
  .voter-transfer
    .v-badge .v-badge__wrapper
      margin-left: 0px
    .v-card__text
      padding-bottom: 8px !important

    .content
      white-space: pre-wrap;
</style>
