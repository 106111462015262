<template>
  <v-row>
    <v-col cols="6">
      <v-card class="px-4 pt-4">
        <div class="fs-16 c-primary">
          {{ $t('product_import_preference_history_page_history_title') }}
        </div>
        <WScheduledActionTraceTable
          :headers="headers"
          :key="infoLocalesColumnWidth"
          :items="productImportScheduledActionTraces"
          :paginateOptions="paginateOptions"
          :serverItemsLength="scheduledActionTraceCount"
          @paginate="paginate"
          class="mt-4"
        >
          <template #infoLocales="{ infoLocales }">
            <span v-for="infoLocale in infoLocales">
              <w-flag-icon
                :locale="infoLocale"
              />
            </span>
          </template>
        </WScheduledActionTraceTable>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

  export default {
    name: 'ProductImportPreferenceHistoryPage',
    props: {
      campaignId: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        paginateOptions: {
          page: 1,
          itemsPerPage: 15,
        }
      }
    },
    asyncComputed: {
      scheduledActionTraceCount: {
        async get() {
          let request = this.$basedRequest().select({
            product_import_scheduled_action_traces: [
              { 'COUNT_id': { as: 'totalCount' } }
            ]
          }).where({
            campaign_id: this.campaignId,
            status: ['ok', 'ko']
          })

          return (await this.$resolve(request))?.first()?.totalCount || 0
        },
        default: undefined
      },
      productImportScheduledActionTraces: {
        async get() {
          let request = this.$basedRequest().select({
            product_import_scheduled_action_traces: [
              { 'updated_at': { as: 'date'}},
              { 'status': { as: 'status'} },
              { 'info_locales': { as: 'infoLocales'}},
              { 'info_imported_products': { as: 'imported' }},
              { 'info_total_products': { as: 'total' }},
            ]
          }).where({
            campaign_id: this.campaignId,
            status: ['ok', 'ko']
          }).order(
            ['updated_at', 'desc']
          ).offset(
            (this.paginateOptions.page - 1) * this.paginateOptions.itemsPerPage)
          .limit(this.paginateOptions.itemsPerPage)

          return (await this.$resolve(request))?.data?.productImportScheduledActionTraces || []
        },
        default: undefined
      },
    },
    methods: {
      headerTitle(text) {
        return this.$t(`product_import_preference_history_page_${text}`)
      },
      paginate(paginateOptions) {
        this.paginateOptions = paginateOptions
      }
    },
    computed: {
      headers() {
        let headers = [
          { text: this.headerTitle('date'), value: 'date', width: '150px', sortable: false },
          { text: this.headerTitle('status'), value: 'status', sortable: false },
          { text: this.headerTitle('imported'), value: 'imported', sortable: false },
          { text: this.headerTitle('total'), value: 'total', align: 'end', sortable: false },
        ]

        if (this.$vuetify.breakpoint.smAndUp) {
          headers.unshift({ text: this.headerTitle('languages'), value: 'infoLocales', width: this.infoLocalesColumnWidth })
        }
        return headers
      },
      infoLocalesColumnWidth() {
        if (this.productImportScheduledActionTraces) {
          const maxNbLanguages = Math.max(...this.productImportScheduledActionTraces?.map(trace => trace.infoLocales.length))
          return `${(maxNbLanguages * 30) + 40}px`
        } else {
          return '100px'
        }
      }
    }
  }
</script>
