<template>
  <v-app-bar
    v-if="!$inIframe"
    color="primary"
    class="w-app-bar"
    clipped-left
    clipped-right
    dense
    dark
    flat
    app
  >
    <a class="d-none d-sm-inline logo-wizville" :class="'logo-' + $env" href="/dashboards/index?href=_self">
      <WIcon icon="wizville" :icon-style="{fontSize: '30px', color: '#fff'}"/>
    </a>

    <v-app-bar-nav-icon @click="toggleSidebar" v-if="$vuetify.breakpoint.xsOnly" />

    <v-toolbar-title
      v-if="$vuetify.breakpoint.xsOnly"
      :class="`app_bar_element_${$helpers.string.slugify($route.name)} ml-3`"
    >
      {{ $t(this.$route.meta.nameTranslationKey ? this.$route.meta.nameTranslationKey : this.$route.name) }}
    </v-toolbar-title>

    <DashboardsMenu v-if="!$vuetify.breakpoint.xsOnly" class="ml-3" />

    <EnvMenu v-if="$vuetify.breakpoint.mdAndUp" />

    <v-spacer></v-spacer>

    <div style="width: 350px" v-if="currentAdmin">
      <Autologin />
    </div>

    <div v-if="presenceManagementPreference && presenceManagementPreference.headerLink">
      <a class="c-white px-3" :onclick="`open('${presenceManagementPreference.url}', 'Popup', 'scrollbars=1,resizable=1,height=600,width=800'); return false;`">
        {{ presenceManagementPreference.name }}
      </a>
    </div>

    <company-menu  v-if="$vuetify.breakpoint.smAndUp && this.currentUser.isAdmin" />

    <UserMenu v-if="$vuetify.breakpoint.smAndUp" />
  </v-app-bar>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Autologin from '@admin/layouts/navigation/Autologin'
  import DashboardsMenu from './DashboardsMenu'
  import EnvMenu from './EnvMenu'
  import UserMenu from './UserMenu'
  import CompanyMenu from './CompanyMenu'

  export default {
    name: 'AppBar',
    components: { Autologin, DashboardsMenu, UserMenu, CompanyMenu, EnvMenu },
    data() {
      return {
        drawer: false
      }
    },
    methods: {
      toggleSidebar() {
        this.$store.dispatch('toggleShowSidebar');
      }
    },
    computed: {
      ...mapGetters([
        "currentUser",
        "currentAdmin",
        "currentDashboard",
        "presenceManagementPreference"
      ]),
      brandLogo() {
        if (this.currentDashboard.dashboardLogo) {
          return this.currentDashboard.dashboardLogo
        }
        return this.currentUser.userLogo
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .logo-wizville
    color: #fff
    height: 100%
    min-width: 56px
    background-color: grey
    text-align: center
    line-height: 46px
    display: inline-block
  .logo-dev
    background-color: red
  .logo-production
    background-color: #65DB96
  .logo-preprod
    background-color: orange
  .brand-logo
    float: left
    width: 46px
    display: inline-block


</style>
