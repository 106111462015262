<template>
  <v-tooltip
    top
    transition="none"
    :max-width="300"
  >
    <template v-slot:activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
        style="vertical-align:middle"
      >
        {{ title }}
      </span>
      <v-icon
        small
        class="ml-1"
        v-bind="attrs"
        v-on="on"
        style="vertical-align:middle"
      >
        mdi-information
      </v-icon>
    </template>
    <slot name="tooltip">
      <span>{{ tooltip }}</span>
    </slot>
  </v-tooltip>
</template>

<script>
  export default {
    name: "HeaderWithTooltip",
    props: {
      title: { type: String, required: true },
      tooltip: { type: String, required: false }
    }
  }
</script>
