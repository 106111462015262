<template>
  <CampaignAutocomplete
    :label="label"
    :campaignId="campaignId"
    :eligibleCampaigns="eligibleCampaigns"
    @change="onChange"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import CampaignAutocomplete from '../shared/CampaignAutocomplete'

export default {
  name: "ProductsCampaignAutocomplete",
  components: {
    CampaignAutocomplete
  },
  props: {
    label: { required: false },
    campaignId: { required: false },
    eligibleCampaigns: { type: Array, required: true }
  },
  methods: {
    onChange(campaignId) {
      this.$router.push(
        {
          name: 'Products',
          params: {
            mode: 'product_categories',
            campaignId: campaignId
          }
        }
      )
    }
  }
}
</script>
