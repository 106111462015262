<template>
  <WStatsWidget
    class="place-ranking-widget"
    height="200px"
    :cols="12"
    :title="title"
    :subtitle="displayedSubtitle"
    :loading="loading"
    :empty="!data || !data.rank"
  >
    <template #content>
      <w-position-widget
        :position="data.rank"
        :maxPosition="comparedGroup.placeIds.length"
        :evolution="data.evolution"
        :displayEvolution="true"
      />
    </template>
    <template #footer-left>
      <template v-if="comparedGroup.name">
        <span class="f-12">
          {{ $t('among') }}
        </span>
        <span class="fb-12">
          {{ translateNbPlaces }}
        </span>
        <span class="f-12">
          (<WTrunc :text="networkTranslation" :limit="20" />)
        </span>
      </template>
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { sqlDate } from '@vue/plugins/helpers/dates'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'

  export default {
    name: "PlaceRankingWidget",
    mixins: [
      WidgetMixin
    ],
    props: {
      title: { type: String },
      basedRequest: { required: true },
      indicatorColumn: { required: true },
      indicatorAlias: { required: true },
      campaign: { required: false }
    },
    data() {
      return {
        loading: true
      }
    },
    computed: {
      ...mapGetters([
        'groupRankingName',
        'groupRankingPlaceIds',
        'networkRankingName',
        'networkRankingPlaceIds',
        'dashboardFilterReady',
        'dashboardFilterRequest',
        'datesScope',
        'placeIdsScope',
        'currentDashboardScopedCampaigns',
        'dashboardFilterDates',
        'placeIds',
        'minimalNumberOfFeedbackForRanking',
        'currentLexicon'
      ]),
      comparedGroup() {
        return {
          name: this.groupRankingName || this.networkRankingName,
          placeIds: this.groupRankingPlaceIds || this.networkRankingPlaceIds
        }
      },
      translateNbPlaces() {
        return `${this.comparedGroup.placeIds.length} ${this.currentLexicon.translate('places', 'places')}` || this.$tc('nbPlace', this.comparedGroup.placeIds.length)
      },
      networkTranslation() {
        return this.comparedGroup?.name || this.$store.getters.currentLexicon.translate('network', 'network')
      }
    },
    methods: {
      sortItems(item1, item2) {
        if ((item1.nbReview === 0 || item1.nbReview < this.minimalNumberOfFeedbackForRanking) &&
            (item2.nbReview === 0 || item2.nbReview < this.minimalNumberOfFeedbackForRanking)) {
          return this.sortByRankingColumn(item1, item2)
        } else if (item1.nbReview === 0 || item1.nbReview < this.minimalNumberOfFeedbackForRanking) {
          return 1
        } else if (item2.nbReview === 0 || item2.nbReview < this.minimalNumberOfFeedbackForRanking) {
          return -1
        } else {
          return this.sortByRankingColumn(item1, item2)
        }
      },
      sortByRankingColumn(item1, item2) {
        if (item1[this.indicatorAlias] && item2[this.indicatorAlias]) {
          if (item1[this.indicatorAlias] == item2[this.indicatorAlias]) {
            return this.sortByNbReview(item1, item2)
          } else {
            return (Number(item1[this.indicatorAlias]) > Number(item2[this.indicatorAlias]) ? -1 : 1)
          }
        } else if (!item1[this.indicatorAlias] && !item2[this.indicatorAlias]){
          return this.sortByNbReview(item1, item2)
        } else if (!item1[this.indicatorAlias]) {
          return 1
        } else {
          return -1
        }
      },
      sortByNbReview(item1, item2) {
        if (item1.nbReview == item2.nbReview) {
          return item1['placeName'].localeCompare(item2['placeName'])
        } else {
          return (item1.nbReview > item2.nbReview ? -1 : 1)
        }
      },
      request({ dateBegin, dateEnd } = {}) {
        const placeIdsScope = this.comparedGroup.placeIds

        let request = this.basedRequest.dateBetween(
          sqlDate(dateBegin || this.datesScope.dateBegin),
          sqlDate(dateEnd || this.datesScope.dateEnd, '23:59:59')
        )
        .group([
          "place_campaigns_place_id"
        ])

        if (this.campaign) {
          request = request.where({ campaign_id: this.campaign.id })
        }

        if (placeIdsScope && placeIdsScope.length) {
          request = request.where({
            place_campaigns_place_id: placeIdsScope,
          })
        }

        return request
      },
    },
    asyncComputed: {
      data: {
        async get() {
          if (this.dashboardFilterReady) {
            this.loading = true

            const data =  (await this.$resolve(this.request()))?.data

            const comparedDataReq = this.request({
              dateBegin: this.datesScope.comparedDateBegin,
              dateEnd: this.datesScope.comparedDateEnd
            })
            const comparedData = (await this.$resolve(comparedDataReq))?.data

            const sortedData = Object.values(data).sort((item1, item2) => {
              return this.sortItems(item1, item2)
            })

            const sortedComparedData = Object.values(comparedData).sort((item1, item2) => {
              return this.sortItems(item1, item2)
            })

            const rank = sortedData.findIndex((place) =>
              place.placeId === this.placeIds[0]
            )

            const comparedRank = sortedComparedData.findIndex((place) =>
              place.placeId === this.placeIds[0]
            )

            this.loading = false
            return {
              rank: rank + 1,
              evolution: rank - comparedRank
            }
          }
        }
      }
    }
  }
</script>
