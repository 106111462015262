import Vue from 'vue'

const voterFloatInfo = {
  state: () => ({
    floatInfoFilters: {},
  }),
  getters: {
    dashboardVoterFloatInfoFilters(state) {
      return (Object.values(state.floatInfoFilters).length > 0 ? state.floatInfoFilters : null)
    },
    floatInfoFilters(state) {
      return state.floatInfoFilters
    }
  },
  mutations: {
    setDashboardVoterFloatInfoFilters(state, floatInfoFilters) {
      state.floatInfoFilters = floatInfoFilters
    },
    setDashboardVoterFloatInfoFilter(state, { filterKey, filterValues }) {
      if (!filterValues.min && !filterValues.max) {
        Vue.delete(state.floatInfoFilters, filterKey)
      } else {
        state.floatInfoFilters = { ...state.floatInfoFilters, ...{ [filterKey]: filterValues } }
      }
    },
  },
  actions: {
    setDashboardVoterFloatInfoFilters({ commit }, filters) {
      if (filters.voterFloatInfoFilters) {
        commit('setDashboardVoterFloatInfoFilters', filters.voterFloatInfoFilters)
      }
    },
    setDashboardVoterFloatInfoFilter({ commit }, { filterKey, filterValues }) {
      commit('setDashboardVoterFloatInfoFilter', { filterKey, filterValues })
    }
  },
}

export default voterFloatInfo
