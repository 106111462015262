<template>
  <div class="file-translations">
    <div class="d-flex">
      <v-btn @click="exportExcel" color="primary">Exporter les traductions</v-btn>
      <WFileReader @load="loadTextFromFile" label="Importer des traductions"  class="pl-3" />
    </div>

    <v-data-table
      :headers="translationsTableHeaders"
      :items="translationsTableItems"
      :loading="loading"
      class="mt-3 f-14"
      disable-pagination
      hide-default-footer
    >
      <template #item.actions="{ item }">
        <v-btn color="success" icon @click="updateTranslation(item)">
          <v-icon>mdi-check</v-icon>
        </v-btn>

        <v-btn color="error" icon @click="hideTranslation(item)">
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
      </template>

      <template #item.newValue="{ value }">
        <pre class="f-14">{{ value }}</pre>
      </template>

      <template #item.oldValue="{ value }">
        <pre class="f-14">{{ value }}</pre>
      </template>

      <template #item.master="{ value }">
        <pre class="f-14">{{ value }}</pre>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { exportToExcel, excelToHash } from '@shared/helpers/export-to-excel.js'
  import _mapKeys from 'lodash/mapKeys'
  import _sortBy from 'lodash/sortBy'
  import _cloneDeep from 'lodash/cloneDeep'

  export default {
    name: 'FileTranslations',
    props: {
      translations: { type: Object, required: true },
      campaignId: { type: Number, required: true },
      features: { type: Array, required: true },
      locales: { type: Array, required: true },
    },
    model: {
      prop: 'translations',
      event: 'updateTranslations'
    },
    data() {
      return {
        loading: false,
        translationsTableItems: [],
        translationsTableHeaders: [
          { text: 'Master', value: 'master' },
          { text: 'Id', value: 'key' },
          { text: 'Ancienne valeur', value: 'oldValue' },
          { text: 'Nouvelle valeur', value: 'newValue' },
          { text: 'Langue', value: 'locale' },
          { text: 'Actions', value: 'actions' }
        ],
      }
    },
    methods: {
      async updateTranslation(item) {
        try {
          // Send update request to API.
          const { key, newValue, locale } = item
          await this.$api.wizville.translationsAdmin.updateTranslation(key, newValue, locale)
          // Emit event to update translations.
          const translations = _cloneDeep(this.translations)
          translations[key].translations[locale] = newValue
          this.$emit('updateTranslations', translations)
          // Hide translation in table.
          this.hideTranslation(item)
          // Notify user.
          this.$store.dispatch(
            'notifySuccess',
            { timeout: 3000, message: `La version ${locale} de ${key} a bien été mise à jour` }
          )
        } catch (e) {
          this.$store.dispatch('notifyError', { message: e.message })
        }
      },
      hideTranslation({ id }) {
        this.translationsTableItems = this.translationsTableItems.filter(
          item => item.id != id
        )
      },
      async loadTextFromFile(e) {
        this.loading = true

        try {
          const sanitize = value => value?.replace(/(\r\n|\n|\r)/gm, '')
          const importedTranslations = (await excelToHash(e))?.translations || []
          const diff = []

          for (const row of importedTranslations) {
            row = _mapKeys(row, (_, key) => key.toLowerCase())
            const existingTranslation = this.translations[row.id]

            if (existingTranslation) {
              for (const locale of this.locales) {
                const oldValue = existingTranslation.translations[locale]
                const newValue = row[locale]

                if (newValue && sanitize(oldValue) !== sanitize(newValue)) {
                  diff.push({
                    id: `${row.id}/${locale}`,
                    key: row.id,
                    master: Object.values(existingTranslation.translations)[0],
                    locale: locale,
                    oldValue: oldValue,
                    newValue: newValue
                  })
                }
              }
            }
          }

          if (diff.length == 0) {
            this.$store.dispatch(
              'notifyInfo',
              { timeout: 5000, message: "Aucun changement n'a été détecté dans les traductions" }
            )
          } else {
            this.$store.dispatch(
              'notifySuccess',
              { timeout: 5000, message: "Votre fichier a bien été chargé, utilisez le tableau pour valider ou non les changements" }
            )
          }

          this.translationsTableItems = diff
        } catch (e) {
          this.$store.dispatch(
            'notifyError',
            { timeout: 5000, message: `Impossible de charger le fichier: ${e}` }
          )
        }

        this.loading = false
      },
      exportExcel() {
        const translations = Object.values(this.translations)
        const maxPath = Math.max(...translations.map(t => t.path?.length || 0))
        const levels = Array.from([maxPath]).map((_, i) => `path ${i} (optional)`)
        const headersText = [...['id'], ...this.locales, ...levels]
        const headersKeys = [...['id'], ...this.locales, ...levels]
        const rows = []

        for (const translation of _sortBy(translations, t => t.path || [])) {
          const row = {
            id: translation.id,
            path: translation.path?.join(" > "),
          }

          for (const locale of this.locales) {
            row[locale] = translation.translations[locale] || ""
          }

          for (const [index, level] of levels.entries()) {
            row[level] = (translation.path ? translation.path[index] : "")
          }

          rows.push(row)
        }

        exportToExcel(
          `${this.features.join('_and_')}_translations_for_campaign_${this.campaignId}`,
          'translations',
          rows,
          headersText,
          headersKeys,
          { minWidth: 50 }
        )
      }
    }
  }
</script>
