<template>
  <div class="openai-preferences-prompt-first-paragraph mt-4">
    <div class="d-flex align-center">
      <p class="fb-14 mb-0">
        {{ $t('openai_preferences_help_section_first_paragraph_title') }}
      </p>
      <a
        class="text-decoration-underline c-dark ml-auto"
        @click="reset"
      >
        {{ $t('openai_preferences_prompt_first_paragraph_reset') }}
      </a>
    </div>
    <v-textarea
      v-model="promptFirstParagraph"
      class="prompt-first-paragraph-textarea f-14"
      :rules="[$required]"
      :hide-details="true"
      name="content"
      dense
      filled
      no-resize
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'PromptFirstParagraph',
    components: {
    },
    props: {
      value: { type: String, required: true },
      defaultPromptFirstParagraph: { type: String, required: true }
    },
    computed: {
      ...mapGetters([
      ]),
      promptFirstParagraph: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit("input", val)
        }
      }
    },
    asyncComputed: {
    },
    methods: {
      reset() {
        this.promptFirstParagraph = this.defaultPromptFirstParagraph
      }
    }
  }
</script>
