<template>
  <v-row class="verbatim-page pl-3" no-gutters>
    <v-col cols="12">
      <v-row no-gutters >
        <v-col cols="12">
          <div class="d-flex pb-3">
            <StatisticsCampaignAutocomplete
              class="mr-3"
              :campaignId="campaignId"
              :eligibleCampaigns="eligibleCampaigns"
              :label="capitalize($t('campaign'))"
              v-if="eligibleCampaigns.length > 1"
            />
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <TopicsVerbatimThemesWidget
          :key="campaign?.id"
          :campaign="campaign"
          :formatedTopics="formatedTopics"
          :themeById="themeById"
          :loading="!themeAndTopicReady"
          :zoomedData="zoomedData"
        />
      </v-row>

      <v-row no-gutters class="mb-3" v-if="zoomedData">
        <v-col cols="12">
          <div class="d-flex">
            <WAutocomplete
              :value="theme ? themeOrTopic : `theme${this.zoomedData.theme.id}`"
              :items="themeOptions"
              @change="onZoomedChange"
            />

            <WAutocomplete
              :value="theme ? 'allTopics' : themeOrTopic"
              :items="topicOptions"
              @change="onZoomedChange"
              class="ml-3"
            />
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters v-if="zoomedData">
        <v-col cols="12">
          <VerbatimZoom
            class="pt-0"
            :key="`${zoomedData?.id}_${campaign?.id}`"
            :campaign="campaign"
            :zoomedData="zoomedData"
            :groupZoomedData="groupZoomedData"
            :networkZoomedData="networkZoomedData"
            :widgetSubtitle="widgetSubtitle"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import TopicSemanticMixin from '@statistics/shared/topic_semantic_mixin'
  import TopicsVerbatimThemesWidget from "./TopicsVerbatimThemesWidget"
  import StatisticsCampaignAutocomplete from '@statistics/shared/StatisticsCampaignAutocomplete'
  import VerbatimZoom from './VerbatimZoom'
  import { capitalize } from 'inflection'
  import { mapGetters } from 'vuex'
  import _sortBy from 'lodash/sortBy'

  export default {
    name: "VerbatimPage",
    props: [ 'campaignId', 'themeOrTopic' ],
    components: {
      TopicsVerbatimThemesWidget,
      StatisticsCampaignAutocomplete,
      VerbatimZoom
    },
    mixins: [
      TopicSemanticMixin
    ],
    computed: {
      zoomedData() {
        return this.getZoomedData(this.formatedTopics, this.themeById, this.theme, this.topic)
      },
      groupZoomedData() {
        if (!this.hasAccessToAllDashboardPlaces && this.displayGroupRanking) {
          return this.getZoomedData(this.groupTopics, this.groupThemesById, this.theme, this.topic)
        }
      },
      networkZoomedData() {
        if (!this.hasAccessToAllDashboardPlaces) {
          return this.getZoomedData(this.networkTopics, this.networkThemesById, this.theme, this.topic)
        }
      },
      ...mapGetters([
        'currentDashboardScopedCampaigns',
        'currentDashboardCampaignWithId',
        'dashboardFilterDates',
        'isDashboardMono'
      ]),
      eligibleCampaigns() {
        return this.currentDashboardScopedCampaigns.filter((campaign) => campaign.hasVerbatimAnalysisEnabled || campaign.hasTextAnalysisEnabled)
      },
      campaign() {
        return (this.isDashboardMono ? this.eligibleCampaigns[0] : this.currentDashboardCampaignWithId(this.campaignId))
      },
      widgetSubtitle() {
        return this.eligibleCampaigns.length > 1 && this.campaign ?
          `${this.dashboardFilterDates.text} - ${this.campaign.name}` :
          this.dashboardFilterDates.text
      },
      theme() {
        if (this.themeOrTopic.match("theme")) {
          return { id: Number(this.themeOrTopic.replace("theme", "")) }
        }
      },
      topic() {
        if (this.themeOrTopic.match("topic")) {
          return {id:  Number(this.themeOrTopic.replace("topic", "")) }
        }
      },
      themeOptions() {
        const themes = Object.values(this.themeById)?.map(theme => {
          return {
            text: this.capitalize(theme.name),
            value: `theme${theme.id}`
          }
        })

        return _sortBy(themes, theme => theme.text.normalize("NFD")?.replace(/\p{Diacritic}/gu, ""))
      },
      topicOptions() {
        const topics = this.formatedTopics.filter(
          topic => [this.theme?.id, this.zoomedData?.theme?.id].includes(topic.theme.id)
        )?.map(topic => {
          return {
            text: this.capitalize(topic.topic.name),
            value: `topic${topic.topic.id}`
          }
        })

        return [
          ...[{ text: this.$t('all_topics'), value: 'allTopics' }],
          ..._sortBy(topics, topic => topic.text.normalize("NFD")?.replace(/\p{Diacritic}/gu, ""))
        ]
      }
    },
    watch: {
      themeById(themes) {
        if (themes) {
          const mainTheme = Object.values(themes).find(row => row.themeFeelingRank === 0)

          if (this.themeOrTopic == 'all' && mainTheme) {
            this.$router.push({ params: { sectionId: `theme${mainTheme.id}` }})
          }
        }
      }
    },
    methods: {
      capitalize,
      onZoomedChange(themeOrTopic) {
        if (themeOrTopic == 'allTopics') {
          this.$router.push({ params: { sectionId: `theme${this.zoomedData.theme.id}` }})
        } else {
          this.$router.push({ params: { sectionId: themeOrTopic }})
        }
      }
    }
  }
</script>
