<template>
  <WDataTable
    v-if="newTaxonomy"
    :headers="headers"
    :items="newTaxonomy"
    :groupBy="groupBy"
    :height="tableHeight"
    :hideDefaultFooter="true"
    :disablePagination="true"
    :scrollable="true"
    :fixedHeader="true"
    :draggable="true"
    @toggleGroup="dirtyResizeTableHeight"
    @draggableOnEndCallback="draggableOnEndCallback"
    :softReload="true"
    dragAndDropClass="handle-drag"
    class="elevation-1 taxonomy-table c-primary"
    ref="taxonomyTable"
  >
    <template #headerGroupItem.topicPublicName="{ groupBy }">
      <v-text-field
        hide-details
        @blur="(event) => updateTaxonomyByThemeId(event, groupBy, 'themePublicName')"
        :value="newTaxonomyTopicFirstByThemeId[groupBy].themePublicName"
        dense
        class="f-13 flex-grow-1"
      />
    </template>
    <template #headerGroupItem.topicName="{ groupBy }">
      <v-text-field
        hide-details
        @blur="(event) => updateTaxonomyByThemeId(event, groupBy, 'themeName')"
        :value="newTaxonomyTopicFirstByThemeId[groupBy].themeName"
        dense
        class="f-13 flex-grow-1"
      />
    </template>
    <template #headerGroupItem.actions="{ groupBy }">
      <v-btn :icon="true" color='primary' @click="deleteTheme(newTaxonomyTopicFirstByThemeId[groupBy].themeId)">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </template>

    <template #item.topicPublicName="{ item }">
      <v-text-field
        hide-details
        v-model="newTaxonomyByTopicId[item.topicId].topicPublicName" 
        validate-on-blur
        dense
        class="f-13 flex-grow-1"
      />
    </template>
    <template #item.topicName="{ item }">
      <v-text-field
        hide-details
        v-model="newTaxonomyByTopicId[item.topicId].topicName"
        validate-on-blur
        dense
        class="f-13"
      />
    </template>
    <template #item.actions="{ item }">
      <div class="d-flex justify-end">
        <v-btn :icon="true" color='primary' class="mr-2">
          <v-icon small style="cursor: grabbing" class="handle-drag c-primary">mdi-arrow-split-horizontal</v-icon>
        </v-btn>
        <v-btn :icon="true" color='primary' @click="deleteTopic(item.topicId)">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </div>
    </template>

    <template #body.append>
      <tr :style="{ position: 'sticky', bottom: '0px' }">
        <td colspan="4" :style="{background: 'white', borderTop: '1px solid rgba(0, 0, 0, 0.12)' }">
          <div class="my-2">
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center">
                <v-btn
                  :color="taxonomyEdition == 'addTopic' ? 'secondary' : 'primary'"
                  :icon="taxonomyEdition != 'addTopic'"
                  class="v-btn-square mr-2"
                  @click="zoomOnTaxonomyEdition('addTopic')"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      :color="taxonomyEdition == 'loadTaxonomy' ? 'secondary' : 'primary'"
                      :icon="taxonomyEdition != 'loadTaxonomy'"
                      class="v-btn-square mr-2"
                      @click="zoomOnTaxonomyEdition('loadTaxonomy')"
                    >
                      <v-icon>mdi-lightning-bolt-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('textAnalysisPreferenceTaxonomyTableReferenceTaxonomyUpdateTaxonomyTooltip') }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      :color="taxonomyEdition == 'saveTaxonomyTemplate' ? 'secondary' : 'primary'"
                      :icon="taxonomyEdition != 'saveTaxonomyTemplate'"
                      class="v-btn-square mr-2"
                      @click="zoomOnTaxonomyEdition('saveTaxonomyTemplate')"
                    >
                      <v-icon>mdi-heart-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('textAnalysisPreferenceTaxonomyTableReferenceTaxonomyMakePublicTooltip') }}</span>
                </v-tooltip>
              </div>
              <div v-show="taxonomyEdition == 'loadTaxonomy'">
                <WAutocomplete
                  :items="taxonomyOptions"
                  v-model="newTaxonomyName"
                  item-text="text"
                  item-value="value"
                  :label="$t('textAnalysisPreferenceTaxonomyTableReferenceTaxonomy')"
                  @change="onChangeReferenceTaxonomy"
                  dense
                />
              </div>
            </div>
            <div v-if="taxonomyEdition == 'addTopic'" class="d-flex justify-space-between mt-4">
              <div class="d-flex">
                <WAutocomplete
                  :items="themeOptions"
                  v-model="newThemeTopic.themeId"
                  item-text="themePublicName"
                  item-value="themeId"
                  :label="themeOptions[0].themePublicName"
                  @change="onChangeTheme"
                  class="f-13 mr-2"
                  dense
                />
                <v-text-field
                  v-if="isNewTheme"
                  hide-details
                  :label="$t('textAnalysisPreferenceTaxonomyTableWizvilleTheme')"
                  v-model="newThemeTopic.themePublicName" 
                  dense
                  class="f-13 mr-1"
                />
                <v-text-field
                  hide-details
                  :label="$t('textAnalysisPreferenceTaxonomyTableWizvilleLabel')"
                  v-model="newThemeTopic.topicPublicName" 
                  dense
                  class="f-13 mr-1"
                />
                <v-btn
                  :icon="true"
                  color='primary'
                  class="mr-5"
                  @click="addTopic"
                  :disabled="!submitableThemeTopic"
                >
                  <v-icon class="c-primary">mdi-playlist-plus</v-icon>
                </v-btn>
              </div>
            </div>
            <div v-if="taxonomyEdition == 'saveTaxonomyTemplate'" class="d-flex justify-space-between mt-4">
              <div class="d-flex">
                <v-text-field
                  :label="$t('textAnalysisPreferenceTaxonomyTableReferenceTaxonomyTemplateNameLabel')"
                  @blur="updateTextAnalysisPreferenceTemplate"
                  :value="textAnalysisPreferenceTemplate.name"
                  dense
                  hide-details
                  class="f-13 mr-1 input-text-width"
                />
                <v-switch
                  v-model="textAnalysisPreferenceTemplate.isPublic"
                  :label="(
                    textAnalysisPreferenceTemplate.isPublic ?
                    $t('textAnalysisPreferenceTaxonomyTableReferenceTaxonomySwitchPublic') :
                    $t('textAnalysisPreferenceTaxonomyTableReferenceTaxonomySwitchPrivate')
                  )"
                  @change="saveTaxonomyTemplate"
                  class="mt-0"
                  hide-details
                />
              </div>
            </div>
          </div>
        </td>
      </tr>
    </template>
  </WDataTable>
</template>

<script>
import _cloneDeep from "lodash/cloneDeep"
import _values from "lodash/values"
import _flatten from "lodash/flatten"
import { mapGetters } from 'vuex'

export default {
  name: "TaxonomyTable",
  components: {},
  props: {
    taxonomy: { required: false },
    groupBy: { type: String, required: true },
    taxonomyOptions: { type: Array, required: false },
    taxonomyName: { type: String, required: false },
  },
  // change the default name 'value' of v-model to taxonomy
  model: {
      prop: 'taxonomy',
      event: 'updateTaxonomy'
  },
  data() {
    return {
      headers: [
        { 
          text: this.$t('textAnalysisPreferenceTaxonomyTableWizvilleLabels'),
          value: 'topicPublicName', sortable: false
        },
        { text: this.$t('textAnalysisPreferenceTaxonomyTableGptLabels'),
          value: 'topicName',
          sortable: false
        },
        {
          text: this.$t('textAnalysisPreferenceTaxonomyTableActions'),
          value: 'actions', align: 'right', width: '60px', sortable: false
        }
      ],
      newTaxonomy: null,
      newThemeTopic: {
        themeId: -1,
      },
      newTopic: null,
      newTaxonomyName: null,
      taxonomyEdition: null,
      tableHeight: '0',
      reloadWDataTableItems: 0,
      textAnalysisPreferenceTemplate: {
        name: null,
        isPublic: false
      },
      savingTaxonomyTemplate: false,
    }
  },
  asyncComputed: {
    currentTemplate: {
      async get(){
        const request = this.$basedRequest().select({
          text_analysis_preferences: [
            { 'text_analysis_preference_templates_name': { as: 'name'} },
            { 'text_analysis_preference_templates_is_public': { as: 'isPublic'} }
          ]
        }).where({
          campaign_id: this.currentCampaignPreferencesCampaignId
        })
        let taxonomyNameResult = (await this.$resolve(request))?.first() || { name: null, isPublic: false }
        this.textAnalysisPreferenceTemplate = taxonomyNameResult
      },
    }
  },
  computed: {
    ...mapGetters([
      'currentCampaignPreferencesCampaignId'
    ]),
    newTaxonomyByTopicId() {
      return this.newTaxonomy?.reduce((h, row) => {
        h[row.topicId] = row
        return h
      }, {})
    },
    newTaxonomyByThemeId() {
      return this.newTaxonomy?.reduce((h, row) => {
        if (!h[row.themeId]) {
          h[row.themeId] = []
        }
        h[row.themeId].push(row)
        return h
      }, {})
    },
    newTaxonomyTopicFirstByThemeId() {
      return this.newTaxonomy?.reduce((h, row) => {
        if (!h[row.themeId]) {
          h[row.themeId] = row
        }
        return h
      }, {})
    },
    themeOptions() {
      let themeOptions = Object.keys(this.newTaxonomyTopicFirstByThemeId).map(themeId => {
        return {
          themePublicName: this.newTaxonomyTopicFirstByThemeId[themeId].themePublicName,
          themeId: themeId
        }
      })
      themeOptions.unshift({
        themePublicName: this.$t('textAnalysisPreferenceTaxonomyTableNewTheme'),
        themeId: -1
      })
      return themeOptions
    },
    submitableThemeTopic() {
      return (
        this.newThemeTopic.themePublicName &&
        this.newThemeTopic.topicPublicName
      )
    },
    isNewTheme() {
      return !this.newTaxonomyTopicFirstByThemeId[this.newThemeTopic.themeId]
    }
  },
  methods: {
    async saveTaxonomyTemplate() {
      this.savingTaxonomyTemplate = true
      if (this.textAnalysisPreferenceTemplate.name) {
        try {
          const response = await this.$api.wizville.textAnalysis.editTextAnalysisPreferenceTemplates(
            this.currentCampaignPreferencesCampaignId,
            this.textAnalysisPreferenceTemplate
          )
          if (response.status === 'ok') {
            this.$emit('updateReloadTaxonomies')
            if (this.textAnalysisPreferenceTemplate.isPublic) {
              this.$store.dispatch('notifySuccess',
                { message: response.message }
              )
            }
          } else {
            this.textAnalysisPreferenceTemplate.isPublic = false
            this.$store.dispatch('notifyError', {
              message: response.message
            })
          }
        } catch (e) {
          this.$store.dispatch('notifyError', {
            message: e.message
          })
        }
      }
      this.savingTaxonomyTemplate = false
    },
    updateTextAnalysisPreferenceTemplate(event) {
      const textAnalysisPreferenceTemplateName = event.target.value

      if (
        textAnalysisPreferenceTemplateName &&
        textAnalysisPreferenceTemplateName !== this.textAnalysisPreferenceTemplate.name
      ) {
        this.textAnalysisPreferenceTemplate.name = textAnalysisPreferenceTemplateName
        this.saveTaxonomyTemplate()
      }
    },
    // Since we use a fix height to be able to scroll inside the table,
    // we need to resize the table when the content change (deletion, addition, dropdown, etc.)
    dirtyResizeTableHeight() {
      this.$nextTick(() => {
        const tBodyHeight = this.$refs.taxonomyTable.$refs.tBody.clientHeight
        const headerTableHeight = this.$refs.taxonomyTable.$el.querySelector('.v-data-table-header').offsetHeight
        const heightTaxonomyTableTbody = tBodyHeight + headerTableHeight
        const windowHeight = window.innerHeight - 70
        if (heightTaxonomyTableTbody < windowHeight) {
          this.tableHeight = heightTaxonomyTableTbody.toString()
        } else {
          this.tableHeight = windowHeight.toString()
        }
      })
    }, 
    zoomOnTaxonomyEdition(taxonomyEdition) {
      this.taxonomyEdition = this.taxonomyEdition === taxonomyEdition ? null : taxonomyEdition
      this.dirtyResizeTableHeight()
    },
    draggableOnEndCallback(movedItem, destinationItem, destinationIndex) {
      movedItem.themeName = destinationItem.themeName
      movedItem.themePublicName = destinationItem.themePublicName
      movedItem.themeId = destinationItem.themeId

      this.newTaxonomyByTopicId[movedItem.topicId] = movedItem
      this.newTaxonomy = _flatten(Object.values(this.newTaxonomyByTopicId))
    },
    updateTaxonomyByThemeId(event, themeId, themeType) {
      const newThemeName = event.target.value

      if (newThemeName) {
        this.newTaxonomyByThemeId[themeId].forEach((item) => {
          item[themeType] = newThemeName
        })
  
        this.newTaxonomy = _flatten(Object.values(this.newTaxonomyByThemeId))
      }
    },
    deleteTopic(topicId) {
      this.newTaxonomy = this.newTaxonomy.filter(item => item.topicId != topicId)
      this.dirtyResizeTableHeight()
      this.$store.dispatch('notifySuccess', {
        message: this.$t('textAnalysisPreferenceTaxonomyTableDeletedTopicMessage'),
        timeout: 1000
      })
    },
    deleteTheme(themeId) {
      this.newTaxonomy = this.newTaxonomy.filter(item => item.themeId != themeId)
      this.dirtyResizeTableHeight()
      this.$store.dispatch('notifySuccess', {
        message: this.$t('textAnalysisPreferenceTaxonomyTableDeleteThemeMessage'),
        timeout: 1000
      })
    },
    addTopic() {
      if (this.submitableThemeTopic) {
        const clonedNewTaxonomy = _cloneDeep(this.newTaxonomy)
        if (this.newThemeTopic.themeId == -1) {
          this.newThemeTopic.themeId = this.randomId()
        }
        this.newThemeTopic.topicId = this.randomId()

        const themeName = this.newTaxonomyTopicFirstByThemeId[this.newThemeTopic.themeId]?.themeName
        this.newThemeTopic.themeName = themeName ?? this.newThemeTopic.themePublicName
        this.newThemeTopic.topicName = this.newThemeTopic.topicPublicName.toUpperCase()

        clonedNewTaxonomy.push(this.newThemeTopic)
        const clonedNewThemeTopic = _cloneDeep(this.newThemeTopic)

        this.newTaxonomy = clonedNewTaxonomy
        this.newThemeTopic = clonedNewThemeTopic

        this.dirtyResizeTableHeight()
        this.$store.dispatch('notifySuccess', {
          message: this.$t('textAnalysisPreferenceTaxonomyTableAddedTopicMessage'),
          timeout: 1000
        })
      }
    },
    onChangeTheme(themeId) {
      this.newThemeTopic.themeId = themeId
      if (this.newTaxonomyTopicFirstByThemeId[themeId]) {
        this.newThemeTopic.themeName = this.newTaxonomyTopicFirstByThemeId[themeId].themeName
        this.newThemeTopic.themePublicName = this.newTaxonomyTopicFirstByThemeId[themeId].themePublicName
      } else {
        this.newThemeTopic.themeName = ''
        this.newThemeTopic.themePublicName = ''
      }

      this.dirtyResizeTableHeight()
    },
    onChangeReferenceTaxonomy(taxonomyName) {
      this.$emit('updateReferenceTaxonomy', taxonomyName)
      this.$emit('updateReloadTaxonomies')

      this.resetNewThemeTopic()
      this.dirtyResizeTableHeight()
    },
    randomId() {
      return `new-id-${Math.floor(Math.random() * 100000)}`
    },
    handleVModelInput(value) {
      this.$emit('updateTaxonomy', value)
    },
    reloadWDataTable() {
      this.reloadWDataTableItems += 1
    },
    resetNewThemeTopic() { 
      this.newThemeTopic =  {
        themeId: -1,
        themeName: '',
        themePublicName: '',
        topicName: '',
        topicPublicName: '' 
      }
    }
  },
  mounted () {
    this.newTaxonomy = this.taxonomy
    this.currentTemplate
    this.dirtyResizeTableHeight()
  },
  watch: {
    taxonomy: {
      handler: function (val) {
        this.newTaxonomy = val
      },
      deep: true
    },
    taxonomyName: {
      handler: function (val) {
        this.newTaxonomyName = val
      }
    },
    newTaxonomy: function(val) {
      this.handleVModelInput(val)
    }
  }
};
</script>

<style lang="stylus" scoped>
  .taxonomy-table
    ::v-deep .v-label
      font-size: 13px;
</style>
