<template>
  <div class="qcm-question-other-table text-align-left">
    <template v-if="!loading">
      <template v-if="otherValues.length > 0">
        <h1 class="fs-12">
          {{ $t('other_choice_title') }}
        </h1>

        <v-virtual-scroll
          :bench="benched"
          :items="otherValues"
          height="smartHeight"
          item-height="42"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item" class="pl-0">
              <v-list-item-content>
                <v-list-item-title class="f-13">
                  <w-icon icon='quote-left' :icon-style="{ fontSize: '13px', color: 'grey' }" />
                  <span class="px-1">{{ item }}</span>
                  <w-icon icon='quote-right' :icon-style="{ fontSize: '13px', color: 'grey' }" />
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </template>
    </template>
    <v-skeleton-loader v-else type="paragraph" class="mt-3" />
  </div>
</template>

<script>
  import { mapGetters }from 'vuex'

  export default {
    name: "QcmQuestionOtherTable",
    props: [ 'question' ],
    data() {
      return {
        benched: 10,
        perPage: 1000,
        loading: true
      }
    },
    methods: {
      async exportToPdf(pdf) {
        const quoteYOffset = -3
        const nameWidth = '5'
        await pdf.addRow({ height: 15, marginTop: 20 }, async(row) => {
          await row.addCol({ width: nameWidth }, async(col) => {
            col.addText(this.$t('other_choice_title'), { fontSize: 12, font: { style: 'bold' } })
          })
        })
        await pdf.addRow({ height: 5 })

        if (this.otherValues.length > 0) {
          await this.otherValues.reduce(async (promise, otherValue) => {
            await promise
            const text = pdf.doc.splitTextToSize(otherValue, 700)
            await pdf.addRow({ height: 20 * text.length, marginTop: 10 }, async(row) => {
              await row.addCol({ width: 700 }, async(col) => {
                col.addText(`"${otherValue}"`, { overflow: 'linebreak', fontSize: 11 })
              })
            })
          }, undefined)
        } else {
          await pdf.addRow({ height: 15, marginTop: 10 }, async(row) => {
            await row.addCol({ width: 15 }, async(col) => {
              await col.addIcon('mdiSkullCrossbones', { height: 20, align: 'left', color:'grey', yOffset:quoteYOffset })
            })
          })
        }



      },
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest'
      ]),
      smartHeight() {
        return (this.otherValues && this.otherValues.length < 5 ? this.otherValues.length * 42 : 210)
      }
    },
    asyncComputed: {
      otherValues: {
        async get() {
          this.loading = true
          const request = this.dashboardFilterRequest.select({
            voters: [ { question_comment_answers_texts_: { question_id: this.question.id, choice_id: 1, as: "value" } } ]
          }).limit(this.perPage).order(['created_at', 'desc'])

          let otherValues = (await this.$resolve(request))?.data?.voters?.map(v => v.value.trim()).filter(e => e !== '')
          this.loading = false

          return otherValues
        },
        default: []
      }
    }
  }
</script>

<style lang="stylus" scoped>
</style>
