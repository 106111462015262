<template>
  <v-menu
    v-if="activator"
    content-class="topics-verbatim-classification-menu"
    :activator="activator"
    :min-width="350"
    :close-on-content-click="false"
    bottom
    offset-y
    v-model="open"
  >
    <v-list
      class="py-0"
      dense
    >
      <v-col class="pa-0">
        <v-text-field
          v-model="debouncedSearch"
          :placeholder="capitalize($t('search'))"
          prepend-inner-icon="mdi-magnify"
          autofocus
          outlined
          dense
          hide-details
        />
      </v-col>
      <v-progress-linear
        v-if="topicsVerbatims === undefined || topics === undefined"
        indeterminate
        color="primary"
      />
      <v-list-item
        v-else
        v-for="topicsVerbatim in topicsVerbatims"
        :key="topicsVerbatim.id"
        dense
      >
        <div class="d-flex align-center flex-grow-1">
          <span class="text-truncate" :style="{ width: '200px' }">{{ _upperFirst(topicsVerbatim.topicName) }}</span>
          <div class="ml-auto">
            <v-icon
              class="pointer"
              color="success"
              @click="updateOrDeleteTopicsVerbatim(topicsVerbatim, 0)">
                {{ topicsVerbatim.feeling == 0 ? 'mdi-emoticon-happy' : 'mdi-emoticon-happy-outline' }}
            </v-icon>
            <v-icon
              class="pointer ml-1"
              color="lightgrey"
              @click="updateOrDeleteTopicsVerbatim(topicsVerbatim, 1)">
                {{ topicsVerbatim.feeling == 1 ? 'mdi-emoticon-neutral' : 'mdi-emoticon-neutral-outline' }}
            </v-icon>
            <v-icon
              class="pointer ml-1"
              color="error"
              @click="updateOrDeleteTopicsVerbatim(topicsVerbatim, 2)">
                {{ topicsVerbatim.feeling == 2 ? 'mdi-emoticon-sad' : 'mdi-emoticon-sad-outline' }}
            </v-icon>
          </div>
        </div>
      </v-list-item>
      <div class="bg-lightergrey sub-list" :style="{ borderTop: 'thin solid rgba(0, 0, 0, 0.12)' }">
        <v-subheader class="px-4">{{ $t('topics_verbatim_classification_menu_not_select_topics').toUpperCase() }}</v-subheader>
        <v-list-item
          v-for="(notSelectedTopic) in notSelectedTopics"
          :key="notSelectedTopic.topicId"
          dense
        >
          <div class="d-flex align-center flex-grow-1">
            <span class="text-truncate" :style="{ width: '200px' }">{{ _upperFirst(notSelectedTopic.topicName) }}</span>
            <div class="ml-auto" :style="{ opacity: 0.4 }">
              <v-icon class="pointer"
                color="success"
                @click="createTopicsVerbatim(notSelectedTopic, 0)">
                  {{ 'mdi-emoticon-happy-outline' }}
              </v-icon>
              <v-icon
                class="pointer ml-1"
                color="lightgrey"
                @click="createTopicsVerbatim(notSelectedTopic, 1)">
                  {{ 'mdi-emoticon-neutral-outline' }}
              </v-icon>
              <v-icon
                class="pointer ml-1"
                color="error"
                @click="createTopicsVerbatim(notSelectedTopic, 2)">
                  {{ 'mdi-emoticon-sad-outline' }}
              </v-icon>
            </div>
          </div>
        </v-list-item>
        </div>
    </v-list>
  </v-menu>
</template>

<script>
  import { capitalize } from 'inflection'
  import _debounce from 'lodash/debounce'
  import _orderBy from "lodash/orderBy"
  import _upperFirst from 'lodash/upperFirst'
  import _cloneDeep from 'lodash/cloneDeep'

  export default {
    name: "TopicsVerbatimClassificationMenu",
    components: {
    },
    props: {
      verbatimId: {
        required: true
      },
      topics: {
        required: false
      },
      menuActivatorId: {
        required: false
      },
    },
    data () {
      return {
        loading: true,
        search: null,
        open: false,
        activator: undefined,
        topicsVerbatims: undefined
      }
    },
    computed: {
      debouncedSearch: {
        get() {
          return this.search
        },
        set: _debounce(function (val) {
          this.search = val
        }, 200),
      },
      notSelectedTopics() {
        if (!this.topicsVerbatims) return []

        let regex = new RegExp(
          this.search,
          'i'
        )

        let notSelectedTopics = this.topics?.filter(topic => !this.topicsVerbatims.find(
            topicsVerbatim => topicsVerbatim.topicId === topic.topicId
          )
        ) || []

        notSelectedTopics = _orderBy(notSelectedTopics, ['topicName'], ['asc'])
        if (this.search) {
          return notSelectedTopics?.filter(topic => topic.topicName.match(regex))
        } else {
          return notSelectedTopics
        }
      },
    },
    methods: {
      capitalize,
      _upperFirst,
      async fetchTopicsVerbatims() {
        let request = this.$basedRequest().select({
          topics_verbatims: [
            'id',
            'feeling',
            { 'text_analysis_topics_id': { as: 'topicId' } },
            { 'topic_public_name': { as: 'topicName' } }
          ]
        }).where({
          verbatim_id: this.verbatimId
        }).order(['topic_public_name', 'asc'])

        let topicsVerbatimResult = (await this.$resolve(request)).data?.topicsVerbatims || []
        return topicsVerbatimResult
      },
      async createTopicsVerbatim(topic, feeling) {
        try {
          const response = await this.$api.wizville.topicsVerbatims.create(
            {
              text_analysis_topic_id: topic.topicId,
              verbatim_id: this.verbatimId,
              feeling
            }
          )

          if (response.status === "ok") {
            const topicsVerbatimFromResponse = this.formatVerbatim(response)
            this.createTopicsVerbatimsClassificationMenu(topicsVerbatimFromResponse)
            this.$emit('createTopicsVerbatims', topicsVerbatimFromResponse)
          } else {
            this.$store.dispatch('notifyError', { message: response.message })
          }
        } catch (error) {
          this.$store.dispatch('notifyError', { message: error.message })
        }
      },
      async updateOrDeleteTopicsVerbatim(topicsVerbatim, feeling) {
        const newFeeling = topicsVerbatim.feeling === feeling ? null : feeling

        if (newFeeling !== null) {
          await this.updateTopicsVerbatim(topicsVerbatim, feeling)
        } else {
          await this.deleteTopicsVerbatim(topicsVerbatim)
        }
      },
      async updateTopicsVerbatim(topicsVerbatim, feeling) {
        try {
          const response = await this.$api.wizville.topicsVerbatims.update(
            topicsVerbatim.id,
            { feeling }
          )

          if (response.status === "ok") {
            const topicsVerbatimFromResponse = this.formatVerbatim(response)
            this.updateTopicsVerbatimsClassificationMenu(topicsVerbatimFromResponse)
            this.$emit('updateOrDeleteTopicsVerbatims', topicsVerbatimFromResponse)

          } else {
            this.$store.dispatch('notifyError', { message: response.message })
          }
        } catch (error) {
          this.$store.dispatch('notifyError', { message: error.message })
        }
      },
      async deleteTopicsVerbatim(topicsVerbatim) {
        try {
          const response = await this.$api.wizville.topicsVerbatims.destroy(
            topicsVerbatim.id
          )

          if (response.status === "ok") {
            const topicsVerbatimFromResponse = this.formatVerbatim(response, true)
            this.deleteTopicsVerbatimsClassificationMenu(topicsVerbatimFromResponse)
            this.$emit('updateOrDeleteTopicsVerbatims', topicsVerbatimFromResponse)
          } else {
            this.$store.dispatch('notifyError', { message: response.message })
          }
        } catch (error) {
          this.$store.dispatch('notifyError', { message: error.message })
        }
      },
      createTopicsVerbatimsClassificationMenu(topicsVerbatimResponse) {
        const topicsVerbatimFromResponse = Object.values(topicsVerbatimResponse)[0]
        let copyTopicsVerbatims = _cloneDeep(this.topicsVerbatims)

        this.topicsVerbatims = _orderBy([...copyTopicsVerbatims, ...[topicsVerbatimFromResponse]], ['topicName'], ['asc'])
      },
      updateTopicsVerbatimsClassificationMenu(topicsVerbatimResponse) {
        const topicsVerbatimFromResponse = Object.values(topicsVerbatimResponse)[0]

        this.topicsVerbatims.forEach((topicsVerbatim, index) => {
          if (topicsVerbatim.topicId === topicsVerbatimFromResponse.topicId) {
            this.$set(this.topicsVerbatims, index, topicsVerbatimFromResponse)
          }
        })
      },
      deleteTopicsVerbatimsClassificationMenu(topicsVerbatimResponse) {
        const topicsVerbatimFromResponse = Object.values(topicsVerbatimResponse)[0]
        let copyTopicsVerbatims = _cloneDeep(this.topicsVerbatims)

        copyTopicsVerbatims = copyTopicsVerbatims.filter((topicsVerbatim) => {
          return topicsVerbatim.topicId !== topicsVerbatimFromResponse.topicId
        })

        this.topicsVerbatims = copyTopicsVerbatims
      },
      formatVerbatim(response, disabled = false) {
        let formattedVerbatim = {}

        const {
          id, verbatimContent, voterVoteAt, voterUniqueId, verbatimId,
          textAnalysisThemeId, textAnalysisTopicId, textAnalysisTopicPublicName, feeling
        } = response

        formattedVerbatim[textAnalysisTopicId] = {
          id: id,
          content: verbatimContent,
          feeling: feeling,
          reviewDate: voterVoteAt,
          themeId: textAnalysisThemeId,
          topicId: textAnalysisTopicId,
          topicName: textAnalysisTopicPublicName,
          verbatimId: verbatimId,
          voterUniqueId: voterUniqueId,
          disabled: disabled
        }

        return formattedVerbatim
      }
    },
    watch: {
      async menuActivatorId(newVal) {
        if (newVal) {
          this.activator = this.menuActivatorId
          this.open = true
          this.topicsVerbatims = await this.fetchTopicsVerbatims()
        }
      },
      open(newVal) {
        if (newVal === false) {
          this.$emit('onCloseMenu')
          this.activator = undefined
          this.topicsVerbatims = undefined
        }
      }
    },
  }
</script>

<style lang="stylus">
  .topics-verbatim-classification-menu::-webkit-scrollbar
    display: none
  .topics-verbatim-classification-menu
    -ms-overflow-style: none
    scrollbar-width: none
    overflow: 'hidden'
    contain: none

    .sub-list::-webkit-scrollbar
      display: none
    .sub-list
      -ms-overflow-style: none
      scrollbar-width: none
      max-height: 200px
      overflow-y: scroll

    .v-list--dense .v-subheader
      height: 35px
    .v-list-item.v-list-item--dense.theme--light
      min-height: 35px
</style>
