<template>
  <div>
    <span
      v-if="nisItem.nisOpportunity > 0"
      class="fb-15 c-primary text-no-wrap">
      {{ `+${nisItem.nisOpportunity} %` }}
    </span>
    <span v-else class="f-12 c-lightgrey">
      {{ $t('nil') }}
    </span>
  </div>
</template>

<script>
  export default {
    name: "NisOpportunityCell",
    props: {
      nisItem: { type: Object, required: true }
    }
  }
</script>
