<template>
  <WStatsWidget
    ref="widget"
    :cols="cols"
    :footerSpacer="footerSpacer"
    :subtitle="defaultSubtitle"
    :title="title"
    :loading="avgProcessingTime === undefined"
    :empty="
      avgProcessingTime !== undefined && (
        !displayAvgProcessingTime ||
        !humanizeAvgProcessingTime
      )
    "
    class="feedback-treatment-widget"
    height="200px"
  >
    <template #content>
      <span class="c-primary f-34 font-weight-bold">
        {{ humanizeAvgProcessingTime.numberTime }}
      </span>
      <span class="c-primary f-20">
        {{ humanizeAvgProcessingTime.lettersTime }}
      </span>
    </template>

    <template #footer-left v-if="groupRanking && isMonoPlace">
      <span class="fb-12">
        <WTrunc :text="groupRankingName" :limit="20" /> :
      </span>
      <span class="mr-3">{{ groupRanking }}{{ $options.filters.ordinal(groupRanking) }}</span>
    </template>

    <template #footer-right v-if="networkRanking && isMonoPlace">
      <span class="fb-12">
        {{ networkRankingName }} :
      </span>
      <span>{{ networkRanking }}{{ $options.filters.ordinal(networkRanking) }}</span>
    </template>
  </WStatsWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
import FeedbackTreatmentMixin from '@statistics/shared/feedback_treatment_mixin'

export default {
  name: 'FeedbackTreatmentWidget',
  mixins: [
    WidgetMixin,
    FeedbackTreatmentMixin,
  ],
  props: {
    campaign: { type: Object },
    cols: { type: Number, default: 3 },
    footerSpacer: { type: Boolean, default: false },
  },
  data() {
    return {
      decimals: 0
    }
  },
  computed: {
    ...mapGetters([
      'dashboardFilterReady',
      'groupRankingName',
      'groupRankingPlaceIds',
      'isMonoPlace',
      'networkRankingName',
      'networkRankingPlaceIds',
      'placeIds',
    ]),
    title() {
      return this.$t('feedbackTreatmentWidgetTitle')
    },
    groupRanking() {
      return this.rankFor(this.groupDatas)
    },
    networkRanking() {
      return this.rankFor(this.networkDatas)
    },
    displayAvgProcessingTime() {
      if (this.dashboardFilterReady && this.avgProcessingTime)
        return this.avgProcessingTime.insatisfactionsAvgProcessingTime != -1
    },
    humanizeAvgProcessingTime() {
      const avgProcessingTime = this.avgProcessingTime?.insatisfactionsAvgProcessingTime
      if (avgProcessingTime) {
        const humanizeProcessingTime = this.$helpers.dates.complete(dayjs().subtract(avgProcessingTime, "hour"))
        const numberTime = humanizeProcessingTime.match(/\d+/g)[0]
        const lettersTime = humanizeProcessingTime.match(/[a-zA-Z]+/g)[0]

        if (numberTime && lettersTime) {
          return { numberTime, lettersTime }
        }
        return null
      } else {
        return null
      }
    },
  },
  asyncComputed: {
    avgProcessingTime: {
      lazy: true,
      default: undefined,
      async get() {
        if (this.dashboardFilterReady) {
          const request = this.avgProcessingTimeRequest()

          return (await this.$resolve(request)).first()
        }
      },
    },
    networkDatas: {
      async get() {
        if (this.dashboardFilterReady && this.isMonoPlace && this.networkRankingPlaceIds) {
          this.loading = true

          const request = this.avgProcessingTimeRequest().where({
              "place_campaigns_place_id": this.networkRankingPlaceIds
            }).group([
              "place_campaigns_place_id"
            ]).order([this.avgProcessingTimeColumn(), "asc"])

          const networkRankingDatas = await this.$resolve(request)

          this.loading = false
          return networkRankingDatas.data.voters
        }
      },
      default: null,
    },
    groupDatas: {
      async get() {
        if (this.dashboardFilterReady && this.isMonoPlace && this.groupRankingPlaceIds) {
          this.loading = true
          const request = this.avgProcessingTimeRequest().where({
              "place_campaigns_place_id": this.groupRankingPlaceIds
            }).group([
              "place_campaigns_place_id"
            ]).order([this.avgProcessingTimeColumn(), "asc"])

          const groupRankingDatas = await this.$resolve(request)

          this.loading = false
          return groupRankingDatas.data.voters
        }
      },
      default: null,
    },
  }
}
</script>
