<template>
  <WLateralSectionLayout class="pl-3 social-page">
    <template #lateral-section>
      <SocialScoreWidget :source="source" :cols="12" />
      <SocialPlaceRankingWidget :source="source" :cols="12" v-if="isMonoPlace" />
    </template>

    <template #main-section>
      <SocialRepartitionWidget :source="source" />
      <SocialEvolution :source="source" />

      <!-- Temporarily comment until real data
      <v-row no-gutters>
        <SocialPositiveTopics :source="source" />
        <SocialNegativeTopics :source="source" />
      </v-row> -->

      <SocialRankingTable :source="source" v-if="hasAccessToNewPlatformRankingTab" />
    </template>
  </WLateralSectionLayout>
</template>

<script>
  import SocialPlaceRankingWidget from "./SocialPlaceRankingWidget"
  import SocialScoreWidget from "./SocialScoreWidget"
  import SocialRepartitionWidget from "./SocialRepartitionWidget"
  import SocialEvolution from "./SocialEvolution"
  import SocialRankingTable from "./SocialRankingTable"
  import SocialPositiveTopics from "./SocialPositiveTopics"
  import SocialNegativeTopics from "./SocialNegativeTopics"
  import { mapGetters } from 'vuex'

  export default {
    name: "SocialPage",
    props: {
      source: { type: String, required: true }
    },
    components: {
      SocialScoreWidget,
      SocialRepartitionWidget,
      SocialEvolution,
      SocialRankingTable,
      SocialPositiveTopics,
      SocialNegativeTopics,
      SocialPlaceRankingWidget
    },
    computed: {
      ...mapGetters([
        'isMonoPlace',
        'hasAccessToNewPlatformRankingTab'
      ])
    }
  }
</script>
