<template>
  <WStatsWidget
    :cols="12"
    :empty="empty"
    :justifyCenter="false"
    :loading="loading"
    :subtitle="displayedSubtitle"
    class="topics-verbatim-themes-widget"
    contentWidth="100%"
    minHeight="200px"
    maxHeight="400px"
  >
    <template #options v-if="!loading">
      <div>
        <v-select
          v-model="groupBy"
          :items="groupByOptions"
          hide-details
          dense
          class="pt-0 mt-0"
          style="width: 80px"
        ></v-select>
      </div>
    </template>

    <template #title>
      <div class="d-flex">
        <a v-if="zoomedTopic" @click="zoomedTopic = null">
          <v-icon small style="position: relative; bottom: 1px; padding-right: 5px">mdi-keyboard-backspace</v-icon>
          <span>{{ zoomedTopic.name }}</span>
        </a>
        <a v-else-if="zoomedTheme" @click="zoomedTheme = null">
          <v-icon small style="position: relative; bottom: 1px; padding-right: 5px">mdi-keyboard-backspace</v-icon>
          <span>{{ zoomedTheme.name }}</span>
        </a>
        <span v-else>{{ $t(title) }}</span>
      </div>
    </template>

    <template #content>
      <ThemesTable class="pb-1"
        v-if="!loading"
        @zoomOnTopic="zoomOnTopic"
        @zoomOnTheme="zoomOnTheme"
        :campaign="campaign"
        :topics="formatedTopics"
        :themeById="themeById"
        :loading="loading"
        :zoomedData="zoomedData"
        :groupBy="groupBy"
      />
      <v-skeleton-loader v-else type="paragraph" class="my-3" />
    </template>
  </WStatsWidget>
</template>

<script>
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import ThemesTable from './ThemesTable'

  export default {
    name: "TopicsVerbatimThemesWidget",
    props: ['campaign', 'formatedTopics', 'themeById', 'loading', 'zoomedData'],
    mixins: [
      WidgetMixin
    ],
    components: {
      ThemesTable
    },
    data() {
      return {
        empty: false,
        title: this.$t('topics_verbatim_themes_widget_title'),
        zoomedTheme: null,
        zoomedTopic: null,
        groupBy: "themes"
      }
    },
    computed: {
      zoomed() {
        if (this.zoomedTheme) {
          return this.zoomedTheme.name
        } else if (this.zoomedTopic) {
          return this.zoomedTopic.name
        }
      },
      groupByOptions() {
        return [ { text: this.$t('themes'), value: 'themes' }, { text: this.$t('topics'), value: 'topics' } ]
      }
    },
    methods: {
      zoomOnTopic(topic) {
        this.$router.push({ params: { sectionId: `topic${topic.id}` } })
      },
      zoomOnTheme(theme) {
        this.$router.push({ params: { sectionId: `theme${theme.id}` } })
      }
    }
  }
</script>
