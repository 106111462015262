  <template>
  <div v-if="currentDashboard.preferences.isLrmEligible" class="lm-places pl-3 mt-2 mt-sm-0">
    <WLateralSectionLayout :fullScreenMode="fullScreenMode">
      <template #lateral-section>
        <template>
          <widget-position
            v-if="isMonoPlace"
            :competitorMode="competitorMode"
            :periodType="periodType"
            :competitorScope="competitorScope"
            :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreference?.id"
            :lmBrandIds="campaignSmartBenchmarkPreference?.lmBrandIds"
            :key="`widget-position-${activeLmPlaceId}-${dashboardFilterBase64}`"
            :active="active"
          />

          <widget-score
            :periodType="periodType"
            :competitorMode="competitorMode"
            :competitorScope="competitorScope"
            :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreference?.id"
            :key="`widget-score-${activeLmPlaceId}-${dashboardFilterBase64}`"
            :active="active"
          />
        </template>
      </template>

      <template #main-section>
        <template>
          <widget-ranking
            :periodType="periodType"
            :competitorMode="competitorMode"
            :competitorScope="displayedCompetitorScope"
            :campaignSmartBenchmarkPreference="campaignSmartBenchmarkPreference"
            :lmBrandIds="campaignSmartBenchmarkPreference?.lmBrandIds"
            :active="active"
            hideHeader
            :key="`widget-ranking-${dashboardFilterBase64}`"
            :zoomable="widgetRankingZoomable"
          />

          <VerbatimTopicRankings
            v-if="showVerbatimTopicRankings"
            :active="active"
            :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreference.id"
            :key="`verbatim-topic-rankings-${activeLmPlaceId}-${dashboardFilterBase64}`"
          />

          <widget-evolution
            :periodType="periodType"
            :competitorMode="competitorMode"
            :competitorScope="displayedCompetitorScope"
            :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreference?.id"
            :lmBrandIds="campaignSmartBenchmarkPreference?.lmBrandIds"
            :key="`widget-evolution-${dashboardFilterBase64}`"
          />

          <widget-lm-place-review-table
            v-if="competitorMode === 'local'"
            :key="`widget-lm-place-review-table-${activeLmPlaceId}-${dashboardFilterBase64}`"
            :active="active"
          />
        </template>
      </template>
    </WLateralSectionLayout>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import WidgetPosition from '../widgets/Position'
  import WidgetScore from '../widgets/Score'
  import WidgetRanking from '../widgets/Ranking'
  import WidgetEvolution from '../widgets/Evolution'
  import WidgetLmPlaceReviewTable from '../widgets/LmPlaceReviewTable'
  import VerbatimTopicRankings from '../widgets/VerbatimTopicRankings'

  export default {
    name: "LmPlaces",
    components: {
      WidgetPosition,
      WidgetScore,
      WidgetRanking,
      WidgetEvolution,
      WidgetLmPlaceReviewTable,
      VerbatimTopicRankings
    },
    props: {
      competitorMode: {
        required: true,
        type: String
      },
      competitorScope: {
        required: false,
        type: String
      },
      periodType: {
        required: true,
        type: String,
      },
      campaignSmartBenchmarkPreference: {
        required: false
      },
      activeLmPlaceId: {
        required: false
      }
    },
    computed: {
      ...mapGetters([
        "currentDashboard",
        "placeIds",
        "isMonoPlace",
        "lmGmbPlaceIdsScope",
        "dashboardFilterBase64",
        "defaultCompetitorScope",
        "fullScreenMode"
      ]),
      widgetRankingZoomable() {
        return this.competitorMode === 'local' &&
          this.isMonoPlace
      },
      showVerbatimTopicRankings() {
        return this.active &&
          this.isMonoPlace &&
          this.campaignSmartBenchmarkPreference &&
          this.campaignSmartBenchmarkPreference.enabled
      },
      displayedCompetitorScope() {
        if (this.competitorMode === 'local') {
          return 'local_competitors'
        }

        return this.competitorScope
      }
    },
    asyncComputed: {
      active: {
        async get() {
          if (this.activeLmPlaceId && this.isMonoPlace) {
            const req = this.$basedRequest().where({
              id: this.activeLmPlaceId
            }).select({
              lm_places: [ 'name' ]
            })

            const result = (await this.$resolve(req)).first()

            return {
              type: 'lmPlace',
              id: Number(this.activeLmPlaceId) ,
              name: result?.name,
              placeId: this.placeIds[0],
              isUserItem: this.lmGmbPlaceIdsScope.includes(this.activeLmPlaceId)
            }
          }
        }
      }
    }
  }
</script>
