<template>
  <v-dialog
    v-model="show"
    max-width="1400px"
    :retain-focus="false"
  >
    <v-card>
      <v-card-title class="d-flex align-center">
        <span class="c-primary">
          {{ $t('openai_preferences_prompt_more_examples_title') }}
        </span>
        <v-btn
          icon
          color="black"
          plain
          @click="show = false"
          class="ml-auto"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <TopicsVerbatimReclassificationsSelector
          v-model="selectedVerbatims"
        />
        <div v-if="selectedVerbatims.length" class="mt-4">
          <v-textarea
            v-if="promptExamples"
            v-model="promptExamples"
            class="prompt-examples-textarea f-14"
            :rules="[$required]"
            :hide-details="true"
            name="content"
            dense
            filled
            no-resize
            height="400px"
            disabled
          />
          <v-skeleton-loader v-else type="paragraph" />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  import TopicsVerbatimReclassificationsSelector from './TopicsVerbatimReclassificationsSelector'

  export default {
    name: 'MoreExamplesDialog',
    components: {
      TopicsVerbatimReclassificationsSelector
    },
    props: {
      value: { type: Boolean, required: true },
    },
    data() {
      return {
        selectedVerbatims: []
      }
    },
    computed: {
      ...mapGetters([
        'currentCampaignPreferencesCampaignId'
      ]),
      show: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit("input", val)
        }
      }
    },
    asyncComputed: {
      promptExamples: {
        async get() {
          const verbatimIds = this.selectedVerbatims.map(
            (verbatim) => verbatim.verbatimId
          )

          if (verbatimIds.length) {
            const exampleVerbatims = await this.fetchMoreVerbatimsPromptExamples(verbatimIds)
            const exampleResponses = await this.fetchMoreResponsesPromptExamples(verbatimIds)

            return `${exampleVerbatims}\n\n${exampleResponses}`
          }
        }
      }
    },
    methods: {
      async fetchMoreVerbatimsPromptExamples(verbatimIds) {
        const request = this.$basedRequest().select({
          campaigns: [{
            text_analysis_more_verbatims_prompt_examples: {
              params: {
                verbatim_ids: verbatimIds
              }
            }
          }]
        }).where({
          id: this.currentCampaignPreferencesCampaignId
        })

        return (await this.$resolve(request)).first()?.textAnalysisMoreVerbatimsPromptExamples
      },
      async fetchMoreResponsesPromptExamples(verbatimIds) {
        const request = this.$basedRequest().select({
          campaigns: [{
            text_analysis_more_responses_prompt_examples: {
              params: {
                verbatim_ids: verbatimIds
              }
            }
          }]
        }).where({
          id: this.currentCampaignPreferencesCampaignId
        })

        return (await this.$resolve(request)).first()?.textAnalysisMoreResponsesPromptExamples
      }
    }
  }
</script>

