<template>
  <WStatsWidget
    class="feedback-treatment-ranking-widget"
    :title="title"
    :subtitle="defaultSubtitle"
    height="200px"
    :cols="cols"
    ref="widget"
  >
    <template #content>
      <span v-if="!loading" class="c-primary f-36">
        <WPositionWidget
          :position="networkRanking"
          :evolution="networkEvolution"
          :displayEvolution="displayEvolution"
          :nrTooltipText="nrTooltipText"
        />
      </span>
      <v-skeleton-loader v-else type="paragraph" />
    </template>

    <template #footer-left>
    </template>
  </WStatsWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
import FeedbackTreatmentMixin from '@statistics/shared/feedback_treatment_mixin'

export default {
  name: 'FeedbackTreatmentRankingWidget',
  mixins: [
    WidgetMixin,
    FeedbackTreatmentMixin
  ],
  props: {
    campaign: { required: false },
    cols: { type: Number, default: 3 },
  },
  data() {
    return {
      loading: true,
      decimals: 0,
      displayEvolution: true
    }
  },
  computed: {
    ...mapGetters([
      'dashboardFilterReady',
      'isMonoPlace',
      'datesScope',
      'networkRankingPlaceIds',
    ]),
    nrTooltipText() {
      return this.$t('feedbackTreatmentWidgetTootip')
    },
    title() {
      return `${this.$t('feedbackTreatmentRankingWidgetTitle')} - ${this.$t('feedbackTreatmentWidgetTitle')}`
    },
    networkRanking() {
      return this.rankFor(this.actualPeriodNetworkDatas)
    },
    networkEvolution() {
      const previousPeriodNetworkDatas = this.rankFor(this.previousPeriodNetworkDatas)
      const actualPeriodNetworkDatas = this.rankFor(this.actualPeriodNetworkDatas)

      if (actualPeriodNetworkDatas != null && previousPeriodNetworkDatas != null) {
        this.displayEvolution = true
        return previousPeriodNetworkDatas - actualPeriodNetworkDatas
      } else {
        this.displayEvolution = false
        return -1
      }
    }
  },
  asyncComputed: {
    previousPeriodNetworkDatas: {
      async get() {
        if (this.dashboardFilterReady && this.isMonoPlace) {
          this.loading = true

          const previousPeriodNetworkRequest = this.avgProcessingTimeRequest(
            {
              dateBegin: this.datesScope.comparedDateBegin,
              dateEnd: this.datesScope.comparedDateEnd
            }
          ).where({
            "place_campaigns_place_id": this.networkRankingPlaceIds
          }).group([
            "place_campaigns_place_id"
          ]).order([this.avgProcessingTimeColumn(
            {
              dateBegin: this.datesScope.comparedDateBegin,
              dateEnd: this.datesScope.comparedDateEnd
            }
          ), "asc"])

          const previousPeriodNetworkDatas = await this.$resolve(previousPeriodNetworkRequest)

          this.loading = false
          return previousPeriodNetworkDatas.data.voters
        }
      },
      default: null
    },
    actualPeriodNetworkDatas: {
      async get() {
        if (this.dashboardFilterReady && this.isMonoPlace) {
          this.loading = true

          const actualPeriodNetworkRequest = this.avgProcessingTimeRequest().where({
              "place_campaigns_place_id": this.networkRankingPlaceIds
            }).group([
              "place_campaigns_place_id"
            ]).order([this.avgProcessingTimeColumn(), "asc"])

          const actualPeriodNetworkDatas = await this.$resolve(actualPeriodNetworkRequest)

          this.loading = false
          return actualPeriodNetworkDatas.data.voters
        }
      },
      default: null
    }
  }
}
</script>
