var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"satisfaction-page",attrs:{"no-gutters":""}},[_c('v-col',[[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[(
              _vm.eligibleCampaigns.length > 1 ||
              _vm.eligibleCampaigns[0].campaignIndicators.length > 0
            )?_c('CampaignIndicatorsTableWidget',{key:_vm.nisMixin_key,attrs:{"load":_vm.loadData(),"campaignScoreIndicator":_vm.campaignIndicatorsMixin_campaignScoreIndicatorByCampaign,"campaignIndicators":_vm.campaignIndicatorsMixin_campaignIndicatorsByCampaign,"eligibleCampaigns":_vm.eligibleCampaigns,"selectedCampaignIndicator":_vm.selectedCampaignIndicator,"cols":12}}):_vm._e()],1)],1),_vm._v(" "),(
          _vm.eligibleCampaigns.length > 1 ||
          _vm.eligibleCampaigns[0].campaignIndicators.length > 0
        )?_c('v-row',{staticClass:"px-3 mb-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('CampaignIndicatorAutocomplete',{key:`${_vm.campaignId}_${_vm.selectedCampaignIndicator?.id}`,ref:"campaignIndicatorAutocomplete",staticClass:"d-flex",attrs:{"eligibleCampaigns":_vm.eligibleCampaigns,"campaignId":_vm.campaign?.id,"campaignIndicatorId":_vm.selectedCampaignIndicator?.id}})],1)],1):_vm._e(),_vm._v(" "),(
          _vm.selectedCampaignIndicator === undefined ||
          _vm.selectedCampaignIndicator.id
        )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('CampaignIndicatorZoom',{key:_vm.selectedCampaignIndicator?.id,attrs:{"load":_vm.loadData(),"campaign":_vm.campaign,"campaignIndicator":_vm.selectedCampaignIndicator,"widgetSubtitle":_vm.widgetSubtitle,"nisVoterProportions":_vm.selectedCampaignIndicator,"nisSummary":_vm.selectedCampaignIndicator?.nisSummary}})],1)],1):_vm._e()]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }