<template>
  <v-menu
    bottom
    left
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn
        text
        tile
        small
        v-on="on"
        :ripple="false"
        style="height: 100%"
        class="text-lowercase"
        @click="toggleMenu"
      >
        {{ menuItems.title }}
        <v-icon right dark class="f-15 toggle-icon">{{ chevronIcon }}</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="menuItemChildren in menuItems.children"
        :key="menuItemChildren.title"
        :to="menuItemChildren.to"
      >
        <v-list-item-icon>
          <v-icon class="f-15 c-dark">{{ menuItemChildren.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-title class="f-13 c-dark">
          {{ $t(menuItemChildren.title) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'UserMenu',
    computed: {
      ...mapGetters([
        "currentUser",
        'currentUserIsAdmin',
        'lrmBrandPreferenceEnabled',
      ]),
      chevronIcon: function() {
        return this.value ? "mdi-chevron-up" : "mdi-chevron-down"
      },
      menuItems() {
        return {
          icon: "mdi-account-cog-outline",
          title: this.currentUser.mail,
          opened: false,
          children: [
            {
              title: "dashboard_navigation_app_bar_user_menu_preferences",
              icon: "mdi-account",
              to: { name: "settings_my_account" }
            },
            {
              title: "dashboard_navigation_app_bar_user_menu_logout",
              icon: "mdi-power",
              to: { name: 'Logout' }
            }
          ]
        }
      }
    },
    methods: {
      toggleMenu() {
        this.value = !this.value
      }
    },
    data() {
      return {
        value: false,
      }
    },
  }

</script>

<style lang="stylus" scoped>
  .v-list-item
    min-height: 30px
    .v-list-item__icon
      margin: 9px 0
      min-width: 20px
  .toggle-icon
    margin-top: 3px
</style>
