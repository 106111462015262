<template>
  <v-menu
    bottom
    left
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn
        text
        tile
        small
        v-on="on"
        :ripple="false"
        style="height: 100%"
        class="text-capitalize"
        @click="opened = !opened"
      >
        {{ $t("dashboard_sidebar_company") }}
        <v-icon right dark class="f-15 toggle-icon">{{ chevronIcon }}</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        exact
        v-for="item in items"
        :key="item.title"
        :to="item.to"
      >
        <v-list-item-title class="f-13 c-dark">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: "CompanyMenu",
    components: {},
    data() {
      return {
        opened: false,
        items: [
          {
            title: this.$t("dashboard_sidebar_places"),
            to: { name: 'PlacesSettings' },
            icon: 'mdi-home-group',
          },
          {
            title: this.$t("dashboard_sidebar_roles"),
            to: { name: 'OldPlatformUserRoles' }
          },
          {
            title: this.$t("dashboard_sidebar_brands"),
            to: { name: 'OldPlatformBrands' }
          }
        ]
      }
    },
    computed: {
      chevronIcon: function() {
        return this.value ? "mdi-chevron-up" : "mdi-chevron-down"
      }
    },
  }

</script>

<style lang="stylus" scoped>
  .v-list-item
    min-height: 30px
  .toggle-icon
    margin-top: 3px
</style>
