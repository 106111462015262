<template>
  <v-tooltip top transition="none" v-if="status">
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          v-if="status && status.type == 'mdi'"
          small
          class="c-lightgrey"
          :class="status.className"
        >
          {{status.icon}}
        </v-icon>
        <w-icon
          v-if="status && status.type == 'wicon'"
          :icon="status.icon"
          :icon-style="{ fontSize: '18px' }"
          class="c-lightgrey"
          :class="status.className"
        />
      </div>
    </template>
    <span>{{ status.tooltip }}</span>
  </v-tooltip>
</template>

<script>
  import VoterMixin from '../../voter_mixin'

  export default {
    name: "ProcessingStatusIcon",
    mixins: [
      VoterMixin
    ],
    computed: {
      status() {
        const availableStatus = {
          insatisfaction_not_processed: {
            icon: 'mdi-message-plus-outline',
            type: 'mdi',
            className: 'not-processed-new-feedback',
            tooltip: this.$t('processing_status_icon_insatisfaction_not_processed_tooltip')
          },
          conversation_need_answer: {
            icon: 'mdi-chat-plus-outline',
            type: 'mdi',
            className: 'not-processed-new-message',
            tooltip: this.$t('processing_status_icon_conversation_need_answer_tooltip')
          },
          pending_validation: {
            icon: 'mdi-comment-check-outline',
            type: 'mdi',
            className: 'not-processed-pending-validation',
            tooltip: this.$t('processing_status_icon_pending_validation_tooltip')
          },
          insatisfaction_archived: {
            icon: 'archive',
            type: 'wicon',
            className: 'insatisfaction_archived',
            tooltip: this.$t('processing_status_icon_insatisfaction_archived_tooltip')
          },
          automated_conversation_message_sent: {
            icon: 'mdi-robot-outline',
            type: 'mdi',
            className: 'automated_conversation_message_sent',
            tooltip: this.$t('processing_status_icon_automated_conversation_message_sent_tooltip')
          },
          call_report: {
            icon: 'phone-shape-2',
            type: 'wicon',
            className: 'call_report',
            tooltip: this.$t('processing_status_icon_call_report_tooltip')
          },
          conversation_message_sent: {
            icon: 'message-sent',
            type: 'wicon',
            className: 'conversation_message_sent',
            tooltip: this.$t('processing_status_icon_conversation_message_sent_tooltip')
          },
          closing: {
            icon: 'archive',
            type: 'wicon',
            className: 'closing',
            tooltip: this.$t('processing_status_icon_closing_tooltip')
          }
        }

        if (this.voterProcessingStatus && this.voterProcessingStatus !== 'processed') {
          return availableStatus[this.voterProcessingStatus]
        } else {
          return availableStatus[this.voterFirstTreatmentAction]
        }
      }
    }
  }
</script>

<style lang='stylus' scoped>
</style>
