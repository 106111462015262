<template>
  <div>
    <v-row no-gutters class="pa-3">
      <v-col>
        <Table
          v-if="showTable"
          ref="table"
          :currentCampaignSmartBenchmarkPreferences="currentCampaignSmartBenchmarkPreferences"
          @removed="onRemoved"
        >
        </Table>
      </v-col>
    </v-row>
    <v-row no-gutters class="pa-3">
      <v-col>
        <Form
          v-if="showForm"
          :currentCampaignSmartBenchmarkPreferences="currentCampaignSmartBenchmarkPreferences"
          @saved="onSaved"
          @duplicated="onDuplicated"
          @removed="onRemoved"
          @makedDefault="onMakedDefault"
        >
        </Form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import Table from './Table'
  import Form from './Form'

  export default {
    name: 'SmartBenchmarkPreferences',
    props: ['campaignSmartBenchmarkPreferencesId'],
    components: {
      Table,
      Form
    },
    data() {
      return {
        reload: 0
      }
    },
    computed: {
      ...mapGetters(['currentCampaignPreferencesCampaignId']),
      currentCampaignSmartBenchmarkPreferences() {
        if (this.campaignSmartBenchmarkPreferencesId) {
          return this.campaignSmartBenchmarkPreferences.find((campaignSmartBenchmarkPreference) => campaignSmartBenchmarkPreference.id = this.campaignSmartBenchmarkPreferencesId
          )
        }

        return this.campaignSmartBenchmarkPreferences.find((campaignSmartBenchmarkPreference) => campaignSmartBenchmarkPreference.isDefault
        )
      },
      showTable() {
        return this.campaignSmartBenchmarkPreferences.length
      },
      showForm() {
        return this.currentCampaignSmartBenchmarkPreferences
      }
    },
    asyncComputed: {
      campaignSmartBenchmarkPreferences: {
        async get() {
          const request = this.$basedRequest().select({
            campaign_smart_benchmark_preferences: [
              { 'id': { as: 'id' } },
              { 'is_default': { as: 'isDefault' } }
            ]
          }).where({
            campaign_id: this.currentCampaignPreferencesCampaignId
          })

          const campaignSmartBenchmarkPreferences = (await this.$resolve(request))?.data?.campaignSmartBenchmarkPreferences || []

          return campaignSmartBenchmarkPreferences
        },
        default: [],
        watch: ['reload']
      }
    },
    methods: {
      zoom(id) {
        if (id) {
          this.$router.push({
            name: 'SmartBenchmarkPreferences',
            params: {
              campaignSmartBenchmarkPreferencesId: id
            }
          })
        } else {
          this.$router.push({
            name: 'SmartBenchmarkPreferences'
          })
        }
      },
      refreshTable() {
        const table = this.$refs.table

        if (table) {
          table.refresh()
        }
      },
      onSaved() {
        this.reload = this.reload + 1
        this.refreshTable()
      },
      onDuplicated(id) {
        this.refreshTable()
        this.zoom(id)
      },
      onRemoved() {
        this.reload = this.reload + 1
        this.zoom()
      },
      onMakedDefault() {
        this.refreshTable()
      }
    }
  }
</script>

<style lang="stylus" scoped>
</style>
