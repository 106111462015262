<template>
  <div class="public-platforms-navigation pl-1">
    <w-nav-tabs
      :router-links-properties="routerLinksProperties"
      scrollable
      :class="{ 'mb-3': $vuetify.breakpoint.xsOnly }"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "PublicPlatformsNavigation",
    props: {
      platform: {
        required: false,
        type: String
      }
    },
    data() {
      return {
        titles: {}
      }
    },
    computed: {
      ...mapGetters([
      ]),
      routerLinksProperties() {
        let routerLinksProperties = [
          {
            title: this.$t('settings_public_platforms_google'),
            active: this.$route.params.platform === 'google',
            route: {
              name: 'PublicPlatforms',
              params: {
                platform: 'google'
              }
            }
          },
          {
            title: this.$t('settings_public_platforms_facebook'),
            active: this.$route.params.platform === 'facebook',
            route: {
              name: 'PublicPlatformsFacebook',
            }
          },
          {
            title: this.$t('settings_public_platforms_trip_advisor'),
            active: this.$route.params.platform === 'trip_advisor',
            route: {
              name: 'PublicPlatformsTripAdvisor',
            }
          }
        ]

        return routerLinksProperties
      }
    },
    methods: {
    }
  }
</script>
