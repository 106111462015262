import { mapGetters, mapMutations } from 'vuex'
import _upperFirst from 'lodash/upperFirst'
import _camelCase from 'lodash/camelCase'
import _throttle from 'lodash/throttle'

export default {
  // This method will be called X number of times, related to how many times the mixin
  // is included in the page.
  mounted() {
    // Initialize the transactional_unit query param in the route.
    if (!this.$route.query?.transactional_unit) {
      this.$router.push({
        query: {
          ...this.$route.query,
          transactional_unit: this.requestedTransactionalUnit || 'average_revenue'
        }
      })
    }
  },
  // Theses methods will be called X number of times, related to how many times the mixin
  // is included in the page.
  watch: {
    // Update the store with the transactional unit value from the route.
    $route({ query }) {
      if (query?.transactional_unit &&
          query.transactional_unit !== this.requestedTransactionalUnit
      ) {
        this.requestTransactionalUnitChange(query.transactional_unit)
      }
    },
    placeIds() {
      this.setNetImpactScoreSummariesLoaded(undefined)
      this.nisMixin_loadNetImpactScoreSummariesByCampaignId()
    }
  },
  computed: {
    ...mapGetters([
      'currentDashboardScopedCampaigns',
      'currentLexicon',
      'hasAccessToNewPlatformNisFeature',
      'requestedTransactionalUnit',
      'currentDashboardRequest',
      'currentDashboardBasedTable',
      'placeIds',
      'netImpactScoreSummariesByCampaignId',
      'netImpactScoreSummariesLoaded',
      'isLcl'
    ]),
    // Used to force the reloading of tables from several NIS pages. The reloaded
    // component then loads new data. See `TopicsTableWidget` in `TopicsPage`.
    // All components relying on this method will be reloaded if the dashboard filters
    // have changed, if the NIS select has changed, or if the summaries are being
    // reloaded (because of a change in the "area" filter).
    nisMixin_key() {
      return `${this.dashboardFilterBase64}_${this.netImpactScoreSummariesLoaded}_${this.nisMixin_transactionalUnit}`
    },
    nisMixin_summaries() {
      return this.netImpactScoreSummariesByCampaignId
    },
    nisMixin_transactionalUnitsSelectElementValue: {
      get() {
        return this.nisMixin_transactionalUnit(
          this.nisMixin_eligibleCampaigns
        )?.nisColumnName
      },
      set(value) {
        // Update the store.
        this.requestTransactionalUnitChange(value)
        // Update the route with the new transactional unit value.
        if (!this.$route.query?.transactional_unit ||
            this.$route.query.transactional_unit !== value
        ) {
          this.$router.push({
            query: {
              ...this.$route.query,
              transactional_unit: value
            }
          })
        }
      }
    },
    _eligibleSummaryTypes() {
      switch (this.$route.params.sectionType) {
        case 'satisfaction':
          return [
            'NetImpactScoreAvgScoreSummary',
            'NetImpactScoreAvgCampaignIndicatorSummary',
            'NetImpactScoreNpsCampaignIndicatorSummary',
          ]
        case 'nps':
          return [
            'NetImpactScoreNpsSummary',
          ]
        case 'summary':
        case 'topics':
        case 'nis':
        // The default case is actually useful to show the NisPage in the tabs, because
        // we can be on any tab and still want to display it.
        default:
          return [
            'NetImpactScoreTopicSummary'
          ]
      }
    },
    nisMixin_eligibleCampaigns() {
      const allEligibleCampaigns =
        this.currentDashboardScopedCampaigns.filter(
          campaign => {
            return this.nisMixin_summaries[campaign.id] === undefined ?
              false :
              Object.values(this.nisMixin_summaries[campaign.id])?.length > 0
          }
        )
      return allEligibleCampaigns.filter(
        campaign => {
          return this.nisMixin_summaries[campaign.id] === undefined ?
            false :
            Object.keys(this.nisMixin_summaries[campaign.id]).some(
              summaryType => this._eligibleSummaryTypes.includes(_upperFirst(summaryType))
            )
        }
      )
    }
  },
  methods: {
    ...mapMutations([
      'requestTransactionalUnitChange',
      'setNetImpactScoreSummariesByCampaignId',
      'setNetImpactScoreSummariesLoaded'
    ]),
    nisMixin_satisfactionImpactHeaderTitle(campaigns) {
      if (campaigns.length) {
        const title = `💰 ${this.$t('topicsRecommendationsTableWidgetSatisfactionImpact')}`

        if (this.$vuetify.breakpoint.lgAndUp) {
          const transactionalUnitLabel = this.nisMixin_transactionalUnit(campaigns)?.label
          return `${title} - ${transactionalUnitLabel}`
        }

        return title
      }
    },
    nisMixin_opportunityHeaderTitle(campaigns) {
      return `💸 ${this.$t('topicsRecommendationsTableWidgetOpportunity')}`
    },
    nisMixin_roiSatisfactionHelpMessage(campaigns) {
      if (campaigns.length) {
        const key = this.nisMixin_transactionalUnit(campaigns)?.nisColumnName

        if (key) {
          if (this.isLcl) {
            return this.$t(
              `roi_satisfaction_help_message_${key}_lcl`,
              {
                customer: this.currentLexicon.translate('customer', 'customer').toLowerCase()
              }
            )
          } else {
            return this.$t(
              `roi_satisfaction_help_message_${key}`,
              {
                customer: this.currentLexicon.translate('customer', 'customer').toLowerCase()
              }
            )
          }
        } else {
          return null
        }
      }
    },
    nisMixin_opportunityHelpMessage(campaigns) {
      if (campaigns.length) {
        const key = this.nisMixin_transactionalUnit(campaigns)?.nisColumnName

        if (key) {
          if (this.isLcl) {
            return this.$t(
              `opportunity_help_message_${key}_lcl`,
              {
                customer: this.currentLexicon.translate('customer', 'customer').toLowerCase()
              }
            )
          } else {
            return this.$t(
              `opportunity_help_message_${key}`,
              {
                customer: this.currentLexicon.translate('customer', 'customer').toLowerCase()
              }
            )
          }
        } else {
          return null
        }
      }
    },
    async nisMixin_loadNetImpactScoreSummariesByCampaignId() {
      // Data is loading or already loaded.
      if (this.netImpactScoreSummariesLoaded !== undefined) {
        return
      }
      this.setNetImpactScoreSummariesLoaded(false)
      // Get the summaries.
      const request = this.currentDashboardRequest.select({
        [this.currentDashboardBasedTable]: [
          {
            computed_net_impact_score_summaries: {
              params: {
                place_ids: this.placeIds
              }
            }
          }
        ]
      })
      const results = (await this.$resolve(request)).first()
                                                    .computedNetImpactScoreSummaries
      this.setNetImpactScoreSummariesByCampaignId(results)
      // Data is loaded.
      this.setNetImpactScoreSummariesLoaded(true)
    },
    nisMixin_canUseNisFeature(campaigns, strict = true) {
      return campaigns.some(
        campaign => {
          const hasNisSummaries = this.nisMixin_summaries[campaign.id] ||
                                  ( !strict &&
                                    (
                                      this.netImpactScoreSummariesLoaded === undefined ||
                                      this.netImpactScoreSummariesLoaded === true
                                    )
                                  )
          return campaign?.netImpactScoreEnabled &&
                 this.hasAccessToNewPlatformNisFeature &&
                 hasNisSummaries
        }
      )
    },
    nisMixin_topicSummaries(campaign) {
      return this.nisMixin_summaries[campaign.id]?.['netImpactScoreTopicSummary'] || {}
    },
    nisMixin_avgScoreSummary(campaign) {
      return this.nisMixin_summaries[campaign.id]?.['netImpactScoreAvgScoreSummary'] || null
    },
    nisMixin_avgCampaignIndicatorSummaries(campaign) {
      return this.nisMixin_summaries[campaign.id]?.['netImpactScoreAvgCampaignIndicatorSummary'] || {}
    },
    nisMixin_npsCampaignIndicatorSummaries(campaign) {
      return this.nisMixin_summaries[campaign.id]?.['netImpactScoreNpsCampaignIndicatorSummary'] || {}
    },
    nisMixin_npsSummary(campaign) {
      return this.nisMixin_summaries[campaign.id]?.['netImpactScoreNpsSummary'] || null
    },
    nisMixin_transactionalUnitsMetadata(campaign) {
      const isSelectable = transactionalUnitName => {
        return () => {
          const summariesByType = this.nisMixin_summaries[campaign.id]
          // No NIS data for that campaign.
          if (!summariesByType) {
            return false
          }
          // No corresponding computed summary for that campaign.
          const allAvailableTypes = Object.keys(summariesByType)
          if (!allAvailableTypes.some(type => this._eligibleSummaryTypes.includes(_upperFirst(type)))) {
            return false
          }
          // Verify if any transactional unit can be used/displayed.
          for (const [type, summaries] of Object.entries(summariesByType)) {
            switch(_upperFirst(type)) {
              case 'NetImpactScoreTopicSummary':
              case 'NetImpactScoreAvgCampaignIndicatorSummary':
              case 'NetImpactScoreNpsCampaignIndicatorSummary':
                for (const summary of Object.values(summaries)) {
                  if (summary[_camelCase(transactionalUnitName)].nis > 0) {
                    return true
                  }
                }
                break
              case 'NetImpactScoreNpsSummary':
              case 'NetImpactScoreAvgScoreSummary':
                if (summaries[_camelCase(transactionalUnitName)].nis > 0) {
                  return true
                }
                break
              default:
                throw new Error(`Summary type ${_upperFirst(type)} is not supported`)
            }
          }
          // No transactional unit selectable.
          return false
        }
      }

      return {
        averageRevenue: {
          order: 1,
          key: 'averageRevenue',
          sectionType: 'nisAverageRevenue',
          nisColumnName: 'average_revenue',
          transactionalUnitColumnNameSuffix: 'average_revenue',
          label: this.isLcl ? 'Valeur annuelle totale' : this.$t('nis_dropdown_average_revenue'),
          currency: 'EUR',
          emoji: "💰",
          isSelectable: isSelectable('average_revenue')
        },
        averageBasketPrice: {
          order: 2,
          key: 'averageBasketPrice',
          sectionType: 'nisAverageBasketPrice',
          nisColumnName: 'average_basket_price',
          transactionalUnitColumnNameSuffix: 'average_basket_price',
          label: this.isLcl ? 'Valeur moyenne par contrat' : this.$t('nis_dropdown_average_basket_price'),
          currency: 'EUR',
          emoji: this.isLcl ? "🪙" : "🛒",
          isSelectable: isSelectable('average_basket_price')
        },
        averageVisitCount: {
          order: 3,
          key: 'averageVisitCount',
          sectionType: 'nisAverageVisitCount',
          nisColumnName: 'average_visit_count',
          transactionalUnitColumnNameSuffix: 'average_visit_count',
          label: this.isLcl ? 'Nombre de contrats' : this.$t('nis_dropdown_average_visit_count'),
          emoji: this.isLcl ? "📄" : "🚶‍♀️",
          isSelectable: isSelectable('average_visit_count')
        }
      }
    },
    nisMixin_transactionalUnit(campaigns) {
      // Retrieve available transactional units.
      const available = this.nisMixin_availableTransactionalUnits(campaigns)
                            .map(transactionalUnit => transactionalUnit.value)
      // Get the corresponding transactional unit for the given name.
      const unit = name => Object.values(this.nisMixin_transactionalUnitsMetadata(campaigns[0]))
                                 .find(transactionalUnit => transactionalUnit.nisColumnName == name)
      // Try to load the requested unit from the store.
      if (this.requestedTransactionalUnit &&
          available.includes(this.requestedTransactionalUnit)
      ) {
        return unit(this.requestedTransactionalUnit)
      }
      // Fallback on the first available transactional unit.
      return unit(available[0])
    },
    nisMixin_availableTransactionalUnits(campaigns) {
      const availableTransactionalUnits =
        Object.values(
                campaigns.reduce(
                  (transactionalUnits, campaign) => {
                    Object.values(this.nisMixin_transactionalUnitsMetadata(campaign))
                          .filter(unit => unit.isSelectable())
                          .forEach(transactionalUnit => {
                            transactionalUnits[transactionalUnit.nisColumnName] = transactionalUnit
                          })
                    return transactionalUnits
                  },
                  {}
                )
              )
              .sort((a, b) => a.order - b.order)
              .map(transactionalUnit => ({
                title: transactionalUnit.label,
                emoji: transactionalUnit.emoji,
                value: transactionalUnit.nisColumnName,
              }))

      if (availableTransactionalUnits.length == 0) {
        console.error("No available transactional unit")
      }

      return availableTransactionalUnits
    },
    nisMixin_placeReferencesAvgSatisfactionRate(placeReferences) {
      const sum = placeReferences.reduce(
        (sum, placeReference) => sum + placeReference.satisfactionRate,
        0
      )
      return sum / placeReferences.length
    },
    nisMixin_currentTransactionalUnitData(campaign, nisSummary) {
      return nisSummary?.[this.nisMixin_transactionalUnit([campaign]).key]
    },
    nisMixin_roiSatisfaction(voterProportions, nisSummary) {
      const { nbPromoters, nbNeutrals, nbDetractors } = voterProportions
      const nbOthers = nbNeutrals + nbDetractors
      const campaign = this.nisMixin_eligibleCampaigns.find(campaign => campaign.id === nisSummary.campaignId)
      const nisSummaryData = this.nisMixin_currentTransactionalUnitData(campaign, nisSummary)

      const promotersTransactionalUnit =
        nisSummaryData.promoters * nbPromoters

      const othersTransactionalUnit =
        nisSummaryData.neutrals * nbNeutrals +
        nisSummaryData.detractors * nbDetractors

      const transactionalUnitWithoutPromoters = othersTransactionalUnit * (1 + nbPromoters/nbOthers)

      const satisfactionImpact = (promotersTransactionalUnit + othersTransactionalUnit - transactionalUnitWithoutPromoters) / transactionalUnitWithoutPromoters

      return this.$options.filters.toPercent(satisfactionImpact, 1)
    },
    nisMixin_transactionalUnitToEarn(voterProportions, nisSummary) {
      const { nbPromoters, nbNeutrals, nbDetractors } = voterProportions
      const nbOthers = nbNeutrals + nbDetractors
      const campaign = this.nisMixin_eligibleCampaigns.find(campaign => campaign.id === nisSummary.campaignId)

      if (campaign === undefined) {
        return undefined
      }

      const transactionalUnitValues = nisSummary[this.nisMixin_transactionalUnit([campaign]).key]

      const promotersTransactionalUnit =
        transactionalUnitValues.promoters * nbPromoters
      const othersTransactionalUnit =
        transactionalUnitValues.neutrals * nbNeutrals +
        transactionalUnitValues.detractors * nbDetractors

      const placeReferencesAvgSatisfactionRate =
        this.nisMixin_placeReferencesAvgSatisfactionRate(nisSummary.placeReferences)

      const transactionalUnitToReach = (
          othersTransactionalUnit / nbOthers * (1 - placeReferencesAvgSatisfactionRate) +
          promotersTransactionalUnit / nbPromoters * placeReferencesAvgSatisfactionRate
        ) * (nbPromoters + nbOthers)

      const transactionalUnitToEarn = (
          transactionalUnitToReach / (
            promotersTransactionalUnit + othersTransactionalUnit
          )
        ) - 1

      return this.$options.filters.toPercent(transactionalUnitToEarn, 1)
    },
    nisMixin_satisfactionRates(voterProportions, nisSummary) {
      return {
        toReach: this.$options.filters.toPercent(
          this.nisMixin_placeReferencesAvgSatisfactionRate(
            nisSummary.placeReferences
          )
        ),
        actual: this.$options.filters.toPercent(
          voterProportions.nbPromoters / voterProportions.nbReview
        )
      }
    },
    nisMixin_getPlaceReferences(nisSummary) {
      return nisSummary.placeReferences.map(placeReference => {
        return {
          placeName: placeReference.placeName,
          satisfactionRate: this.$options.filters.toPercent(
            placeReference.satisfactionRate
          )
        }
      })
    }
  }
}
