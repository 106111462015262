<template>
  <v-tabs-items class="action-tabs" v-model="tab">
    <v-tab-item
      v-if="tab !== 'cleared'"
      :transition="false"
      :reverse-transition="false"
      value="send_message"
    >
      <SendMessage
        :voter="voter"
        :gBrandName="gBrandName"
        :fieldsCustomization="fieldsCustomization"
        :prospectFields="prospectFields"
        :smartAnswer="smartAnswer"
      />
    </v-tab-item>

    <v-tab-item
      v-if="tab !== 'cleared'"
      :transition="false"
      :reverse-transition="false"
      value="write_up"
    >
      <WriteUp
        :voter="voter"
      />
    </v-tab-item>

    <v-tab-item
      v-if="tab !== 'cleared'"
      :transition="false"
      :reverse-transition="false"
      value="assign"
    >
      <Assign
        :voter="voter"
      />
    </v-tab-item>

    <v-tab-item
      v-if="tab !== 'cleared'"
      :transition="false"
      :reverse-transition="false"
      value="send_internal_memo"
    >
      <SendInternalMemo
        :voter="voter"
      />
    </v-tab-item>

    <v-tab-item
      v-if="tab !== 'cleared'"
      :transition="false"
      :reverse-transition="false"
      value="send_feedback"
    >
      <SendFeedback
        :voter="voter"
      />
    </v-tab-item>
    <v-tab-item
      v-if="tab !== 'cleared'"
      :transition="false"
      :reverse-transition="false"
      value="close_feedback"
    >
      <CloseFeedback
        :voter="voter"
      />
    </v-tab-item>

    <v-tab-item
      v-if="tab !== 'cleared'"
      :transition="false"
      :reverse-transition="false"
      value="trustville_report"
    >
      <TrustvilleReport
        :voter="voter"
      />
    </v-tab-item>

  </v-tabs-items>
</template>

<script>
import { mapGetters } from 'vuex'
import SendMessage from './VoterAction/SendMessage'
import WriteUp from './VoterAction/WriteUp'
import Assign from './VoterAction/Assign'
import SendInternalMemo from './VoterAction/SendInternalMemo'
import SendFeedback from './VoterAction/SendFeedback'
import CloseFeedback from './VoterAction/CloseFeedback'
import TrustvilleReport from './VoterAction/TrustvilleReport'

export default {
  name: 'ActionTabs',
  components: {
    SendMessage,
    WriteUp,
    Assign,
    SendInternalMemo,
    SendFeedback,
    CloseFeedback,
    TrustvilleReport
  },
  props: {
    voter: { required: true },
    gBrandName: { required: true },
    fieldsCustomization: { required: true },
    prospectFields: { required: true },
    smartAnswer: { type: String },
  },
  computed: {
    ...mapGetters([
      'currentVoterActionsTab'
    ]),
    tab: {
      get: function () {
        return this.currentVoterActionsTab
      },
      set: function (tab) {
        this.$store.dispatch('setActionsTab', tab)
      }
    }
  }
}
</script>

<style lang="stylus">
  .action-tabs
    .v-window-item
      height: 100%
</style>
