<template>
  <WStatsWidget
    v-if="topic === undefined"
    :title="$t('question_condition_widget_title_loading')"
    :cols="12"
    :loading="true"
    :empty="false"
  />
  <v-row v-else no-gutters>
    <QuestionConditionWidget
      v-for="questionCondition of detactorsQuestionConditions"
      :key="questionCondition.id"
      :questionCondition="questionCondition"
      :title="$t('question_condition_detractors_widget_title', { item: topic.name })"
      :campaign="campaign"
      :cols="widgetSize"
    />
    <QuestionConditionWidget
      v-for="questionCondition of promotersQuestionConditions"
      :key="questionCondition.id"
      :questionCondition="questionCondition"
      :title="$t('question_condition_promoters_widget_title', { item: topic.name })"
      :campaign="campaign"
      :cols="widgetSize"
    />
  </v-row>
</template>

<script>
  import QuestionConditionWidget from "@statistics/shared/widgets/QuestionConditionWidget"

  export default {
    name: "TopicConditionalQuestionsWidget",
    props: {
      campaign: { type: Object, required: true },
      topic: { type: Object },
    },
    components: {
      QuestionConditionWidget
    },
    computed: {
      widgetSize() {
        if (this.promotersQuestionConditions.length + this.detactorsQuestionConditions.length == 1) {
          return 12
        } else {
          return 6
        }
      },
      promotersQuestionConditions() {
        return this.questionConditions.filter(questionCondition => (
          [">", ">="].includes(questionCondition?.condition?.params?.condition)
        ))
      },
      detactorsQuestionConditions() {
        return this.questionConditions.filter(questionCondition => (
          ["<", "<="].includes(questionCondition?.condition?.params?.condition)
        ))
      },
    },
    asyncComputed: {
      questionConditions: {
        lazy: true,
        default: [],
        async get() {
          let questionConditionsReq = this.$basedRequest().where({
            id: this.campaign.id
          }).select({
            campaigns: [{ conditional_questions_topic_: { as: "questionConditions", params: { topic_id: this.topic.id } } }]
          })

          const questionConditions = ((await this.$resolve(questionConditionsReq))?.data?.campaigns || [])

          return questionConditions[0]?.questionConditions || []
        }
      }
    }
  }
</script>
