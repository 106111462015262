<template>
  <EvolutionWidget
    :title="title"
    :basedRequest="basedRequest"
    :campaign="campaign"
    :indicatorAlias="indicatorAlias"
    :decimals="0"
    :min="0"
    :chartZoom="null"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
import FeedbackTreatmentMixin from '@statistics/shared/feedback_treatment_mixin'
import EvolutionWidget from '@statistics/shared/widgets/EvolutionWidget'

export default {
  name: "FeedbackTreatmentEvolutionWidget",
  components: {
    EvolutionWidget
  },
  mixins: [
    WidgetMixin,
    FeedbackTreatmentMixin
  ],
  props: {
    campaign: { required: true },
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    title() {
      return `${this.$t('widget_evolution_title')} - ${this.$t('feedbackTreatmentWidgetTitle')}`
    },
    basedRequest() {
      const uniqueId = Math.random().toString(36).substr(2, 9)
      const self = this

      const requestObj = {
        id: uniqueId,
        dateBegin: null,
        dateEnd: null,
        groups: null,
        dateBetween(dateBegin, dateEnd) {
          this.dateBegin = dateBegin
          this.dateEnd = dateEnd
          return this
        },
        group(groups) {
          this.groups = groups
          return this
        },
        async resolve(
          queryName,
          {
            tickNumber,
            tickUnit,
            timeSerieParams
          },
          endPoint
        ) {
          let request = self.avgProcessingTimeRequest(
            { dateBegin: this.dateBegin, dateEnd: this.dateEnd }
            ).group(this.groups)
            .dateBetween(this.dateBegin, this.dateEnd)

          const data = (await request.resolve(
            "FeedbackTreatmentEvolutionWidget",
            {
              tickNumber: tickNumber,
              tickUnit: tickUnit,
              timeSerieParams: timeSerieParams
            },
            "time_series"
          ))?.data || {}

          if (data.series[0]) {
            data.series[0].data = data.series[0].data.map((data) => {
              const insatisfactionsAvgProcessingTime = (
                data && data.insatisfactionsAvgProcessingTime !== -1 ?
                  data.insatisfactionsAvgProcessingTime / 24 :
                  null
              )

              return { insatisfactionsAvgProcessingTime }
            })
          }

          return {
            data: data || {}
          }
        }
      }
      return Object.create(requestObj)
    }
  }
}
</script>
