import Vue from 'vue'

const voterStringInfo = {
  state: () => ({
    stringInfoFilters: {},
  }),
  getters: {
    dashboardVoterStringInfoFilters(state) {
      return (Object.values(state.stringInfoFilters).length > 0 ? state.stringInfoFilters : null)
    },
    stringInfoFilters(state) {
      return state.stringInfoFilters
    }
  },
  mutations: {
    setDashboardVoterStringInfoFilters(state, stringInfoFilters) {
      state.stringInfoFilters = stringInfoFilters
    },
    setDashboardVoterStringInfoFilter(state, { filterKey, filterValues }) {
      if (!filterValues || filterValues.length == 0) {
        Vue.delete(state.stringInfoFilters, filterKey)
      } else {
        state.stringInfoFilters = { ...state.stringInfoFilters, ...{ [filterKey]: filterValues } }
      }
    },
  },
  actions: {
    setDashboardVoterStringInfoFilters({ commit }, filters) {
      if (filters.voterStringInfoFilters) {
        commit('setDashboardVoterStringInfoFilters', filters.voterStringInfoFilters)
      }
    },
    setDashboardVoterStringInfoFilter({ commit }, { filterKey, filterValues }) {
      commit('setDashboardVoterStringInfoFilter', { filterKey, filterValues })
    }
  },
}

export default voterStringInfo
