<template>
  <v-list class="pa-0 header-menu">
    <v-list-item class="pa-0 header border-color-bodygrey">
      <div class="pa-0 flex-grow-0 flex-shrink-1 align-self-stretch" style="min-width:46px">
        <a class="logo" href="/?href=_self">
          <WIcon icon="wizville" :icon-style="{fontSize: '30px', color: 'fff'}"/>
        </a>
      </div>
      <div align="center" class="ml-auto close-button pa-0 flex-grow-0 flex-shrink-1 align-self-stretch border-color-bodygrey">
        <a @click="$emit('closeMenuClicked')">
          <v-icon class="mt-0 f-25">mdi-close</v-icon>
        </a>
      </div>
    </v-list-item>
  </v-list>
</template>

<script>
  import { mapGetters } from "vuex"

  export default {
    name: "HeaderMenu",
    components: {},
    computed: {
      ...mapGetters(
        [
          'currentUser'
        ]
      )
    }
  }
</script>

<style lang="stylus">
  .header-menu
    .logo
      color: #fff
      height: 100%
      min-width: 56px
      background-color: #65c095
      text-align: center
      line-height: 46px
      display: inline-block

    .header
      border-bottom: 1px solid
      .close-button
        min-width: 46px
        line-height: 46px
        border-left: 1px solid
        .v-icon
          min-height:46px

    .v-list-item__icon:first-child
      margin-right: 4px !important

    .v-list-item__icon
      .v-icon
        margin-top: 0px !important
        margin-left: 3px
        font-size: 15px
</style>

