const snackbar = {
  state: () => ({ 
    snackbar: {
      display: false,
      color: "primary",
      timeout: 2000,
      message: 'done',
      icon: undefined,
      multiLine: false,
      top: true,
      right: true,
      left: false ,
      bottom: false,
      absolute: false,
      vertical: false
    }
  }),
  getters: {
    snackbar(state, getters) {
      return state.snackbar
    }
  },
  mutations: {
    updateSnackbarDisplay(state, display) {
      state.snackbar.display = display
    },
    updateSnackbar(state, payload) {
      state.snackbar = payload
    }
  },
  actions: {
    updateSnackbarDisplay(context, display) {
      context.commit('updateSnackbarDisplay', display)
    },
    notifySuccess(context, props = {}) {
      context.commit('updateSnackbar', propsOrDefault("success", "Opération effectuée", "mdi-check", props))
    },
    notifyError(context, props = {}) {
      context.commit('updateSnackbar', propsOrDefault("error", "Opération non effectuée", "mdi-alert-circle-outline", props))
    },
    notifyInfo(context, props = {}) {
      context.commit('updateSnackbar', propsOrDefault("info", "Opération en cours", "mdi-information-outline", props))
    },
    notifyWarning(context, props = {}) {
      context.commit('updateSnackbar', propsOrDefault("warning", "Opération prise en compte", "mdi-information-outline", props))
    },
    notifySecondary(context, props = {}) {
      context.commit('updateSnackbar', propsOrDefault("secondary", "Opération en cours", undefined, props))
    },
    notify(context, props = {}) {
      context.commit('updateSnackbar', propsOrDefault("primary", "Opération en cours", undefined, props))
    }
  }
}

function propsOrDefault(color, defaultMessage, defaultIcon, props) {
  if (props.newLine) {
    props.message = props.message.replace(/\n/g, '<br>').replace(/\\n/g, '<br>')
  }

  return {
    display: true,
    color: props.color || color,
    icon: props.icon || defaultIcon,
    multiLine: props.multiLine || false,
    top: props.top || true,
    right: props.right || true,
    left: props.left || false,
    timeout: props.timeout || 2000,
    bottom: props.bottom || false,
    absolute: props.absolute || false,
    vertical: props.vertical || false,
    message: props.message || defaultMessage
  }
}

export default snackbar