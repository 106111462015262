import HomeLayout from '@platform/layouts/HomeLayout'
import { legacyOldUrl } from '@shared/helpers/env-helpers'
import { resetUser as resetAnalyticsUser } from '@shared/helpers/analytics/base'

export default {
  path: '/',
  component: HomeLayout,
  redirect: { name: 'Login' },
  children: [
    {
      path: '/login/:sso?',
      name: 'Login',
      meta: { public: true, authRedirected: 'Dashboards' },
      props: (route) => ({
        sso: route.params.sso
      }),
      component: () => import('@platform/pages/home/LoginPage'),
    },
    {
      path: '/password/forgotten',
      name: 'ForgottenPassword',
      meta: { public: true },
      component: () => import('@platform/pages/home/ForgottenPassword'),
    },
    {
      path: '/password/reset',
      name: 'ResetPassword',
      meta: { public: true },
      component: () => import('@platform/pages/home/ResetPassword'),
    },
    {
      path: '/email/confirm',
      name: 'ConfirmEmail',
      meta: { public: true },
      component: () => import('@platform/pages/home/ConfirmEmail'),
    },
    {
      path: '/logout',
      name: 'Logout',
      meta: { public: true },
      async beforeEnter(to, from, next) {
        const urlParams = new URLSearchParams(window.location.search);
        const logout = urlParams.get('logout')
        const error = urlParams.get('error')

        if (error) {
          localStorage.setItem("error", error)
        }

        if (logout === 'ok') {
          const ssoSession = window.app.$cryptr.getCurrentAccessToken()
          
          localStorage.removeItem('wizvilleSessionToken')
          window.Intercom('shutdown')
          resetAnalyticsUser()
          

          window.location.href = (ssoSession ? '/login/sso?ssout=1' : '/login' )
        } else {
          window.location.href = `${legacyOldUrl}/logout`
        }
      },
      component: () => import('@platform/pages/home/LoginPage'),
    },
  ],
}
