var render = function render(){var _vm=this,_c=_vm._self._c;return _c('WLateralSectionLayout',{staticClass:"pl-3 nis-zoom",scopedSlots:_vm._u([{key:"lateral-section",fn:function(){return [_c('TopicRoiSatisfactionWidget',{attrs:{"campaign":_vm.campaign,"topic":_vm.topic,"nisTopicSummary":_vm.nisTopicSummary}}),_vm._v(" "),_c('TransactionalUnitToEarnWidget',{attrs:{"campaign":_vm.campaign,"linkTo":{
        name: 'Statistics',
        params: {
          sectionType: 'topics',
          sectionId: _vm.topic.id,
          subSectionId: _vm.campaign.id
        }
      },"summary":_vm.nisTopicSummary,"topic":_vm.topic,"voterProportions":_vm.topic}})]},proxy:true},{key:"main-section",fn:function(){return [_c('TopicTransactionalUnitRepartitionWidget',{attrs:{"campaign":_vm.campaign,"topic":_vm.topic,"nisTopicSummary":_vm.nisTopicSummary}}),_vm._v(" "),_c('TopicRoiSatisfactionEvolutionWidget',{attrs:{"campaign":_vm.campaign,"topic":_vm.topic,"nisTopicSummary":_vm.nisTopicSummary}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }