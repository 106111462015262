import { render, staticRenderFns } from "./SocialPositiveTopics.vue?vue&type=template&id=0835fb7a&scoped=true&"
import script from "./SocialPositiveTopics.vue?vue&type=script&lang=js&"
export * from "./SocialPositiveTopics.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0835fb7a",
  null
  
)

export default component.exports