import { mapGetters } from 'vuex'

const AVAILABLE_DYNAMIC_FIELDS = [
  'first_name',
  'last_name',
  'email',
  'phone_number',
  'customer_number',
  'purchase_date',
  'delivery_date',
  'service_date',
  'products',
  'employee_first_name',
  'employee_last_name',
  'civility',
  'rehire_date',
  'matricule',
  'employment',
  'tpl_tpa',
  'full_part_time',
  'sort',
  'price',
  'surface',
  'mandate_sort',
  'nb_room',
  'nb_bedroom',
  'ticket_number',
  'order_number',
  'origin',
  'marketing_code',
  'delivery_mode',
  'purchase_amount',
  'card_number',
  'card_status',
  'city',
  'language',
  'service_type',
  'birthdate',
  'company',
  'customer_category',
  'seller',
  'kind_of_product',
  'code_category1',
  'label_category1',
  'code_category2',
  'label_category2',
  'kind_of_correction',
  'category',
  'classify_level',
  'coefficient',
  'contract',
  'amex',
  'comments',
]

export default {
  computed: {
    ...mapGetters([
      'currentUser',
      'currentUserBrandName',
      'currentVoter'
    ])
  },
  asyncComputed: {
    dynamicFieldsMixin_gBrandName: {
      watch: ['currentVoter.placeId'],
      default() {
        return this.currentUserBrandName
      },
      async get() {
        const req = this.$basedRequest()
          .select({
            groups: ['name'],
          })
          .where({
            place_groups_place_id: this.currentVoter.placeId,
            semantic: 'brand',
          })

        const response = (await this.$resolve(req)).first()

        return response.name || this.currentUserBrandName
      }
    },
    dynamicFieldsMixin_fieldsCustomization: {
      default: {},
      watch: ['currentVoter.campaignId'],
      async get() {
        const req = this.$basedRequest()
          .select({
            emailings_preferences: ['fields_customization'],
          })
          .where({ campaign_id: this.currentVoter.campaignId })

        const response = (await this.$resolve(req)).first()

        return response?.fieldsCustomization ?
               JSON.parse(response.fieldsCustomization) :
               {}
      }
    },
    dynamicFieldsMixin_prospectFields: {
      default: {},
      watch: ['currentVoter.prospectId'],
      async get() {
        if (!this.currentVoter.prospectId) {
          return {}
        }

        const req = this.$basedRequest()
          .select({
            prospects: AVAILABLE_DYNAMIC_FIELDS,
          })
          .where({ id: this.currentVoter.prospectId })

        return (await this.$resolve(req)).first()
      }
    },
  },
}
