<template>
  <WStatsWidget
    class="product-recommandation-rate-widget"
    :title="title"
    :subtitle="displayedSubtitle"
    height="200px"
    :cols="cols"
    :loading="loading"
    :empty="!rateResults['rate'] || rateResults['rate'] === null"
    ref="widget"
  >
    <template #content>
      <WRatingCircularWithEvol
        :value="rateResults['rate']"
        :evolution="rateResults['evolution']"
        :nbReview="rateResults['nbReview']"
      />
    </template>
  </WStatsWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import { sqlDate } from '@vue/plugins/helpers/dates'
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'

export default {
  name: 'ProductRecommendationRateWidget',
  props: {
    campaignId: { required: true },
    cols: { type: Number, default: 3 },
  },
  mixins: [
    WidgetMixin,
  ],
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'dashboardFilterDates',
      'dashboardFilterRequest',
      'hasAccessToAllDashboardPlaces',
      'datesScope',
      'networkRankingName'
    ]),
    basedRequest() {
      return this.dashboardFilterRequest.select({
        voters: [{
          product_ratio_recommendation: { as: 'rate' },
          COUNT_DISTINCT_id: { as: 'nbReview' }
        }]
      })
    },
    title() {
      return this.$t('recommendation_percent')
    }
  },
  asyncComputed: {
    rateResults: {
      default: {
        evolution: 0,
        rate: 0,
        nbReview: 0
      },
      async get() {
        this.loading = true

        let request = this.basedRequest
        let resultPeriod = (await (this.$resolve(request))).first()

        let requestCompared = this.basedRequest.dateBetween(
          sqlDate(this.datesScope.comparedDateBegin),
          sqlDate(this.datesScope.comparedDateEnd, '23:59:59')
        )
        let resultCompared = (await (this.$resolve(requestCompared))).first()

        this.loading = false
        return {
          evolution: (resultPeriod.rate - resultCompared.rate),
          rate: Number(resultPeriod.rate),
          nbReview: Number(resultPeriod.nbReview)
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.product-recommandation-rate-widget-link
  display contents
</style>
