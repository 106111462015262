<template>
  <v-row
    no-gutters
    class="verbatim-topic-rankings"
    v-if="active"
    :style="{ maxWidth: '100%' }"
  >
    <VerbatimTopicRanking 
      :countryCode="countryCode"
      :strengths="true" 
      :ranking="ranking"
      :loading="!ranking"
      :active="active"
    />

    <VerbatimTopicRanking
      :countryCode="countryCode"
      :weaknesses="true"
      :ranking="ranking"
      :loading="!ranking"
      :active="active"
    />

    <CompetitivityTopicRanking 
      v-if="!fullScreenMode"
      :countryCode="countryCode"
      :ranking="ranking"
      :loading="!ranking"
      :active="active"
    />

    <ExpectationTopicRanking
      v-if="active.type === 'lmBrand'" 
      :countryCode="countryCode"
      :ranking="ranking"
      :loading="!ranking"
      :active="active"
    />
  </v-row>
</template>

<script>
  import VerbatimTopicRanking from './VerbatimTopicRanking'
  import CompetitivityTopicRanking from './CompetitivityTopicRanking'
  import ExpectationTopicRanking from './ExpectationTopicRanking'
  import { mapGetters } from 'vuex'

  export default {
    name: "VerbatimTopicRankings",
    props: [ 'campaignSmartBenchmarkPreferenceId', 'countryCode', 'active' ],
    components: {
      VerbatimTopicRanking,
      CompetitivityTopicRanking,
      ExpectationTopicRanking
    },
    computed: {
      ...mapGetters([
        'sqlDatesScope',
        'currentDashboard',
        'lmGmbPlaceIdsScope',
        'fullScreenMode'
      ]),
      baseRequest() {
        return this.$basedRequest()
      },
      ranking() {        
        const result = this.verbatimTopicRanking?.map(row => ({
          ...row,
          current: row.ranking.find(item => item.id === Number(this.active.id)),
          user: row.ranking.find(item => this.userScopeIds.includes(item.id))
        }))

        return result
      },
      userScopeIds() {
        return (this.active.type === 'lmBrand' ? this.currentDashboard.lmBrandIds : this.lmGmbPlaceIdsScope)
          
      },
      rankingColumn() {
        if (this.active.type === 'lmBrand') {
          return { 
            lm_brand_verbatim_topic_ranking: {
              as: 'verbatimTopicRanking', params: {
                date_begin: this.sqlDatesScope.dateBegin, 
                date_end: this.sqlDatesScope.dateEnd,
                country_code: this.countryCode,
                user_lm_brand_ids: this.currentDashboard.lmBrandIds
              }
            } 
          }
        } else if (this.active.type === 'lmPlace') {
          return { 
            lm_place_verbatim_topic_ranking: {
              as: 'verbatimTopicRanking', params: {
                place_id: this.active.placeId,
                date_begin: this.sqlDatesScope.dateBegin, 
                date_end: this.sqlDatesScope.dateEnd
              }
            } 
          }
        }
      }
    },
    asyncComputed: {
      async verbatimTopicRanking() {
        const request = this.baseRequest.select({ 
          campaign_smart_benchmark_preferences: [ 
            this.rankingColumn
          ]
        }).where({
          id: this.campaignSmartBenchmarkPreferenceId
        })
        
        const response = (await this.$resolve(request))?.first()

        return response.verbatimTopicRanking
      }
    }
  }
</script>
