<template>
  <v-form v-model="isValid" class="d-flex align-center ml-2">
    <div class="d-flex align-center">
      <v-text-field
        v-model="newName"
        class="template-name f-14 mr-2"
        :rules="[$required]"
        required
        dense
        filled
        outlined
        background-color="#eef1f5"
        hide-details="auto"
        :label="$t('achievements_settings_action_template_name')"
      >
        {{ newName }}
      </v-text-field>
      <w-emoji-picker
        @emojiSelected="onEmojiSelected"
        :initEmoji="newEmoji"
      />
    </div>
    <div class="ml-auto mr-2">
      <v-icon
        class="c-primary f-20"
        @click="onCancelClicked"
      >
        mdi-close-thick
      </v-icon>
      <v-icon
        class="c-primary f-20"
        @click="onSaveClicked"
      >
        mdi-check-bold
      </v-icon>
    </div>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Form',
    components: {

    },
    data () {
      return {
        newEmoji: null,
        newName: '',
        isValid: false,
        loading: false
      }
    },
    mounted() {
      this.newName = this.achievementActionTemplate?.name || ''
      this.newEmoji = this.achievementActionTemplate?.emoji
    },
    props: {
      achievementActionTemplate: {
        required: false
      }
    },
    methods: {
      onCancelClicked() {
        this.$emit('cancel')
      },
      async onSaveClicked() {
        if (this.isValid) {
          this.$emit('save', { name: this.newName, emoji: this.newEmoji })
        }
      },
      onEmojiSelected(emoji) {
        this.newEmoji = emoji
      }
    }
  }
</script>

<style lang="stylus" scoped>
</style>
