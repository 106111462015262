<template>
  <div
    class="timeline d-flex flex-column ga-10 px-0"
    v-if="!loading"
  >
    <div
      v-for="(timelineItem, index) in voterTimeline"
      ref="items"
    >
      <div class="timeline-item"
        :data-timeline-item-id="timelineItem.uniqueId || timelineItem.id"
        :class="{ 'mb-3': index < voterTimeline.length - 1 }"
      >
        <UserMessage
          :key="timelineItem.id"
          :voter="voter"
          :message="timelineItem"
          v-if='timelineItem.type === "Message" && timelineItem.sender === "user"'
          :isFirstUserMessage="isFirstUserMessage(timelineItem)"
        />
        <VoterMessage
          :key="timelineItem.id"
          :voter="voter"
          :message="timelineItem"
          v-if='timelineItem.type === "Message" && timelineItem.sender === "voter"'
          :isLastVoterMessage="isLastVoterMessage(timelineItem)"
          :isFirstUserMessage="isFirstUserMessage(timelineItem)"
        />
        <CallReport :key="timelineItem.id" :voter="voter" :callReport="timelineItem" v-if='timelineItem.type === "CallReport"' />
        <InternalMemo :key="timelineItem.id" :voter="voter" :internalMemo="timelineItem" v-if='timelineItem.type === "InternalMemo"' />
        <VoterClosing :key="timelineItem.id" :voter="voter" :voterClosing="timelineItem" v-if='timelineItem.type === "VoterClosing"' />
        <VoterTransfer :key="timelineItem.id" :voter="voter" :voterTransfer="timelineItem" v-if='timelineItem.type === "VoterTransfer"' />
        <VoterThirdPartyTransfer :key="timelineItem.id" :voter="voter" :voterThirdPartyTransfer="timelineItem" v-if='timelineItem.type === "VoterThirdPartyTransfer"' />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import UserMessage from "./UserMessage"
  import VoterMessage from "./VoterMessage"
  import CallReport from "./CallReport"
  import VoterClosing from "./VoterClosing"
  import InternalMemo from "./InternalMemo"
  import VoterTransfer from "./VoterTransfer"
  import VoterThirdPartyTransfer from "./VoterThirdPartyTransfer"
  import VoterMixin from "../../voter_mixin"

  export default {
    name: "VoterTimeline",
    mixins: [
      VoterMixin
    ],
    data() {
      return {
        reload: 0,
        loading: false,
        scrollTo: null
      }
    },
    components: {
      UserMessage,
      VoterMessage,
      CallReport,
      VoterClosing,
      InternalMemo,
      VoterTransfer,
      VoterThirdPartyTransfer
    },
    computed: {
      ...mapGetters([
        'voterUpdateEvent',
        'currentVoterMobileTab'
      ])
    },
    methods: {
      isFirstUserMessage(timelineItem) {
        const firstUserMessageId = this.voterTimeline.map(
          (item) => ( item.type === "Message" && item.sender === 'user') ? item.id : null
        ).filter(Number)[0]

        if (firstUserMessageId === undefined) {
          return true
        }
        return firstUserMessageId && firstUserMessageId === timelineItem.id
      },
      isLastVoterMessage(timelineItem) {
        const lastTimelineItem = this.voterTimeline[this.voterTimeline.length - 1];

        return lastTimelineItem &&
          lastTimelineItem.id === timelineItem.id
          timelineItem.type === 'Message' &&
          timelineItem.sender === 'voter'
      },
      scrollToElement() {
        if (this.$refs?.items?.length === this.voterTimeline.length && this.scrollTo) {
          const timelineElement = this.$el
          const overflowElement = timelineElement.closest('.overflow')

          if (this.scrollTo === 'end') {
            overflowElement.scrollTop = overflowElement.scrollHeight
          } else {
            const timelineItems = timelineElement.querySelectorAll('.timeline-item')
            const scrollToElement = timelineElement
              .querySelector(`.timeline-item[data-timeline-item-id="${this.scrollTo}"]`)

            if (scrollToElement) {
              const pos = scrollToElement.offsetTop + scrollToElement.offsetHeight

              overflowElement.scrollTop = pos
            }
          }
          this.scrollTo = null
        }
      }
    },
    updated() {
      this.$nextTick(function () {
        this.scrollToElement()
      })
    },
    mounted() {
      if (this.voterNeedsMessageReview) {
        this.scrollTo = 'end'
      }
    },
    asyncComputed: {
      voterTimeline: {
        async get() {
          this.loading = true
          const request = this.$basedRequest().where({
            unique_id: [ this.voter.uniqueId ]
          }).select({
            voters: ["voter_timeline"]
          })

          const timeline = (await this.$resolve(request)).first()

          this.loading = false
          return timeline.voterTimeline
        },
        default: [],
        watch: [ 'reload' ]
      }
    },
    watch: {
      voterUpdateEvent: function (voterUpdateEvent) {
        if (voterUpdateEvent.voter.uniqueId === this.voter?.uniqueId) {
          this.reload = this.reload + 1
          this.scrollTo = voterUpdateEvent.voter.timelineELementUniqueId || 'end'
        }
      }
    },
  }
</script>

<style lang='stylus' scoped>
</style>

