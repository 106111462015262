<template>
  <v-card>
    <v-form
      class="smart-benchmark-form"
      ref="form"
      v-model="valid"
      v-if="campaignSmartBenchmarkPreferences"
    >
      <v-card-text>
        <div class="d-flex align-center justify-end">
          <v-btn
            v-if="!loading"
            color="primary"
            @click="save"
            :loading="saving"
            :disabled="!valid || selectedTopics.length == 0 || !hasChanged"
          >
            {{ $t("save") }}
          </v-btn>
          <w-drop-down-menu
            class="f-16"
            :items="otherActions"
            icon="mdi-dots-horizontal"
            :iconProperties="otherActionsIconProperties"
            :showArrow="false"
          />
        </div>
        <v-row>
          <v-col cols="12" md="6">
            <h4 class="f-16 c-primary mb-2">{{ $t('campaign_smart_benchmark_preferences_parameters') }}</h4>
              <div v-if="!loading">
                <v-row>
                  <v-col class="d-flex align-center">
                    <v-btn-toggle
                      v-model="campaignSmartBenchmarkPreferences.enabled"
                      mandatory
                      color="info"
                      flat
                      outlined
                    >
                      <v-btn @click="setChange" :value="false" class="v-btn v-size--default ">
                        <span>OFF</span>
                      </v-btn>
                        <v-btn @click="setChange" :value="true" class="v-btn v-size--default">
                        <span>ON</span>
                      </v-btn>
                    </v-btn-toggle>
                    <v-text-field
                      outlined
                      dense
                      background-color="#eef1f5"
                      v-model="campaignSmartBenchmarkPreferences.name"
                      :label="$t('campaign_smart_benchmark_preferences_name')"
                      @input="setChange"
                      hide-details="auto"
                      :rules="[$rules.required]"
                      class="ml-4"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      background-color="#eef1f5"
                      v-model="campaignSmartBenchmarkPreferences.nbMonthForTopicWeight"
                      :label="'Période d\'analyse des attentes consommateur'"
                      type="number"
                      @input="setChange"
                      hide-details="auto"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      background-color="#eef1f5"
                      v-model="campaignSmartBenchmarkPreferences.minTopicReviewForRanking"
                      :label="'Minimum d\'avis par sujet ou thématique'"
                      type="number"
                      @input="setChange"
                      hide-details="auto"
                    />
                  </v-col>
                </v-row>
                <!-- Commented for now as it's not implemented-->
                <v-row>
                  <v-col>
                    <v-select
                      :items="segmentationOptions"
                      v-model="campaignSmartBenchmarkPreferences.byDefaultDisplayThemes"
                      outlined
                      dense
                      @input="setChange"
                      :label="$t('campaign_smart_benchmark_preferences_segmentation')"
                      hide-details="auto"
                      background-color="#eef1f5"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="mt-0">
                    <h5 class="f-14 c-darkergrey">{{ $t('lrm_select_brand_category_competitors') }}</h5>
                    <v-checkbox
                      :ripple="false"
                      v-model="campaignSmartBenchmarkPreferences.useLrmSearchBrandCategories"
                      :label="$t('campaign_smart_benchmark_preferences_use_lrm_search_parameters')"
                      class="mt-1 mb-2"
                      @change="setChange"
                      hide-details="auto"
                      dense
                    />
                    <v-autocomplete
                      v-if="!campaignSmartBenchmarkPreferences.useLrmSearchBrandCategories"
                      v-model="campaignSmartBenchmarkPreferences.lmBrandCategoryIds"
                      :rules="[$rules.required]"
                      :items="lmBrandCategoryOptions"
                      outlined
                      dense
                      background-color="#eef1f5"
                      multiple
                      hide-details="auto"
                      @input="setChange"
                      :label="$t('lrm_select_brand_category_competitors')"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col>
                    <h5 class="f-14 c-darkergrey">{{ $t('lrm_select_brand_competitors') }}</h5>
                    <v-checkbox
                      :ripple="false"
                      v-model="campaignSmartBenchmarkPreferences.useLrmSearchBrands"
                      :label="$t('campaign_smart_benchmark_preferences_use_lrm_search_parameters')"
                      class="mt-1 mb-2"
                      @change="setChange"
                      hide-details="auto"
                      dense
                    />
                    <v-autocomplete
                      v-if="!campaignSmartBenchmarkPreferences.useLrmSearchBrands"
                      v-model="campaignSmartBenchmarkPreferences.lmBrandIds"
                      :rules="[$rules.required]"
                      :items="lmBrandOptions"
                      outlined
                      dense
                      background-color="#eef1f5"
                      multiple
                      hide-details="auto"
                      @input="setChange"
                      :label="$t('lrm_select_brand_competitors')"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </div>
              <v-skeleton-loader v-else type="paragraph" />
          </v-col>
          <v-col cols="12" md="6" class="pl-3">
            <h4 class="f-16 c-primary mb-2">{{ $t('topics') }}</h4>
            <v-data-table
              v-if="topicOptions && !loading"
              v-model="selectedTopics"
              :headers="headers"
              :items="topicOptions"
              disable-pagination
              hide-default-header
              hide-default-footer
              sort-by="topicName"
              group-by="themeName"
              item-key="topicId"
              show-group-by
              dense
              showSelect
              fixedHeader
              @item-selected="setChange"
              height="calc(100vh - 95px)"
              mobile-breakpoint="0"
            >
              <template #group.header="{ group }">
                <td colspan="2">
                  {{ group }}
                </td>
              </template>
            </v-data-table>
            <v-skeleton-loader v-else type="paragraph" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _uniq from 'lodash/uniq'

  const DEFAULT_CAMPAIGN_SMART_BENCHMARK_PREFERENCES = {
    isDefault: true,
    enabled: false,
    minTopicReviewForRanking: 30,
    nbMonthForTopicWeight: 12,
    byDefaultDisplayThemes: false,
    lmBrandCategoryIds: [],
    lmBrandIds: [],
    topicIds: [],
    useLrmSearchBrandCategories: true,
    useLrmSearchBrands: true
  }

  export default {
    name: 'Form',
    components: {},
    props: ['currentCampaignSmartBenchmarkPreferences'],
    data() {
      return {
        valid: true,
        saving: false,
        makingDefault: false,
        hasChanged: false,
        headers: [
          { text: 'theme', value: 'themeName' },
          { text: 'topic', value: 'topicName' }
        ],
        selectedTopics: [],
        loading: true,
        topicsLoading: true
      }
    },
    computed: {
      ...mapGetters([
        'currentCampaignPreferencesCampaignId'
      ]),
      otherActionsIconProperties() {
        return {
          color: 'c-lightgrey',
          size: 'f-24'
        }
      },
      otherActions() {
        const actions = [
          {
            title: this.$t("campaign_smart_benchmark_preferences_make_default"),
            onClick: this.makeDefault,
            displayCondition: !this.campaignSmartBenchmarkPreferences.isDefault,
            class: 'c-textgrey'
          },
          {
            title: this.$t("campaign_smart_benchmark_preferences_duplicate"),
            onClick: this.duplicate,
            class: 'c-textgrey'
          },
          {
            title: this.$t("campaign_smart_benchmark_preferences_remove"),
            onClick: this.remove,
            class: 'c-textgrey'
          },
        ]

        return actions.filter((action) => action.displayCondition === undefined || action.displayCondition)
      },
      selectedTopicIds() {
        return this.selectedTopics?.map(e => e.topicId)
      },
      segmentationOptions() {
        return [
          {
            text: this.$t('campaign_smart_benchmark_preferences_segmentation_topics'),
            value: false
          },
          {
            text: this.$t('campaign_smart_benchmark_preferences_segmentation_themes'),
            value: true
          },
        ]
      },
    },
    asyncComputed: {
      lrmBrandGmbPreferences: {
        async get() {
          const request = this.$basedRequest().select(
            {
              brands: [ "lrm_brand_gmb_preferences" ]
            }
          )

          return (await this.$resolve(request)).first().lrmBrandGmbPreferences
        },
        default: null,
      },
      campaignSmartBenchmarkPreferences: {
        async get() {
          this.loading = true

          let request = this.$basedRequest().select({
            campaign_smart_benchmark_preferences: [
              { 'MAX_id': { as: 'id' } },
              { 'MAX_name': { as: 'name' } },
              { 'BOOL_AND_is_default': { as: 'isDefault' } },
              { 'BOOL_AND_enabled': { as: 'enabled' } },
              { 'MAX_min_topic_review_for_ranking': { as: 'minTopicReviewForRanking' } },
              { 'MAX_nb_month_for_topic_weight': { as: 'nbMonthForTopicWeight' } },
              { 'BOOL_AND_by_default_display_themes': { as: 'byDefaultDisplayThemes' } },
              { 'lm_brand_category_ids': { as: 'lmBrandCategoryIds' } },
              { 'lm_brand_ids': { as: 'lmBrandIds' } },
              { 'topic_ids': { as: 'topicIds' } }
            ]
          }).where({
            campaign_id: this.currentCampaignPreferencesCampaignId
          })

          if (this.currentCampaignSmartBenchmarkPreferences) {
            request = request.where({
              id: this.currentCampaignSmartBenchmarkPreferences.id
            })
          }

          const campaignSmartBenchmarkPreferences = (await this.$resolve(request)).first()

          if (campaignSmartBenchmarkPreferences.id) {

            campaignSmartBenchmarkPreferences.topicIds = this.formatIds(
              campaignSmartBenchmarkPreferences.topicIds
            )

            campaignSmartBenchmarkPreferences.lmBrandCategoryIds = this.formatIds(
              campaignSmartBenchmarkPreferences.lmBrandCategoryIds
            )

            campaignSmartBenchmarkPreferences.lmBrandIds = this.formatIds(
              campaignSmartBenchmarkPreferences.lmBrandIds
            )

            campaignSmartBenchmarkPreferences.useLrmSearchBrandCategories =
              campaignSmartBenchmarkPreferences.lmBrandCategoryIds?.length === 0

            campaignSmartBenchmarkPreferences.useLrmSearchBrands =
              campaignSmartBenchmarkPreferences.lmBrandIds?.length === 0

            this.selectedTopics = campaignSmartBenchmarkPreferences.topicIds.map(topicId => ({ topicId: Number(topicId) }))

            this.loading = false

            return campaignSmartBenchmarkPreferences
          } else {
            this.loading = false

            return DEFAULT_CAMPAIGN_SMART_BENCHMARK_PREFERENCES
          }
        },
        default: DEFAULT_CAMPAIGN_SMART_BENCHMARK_PREFERENCES
      },
      async topicOptions() {
        this.topicsLoading = true

        const stemGroupThemesReq = this.$basedRequest().select({
          stem_group_themes: [ 'theme_and_topics' ]
        })

        const stemGroupThemes = (await this.$resolve(stemGroupThemesReq)).data.stemGroupThemes
        const stemGroupTopicOptions = []

        stemGroupThemes.forEach(stemGroupTheme => {
          const themeAndTopics = stemGroupTheme.themeAndTopics

          themeAndTopics.stemGroupTopics.forEach(stemGroupTopic => {
            stemGroupTopicOptions.push({
              topicName: stemGroupTopic.name,
              topicId: stemGroupTopic.id,
              themeName: themeAndTopics.name,
              themeId: themeAndTopics.id
            })
          })
        })

        this.topicsLoading = false

        return stemGroupTopicOptions
      },
      lmBrandCategoryOptions: {
        async get() {
          const lmBrandCategoryIds = this.lrmBrandGmbPreferences?.defaultSearchPreference?.lmBrandCategoryIds

          if (lmBrandCategoryIds) {
            const request = this.$basedRequest().select({
              lm_brand_categories: [ { id: { as: 'value' } }, { label: { as: 'text' } } ]
            }).where({
              id: lmBrandCategoryIds
            }).order(['label', 'asc'])

            return (await this.$resolve(request)).data?.lmBrandCategories
          }
        },
        default: []
      },
      lmBrandOptions: {
        async get() {
          const lmBrandIds = this.lrmBrandGmbPreferences?.defaultSearchPreference?.lmBrandIds

          if (lmBrandIds) {
            const request = this.$basedRequest().select({
              lm_brands: [ { id: { as: 'value' } }, { name: { as: 'text' } } ]
            }).where({
              id: lmBrandIds
            }).order(["name", "asc"])

            return (await this.$resolve(request)).data?.lmBrands
          }
        },
        default: []
      }
    },
    methods: {
      formatIds(ids) {
        return _uniq(
          ids
        ).filter(
          n => n
        ).map(
          n => Number(n)
        )
      },
      setChange() {
        this.hasChanged = true
      },
      async save() {
        this.saving = true

        try {
          delete this.campaignSmartBenchmarkPreferences.topicIds
          await this.$api.wizville.campaignPreferences.editSmartBenchmarkPreferences(
            this.currentCampaignPreferencesCampaignId,
            this.campaignSmartBenchmarkPreferences,
            this.selectedTopicIds
          )
          this.$store.commit("setSmartBenchmarkEnabled", this.campaignSmartBenchmarkPreferences.enabled)
          this.$store.dispatch("notifySuccess")
          this.$emit('saved')
          this.hasChanged = false
        } catch (e) {
          this.$store.dispatch("notifyError")
        }
        this.saving = false

      },
      async makeDefault() {
        this.makingDefault = true

        try {
          await this.$api.wizville.campaignPreferences.makeDefaultSmartBenchmarkPreferences(
            this.currentCampaignPreferencesCampaignId,
            { id: this.campaignSmartBenchmarkPreferences.id }
          )
          this.campaignSmartBenchmarkPreferences.isDefault = true
          this.$store.dispatch("notifySuccess")
          this.$emit('makedDefault')
        } catch (e) {
          this.$store.dispatch("notifyError")
        }
        this.makingDefault = false
      },
      async duplicate() {
        const response = await this.$api.wizville.campaignPreferences.duplicateSmartBenchmarkPreferences(
          this.currentCampaignPreferencesCampaignId,
          { id: this.campaignSmartBenchmarkPreferences.id }
        )

        if (response.status === "ok") {
          this.$store.dispatch('notifySuccess', {
            message: this.$t('campaign_smart_benchmark_preferences_successfully_duplicated')
          })
          this.$emit('duplicated', response.campaignSmartBenchmarkPreferencesId)
        } else {
          this.$store.dispatch("notifyError")
        }
      },
      async remove() {
        const response = await this.$api.wizville.campaignPreferences.deleteSmartBenchmarkPreferences(
          this.currentCampaignPreferencesCampaignId,
          { id: this.campaignSmartBenchmarkPreferences.id }
        )

        if (response.status === "ok") {
          this.$store.dispatch('notifySuccess', {
            message: this.$t('campaign_smart_benchmark_preferences_successfully_deleted')
          })
          this.$emit('removed')
        } else {
          this.$store.dispatch("notifyError")
        }
      }
    },
    watch: {
      currentCampaignSmartBenchmarkPreferences() {
        this.hasChanged = false
      },
      lmBrandOptions() {
        if (this.lmBrandOptions?.length && this.campaignSmartBenchmarkPreferences?.lmBrandIds?.length) {
          this.campaignSmartBenchmarkPreferences.lmBrandIds = this.lmBrandOptions.filter(lmBrandOption => this.campaignSmartBenchmarkPreferences.lmBrandIds.includes(lmBrandOption.value)).map(filteredLmBrand => filteredLmBrand.value)
        }
      },
      lmBrandCategoryOptions() {
        if (this.lmBrandCategoryOptions?.length && this.campaignSmartBenchmarkPreferences?.lmBrandCategoryIds?.length) {
          this.campaignSmartBenchmarkPreferences.lmBrandCategoryIds = this.lmBrandCategoryOptions.filter(lmBrandCategory => this.campaignSmartBenchmarkPreferences.lmBrandCategoryIds.includes(lmBrandCategory.value)).map(filteredLmBrandCategory => filteredLmBrandCategory.value)
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
</style>
