<template>
  <v-card
    class="campaign-smart-benchmark-preferences-table"
  >
    <v-card-title>
      <h4 class="f-16 c-primary">{{ $t('campaign_smart_benchmark_preferences') }}</h4>
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-if="!loading"
        :headers="headers"
        :items="campaignSmartBenchmarkPreferences"
        :items-per-page="40"
        hide-default-footer
        mobile-breakpoint="0"
        :item-class= "rowClasses"
        class="v-datatable-without-y-borders"
      >
        <template v-slot:item.name="{ item }">
          <div class="d-flex align-center">
            <span>
              {{ item.name }}
            </span>
            <v-chip
              v-if="item.isDefault"
              class="ml-2"
              label
              x-small
            >
              {{ $t('campaign_smart_benchmark_preferences_default_name') }}
            </v-chip>
          </div>
        </template>
        <template v-slot:item.enabled="{ item }">
          <v-icon
            class="c-primary f-20"
            :class="{ 'c-secondary': item.enabled, 'c-error': !item.enabled }"
          >
            mdi-checkbox-blank-circle
          </v-icon>
        </template>
        <template v-slot:item.byDefaultDisplayThemes="{ item }">
          {{ computeSegmentation(item) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex align-center flex-nowrap">
            <v-icon
              class="ml-auto c-secondary f-20"
              @click="zoom(item)"
            >
              mdi-pencil
            </v-icon>

            <v-icon
              class="c-secondary f-20 ml-2"
              @click="duplicate(item)"
            >
              mdi-content-copy
            </v-icon>

            <v-icon
              v-if="!item.isDefault"
              class="c-secondary f-20 ml-2"
              @click="remove(item)"
            >
              mdi-delete
            </v-icon>
          </div>
        </template>
      </v-data-table>
      <v-skeleton-loader v-else type="paragraph" />
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "CampaignSmartBenchmarkPreferencesTable",
    props: {
      currentCampaignSmartBenchmarkPreferences: { required: false },
    },
    data() {
      return {
        loading: true,
        reload: 0
      }
    },
    computed: {
      ...mapGetters([
        'currentCampaignPreferencesCampaignId'
      ]),
      headers() {
        let headers = [
          {
            text: this.$t('campaign_smart_benchmark_preferences_table_headers_name'),
            value: 'name',
            cellClass: 'name-cell text-truncate'
          },
          {
            text: this.$t('campaign_smart_benchmark_preferences_table_headers_enabled'),
            value: 'enabled',
          },
          {
            text: this.$t('campaign_smart_benchmark_preferences_table_headers_nb_month_for_topic_weight'),
            value: 'nbMonthForTopicWeight',
            displayCondition: this.$vuetify.breakpoint.mdAndUp
          },
          {
            text: this.$t('campaign_smart_benchmark_preferences_table_headers_min_topic_review_for_ranking'),
            value: 'minTopicReviewForRanking',
            displayCondition: this.$vuetify.breakpoint.mdAndUp
          },
          {
            text: this.$t('campaign_smart_benchmark_preferences_table_headers_by_default_display_themes'),
            value: 'byDefaultDisplayThemes',
            displayCondition: this.$vuetify.breakpoint.mdAndUp
          },
          {
            text: this.$t('actions'),
            value: 'actions',
            align: 'end',
            cellClass: 'pr-1',
          }
        ]

        return headers.filter((header) => header.displayCondition === undefined || header.displayCondition)
      }
    },
    asyncComputed: {
      campaignSmartBenchmarkPreferences: {
        async get() {
          this.loading = true
          const request = this.$basedRequest().select({
            campaign_smart_benchmark_preferences: [
              { 'id': { as: 'id' } },
              { 'name': { as: 'name' } },
              { 'is_default': { as: 'isDefault' } },
              { 'enabled': { as: 'enabled' } },
              { 'min_topic_review_for_ranking': { as: 'minTopicReviewForRanking' } },
              { 'nb_month_for_topic_weight': { as: 'nbMonthForTopicWeight' } },
              { 'by_default_display_themes': { as: 'byDefaultDisplayThemes' } },
            ]
          }).where({
            campaign_id: this.currentCampaignPreferencesCampaignId
          }).order(
            ["is_default", "desc"],
            ["name", "asc"]
          )

          const campaignSmartBenchmarkPreferences = (await this.$resolve(request))?.data?.campaignSmartBenchmarkPreferences || []

          this.loading = false

          return campaignSmartBenchmarkPreferences
        },
        default: [],
        watch: ['reload']
      }
    },
    methods: {
      refresh() {
        this.reload = this.reload + 1
      },
      computeSegmentation(item) {
        return item.byDefaultDisplayThemes ?
          this.$t('campaign_smart_benchmark_preferences_themes_segmentation') :
          this.$t('campaign_smart_benchmark_preferences_topics_segmentation')
      },
      async remove(item) {
        const response = await this.$api.wizville.campaignPreferences.deleteSmartBenchmarkPreferences(
          this.currentCampaignPreferencesCampaignId,
          { id: item.id }
        )

        if (response.status === "ok") {
          this.$store.dispatch('notifySuccess', {
            message: this.$t('campaign_smart_benchmark_preferences_successfully_deleted')
          })
          this.refresh()
          this.$emit('removed')
        } else {
          this.$store.dispatch("notifyError")
        }
      },
      async duplicate(item) {
        const response = await this.$api.wizville.campaignPreferences.duplicateSmartBenchmarkPreferences(
          this.currentCampaignPreferencesCampaignId,
          { id: item.id }
        )

        if (response.status === "ok") {
          this.$store.dispatch('notifySuccess', {
            message: this.$t('campaign_smart_benchmark_preferences_successfully_duplicated')
          })
          this.refresh()
          this.zoom({ id: response.campaignSmartBenchmarkPreferencesId })
        } else {
          this.$store.dispatch("notifyError")
        }
      },
      zoom(item) {
        this.$router.push(this.linkTo(item.id))
      },
      rowClasses(item) {
        return item.id === this.currentCampaignSmartBenchmarkPreferences?.id ? 'current-item bg-paleblue' : ''
      },
      linkTo(id) {
        return {
          name: 'SmartBenchmarkPreferences',
          params: {
            campaignSmartBenchmarkPreferencesId: id
          }
        }
      }
    }
  }
</script>

<style lang="stylus">
  .campaign-smart-benchmark-preferences-table
    .v-data-table__wrapper > table > tbody > tr:hover:not(.current-item)
      background-color: var(--palegrey) !important

    .current-item:hover
      background-color: var(--bluehover) !important

    .v-data-table__wrapper
      border-top: 1px solid #0000001f
      border-bottom: 1px solid #0000001f

    .name-cell
      max-width: 150px
      min-width: 100px

    .repartition-cell
      max-width: 300px
      min-width: 200px
</style>
