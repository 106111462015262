import $api from '@api'

const adminContext = {
  state: () => ({ 
    loaded: null,
    digest: null,
    id: null,
    email: null
  }),
  getters: {
    currentAdmin(state, getters) {
      if (state.loaded) {
        return state
      } else {
        return null
      }
    }
  },
  mutations: {
    setAdminContext(state, data) {
      state.loaded = Date.now()
      state.digest = data.digest
      state.id = data.id
      state.email = data.email
    }
  },
  actions: {
    async adminContext(context) {
      const digest = localStorage.getItem("wizvilleSessionToken")
      if (digest !== context.state.digest || !context.state.loaded) {
        const ctx = await $api.wizville.contexts.authAdmin()

        if (ctx) {
          context.commit('setAdminContext', { digest, ...ctx })
        }
      }
    }
  }
}

export default adminContext
