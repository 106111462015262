<template>
  <WInfiniteScroll 
    class="topic-ranking-table"
    :rows="formatedRows" 
    :headers="headers"
    :fullScreenMode="fullScreenMode"
  >
    <template #header.top="{}">
      <WTooltip :message="$t('competitivity_tooltip')" :text="$t('competitivity')" />
    </template>

    <template #item.name="{ value }">
      {{ value }}
    </template>

    <template #item.top="{ item }">
      <div class="d-flex justify-center">
        <WTopBadge :value="item.current.top" :bgColorClass="`bg-${item.color}`" />
      </div>
    </template>

    <template #item.avgScores="{ item }">
      <w-score-range
        class="score-range"
        :color="item.color"
        :value="item.current.top"
        :score="item.current.avgScore"
        :rangeScoreMin="item.scoreSpread.min.avgScore"
        :rangeScoreMax="item.scoreSpread.max.avgScore"
      />
    </template>

    <template #item.actions="{ item }">
      <div class="nowrap">
        <v-icon class="c-lightgrey cursor" @click="$emit('showRankingTable', item)" small>mdi-format-list-numbered</v-icon>
        <v-icon class="c-lightgrey ml-2 cursor" @click="$emit('showVerbatimTable', item)"  small>mdi-comment-outline</v-icon>
      </div>
    </template>
  </WInfiniteScroll>
</template>

<script>
  import _sortBy from 'lodash/sortBy'
  import { mapGetters } from 'vuex';

  export default {
    name: "VerbatimTopicRankingTable",
    props: [ 'ranking' ],
    computed: {
      ...mapGetters([
        'fullScreenMode'
      ]),
      headers() {
        return [
          {
            title: this.$t('topic'),
            name: 'name',
            minWidth: "150px",
            class: [ 'flex-shrink-0', 'text-left', 'pl-3' ]
          },
          {
            title: this.$t('competitivity'),
            class: [ 'flex-grow-2', 'text-center' ],
            minWidth: '110px',
            name: 'top',
          },
          {
            title: this.$t('avg_scores'),
            name: 'avgScores',
            width: "150px",
            overflowY: 'visible',
            class: [ 'flex-grow-1', 'text-center', 'd-none d-sm-block']
          },
          {
            title: '',
            minWidth: "80px",
            name: 'actions',
            class: [ 'text-right', 'pr-3' ]
          }
        ]
      },
      formatedRows() {
        return _sortBy(this.ranking?.filter(item => item.current?.top), item => -item.current.top).map(item => ({
          ...item,
          color: this.colorClass(100 - item.current.top)
        }))
      }
    },
    methods: {
      colorClass(value) {
        if (value <= 10) {
          return 'score100'
        } else if (value <= 20) {
          return 'score90'
        } else if (value <= 30) {
          return 'score80'
        } else if (value <= 40) {
          return 'score70'
        } else if (value <= 50) {
          return 'score60'
        } else if (value <= 60) {
          return 'score50'
        } else if (value <= 70) {
          return 'score40'
        } else if (value <= 80) {
          return 'score30'
        } else if (value <= 90) {
          return 'score20'
        } else if (value <= 100) {
          return 'score10'
        } else {
          return 'primary'
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>  
  .topic-ranking-table
    .score-range
      position: relative
      top: 15px
</style>

