import Vue from "vue";
import CryptrSPA from "@cryptr/cryptr-spa-js";
import { env, appBaseUrl } from '@shared/helpers/env-helpers.js'
import * as Cookies from 'es-cookie'

let clientWrapper;
const clientId = (env === 'production' ? "c1f7cbfb-e2aa-431c-824f-2fc95f148fd7" : (env === 'preprod' ? "6224cd7c-f7c8-4cef-b6ff-220174fcad7f" : (env === 'dev' ? "6224cd7c-f7c8-4cef-b6ff-220174fcad7f" : "6224cd7c-f7c8-4cef-b6ff-220174fcad7f" )))

const isRedirectFromCryptr = () =>
  window.location.search.includes("code=") &&
  window.location.search.includes("state=");

export const getCryptrClient = () => clientWrapper;

const setCryptrClientWrapper = options => {
  if (clientWrapper) {
    return clientWrapper;
  }

  clientWrapper = new Vue({
    data() {
      return {
        client: null,
        error: null,
        isAuthenticated: false,
        canRefresh: false,
        loading: true,
        user: null
      };
    },

    async created() {
      this.client = await CryptrSPA.createClient(options);
      this.loading = true;
      this.isAuthentcated = this.client.isAuthentcated;
      this.canRefresh = this.client.canRefresh(this.client.getRefreshStore());
      try {
        // 1. When user is returning to its browser
        if (!this.isAuthenticated && this.canRefresh) {
          await this.client.handleRefreshTokens();

          // 2. When user is returning to the app after authentication
        } else if (!this.isAuthenticated && isRedirectFromCryptr()) {
          await this.client.handleRedirectCallback();
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.isAuthenticated = await this.client.isAuthenticated();

        this.user = await this.client.getUser();

        this.loading = false;
      }
    },

    methods: {
      signInWithRedirect() {
        return this.client.signInWithRedirect();
      },
      signUpWithRedirect() {
        return this.client.signUpWithRedirect();
      },
      signInWithDomain(domain) {
        this.cleanCryptrCookies()

        if (domain == 'nodomain') {
          return this.client.signInWithDomain(null, {});
        } else {
          return this.client.signInWithDomain(domain, {});
        }
      },
      signInWithEmail(email) {
        this.cleanCryptrCookies()
        return this.client.signInWithEmail(email, {});
      },
      getCurrentAccessToken() {
        return this.client.getCurrentAccessToken();
      },
      logout() {
        this.loading = true;
        this.client.logOut();
        this.loading = false;
        return true;
      },
      cleanCryptrCookies() {
        if (typeof document !== 'undefined') {
          let cookies = Cookies.getAll()
  
          Object.keys(cookies).forEach((cookieKey) => {
            if (cookieKey.startsWith('$cryptr-spa-js$')) {
              Cookies.remove(cookieKey)
            }
          })
        }
      }
    }
  });

  return clientWrapper;
};

export function getAppCryptrConfig() {
  return {
    audience: appBaseUrl,
    client_id: clientId,
    default_redirect_uri: appBaseUrl,
    cryptr_base_url: "https://wizville.authent.me",
    default_slo_after_revoke: false,
    default_locale: "fr",
    telemetry: false,
    fixed_pkce: true,
    dedicated_server: true,
    tenant_domain: "wizville"
  }
}

export default {
  install(Vue, options) {
    Vue.prototype.$cryptr = setCryptrClientWrapper(options);
  }
};