<template>
  <WLateralSectionLayout class="pl-3 campaignIndicator-zoom">
    <template #lateral-section>
      <CampaignIndicatorScoreWidget
        :campaign="campaign"
        :campaignIndicator="campaignIndicator"
        :subtitle="widgetSubtitle"
        :cols="12"
      />
      <ProductRecommendationRateWidget
        v-if="campaign?.hasProductQuestion"
        :campaignId="campaign.id"
        :cols="12"
        ref="ProductRecommendationRateWidget"
      />
      <CampaignIndicatorPlaceRankingWidget
        v-if="isMonoPlace && !isNetworkMonoPlace"
        :campaign="campaign"
        :campaignIndicator="campaignIndicator"
        :subtitle="widgetSubtitle"
      />
      <TransactionalUnitToEarnWidget
        v-if="campaign && nisMixin_canUseNisFeature([campaign])"
        :campaign="campaign"
        :voterProportions="nisVoterProportions"
        :summary="nisSummary"
      />
    </template>

    <template #main-section>
      <CampaignIndicatorRepartitionWidget
        v-if="campaign && nisMixin_canUseNisFeature([campaign])"
        :campaign="campaign"
        :campaignIndicator="campaignIndicator"
        :subtitle="widgetSubtitle"
        :nisVoterProportions="nisVoterProportions"
        :nisSummary="nisSummary"
      />
      <CampaignIndicatorRepartitionWidget
        v-else
        :campaign="campaign"
        :campaignIndicator="campaignIndicator"
        :subtitle="widgetSubtitle"
      />

      <CampaignIndicatorEvolutionWidget
        :campaign="campaign"
        :campaignIndicator="campaignIndicator"
        :key="optionId"
        :subtitle="widgetSubtitle"
      />

      <CampaignIndicatorRankingTableWidget
        v-if="!isNetworkMonoPlace && hasAccessToNewPlatformRankingTab"
        :campaign="campaign"
        :campaignIndicator="campaignIndicator"
        :subtitle="widgetSubtitle"
      />
    </template>
  </WLateralSectionLayout>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CampaignIndicatorScoreWidget from './CampaignIndicatorScoreWidget'
  import CampaignIndicatorPlaceRankingWidget from './CampaignIndicatorPlaceRankingWidget'
  import CampaignIndicatorEvolutionWidget from './CampaignIndicatorEvolutionWidget'
  import CampaignIndicatorRankingTableWidget from './CampaignIndicatorRankingTableWidget'
  import CampaignIndicatorRepartitionWidget from './CampaignIndicatorRepartitionWidget'
  import TransactionalUnitToEarnWidget from '../../shared/widgets/TransactionalUnitToEarnWidget'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import NisMixin from '@statistics/shared/nis_mixin'
  import ProductRecommendationRateWidget from '@statistics/ProductsPage/ProductsZoom/ProductRecommendationRateWidget'

  export default {
    name: "CampaignIndicatorZoom",
    mixins: [
      WidgetMixin,
      NisMixin,
    ],
    props: {
      campaign: { type: Object },
      campaignIndicator: { type: Object },
      campaignIndicatorType: { type: String },
      widgetSubtitle: { type: String },
      nisVoterProportions: { type: Object },
      nisSummary: { type: Object },
    },
    components: {
      CampaignIndicatorEvolutionWidget,
      CampaignIndicatorScoreWidget,
      CampaignIndicatorRankingTableWidget,
      CampaignIndicatorPlaceRankingWidget,
      CampaignIndicatorRepartitionWidget,
      TransactionalUnitToEarnWidget,
      ProductRecommendationRateWidget,
    },
    computed: {
      ...mapGetters([
        'isMonoPlace',
        'isNetworkMonoPlace',
        'hasAccessToNewPlatformRankingTab',
        'isDemoUser',
        'hasAccessToNewPlatformStatisticVerbatimAnalysis'
      ]),
      optionId() {
        return `${this.campaign?.id}_${this.campaignIndicator?.id}`
      }
    }
  }
</script>
