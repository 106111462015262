<template>
  <div class="achievement">
    <v-row class="pa-3">
      <v-col cols="12" sm="12" md="6" lg="6">
        <Form
          @campaignAchievementPreferenceSaved="reload = reload + 1"
          :campaignAchievementPreference="campaignAchievementPreference"
          @retroactivityDelayChanged="onRetroactivityDelayChanged"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <Actions/>
      </v-col>
    </v-row>
    <v-row class="pa-3">
      <PlaceCampaignSatisfactionByTopic
        v-if="campaignAchievementPreference.enabled"
        :minimumFeedbacksCount="parseInt(campaignAchievementPreference.minimumFeedbacksCount)"
        :topProgressionThresholdRate="parseInt(campaignAchievementPreference.topProgressionThresholdRate)/100"
        :retroactivityDelay="retroactivityDelay"
      />
    </v-row>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  import Actions from './Actions'
  import Form from './Form'
  import PlaceCampaignSatisfactionByTopic from './PlaceCampaignSatisfactionByTopic'

  const DEFAULT_PREFERENCE = {
    enabled: false,
    minimumFeedbacksCount: 20,
    topProgressionThresholdRate: 10
  }

  export default {
    name: "Achievement",
    components: {
      Actions,
      Form,
      PlaceCampaignSatisfactionByTopic
    },
    data() {
      return {
        reload: 0,
        retroactivityDelay: 1
      }
    },
    methods: {
      onRetroactivityDelayChanged(val) {
        this.retroactivityDelay = val
      }
    },
    computed: {
      ...mapGetters([
        'currentCampaignPreferencesCampaignId'
      ])
    },
    asyncComputed: {
      campaignAchievementPreference: {
        async get() {
          const request = this.$basedRequest().select({
            campaign_achievement_preferences: [
              { 'id': { as: 'id' } },
              { 'enabled': { as: 'enabled' } },
              { 'minimum_feedbacks_count': { as: 'minimum_feedbacks_count' } },
              { 'top_progression_threshold_rate': { as: 'top_progression_threshold_rate' } },
            ],
          }).where({
            campaign_id: this.currentCampaignPreferencesCampaignId
          })

          const campaignAchievementPreference = (await this.$resolve(request)).first()

          if (campaignAchievementPreference.enabled === null) {
            return DEFAULT_PREFERENCE
          }
          campaignAchievementPreference.topProgressionThresholdRate = this.$options.filters.toPercent(campaignAchievementPreference.topProgressionThresholdRate)
          return campaignAchievementPreference
        },
        default: DEFAULT_PREFERENCE,
        watch: ['reload']
      }
    }
  }

</script>

<style lang="stylus" scoped>
</style>
