<template>
  <div
    v-if="nbAchievements && shouldDisplayButton"
    class="success-action"
    v-scroll="onScroll"
    @mouseleave="onMouseLeave"
    @mouseover="onMouseHover"
  >
    <v-speed-dial
      class="action-speed-dial"
      bottom
      right
      transition
      fixed
    >
      <template v-slot:activator>
        <v-btn
          v-if="displayDetails"
          rounded
          :ripple="false"
          x-large
          class="open-modal bg-palegrey"
          @click="openModal"
        >
          <span v-if="$i18n.locale == 'fr'">
            <w-badge
              type="success"
              :content="nbAchievements"
              inline
              classNames="w-badge-success"
            >
              <span class="f-13 c-primary">{{ $t('achievementsFloatingActionShare') }}</span>
            </w-badge>
            <span class="f-13 c-primary">{{ $t('achievementsFloatingActionYourSuccesses') }}</span>
          </span>
          <w-badge
            v-else
            type="success"
            :content="nbAchievements"
            inline
            classNames="w-badge-success"
          >
            <span class="f-13 c-primary">{{ $t('achievementsFloatingActionShareYourSuccesses') }}</span>
          </w-badge>
        </v-btn>
        <v-btn
          class="bg-white"
          elevation="1"
          fab
          @click="openModal"
        >
          <span class="f-17">{{ "🥳" }}</span>
        </v-btn>
      </template>
    </v-speed-dial>
  </div>
</template>

<script>
  import AchievementsMixin from './shared/achievements_mixin'

  export default {
    name: 'AchievementsFloatingAction',
    mixins: [
      AchievementsMixin
    ],
    data () {
      return {
        mouseOnButton: false,
        offsetTopScroll: 0,
      }
    },
    methods: {
      openModal() {
        this.$store.dispatch('setIsOpenClaimAchievementModal', true)
      },
      onMouseHover() {
        this.mouseOnButton = true
      },
      onMouseLeave() {
        this.mouseOnButton = false
      },
      onScroll() {
        // We use the offset scroll of the window instead of the event
        // v-scroll is only use to save the offsetTopScroll when triggered
        this.offsetTopScroll = window.scrollY
      },
    },
    computed: {
      displayDetails() {
        if (this.offsetTopScroll <= 20) {
          return true
        } else {
          return this.mouseOnButton
        }
      },
      shouldDisplayButton() {
        return ![
          'Feedbacks',
          'Ranking'
        ].includes(this.$route.name)
      }
    }
  }
</script>

<style lang="stylus">
  .success-action
    .w-badge-success
      width: unset !important
      .v-badge--inline
        .v-badge__wrapper
          margin: 0 0 0 2px

    .v-btn
      letter-spacing: normal !important

    .open-modal
      left: 60px
      padding-right: 70px !important
      text-transform: unset
</style>
