import App from '@platform/app.vue'
import { router } from '@platform/router'
import Vue from 'vue'
import wizville from '@vue/plugins/wizville'
import vuetify from '@vue/plugins/vuetify'
import store from '@platform/store'
import { i18n, setLanguageAsync } from '@i18n/setup'
import { initSession } from '@shared/router/sessionGuards'
import { userLocale } from "@shared/helpers/env-helpers.js"
import { removeAlkAndHrefFromUrl } from '@shared/helpers/query'
import cryptr, { getAppCryptrConfig } from '@vue/plugins/cryptr'

Vue.config.productionTip = false;
const cryptrConfig = getAppCryptrConfig()
window.cryptrConfig = cryptrConfig
Vue.use(cryptr, cryptrConfig);

router.beforeEach((to, from, next) => {
  const lang = to.params.lang || store.getters.currentUser.locale || 'default';
  setLanguageAsync(lang).then(() => next())
})

removeAlkAndHrefFromUrl()

window.$store = store
document.addEventListener('DOMContentLoaded', () => {
  setLanguageAsync(userLocale || 'default').then(() => {
    const el = document.body.appendChild(document.createElement('platform'))

    initSession()
    window.app = new Vue({
      el,
      vuetify,
      wizville,
      store,
      i18n,
      router,
      render: (h) => h(App),
    })
  })
})
