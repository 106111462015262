<template>
    <WStatsWidget
        :border="totalEtsWihoutCollaborator != null && totalEtsWihoutCollaborator > 0 ? $colors.red : null"
        height="70px"
        :cols="5"
        :loading="alertingListLoading"
        loadingHeight="70px"
        contentWidth="100%"
    >
        <template #content>
            <div class="d-flex align-center ga-2 pa-2">
            <div class="d-flex icon-layout">
                <v-chip class="rounded bg-oslo-gray-100 icon-style pr-2">
                    <v-icon color="c-sea-green" small> mdi-bottle-tonic-outline </v-icon> 
                </v-chip>
            </div>
            <div class="d-flex flex-grow-1 w-alerting-widget-title">
                <span class="c-primary fb-14">{{ $t('collaborator_page_perimeter_without_collaborators') }}</span>
            </div>
            <div class="d-flex ml-auto align-center ga-2">
                <span class="fb-28 c-primary">
                    {{ totalEtsWihoutCollaborator }} 
                </span>
                <span class="fb-13 cursor-pointer c-primary" @click="alertingFilterActivation(2)">
                    {{ $t('collaborator_page_see_action') }}
                </span>
            </div>
            </div>
        </template>
    </WStatsWidget>
</template>

<script>

export default {
  name: "PerimeterWithoutCollaborator",
  props: {
    alertingListLoading:{
      type: Boolean,
      required: true,
    },
    totalEtsWihoutCollaborator: {
        type: Number,
        required: false
    },
    activateFilter: {
      type: Function,
      required: true,
    }
  },
  methods: {
    alertingFilterActivation(value) {
        this.activateFilter(value);
    }
  }
}
</script>