<template>
  <v-form ref="form" v-model="isValid" class="assign-to-third-party d-flex flex-column">
    <v-row class="flex-grow-0">
      <v-col class="py-0 my-1">
        <v-combobox
          ref="recipientsField"
          class="last-used-emails f-14 mt-1"
          v-model="email"
          label="E-mail"
          :items="lastUsedEmails"
          :rules="emailRules"
          background-color="#eef1f5"
          chips
          dense
          hide-details
          filled
          attach
          solo
          flat
          deletable-chips
          :menu-props="{ minWidth: '170%', value: autoselectMenu }"
          @keydown.enter="focusOnComment"
          @click="autoselectMenu = true"
          @focus="autoselectMenu = true"
          @change="focusOnComment"
        >
          <template v-slot:prepend-inner>
            <v-icon small> mdi-at</v-icon>
          </template>
          <template v-slot:selection="data">
            <v-chip
              :key="JSON.stringify(data.item)"
              v-bind="data.attrs"
              class="my-3"
              :class="{ 'c-error border-error' : !$rules.email(data.item) }"
              close
              @click:close="email = ''"
            >
              <v-avatar
                class="accent white--text mr-1"
                left
                v-text="data.item.slice(0, 1).toUpperCase()"
              ></v-avatar>
              <span>{{ data.item }}</span>
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-avatar
              size
              class="accent white--text mr-1"
              left
              v-text="data.item.slice(0, 1).toUpperCase()"
            >
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-combobox>


      </v-col>
    </v-row>
    <v-row class="flex-grow-1">
      <v-col class="py-0 my-1">
        <v-textarea
          ref="contentArea"
          v-model="content"
          class="content-textarea f-14"
          :label="$t('feedbacks_assign_text_area_comment')"
          background-color="#eef1f5"
          :placeholder="$t('feedbacks_assign_third_party_text_area_description')"
          name="content"
          height="100%"
          rows="0"
          dense
          filled
          no-resize
        />
      </v-col>
    </v-row>
    <v-row class="flex-grow-0">
      <v-col class="text-right">
        <v-btn
          class="save"
          @click="assignToThirdParty"
          :disabled="!isValid"
          :ripple="false"
          :loading="loading"
          color="secondary"
        >
          <v-icon left dark>mdi-account-outline</v-icon>
          {{ $t('feedbacks_assign_to_third_party_button') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'AssignToThirdParty',
  props: {
    voter: { required: true }
  },
  computed: {
    ...mapGetters([
      'currentVoter',
      'currentUserGroupId',
      'currentUserId',
      'currentDashboardCampaignIds',
      'currentUser'
    ]),
  },
  data() {
    return {
      autoselectMenu: false,
      isValid: false,
      loading: false,
      content: '',
      email: '',
      emailRules: [
        v => !!v || this.$t('email_required'),
        v => /.+@.+\..+/.test(v) || this.$t('email_must_be_valid'),
      ],
    }
  },
  methods: {
    focusOnComment() {
      this.autoselectMenu = false
      this.$refs["contentArea"].$refs.input.focus()
    },
    async assignToThirdParty() {
      if (this.isValid) {
        this.loading = true

        try {
          const response = await this.$api.wizville.voterThirdPartyTransfers.create(
            this.voter.uniqueId,
            { comment: this.content, email: this.email }
          )

          if (response.status === "ok") {
            this.$store.dispatch('notifySuccess', { message: this.$t('feedbacks_assign_to_third_party_notify_success', { email: this.email }), timeout: 5000 })
            this.$store.dispatch('events', { voterUpdate: { voter: { uniqueId: this.voter.uniqueId } } })

            this.$store.dispatch('setVoterThirdPartyTransfer', response.voterThirdPartyTransfer)
          } else {
            this.notifyError(response.message)
          }
        } catch (error) {
          this.notifyError(error.message)
        }

        this.loading = false
      }
    }
  },
  asyncComputed: {
    lastUsedEmails: {
      async get() {
        const lastUsedEmailsRequest = this.$basedRequest().select({
          voter_third_party_transfers: ['email']
        }).where(
          { brands_id: [this.currentUser.brandId] }
        ).group(['email'])

        const lastEmails = Object.keys((await this.$resolve(lastUsedEmailsRequest)).data) || []

        if (lastEmails.length) {
          return [{
            header: this.$t('voter_third_party_transfer_last_used_emails')
          }].concat(lastEmails)
        }

        return []
      },
      default: []
    }
  }
}
</script>
<style lang='stylus'>
  @import '~@theme/medias.styl'

  .assign-to-third-party
    height: 100%
    padding: 20px 10px 20px 10px

    .content-textarea
      height: 100%
      textarea
        line-height: 18px

    .content-textarea .v-input__control
      height: 100%

    .content-textarea .v-input__slot
      margin-bottom: 0px

    +forBreakpoints(mobile, tablet)
      .content-textarea
        min-height: 200px

    .v-input__append-inner
      display: none

    .v-chip
      height: 20px
      max-width: unset
      padding-right: 9px
      overflow: initial
    .v-avatar
      font-size: 10px
      height: 16px !important
      width: 16px !important
      min-width: 16px !important
    .v-select__selections
      -ms-overflow-style: none
      scrollbar-width: none
      overflow: scroll
      flex-wrap: nowrap
    .v-select__selections::-webkit-scrollbar
      display: none;
    .v-chip__close
      margin-left: 3px !important
      font-size: 14px !important
    .v-subheader
      height: 25px
      font-size: 14px
      font-family: SourceSansProBold, Roboto !important
      display: block
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
</style>
