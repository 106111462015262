import { i18n } from '@i18n/setup'
import _upperFirst from 'lodash/upperFirst'

export class Lexicon {
  constructor(lexicon) {
    this.lexicon = (lexicon?.translations || {})[i18n.locale] || {}
  }

  translate(words, localeKey, otherVariables) {
    let wordsToTranslate = {}

    for (let wordToTranslate of [words].flat()) {
      let value =
        this.lexicon[wordToTranslate] ||
        i18n.t(`lexicon.default.${wordToTranslate}`)

      // Capitalize all german nouns
      if (wordToTranslate === localeKey || i18n.locale === 'de') {
        value = _upperFirst(value)
      } else {
        value = value.toLowerCase()
      }

      wordsToTranslate[wordToTranslate] = value
    }

    return i18n.t(`lexicon.translation.${localeKey}`, {
      ...otherVariables,
      ...wordsToTranslate,
    })
  }

  translateI18n(key, params, words, otherVariables = {}) {
    let translatedText = i18n.t(key, params)

    for (let wordToTranslate of [words].flat()) {
      let wordToReplace = i18n.t(`lexicon.default.${wordToTranslate}`)
      let translation = this.lexicon[wordToTranslate]

      if (translation) {
        translatedText = translatedText.replace(
          new RegExp(
            `${wordToReplace[0].toUpperCase()}${wordToReplace.slice(1)}`,
            'g'
          ),
          `${translation[0].toUpperCase()}${translation.slice(1)}`
        )

        translatedText = translatedText.replace(
          new RegExp(`${wordToReplace.toLowerCase()}`, 'g'),
          translation.toLowerCase()
        )
      }
    }

    return translatedText
  }
}
