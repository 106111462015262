<template>
  <div
    v-if="picturesData"
    class="d-flex mt-2 flex-wrap voter-pictures"
  >
    <v-dialog
      v-for="(picture, index) in picturesData"
      :key="index"
      max-width="800px"
      v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <template>
          <v-tooltip
            top
          >
            <template #activator="{ on }">
              <v-img
                v-on="on"
                max-height="200px"
                max-width="200px"
                :src="picture.pictureUrl"
                class="mx-2 voter-picture-img-clickable"
                :class="{ blur: picture.hide }"
                @click="onClickPicture(picture, index)"
              >
                <v-overlay
                  class="voter-pictures-overlay"
                  absolute
                  opacity="0"
                >
                  <div v-if="isModeratedPicture(picture)" class="fs-13 bg-dark c-white voter-pictures-moderated-text align-self-end text-center">
                    {{ $t('voter_pictures_moderated_picture') }}
                  </div>
                </v-overlay>
              </v-img>
            </template>
              <span v-if="picture.hide || isVisiblePicture(picture)">
                {{ $t('voter_pictures_tooltip_content_see_picture') }}
              </span>
              <span v-else>
                {{ $t("voter_pictures_tooltip_content_hide_picture") }}
              </span>
          </v-tooltip>
        </template>
      </template>
      <v-card>
        <v-img
          max-height="800px"
          max-width="800px"
          :src="selectedPicture"
        ></v-img>
        <v-btn icon class="voter-pictures-close-button" @click="closePictureDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  const KO_PICTURE_MODERATION = 'ko'
  const OK_PICTURE_MODERATION = 'ok'
  const PENDING_PICTURE_MODERATION = null

  const MODERATED_PICTURE = [KO_PICTURE_MODERATION]
  const VISIBLE_PICTURE = [OK_PICTURE_MODERATION, PENDING_PICTURE_MODERATION]

  export default {
    name: "VoterPictures",
    props: {
      pictures: { required: true },
    },
    data() {
      return {
        dialog: false,
        selectedPicture: null,
        picturesData: undefined
      }
    },
    methods: {
      isModeratedPicture(picture) {
        return MODERATED_PICTURE.includes(picture.moderationStatus)
      },
      isVisiblePicture(picture) {
        return VISIBLE_PICTURE.includes(picture.moderationStatus)
      },
      isHiddenPicture(picture) {
        return picture.moderationStatus === KO_PICTURE_MODERATION
      },
      opacityForPicture(picture) {
        return this.isHiddenPicture(picture) ? 0.8 : 0
      },
      onClickPicture(picture, index) {
        if (this.isHiddenPicture(picture)) {
          this.picturesData[index].hide = !this.picturesData[index].hide
        } else {
          this.selectedPicture = picture.pictureUrl
          this.dialog = true
        }
      },
      closePictureDialog(picture) {
        this.dialog = false
      }
    },
    mounted() {
      this.picturesData = this.pictures.map(
        picture => ({ ...picture, hide: this.isHiddenPicture(picture) })
      )
    }
  }
</script>

<style lang="stylus">
.voter-pictures
  .voter-picture-img-clickable.blur
    .v-image__image
      filter: blur(3px)
  .voter-picture-img-clickable
    cursor: pointer

    .v-overlay__content
      display: flex
      height: 100%
      width: 100%

      .voter-pictures-moderated-text
        height: 20px
        width: 100%
        opacity: 0.8

.voter-pictures-close-button
  position: absolute
  top: 5px
  right: 5px

</style>
