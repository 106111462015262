<template>
  <v-tooltip top transition="none" class="push-mobile-insatisfaction-test">
    <template #activator="{ on }">
      <v-btn
        small
        icon
        v-on="on"
        @click.stop="sendPushMobileInsatifactionTest"
      >
        <v-icon> mdi-cellphone-message </v-icon>
      </v-btn>
    </template>

    Envoyer un test de notification mobile d'insatisfaction à {{ currentUser.mail }}
  </v-tooltip>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "PushMobileInsatisfactionTest",
    props: {
      voter: { required: true }
    },
    computed: {
      ...mapGetters([
        'currentUser',
      ]),
    },
    methods: {
      sendPushMobileInsatifactionTest() {
        this.$api.wizville.tests.sendPushMobileInsatisfactionTest(this.voter.uniqueId)
        this.$store.dispatch('notifySuccess', {
          message: "Une notification a été envoyée sur le téléphone de " + this.currentUser.mail
        })
      }
    }
  }
</script>

<style lang="stylus" scoped>
 
</style>
