<template>
  <div class="voter-mobile-actions">
    <v-speed-dial
      class="action-speed-dial"
      v-if="!open"
      v-model="open"
      bottom
      right
      transition
      fixed
    >
      <template v-slot:activator>
        <v-btn
          v-if="quickAction"
          :id="quickAction.id"
          rounded
          :ripple="false"
          x-large
          class="quick-action"
          :class="quickAction.fabButtonClassNames"
          @click="showPanel"
        >
          {{ quickAction.title}}
        </v-btn>
        <v-btn
          v-model="open"
          class="bg-secondary more-actions"
          dark
          fab
        >
          <v-icon v-if="open">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
    </v-speed-dial>
    <v-overlay
      :value="open"
    >
      <v-speed-dial
        v-model="open"
        bottom
        right
        absolute
        transition
      >
        <template v-slot:activator>
          <v-btn
            v-model="open"
            class="bg-secondary"
            dark
            fab
          >
            <v-icon v-if="open">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>

        <Action
          v-for="action in reversedActions"
          v-if="action.displayCondition !== undefined ? action.displayCondition && !action.disabled : true"
          :id="action.id"
          :key="action.title"
          :title="action.title"
          :icon="action.icon"
          :buttonClassNames="action.fabButtonClassNames"
          :iconClassNames="action.fabIconClassNames"
          @onClicked="showPanel"
        />

      </v-speed-dial>
    </v-overlay>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Action from './Action'

  export default {
    name: "MobileAction",
    components: {
      Action
    },
    props: {
      actions: {
        type: Array,
        required: true
      }
    },
    data () {
      return {}
    },
    computed: {
      ...mapGetters([
        'currentVoterIsMobileActionMenuOpened',
      ]),
      reversedActions() {
        return this.actions.slice().reverse()
      },
      open: {
        get: function () {
          return this.currentVoterIsMobileActionMenuOpened
        },
        set: function (value) {
          this.$store.dispatch('setIsMobileActionMenuOpened', value)
        }
      },
      quickAction() {
        return this.actions[0].displayCondition && !this.actions[0].disabled ? this.actions[0] : null
      }
    },
    methods: {
      showPanel(e) {
        this.$store.dispatch('setActionsTab', e.currentTarget.id)
        this.$store.dispatch('setShowVoterActions', true);
        this.open = false
      }
    }
  }
</script>

<style lang="stylus">
  .voter-mobile-actions
    .quick-action
      left: 60px
      padding-right: 70px !important
      text-transform: unset
    .v-overlay__content
      height: 100%
      width: 100%

    .v-speed-dial__list
      align-items: flex-end
      .v-btn
        margin-right: 0px
</style>
