import { mapGetters } from 'vuex'
import { sqlDate } from '@vue/plugins/helpers/dates'

export default {
  props: {
    campaign: { type: Object },
  },
  computed: {
    ...mapGetters([
      'dashboardFilterRequest',
      'dashboardFilterReady',
      'isMonoPlace',
      'datesScope',
      'groupRankingName',
      'groupRankingPlaceIds',
      'networkRankingName',
      'networkRankingPlaceIds',
      'placeIdsScope',
      'dashboardFilterDates',
      'currentDashboardScopedCampaigns',
      'placeIds',
      'dashboardAvgScale'
    ]),
    aliasedIndicator() {
      const aliasedIndicator = { ...this.indicatorColumn }
      const indicator = Object.keys(this.indicatorColumn)[0]
      aliasedIndicator[indicator].as = 'indicator'

      return aliasedIndicator
    },
    scoreScale() {
      return this.avgScoreScale || this.campaign?.avgScoreScale || this.dashboardAvgScale
    },
    isFiveScore() {
      return this.scoreScale.max === 5
    }
  },
  methods: {
    request({ placeIds, dateBegin, dateEnd } = {}) {
      const placeIdsScope = placeIds || this.placeIdsScope

      let request = this.basedRequest.dateBetween(
        sqlDate(dateBegin || this.datesScope.dateBegin),
        sqlDate(dateEnd || this.datesScope.dateEnd, '23:59:59')
      )

      if (this.campaign) {
        request = request.where({ campaign_id: this.campaign.id })
      }

      if (placeIdsScope && placeIdsScope.length) {
        request = request.where({
          place_campaigns_place_id: placeIdsScope,
        })
      }

      return request
    },
    computeIndicator(value) {
      return typeof value === 'string' ?
             parseFloat(value) :
             value
    }
  },
  asyncComputed: {
    data: {
      lazy: true,
      default: undefined,
      async get() {
        if (this.basedRequest && this.dashboardFilterReady) {
          const data = (await this.$resolve(this.request(), 'voter_score_mixin.data')).first() || {}
          const request = this.request({
            dateBegin: this.datesScope.comparedDateBegin,
            dateEnd: this.datesScope.comparedDateEnd
          })
          const comparedData = (await this.$resolve(request, 'voter_score_mixin.comparedData')).first() || {}
          const displayEvolution = !!data[this.indicatorAlias] &&
                                   !!comparedData[this.indicatorAlias] &&
                                   data.nbReview > 0 &&
                                   comparedData.nbReview > 0
          return data ?
                 {
                   indicator: this.computeIndicator(data[this.indicatorAlias]),
                   nbReview: data.nbReview,
                   displayEvolution,
                   evolution: data[this.indicatorAlias] - comparedData[this.indicatorAlias]
                 } :
                 {}
        }
      }
    },
    groupIndicator: {
      lazy: true,
      default: undefined,
      async get() {
        if (this.basedRequest && this.dashboardFilterReady && this.groupRankingPlaceIds) {
          const request = this.request({
            placeIds: this.groupRankingPlaceIds
          })
          const result = (await this.$resolve(request, 'voter_score_mixin.groupIndicator')).first() || {}

          return this.computeIndicator(result[this.indicatorAlias])
        }
      }
    },
    networkIndicator: {
      lazy: true,
      default: undefined,
      async get() {
        if (this.basedRequest && this.dashboardFilterReady && this.networkRankingPlaceIds) {
          const request = this.request({ placeIds: this.networkRankingPlaceIds })
          const result = (await this.$resolve(request, 'voter_score_mixin.networkIndicator')).first() || {}

          return this.computeIndicator(result[this.indicatorAlias])
        }
      }
    },
    rank: {
      lazy: true,
      default: undefined,
      async get() {
        if (this.dashboardFilterReady && this.isMonoPlace) {
          let request = this.request({ placeIds: [] })

          request = request.group([
            "place_campaigns_place_id"
          ])
          .order(
            [
              [this.indicatorColumn, "desc"],
              ['nb_review', "desc"]
            ]
          )

          const data = (await this.$resolve(request)).first()
          const rank = data.findIndex((place) =>
            place.placeCampaignsPlaceId === this.placeIds[0]
          )

          return rank ? rank + 1 : null
        }
      }
    }
  }
}
