<template>
  <div v-if="currentUserId && dashboardFilterReady">
    <AppBar 
      v-if="!fullScreenMode"
    />
    <DashboardSideBar
      v-if="!fullScreenMode"
      :dashboardId="currentDashboard.id"
    />
    <v-main class="dashboard-layout">
      <v-container
        fluid
        class="pa-0"
        v-if="dashboardFilterReady"
      >
        <div v-show="showFilters">
          <Filters
            v-if="!fullScreenMode"
            class="filter-container"
          />
        </div>

        <div v-if="showClaimAchievements && !fullScreenMode">
          <AchievementsFloatingAction
            v-if="showAchievementsFloatingAction"
          />
          <AchievementsModal/>
        </div>
        <router-view :class="{ 'full-screen-padding-top': fullScreenMode }" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import AppBar from '../navigation/AppBar'
import DashboardSideBar from '../navigation/sidebars/DashboardSideBar'
import AchievementsFloatingAction from './Achievements/AchievementsFloatingAction'
import AchievementsModal from './Achievements/AchievementsModal'
import AchievementsMixin from './Achievements/shared/achievements_mixin'
import { addQueryParamsWithoutReload } from '@shared/helpers/query'
import Filters from './Filters'
import { mapGetters } from 'vuex'
import store from '@platform/store'
import { router } from '@platform/router'

export default {
  name: 'DashboardLayout',
  components: {
    AppBar,
    DashboardSideBar,
    Filters,
    AchievementsFloatingAction,
    AchievementsModal
  },
  computed: {
    ...mapGetters([
      'dashboardFilterSurveySources',
      'currentUserId',
      'currentDashboard',
      'dashboardFilterBase64',
      'dashboardFilterReady',
      'currentVoter',
      'currentUserId',
      'hasAccessToNewPlatformAchievementsTab',
      'hasRightToShareAchievements',
      'currentDashboardAchievementsEnabled',
      'fullScreenMode'
    ]),
    showFilters() {
      if (this.currentVoter?.collapsed === false && this.$vuetify.breakpoint.smAndDown) {
        return false
      }

      return true
    },
    showAchievementsFloatingAction() {
      return !(this.$route.name === "Feedbacks" && this.$vuetify.breakpoint.smAndDown)
    },
    showClaimAchievements() {
      return this.hasAccessToNewPlatformAchievementsTab && this.hasRightToShareAchievements && this.currentDashboardAchievementsEnabled
    }
  },
  watch: {
    dashboardFilterBase64(newVal, oldVal) {
      if (newVal && oldVal !== newVal && this?.$route?.query?.dashboard_filters !== newVal) {
        this.$router.push(this.dashboardFiltersWithParams(newVal))
      }
    },
    $route(to, from) {
      if (!to.query.dashboard_filters) {
        this.$router.replace(this.dashboardFiltersWithParams(store.getters.dashboardFilterBase64))
      } else if (to.query.dashboard_filters && to.query.dashboard_filters !== store.getters.dashboardFilterBase64) {
        store.dispatch("refreshDashboardFilters", {
          dashboardFilters: JSON.parse(decodeURIComponent(escape(atob(to.query.dashboard_filters))))
        })
      }
    }
  },
  mounted() {
    if (this.dashboardFilterBase64 !== this?.$route?.query?.dashboard_filters) {
      this.$router.push(this.dashboardFiltersWithParams(this.dashboardFilterBase64))
    }
  },
  methods: {
    dashboardFiltersWithParams(dashboardFilters) {
      return {
        query: {
          ...(this.$route.query || {}),
          dashboard_filters: dashboardFilters
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import '~@theme/colors.styl'
  @import '~@theme/medias.styl'
  @import '~@theme/constants.styl'

  .filter-container
    border-bottom: 1px solid rgba(0, 0, 0, 0.12)
    background-color: var(--bodygrey)
    height: 41px
  
  .full-screen-padding-top
    padding-top: 12px
</style>
