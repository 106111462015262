<template>
  <v-app id="platform" class="bg-bodygrey" :class="{ 'full-screen-mode': fullScreenMode }">
    <WProfiler v-if="showBasedPanel" />

    <template v-show="appReady">
      <WSnackBar />
      <WIconsBase />
      <router-view />
    </template>

    <div style="margin: auto; text-align: center;" v-show="!appReady">
      <v-progress-circular v-if="!loadingSlow" indeterminate color="primary" style="margin: auto" />
      <v-btn icon v-else color="primary" style="margin: auto">
        <a href="/login"><v-icon large>mdi-login</v-icon></a>
      </v-btn>
    </div>

    <WPasswordSetupDialog v-if="displayPasswordSetupDialog" />
  </v-App>
</template>

<script>
  import { addQueryParamsWithoutReload, localHost } from '@shared/helpers/query'
  import { mapGetters } from 'vuex'
  import { bootIntercomIfn, updateIntercomIfn } from '@shared/helpers/intercom'
  import {
    trackUser as trackAnalyticsUser,
    trackPageView as trackAnalyticsPageView 
  } from '@shared/helpers/analytics/base'

  export default {
    name: 'App',
    components: {
    },
    data() {
      return {
        loadingSlow: false,
        basedPanel: (localStorage.basedPanel === undefined ? `${this.$local}` : `${localStorage.basedPanel === 'true'}`)
      }
    },
    mounted() {
      this._keyListener = function(e) {
        if (e.key === "k" && (e.ctrlKey || e.metaKey)) {
          e.preventDefault();
          addQueryParamsWithoutReload(this.$route, { alk: this.currentUser.alk, alks: this.currentAdmin?.id || '' })
        }

        if (e.key === ";" && (e.ctrlKey || e.metaKey)) {
          addQueryParamsWithoutReload(this.$route, { alk: this.currentUser.alk, alks: this.currentAdmin?.id || '' })
          window.open(localHost(window.location.href), '_blank');
        }

        if (e.key === "j" && (e.ctrlKey || e.metaKey)) {
          this.basedPanel = localStorage.basedPanel = (this.basedPanel === 'true' ? 'false' : 'true')
        }

        if (e.key === "π" && e.altKey) {
          if (e.ctrlKey || e.metaKey) {
            this.exportPagePdf()
          } else {
            this.$store.dispatch('updateFullScreenMode', this.fullScreenMode === false)
          }
        }
      }

      document.addEventListener('keydown', this._keyListener.bind(this));

      setTimeout(() => {
        this.loadingSlow = true
      }, 30000)
    },
    computed: {
      ...mapGetters([
        'currentUser',
        'currentUserShouldSetupPassword',
        'currentAdmin',
        'currentUserIsAdmin',
        'currentUserId',
        'appReady',
        'ssoOnly',
        'fullScreenMode',
        'trackingEvents'
      ]),
      showBasedPanel() {
        return this.basedPanel === 'true' && (this.$staging || this.currentAdmin || this.currentUser?.isAdmin) && this.$vuetify.breakpoint.mdAndUp && !this.fullScreenMode
      },
      displayPasswordSetupDialog() {
        return this.currentUserId &&
          this.currentUserShouldSetupPassword &&
          !this.currentAdmin &&
          !this.currentUserIsAdmin &&
          !this.ssoOnly &&
          !this.$cryptr.getCurrentAccessToken()
      }
    },
    watch: {
      currentUser: {
        handler: function(user) {
          bootIntercomIfn(this.currentAdmin, user)
          trackAnalyticsUser()
        },
        immediate: true,
        deep: true
      },
      $route(to, from) {
        document.title = `${this.$t(to.meta.nameTranslationKey ? to.meta.nameTranslationKey : to.name)} | WizVille`
        if (this.$local || this.$staging) {
          console.log(`Going from "${from.fullPath}" to "${to.fullPath}"`)
        }
        updateIntercomIfn(this.currentAdmin, this.currentUser)
        trackAnalyticsPageView(to, from)
      }
    },
    methods: {
      async exportPagePdf() {
        const oldValue = this.fullScreenMode

        try {
          await this.$store.dispatch('updateFullScreenMode', true)
          await this.$htmlToPdf(document.getElementById('platform'), this.$helpers.dates.filename('wiz', '.pdf'), { windowWidth: 595.28 * 1.6 })
          this.$store.dispatch('updateFullScreenMode', oldValue)
          this.$store.dispatch("notifySuccess")
        } catch (error) {
          console.error(error)
          this.$store.dispatch('updateFullScreenMode', oldValue)
          this.$store.dispatch("notifyError")
        }
      }
    }
  }
</script>

<style lang="stylus">
  @import '~@theme/medias.styl'

  +forBreakpoints(mobile)
    .v-data-table-header__icon
      display: none !important
</style>
