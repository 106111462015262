<template>
  <WStatsWidget
    v-if="topic === undefined"
    :title="title"
    :cols="12"
    :loading="true"
    :empty="false"
  />
  <RankingTableWidget
    v-else
    :campaign="campaign"
    :staticColumns="staticColumns"
    :customRankingRelatedIndicator="customRankingRelatedIndicator"
    :title="title"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import RankingTableWidget  from '@statistics/shared/widgets/RankingTableWidget'

  export default {
    name: "TopicRankingTableWidget",
    props: {
      campaign: { type: Object, required: true },
      topic: { type: Object },
    },
    components: { RankingTableWidget },
    computed: {
      ...mapGetters([
        'currentLexicon'
      ]),
      customRankingRelatedIndicator() {
        return `t_${this.topic.id}`
      },
      staticColumns() {
        return {
          'g_place_name': {},
          [this.customRankingRelatedIndicator]: { text: `${this.$t('average_mark')} - ${this.topic.name}` },
          [`${this.customRankingRelatedIndicator}_answer_repartition`]: {
            hideNbReview: true,
            hideScore: true,
            avgScoreScale: this.campaign?.avgScoreScale
          }
        }
      },
      title() {
        const title = `${this.$t('competitors_widget_ranking_title')} ${this.currentLexicon.translate('places', 'places') }`
        return this.topic === undefined ?
               title :
               `${title} - ${this.topic.name}`
      }
    }
  }
</script>
