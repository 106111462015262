const layout = {
  state: () => ({
    showSidebar: false,
    appReady: false,
    activeDatePickers: {},
    fullScreenMode: false
  }),
  getters: {
    activeDatePickers(state) {
      return state.activeDatePickers
    },
    sidebarShowed(state, getters) {
      return state.showSidebar
    },
    appReady(state, getters) {
      return state.appReady
    },
    fullScreenMode(state, getters) {
      return state.fullScreenMode
    }
  },
  mutations: {
    toggleShowSidebar(state) {
      state.showSidebar = !state.showSidebar
    },
    toggleDatePicker(state, pickerId) {
      const activeDatePickers = {}

      if (state.activeDatePickers[pickerId]) {
        activeDatePickers[pickerId] = false
      } else {
        for (const [pickerId, value] of Object.entries(state.activeDatePickers)) {
          activeDatePickers[pickerId] = false
        }
        activeDatePickers[pickerId] = true
      }

      state.activeDatePickers = activeDatePickers
    },
    updateShowSidebar(state, value) {
      state.showSidebar = value
    },
    updateAppReady(state, isReady) {
      state.appReady = isReady
    },
    updateAppReady(state, isReady) {
      state.appReady = isReady
    },
    setFullScreenMode(state, fullScreenMode) {
      state.fullScreenMode = fullScreenMode
    }
  },
  actions: {
    appReady(context, isReady) {
      context.commit('updateAppReady', isReady)
    },
    toggleShowSidebar(context) {
      context.commit('toggleShowSidebar')
    },
    updateFullScreenMode(context, fullScreenMode) {
      context.commit('setFullScreenMode', fullScreenMode)
    },
    updateShowSidebar(context, value) {
      context.commit('updateShowSidebar', value)
    },
    toggleDatePicker(context, pickerId) {
      context.commit('toggleDatePicker', pickerId)
    }
  }
}

export default layout
