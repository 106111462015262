<template>
  <v-avatar
    size="40"
    class="bg-bodygrey"
  >
    <span class="c-primary fb-15 text-uppercase">{{ avatarContent }}</span>
  </v-avatar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CompanyAvatar',
  computed: {
    ...mapGetters([
      'currentUserBrandName'
    ]),
    avatarContent() {
      return this.currentUserBrandName.charAt(0)
    }
  }
}
</script>
