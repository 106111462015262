<template>
  <v-row class="nps-page" no-gutters>
    <v-col>
      <v-row v-if="eligibleCampaigns.length > 1" no-gutters>
        <v-col>
          <CampaignTableWidget
            :key="nisMixin_key"
            :load="loadData()"
            :selectedCampaign="selectedCampaign"
            :eligibleCampaigns="eligibleCampaigns"
            :npsData="npsData"
            :cols="12"
            class="pt-0"
          />
        </v-col>
      </v-row>
      <v-row v-if="eligibleCampaigns.length > 1" no-gutters class="px-3 mb-3">
        <v-col cols="12">
          <StatisticsCampaignAutocomplete
            :campaignId="selectedCampaign?.id"
            :eligibleCampaigns="eligibleCampaigns"
            class="d-flex"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <NpsZoom
            :key="selectedCampaign?.id"
            :load="loadData()"
            :campaign="selectedCampaign"
            :widgetSubtitle="widgetSubtitle"
            :nisVoterProportions="npsMixin_nps[selectedCampaign?.id]"
            :nisSummary="
              npsMixin_nps[selectedCampaign?.id] &&
              nisMixin_npsSummary(selectedCampaign)
            "
            class="pt-0"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import NpsZoom from './NpsZoom'
  import CampaignTableWidget from './CampaignTableWidget'
  import StatisticsCampaignAutocomplete from '@statistics/shared/StatisticsCampaignAutocomplete'
  import NisMixin from '@statistics/shared/nis_mixin'
  import NpsMixin from '@statistics/shared/nps_mixin'

  export default {
    name: "NpsPage",
    props: {
      selectedCampaignId: { type: Number | String, required: true }
    },
    mixins: [
      NisMixin,
      NpsMixin,
    ],
    components: {
      CampaignTableWidget,
      StatisticsCampaignAutocomplete,
      NpsZoom,
    },
    methods: {
      loadData() {
        this.eligibleCampaigns.forEach(campaign => {
          this.npsMixin_loadNps(campaign)
        })
      }
    },
    computed: {
      ...mapGetters([
        'currentDashboardCampaignWithId',
        'currentDashboardScopedCampaigns',
        'dashboardFilterDates'
      ]),
      npsData() {
        return Object.values(this.npsMixin_nps)
      },
      selectedCampaign() {
        const npsData = this.npsData

        if (this.selectedCampaignId == 'default') {
          if (npsData.length) {
            const sortedNpsData = this.npsData.sort((a, b) => {
              if ('nisOpportunity' in a) {
                return b.nisOpportunity - a.nisOpportunity
              }
              return b.score - a.score
            })
            return this.currentDashboardCampaignWithId(sortedNpsData[0].id)
          }
          return undefined
        }
        return this.currentDashboardCampaignWithId(Number(this.selectedCampaignId))
      },
      eligibleCampaigns() {
        return this.currentDashboardScopedCampaigns.filter(
          campaign => campaign.hasNpsQuestion
        )
      },
      widgetSubtitle() {
        return this.eligibleCampaigns.length > 1 && this.selectedCampaign ?
               `${this.dashboardFilterDates.text} - ${this.selectedCampaign.name}` :
               this.dashboardFilterDates.text
      }
    }
  }
</script>
