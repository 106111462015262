<template>
  <div class="questions-list">
    <div v-if="questions && questions.length == 0" class="pa-3 bg-white">{{ $t('no_question_error') }}</div>
    <v-list two-line v-else-if="questions" class="py-0">
      <v-list-item-group
        :ripple="false"
        v-model="selected"
        active-class="primary--text"
      >
        <template v-for="(question, index) in questions">
          <v-list-item
            :key="index"
            class="pl-2 item"
            active-class="active"
            :ripple="false"
          >
            <template v-slot:prepend>

            </template>
            <template v-slot:default="{ active }">
              <v-list-item-icon>
                <w-zoom-button
                  class="mx-2"
                  :highlighted="active"
                />
              </v-list-item-icon>
              <v-list-item-content class="ml-4">
                <v-list-item-title class="name fs-13 c-primary" v-text="question.content"></v-list-item-title>

                <v-list-item-subtitle class="f-12">
                  {{ question.qcmQuestionChoices.map(c => c.choice).join(" / ") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-divider
            v-if="questions < questions.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
    <v-skeleton-loader v-else type="paragraph" class="pa-3 pt-5 bg-white" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: "QuestionsList",
    props: [ 'value', 'questionId', 'campaign' ],
    data: () => ({
      selected: []
    }),
    computed: {
      ...mapGetters([
      ])
    },
    asyncComputed: {
      questions: {
        async get() {
          const request = this.$basedRequest().select({
            campaigns: [ 'survey' ]
          }).where({
            id: this.campaign.id
          })
          
          const survey = (await this.$resolve(request))?.data?.campaigns[0]?.survey
          const questions = survey.questions.filter(q => q.type === 'QcmQuestion')
          if (this.questionId) {
            this.selected = questions.findIndex(q => q.id == this.questionId)
            this.$emit('input', questions[this.selected])
          } else if (!this.$vuetify.breakpoint.smAndDown && (!this.questionId || questions.length > 0)) {
            this.selected = 0
            this.$emit('input', questions[0])
            this.$router.push({ params: { sectionId: questions[0].id } })
          }
          return questions
        },
        default: null
      }
    },
    watch: {
      selected(newVal) {
        if (this.questions) {
          const question = this.questions[newVal]

          if (question) {
            this.$emit('input', question)
            this.$router.push({ params: { sectionId: question?.id || 'all' } })
          }
        }
      }
    }
  }
</script>

<style lang="stylus">
  .questions-list
    .name
      text-wrap: initial
    .v-list-item__icon
      margin-bottom: 0px !important
      margin-right: 6px !important

    .v-list-item--active:hover::before, .theme--light.v-list-item--active::before
      opacity: 0

    .item.active
      background-color: var(--sea-green-100) !important
    .item.active:hover
      background-color: var(--sea-green-300) !important
    .item:hover:not(.active)
      background: inherit !important
      background-color: var(--palegrey) !important

</style>
