<template>
  <v-card>
    <v-card-title>
      <h4 class="f-16 c-primary">{{ $t('automated_message_treatments') }}</h4>
    </v-card-title>
    <v-card-text>
      <Filters
        v-model="filters"
      />
      <Table
        :filters="filters"
      />
    </v-card-text>
  </v-card>
</template>
<script>
  import dayjs from 'dayjs'
  import { mapGetters } from 'vuex'

  import Filters from './Filters'
  import Table from './Table'

  export default {
    name: "AutomatedMessageTreatments",
    components: {
      Filters,
      Table
    },
    data() {
      return {
        filters: {},
      }
    },
    computed: {
      ...mapGetters([
      ])
    },
    methods: {
    }
  }
</script>
