import { mapGetters } from 'vuex'

export default {
  data: () => ({
    reloadInsatisfaction: 0
  }),
  computed: {
    ...mapGetters([
      'currentUser',
      'voterUpdateEvent'
    ]),
    isInsatisfactionForCurrentGroup() {
      return !!(
        !this.insatisfaction?.transfered ||
        this.currentUser?.userGroupId === this.insatisfaction?.userGroupId
      )
    },
    insatisfactionTransferedUserEmail() {
      return this.insatisfaction?.transferUserMail
    },
    insatisfactionTransferedToUserGroupName() {
      return this.insatisfaction?.userGroupName
    },
    insatisfactionTransferedByCurrentUser() {
      return this.insatisfaction?.transfered && this.insatisfaction?.transferUserId === this.currentUser.id
    },
    insatisfactionNotProcessed() {
      return !!(this.insatisfaction?.status === 'not_processed')
    },
    insatisfactionStatus() {
      return this.insatisfaction?.status || ''
    }
  },
  asyncComputed: {
    insatisfaction: {
      lazy: true,
      watch: ['reloadInsatisfaction'],
      default: undefined,
      async get() {
        const request = this.$basedRequest().select({
          insatisfactions: [
            'id',
            'transfered',
            'transfer_user_id',
            'transfer_user_mail',
            'user_group_id',
            'user_group_name',
            'status'
          ]
        }).where({ voter_id: this.voter.id })

        return (await this.$resolve(request, 'insatisfaction')).first()
      }
    }
  },
  watch: {
    voterUpdateEvent: function(voterUpdateEvent) {
      if (voterUpdateEvent.voter.uniqueId === this.voter?.uniqueId) {
        this.reloadInsatisfaction = this.reloadInsatisfaction + 1
      }
    }
  }
}
