<template>
  <WTreeView
    :expandDeep="1"
    :level="Object.values(translations)"
    class="manual-translations"
  >
    <template #leaf="{ leaf, deep, idx }">
      <v-row>
        <v-col
          v-for="(locale, index) of locales"
          :key="index"
        >
          <v-textarea
            :class="{ 'mt-2': idx == 0 }"
            :label="locale.toUpperCase()"
            :placeholder="leaf.id"
            :rows="leaf.translations[locale]?.split(/\r\n|\r|\n/)?.length || 1"
            :value="leaf.translations[locale]"
            @blur="updateTranslation($event, leaf, locale)"
            dense
            hide-details
            outlined
            persistent-placeholder
          />
        </v-col>
      </v-row>
    </template>
  </WTreeView>
</template>

<script>
  import _cloneDeep from 'lodash/cloneDeep'

  export default {
    name: 'ManualTranslations',
    props: {
      translations: { type: Object, required: true },
      locales: { type: Array, required: true },
    },
    model: {
      prop: 'translations',
      event: 'updateTranslations'
    },
    methods: {
      async updateTranslation(event, leaf, locale) {
        const value = event.srcElement.value.trim()
        if (value !== leaf.translations[locale] && value != '') {
          try {
            // Send update request to API.
            await this.$api.wizville.translationsAdmin.updateTranslation(leaf.id, value, locale)
            // Emit event to update translations.
            const translations = _cloneDeep(this.translations)
            translations[leaf.id].translations[locale] = value
            this.$emit('updateTranslations', translations)
            // Notify user.
            this.$store.dispatch(
              'notifySuccess',
              { timeout: 3000, message: `La traduction en '${locale}' de '${leaf.id}'' a bien été mise à jour` }
            )
          } catch (e) {
            this.$store.dispatch('notifyError', { message: e.message })
          }
        }
      }
    }
  }
</script>
