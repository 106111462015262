<template>
  <div class="d-flex align-center">
    <span
      class="f-12"
      >
        {{ $t('conversation_prefilled_messages_table_automated') }}
    </span>
    <v-chip
      v-if="!automated"
      class="ma-2 f-12"
      color="error"
      label
      x-small
    >
      Off
    </v-chip>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "Automated",
    props: {
      automated: {
        type: Boolean,
        required: true,
        default: false
      },
    },
    computed: {
    },
    methods: {
    }
  }
</script>

<style lang="stylus">
</style>
