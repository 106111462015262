// Rails time zones to avoid useless api calls
const TIME_ZONES = [
  {
    short: "International Date Line West",
    long: "(GMT-12:00) International Date Line West",
    translationKey: "timeZones.internationalDateLineWest",
    locale: "en-US"
  },
  {
    short: "American Samoa",
    long: "(GMT-11:00) American Samoa",
    translationKey: "timeZones.americanSamoa",
    locale: "en-AS"
  },
  {
    short: "Midway Island",
    long: "(GMT-11:00) Midway Island",
    translationKey: "timeZones.midwayIsland",
    locale: "en-UM"
  },
  {
    short: "Hawaii",
    long: "(GMT-10:00) Hawaii",
    translationKey: "timeZones.hawaii",
    locale: "en-US"
  },
  {
    short: "Alaska",
    long: "(GMT-08:00) Alaska",
    translationKey: "timeZones.alaska",
    locale: "en-US"
  },
  {
    short: "Pacific Time (US & Canada)",
    long: "(GMT-07:00) Pacific Time (US & Canada)",
    translationKey: "timeZones.pacificTimeUsCanada",
    locale: "en-US"
  },
  {
    short: "Tijuana",
    long: "(GMT-07:00) Tijuana",
    translationKey: "timeZones.tijuana",
    locale: "es-MX"
  },
  {
    short: "Arizona",
    long: "(GMT-07:00) Arizona",
    translationKey: "timeZones.arizona",
    locale: "en-US"
  },
  {
    short: "Chihuahua",
    long: "(GMT-06:00) Chihuahua",
    translationKey: "timeZones.chihuahua",
    locale: "es-MX"
  },
  {
    short: "Mazatlan",
    long: "(GMT-06:00) Mazatlan",
    translationKey: "timeZones.mazatlan",
    locale: "es-MX"
  },
  {
    short: "Mountain Time (US & Canada)",
    long: "(GMT-06:00) Mountain Time (US & Canada)",
    translationKey: "timeZones.mountainTimeUsCanada",
    locale: "en-US"
  },
  {
    short: "Central America",
    long: "(GMT-06:00) Central America",
    translationKey: "timeZones.centralAmerica",
    locale: "es-HN"
  },
  {
    short: "Central Time (US & Canada)",
    long: "(GMT-05:00) Central Time (US & Canada)",
    translationKey: "timeZones.centralTimeUsCanada",
    locale: "en-US"
  },
  {
    short: "Guadalajara",
    long: "(GMT-05:00) Guadalajara",
    translationKey: "timeZones.guadalajara",
    locale: "es-MX"
  },
  {
    short: "Mexico City",
    long: "(GMT-05:00) Mexico City",
    translationKey: "timeZones.mexicoCity",
    locale: "es-MX"
  },
  {
    short: "Monterrey",
    long: "(GMT-05:00) Monterrey",
    translationKey: "timeZones.monterrey",
    locale: "es-MX"
  },
  {
    short: "Saskatchewan",
    long: "(GMT-06:00) Saskatchewan",
    translationKey: "timeZones.saskatchewan",
    locale: "en-CA"
  },
  {
    short: "Bogota",
    long: "(GMT-05:00) Bogota",
    translationKey: "timeZones.bogota",
    locale: "es-CO"
  },
  {
    short: "Eastern Time (US & Canada)",
    long: "(GMT-04:00) Eastern Time (US & Canada)",
    translationKey: "timeZones.easternTimeUsCanada",
    locale: "en-US"
  },
  {
    short: "Indiana (East)",
    long: "(GMT-04:00) Indiana (East)",
    translationKey: "timeZones.indianaEast",
    locale: "en-US"
  },
  {
    short: "Lima",
    long: "(GMT-05:00) Lima",
    translationKey: "timeZones.lima",
    locale: "es-PE"
  },
  {
    short: "Quito",
    long: "(GMT-05:00) Quito",
    translationKey: "timeZones.quito",
    locale: "es-EC"
  },
  {
    short: "Atlantic Time (Canada)",
    long: "(GMT-03:00) Atlantic Time (Canada)",
    translationKey: "timeZones.atlanticTimeCanada",
    locale: "en-CA"
  },
  {
    short: "Caracas",
    long: "(GMT-04:00) Caracas",
    translationKey: "timeZones.caracas",
    locale: "es-VE"
  },
  {
    short: "Georgetown",
    long: "(GMT-04:00) Georgetown",
    translationKey: "timeZones.georgetown",
    locale: "en-GY"
  },
  {
    short: "La Paz",
    long: "(GMT-04:00) La Paz",
    translationKey: "timeZones.laPaz",
    locale: "es-BO"
  },
  {
    short: "Puerto Rico",
    long: "(GMT-04:00) Puerto Rico",
    translationKey: "timeZones.puertoRico",
    locale: "es-PR"
  },
  {
    short: "Santiago",
    long: "(GMT-04:00) Santiago",
    translationKey: "timeZones.santiago",
    locale: "es-CL"
  },
  {
    short: "Newfoundland",
    long: "(GMT-02:30) Newfoundland",
    translationKey: "timeZones.newfoundland",
    locale: "en-CA"
  },
  {
    short: "Brasilia",
    long: "(GMT-03:00) Brasilia",
    translationKey: "timeZones.brasilia",
    locale: "pt-BR"
  },
  {
    short: "Buenos Aires",
    long: "(GMT-03:00) Buenos Aires",
    translationKey: "timeZones.buenosAires",
    locale: "es-AR"
  },
  {
    short: "Greenland",
    long: "(GMT-02:00) Greenland",
    translationKey: "timeZones.greenland",
    locale: "kl-GL"
  },
  {
    short: "Montevideo",
    long: "(GMT-03:00) Montevideo",
    translationKey: "timeZones.montevideo",
    locale: "es-UY"
  },
  {
    short: "Mid-Atlantic",
    long: "(GMT-02:00) Mid-Atlantic",
    translationKey: "timeZones.midAtlantic",
    locale: "en-001"
  },
  {
    short: "Azores",
    long: "(GMT+00:00) Azores",
    translationKey: "timeZones.azores",
    locale: "pt-PT"
  },
  {
    short: "Cape Verde Is.",
    long: "(GMT-01:00) Cape Verde Is.",
    translationKey: "timeZones.capeVerdeIs",
    locale: "pt-CV"
  },
  {
    short: "Casablanca",
    long: "(GMT+01:00) Casablanca",
    translationKey: "timeZones.casablanca",
    locale: "ar-MA"
  },
  {
    short: "Dublin",
    long: "(GMT+01:00) Dublin",
    translationKey: "timeZones.dublin",
    locale: "en-IE"
  },
  {
    short: "Edinburgh",
    long: "(GMT+01:00) Edinburgh",
    translationKey: "timeZones.edinburgh",
    locale: "en-GB"
  },
  {
    short: "Lisbon",
    long: "(GMT+01:00) Lisbon",
    translationKey: "timeZones.lisbon",
    locale: "pt-PT"
  },
  {
    short: "London",
    long: "(GMT+01:00) London",
    translationKey: "timeZones.london",
    locale: "en-GB"
  },
  {
    short: "Monrovia",
    long: "(GMT+00:00) Monrovia",
    translationKey: "timeZones.monrovia",
    locale: "en-LR"
  },
  {
    short: "UTC",
    long: "(GMT+00:00) UTC",
    translationKey: "timeZones.utc",
    locale: "en-001"
  },
  {
    short: "Amsterdam",
    long: "(GMT+02:00) Amsterdam",
    translationKey: "timeZones.amsterdam",
    locale: "nl-NL"
  },
  {
    short: "Belgrade",
    long: "(GMT+02:00) Belgrade",
    translationKey: "timeZones.belgrade",
    locale: "sr-RS"
  },
  {
    short: "Berlin",
    long: "(GMT+02:00) Berlin",
    translationKey: "timeZones.berlin",
    locale: "de-DE"
  },
  {
    short: "Bern",
    long: "(GMT+02:00) Bern",
    translationKey: "timeZones.bern",
    locale: "de-CH"
  },
  {
    short: "Bratislava",
    long: "(GMT+02:00) Bratislava",
    translationKey: "timeZones.bratislava",
    locale: "sk-SK"
  },
  {
    short: "Brussels",
    long: "(GMT+02:00) Brussels",
    translationKey: "timeZones.brussels",
    locale: "nl-BE"
  },
  {
    short: "Budapest",
    long: "(GMT+02:00) Budapest",
    translationKey: "timeZones.budapest",
    locale: "hu-HU"
  },
  {
    short: "Copenhagen",
    long: "(GMT+02:00) Copenhagen",
    translationKey: "timeZones.copenhagen",
    locale: "da-DK"
  },
  {
    short: "Ljubljana",
    long: "(GMT+02:00) Ljubljana",
    translationKey: "timeZones.ljubljana",
    locale: "sl-SI"
  },
  {
    short: "Madrid",
    long: "(GMT+02:00) Madrid",
    translationKey: "timeZones.madrid",
    locale: "es-ES"
  },
  {
    short: "Paris",
    long: "(GMT+02:00) Paris",
    translationKey: "timeZones.paris",
    locale: "fr-FR"
  },
  {
    short: "Prague",
    long: "(GMT+02:00) Prague",
    translationKey: "timeZones.prague",
    locale: "cs-CZ"
  },
  {
    short: "Rome",
    long: "(GMT+02:00) Rome",
    translationKey: "timeZones.rome",
    locale: "it-IT"
  },
  {
    short: "Sarajevo",
    long: "(GMT+02:00) Sarajevo",
    translationKey: "timeZones.sarajevo",
    locale: "bs-BA"
  },
  {
    short: "Skopje",
    long: "(GMT+02:00) Skopje",
    translationKey: "timeZones.skopje",
    locale: "mk-MK"
  },
  {
    short: "Stockholm",
    long: "(GMT+02:00) Stockholm",
    translationKey: "timeZones.stockholm",
    locale: "sv-SE"
  },
  {
    short: "Vienna",
    long: "(GMT+02:00) Vienna",
    translationKey: "timeZones.vienna",
    locale: "de-AT"
  },
  {
    short: "Warsaw",
    long: "(GMT+02:00) Warsaw",
    translationKey: "timeZones.warsaw",
    locale: "pl-PL"
  },
  {
    short: "West Central Africa",
    long: "(GMT+01:00) West Central Africa",
    translationKey: "timeZones.westCentralAfrica",
    locale: "en-001"
  },
  {
    short: "Zagreb",
    long: "(GMT+02:00) Zagreb",
    translationKey: "timeZones.zagreb",
    locale: "hr-HR"
  },
  {
    short: "Athens",
    long: "(GMT+03:00) Athens",
    translationKey: "timeZones.athens",
    locale: "el-GR"
  },
  {
    short: "Bucharest",
    long: "(GMT+03:00) Bucharest",
    translationKey: "timeZones.bucharest",
    locale: "ro-RO"
  },
  {
    short: "Cairo",
    long: "(GMT+03:00) Cairo",
    translationKey: "timeZones.cairo",
    locale: "ar-EG"
  },
  {
    short: "Harare",
    long: "(GMT+02:00) Harare",
    translationKey: "timeZones.harare",
    locale: "en-ZW"
  },
  {
    short: "Helsinki",
    long: "(GMT+03:00) Helsinki",
    translationKey: "timeZones.helsinki",
    locale: "fi-FI"
  },
  {
    short: "Jerusalem",
    long: "(GMT+03:00) Jerusalem",
    translationKey: "timeZones.jerusalem",
    locale: "he-IL"
  },
  {
    short: "Kaliningrad",
    long: "(GMT+03:00) Kaliningrad",
    translationKey: "timeZones.kaliningrad",
    locale: "ru-RU"
  },
  {
    short: "Kyiv",
    long: "(GMT+03:00) Kyiv",
    translationKey: "timeZones.kyiv",
    locale: "uk-UA"
  },
  {
    short: "Pretoria",
    long: "(GMT+02:00) Pretoria",
    translationKey: "timeZones.pretoria",
    locale: "en-ZA"
  },
  {
    short: "Riga",
    long: "(GMT+03:00) Riga",
    translationKey: "timeZones.riga",
    locale: "lv-LV"
  },
  {
    short: "Sofia",
    long: "(GMT+03:00) Sofia",
    translationKey: "timeZones.sofia",
    locale: "bg-BG"
  },
  {
    short: "Tallinn",
    long: "(GMT+03:00) Tallinn",
    translationKey: "timeZones.tallinn",
    locale: "et-EE"
  },
  {
    short: "Vilnius",
    long: "(GMT+03:00) Vilnius",
    translationKey: "timeZones.vilnius",
    locale: "lt-LT"
  },
  {
    short: "Baghdad",
    long: "(GMT+03:00) Baghdad",
    translationKey: "timeZones.baghdad",
    locale: "ar-IQ"
  },
  {
    short: "Kuwait",
    long: "(GMT+03:00) Kuwait",
    translationKey: "timeZones.kuwait",
    locale: "ar-KW"
  },
  {
    short: "Minsk",
    long: "(GMT+03:00) Minsk",
    translationKey: "timeZones.minsk",
    locale: "be-BY"
  },
  {
    short: "Nairobi",
    long: "(GMT+03:00) Nairobi",
    translationKey: "timeZones.nairobi",
    locale: "en-KE"
  },
  {
    short: "Riyadh",
    long: "(GMT+03:00) Riyadh",
    translationKey: "timeZones.riyadh",
    locale: "ar-SA"
  },
  {
    short: "Volgograd",
    long: "(GMT+04:00) Volgograd",
    translationKey: "timeZones.volgograd",
    locale: "ru-RU"
  },
  {
    short: "Tehran",
    long: "(GMT+03:30) Tehran",
    translationKey: "timeZones.tehran",
    locale: "fa-IR"
  },
  {
    short: "Abu Dhabi",
    long: "(GMT+04:00) Abu Dhabi",
    translationKey: "timeZones.abuDhabi",
    locale: "ar-AE"
  },
  {
    short: "Baku",
    long: "(GMT+04:00) Baku",
    translationKey: "timeZones.baku",
    locale: "az-AZ"
  },
  {
    short: "Muscat",
    long: "(GMT+04:00) Muscat",
    translationKey: "timeZones.muscat",
    locale: "ar-OM"
  },
  {
    short: "Tbilisi",
    long: "(GMT+04:00) Tbilisi",
    translationKey: "timeZones.tbilisi",
    locale: "ka-GE"
  },
  {
    short: "Yerevan",
    long: "(GMT+04:00) Yerevan",
    translationKey: "timeZones.yerevan",
    locale: "hy-AM"
  },
  {
    short: "Kabul",
    long: "(GMT+04:30) Kabul",
    translationKey: "timeZones.kabul",
    locale: "fa-AF"
  },
  {
    short: "Ekaterinburg",
    long: "(GMT+05:00) Ekaterinburg",
    translationKey: "timeZones.ekaterinburg",
    locale: "ru-RU"
  },
  {
    short: "Islamabad",
    long: "(GMT+05:00) Islamabad",
    translationKey: "timeZones.islamabad",
    locale: "en-PK"
  },
  {
    short: "Karachi",
    long: "(GMT+05:00) Karachi",
    translationKey: "timeZones.karachi",
    locale: "en-PK"
  },
  {
    short: "Tashkent",
    long: "(GMT+05:00) Tashkent",
    translationKey: "timeZones.tashkent",
    locale: "uz-UZ"
  },
  {
    short: "Chennai",
    long: "(GMT+05:30) Chennai",
    translationKey: "timeZones.chennai",
    locale: "ta-IN"
  },
  {
    short: "Kolkata",
    long: "(GMT+05:30) Kolkata",
    translationKey: "timeZones.kolkata",
    locale: "bn-IN"
  },
  {
    short: "Mumbai",
    long: "(GMT+05:30) Mumbai",
    translationKey: "timeZones.mumbai",
    locale: "mr-IN"
  },
  {
    short: "New Delhi",
    long: "(GMT+05:30) New Delhi",
    translationKey: "timeZones.newDelhi",
    locale: "hi-IN"
  },
  {
    short: "Sri Jayawardenepura",
    long: "(GMT+05:30) Sri Jayawardenepura",
    translationKey: "timeZones.sriJayawardenepura",
    locale: "si-LK"
  },
  {
    short: "Kathmandu",
    long: "(GMT+05:45) Kathmandu",
    translationKey: "timeZones.kathmandu",
    locale: "ne-NP"
  },
  {
    short: "Almaty",
    long: "(GMT+06:00) Almaty",
    translationKey: "timeZones.almaty",
    locale: "kk-KZ"
  },
  {
    short: "Astana",
    long: "(GMT+06:00) Astana",
    translationKey: "timeZones.astana",
    locale: "kk-KZ"
  },
  {
    short: "Dhaka",
    long: "(GMT+06:00) Dhaka",
    translationKey: "timeZones.dhaka",
    locale: "bn-BD"
  },
  {
    short: "Urumqi",
    long: "(GMT+06:00) Urumqi",
    translationKey: "timeZones.urumqi",
    locale: "ug-CN"
  },
  {
    short: "Rangoon",
    long: "(GMT+06:30) Rangoon",
    translationKey: "timeZones.rangoon",
    locale: "my-MM"
  },
  {
    short: "Bangkok",
    long: "(GMT+07:00) Bangkok",
    translationKey: "timeZones.bangkok",
    locale: "th-TH"
  },
  {
    short: "Hanoi",
    long: "(GMT+07:00) Hanoi",
    translationKey: "timeZones.hanoi",
    locale: "vi-VN"
  },
  {
    short: "Jakarta",
    long: "(GMT+07:00) Jakarta",
    translationKey: "timeZones.jakarta",
    locale: "id-ID"
  },
  {
    short: "Krasnoyarsk",
    long: "(GMT+08:00) Krasnoyarsk",
    translationKey: "timeZones.krasnoyarsk",
    locale: "ru-RU"
  },
  {
    short: "Novosibirsk",
    long: "(GMT+07:00) Novosibirsk",
    translationKey: "timeZones.novosibirsk",
    locale: "ru-RU"
  },
  {
    short: "Beijing",
    long: "(GMT+08:00) Beijing",
    translationKey: "timeZones.beijing",
    locale: "zh-CN"
  },
  {
    short: "Chongqing",
    long: "(GMT+08:00) Chongqing",
    translationKey: "timeZones.chongqing",
    locale: "zh-CN"
  },
  {
    short: "Hong Kong",
    long: "(GMT+08:00) Hong Kong",
    translationKey: "timeZones.hongKong",
    locale: "zh-HK"
  },
  {
    short: "Kuala Lumpur",
    long: "(GMT+08:00) Kuala Lumpur",
    translationKey: "timeZones.kualaLumpur",
    locale: "ms-MY"
  },
  {
    short: "Perth",
    long: "(GMT+08:00) Perth",
    translationKey: "timeZones.perth",
    locale: "en-AU"
  },
  {
    short: "Singapore",
    long: "(GMT+08:00) Singapore",
    translationKey: "timeZones.singapore",
    locale: "en-SG"
  },
  {
    short: "Taipei",
    long: "(GMT+08:00) Taipei",
    translationKey: "timeZones.taipei",
    locale: "zh-TW"
  },
  {
    short: "Ulaan Bataar",
    long: "(GMT+08:00) Ulaan Bataar",
    translationKey: "timeZones.ulaanBataar",
    locale: "mn-MN"
  },
  {
    short: "Osaka",
    long: "(GMT+09:00) Osaka",
    translationKey: "timeZones.osaka",
    locale: "ja-JP"
  },
  {
    short: "Sapporo",
    long: "(GMT+09:00) Sapporo",
    translationKey: "timeZones.sapporo",
    locale: "ja-JP"
  },
  {
    short: "Seoul",
    long: "(GMT+09:00) Seoul",
    translationKey: "timeZones.seoul",
    locale: "ko-KR"
  },
  {
    short: "Tokyo",
    long: "(GMT+09:00) Tokyo",
    translationKey: "timeZones.tokyo",
    locale: "ja-JP"
  },
  {
    short: "Yakutsk",
    long: "(GMT+09:00) Yakutsk",
    translationKey: "timeZones.yakutsk",
    locale: "ru-RU"
  },
  {
    short: "Adelaide",
    long: "(GMT+09:30) Adelaide",
    translationKey: "timeZones.adelaide",
    locale: "en-AU"
  },
  {
    short: "Darwin",
    long: "(GMT+09:30) Darwin",
    translationKey: "timeZones.darwin",
    locale: "en-AU"
  },
  {
    short: "Brisbane",
    long: "(GMT+10:00) Brisbane",
    translationKey: "timeZones.brisbane",
    locale: "en-AU"
  },
  {
    short: "Canberra",
    long: "(GMT+10:00) Canberra",
    translationKey: "timeZones.canberra",
    locale: "en-AU"
  },
  {
    short: "Guam",
    long: "(GMT+10:00) Guam",
    translationKey: "timeZones.guam",
    locale: "en-GU"
  },
  {
    short: "Hobart",
    long: "(GMT+10:00) Hobart",
    translationKey: "timeZones.hobart",
    locale: "en-AU"
  },
  {
    short: "Melbourne",
    long: "(GMT+10:00) Melbourne",
    translationKey: "timeZones.melbourne",
    locale: "en-AU"
  },
  {
    short: "Port Moresby",
    long: "(GMT+10:00) Port Moresby",
    translationKey: "timeZones.portMoresby",
    locale: "en-PG"
  },
  {
    short: "Sydney",
    long: "(GMT+10:00) Sydney",
    translationKey: "timeZones.sydney",
    locale: "en-AU"
  },
  {
    short: "Vladivostok",
    long: "(GMT+10:00) Vladivostok",
    translationKey: "timeZones.vladivostok",
    locale: "ru-RU"
  },
  {
    short: "Magadan",
    long: "(GMT+11:00) Magadan",
    translationKey: "timeZones.magadan",
    locale: "ru-RU"
  },
  {
    short: "New Caledonia",
    long: "(GMT+11:00) New Caledonia",
    translationKey: "timeZones.newCaledonia",
    locale: "fr-NC"
  },
  {
    short: "Solomon Is.",
    long: "(GMT+11:00) Solomon Is.",
    translationKey: "timeZones.solomonIs",
    locale: "en-SB"
  },
  {
    short: "Srednekolymsk",
    long: "(GMT+11:00) Srednekolymsk",
    translationKey: "timeZones.srednekolymsk",
    locale: "ru-RU"
  },
  {
    short: "Auckland",
    long: "(GMT+12:00) Auckland",
    translationKey: "timeZones.auckland",
    locale: "en-NZ"
  },
  {
    short: "Fiji",
    long: "(GMT+12:00) Fiji",
    translationKey: "timeZones.fiji",
    locale: "en-FJ"
  },
  {
    short: "Kamchatka",
    long: "(GMT+12:00) Kamchatka",
    translationKey: "timeZones.kamchatka",
    locale: "ru-RU"
  },
  {
    short: "Marshall Is.",
    long: "(GMT+12:00) Marshall Is.",
    translationKey: "timeZones.marshallIs",
    locale: "en-MH"
  },
  {
    short: "Wellington",
    long: "(GMT+12:00) Wellington",
    translationKey: "timeZones.wellington",
    locale: "en-NZ"
  },
  {
    short: "Chatham Is.",
    long: "(GMT+12:45) Chatham Is.",
    translationKey: "timeZones.chathamIs",
    locale: "en-NZ"
  },
  {
    short: "Nuku'alofa",
    long: "(GMT+13:00) Nuku'alofa",
    translationKey: "timeZones.nukualofa",
    locale: "en-TO"
  },
  {
    short: "Samoa",
    long: "(GMT+13:00) Samoa",
    translationKey: "timeZones.samoa",
    locale: "en-WS"
  },
  {
    short: "Tokelau Is.",
    long: "(GMT+13:00) Tokelau Is.",
    translationKey: "timeZones.tokelauIs",
    locale: "en-TK"
  }
]

export const MAIN_LOCAL_TO_TIME_ZONE = {
  "de": "de-DE",
  "en": "en-GB",
  "ar": "ar-SA",
  "ca": "ca-ES",
  "es": "es-ES",
  "fr": "fr-FR",
  "it": "it-IT",
  "nl": "nl-NL",
  "no": "no-NO",
  "pl": "pl-PL",
  "pt": "pt-PT",
  "sk": "sk-SK",
  "sv": "sv-SE",
  "tr": "tr-TR"
}


export default TIME_ZONES
