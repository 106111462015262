<template>
  <div class="free-question-answer f-14">
    <FreeAnswerCard
      v-if="topicsVerbatims.length"
      :topicsVerbatims="topicsVerbatims"
      :voter="voter"
      :answer="answer"
    />
    <WQuote
      v-else
      :content="answer.freeAnswer"
    />
    <VoterPictures v-if="pictures" :pictures="pictures"/>
  </div>
</template>

<script>
  import VoterPictures from "./../VoterPictures";
  import FreeAnswerCard from "./FreeAnswerCard";
  export default {
    name: "FreeQuestionAnswer",
    components: {
      VoterPictures,
      FreeAnswerCard
    },
    props: {
      answer: { required: true },
      question: { required: true },
      pictures: { required: false },
      topicsVerbatims: {
        required: false,
        type: Array,
        default: () => []
      },
      voter: {
        required: false,
        type: Object,
        default: () => {}
      }
    }
  }
</script>

<style lang='stylus' scoped>
  .free-question-answer
    line-height: 21px
</style>
