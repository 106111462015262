<template>
  <div
    v-click-outside="clickedOutside"
    class="border-lightblue voter-actions"
    :class="{ show: currentVoterShowActions }"
  >
    <v-tabs
      v-model="tab"
      grow
      height="45px"
    >
      <v-tabs-slider :class="tabSliderClass"></v-tabs-slider>

      <v-tab
        v-for="item in tabs"
        :id="item.id"
        :key="item.id"
        :href="`#${item.id}`"
        @click="tabClicked"
        :ripple="false"
        class="px-0"
        :class="item.tabClassNames"
      >
        <div class="d-flex align-center ga-1">
          <v-icon :class="item.tabIconClassNames">{{ item.icon }}</v-icon>
          <div class="tab-title">{{ item.title }}</div>
        </div>
      </v-tab>

      <v-menu
        v-model="othersMenuOpen"
        v-if="othersTabs.length > 0"
        offset-y
        left
        :top="!currentVoterShowedActions"
        :bottom="currentVoterShowedActions"
      >
        <template v-slot:activator="{ on, attrs }">
          <a
            href="#others"
            class="other-tabs d-flex flex-column others px-4 justify-center bg-palegrey"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </a>
        </template>
        <v-list class="exclude-click-outside">
          <v-list-item
            class="exclude-click-outside"
            :class="item.tabClassNames"
            v-for="item in othersTabs"
            :key="item.id"
            @click="swithItem(item.id)"
          >
            <v-icon small class="mr-1">{{ item.icon }}</v-icon>
            <span class="tab-title sub-tab exclude-click-outside">{{ item.title }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-tabs>
    <ActionTabs
      :voter="voter"
      :gBrandName="gBrandName"
      :fieldsCustomization="fieldsCustomization"
      :prospectFields="prospectFields"
      :smartAnswer="smartAnswer"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SendMessage from './SendMessage'
import WriteUp from './WriteUp'
import Assign from './Assign'
import SendInternalMemo from './SendInternalMemo'
import TrustvilleReport from './TrustvilleReport'
import SendFeedback from './SendFeedback'
import ActionTabs from '../ActionTabs'
import VoterMixin from '../../voter_mixin'
import CurrentUserCapacityMixin from '../../current_user_capacity_mixin'
import InsatisfactionMixin from '../../insatisfaction_mixin'

const MAX_TAB_LENGTH = 3

export default {
  name: 'VoterAction',
  components: {
    SendMessage,
    WriteUp,
    Assign,
    SendInternalMemo,
    SendFeedback,
    TrustvilleReport,
    ActionTabs,
  },
  mixins: [
    VoterMixin,
    InsatisfactionMixin,
    CurrentUserCapacityMixin
  ],
  props: {
    actions: { type: Array, required: true },
    section: { type: String, required: true },
    gBrandName: { required: true },
    fieldsCustomization: { required: true },
    prospectFields: { required: true },
    smartAnswer: { type: String },
  },
  data() {
    return {
      tabs: [],
      othersTabs: [],
      othersMenuOpen: false,
      disabledClickedOutside: false
    }
  },
  methods: {
    clickedOutside(e) {
      // We momentarily disable the clickedOutside event to prevent
      // the menu from closing when switching tabs actions
      if (this.disabledClickedOutside) {
        this.disabledClickedOutside = false
        return
      }

      if (
        !e.target.closest('.exclude-click-outside') &&
        this.currentVoterShowedActions &&
        this.$vuetify.breakpoint.mdAndUp
      ) {
        this.$store.dispatch('setShowVoterActions', false)
      }
    },
    tabClicked(e) {
      if (!this.currentVoterShowedActions) {
        this.$store.dispatch('setShowVoterActions', true)
      } else {
        this.$store.dispatch(
          'setShowVoterActions',
          e.currentTarget.id !== this.tab
        )
      }
    },
    resetTabs() {
      const tabs = []
      const othersTabs = []

      this.actions.forEach((tab) => {
        if (tab.displayCondition === undefined || tab.displayCondition) {
          if (tabs.length < MAX_TAB_LENGTH) {
            tabs.push(tab)
          } else {
            othersTabs.push(tab)
          }
        }
      })

      this.tabs = tabs
      this.othersTabs = othersTabs
      this.tab = this.tabs.find(tab => tab.id === this.$route.query.voter_action)?.id
    },
    swithItem(itemId) {
      const item = this.othersTabs.filter(tab => tab.id === itemId)[0]

      const removedItem = this.tabs.pop();

      this.tabs.push(
        ...this.othersTabs.splice(this.othersTabs.indexOf(item), 1)
      )
      this.othersTabs.push(removedItem)

      if (item) {
        this.tab = item.id
        this.$store.dispatch('setShowVoterActions', true)
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentVoter',
      'currentUser',
      'currentVoterShowActions',
      'currentVoterShowedActions',
      'currentVoterActionsTab',
      'currentVoterIsActionSubMenuOpened',
      'voterUpdateEvent'
    ]),
    tabSliderClass() {
      const tabColor = {
        write_up: 'c-violette border-color-violette',
        send_message: 'c-secondary border-color-secondary'
      }

      return tabColor[this.currentVoterActionsTab] || ''
    },
    tab: {
      get: function () {
        return this.currentVoterActionsTab
      },
      set: function (tab) {
        this.$store.dispatch('setActionsTab', tab)
      }
    },
  },
  watch: {
    currentVoterShowedActions: function(value) {
      this.othersMenuOpen = false
    },
    currentVoterActionsTab: function(value) {
      if (value != 'cleared' && value != null) {
        if (this.tabs.filter(tab => tab.id === value).length === 0) {
          this.swithItem(value)
        }
      }
    },
    actions: function(newValue, oldValue) {
      this.resetTabs()
    },
    voterUpdateEvent: function(voterUpdateEvent) {
      this.$store.dispatch('setShowVoterActions', false)
      this.$store.dispatch('setActionsTab', 'cleared')
    },
    section: function() {
      this.disabledClickedOutside = true

      if (this.currentVoterShowedActions) {
        this.$store.dispatch('setShowVoterActions', true)
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.resetTabs()

      if (['send_message', 'write_up', 'assign', 'send_internal_memo', 'send_feedback', 'close_feedback', 'trustville_report'].includes(this.$route.query.voter_action)) {
        this.$store.dispatch('setActionsTab', this.$route.query.voter_action)
        this.$store.dispatch('setShowVoterActions', true)
      }
      this.$el.addEventListener('transitionstart', (e) => {
        if (e.propertyName === 'height') {
          this.$store.dispatch('setShowedVoterActions', null)
        }
      })
      this.$el.addEventListener('transitionend', (e) => {
        if (e.propertyName === 'height') {
          this.$store.dispatch('setShowedVoterActions', this.currentVoterShowActions)
        }
      })
    })
  }
}
</script>

<style lang="stylus" scoped>
  @import '~@theme/colors.styl'

  .voter-actions
    transition: height 0.3s
    height: 45px
    overflow: hidden
    &.show
      height: 380px
      z-index: 1
      position: relative
      overflow visible
    .v-window__container
      justify-content center !important
    .v-window
      overflow visible !important
    .v-slide-group__prev--disabled
      display none !important
    .v-slide-group__next--disabled
      display none !important
    .v-tabs-slider-wrapper
      display none !important

    .v-tabs-items, .v-window-item
      height 338px

    .tab-title
      text-transform: initial
      white-space: nowrap
      &.other-title
        margin-bottom: 6px

    .tab-subtitle
      text-transform: initial
      padding-top: 3px
      white-space: nowrap

    .v-form
      height: 100%
    .others
      border-bottom 1px solid var(--lightblue) !important
    .v-tab
      &:not(:last-child)
        border-right 1px solid var(--lightblue) !important
      &:not(.v-tab--active)
        background-color: var(--palegrey) !important
        border-bottom 1px solid var(--lightblue) !important
</style>
