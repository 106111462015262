<template>
  <div class="question-title c-blue">
    <span v-if="topicName" class="fb-14 lh-15">{{ topicName }} - </span>

    <span class="f-13">
      {{ title }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dynamicFields from '@shared/helpers/dynamic-fields'
import VoterMixin from '../../../../voter_mixin'

export default {
  name: 'QuestionTitle',
  mixins: [
    VoterMixin,
  ],
  props: {
    question: { required: true, type: Object },
    position: { required: false, type: Number },
    prospectFields: {
      required: false,
      type: Object,
      default: () => {
        return {}
      },
    },
    fieldsCustomization: {
      required: false,
      type: Object,
      default: () => {
        return {}
      },
    },
    gBrandName: { required: false, type: String, default: '' }
  },
  computed: {
    ...mapGetters([
      'currentLexicon',
      'currentUser',
      'currentVoter',
      'isLcl'
    ]),
    title() {
      return (this.voterIsPublicPlatform || !this.position) ?
        this.advancedContent :
        `Q${this.position}. ${this.advancedContent}`
    },
    topicName() {
      if (this.question.subtype === 'NpsQuestion') {
        return (this.isLcl ? "IRC" : "NPS")
      } else if (this.question?.topic?.name) {
        return this.question.topic.name
      } else {
        return null
      }
    },
    advancedContent() {
      return dynamicFields(
        this.question.content,
        this.question.subtype === 'NpsQuestion',
        this.prospectFields,
        this.fieldsCustomization,
        this.currentLexicon,
        this.gBrandName,
        this.currentVoter?.placeName,
        this.currentVoter
      )
    },
  },
}
</script>

<style lang="stylus" scoped></style>
