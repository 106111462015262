<template>
  <WStatsWidget
    class="repartition-widget"
    :title="$t('topic_transactional_unit_repartition_title')"
    :subtitle="defaultSubtitle"
    :cols="12"
    height="200px"
    :loading="topic === undefined || nisTopicSummary === undefined"
    contentWidth="100%"
    contentPadding="0.75em"
  >
    <template v-if="topic === undefined || nisTopicSummary === undefined" #options>
      <w-drop-down-menu :items="exportOptions" icon="mdi-download" />
    </template>

    <template #content>
      <WRepartitionHorizontalFrieze
        :nbPromoters="topic.nbPromoters"
        :nbDetractors="topic.nbDetractors"
        :nbNeutrals="topic.nbNeutrals"
        :promotersSubLabel="promotersLabel"
        :neutralsSubLabel="neutralsLabel"
        :detractorsSubLabel="detractorsLabel"
        :promotersTooltip="promotersTooltip"
        :neutralsTooltip="neutralsTooltip"
        :detractorsTooltip="detractorsTooltip"
        height="62px"
        max-width="100%"
        :emoticons="true"
        :promoters-legend="repartitionSubtitle(repartitionScore('promoters'), topic.name, $vuetify.breakpoint.mdAndUp)"
        :neutrals-legend="repartitionSubtitle(repartitionScore('neutrals'), topic.name, $vuetify.breakpoint.mdAndUp)"
        :detractors-legend="repartitionSubtitle(repartitionScore('detractors'), topic.name, $vuetify.breakpoint.mdAndUp)"
      />
    </template>
  </WStatsWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
import NisMixin from '@statistics/shared/nis_mixin'

export default {
  name: "TopicTransactionalUnitRepartitionWidget",
  mixins: [
    WidgetMixin,
    NisMixin,
  ],
  props: {
    campaign: { type: Object, required: true },
    topic: { type: Object },
    nisTopicSummary: { type: Object },
  },
  computed: {
    ...mapGetters([
      'dashboardFilterReady',
      'placeIdsScope',
      'dashboardFilterRequest',
      'datesScope'
    ]),
    promotersLabel() {
      return this.label(
        this.nisMixin_currentTransactionalUnitData(
          this.campaign,
          this.nisTopicSummary
        ).promoters
      )
    },
    neutralsLabel() {
      return this.label(
        this.nisMixin_currentTransactionalUnitData(
          this.campaign,
          this.nisTopicSummary
        ).neutrals
      )
    },
    detractorsLabel() {
      return this.label(
        this.nisMixin_currentTransactionalUnitData(
          this.campaign,
          this.nisTopicSummary
        ).detractors
      )
    },
    promotersTooltip() {
      return this.tooltip(
        this.topic.nbPromoters,
        this.nisMixin_currentTransactionalUnitData(
          this.campaign,
          this.nisTopicSummary
        ).promoters
      )
    },
    neutralsTooltip() {
      return this.tooltip(
        this.topic.nbNeutrals,
        this.nisMixin_currentTransactionalUnitData(
          this.campaign,
          this.nisTopicSummary
        ).neutrals
      )
    },
    detractorsTooltip() {
      return this.tooltip(
        this.topic.nbDetractors,
        this.nisMixin_currentTransactionalUnitData(
          this.campaign,
          this.nisTopicSummary
        ).detractors
      )
    }
  },
  methods: {
    label(transactionalUnitValue) {
      return this.nisMixin_transactionalUnit([this.campaign]).currency ?
             transactionalUnitValue.toLocaleString(
               this.$i18n.locale,
               {
                 style: 'currency',
                 currency: this.nisMixin_transactionalUnit([this.campaign]).currency,
                 maximumFractionDigits: 2
               }
             ) :
             transactionalUnitValue.toFixed(1).replaceAll('.', ',')
    },
    tooltip(count, transactionalUnitValue) {
      const percentage = this.$options.filters.toPercent(
        count / (this.topic.nbPromoters + this.topic.nbNeutrals + this.topic.nbDetractors)
      )
      if (this.isLcl) {
        return this.$t(`topic_transactional_unit_repartition_widget_${this.nisMixin_transactionalUnit([this.campaign]).nisColumnName}_label_lcl`, {
          count,
          percentage,
          transactional_unit_value: this.label(transactionalUnitValue)
        })
      } else {
        return this.$t(`topic_transactional_unit_repartition_widget_${this.nisMixin_transactionalUnit([this.campaign]).nisColumnName}_label`, {
          count,
          percentage,
          transactional_unit_value: this.label(transactionalUnitValue)
        })
      }
    },
    repartitionScore(reviewType) {
      return this.campaign.avgScoreScale.max === 5 ? (
        {
          promoters: { min: 4, max: 5 },
          neutrals: { min: 3, max: 3 },
          detractors: { min: 0, max: 2 }
        }[reviewType]) : (
        {
          promoters: { min: 9, max: 10 },
          neutrals: { min: 7, max: 8 },
          detractors: { min: 0, max: 6}
        }[reviewType])
    },
    repartitionSubtitle({ min, max }, topicName, long) {
      return long ? (
        this.$t("topicTransactionalUnitRepartitionWidgetRepartitionSubtitle", {
          min,
          max,
          topicName
        })
      ) : (
        this.$t("topicTransactionalUnitRepartitionWidgetRepartitionSubtitleShort", {
          min,
          max
        })
      )
    },
  }
}
</script>
