var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question-tree"},[_vm._l((_vm.questions),function(question,idx){return [(_vm.isProductQuestion(question) && _vm.answersForQuestion[question.id])?[_c('ProductQuestionAnswers',{attrs:{"question":question,"answer":_vm.answersForQuestion[question.id][0],"prospect-fields":_vm.prospectFields,"fields-customization":_vm.fieldsCustomization,"pictures":_vm.pictureUrlsForQuestion[question.id],"g-brand-name":_vm.gBrandName}})]:_vm._e(),_vm._v(" "),(_vm.shouldShowQuestion(question))?_c('div',{key:question.id,class:['question-filled', 'question-filled-level-' + _vm.questionLevel]},[_c('QuestionTitle',{class:{ 'mt-1': idx > 0 },attrs:{"voter":_vm.voter,"question":question,"prospect-fields":_vm.prospectFields,"fields-customization":_vm.fieldsCustomization,"g-brand-name":_vm.gBrandName,"position":_vm.questionLevel === 0 ? idx + 1 : null}}),_vm._v(" "),(question.type === 'MultipleCriteriaQuestion')?[_c('QuestionTree',{staticClass:"mt-2",attrs:{"voter":_vm.voter,"questions":question.markQuestions,"answers":_vm.answers,"pictures":_vm.pictures,"question-level":_vm.questionLevel + 1}})]:[_c('QuestionAnswer',{staticClass:"mt-1",attrs:{"question":question,"answers":_vm.answersForQuestion[question.id],"pictures":_vm.pictureUrlsForQuestion[question.id],"topicsVerbatims":_vm.topicsVerbatimForQuestion[question.id],"voter":_vm.voter}})],_vm._v(" "),(question.questionConditions)?[_c('QuestionTree',{attrs:{"voter":_vm.voter,"questions":question.questionConditions.map((q) => q.qcmQuestion),"answers":_vm.answers,"is-question-condition":true,"question-level":_vm.questionLevel + 1}})]:_vm._e()],2):(
        !_vm.voterIsPublicPlatform &&
        (_vm.totalQuestions > idx + 1 &&
        (idx === 0 || _vm.answersForQuestion[_vm.questions[idx - 1].id]))
        && question.subtype !== 'ProductsQuestion'
      )?_c('div',{class:[
        'question-not-filled',
        'question-not-filled-level-' + _vm.questionLevel,
      ]},[_vm._v("\n      [...]\n    ")]):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }