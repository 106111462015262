import $api from '@api'

const campaignPreferencesContext = {
  state: () => ({
    loaded: null,
    campaign: null
  }),
  getters: {
    currentCampaignPreferences(state, getters) {
      if (state.loaded) {
        return state.campaign
      } else {
        return null
      }
    },
    currentCampaignPreferencesCampaignId(state, getters) {
      return getters.currentCampaignPreferences?.id
    },
    currentCampaignPreferencesCampaignDashboardId(state, getters) {
      return getters.currentCampaignPreferences?.dashboardId
    },
    currentCampaignPreferencesCampaignAvgScoreScale(state, getters) {
      return getters.currentCampaignPreferences?.avgScoreScale
    },
    currentCampaignPreferencesCampaignPlaceCount(state, getters) {
      return getters.currentCampaignPreferences?.placeCount
    },
    currentCampaignPreferencesCampaignIsProduct(state, getters) {
      return getters.currentCampaignPreferences?.hasProductQuestion
    },
  },
  mutations: {
    setCampaignPreferencesContext(state, campaign) {
      state.loaded = Date.now()
      state.campaign = campaign
    }
  },
  actions: {
    async campaignPreferencesContext(context, { campaignId }) {
      const ctx = await $api.wizville.contexts.authCampaignPreferences(campaignId)
      if (Number(campaignId) !== context.state?.campaign?.id || !context.state.loaded) {
        if (ctx) {
          context.commit('setCampaignPreferencesContext', { ...ctx })
        }
      }
    }
  }
}

export default campaignPreferencesContext
