<template>
  <div class="collaborators-edit-sidebar c-primary">
    <v-navigation-drawer
      v-model="open"
      right
      persistent
      fixed
      width="400"
      @input="onChangeRightbar"
    >
      <div class="d-flex flex-column" :style="{ height: '100%' }">
        <div class="d-flex pl-3 pt-1 justify-space-between align-center">
          <div class="fs-14">{{ title }}</div>
          <v-btn
            icon
            color="black"
            plain
            @click="closeRightbar(false)"
            class="close-sidebar-btn"
          >
            <v-icon small color="primary">mdi-close</v-icon>
          </v-btn>
        </div>

        <v-form ref="userForm" v-model="formIsValid" class="d-flex flex-column" :style="{ height: '100%' }">
          <div class="px-3 pt-3"
            v-if="!isLoadingForm"
          >
            <v-text-field
              ref="emailTextFieldRef"
              v-if="isEditionUser"
              v-model="emailData"
              outlined
              dense
              append-icon=""
              hide-selected
              hide-no-data
              hide-details="auto"
              :error-messages="emailBackendError"
              :rules="[emailDataRule]"
            >
              <template v-slot:label>
                <span class="f-13">{{ $t('collaborator_sidebar_email') }}</span>
              </template>
            </v-text-field>

            <v-combobox
              v-else
              ref="emailTextFieldRef"
              v-model="emailsData"
              outlined
              dense
              multiple
              chips
              clearable
              append-icon=""
              small-chips
              hide-selected
              hide-no-data
              hide-details="auto"
              :error-messages="emailBackendError"
              :rules="[emailsDataRule]"
            >
              <template v-slot:label>
                <span class="f-13">{{ $t('collaborator_sidebar_emails') }}</span>
              </template>
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  label
                  small
                  @click:close="removeEmailChip(data.item)"
                  class="my-1"
                  :color="isValidEmail(data.item) && !isDuplicateEmail(data.item) ? undefined : 'error'"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>

            <v-combobox
              v-model="languageLocaleData"
              :items="selectableLanguages"
              @change="onSelectLanguage"
              item-value="locale"
              item-text="language"
              outlined
              dense
              hide-no-data
              class="mt-2"
              hide-details="auto"
              :rules="[languageLocaleDataRule]"
            >
              <template v-slot:label>
                <span class="f-13">{{ $t('collaborator_sidebar_language') }}</span>
              </template>

              <template v-slot:item="{ item}">
                <span>
                  <w-flag-icon
                    :locale="item.locale"
                    class="mr-2"
                  />
                    {{ item.language }}
                </span>
              </template>

              <template v-slot:selection="{ item }">
                <span>
                  <w-flag-icon
                    :locale="item.locale"
                    class="mr-2"
                  />
                    {{ item.language }}
                </span>
              </template>
            </v-combobox>

            <v-combobox
              v-model="timeZoneData"
              :items="selectableTimeZones"
              item-value="timeZoneShort"
              item-text="timeZone"
              outlined
              dense
              hide-no-data
              hide-details="auto"
              class="mt-2"
              :rules="[timeZoneDataRule]"
            >
              <template v-slot:label>
                <span class="f-13">{{ $t('collaborator_sidebar_time_zone') }}</span>
              </template>

              <template v-slot:item="data">
                <span>
                  {{ data.item.timeZone }}
                </span>
              </template>
            </v-combobox>

            <v-combobox
              v-model="userGroupData"
              :items="groupsList"
              ref="userGroupComboboxRef"
              outlined
              dense
              hide-no-data
              hide-details="auto"
              hide-selected
              item-text="name"
              item-value="id"
              class="mt-2"
              :menu-props="userGroupComboboxMenu"
              :error-messages="userGroupBackendError"
              :rules="[userGroupDataRule]"
            >
              <template v-slot:label>
                <span class="f-13">{{ $t('collaborator_sidebar_time_user_group') }}</span>
              </template>
            </v-combobox>

            <div class="mt-2">
              <PlaceGroupWTreeSelect
                ref="placeGroupForm"
                :placeGroupsList="placeGroupsList"
                v-model="placeGroupsOrPlaceIdsData"
                :errorMessages="placeGroupOrPlaceIdsBackendError"
                @updatePlaceGroupFormIsValid="updatePlaceGroupFormIsValid"
              />
            </div>
          </div>

          <div v-else>
            <v-skeleton-loader type="text,text,text" class="pa-3" />
            <v-skeleton-loader type="text,text,text" class="pa-3" />
          </div>

          <div v-if="resultsUsers" class="px-3 pt-3">
            <v-alert v-if="errorsEmails && !formsValidity" outlined type="error">
              <span class="f-13">{{ $t('collaborator_sidebar_inform_error') }}</span>
            </v-alert>
          </div>

          <div class="flex-grow-1"></div>

          <hr class="border-bodygrey" />
          <div class="d-flex align-center pa-3">
            <div class="flex-grow-1 mr-2" :style="{ width: '50%' }">
              <v-btn
                @click="closeRightbar"
                block
                outlined
                class="fs-12 pa-3"
                small
                color="info"
              >
                {{ $t('collaborator_sidebar_cancel_btn') }}
              </v-btn>
            </div>
            <div class="flex-grow-1" :style="{ width: '50%' }">
              <v-btn
                v-if="rightbarActionData == 'create'"
                @click="createUsers"
                block
                :disabled="!formsValidity"
                class="pa-3 c-white"
                small
                color="info"
                :loading="isCreatingUsers"
              >
                <span class="fs-12">
                  {{ $t('collaborator_sidebar_add_user_btn') }}
                </span>
              </v-btn>

              <v-btn
                v-else
                @click="editUser"
                block
                :disabled="!formsValidity"
                class="pa-3 c-white"
                small
                color="info"
                :loading="isCreatingUsers || isEditingUser"
              >
                <span class="fs-12">
                  {{ $t('collaborator_sidebar_edit_user_btn') }}
                </span>
              </v-btn>
            </div>
          </div>
        </v-form>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _debounce from "lodash/debounce"
import PlaceGroupWTreeSelect from './PlaceGroupWTreeSelect'
import { LANGUAGES_NAME } from '@i18n/setup'
import TIME_ZONES, { MAIN_LOCAL_TO_TIME_ZONE } from '@platform/constants/time_zones'

const USER_ERRORS = {
  duplicate_email: 'duplicate_email',
  no_places_or_place_groups: 'no_places_or_place_groups',
  no_user_group: 'no_user_group'
}

export default {
  props: [
    'isRightbarUserOpen', 'userGroupsList', 'placeGroupsList',
    'placeGroupId', 'rightbarActionData',
    'editionUserIdData'
  ],
  components: {
    PlaceGroupWTreeSelect
  },
  data() {
    return {
      open: false,
      isCreatingUsers: false,
      isEditingUser: false,
      userGroupComboboxMenu: undefined,
      placeGroupTextFieldFocusData: false,
      emailsData: [],
      emailData: '',
      languageLocaleData: null,
      timeZoneData: null,
      userGroupData: null,
      placeGroupsOrPlaceIdsData: [],
      groupsList: undefined,
      languageLocaleDataError: undefined,
      emailsDataError: undefined,
      userGroupBackendError: undefined,
      emailBackendError: undefined,
      placeGroupOrPlaceIdsBackendError: undefined,
      formIsValid: false,
      placeGroupFormIsValid: false,
      resultsUsers: undefined,
      isFetchingEditionUser: undefined,
    }
  },
  methods: {
    async createUsers() {
      this.isCreatingUsers = true
      this.resultsUsers = null
      try {
        const response = await this.$api.wizville.collaborators.createUsers(
          {
            emails: this.emailsData,
            countryLocale: this.languageLocaleData,
            userGroup: this.userGroupData,
            placeGroupsOrPlaceIds: this.placeGroupsOrPlaceIdsData,
            timeZone: this.timeZoneData
          }
        )

        if (response.status === "ok") {
          this.resultsUsers = response.results
          this.setBackendErrors()
          if (!this.errorUsers.length) {
            this.$store.dispatch('notifySuccess', { timeout: 10000, message: response.message, newLine: true })
            this.closeRightbar()
            this.$emit('reloadUserList', this.successUsers.join('-'))
          }
        } else {
          this.$store.dispatch('notifyError', { message: response.message })
        }
      } catch (error) {
        this.$store.dispatch('notifyError', { message: error.message })
      }
      this.isCreatingUsers = false
    },
    async editUser() {
      this.isEditingUser = true
      try {
        const response = await this.$api.wizville.collaborators.editUser(
          {
            userId: this.editionUserIdData,
            email: this.emailData,
            countryLocale: this.languageLocaleData,
            userGroup: this.userGroupData,
            placeGroupsOrPlaceIds: this.placeGroupsOrPlaceIdsData,
            timeZone: this.timeZoneData
          }
        )

        if (response.status === "ok") {
          this.resultsUsers = response.results
          this.setBackendErrors()
          if (!this.errorUsers.length) {
            this.$store.dispatch('notifySuccess', { timeout: 10000, message: response.message, newLine: true })
            this.closeRightbar()
            this.$emit('reloadUserList', this.successUsers.join('-'))
          }
        } else {
          this.$store.dispatch('notifyError', { message: response.message })
        }
      } catch (error) {
        this.$store.dispatch('notifyError', { message: error.message })
      }
      this.isEditingUser = false
    },
    onChangeRightbar(event) {
      if (!this.isLoadingForm) {
        this.resetForms()
      }
      
      if (event === false) {
        this.placeGroupsOrPlaceIdsData = []
        this.resultsUsers = null
        this.$emit('toggleRightbarUser', event)
      } else {
        if (this.placeGroupId) {
          this.placeGroupsOrPlaceIdsData = [this.placeGroupId]
        }
      }
    },
    updatePlaceGroupFormIsValid(value) {
      this.placeGroupFormIsValid = value
    },
    resetForms() {
      this.$refs.userForm.reset()
      this.$refs.userForm.resetValidation()
      this.$refs.placeGroupForm.resetForm()
    },
    closeRightbar() {
      this.isFetchingEditionUser = undefined
      this.open = false
    },
    isValidEmail(email) {
      const emailPattern = /[\w._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+/
      return emailPattern.test(email)
    },
    isDuplicateEmail(email) {
      const emailsErrors = this.backendEmailsByError[USER_ERRORS.duplicate_email]
      return emailsErrors?.includes(email)
    },
    removeEmailChip(email) {
      this.emailsData = this.emailsData.filter(e => e !== email)
    },
    // set the width of the combobox menu to the width of the combobox
    // to be able to truncate elements in the menu
    setUserGroupComboboxMenuWidth() {
      if (this.$refs.userGroupCombobox) {
        const comboboxWidth = this.$refs.userGroupComboboxRef.$el.clientWidth
        this.userGroupComboboxMenu = {
          maxWidth: `${comboboxWidth}px`,
          contentClass: 'user-group-combobox-menu'
        };
      }
    },
    emailDataRule(value) {
      if (!value) {
        return this.$t('collaborator_sidebar_errors_required_field')
      }
      return this.isValidEmail(value) || this.$t('collaborator_sidebar_errors_email_conformity')
    },
    emailsDataRule(values) {
      if (values.length === 0) {
        return this.$t('collaborator_sidebar_errors_required_field')
      }
      return values.every(this.isValidEmail) || this.$t('collaborator_sidebar_errors_email_conformity')
    },
    languageLocaleDataRule(value) {
      if (!value) {
        return this.$t('collaborator_sidebar_errors_required_field')
      }
      return this.selectableLanguages.some(item => item.locale === value.locale);
    },
    timeZoneDataRule(value) {
      if (!value) {
        return this.$t('collaborator_sidebar_errors_required_field')
      }
      return this.selectableTimeZones.some(item => item.timeZoneShort === value.timeZoneShort);
    },
    userGroupDataRule(value) {
      if (!value) {
        return this.$t('collaborator_sidebar_errors_required_field')
      }
      return true
    },
    onSelectLanguage() {
      let timeZoneLocale = MAIN_LOCAL_TO_TIME_ZONE[this.languageLocaleData.locale]
      this.timeZoneData = this.timeZoneByLocale[timeZoneLocale]
    },
    setDataModelFromUserEdition(userData) {
      if (!this.isFetchingEditionUser) {
        this.$nextTick(() => {
          this.emailData = userData.email
          this.languageLocaleData = this.selectableLanguages.find(country => country.locale === userData.locale)
          this.timeZoneData = this.selectableTimeZones.find(timeZone => timeZone.timeZoneShort === userData.timeZone)
          this.userGroupData = userData.userGroupData
          this.placeGroupsOrPlaceIdsData = userData.placeGroupOrPlaceKeys
        })
      }
    },
    setEmailTextFieldFocus() {
      if (!this.loadingForm && this.open) {
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.$refs.emailTextFieldRef) {
              this.$refs.emailTextFieldRef.focus()
            }
          }, 100)
        })
      }
    },
    emailsWithError(errorType) {
      if (this.resultsUsers) {
        return Object.entries(this.resultsUsers)
        .filter(([email, errors]) => errors.includes(errorType))
        .map(([email, errors]) => email)
      }
    },
    setEmailBackendError() {
      const emailsErrors = this.backendEmailsByError[USER_ERRORS.duplicate_email]
      if (emailsErrors?.length) {
        this.emailBackendError = emailsErrors.length > 1 ? this.$t(`collaborator_sidebar_errors_${USER_ERRORS.duplicate_email}s`) :
          this.$t(`collaborator_sidebar_errors_${USER_ERRORS.duplicate_email}`)
      }
    },
    setUserGroupBackendError() {
      const userGroupErrorEmails = this.backendEmailsByError[USER_ERRORS.no_user_group]
      if (userGroupErrorEmails?.length) {
        this.userGroupBackendError = this.$t(`collaborator_sidebar_errors_${USER_ERRORS.no_user_group}`)
      }
    },
    setPlaceGroupOrPlaceIdsBackendError() {
      const placeGroupOrPlaceIdsEmails = this.backendEmailsByError[USER_ERRORS.no_places_or_place_groups]
      if (placeGroupOrPlaceIdsEmails?.length) {
        this.placeGroupOrPlaceIdsBackendError = this.$t(`collaborator_sidebar_errors_${USER_ERRORS.no_places_or_place_groups}`)
      }
    },
    setBackendErrors() {
      this.setEmailBackendError()
      this.setUserGroupBackendError()
      this.setPlaceGroupOrPlaceIdsBackendError()
    },
  },
  asyncComputed: {
    UserToEdit: {
      async get() {
        if (this.isEditionUser && this.editionUserIdData) {
          let userData = null
          this.isFetchingEditionUser = true
          try {
            const response = await this.$api.wizville.collaborators.getUser(this.editionUserIdData)

            if (response.status === "ok") {
              userData = response.userData
            } 
          } catch (error) {
            this.$store.dispatch('notifyError', { message: error.message })
          }
          this.isFetchingEditionUser = false

          this.setDataModelFromUserEdition(userData)
        }
      },
      watch: ['editionUserIdData']
    }
  },
  computed: {
    ...mapGetters([
      'currentDashboard',
      'isDashboardMulti'
    ]),
    title() {
      return this.rightbarActionData == 'create' ? this.$t('collaborator_sidebar_add_users') : this.$t('collaborator_sidebar_edit_users')
    },
    isLoadingForm() {
      return (this.isEditionUser && [true, undefined].includes(this.isFetchingEditionUser))
    },
    isEditionUser() {
      return this.rightbarActionData == 'edit'
    },
    successUsers() {
      return Object.entries(this.resultsUsers)
        .filter(([email, errors]) => errors.length === 0)
        .map(([email, errors]) => email)
    },
    errorUsers() {
      return Object.entries(this.resultsUsers)
        .filter(([email, errors]) => errors.length !== 0)
        .map(([email, errors]) => email)
    },
    successEmails() {
      return this.successUsers.join(', ');
    },
    errorsEmails() {
      return this.errorUsers.join(', ');
    },
    formsValidity() {
      return this.placeGroupFormIsValid && this.formIsValid
    },
    timeZoneByLocale() {
      return this.selectableTimeZones.reduce((hash, timeZone) => {
        hash[timeZone.locale] = timeZone
        return hash
      }, {})
    },
    selectableLanguages() {
      const languageLocales = Object.entries(LANGUAGES_NAME).map(([id, locale]) => {
        return { "locale": id, "language": this.$t(`languages.${locale}`) }
      }).sort(
        (a, b) => a.language.localeCompare(b.language)
      ).filter(
        localeLanguage => !localeLanguage.language.includes('languages.')
      )

      return languageLocales
    },
    selectableTimeZones() {
      return TIME_ZONES.map((timeZone) => {
        return { "timeZoneShort": timeZone.short, "timeZone": this.$t(timeZone.translationKey), locale: timeZone.locale }
      });
    },
    backendEmailsByError() {
      if (!this.resultsUsers) { return {} }

      return Object.entries(this.resultsUsers).reduce((hash, [email, errors]) => {
        errors.forEach(error => {
          if (!hash[error]) {
            hash[error] = []
          }
          hash[error].push(email);
        })
        return hash
      }, {})
    }
  },
  watch: {
    isRightbarUserOpen(newValue) {
      this.open = newValue
    },
    userGroupsList(newValue) {
      this.groupsList = newValue
    },
    open() {
      this.setEmailTextFieldFocus()
    },
    isLoadingForm() {
      this.setEmailTextFieldFocus()
    },
    userGroupData() {
      this.userGroupBackendError = undefined
    },
    emailsData() {
      this.emailBackendError = undefined
    },
    emailData() {
      this.emailBackendError = undefined
    },
    placeGroupsOrPlaceIdsData() {
      this.placeGroupOrPlaceIdsBackendError = undefined
    }
  },
  mounted() {
    window.addEventListener('resize', this.setUserGroupComboboxMenuWidth)
  },
}
</script>

<style lang="stylus">
  .collaborators-edit-sidebar
    .v-btn
      text-transform:none !important
      letter-spacing: normal

    .v-text-field__details
      margin-bottom: 0px !important

  // unfortunately we can override the header of the list with slot so we have to override the css
  .user-group-combobox-menu
    .v-subheader
      height: 30px
      font-size: 13px
    .v-list-item
      height: 30px
      min-height: 30px
      .v-list-item__title
        font-size: 14px
</style>

