const camelCase = require('camelcase');

export function getEventList(currentNode, events = {}, name = "", payload = [], parents = []) {
  if (Array.isArray(currentNode)) {
     for (const nodeName of Object.values(currentNode)) {
       getEventList(nodeName, events, `${name}_${nodeName}`, payload, parents)
     }
  } else if (typeof currentNode === 'object') {
    for (const [nodeName, nodeContent] of Object.entries(currentNode)) {
      switch (nodeName) {
        case 'payload':
          break;
        case 'events':
          getEventList(nodeContent, events, name, payload.concat(nodeContent.payload || []), parents)
          break;
        default:
          const nodePayload = payload.concat(nodeContent.payload || [])
          const subNodeName = `${name}_${nodeName}`
          
          events[camelCase(subNodeName)] = { payload: nodePayload, parents: parents }
          getEventList(nodeContent, events, subNodeName, nodePayload, parents.concat(nodeName))
          break;
      }
    }
  } else {
    events[camelCase(name)] = { payload: payload, parents: parents }
  }
 
 return events
}

export function payloadEvent(eventList, eventName, eventPayload) {
  if (eventList[eventName]) {
    const payload = { at: Date.now() }

    for (const payloadItem of Object.values(eventList[eventName].payload)) {
      if (typeof eventPayload === "object" && eventPayload[payloadItem]) {
        payload[payloadItem] = eventPayload[payloadItem]
      } else if (eventList[eventName].payload.length === 1) {
        payload[payloadItem] = eventPayload
      } else {
        console.error(eventName, payloadItem, "not found")
        return null
      }
    }

    return payload
  } else {
    console.error(eventName, "event not found")
    return null
  }
}