<template>
  <div class="fill-height assign">
    <div
      class="fill-height"
      v-if="displayThirdPartyTransferTab"
    >
      <v-tabs
        v-model="tab"
        color="secondary"
      >
        <v-tab
          key="internally"
          :ripple="false"
        >
            {{$t('assign_internally')}}
        </v-tab>
        <v-tab
          key="third-party"
          :ripple="false"
        >
          {{$t('assign_to_third_party')}}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="internally">
          <AssignInternally :voter="voter" />
        </v-tab-item>
        <v-tab-item key="third-party">
          <AssignToThirdParty :voter="voter" />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <AssignInternally :voter="voter" v-else />
  </div>
</template>

<script>
  import {mapGetters} from "vuex";

  import AssignInternally from "./AssignInternally";
  import AssignToThirdParty from "./AssignToThirdParty";

  import CurrentUserCapacityMixin from '../../../current_user_capacity_mixin'
  import InsatisfactionMixin from '../../../insatisfaction_mixin'

  export default {
    name: "Assign",
    components: {
      AssignToThirdParty,
      AssignInternally
    },
    mixins: [
      CurrentUserCapacityMixin,
      InsatisfactionMixin
    ],
    props: {
      voter: { required: true },
    },
    data() {
      return {
        content: '',
        tab: null
      }
    },
    computed: {
      ...mapGetters([
        'currentVoter',
        'currentUserGroupId',
        'currentUserId',
        'currentDashboardCampaignIds'
      ]),
      displayThirdPartyTransferTab() {
        return this.voter.source === 'google' && this.canAssignInsatisfactionToThirdParty
      },
    }
  }
</script>

<style lang='stylus'>
  @import '~@theme/medias.styl'
  .assign
    .v-tab
      text-transform: none !important
    .fill-height
      height: 100%

    .v-window.v-item-group
      height: calc(100% - 47px) !important
    .v-window__container
      height: 100% !important
</style>

