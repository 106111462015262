<template>
  <div class="reannotation-table">
    <WDataTable
      v-if="items?.length > 0"
      class="mt-3"
      :headers="headers"
      :items="items"
    >
      <template #item.createdAt="{ value }">
        <w-date :date="value" font="f-12" :displayTime="true" />
      </template>

      <template #item.period="{ value }">
        <div class="nowrap f-12">{{ value.dateBegin | ddmmyyyy }} - {{ value.dateEnd | ddmmyyyy }}</div>
      </template>

      <template #item.status="{ item, value }">
        <v-progress-circular
          :rotate="-90"
          :size="40"
          :width="5"
          :value="value"
          color="secondary"
          class="f-12"
        >
         {{ value }}%
        </v-progress-circular>
      </template>

      <template #item.nbScheduled="{ value }">
        {{ value }}
      </template>

      <template #item.nbOk="{ value }">
        {{ value }}
      </template>

      <template #item.nbKo="{ value }">
        {{ value }}
      </template>

      <template #item.verbatimAnnotationBatchId="{ value }">
        <div class="nowrap">
          <v-btn icon color="error" class="my-0" @click="cancel(value)"><WTooltip icon="mdi-cancel" :message="$t('stop_annotation_message')" /></v-btn>
          <v-btn icon color="success" class="my-0" @click="exportAnnotation(value)"><WTooltip icon="mdi-export" :message="$t('export_annotation_message')" /></v-btn>
        </div>
      </template>
    </WDataTable>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'ReannotationTable',
    props: [ 'nbReannotation' ],
    data() {
      return {
        headers: [
          { text: this.$t('reannotation'), value: 'createdAt' },
          { text: this.$t('period'), value: 'period' },
          { text: this.$t('status'), value: "status" },
          { text: this.$t('to_annotate'), value: 'nbScheduled' },
          { text: 'OK', value: 'nbOk' },
          { text: 'KO', value: 'nbKo' },
          { text: '', value: 'verbatimAnnotationBatchId' }
        ],
        heartBeat: 0,
        timer: null
      }
    },
    computed: {
      ...mapGetters(['currentCampaignPreferencesCampaignId']),
      items() {
        return this.verbatimAnnotationBatchs?.map(item => {
          const nbScheduled = item.progression?.scheduled?.total || 0
          const nbOk = item.progression?.ok?.total || 0
          const nbKo = item.progression?.ko?.total || 0
          const total = Object.values(item.progression).reduce((total, v) => {
            total += v.total
            return total
          }, 0)

          const status = (total > 0 ? Math.round(((nbOk + nbKo) * 100 / total)) : null)

          return {
            createdAt: item.createdAt,
            period: { dateBegin: item.dateBegin, dateEnd: item.dateEnd },
            progress: item.progression,
            verbatimAnnotationBatchId: item.id,
            status: status,
            nbOk: nbOk,
            nbKo: nbKo,
            nbScheduled: nbScheduled
          }
        })
      }
    },
    asyncComputed: {
      verbatimAnnotationBatchs: {
        async get() {
          let request = this.$basedRequest().select({
            verbatim_annotation_batchs: [
              "id",
              "created_at",
              "date_begin",
              "date_end",
              "progression"
          ]}).where({
            campaign_id: this.currentCampaignPreferencesCampaignId
          }).order(
            ['created_at', 'desc']
          )

          const result = (await this.$resolve(request))

          return result?.data?.verbatimAnnotationBatchs
        },
        watch: [ 'heartBeat' ]
      }

    },
    methods: {
      async cancel(verbatimAnnotationBatchId) {
        await this.$api.wizville.verbatimAnnotationBatchs.cancel(verbatimAnnotationBatchId)
        this.heartBeat += 1
        this.$store.dispatch('notifySuccess')
      },
      async exportAnnotation(verbatimAnnotationBatchId) {
        const result = await this.$api.wizville.verbatimAnnotationBatchs.exportAnnotation(verbatimAnnotationBatchId)
        this.$textToFile("annotation_batch_export.csv", result.csv)
        this.$store.dispatch('notifySuccess')
      },
      cancelAutoUpdate () {
        clearInterval(this.timer)
      }
    },
    created () {
      this.timer = setInterval(() => { this.heartBeat += 1 }, (this.nbReannotation > 0 ? 2500 : 10000))
    },
    beforeUnmount () {
      this.cancelAutoUpdate()
    }
  }
</script>

