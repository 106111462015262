var render = function render(){var _vm=this,_c=_vm._self._c;return _c('WLateralSectionLayout',{staticClass:"pl-3 topic-zoom",scopedSlots:_vm._u([{key:"lateral-section",fn:function(){return [_c('TopicScoreWidget',{attrs:{"topic":_vm.topic,"cols":12}}),_vm._v(" "),(_vm.isMonoPlace && !_vm.isNetworkMonoPlace)?_c('TopicPlaceRankingWidget',{attrs:{"campaign":_vm.campaign,"topic":_vm.topic}}):_vm._e(),_vm._v(" "),(_vm.campaign && _vm.nisMixin_canUseNisFeature([_vm.campaign]))?_c('TransactionalUnitToEarnWidget',{attrs:{"campaign":_vm.campaign,"voterProportions":_vm.topic,"summary":_vm.nisTopicSummary,"topic":_vm.topic}}):_vm._e(),_vm._v(" "),(
        _vm.hasAccessToNewPlatformAchievementsTab &&
        _vm.currentDashboardAchievementsEnabled &&
        !_vm.campaign.hasProductQuestion &&
        _vm.topic
      )?_c('TopicAchievementActionRecommendationsWidget',{attrs:{"topic":_vm.topic}}):_vm._e()]},proxy:true},{key:"main-section",fn:function(){return [(_vm.campaign && _vm.nisMixin_canUseNisFeature([_vm.campaign]))?_c('TopicRepartitionWidget',{attrs:{"campaign":_vm.campaign,"topic":_vm.topic,"nisVoterProportions":_vm.topic,"nisSummary":_vm.nisTopicSummary}}):(_vm.topic && !_vm.topicsMixin_isDisplayTypeScale(_vm.topic))?_c('TopicRepartitionWidget',{attrs:{"campaign":_vm.campaign,"topic":_vm.topic}}):_vm._e(),_vm._v(" "),_c('TopicEvolutionWidget',{attrs:{"campaign":_vm.campaign,"topic":_vm.topic}}),_vm._v(" "),_c('TopicConditionalQuestionsWidget',{attrs:{"campaign":_vm.campaign,"topic":_vm.topic}}),_vm._v(" "),(
        !_vm.isNetworkMonoPlace &&
        _vm.hasAccessToNewPlatformRankingTab &&
        !_vm.campaign.hasProductQuestion
      )?_c('TopicRankingTableWidget',{attrs:{"campaign":_vm.campaign,"topic":_vm.topic}}):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }