<template>
  <v-btn
    :id="id"
    rounded
    :ripple="false"
    x-large
    class="voter-action"
    :class="buttonClassNames"
    @click="clicked"
  >
    {{ title }}
    <v-icon
      class="icon ml-3"
      :class="iconClassNames"
    >
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "Action",
    components: {
    },
    props: {
      id: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      },
      buttonClassNames: {
        type: String,
        required: true
      },
      iconClassNames: {
        type: String,
        required: true
      }
    },
    data() {
      return {}
    },
    methods: {
      clicked(e) {
        this.$emit('onClicked', e)
      }
    },
    computed: {
      ...mapGetters([
        'currentVoter',
      ]),
    }
  }
</script>

<style lang="stylus">
  .voter-action
     padding-right: 1px !important
     text-transform: unset
    .icon
      margin-right: 1px
      height: 48px
      width: 48px
      border-radius: 24px
</style>
