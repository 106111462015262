<template>
  <div class="action">
    <Form
      v-if="isEditing"
      :achievementActionTemplate="achievementActionTemplate"
      @save="onSave"
      @cancel="onCancel"
    />
    <div class="fill-height" v-else>
      <v-hover
        v-if="achievementActionTemplate"
        v-slot="{ hover }"
      >
        <div class="fill-height d-flex align-center">
          <v-chip
            class="action-chip ml-2"
            color="primary"
            :outlined="true"
          >
            <div class="d-flex f-13 text-truncate">
              <span v-if="achievementActionTemplate.emoji">
                {{ `${achievementActionTemplate.emoji}&nbsp;` }}
              </span>
              <span class="text-truncate">
                {{ achievementActionTemplate.name }}
              </span>
            </div>
          </v-chip>
          <div v-show="hover && !hideButtons" class="ml-auto mr-2">
            <v-icon
              class="c-primary f-20"
              @click="onDeleteClicked"
            >
              mdi-delete
            </v-icon>
            <v-icon
              class="c-primary f-20"
              @click="onEditClicked"
            >
              mdi-pencil
            </v-icon>
          </div>

        </div>
      </v-hover>
      <div
        class="fill-height d-flex align-center"
        v-else
      >
        <v-chip
          class="f-30 ml-2"
          color="primary"
          :outlined="true"
          @click="onEditClicked"
          :disabled='hideButtons'
        >
          +
        </v-chip>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import Form from './Form'

  export default {
    name: 'Action',
    components: {
      Form
    },
    data () {
      return {
        isEditing: false
      }
    },
    props: {
      achievementActionTemplate: {
        required: false
      },
      hideButtons: {
        required: false,
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters([
        'currentCampaignPreferencesCampaignId'
      ]),
    },
    methods: {
      onEditClicked() {
        this.$emit('editingActionTemplate', true)
        this.isEditing = true
      },
      onCancel() {
        this.$emit('editingActionTemplate', false)
        this.isEditing = false
      },
      async onSave(params) {
        let requestParams = { ...params }

        requestParams.campaign_id = this.currentCampaignPreferencesCampaignId

        let response

        try {
          if (this.achievementActionTemplate) {
            response = await this.$api.wizville.achievementActionTemplates.update(
              this.achievementActionTemplate.id,
              requestParams
            )
          } else {
            response = await this.$api.wizville.achievementActionTemplates.create(
              requestParams
            )
          }

          if (response.status === "ok") {
            const messageKey = this.achievementActionTemplate ?
              'achievement_action_template_update_notify_success' :
              'achievement_action_template_create_notify_success'

            this.$store.dispatch('notifySuccess', {
              message: this.$t(
                messageKey
              ), timeout: 5000
            })

            this.$emit('saved')
            this.isEditing = false
            this.$emit('editingActionTemplate', false)
          } else {
            this.$store.dispatch('notifyError', { message: response.message })
          }
        } catch (error) {
          this.$store.dispatch('notifyError', { message: error.message })
        }
      },
      async onDeleteClicked() {
        try {
          const response = await this.$api.wizville.achievementActionTemplates.destroy(
            this.achievementActionTemplate.id
          )

          if (response.status === "ok") {

            this.$store.dispatch('notifySuccess', {
              message: this.$t(
                'achievement_action_template_delete_notify_success'
              ), timeout: 5000
            })

            this.$emit('deleted')
          } else {
            this.$store.dispatch('notifyError', { message: response.message })
          }
        } catch (error) {
          this.$store.dispatch('notifyError', { message: error.message })
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .action
    width: 100%
    height: 40px
</style>
