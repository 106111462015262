<template>
  <v-list class="pa-0 footer-menu">
    <v-list-item class="bg-palegrey pr-0 border-bodygrey">
      <div class="d-flex flex-column truncate align-self-center flex-grow-1 mb-1 mr-3">
        <router-link :to="{ name: settingslink }">
          <div class="d-flex flex-row">
            <span class="f-14 c-grey text-truncate">
              {{userBrand.name}}
            </span>
          </div>
          <div class="d-flex flex-row f-12">
            <span class="text-truncate c-grey">
              {{currentUser.mail}}
            </span>
          </div>
        </router-link>
      </div>

      <div align="center" class="sign-out flex-grow-0 flex-shrink-1 align-self-stretch bg-bodygrey">
        <a href="/logout">
          <v-icon class="mt-0 c-grey f-32">mdi-power</v-icon>
        </a>
      </div>
    </v-list-item>
  </v-list>
</template>

<script>
  import { mapGetters } from "vuex"

  export default {
    name: 'FooterMenu',
    components: {},
    computed: {
      ...mapGetters(
        [
          'currentUser'
        ]
      ),
      settingslink() {
        if (this.currentUserIsAdmin) {
          return 'local_reputation_monitoring_gmb_overview'
        } else {
          return this.lrmBrandPreferenceEnabled ? 'local_reputation_monitoring_gmb_assignment' : 'settings_my_account'
        }
      }
    },
    asyncComputed: {
      userBrand: {
        async get() {
          let request = this.$basedRequest()
            .select({
              brands: ['name'],
            })
            .where({
              id: this.currentUser.brandId,
            })

          const response = (await this.$resolve(request)).first()
          return response
        },
        default() {
          return {}
        },
      },
    },
  }
</script>

<style lang="stylus">
  .footer-menu
    .sign-out
      min-width: 46px
      line-height: 46px
      .v-icon
        min-height:46px

    .text-truncate
      max-width: 100%

    .truncate
      min-width: 0%
</style>

