<template>
  <v-list-item class="bottom-menu">
    <v-list-item-icon>
      <v-icon class="mt-0 c-bluegrey" dense>{{ icon }}</v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title class="f-15 c-bluegrey">{{ title }}</v-list-item-title>
    </v-list-item-content>

    </v-btn>
  </v-list-item>
</template>

<script>
  export default {
    name: "BottomMenu",
    components: {},
    props: {
      hovered: {
        required: false,
        type: Boolean,
        default: true
      },
      title: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="stylus">
  @import '~@theme/constants.styl'

  .bottom-menu
    max-height: $filters-height !important
    min-height: $filters-height !important
    // position: fixed !important;
    bottom: 80 !important;

    .v-list-item__icon:first-child
      margin-right: 4px !important

    .v-list-item__icon
      margin: 12px 0px
      .v-icon
        margin-top: 0px !important
        margin-left: 3px
        font-size: 15px
</style>

