export default {
  state: () => ({
    requestedTransactionalUnit: undefined,
    netImpactScoreSummariesByCampaignId: {},
    netImpactScoreSummariesLoaded: undefined
  }),
  getters: {
    requestedTransactionalUnit(state) {
      return state.requestedTransactionalUnit
    },
    netImpactScoreSummariesByCampaignId(state) {
      return state.netImpactScoreSummariesByCampaignId
    },
    netImpactScoreSummariesLoaded(state) {
      return state.netImpactScoreSummariesLoaded
    }
  },
  mutations: {
    requestTransactionalUnitChange(state, value) {
      state.requestedTransactionalUnit = value
    },
    setNetImpactScoreSummariesByCampaignId(state, value) {
      state.netImpactScoreSummariesByCampaignId = value
    },
    setNetImpactScoreSummariesLoaded(state, value) {
      state.netImpactScoreSummariesLoaded = value
    }
  }
}
