<template>
  <v-dialog
    v-model="show"
    max-width="1400px"
    :retain-focus="false"
  >
    <v-card>
      <v-card-title class="d-flex align-center">
        <span class="c-primary">
          {{ $t('openai_preferences_prompt_final_prompt_title') }}
        </span>
        <v-btn
          icon
          color="black"
          plain
          @click="show = false"
          class="ml-auto"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-textarea
          v-model="prompt"
          class="full-prompt-textarea f-14"
          :rules="[$required]"
          :hide-details="true"
          name="content"
          dense
          filled
          no-resize
          height="600px"
          disabled
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'FullPromptDialog',
    components: {
    },
    props: {
      value: { type: Boolean, required: true },
      prompt: { type: String, required: false }
    },
    computed: {
      ...mapGetters([
      ]),
      show: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit("input", val)
        }
      }
    }
  }
</script>

