<template>
  <v-row
    no-gutters
    class="place-campaign-satisfaction-by-topic pa-3"
  >
    <v-col cols="12">
      <v-card
        class="mt-3"
        outlined
      >
        <v-card-title class="justify-left">
          <h4 class="f-16 c-primary">{{ $t('place_campaign_satisfaction_by_topic') }}</h4>
          <span v-if="!loading" class="fb-12 ml-auto">{{ nextGenerationDate }}</span>
        </v-card-title>
        <v-card-text>
          <div class="d-flex align-center">
            <v-select
              class="period-select ml-2"
              :items="periodSelectItems"
              :label="$t('place_campaign_satisfaction_by_topic_period')"
              v-model="period"
              background-color="#eef1f5"
              hide-details="auto"
              outlined
              dense
            />
            <div
              class="ml-auto"
              v-if="!loading"
            >
              <v-icon
                class="c-secondary f-20"
                v-if="$vuetify.breakpoint.smAndDown"
                @click="download"
              >
                mdi-download
              </v-icon>
              <v-btn
                outlined
                small
                color="secondary"
                v-else
                @click="download"
              >
                <v-icon
                  class="c-secondary f-13"
                >
                  mdi-download
                </v-icon>
                {{ $t('net_impact_score_summary_download')}}
              </v-btn>
            </div>
          </div>
          <v-data-table
            ref="table"
            v-show="!loading"
            :headers="headers"
            :items="placeCampaignSatisfactionByTopic"
            :items-per-page="-1"
            hide-default-footer
            mobile-breakpoint="0"
            group-by="topicName"
            group-desc
            class="place-campaign-satisfaction-by-topics-table v-datatable-without-y-borders"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template v-slot:group.header="{ group, items, toggle, isOpen }">
              <td
                @click="toggle"
                :colspan="headers.length"
              >
                <div class="d-flex align-center cursor-pointer">
                  <div
                    class="d-flex flex-column flex-md-row"
                  >
                    <div class="d-flex align-sm-center flex-column flex-sm-row">
                      <span class="ml-sm-2 fb-14">{{ group }}</span>
                      <span
                        class="ml-sm-2 f-12"
                        :class="groupPlaceCampaignCountClass(items)"
                      >
                        {{ groupPlaceCampaignCount(items) }}
                      </span>
                      <Warning
                        v-if="!hasEnoughPlaceCampaignsWithReviewOnTopic(items)"
                        :tooltip="$t('place_campaign_satisfaction_by_topic_nb_place_campaign_tooltip')"
                      />
                    </div>
                  </div>
                  <v-btn
                    class="ml-auto"
                    small
                    icon
                    :ref="group"
                    :data-open="isOpen"
                  >
                    <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                    <v-icon v-else>mdi-chevron-down</v-icon>
                  </v-btn>
                </div>
              </td>
            </template>
            <template v-slot:item.placeName="{ item }">
              <span class="fs-13 pt-1">
                {{ item.placeName }}
              </span>
            </template>
            <template v-slot:item.comparedPeriod="{ item }">
              <div v-if="item.comparedPeriod.nbReview" class="d-flex align-center f-14">
                <v-rating
                  class="w-rating"
                  background-color="grey lighten-2"
                  readonly
                  :value="scoreForStars(item.comparedPeriod.avgScore)"
                  half-increments
                  length="5"
                  size="20"
                  :color="$colors.gold"
                ></v-rating>
                <span class="ml-1 f-13 c-primary">{{ item.comparedPeriod.avgScore.toFixed(1) }}</span>
              </div>
              <div class="d-flex align-center" v-if="item.comparedPeriod.nbReview">
                <span
                  class="ml-1 flex-nowrap flex-shrink-0 sub-text f-12"
                >
                  {{ translateNbReview(item.comparedPeriod.nbReview) }}
                </span>
                <Warning
                  v-if="item.comparedPeriod.nbReview < displayedMinimumFeedbacksCount"
                  :tooltip="$t('place_campaign_satisfaction_by_topic_nb_review_tooltip')"
                />
              </div>
            </template>
            <template v-slot:item.currentPeriod="{ item }">
              <div v-if="item.currentPeriod.nbReview" class="d-flex align-center f-14">
                <v-rating
                  class="w-rating"
                  background-color="grey lighten-2"
                  readonly
                  :value="scoreForStars(item.currentPeriod.avgScore)"
                  half-increments
                  length="5"
                  size="20"
                  :color="$colors.gold"
                ></v-rating>
                <span class="ml-1 f-13 c-primary">{{ item.currentPeriod.avgScore.toFixed(1) }}</span>
              </div>
              <div class="d-flex align-center" v-if="item.currentPeriod.nbReview">
                <span
                  class="ml-1 flex-nowrap flex-shrink-0 sub-text f-12"
                >
                  {{ translateNbReview(item.currentPeriod.nbReview) }}
                </span>
                <Warning
                  v-if="item.currentPeriod.nbReview < displayedMinimumFeedbacksCount"
                  :tooltip="$t('place_campaign_satisfaction_by_topic_nb_review_tooltip')"
                />
              </div>
            </template>
            <template v-slot:item.evolution="{ item }">
              <div class="d-flex align-center">
                <w-color-sign
                  class="ml-1"
                  :value="item.evolution"
                  :decimals="2"
                />
                <Warning
                  v-if="item.evolution < 0"
                  :tooltip="$t('place_campaign_satisfaction_by_topic_negative_evolution_tooltip')"
                />
              </div>
            </template>
            <template v-slot:item.achievement="{ item }">
              <div v-if="item.achievement" class="d-flex align-center">
                🥳 {{ achievementStatus(item.achievement)}}
              </div>
            </template>
          </v-data-table>
          <v-skeleton-loader class="mt-4" v-show="loading" type="paragraph" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
  import { mapGetters } from 'vuex'
  import _groupBy from 'lodash/groupBy'
  import _upperFirst from 'lodash/upperFirst'
  import _snakeCase from 'lodash/snakeCase'
  import dayjs from 'dayjs'
  import { sqlDate } from '@vue/plugins/helpers/dates'

  import { exportToExcel, formatExcelValues } from '@shared/helpers/export-to-excel.js'

  import Warning from './Warning'

  const toBeGenerated = 'toBeGenerated'

  export default {
    name: "placeCampaignSatisfactionByTopic",
    components: {
      Warning
    },
    data() {
      return {
        loading: false,
        shouldCloseGroups: true,
        period: 1,
        sortBy: 'evolution',
        sortDesc: true,
        minimumFeedbacksCountFromAchievements: null,
        topProgressionThresholdRateFromAchievement: null
      }
    },
    props: {
      minimumFeedbacksCount: {
        type: Number,
        required: true
      },
      topProgressionThresholdRate: {
        type: Number,
        required: true
      },
      retroactivityDelay: {
        type: Number,
        required: true
      }
    },
    computed: {
      ...mapGetters([
        'currentLexicon',
        'currentUser',
        'currentUserId',
        'currentCampaignPreferencesCampaignId',
        'currentCampaignPreferencesCampaignAvgScoreScale',
        'currentCampaignPreferencesCampaignPlaceCount'
      ]),
      displayedMinimumFeedbacksCount() {
        return this.minimumFeedbacksCountFromAchievements || this.minimumFeedbacksCount
      },
      displayedTopProgressionThresholdRate() {
        return this.topProgressionThresholdRateFromAchievement || this.topProgressionThresholdRate
      },
      periodSelectItems() {
        const items = [{
          text: this.$t('achievements_period_this_month'),
          value: 0
        }]

        const months = [...Array(4).keys()]

        months.forEach((month) => {
          items.push(
            {
              text: _upperFirst(this.formatDate(dayjs().subtract(month + 1, "month"), 'MMMM')),
              value: month + 1
            }
          )
        })

        return items
      },
      nextGenerationDate() {
        const nextGenerationDate = this.formatDate(dayjs().add(
          1, "month"
        ).startOf('month'))

        return this.$t('achievements_next_generation_date', { date: nextGenerationDate })
      },
      currentPeriod() {
        return {
            dateBegin: dayjs().subtract(this.period, "month").startOf('month'),
            dateEnd: dayjs().subtract(this.period, "month").endOf('month')
          }
      },
      comparedPeriod() {
        return {
          dateBegin: this.currentPeriod.dateBegin.subtract(1, 'month').startOf('month'),
          dateEnd: this.currentPeriod.dateEnd.subtract(1, 'month').endOf('month')
        }
      },
      headers() {
        let headers = [
          {
            text: this.currentLexicon.translate('place', 'place'),
            value: 'placeName',
            cellClass: 'name-cell text-truncate',
            sortable: false
          },
          {
            text: this.$t('place_campaign_satisfaction_by_topic_compared_period',
              { month: _upperFirst(this.formatDate(this.comparedPeriod.dateBegin, 'MMMM')) }
            ),
            value: 'comparedPeriod',
            sortable: false
          },
          {
            text: this.$t('place_campaign_satisfaction_by_topic_current_period',
              { month: _upperFirst(this.formatDate(this.currentPeriod.dateBegin, 'MMMM')) }
            ),
            value: 'currentPeriod',
            sortable: false
          },
          {
            text: this.$t('place_campaign_satisfaction_by_topic_evolution'),
            value: 'evolution'
          },
          {
            text: this.$t('place_campaign_satisfaction_by_topic_achievement'),
            value: 'achievement',
            sortable: false
          }
        ]

        return headers.filter((header) => header.displayCondition === undefined || header.displayCondition)
      },
      isCurrentPeriodInRetroactivityDelay() {
        return this.period <= this.retroactivityDelay
      }
    },
    asyncComputed: {
      placeCampaignSatisfactionByTopic: {
        async get() {
          this.loading = true

          const currentPeriodData = (await this.getPeriodData(this.currentPeriod))
          const comparedPeriodData = (await this.getPeriodData(this.comparedPeriod))
          const placeCampaignAchievements = (await this.placeCampaignAchievements())

          this.minimumFeedbacksCountFromAchievements = null
          this.topProgressionThresholdRateFromAchievement = null

          if (placeCampaignAchievements.length > 0) {
            this.minimumFeedbacksCountFromAchievements = placeCampaignAchievements[0].minimumFeedbacksCount
            this.topProgressionThresholdRateFromAchievement = placeCampaignAchievements[0].topProgressionThresholdRate
          }

          const data = this.formatData(comparedPeriodData, currentPeriodData, placeCampaignAchievements)

          this.loading = false

          return data
        },
        default: [],
        watch: ['topProgressionThresholdRate', 'minimumFeedbacksCount', 'isCurrentPeriodInRetroactivityDelay']
      }
    },
    methods: {
      placeCampaignsWithMinimumFeedbackCount(placeCampaigns) {
        return placeCampaigns.filter((placeCampaign) => {
          return placeCampaign.comparedPeriod.nbReview >= this.displayedMinimumFeedbacksCount &&
            placeCampaign.currentPeriod.nbReview >= this.displayedMinimumFeedbacksCount
        })
      },
      achievementToGenerateCount(placeCampaignCount) {
        const ratio = placeCampaignCount * this.displayedTopProgressionThresholdRate
        
        if (ratio >= 1) {
          return Math.round(ratio)
        } 
        return 0
      },
      groupPlaceCampaignCountClass(placeCampaigns) {
        return this.hasEnoughPlaceCampaignsWithReviewOnTopic(placeCampaigns) ?
          'c-grey' : 'c-error'
      },
      groupPlaceCampaignCount(placeCampaigns) {
        return this.$t(
          'place_campaign_satisfaction_by_topic_group_place_campaign_count_with_minimum',
          {
            placeCampaignCount: this.placeCampaignsWithMinimumFeedbackCount(placeCampaigns).length,
            totalPlaceCampaignCount: this.currentCampaignPreferencesCampaignPlaceCount,
            minimumPlaceCampaignCount: this.achievementToGenerateCount(this.placeCampaignsWithMinimumFeedbackCount(placeCampaigns).length)
          }
        )
      },
      hasEnoughPlaceCampaignsWithReviewOnTopic(placeCampaigns) {
        return this.placeCampaignsWithMinimumFeedbackCount(placeCampaigns).length >= this.achievementToGenerateCount(this.placeCampaignsWithMinimumFeedbackCount(placeCampaigns).length)
      },
      formatData(comparedPeriodData, currentPeriodData, placeCampaignAchievements) {
        const data = {}
        const topicIds = Object.keys(currentPeriodData)

        topicIds.forEach((topicId) => {
          const comparedPeriodDataItems = Object.values(comparedPeriodData[topicId] || {})
          let topicData = Object.values(currentPeriodData[topicId]).map((currentPeriodDataItem) => {
            
            const comparedPeriodDataItem = comparedPeriodDataItems.find((item) => {
              return currentPeriodDataItem.placeCampaignsId === item.placeCampaignsId &&
                currentPeriodDataItem.topicId === item.topicId
            })

            if (comparedPeriodDataItem) {
              const achievement = placeCampaignAchievements.find((item) => {
                return currentPeriodDataItem.placeCampaignsId === item.placeCampaignId &&
                  currentPeriodDataItem.topicId === item.topicId
              })
              const data = {
                topicName: currentPeriodDataItem.topicName,
                placeName: currentPeriodDataItem.placeName,
                placeCampaignId: currentPeriodDataItem.placeCampaignsId,
                comparedPeriod: {
                  nbReview: comparedPeriodDataItem.nbReview,
                  avgScore: comparedPeriodDataItem.avgSatisfaction,
                },
                currentPeriod: {
                  nbReview: currentPeriodDataItem.nbReview,
                  avgScore: currentPeriodDataItem.avgSatisfaction
                },
                evolution: currentPeriodDataItem.avgSatisfaction - comparedPeriodDataItem.avgSatisfaction,
              }

              if (achievement) {
                data.achievement = achievement
              }

              return data
            }
          })

          topicData = topicData.filter((element) => element !== undefined)

          topicData = topicData.sort((a, b) =>
            b.evolution - a.evolution
          )
          if (this.isCurrentPeriodInRetroactivityDelay) {
            const placeCampaignsWithMinimumFeedbackCount = this.placeCampaignsWithMinimumFeedbackCount(topicData)

            let achievementToGenerateTotal = this.achievementToGenerateCount(placeCampaignsWithMinimumFeedbackCount.length)

            if (placeCampaignsWithMinimumFeedbackCount.length >= achievementToGenerateTotal) {

              let placeCampaignsWithAchievementToGenerate = placeCampaignsWithMinimumFeedbackCount.filter((dataItem) => {
                return dataItem.evolution >= 0
              }).slice(0, achievementToGenerateTotal)

              topicData = topicData.map((dataItem) => {
                // cas 1 : pas d'achievement avant, non apres -> rien
                // cas 2 : pas d'achievement avant, oui apres -> tobegenerated = vrai
                // cas 3 : un achievement avant :
                  // 3.1 claimed : non apres => rien (il va s'afficher claimed)
                  // 3.2 claimed : oui apres => rien (il va s'afficher en claimed)
                  // 3.3 unclaimed : non apres => mettre achievement a vide
                  // 3.4 unclaimed : oui apres => mis en tobegenerated
                const shouldGenerateAchievement = placeCampaignsWithAchievementToGenerate.find((placeCampaignData) => {
                  return dataItem.placeCampaignId === placeCampaignData.placeCampaignId
                })
              
                if (shouldGenerateAchievement) {
                  if (!dataItem.achievement?.claimedAt) {
                    dataItem.achievement = toBeGenerated
                  }
                } else {
                  if (dataItem.achievement && !(dataItem.achievement.claimedAt)) {
                    dataItem.achievement = null
                  }
                }

                return dataItem
              })
            }
          }
          data[topicId] = topicData
        })

        return Object.values(data).flat()
      },
      achievementStatus(achievement) {
        if (achievement) {
          if (achievement === toBeGenerated) {
            return this.$t('place_campaign_satisfaction_by_topic_group_achievement_to_be_generated')
          }

          return achievement.claimedAt ?
            this.$t('place_campaign_satisfaction_by_topic_group_achievement_claimed') :
            this.$t('place_campaign_satisfaction_by_topic_group_achievement_generated')
        }
      },
      scoreForStars(score) {
        if (score === null) {
          return 0
        }

        const displayedsScore = this.currentCampaignPreferencesCampaignAvgScoreScale?.max === 5 ? score : score / 2

        return Math.round(displayedsScore * 2) / 2
      },
      translateNbReview(nbReview) {
        return this.$t('nbReview_without_google_logo', {
          nbReview: nbReview?.toLocaleString(),
        })
      },
      async placeCampaignAchievements() {
        const request =
          this.$basedRequest().select({
            place_campaign_achievements: [
              'id',
              'claimed_at',
              'month',
              'topic_id',
              'place_campaign_id',
              'minimum_feedbacks_count',
              'top_progression_threshold_rate'
            ]
          }).where({
            campaign_id: this.currentCampaignPreferencesCampaignId,
            month: {
              "<=>": {
                min: sqlDate(this.formatDate(this.currentPeriod.dateBegin)),
                max: sqlDate(this.formatDate(this.currentPeriod.dateEnd), '23:59:59'),
              }
            }
          })
        const response = (await this.$resolve(request))?.data?.placeCampaignAchievements || []

        return response
      },
      async getPeriodData(period) {
        const request = this.$basedRequest().select(
          {
            voter_avg_topics: [
              { 'MAX_topics_name': { as: 'topicName'} },
              { 'MAX_places_name': { as: 'placeName'} },
              'topic_id',
              'place_campaigns_id',
              'nb_review',
              'avg_satisfaction'
            ]
          }
        ).where({
          campaign_id: this.currentCampaignPreferencesCampaignId
        }).group([
          'topic_id', 'place_campaigns_id'
        ]).dateBetween(
          sqlDate(this.formatDate(period.dateBegin)),
          sqlDate(this.formatDate(period.dateEnd), '23:59:59')
        )

        const periodData = (await this.$resolve(request))?.data || {}

        return periodData
      },
      closeGroups() {
        if (this.shouldCloseGroups) {
          let table = this.$refs.table

          if (table) {
            let keys = Object.keys(table.$vnode.componentInstance.openCache)

            keys.forEach(x => {
              table.$vnode.componentInstance.openCache[x] = false;
            })
            this.shouldCloseGroups = false
          }
        }
      },
      formatDate(date, format='YYYY-MM-DD') {
        return dayjs(date).locale(this.$i18n.locale).format(format)
      },
      download() {
        this.$store.dispatch("notifyInfo")

        const title = this.$t('place_campaign_satisfaction_by_topic_group_achievement_export_title')

        const fileName = [title, this.currentPeriod.dateBegin, this.currentPeriod.dateEnd].join(' - ')

        const data = this.placeCampaignSatisfactionByTopic.map((placeCampaign) => {
          const { placeCampaignId, currentPeriod, comparedPeriod, ...filteredMapping } = placeCampaign

          filteredMapping.comparedPeriodScore = placeCampaign.comparedPeriod.avgScore
          filteredMapping.comparedPeriodNbReview = placeCampaign.comparedPeriod.nbReview
          filteredMapping.currentPeriodScore = placeCampaign.currentPeriod.avgScore
          filteredMapping.currentPeriodNbReview = placeCampaign.currentPeriod.nbReview
          filteredMapping.achievement = this.achievementStatus(placeCampaign.achievement)

          return filteredMapping
        })

        const formattedDatas = formatExcelValues(
          data,
          {
            comparedPeriodScore: { round: 1 },
            currentPeriodScore: { round: 1 },
            evolution: { round: 2 }
          }
        )

        const headers = Object.keys(data[0]).map((key, index) => {
          if (['comparedPeriodScore', 'comparedPeriodNbReview'].includes(key)) {
            return this.$t(
              `net_impact_score_summaries_export_header_${_snakeCase(key)}`,
              { period: _upperFirst(this.formatDate(this.comparedPeriod.dateBegin, 'MMMM-YYYY')) }
            )
          } else if (['currentPeriodScore', 'currentPeriodNbReview'].includes(key)) {
            return this.$t(
              `net_impact_score_summaries_export_header_${_snakeCase(key)}`,
              { period: _upperFirst(this.formatDate(this.currentPeriod.dateBegin, 'MMMM-YYYY')) }
            )
          } else {
            return this.$t(`net_impact_score_summaries_export_header_${_snakeCase(key)}`)
          }
        })

        exportToExcel(fileName, '', formattedDatas, headers)
      },
    },
    watch: {
      placeCampaignSatisfactionByTopic() {
        this.$nextTick(function () {
          if (this.placeCampaignSatisfactionByTopic.length > 0) {
            this.closeGroups()
          }
        })
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .place-campaign-satisfaction-by-topic
    .period-select
      max-width: 140px
    .cursor-pointer
      cursor: pointer
</style>
