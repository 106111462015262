<template>
  <div class="dashboard-secondary-menu" v-if="items && items.length > 0">
    <v-divider />

    <template
      v-for="item in items"
    >
      <v-list-group
        v-if="item.childs"
        :key="item.title"
        :value="item.opened"
        color="grey"
        :prepend-icon="item.icon"
        :ripple="false"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <WSideBarListItem
          v-for="child in item.childs"
          :key="child.title"
          :item="child"
        />
      </v-list-group>

      <WSideBarListItem
        v-else
        :key="item.title"
        :item="item"
      />

      <v-divider />
    </template>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"

  export default {
    name: "DashboardSecondaryMenu",
    components: {
    },
    data() {
      return {
        initialRouteName: this.$route.name,
        color: 'bluegrey'
      }
    },
    computed: {
      ...mapGetters([
        'isDashboardMono',
        'currentUserIsAdmin',
        'hasAccessToOldPlatform',
        'hasRightRedirectToNewPlatform',
        'hasAccessToCollaboratorManagementTab'
      ]),
      items() {
        let items = [
          {
            icon: "mdi-account-outline",
            title: "dashboard_sidebar_collaborators",
            show: this.hasAccessToCollaboratorManagementTab,
            color: this.color,
            to: { name: "Collaborators" }
          },
          {
            icon: "mdi-export",
            title: "dashboard_sidebar_campaign_export",
            show: this.isDashboardMono,
            color: this.color,
            to: { name: "CampaignExport" }
          },
          {
            icon: "mdi-download",
            title: "my_reportings",
            show: !this.currentUserIsAdmin,
            color: this.color,
            to: { name: "Reportings" }
          },
          {
            icon: "mdi-open-in-new",
            title: "dashboard_sidebar_competitors_old_platform",
            show: (this.hasAccessToOldPlatform && this.hasRightRedirectToNewPlatform),
            color: this.color,
            to: { name: "OldPlatform" }
          }
        ]

        return items.filter(item => item.show)
      }
    }
  }
</script>

<style lang="stylus">
  @import '~@theme/colors.styl'

  .dashboard-secondary-menu
    .v-list-item__icon:first-child
      margin-right: 4px !important

    .v-list-item__icon
      .v-icon
        margin-top: 0px !important
        margin-left: 3px
        font-size: 15px

    .active-item
      .v-list-item__icon i, .v-list-item__title
        color: var(--info) !important
</style>

