<template>
  <WStatsWidget
    v-if="campaign === undefined || campaignIndicator === undefined"
    :cols="cols"
    :loading="true"
    :empty="false"
  />
  <ScoreWidget
    v-else
    :title="title"
    :subtitle="displayedSubtitle"
    :indicator="campaignIndicator?.score"
    :displayEvolution="!!campaignIndicator?.evolution"
    :evolution="campaignIndicator?.evolution"
    :nbReview="campaignIndicator?.nbReview"
    :basedRequest="campaignIndicator === null ? basedRequest : undefined"
    :indicatorAlias="campaignIndicator === null ? indicatorAlias : undefined"
    :indicatorColumn="campaignIndicator === null ? indicatorColumn : undefined"
    :cols="cols"
    :displayRank="false"
    :linkTo="linkTo"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import ScoreWidget from '@statistics/shared/widgets/ScoreWidget'
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'

export default {
  name: 'CampaignIndicatorScoreWidget',
  components: {
    ScoreWidget,
  },
  mixins: [
    WidgetMixin,
  ],
  props: {
    campaign: { type: Object },
    campaignIndicator: { type: Object },
    partialCampaignIndicator: { type: Object },
    cols: { type: Number, default: 3 },
  },
  computed: {
    ...mapGetters([
      'dashboardFilterRequest',
      'currentUserCampaignScoreIndicator'
    ]),
    linkTo() {
      const indicator = this.partialCampaignIndicator ?
                        this.partialCampaignIndicator :
                        this.campaignIndicator
      return {
        name: 'Statistics',
        params: {
          sectionType: 'satisfaction',
          sectionId: indicator.id,
          subSectionId: this.campaign.id
        }
      }
    },
    indicatorAlias() {
      return 'score'
    },
    indicatorColumn() {
      if (this.partialCampaignIndicator.indicatorType == 'campaignScoreIndicator') {
        return {
          [this.partialCampaignIndicator.formulaToColumn]: {
            as: this.indicatorAlias
          }
        }
      } else {
        return {
          avg_score_campaign_indicator_: {
            as: this.indicatorAlias,
            campaign_indicator_id: this.partialCampaignIndicator.id
          }
        }
      }
    },
    basedRequest() {
      const indicator = this.partialCampaignIndicator
      let request

      if (indicator.indicatorType == 'campaignScoreIndicator') {
        request = this.dashboardFilterRequest.select({
          brand_voters: [
            this.indicatorColumn,
            { nb_review_with_avg_score: { as: 'nbReview' } }
          ]
        })
        if (indicator.formulaToIdKey) {
          request = request.where({
            [indicator.formulaToIdKey]: indicator.formulaToId
          })
        }
      } else {
        request = this.dashboardFilterRequest.select({
          brand_voters: [
            this.indicatorColumn,
            { nb_review_avg_campaign_indicator_: { as: 'nbReview', campaign_indicator_id: indicator.id } },
          ]
        })
      }

      request = request.where({
        campaign_id: this.campaign.id
      })
      return request
    },
    title() {
      const indicator = this.partialCampaignIndicator ?
                        this.partialCampaignIndicator :
                        this.campaignIndicator

      return indicator.indicatorType == 'campaignScoreIndicator' ?
             indicator.formulaToName :
             indicator.name
    }
  }
}
</script>

<style lang="stylus" scoped>
  .widget-link
    display: contents
</style>
