<template>
   <WAutocomplete
      v-model="dataSource"
      :items="sources"
      :key="source"
      @change="onChange"
    />
</template>

<script>
import { mapGetters } from 'vuex'
import { capitalize } from 'inflection'

export default {
  name: "SourceAutocomplete",
  data() {
    return {
      dataSource: this.source
    }
  },
  props: {
    source: { required: false }
  },
  computed: {
    ...mapGetters([
      'currentDashboardPublicPlatforms',
    ]),
    sources() {
      return this.$sortByAlpha(this.currentDashboardPublicPlatforms.map((source) => {
        return {
          text: this.$t(`source_${source}`),
          value: source
        }
      }), 'text')
    },
  },
  methods: {
    onChange() {
      this.$router.push({
        params: { sectionId: this.dataSource }
      })
    },
  },
  watch: {
    source: function() {
      this.dataSource = this.source
    }
  }
}
</script>
