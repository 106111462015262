<template>
  <div class="products">
    <ProductFeedsNavigations
      :page="page"
      :campaignId="campaignId"
    />
    <div>
      <v-row no-gutters class="pa-3">
        <v-col>
          <ProductImportPreferenceSettingsPage
            v-if="page === 'settings'"
            :campaignId="campaignId"
          />
          <ProductImportPreferenceHistoryPage
            v-if="page === 'history'"
            :campaignId="campaignId"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ProductFeedsNavigations from './ProductFeedsNavigations'
  import ProductImportPreferenceSettingsPage from './ProductImportPreferenceSettingsPage'
  import ProductImportPreferenceHistoryPage from './ProductImportPreferenceHistoryPage'

  export default {
    name: "ProductImportPreference",
    components: {
      ProductFeedsNavigations,
      ProductImportPreferenceSettingsPage,
      ProductImportPreferenceHistoryPage,
    },
    props: {
      page: {
        type: String,
        required: true
      },
      campaignId: {
        type: Number,
        required: true
      }
    },
    computed: {
      ...mapGetters([
      ])
    }
  }
</script>
