import DashboardsLayout from '@platform/layouts/DashboardsLayout'
import store from '@platform/store'
import { legacyUrl } from '@shared/helpers/env-helpers'
import Collaborators from '@platform/pages/dashboard/Collaborators'

export default {
  path: '/dashboards',
  component: DashboardsLayout,

  children: [
    {
      path: '/',
      name: 'Dashboards',
      component: () => import('@platform/pages/dashboards/Index'),
      beforeEnter: (to, from, next) => {
        const firstActiveDashboard = store.getters.activeDashboardBasics[0]
        const firstActiveDashboardMulti = store.getters.activeDashboardMultis[0]

        if (store.getters.priorityForDashboardMulti && firstActiveDashboardMulti) {
          next({ path: `/dashboard/multi/${firstActiveDashboardMulti.id}` })
        } else if (firstActiveDashboard && store.getters.activeDashboardBasics.length == 1 && !store.getters.currentUser.isAdmin) {
          next({ path: `/dashboard/mono/${firstActiveDashboard.id}` })
        } else {
          next()
        }
      }
    },
    {
      path: 'collaborators',
      name: 'Collaborators',
      component: Collaborators
    },
    {
      path: '/dashboards/index',
      name: 'DashboardsIndex',
      component: () => import('@platform/pages/dashboards/Index')
    },
    {
      path: 'legacyCredentials',
      name: 'OldPlatformCredentials',
      beforeEnter(to, from, next) {
        window.open(`${legacyUrl}/settings/credentials?nup=1&alk=${store.getters.currentUser.alk}`)
      }
    },
    {
      path: 'legacyPlaces',
      name: 'OldPlatformPlaces',
      beforeEnter(to, from, next) {
        window.open(`${legacyUrl}/places?nup=1&alk=${store.getters.currentUser.alk}`)
      }
    },
    {
      path: 'legacyUserRoles',
      name: 'OldPlatformUserRoles',
      beforeEnter(to, from, next) {
        window.open(`${legacyUrl}/users/roles2?nup=1&alk=${store.getters.currentUser.alk}`)
      }
    },
    {
      path: 'legacyBrands',
      name: 'OldPlatformBrands',
      beforeEnter(to, from, next) {
        window.open(`${legacyUrl}/brands?nup=1&alk=${store.getters.currentUser.alk}`)
      }
    },
    {
      path: 'legacyResponseTypes/:campaignId',
      name: 'OldPlatformResponseTypes',
      beforeEnter(to, from, next) {
        window.open(`${legacyUrl}/campaigns/${to.params.campaignId}/response_type?nup=1&alk=${store.getters.currentUser.alk}`)
      }
    }
  ]
}
