export function initBeforeEachGuards(router) {
  router.beforeEach(async (to, from, next) => {
    let isDelegated = false;

    for (let matched = (to.matched || []), i = matched.length; i--;) {
        let route = matched[i];

        if (route.meta.beforeEach) {
            isDelegated = true;
            await route.meta.beforeEach(to, from, next);
        }
    }

    !isDelegated && next();
  });
}
