<template>
  <WAutocomplete
    v-model="dataCampaignId"
    :items="campaignsList"
    @change="onChange"
    :label="label"
    backgroundColor="white"
    rounded
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "CampaignAutocomplete",
  data() {
    return {
      dataCampaignId: this.campaignId,
    }
  },
  props: {
    label: { required: false },
    campaignId: { required: false },
    eligibleCampaigns: { type: Array, required: true }
  },
  computed: {
    ...mapGetters([
      'currentDashboardScopedCampaigns',
      'currentDashboardCampaignWithId'
    ]),
    campaignsList() {
      return this.$sortByAlpha(this.eligibleCampaigns.map((campaign) => {
        return {
          text: campaign.name,
          value: campaign.id
        }
      }), 'text')
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.dataCampaignId)
    },
  },
  watch: {
    campaignId: function(campaignId) {
      this.dataCampaignId = campaignId
    }
  }
}
</script>
