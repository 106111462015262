<template>
  <v-card class="free-answer-card">
    <v-card-text class="py-3">
      <div class="f-14 c-dark">{{ answer.freeAnswer }}</div>
    </v-card-text>

    <div v-if="topicsVerbatimsDatas && !allTopicsVerbatimDisabled">
      <v-divider class="mx-4"></v-divider>
      <v-card-text class="py-2 f-14">
        <div class="pb-1 fs-11 c-lightgrey">
          {{ $t('free_question_answer_mention_subjects_subtitle').toUpperCase() }}
        </div>

        <div class="text-align-left mr-2 flex-grow-1">
          <span v-for="topicsVerbatim of topicsVerbatimsDatas">
            <span
              v-if="!topicsVerbatim.disabled"
              class="d-inline-block my-1 mr-2"
              :id="getMenuActivatorId(topicsVerbatim)"
              @click="hasRightClassifyVerbatims && setMenuDatas(topicsVerbatim)"
            >
              <v-chip
                label
                small
                outlined
                :style="{ cursor: hasRightClassifyVerbatims ? 'pointer' : 'default' }"
                :key="topicsVerbatim.topicId"
              >
                <WFeelingIcon :small="true" class="pr-2" :feeling="topicsVerbatim.feeling"/>
                {{ _upperFirst(topicsVerbatim.topicName) }}
              </v-chip>
            </span>
          </span>

          <TopicsVerbatimClassificationMenu
            :menuActivatorId="menuActivatorId"
            :verbatimId="menuVerbatimId"
            :topics="topics"
            @createTopicsVerbatims="(topicsVerbatim) => createTopicsVerbatims(topicsVerbatim)"
            @updateOrDeleteTopicsVerbatims="(topicsVerbatim) => updateOrDeleteTopicsVerbatims(topicsVerbatim)"
            @onCloseMenu="onCloseMenu()"
          />
        </div>
        <VerbatimTextAnalysisInfo
          v-if="hasRightClassifyVerbatims && answer.verbatim"
          :processedAt="answer.verbatim.textAnalysisProcessedAt"
          :isReclassified="answer.verbatim.isTextAnalysisReclassified"
        />
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import TopicsVerbatimClassificationMenu from '../../../../../../shared/TopicsVerbatimClassificationMenu'
  import VerbatimTextAnalysisInfo from '../../../../../../shared/VerbatimTextAnalysisInfo'
  import _cloneDeep from "lodash/cloneDeep"
  import _upperFirst from 'lodash/upperFirst'

  export default {
    name: "FreeAnswerCard",
    components: {
      TopicsVerbatimClassificationMenu,
      VerbatimTextAnalysisInfo
    },
    data () {
      return {
        topicsVerbatimsDatas: undefined,
        menuActivatorId: undefined,
        menuVerbatimId: undefined
      }
    },
    props: {
      answer: { required: true },
      topicsVerbatims: {
        required: false,
        type: Array,
        default: () => []
      },
      voter: {
        required: false,
        type: Object,
        default: () => {}
      }
    },
    computed: {
      ...mapGetters([
        'hasRightClassifyVerbatims'
      ]),
      allTopicsVerbatimDisabled() {
        return !!this.topicsVerbatimsDatas?.every((topicsVerbatim) => topicsVerbatim.disabled)
      },
      indexTopicsVerbatimDatasByTopicId() {
        return this.topicsVerbatimsDatas?.reduce((h, topicsVerbatimDatas, index) => {
          h[topicsVerbatimDatas.topicId] = index
          return h
        }, {}
      )}
    },
    methods: {
      _upperFirst,
      setMenuDatas(topicsVerbatim) {
        this.menuActivatorId = `#id_${topicsVerbatim.verbatimId}_${topicsVerbatim.topicId}`
        this.menuVerbatimId = topicsVerbatim.verbatimId
      },
      onCloseMenu() {
        this.menuActivatorId = undefined
        this.menuVerbatimId = undefined
      },
      getMenuActivatorId(topicsVerbatim) {
        return `id_${topicsVerbatim.verbatimId}_${topicsVerbatim.topicId}`
      },
      createTopicsVerbatims(topicsVerbatim) {
        const [updatedTopicId, newTopicsVerbatim] = Object.entries(topicsVerbatim)[0]
        const indexTopicVerbatim = this.indexTopicsVerbatimDatasByTopicId[updatedTopicId]

        if (this.topicsVerbatimsDatas[indexTopicVerbatim]) {
          this.$set(this.topicsVerbatimsDatas, indexTopicVerbatim, newTopicsVerbatim)
        } else {
          this.topicsVerbatimsDatas = [...this.topicsVerbatimsDatas, newTopicsVerbatim]
        }
      },
      updateOrDeleteTopicsVerbatims(topicsVerbatim) {
        const [updatedTopicId, newTopicsVerbatim] = Object.entries(topicsVerbatim)[0]
        const indexTopicVerbatim = this.indexTopicsVerbatimDatasByTopicId[updatedTopicId]
        let copyTopicsVerbatimsDatas = _cloneDeep(this.topicsVerbatimsDatas)

        copyTopicsVerbatimsDatas[indexTopicVerbatim] = newTopicsVerbatim
        this.topicsVerbatimsDatas = copyTopicsVerbatimsDatas
      },
      formatTopicsVerbatims(topicsVerbatims) {
        return topicsVerbatims.map((topicsVerbatim) => {
          return {
            topicId: topicsVerbatim.textAnalysisTopicId,
            topicName: topicsVerbatim.textAnalysisTopicPublicName,
            feeling: topicsVerbatim.feeling,
            verbatimId: topicsVerbatim.verbatimId,
            disabled: false
          }
        })
      },
    },
    asyncComputed: {
      topics: {
        async get() {
          let request = this.$basedRequest().select({
          text_analysis_preferences: [
            { 'text_analysis_topics_id': { as: 'topicId' } },
            { 'topic_public_name': { as: 'topicName' } }
          ]
          }).where({
            campaign_id: this.voter.campaignId
          }).order('topic_public_name, asc')

          return (await this.$resolve(request)).data?.textAnalysisPreferences || []
        }
      }
    },
    mounted() {
      this.topicsVerbatimsDatas = this.formatTopicsVerbatims(this.topicsVerbatims)
    },
  }
</script>

<style lang='stylus' scoped>
  .free-answer-card.v-sheet.v-card:not(.v-sheet--outlined)
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08)

  .free-answer-card
    border-radius: 8px

    .v-card__subtitle, .v-card__text
      letter-spacing: normal
      line-height: 18px
</style>
