<template>
  <div class="voter-float-info-filter-content">
    <v-form
      class="d-flex align-center"
    >
      <v-text-field
        class="mr-2 text-field"
        v-model="min"
        type="number"
        label="Min"
        hide-details="auto"
        @input="onFilterOptionUpdated"
        @keypress="filterEvent"
        outlined
        dense
      ></v-text-field>
      <v-text-field
        class="text-field"
        v-model="max"
        label="Max"
        type="number"
        hide-details="auto"
        @input="onFilterOptionUpdated"
        @keypress="filterEvent"
        outlined
        dense
      ></v-text-field>
    </v-form>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _debounce from "lodash/debounce"

  export default {
    name: "VoterFloatInfoFilterContent",
    props: ['filterKey'],
    data() {
      return {
        min: '',
        max: ''
      }
    },
    methods: {
      sanitize(value) {
        const sanitizedValue = parseFloat(value.replace(',', '.'))

        return isNaN(sanitizedValue) ? null : sanitizedValue
      },
      filterEvent(e) {
        const event = (e) ? e : window.event
        const expect = event.target.value.toString() + event.key.toString();

        if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
          event.preventDefault()
        } else {
          return true
        }
      },
      onFilterOptionUpdated: _debounce(function (val) {
        this.$store.dispatch("setDashboardVoterFloatInfoFilter", {
          filterKey: this.filterKey,
          filterValues: {
            min: this.sanitize(this.min),
            max: this.sanitize(this.max)
          }
        })
      }, 1000)
    }
  }
</script>

<style lang="stylus">
  .voter-float-info-filter-content
    .text-field
      width: 100px
</style>
