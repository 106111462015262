<template>
  <WStatsWidget
    v-if="campaign === undefined || campaignIndicator === undefined"
    :title="$t('widget_place_ranking_title')"
    :cols="12"
    :loading="true"
    :empty="false"
  />
  <RankingTableWidget
    v-else
    :title="title"
    :staticColumns="staticColumns"
    :customRankingRelatedIndicator="customRankingRelatedIndicator"
    :campaign="campaign"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import RankingTableWidget  from '@statistics/shared/widgets/RankingTableWidget'

  export default {
    name: "CampaignIndicatorRankingTableWidget",
    props: {
      campaign: { type: Object },
      campaignIndicator: { type: Object },
    },
    components: {
      RankingTableWidget,
    },
    computed: {
      ...mapGetters([
        'currentLexicon'
      ]),
      customRankingRelatedIndicator() {
        return this.campaignIndicator.indicatorType == 'campaignScoreIndicator' ?
               this.campaignIndicator.formulaToColumn :
               `i_${this.campaignIndicator.id}`
      },
      campaignIndicatorColumnText() {
        return this.campaignIndicator.indicatorType == 'campaignScoreIndicator' ?
               this.campaignIndicator.formulaToName :
               this.campaignIndicator.name
      },
      staticColumns() {
        return {
          'g_place_name': {},
          [this.customRankingRelatedIndicator]: { text: this.campaignIndicatorColumnText },
          [`${this.customRankingRelatedIndicator}_answer_repartition`]: {
            hideNbReview: true,
            hideScore: true,
            avgScoreScale: this.campaign?.avgScoreScale
          }
        }
      },
      title() {
        return ` ${this.$t('competitors_widget_ranking_title')} ${this.currentLexicon.translate('places', 'places') } - ${this.campaignIndicatorColumnText}`
      }
    }
  }
</script>
