<template>
  <div class="question-condition-answer-rate f-12">
    <span v-if="!loading" v-html="questionConditionAnswerRate()" />
    <v-skeleton-loader v-else type="text" style="width: 33%; padding-top: 8px;" />
  </div>
</template>

<script>
  import { mapGetters} from 'vuex'

  export default {
    name: "QuestionConditionAnswerRate",
    props: [ 'questionCondition', 'color' ],
    data() {
      return {
        loading: true
      }
    },
    methods: {
      async exportToPdf(pdf) {
        await pdf.addRow({ height: 20 }, async(row) => {
          await row.addCol({ }, async(col) => {
            await col.addText(
              this.questionConditionAnswerRatePdf(),
              { color: '#666', fontSize: 12 }
            )
          })
          await row.addCol({ marginLeft: 4 }, async(col) => {
            await col.addText(
              this.conditionLabel,
              { color: this.color, fontSize: 12 }
            )
          })
        })
      },
      questionConditionAnswerRate() {
        return this.$t(
          'question_condition_answer_rate',
          {
            rate: this.rate,
            total: this.totalWithCondition,
            customersLexicon: this.currentLexicon.translate('customers', 'customers').toLowerCase(),
            color: this.color,
            note: this.conditionLabel
          }
        )
      },
      questionConditionAnswerRatePdf() {
        return this.$t(
          'question_condition_answer_rate_pdf',
          {
            rate: this.rate,
            total: this.totalWithCondition,
            customersLexicon: this.currentLexicon.translate('customers', 'customers').toLowerCase()
          }
        )
      }
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest',
        'currentLexicon'
      ]),
       conditionParams() {
        return this.questionCondition?.condition?.params || {}
      },
      total() {
        return this.totalVoters?.total || 0
      },
      totalWithCondition() {
        return this.totalVoters?.withCondition || 0
      },
      rate() {
        return (this.total > 0 ? Math.round(this.totalWithCondition * 100 / this.total) : 0)
      },
      conditionLabel() {
        if (this.conditionParams.condition == ">") {
          return this.$t('note_greater_than', { note: this.conditionParams.value })
        } else if (this.conditionParams.condition == ">=") {
          return this.$t('note_greater_than_or_equal', { note: this.conditionParams.value })
        } else if (this.conditionParams.condition == "<") {
          return this.$t('note_less_than', { note: this.conditionParams.value })
        } else if (this.conditionParams.condition == "<=") {
          return this.$t('note_less_than_or_equal', { note: this.conditionParams.value })
        }
      }
    },
    asyncComputed: {
      totalVoters: {
        async get() {
          this.loading = true
          const request = this.dashboardFilterRequest.select({
            voters: [ {
              total_mark_question_answers_question_: {
                as: 'total', question_id: this.questionCondition.markQuestion.id
              },
            },
            {
              total_mark_question_answers_question_: {
                as: 'withCondition', question_id: this.questionCondition.markQuestion.id, operator: this.conditionParams.condition, value: this.conditionParams.value
              },
            } ]
          })

          const totalVoters = ((await this.$resolve(request))?.data?.voters || [])[0] || {}

          this.loading = false
          return totalVoters
        }, default: {}
      }
    }
  }
</script>

<style lang="stylus" scoped>

</style>
