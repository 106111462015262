<template>
  <v-avatar
    size="40"
    class="bg-bodygrey"
  >
    <span class="c-primary fb-15 text-uppercase">{{ avatarContent }}</span>
  </v-avatar>
</template>

<script>
import { mapGetters } from 'vuex'

import VoterMixin from '../voter_mixin'

export default {
  name: 'VoterAvatar',
  mixins: [
    VoterMixin
  ],
  computed: {
    ...mapGetters([
    ]),
    avatarContent() {
      return this.voter.author?.charAt(0)
    }
  }
}
</script>
