/* eslint-disable */
export function exportToExcel(fileName, sheetName, data, headerTexts, headerKeys, options = {}) {
  import(/* webpackChunkName: "xlsx" */ 'xlsx').then((xlsx) => {
    const ws = xlsx.utils.json_to_sheet(data, { header: headerKeys });
    const minWidth = options.minWidth

    ws["!cols"] = headerTexts.map((headerText) => {
      const length = headerText.length + 2
      return { width: (minWidth && length < minWidth ? minWidth : length) }
    });

    const wb = xlsx.WorkBook = xlsx.utils.book_new();

    headerTexts.map((text, index) => {
      const cellAddress = `${xlsx.utils.encode_col(index)}1`;
      if (ws[cellAddress]) {
        ws[cellAddress].v = text;
      }
    })

    xlsx.utils.book_append_sheet(wb, ws, sheetName);
    xlsx.writeFile(wb, `${fileName}.xlsx`);
  });
}

export async function excelToHash(data) {
  const xlsx = await import(/* webpackChunkName: "xlsx" */ 'xlsx')
  const workbook = await xlsx.read(data, { type: 'binary' })
  const sheets = {}

  workbook.SheetNames.forEach((sheetName) => {
    const rowsObject = xlsx.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
    sheets[sheetName] = rowsObject;
  })

  return sheets
}

// EXAMPLES
// datas = [{ avg: 4.5, score: 7.543 }, { avg: 4.4, score: 7.522  }]
// formattersByKey = { score: { round: 2 }, avg: { suffix: "%" } }
// formatExcelValues => [{ avg: "4.5%", score: 7.54 }, { avg: "4.4%", score: 7.52 }]
export const formatExcelValues = (datas, formattersByKey) => {
  return datas.map(data => {
    Object.keys(data).forEach((key) => {
      if (Object.keys(formattersByKey).includes(key)) {
        if (Object.keys(formattersByKey[key]).includes('round'))
          data[key] = data[key] ? data[key].toFixed(formattersByKey[key].round) : ''
        if (Object.keys(formattersByKey[key]).includes('suffix'))
          data[key] = data[key] ? `${data[key]}${formattersByKey[key].suffix}` : ''
      }
    })
    return data
  })
}

export default exportToExcel;
