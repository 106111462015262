<template>
  <v-form ref="form" v-model="isValid" class="send-feedback d-flex flex-column">
    <v-row class="flex-grow-0">
      <v-col cols="12" sm="6" md="6" lg="6" class="py-0 my-1">
        <v-combobox
          class="recipients f-14"
          :class="{'with-elements': recipients.length > 0}"
          v-model="recipients"
          :items="selectableRecipientsGroupedByUserGroup"
          :placeholder="recipients.length ? '' : $t('sendFeedback.recipients')"
          :rules="[$rules.arrayRequired]"
          background-color="#eef1f5"
          multiple
          chips
          dense
          :delimiters="[' ']"
          hide-details
          attach
          filled
          solo
          flat
          deletable-chips
        >
          <template v-slot:selection="data">
            <v-chip
              :key="JSON.stringify(data.item)"
              v-bind="data.attrs"
              :class="{'c-error border-error': !$rules.email(data.item)}"
              close
              @click:close="recipients.splice(data.index, 1)"
            >
              <v-avatar
                class="accent white--text mr-1"
                left
                v-text="data.item.slice(0, 1).toUpperCase()"
              />
              <span>{{ data.item }}</span>
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-avatar
              size
              class="accent white--text mr-1"
              left
              v-text="data.item.slice(0, 1).toUpperCase()"
            />
            <v-list-item-content>
              <v-list-item-title v-html="data.item" />
            </v-list-item-content>
          </template>
        </v-combobox>
      </v-col>
    </v-row>

    <v-row class="flex-grow-0">
      <v-col cols="12" sm="6" md="6" lg="6" class="py-0 my-1">
        <v-text-field
          v-model="sender"
          class="f-14 sender"
          :rules="[$required]"
          :placeholder="$t('sendFeedback.sender')"
          filled
          solo
          flat
          dense
          hide-details
          name="sender"
          background-color="#eef1f5"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row class="flex-grow-1">
      <v-col class="py-0 my-1">
        <v-textarea
          v-model="content"
          class="content-textarea f-14"
          :label="$t('sendFeedback.content')"
          background-color="#eef1f5"
          name="content"
          height="100%"
          rows="0"
          dense
          filled
          no-resize
        />
      </v-col>
    </v-row>

    <v-row space-between="space-between" class="d-flex flex-grow-0 align-center mt-3">
      <v-col cols="auto" class="py-0 my-1 mr-auto">
        <v-checkbox
          v-model="addSenderInCC"
          class="sender-in-cc"
          :label="$t('sendFeedback.senderInCC')"
          hide-details="auto"
        ></v-checkbox>
      </v-col>
      <v-col cols="auto" class="py-0 my-1">
        <v-btn
          class="send ml-auto py-0 my-1"
          @click="sendByEmail"
          :disabled="!isValid"
          :ripple="false"
          :loading="loading"
          color="secondary"
        >
          <v-icon left dark>mdi-email-outline</v-icon>
          {{ $t('sendFeedback.send') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  export default {
    name: "SendFeedback",
    props: {
      voter: { required: true }
    },
    data() {
      return {
        isValid: false,
        loading: false,
        sender: '',
        addSenderInCC: false,
        content: '',
        recipients: [],
      }
    },
    methods: {
      async sendByEmail() {
        if (this.isValid) {
          this.loading = true

          try {
            const response = await this.$api.wizville.voters.sendByEmail(
              this.voter.uniqueId,
              {
                recipients: this.recipients,
                sender: this.sender,
                message: this.content,
                add_sender_in_cc: this.addSenderInCC
              }
            )

            if (response.status === "ok") {
              this.$store.dispatch('notifySuccess', {
                message: this.$t(
                  'flash_message_successfully_sent_voter_by_email',
                  { author: this.voter.author }
                ),
                timeout: 5000
              })
              this.$store.dispatch('events', { voterUpdate: { voter: { uniqueId: this.voter.uniqueId } } })

            } else {
              this.$store.dispatch('notifyError', { message: response.message })
            }
          } catch (error) {
            this.$store.dispatch('notifyError', { message: error.message })
          }

          this.loading = false
        }
      },
    },
    asyncComputed: {
      selectableRecipientsGroupedByUserGroup: {
        default: [],
        async get() {
          let result = []

          const userIdsWithPlaceAccessRequest = this.$basedRequest().select({
            voters: ['user_ids_for_voter_sharing']
          }).where({
            unique_id: [ this.voter.uniqueId ]
          })

          const userIdsWithPlaceAccess = (await this.$resolve(userIdsWithPlaceAccessRequest)).first()

          const userEmailsGroupedByUserGroupReq = this.$basedRequest().select({
            user_user_groups: ['user_group_name', 'lj_users_mail']
          }).where({
            user_id: userIdsWithPlaceAccess.userIdsForVoterSharing,
            is_wizville: false
          }).group(['user_group_name', 'lj_users_mail'])

          const userEmailsGroupedByUserGroup = await this.$resolve(userEmailsGroupedByUserGroupReq)

          const data = userEmailsGroupedByUserGroup.data
          const keys = Object.keys(data)

          let groupEmailsHash = {}
          keys.forEach((group, index) => {
            Object.keys(data[group]).forEach((user, index) => {
              let groupUser = data[group][user]
              let groupName = groupUser["userGroupName"]
              let email = groupUser["ljUsersMail"]

              groupEmailsHash[groupName] = groupEmailsHash[groupName] || []
              groupEmailsHash[groupName].push(email)
            })
          })

          Object.keys(groupEmailsHash).forEach((group, index) => {
            result.push({ header: group })
            result = result.concat(groupEmailsHash[group])
            if (index < keys.length - 1) {
              result.push({ divider: true })
            }
          })

          return result
        }
      }
    }
  }
</script>

<style lang="stylus">
  @import "~@theme/medias.styl"
  @import "~@theme/colors.styl"

  .send-feedback
    height: 100%
    padding: 20px 10px 20px 10px

    .content-textarea
      height: 100%

      textarea
        line-height: 18px

    .content-textarea .v-input__control
      height: 100%

    .content-textarea .v-input__slot
      margin-bottom: 0px

    +forBreakpoints(mobile, tablet)
      .content-textarea
        min-height: 200px

    .recipients.error--text,
    .sender.error--text
      input::placeholder
        color: var(--error)

    .recipients
      .v-select__selections
        gap: 0.5em
        padding: 0.5em 0

      .v-select__selections input
        min-height: 26px

      // Avoid a gap at the bottom of the combobox when some elements have been added.
      &.with-elements .v-select__selections input
        min-height: 0

      // Avoid issues with opening the combobox after some elements have been added to the
      // list.
      &.with-elements .v-input__append-inner
        display: none

      .v-input__control
        max-height: 5.2em // Limit the max height of the combobox.
        overflow: auto // Add a scrollbar.
        background-color: rgb(238, 241, 245) // Extend the background color.

      .v-select__slot
        align-items: self-start !important // Align the list to the first element.

      .v-chip
        height: 26px !important
        margin: 0 !important

      .v-chip__content
        position: relative

        // Make room for the close button.
        & > span
          max-width: 89%
          text-overflow: ellipsis
          white-space: nowrap
          overflow: hidden

        .v-chip__close
          font-size: 14px !important
          position: absolute
          right: -6px
          margin-right: 0 !important

    .sender .v-input__control > .v-input__slot
      min-height: 40px
      height: 40px

    .sender.v-text-field--filled input
      padding-top: 0px
      margin-top: 0px

    .v-avatar
      font-size: 10px
      height: 16px !important
      width: 16px !important
      min-width: 16px !important

    .v-subheader
      height: 25px
      font-size: 14px
      font-family: SourceSansProBold, Roboto !important
      display: block
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

    .v-menu__content.v-autocomplete__content
      max-height: 230px !important
      top: 40px !important

    .v-divider
      margin-top: 8px

    .v-list-item
      height: 25px
      min-height: 25px

    .v-input--selection-controls
      margin-top: 0px

    .v-list-item--highlighted:not(.v-list-item--active)::before
      opacity: 0 !important
</style>
