<template>
  <v-chip-group class="product-filter-chips" :mobile-breakpoint="0"> 
    <template v-for="selectedProductCategory, idx of selectedProductCategories">
      <v-chip
        class="ma-1 f-12"
        close
        dense
        @click:close="$emit('removeSelectedProductCategoryId', selectedProductCategory.id)"
      >
        <v-icon small class="pr-1">mdi-shape</v-icon>{{ selectedProductCategory.name }}
      </v-chip>
    </template>

    <template v-for="selectedProduct, idx of selectedProducts">
      <v-chip
        class="ma-1 f-12"
        close
        dense
        @click:close="$emit('removeSelectedProductId', selectedProduct.id)"
      >
        <v-icon small class="pr-1">mdi-cart</v-icon>{{ selectedProduct.name }}
      </v-chip>
    </template>
  </v-chip-group>
</template>

<script>
export default {
  name: "ProductFilterChips",
  props: [ 'selectedProductCategories', 'selectedProducts' ],
}
</script>

<style lang="stylus" scoped>
  .product-filter-chips
    max-width: 600px
</style>
