<template>
  <v-icon
    v-if="shouldDisplay"
    small
    :class="[colorClass, 'processing-status-chip']"
    class="mr-1 f-12"
  >
    mdi-circle
  </v-icon>
</template>

<script>
  export default {
    name: "VoterProcessingStatusChip",
    props: {
      voterNeedsProcessing: { required: true, type: Boolean },
      voterProcessingStatus: { required: true, type: String},
      voterInsatisfactionForCurrentGroup: { required: true, type: Boolean },
      isConversationManagedByCurrentUser: { required: false, type: Boolean},
      currentUserNeedValidationForCampaign: { required: true, type: Boolean, default: false },
      hasVoterAutomatedMessageTreatmentScheduled: { required: false, type: Boolean }
    },
    computed: {
      shouldDisplay() {
        if (this.voterProcessingStatus) {
          return true
        } else {
          if (this.hasVoterAutomatedMessageTreatmentScheduled) {
            return true
          }
        }

        return false
      },
      colorClass() {
        if (this.voterNeedsProcessing) {
          if (this.isVoterPendingForValidationForAnotherUser) {
            return 'normal--text'
          } else if (this.isVoterNeedAnswerFromAnotherUser) {
            return 'normal--text'
          } else if (this.isVoterInsatisfactionForAnother) {
            return 'normal--text'
          } else {
            return this.notProcessStatusColor
          }
        } else if (this.isVoterProcessed) {
          return 'success--text'
        } else if (this.hasVoterAutomatedMessageTreatmentScheduled) {
          return 'normal--text'
        }
      },
      isVoterPendingForValidationForAnotherUser() {
        return this.voterProcessingStatus === 'pending_validation' &&
          this.currentUserNeedValidationForCampaign
      },
      isVoterNeedAnswerFromAnotherUser() {
        return this.voterProcessingStatus === 'conversation_need_answer' &&
          !this.isConversationManagedByCurrentUser
      },
      isVoterProcessed() {
        return this.voterProcessingStatus === 'processed'
      },
      isVoterInsatisfactionForAnother() {
        this.voterProcessingStatus === 'insatisfaction_not_processed' && !this.voterInsatisfactionForCurrentGroup
      },
      notProcessStatusColor() {
        return this.hasVoterAutomatedMessageTreatmentScheduled ?
          'normal--text' :
          'error--text'
      }
    }
  }
</script>
