<template>
  <div
    class="voter-third-third-party-transfer"
  >
    <w-timeline-element
      right
    >
      <template v-slot:avatar>
        <CompanyAvatar/>
      </template>

      <template v-slot:title>
        <div class="d-flex">
          <span
            class="text-truncate fb-14 c-primary"
          >
            {{ currentUserBrandName }}
          </span>
          <v-tooltip top>
            <template #activator="{on}">
              <span class="fb-14 c-primary" v-on="on">{{ createdAt }}</span>
            </template>
            {{ createdAtTooltip }}
          </v-tooltip>
        </div>
      </template>

      <template v-slot:content>
        <div
          class="d-flex pa-4 rounded-lg bg-palegrey"
        >
          <div
            class="d-flex flex-column ga-3"
            :style="{ width: '100%' }"
          >

            <div class="d-flex align-center ga-1">
              <v-icon class="f-16 c-primary">mdi-account</v-icon>
              <span class="c-primary fb-14">
                {{ $t('voter_third_party_transfer_assigned_to_third_party') }}
              </span>
              <v-chip
                small
                :style="{ paddingLeft: '6px' }"
              >
                <v-avatar
                  class="accent white--text mr-1"
                  left
                  v-text="voterThirdPartyTransfer.email.slice(0, 1).toUpperCase()"
                ></v-avatar>
                <span>{{ voterThirdPartyTransfer.email }}</span>
              </v-chip>
            </div>
            <pre
              v-if="voterThirdPartyTransfer.comment"
              class="c-darkergrey f-14 content"
            >
             {{ voterThirdPartyTransfer.comment }}
            </pre>

            <v-divider></v-divider>
            <div class="d-flex flex-row align-center internal-memo-footer">
              <div>
                <v-icon small>mdi-account</v-icon>
                <span class="f-12">
                  {{ voterThirdPartyTransfer.email }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </w-timeline-element>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CompanyAvatar from '../../CompanyAvatar'

  export default {
    name: "VoterThirdPartyTransfer",
    props: {
      voterThirdPartyTransfer: { required: true },
      voter: { required: true }
    },
    components: {
      CompanyAvatar
    },
    computed: {
      ...mapGetters([
        'currentUserBrandName'
      ]),
      createdAt() {
        return this.$t('voter_timeline_element_created_at', { date: this.$helpers.dates.short(this.voterThirdPartyTransfer.createdAt, false) })
      },
      createdAtTooltip() {
        return this.$helpers.dates.short(this.voterThirdPartyTransfer.createdAt, true)
      }
    }
  }
</script>

<style lang='stylus'>
  .voter-third-third-party-transfer
    .v-badge .v-badge__wrapper
      margin-left: 0px
    .v-card__text
      padding-bottom: 8px !important

    .content
      white-space: pre-line
</style>
