<template>
  <v-row no-gutters class="dashboard-stats-actions-section">
    <OpportunityActionsWidget v-if="mainWizvilleCampaignWithTopics && nisMixin_canUseNisFeature([mainWizvilleCampaignWithTopics])" :key="$key('OpportunityActionsWidget', dashboardFilterBase64)" />
    <TreatActionsWidget treatStatus="new_feedbacks" :key="$key('TreatActionsWidget', dashboardFilterBase64, 'new_feedbacks')" />
    <TreatActionsWidget treatStatus="new_messages" :key="$key('TreatActionsWidget', dashboardFilterBase64, 'new_messages')" />
    <TreatActionsWidget treatStatus="pending_validations" v-if="shouldDisplayPendingValidationWidget" :key="$key('TreatActionsWidget', dashboardFilterBase64, 'pending_validations')" />
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import OpportunityActionsWidget from './OpportunityActionsWidget'
import TreatActionsWidget from './TreatActionsWidget'
import NisMixin from '@statistics/shared/nis_mixin'

export default {
  name: 'DashboardStatsActionsSection',
  mixins: [
    NisMixin,
  ],
  components: {
    OpportunityActionsWidget,
    TreatActionsWidget
  },
  computed: {
    ...mapGetters([
      'isMonoPlace',
      'hasNpsQuestion',
      'currentUserCustomerAnswerRightByCampaign',
      'currentDashboardCampaignIds',
      'isCurrentUserBrandNeedValidation',
      'mainWizvilleCampaignWithTopics',
      'isDemoUser',
      'dashboardFilterBase64'
    ]),
    shouldDisplayPendingValidationWidget() {
      const directValidationCampaignIds = this.currentDashboardCampaignIds.filter(
          campaignId => this.currentUserCustomerAnswerRightByCampaign[campaignId] === "direct"
        )

      return directValidationCampaignIds.length && this.isCurrentUserBrandNeedValidation
    },
  },
}
</script>
