<template>
  <WStatsWidget
    class="widget-position"
    :title="title"
    :cols="12"
    height="200px"
    contentWidth="100%"
  >
    <template #subtitle>
      <HeaderSubtitle/>
    </template>

    <template #options>
      <WidgetSettingsMenu
        :competitorScope="competitorScope"
        :competitorMode="competitorMode"
        :periodType="periodType"
        :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreferenceId"
      >
      </WidgetSettingsMenu>
    </template>

    <template #content>
      <PositionGauge
        :periodType="periodType"
        :competitorMode="competitorMode"
        :competitorScope="competitorScope"
        :lmBrandIds="lmBrandIds"
        :active="active"
      />
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import WidgetSettingsMenu from '../../WidgetSettingsMenu'
  import HeaderSubtitle from '../../shared/HeaderSubtitle'
  import PositionGauge from './PositionGauge'

  export default {
    name: "widget-position",
    components: {
      WidgetSettingsMenu,
      PositionGauge,
      HeaderSubtitle
    },
    props: {
      periodType: {
        required: true,
        type: String
      },
      competitorMode: {
        required: true,
        type: String
      },
      competitorScope: {
        required: true,
        type: String
      },
      campaignSmartBenchmarkPreferenceId: {
        required: false
      },
      lmBrandIds: {
        required: false
      },
      active: {
        required: true
      }
    },
    computed: {
      ...mapGetters([
        "isMonoPlace",
        "dashboardFilterDates"
      ]),
      title() {
        const localTitle = this.$t("competitors_widget_position_mono_title_local")

        if (this.competitorMode === "local") {
          if (this.active?.isUserItem === false) {
            return `${localTitle} - ${this.active.name}`
          }

          return localTitle
        } else {
          return this.$t("competitors_widget_position_mono_title_national")
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~@theme/constants.styl'

  .widget-position-header
    height: $widget-header-height
    .widget-position-main-title
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
</style>
