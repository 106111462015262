<template>
  <div>
    <v-card
      class="conversation-pre-filled-message"
    >
      <v-card-title>
        <h4 class="f-16 c-primary">{{ $t('conversation_pre_filled_message_preference') }}</h4>
        <div class="ml-auto">
          <v-btn
            class="mr-2"
            v-if="!loading"
            color="primary"
            outlined
            :to="oldPlateformResponseTypesTo"
          >
            {{ $t('conversation_pre_filled_message_create_title')}}
          </v-btn>
          <v-btn
            v-if="!loading"
            color="primary"
            outlined
            @click="showGroupDialog(null)"
          >
            {{ $t('conversation_pre_filled_message_group_create_title')}}
          </v-btn>
        </div>

        <!-- <v-btn
          class="ml-auto"
          color="primary"
          outlined
          @click="exportConversationPreFilledMessages"
          :loading="exportingConversationPreFilledMessages"
        >
          Export
        </v-btn> -->
  <!--       <ImportDialog
          @refreshTable="refreshTable"
        /> -->
      </v-card-title>
      <v-card-subtitle @click="isHelpOpened = !isHelpOpened">
        <div class="d-flex flex-column">
          <div class="d-flex align-center">
            <span>
              {{ $t('conversation_pre_filled_message_preference_subtitle') }}
            </span>
            <v-icon class="ml-1" small>mdi-information</v-icon>
          </div>
          <v-card
            v-if="isHelpOpened"
            flat
            class="mt-2 bg-bodygrey"
            width="600"
          >
            <v-card-text class="d-flex flex-column">
              <span class="fb-12">
                {{ $t('conversation_pre_filled_message_preference_general_functioning_title') }}
              </span>
              <span class="f-12">
                {{ $t('conversation_pre_filled_message_preference_general_functioning_content') }}
              </span>
              <span class="fb-12">
                {{ $t('conversation_pre_filled_message_preference_sending_time_title') }}
              </span>
              <span class="f-12">
                {{ $t('conversation_pre_filled_message_preference_sending_time_content') }}
              </span>
              <span class="fb-12">
                {{ $t('conversation_pre_filled_message_preference_local_title') }}
              </span>
              <span class="f-12">
                {{ $t('conversation_pre_filled_message_preference_local_content') }}
              </span>
            </v-card-text>
          </v-card>
        </div>

      </v-card-subtitle>
      <v-card-text>
        <WDataTable
          v-if="!loading && messages.length"
          :headers="headers"
          :items="messages"
          group-by="groupId"
          :hideDefaultHeader="true"
          :hideDefaultFooter="true"
          :disablePagination="true"
          :scrollable="true"
          :fixedHeader="true"
          :draggable="true"
          @draggableOnMoveCallback="draggableOnMoveCallback"
          @draggableOnEndCallback="draggableOnEndCallback"
          class="border-bodygrey"
        >
          <template #headerGroupItem.title="{ groupBy }">
            <div class="d-flex align-center ml-4 flex-wrap">
              <div class="d-flex align-center mr-2">
                <span
                  class="c-darkergrey fb-16 pointer flex-shrink-0"
                >
                  {{ groupName(groupBy) }}
                </span>
                <span
                  v-if="groupBy !== noGroupId && groupAnswerCount(groupBy) > 0"
                  class="c-darkergrey f-14 ml-1 pointer flex-shrink-0"
                >
                  ({{ $tc('conversation_pre_filled_message_answer_count', groupAnswerCount(groupBy)) }})
                </span>
              </div>


              <div
                v-if="groupAutomated(groupBy)"
              >
                <AutomatedGrades
                  :gradeMin="groupAutomatedgradeMin(groupBy)"
                  :gradeMax="groupAutomatedgradeMax(groupBy)"
                />
              </div>
            </div>
          </template>
          <template #headerGroupItem.actions="{ groupBy }">
            <div
              class="ml-auto d-flex align-center flex-nowrap"
            >
              <v-btn
                icon
                @click="showGroupDialog(groupBy)"
                v-if="!isDefaultGroup(groupBy)"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                small
                icon
                @click="deleteGroup(groupBy)"
                v-if="!isDefaultGroup(groupBy)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>

          <template #item.title="{ item }">
            <div
              class="d-flex flex-column ml-4 d-md-none my-1"
            >
              <div class="d-flex align-center">
                <v-chip
                  v-if="displayIndex(item)"
                  class="fb-13 mb-1 mr-2"
                >
                  {{ index(item) }}
                </v-chip>
                <span class="fb-13 mb-1">
                  {{ title(item) }}
                </span>
              </div>
              <Translations
                :translations="item.translations"
              />
            </div>
            <div class="d-none d-md-flex align-center ml-4">
              <v-chip
                v-if="displayIndex(item)"
                class="fb-13 mr-2"
              >
                {{ index(item) }}
              </v-chip>
              <span class="fb-13">
                {{ title(item) }}
              </span>
            </div>

          </template>
          <template #item.translations="{ item }">
            <Translations
              :translations="item.translations"
            />
          </template>
          <template #item.actions="{ item }">
            <div class="d-flex ml-auto">
              <v-btn
                icon
                small
                v-if="item.translations"
              >
                <v-icon small style="cursor: grabbing">mdi-arrow-split-horizontal</v-icon>
              </v-btn>
            </div>
          </template>
        </WDataTable>
        <v-skeleton-loader v-else type="paragraph" />
      </v-card-text>
    </v-card>
    <GroupDialog
      v-model="groupDialogOpen"
      :group="group"
      @groupCreated="onGroupCreated"
      @groupUpdated="onGroupUpdated"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _uniq from "lodash/uniq"
  import _compact from "lodash/compact"
  import _cloneDeep from "lodash/cloneDeep"

  import ImportDialog from './ImportDialog'
  import Automated from './Automated'
  import AutomatedGrades from './../AutomatedGrades'
  import GroupDialog from './GroupDialog'
  import Translations from './Translations'

  export default {
    name: "conversationPreFilledMessage",
    components: {
      // ImportDialog,
      Automated,
      AutomatedGrades,
      GroupDialog,
      Translations
    },
    data() {
      return {
        loading: true,
        exportingConversationPreFilledMessages: false,
        groupDialogOpen: false,
        group: null,
        noGroupId: 'noGroupId',
        messages: null,
        conversationPreFilledMessageGroups: [],
        conversationPreFilledMessages: [],
        isHelpOpened: false
      }
    },
    computed: {
      ...mapGetters([
        'currentUser',
        'currentCampaignPreferences',
        'currentCampaignPreferencesCampaignId'
      ]),
      headers() {
        let headers = [
          {
            text: this.$t('conversation_prefilled_messages_table_headers_title'),
            value: 'title',
            cellClass: 'title-cell text-truncate'
          },
          {
            text: this.$t('conversation_prefilled_messages_table_headers_translations'),
            value: 'translations',
            displayCondition: this.$vuetify.breakpoint.mdAndUp
          },
          {
            text: this.$t('conversation_prefilled_messages_table_headers_source'),
            value: 'source',
            displayCondition: this.$vuetify.breakpoint.mdAndUp
          },
          {
            text: '',
            value: 'actions'
          }
        ]

        return headers.filter((header) => header.displayCondition === undefined || header.displayCondition)
      },
      oldPlateformResponseTypesTo() {
        return {
          name: 'OldPlatformResponseTypes',
          params: {
            campaignId: this.currentCampaignPreferencesCampaignId
          },
          target: '_blank'
        }
      }
    },
    methods: {
      getGroup(groupId) {
        return this.conversationPreFilledMessageGroups.find(
          group => group.id === groupId
        )
      },
      groupName(groupId) {
        return this.getGroup(groupId).name
      },
      groupAutomated(groupId) {
        return this.getGroup(groupId).automated
      },
      groupAnswerCount(groupId) {
        return this.getGroup(groupId).totalVoterAutomatedMessageTreatmentCount
      },
      groupAutomatedgradeMin(groupId) {
        return this.getGroup(groupId).gradeMin
      },
      groupAutomatedgradeMax(groupId) {
        return this.getGroup(groupId).gradeMax
      },
      isDefaultGroup(groupId) {
        return groupId === this.noGroupId
      },
      onGroupCreated(createdGroup) {
        const newGroups = [...this.conversationPreFilledMessageGroups]

        newGroups.push(createdGroup)

        this.conversationPreFilledMessageGroups = newGroups

        this.refreshMessages()
      },
      onGroupUpdated(updatedGroup) {
        const newGroups = [...this.conversationPreFilledMessageGroups].filter(group =>
          group.id !== updatedGroup.id
        )

        newGroups.push(updatedGroup)

        this.conversationPreFilledMessageGroups = newGroups

        this.refreshMessages()
      },
      draggableOnMoveCallback(item, destinationItem, destinationIndex) {
        let newMessages = [...this.messages]

        // Add an empty message if the old group becomes empty
        const oldGroupMessages = this.messages.filter(message =>
          message.groupId === item.groupId
        )

        if (oldGroupMessages.length === 1) {
          newMessages.push({
            groupId: item.groupId
          })
        }

        this.messages = newMessages
      },
      async draggableOnEndCallback(item, destinationItem, destinationIndex) {
        let newMessages = [...this.messages]

        // Remove the message in the old group
        newMessages = newMessages.filter(message =>
          message.id !== item.id
        )

        // Remove empty message in new group
        newMessages = newMessages.filter(message =>
          message.groupId !== destinationItem.groupId ||
          (message.groupId === destinationItem.groupId && message.translations)
        )

        const groupMessages = newMessages.filter(message =>
          message.groupId === destinationItem.groupId
        )

        newMessages = newMessages.filter(message =>
          message.groupId !== destinationItem.groupId
        )

        // Add the new message in the new group
        const message = { ...item }

        message.groupId = destinationItem.groupId
        groupMessages.splice(destinationIndex, 0, message)

        newMessages = newMessages.concat(groupMessages)

        // Add an empty message if the old group becomes empty
        const oldGroupMessages = this.messages.filter(message =>
          message.groupId === item.groupId
        )

        if (oldGroupMessages.length === 1) {
          newMessages.push({
            groupId: item.groupId
          })
        }

        this.messages = newMessages

        await this.changeConversationPreFilledMessage(item, destinationItem.groupId)
      },
      async getConversationPreFilledMessages() {
        this.loading = true

        const request = this.$basedRequest().select({
          conversation_pre_filled_messages: [
            'id',
            { 'lj_conversation_pre_filled_message_groups_id': { as: 'groupId' } },
            'translations',
            'source'
          ]
        }).where({
          campaign_id: this.currentCampaignPreferencesCampaignId
        })

        const conversationPreFilledMessages = (await this.$resolve(request))?.data?.conversationPreFilledMessages || []

        return conversationPreFilledMessages
      },
      async getConversationPreFilledMessageGroups() {
        const request = this.$basedRequest().select({
          conversation_pre_filled_message_groups: [
            { 'MAX_id': { as: 'id' } },
            { 'MAX_name': { as: 'name' } },
            { 'BOOL_AND_automated': { as: 'automated' } },
            { 'MAX_grade_min': { as: 'gradeMin' } },
            { 'MAX_grade_max': { as: 'gradeMax' } },
            'total_voter_automated_message_treatment_count'
          ]
        }).where({
          campaign_id: this.currentCampaignPreferencesCampaignId
        }).group(
          [ "id" ]
        )

        const data = (await this.$resolve(request))?.data || {}

        const conversationPreFilledMessageGroups = Object.values(data)

        conversationPreFilledMessageGroups.push({
          id: this.noGroupId,
          name: this.$t('conversation_pre_filled_message_groups_no_group'),
          automated: false
        })

        return conversationPreFilledMessageGroups
      },
      async deleteGroup(groupId) {
        try {
          const response = await this.$api.wizville.conversationPreFilledMessageGroups.destroy(groupId)

          if (response.status === 'ok') {
            this.$store.dispatch(
              'notifySuccess',
              {
                message: this.$t('flash_message_successfully_deleted_conversation_pre_filled_message_group'),
                timeout: 5000
              }
            )

            const newGroups = [...this.conversationPreFilledMessageGroups].filter(group =>
              group.id !== groupId
            )

            this.conversationPreFilledMessageGroups = newGroups

            this.refreshMessages()
          } else {
            this.notifyError(response.message)
          }
        } catch (error) {
          this.notifyError(error.message)
        }
      },
      async changeConversationPreFilledMessage(message, destinationGroupId) {
        const groupId = destinationGroupId === this.noGroupId ? null : destinationGroupId
        try {
          const response = await this.$api.wizville.conversationPreFilledMessages.update(
            message.id,
            { conversation_pre_filled_message_group_id: groupId }
          )

          if (response.status === 'ok') {
            this.$store.dispatch(
              'notifySuccess',
              {
                message: this.$t('flash_message_successfully_updated_conversation_pre_filled_message'),
                timeout: 5000
              }
            )
          } else {
            this.notifyError(response.message)
          }
        } catch (error) {
          this.notifyError(error.message)
        }
      },
      showGroupDialog(groupId) {
        if (groupId) {
          const group = this.getGroup(groupId)

          this.group = {
            id: group.id,
            name: group.name,
            automated: group.automated,
            gradeMin: group.gradeMin || 0,
            gradeMax: group.gradeMax || 5
          }
        }
        this.groupDialogOpen = true
      },
      displayIndex(item) {
        return item.groupId !== this.noGroupId &&
          item.translations
      },
      index(item) {
        return this.messages.filter(
          element => element.groupId === item.groupId
        ).findIndex(
          element => element.id === item.id
        ) + 1
      },
      title(item) {
        if (!item.translations) {
          return this.$t('conversation_pre_filled_message_groups_no_message')
        }

        [
          this.currentUser?.locale,
          this.currentCampaignPreferences?.locale
        ].filter(
          elements => elements !== null
        ).forEach(locale => {
          if (item.translations[locale]) {
            return item.translations[locale].title
          }
        })

        return item.translations[Object.keys(item.translations)[0]].title
      },
      refreshMessages() {
        const currentMessages = _cloneDeep(this.messages || this.conversationPreFilledMessages)
        const newMessages = []

        // Add messages in existing groups
        this.conversationPreFilledMessageGroups.forEach(group => {
          const groupIdInclude = group.id !== this.noGroupId ? [group.id] : [null, this.noGroupId]

          let groupMessages = currentMessages.filter(
            message => groupIdInclude.includes(message.groupId) && message.id !== undefined
          )

          groupMessages.forEach(message => {
            message.groupId = group.id
            newMessages.push(message)
          })
        })

        // Add message without group in the default group
        const groupIds = this.conversationPreFilledMessageGroups.map(
          group => group.id
        )

        const conversationPreFilledMessagesWithoutGroup = currentMessages.filter(message => {
          return !groupIds.includes(message.groupId)
        })

        conversationPreFilledMessagesWithoutGroup.forEach(message => {
          message.groupId = this.noGroupId
          newMessages.push(message)
        })

        // Add empty message in groups without message
        const groupIdsWithMessage = _uniq(
          newMessages.filter(
            message => message.groupId
          ).map(
            message => message.groupId
          )
        )

        const groupWithoutMessage = this.conversationPreFilledMessageGroups.filter(
          group => !groupIdsWithMessage.includes(group.id)
        )

        groupWithoutMessage.forEach(group => {
          newMessages.push({
            groupId: group.id
          })
        })

        this.messages = newMessages
      },
      async exportConversationPreFilledMessages() {
        this.exportingConversationPreFilledMessages = true

        const result = (await this.$api.wizville.conversations.exportConversationPreFilledMessages(this.currentCampaignPreferencesCampaignId))

        this.$textToFile(`ConversationPreFilledMessages.xlsx`, result.excel, { base64: true, binary: true })

        this.exportingConversationPreFilledMessages = false
      }
    },
    async mounted() {
      this.loading = true
      this.conversationPreFilledMessages = await this.getConversationPreFilledMessages()
      this.conversationPreFilledMessageGroups = await this.getConversationPreFilledMessageGroups()
      this.refreshMessages()
      this.loading = false
    },
    watch: {
      groupDialogOpen(val) {
        if (!val) {
          this.group = null
        }
      }
    }
  }
</script>
