<template>
  <div class="ranking-options">
    <v-select
      :items="scopeChoices"
      v-model="currentScope"
      @change="updateScope"
      outlined
      dense
      hide-details
      label="Périmètre"
      class="f-13"
    ></v-select>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "RankingOptions",
    props: {
      scope: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        currentScope: this.scope
      }
    },
    watch :{
      hasGroupRanking(newVal, oldVal) {
        let scope = 'network'

        if (this.dashboardPlaceIds.length > 1) {
          scope = 'mine'
        } else if (this.hasGroupRanking) {
          scope = 'group'
        }
        
        if (this.$route.params?.scope !== scope) {
          this.$router.push({ params: { scope: scope } })
        }
      }
    },
    computed: {
      ...mapGetters([
        'hasGroupRanking',
        'groupRankingName',
        'networkRankingName',
        'groupRankingPlaceIds',
        'networkRankingPlaceIds',
        'dashboardPlaceIds',
        'currentLexicon'
      ]),
      scopeChoices() {
        const choices = []

        choices.push({
          text: this.$t('ranking_perimeter_filter', {
            count: this.dashboardPlaceIds.length
          }),
          value: "mine"
        })

        if (this.hasGroupRanking) {
          choices.push({
            text: `${this.groupRankingName} (${this.groupRankingPlaceIds?.length || 0} ⌂)`,
            value: "group"
          })
        }

        if (this.networkRankingName) {
          choices.push({
            text: `${this.currentLexicon.translate('network', 'network')} (${this.networkRankingPlaceIds.length || 0} ⌂)`,
            value: "network"
          })
        }

        return choices
      }
    },
    methods: {
      updateScope(newVal) {
        this.$emit('scopeChanged', newVal)
        this.$router.push({ params: { scope: newVal } })
      }
    },
  }
</script>

<style lang="stylus" scoped>

</style>
