<template>
  <div class="achievement-leaderboard">
    <WStatsWidget
      :cols="width"
      contentWidth="100%"
      :justifyCenter="false"
    >
      <template #content>
        <v-data-table
          :headers="headers"
          :item-class="itemClass"
          :items="campaignAchievements"
          :items-per-page="-1"
          :loading="loading"
          :loading-text="$t('loading_text')"
          :no-data-text="$t('no_data_text')"
          :no-results-text="$t('no_results_text')"
          @click:row="openPlaceAchievements"
          class="campaign_achievements_table"
          hide-default-footer
          mobile-breakpoint="0"
          :height="'calc(100vh - 160px)'"
          scrollable
          fixed-header
        >
          <template v-slot:item.position="{ index }">
            <WRank :rank="index + 1" />
          </template>
          <template v-slot:item.achievementCount="{ value }">
            {{ value }} 🥳
          </template>
          <template v-slot:item.actions>
            <v-icon
              class="c-secondary f-13"
              v-if="$vuetify.breakpoint.smAndDown"
            >
              mdi-eye-outline
            </v-icon>
            <v-btn
              outlined
              small
              color="secondary"
              v-else
            >
              <v-icon class="c-secondary f-13">
                mdi-eye-outline
              </v-icon>&nbsp;
              {{ $t('achievements_leaderboard_tab_see_action') }}
            </v-btn>
          </template>
        </v-data-table>
      </template>
    </WStatsWidget>
    <v-dialog
      v-model="placeAchievementsDialogShown"
      max-width="1000px"
      :retain-focus="false"
    >
      <v-card class="pa-5">
        <div class="d-flex">
          <div class="mb-3">
            <div class="f-18">
              <span v-html="placeName" />
            </div>
            <div class="f-13">
              {{ dashboardFilterDates.text }}
            </div>
          </div>
          <v-btn
            icon
            color="black"
            plain
            @click="placeAchievementsDialogShown = false"
            class="ml-auto"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <AchievementTable :place-campaign-id="selectedPlaceCampaignId" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import AchievementTable from '../AchievementTable'

  export default {
    name: 'LeaderboardPage',
    components: { AchievementTable },
    data() {
      return {
        loading: false,
        placeAchievementsDialogShown: false,
        selectedPlaceCampaignId: null,
        selectedPlaceName: null,
        selectedPlaceAchievementCount: null,
      }
    },
    methods: {
      itemClass(item) {
        if (this.placeIdsScope) return ''
        if (
          this.networkRankingPlaceIds.length != this.dashboardPlaceIds.length &&
          this.dashboardPlaceIds?.includes(item.placesId)
        ) {
          return 'active bg-paleblue'
        }
        return ''
      },
      openPlaceAchievements(item) {
        this.selectedPlaceCampaignId = item.placeCampaignId
        this.selectedPlaceName = item.placesName
        this.selectedPlaceAchievementCount = item.achievementCount
        this.placeAchievementsDialogShown = true
      }
    },
    computed: {
      ...mapGetters([
        'currentLexicon',
        'sqlDatesScope',
        'networkRankingPlaceIds',
        'placeIdsScope',
        'dashboardPlaceIds',
        'currentDashboardCampaignIds',
        'dashboardFilterDates',
      ]),
      headers() {
        return [
          {
            text: this.$t('achievements_leaderboard_tab_position_column_title'),
            value: 'position',
            width: 75,
            sortable: false,
          },
          {
            text: this.currentLexicon.translate('place', 'place'),
            value: 'placesName',
            align: 'start',
            sortable: false,
          },
          {
            text: this.$t('achievements_leaderboard_tab_achievements_column_title'),
            value: 'achievementCount',
            align: 'center',
            sortable: false,
          },
          {
            value: 'actions',
            align: 'center',
            cellClass: 'pr-2 actions',
            sortable: false,
          }
        ]
      },
      placeName() {
        return this.$t(
          'achievements_modal_place_title',
          {
            achievementCount: this.selectedPlaceAchievementCount,
            placeName: this.selectedPlaceName,
          }
        )
      },
      width() {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 12
        } else if (this.$vuetify.breakpoint.lgAndDown) {
          return 6
        } else {
          return 4
        }
      }
    },
    asyncComputed: {
      campaignAchievements: {
        default: [],
        lazy: true,
        async get() {
          this.loading = true
          let request =
            this.$basedRequest().select({
              place_campaign_achievements: [
                'places_id',
                { MAX_places_name: { as: 'places_name' } },
                { MAX_place_campaign_id: { as: 'place_campaign_id' } },
                { COUNT_id: { as: 'achievement_count' } },
              ]
            })
            .where({
              place_campaigns_campaign_id: this.currentDashboardCampaignIds,
              claimed_at: { 'is_not_null': {} },
            })
            .dateBetween(
              this.sqlDatesScope.dateBegin,
              this.sqlDatesScope.dateEnd
            )
            .group([
              'places_id'
            ])
            .order([
              ['COUNT_id', 'desc']
            ])

          if (this.placeIdsScope) {
            request = request.where({
              place_id: this.placeIdsScope
            })
          }
          const response = await this.$resolve(request)

          this.loading = false
          return response.data.placeCampaignAchievements
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .campaign_achievements_table >>> th
    white-space nowrap

  .campaign_achievements_table >>> tbody tr:hover
    cursor pointer

  .campaign_achievements_table .actions
    width 70px

  .campaign_achievements_table >>> .rank
    margin 0 auto
</style>
