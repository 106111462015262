<template>
  <WStatsWidget
    ref="widget"
    :cols="cols"
    :linkTo="linkTo"
    :subtitle="displayedSubtitle"
    :title="isLcl ? 'IRC' : $t('nps')"
    :loading="data === undefined || campaign === undefined"
    :empty="data !== undefined && data.indicator === undefined"
    class="nps-score-widget"
    height="200px"
  >
    <template #content>
      <WStatsNpsWidget
        :nps="data.indicator"
        :evolution="data.displayEvolution ? data.evolution : null"
        :nbReview="data.nbReview"
      />
    </template>

    <template
      #footer-left
      v-if="!hasAccessToAllDashboardPlaces"
      class="mt-2"
    >
      <template v-if="isMonoPlace && rank">
        {{ rank }}{{ $options.filters.ordinal(rank) }}
      </template>
      <template v-if="groupIndicator">
        <span class="fb-12">
          <WTrunc :text="groupRankingName" :limit="20" /> :
        </span>
        <span class="mr-3">{{ groupIndicator.toFixed(0) }}</span>
      </template>
    </template>

    <template
      #footer-right
      v-if="!hasAccessToAllDashboardPlaces && networkIndicator"
      class="mt-2"
    >
      <span class="fb-12">
        {{ networkRankingName }} :
      </span>
      <span>{{ networkIndicator.toFixed(0) }}</span>
    </template>
  </WStatsWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import VoterScoreMixin from '@statistics/shared/voter_score_mixin'
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'

export default {
  name: 'NpsScoreWidget',
  mixins: [
    VoterScoreMixin,
    WidgetMixin,
  ],
  props: {
    cols: { type: Number, default: 3 },
  },
  computed: {
    ...mapGetters([
      'dashboardFilterDates',
      'dashboardFilterRequest',
      'hasAccessToAllDashboardPlaces',
      'networkRankingName',
      'isLcl'
    ]),
    basedRequest() {
      return this.dashboardFilterRequest.select({
        brand_voters: [
          this.indicatorColumn,
          { "nb_review_by_nps": { min: 0, max: 10, condition: '[..]', as: 'nbReview' }},
        ]
      })
    },
    indicatorColumn() {
      return {
        avg_nps: { as: this.indicatorAlias }
      }
    },
    indicatorAlias() {
      return 'score'
    },
    linkTo() {
      return {
        name: 'Statistics',
        params: { sectionType: 'nps', sectionId: "default", subSectionId: this.campaign?.id }
      }
    }
  }
}
</script>
