<template>
  <div>
    <v-breadcrumbs
      class="pt-0 pb-1 px-0"
      v-if="categoriesPath"
      :items="categoriesPath"
    >
      <template v-slot:item="{ item }">
        <span v-if="item.text" >
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
            :class='item.classNames'
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </span>
      </template>
    </v-breadcrumbs>
    <v-skeleton-loader v-else type="text" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "Breadcrumb",
    props: {
      mode: { type: String, required: false },
      campaign: { required: true },
      categoryId: { type: Number, required: false },
      productCategoriesById: { required: true },
      applyNavigationFilters: { required: false, default: true }
    },
    asyncComputed: {
      categoriesPath: {
        async get() {
          if (this.productCategoriesById) {
            const currentCategory = this.productCategoriesById[this.categoryId]
            let category = currentCategory
            let categoriesArray = []
            if (this.applyNavigationFilters) {
              categoriesArray.unshift(
                {
                  text: currentCategory?.categoryName,
                  value: currentCategory?.categoryId,
                  disabled: this.mode === 'products',
                  classNames: ['current'],
                  to: {
                    name: 'Products',
                    params: {
                      productCategoryId: currentCategory?.categoryId,
                      mode: 'product_categories'
                    }
                  }
                }
              )

              while (category?.categoryParentId) {
                category = this.productCategoriesById[category?.categoryParentId]
                categoriesArray.unshift(
                  {
                    text: category?.categoryName,
                    value: category?.categoryId,
                    disabled: false,
                    to: {
                      name: 'Products',
                      disabled: category?.categoryId === this.categoryId,
                      params: {
                        productCategoryId: category?.categoryId,
                        mode: 'product_categories'
                      }
                    }
                  }
                )
              }
            }

            categoriesArray.unshift({
              text: this.$t('categories_products_all'),
              value: null,
              disabled: false,
              to: {
                name: 'Products',
                params: {
                  productCategoryId: 'all',
                  mode: 'product_categories'
                }
              }
            })

            return categoriesArray
          }
        }
      }
    }
  }
</script>

<style lang="stylus">
  .categories-product-widget
    .v-breadcrumbs__item--disabled
      color: var(--primary) !important
    .v-breadcrumbs__item:not(.v-breadcrumbs__item--disabled)
      color: var(--legend) !important
</style>
