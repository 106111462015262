<template>
  <div class="voter-status-nav">
    <w-nav-tabs :router-links-properties="routerLinksProperties" />
    <v-divider class="divider"></v-divider>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import VoterRequestsMixin from "../../shared/voter_requests_mixin"

export default{
  name: "VoterStatusNav",
  mixins: [
    VoterRequestsMixin
  ],
  computed: {
    ...mapGetters([
      'dashboardFilterRequest',
      'dashboardFilterReady',
      'voterUpdateEvent',
      'isCurrentUserBrandNeedValidation',
      'currentDashboardCampaignIds',
      'currentDashboardCampaigns',
      'currentUserCustomerAnswerRightByCampaign',
      'currentDashboardInsatisfactionNotificationEnabled'
    ]),
    shouldDisplayPendingValidationLink() {
      const directValidationCampaignIds = this.currentDashboardCampaignIds.filter(
          campaignId => this.currentUserCustomerAnswerRightByCampaign[campaignId] === "direct"
        )
      return directValidationCampaignIds.length &&
        this.isCurrentUserBrandNeedValidation ||
        this.currentDashboardCampaigns.filter(campaign => campaign.thirdPartyTransferEnabled).length
    },
    routerLinksProperties() {
      const routerLinksPropertiesResult = [
        {
          title: 'feedbacks_all',
          className: 'feedbacks-all py-2',
          route: {
            name: 'Feedbacks',
            params: { status: 'all', subStatus: 'all', voterUniqueId: null }
          },
        }
      ];
      if (this.currentDashboardInsatisfactionNotificationEnabled) {
        routerLinksPropertiesResult.push({
          title: 'feedbacks_not_processed',
          className: 'feedbacks-not-processed py-2',
          route: {
            name: 'Feedbacks',
            params: { status: 'treat', subStatus: 'all', voterUniqueId: null }
          },
          optionEls: {
            badge: {
              color: this.totalCount > 0 ? 'error' : 'grey',
              content: this.totalCount
            }
          },
          childNav: [
            {
              title: 'feedbacks_not_processed_all',
              default: true,
              className: 'feedbacks-not-processed-all',
              optionEls: {
                badge: {
                  color: this.totalCount > 0 ? 'error' : 'grey',
                  content: this.totalCount
                }
              },
              route: {
                name: 'Feedbacks',
                params: { status: 'treat', subStatus: 'all', voterUniqueId: null }
              },
            },
            {
              title: 'feedbacks_not_processed_new_feedbacks',
              className: 'feedbacks-not-processed-new-feedbacks',
              optionEls: {
                badge: {
                  color: this.newFeedbacksCount > 0 ? 'error' : 'grey',
                  content: this.newFeedbacksCount
                }
              },
              route: {
                name: 'Feedbacks',
                params: { status: 'treat', subStatus: 'new_feedbacks', voterUniqueId: null }
              },
            },
            {
              title: 'feedbacks_not_processed_new_messages',
              className: 'feedbacks-not-processed-new-messages',
              optionEls: {
                badge: {
                  color: this.newMessagesCount > 0 ? 'error' : 'grey',
                  content: this.newMessagesCount
                }
              },
              route: {
                name: 'Feedbacks',
                params: { status: 'treat', subStatus: 'new_messages', voterUniqueId: null }
              },
            },
            {
              title: 'feedbacks_not_processed_pending_validations',
              className: 'feedbacks-not-processed-pending-validation',
              shouldHide: !this.shouldDisplayPendingValidationLink,
              optionEls: {
                badge: {
                  color: this.pendingValidationsCount > 0 ? 'error' : 'grey',
                  content: this.pendingValidationsCount
                }
              },
              route: {
                name: 'Feedbacks',
                params: { status: 'treat', subStatus: 'pending_validations', voterUniqueId: null }
              },
            }
          ]
        },
        {
          title: 'feedbacks_processed',
          className: 'feedbacks-processed py-2',
          route: {
            name: 'Feedbacks',
            params: { status: 'treated', subStatus: 'all', voterUniqueId: null }
          }
        })
      }
      return routerLinksPropertiesResult
    }
  },
  data () {
    return {
      reload: 0
    }
  },
  asyncComputed: {
    newFeedbacksCount: {
      async get() {
        const count = await this.getCount(
          'treat',
          'new_feedbacks',
          { assignation: { value: 'me' } }
        )
        return count
      },
      default: 0,
      watch: [ 'reload' ]
    },
    newMessagesCount: {
      async get() {
        const count = await this.getCount(
          'treat',
          'new_messages',
          { assignation: { value: 'me' } }
        )
        return count
      },
      default: 0,
      watch: [ 'reload' ]
    },
    pendingValidationsCount: {
      async get() {
        const count = await this.getCount(
          'treat',
          'pending_validations',
          { assignation: { value: 'me' } }
        )
        return count
      },
      default: 0,
      watch: [ 'reload' ]
    },
    totalCount: {
      async get() {
        const count = await this.getCount(
          'treat',
          'all',
          { assignation: { value: 'me' } }
        )
        return count
      },
      default: 0,
      watch: [ 'reload' ]
    }
  },
  watch: {
    voterUpdateEvent: function(voterUpdateEvent) {
      this.reload = this.reload + 1
    }
  }
}
</script>
<style lang="stylus">
  .voter-status-nav
    .divider
      margin-top: -2px
</style>
