<template>
  <div class="reviews-export">
    <v-card
      outlined
      class="d-flex justify-space-between pr-4 pl-4 ml-4 mt-4 mr-4"
      v-if="!$vuetify.breakpoint.smAndDown"
    >
    <v-card-title>{{ $i18n.t('components.dashboardPage.sidebar.footer.title') }}</v-card-title>
    <v-checkbox
      v-for="selectableTab in selectableTabs"
      @change="changeSelectedTabs(selectableTab.value)"
      :key="selectableTab.text"
      :label="selectableTab.text"
      :value="selectableTab.value"
    />
      <v-card-actions>
        <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading || selectedTabs.length === 0"
        @click="exportCampaign">
          {{ $i18n.t('dashboard_sidebar_campaign_export') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      outlined
      v-else
    >
      <v-card-title>{{ $i18n.t('components.dashboardPage.sidebar.footer.title') }}</v-card-title>
      <v-card-text>
        <v-checkbox
          v-for="selectableTab in selectableTabs"
          @change="changeSelectedTabs(selectableTab.value)"
          :key="selectableTab.text"
          :label="selectableTab.text"
          :value="selectableTab.value"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading || selectedTabs.length === 0"
          @click="exportCampaign">
          {{ $i18n.t('dashboard_sidebar_campaign_export') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: "CampaignExport",
    data() {
      return {
        headers: [
          { text: this.$i18n.t('all_tabs'), value: 'text' },
        ],
        selectedTabs: [],
        loading: false,
      }
    },
    methods: {
      changeSelectedTabs(value) {
        const index = this.selectedTabs.indexOf(value)
        index > -1 ? this.selectedTabs.splice(index, 1) : this.selectedTabs.push(value)
      },
      async exportCampaign() {
        this.loading = true
        try {
          const response = await this.$api.wizville.campaign.export_campaign(this.mainCampaignId,
            {
              filters: {
                place_campaign_ids: this.placeCampaignIds.map(placeCampaignId => placeCampaignId.toString()),
                dates : {
                  date_begin: this.sqlDateBegin,
                  date_end: this.sqlDateEnd,
                  type: "vote"
                },
              },
              tabs: this.selectedTabs
            }
          )

          setTimeout(() => {
            this.loading = false
            if (response.status === 'ok') {
              this.$store.dispatch('notifySuccess', {
                message: this.$i18n.t('campaign_export_success'),
                timeout: 5000,
              })
            } else {
              this.$store.dispatch('notifyError', {
                message: this.$i18n.t('campaign_export_failure'),
                timeout: 5000,
              })
            }
          }, "2000")

        } catch (error) {
          console.log(error)
          this.$store.dispatch('notifyError', {
            message: this.$i18n.t('campaign_export_failure'),
            timeout: 5000,
          })
        }


      },
    },
    computed: {
      ...mapGetters([
        'currentDashboard',
        'currentAdmin',
        'currentUserId',
        'hasAccessToNewPlatformFeedbackTab',
        'hasAccessToNewPlatformRankingTab',
        'mainCampaignId',
        'currentDashboardMonoCampaign',
        'placeCampaignIds',
        'sqlDateBegin',
        'sqlDateEnd',
        ]),
      selectableTabs() {
        const list = [
          {
            text: this.$i18n.t('ranking'),
            value: 'rankings',
            show: this.hasAccessToNewPlatformRankingTab,
          },
          {
            text: this.$i18n.t('components.dashboardPage.sidebar.feedbacks.title'),
            value: 'answers',
            show: this.hasAccessToNewPlatformFeedbackTab,
          },
          {
            text: this.$i18n.t('insatisfactions'),
            value: 'insatisfactions',
            show: this.hasAccessToNewPlatformFeedbackTab,
          },
          {
            text: this.$i18n.t('components.dashboardPage.sidebar.conversations.title'),
            value: 'conversations',
            show: this.hasAccessToNewPlatformFeedbackTab,
          },
          {
            text: this.$i18n.t('optouts'),
            value: 'optouts',
            show: true,
          },
          {
            text: this.$i18n.t('products'),
            value: 'products',
            show: this.currentDashboardMonoCampaign.hasProductQuestion,
          }].filter(item => item.show)
        return list
      },

    },
  }
</script>
