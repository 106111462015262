<template>
  <div
    class="other-voters-navigation d-flex ga-1"
    v-if="voterMixin_currentVoterOtherVotersUniqueIds.length > 1"
  >
    <v-icon
      :disabled="currentVoterIndex <= 0"
      class="f-18 c-lightgrey"
      @click="previousVoter"
    >
      mdi-chevron-left
    </v-icon>
    <span class="fb-13 c-primary">{{ `${$t('review')} ${displayedCurrentVoterIndex}/${voterMixin_currentVoterOtherVotersUniqueIds.length}`}}</span>
    <v-icon
      :disabled="currentVoterIndex >= voterMixin_currentVoterOtherVotersUniqueIds.length - 1"
      class="f-18 c-lightgrey"
      @click="nextVoter"
    >
      mdi-chevron-right
    </v-icon>
  </div>
</template>

<script>
import VoterMixin from '../../voter_mixin'
import Voter from './Voter'

export default {
  name: 'OtherVoters',
  components: {
    Voter
  },
  mixins: [
    VoterMixin,
  ],
  computed: {
    displayedCurrentVoterIndex() {
      return this.currentVoterIndex + 1
    },
    currentVoterIndex() {
      return this.voterMixin_currentVoterOtherVotersUniqueIds.findIndex(uniqueId => this.voter.uniqueId === uniqueId)
    }
  },
  methods: {
    previousVoter() {
      this.voterMixin_goToVoter(this.voterMixin_currentVoterOtherVotersUniqueIds[this.currentVoterIndex - 1])
    },
    nextVoter() {
      this.voterMixin_goToVoter(this.voterMixin_currentVoterOtherVotersUniqueIds[this.currentVoterIndex + 1])
    }
  },
  asyncComputed: {
    voters: {
      async get() {
        this.loading = true

        const voters = await this.voterMixin_currentVoterPaginatedOtherVoters(this.pageSize, (this.page - 1) * this.pageSize)

        this.loading = false
        return voters
      }
    }
  }
}
</script>

<style lang="stylus">
</style>
