<template>
  <v-card height="800px" class="places ma-4">
    <v-card-title class="d-flex">
      <h4 class="f-16 c-primary">
        <span>{{ currentLexicon.translate('places', 'places') }}</span>
        <span v-if="!loading">{{ `(${places.length})` }}</span>
      </h4>
      <div
        v-if="!loading"
        class="d-flex align-center ml-auto ga-1">
        <v-select
          class="f-13"
          color="primary"
          outlined
          hide-details
          v-model="placeStatus"
          :items="placeAvailableStatus"
          :label="$t('google_brand_place_status')"
          dense
          :menu-props="{ offsetY: true }"
        >
          <template v-slot:selection="{ item }">
            <div v-if="placeStatus !== null" class="d-flex align-center ga-1">
              <v-icon
                v-if="item.icon"
                small
                :class="item.iconClass"
              >
                {{ item.icon }}
              </v-icon>
              <span class="f-13">{{ item.text }}</span>
            </div>
            <span v-else>
              {{ $t('google_brand_place_status') }}
            </span>
          </template>
          <template v-slot:item="{ item }">
            <div
              class="d-flex align-center ga-1"
            >
              <v-icon
                v-if="item.icon"
                small
                :class="item.iconClass"
              >
                {{ item.icon }}
              </v-icon>
              <span class="f-13">{{ item.text }}</span>
            </div>

          </template>
        </v-select>
        <v-btn
          icon
          @click="exportPlaces"
        >
          <v-icon class="f-20 c-primary">mdi-download</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-show="!loading"
        :headers="headers"
        :items="displayedPlaces"
        mobile-breakpoint="0"
        class="v-datatable-without-y-borders"
        :sort-by="['name']"
        :sort-desc="[true]"
        fixed-header
        height="700px"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item.name="{ item }">
          <div class="d-flex align-center ga-2">
            <v-icon small
              class="c-gradient-green-start"
              v-if="item.status === 'assigned'"
            >
              mdi-circle
            </v-icon>
            <v-icon small class="c-red" v-else>mdi-circle</v-icon>
            <span
              class="fb-14"
            >
              {{ item.name }}
              <span
                class="f-14"
              >
                {{ `(${item.aliases})` }}
              </span>
            </span>
          </div>
        </template>

        <template v-slot:item.googleBrandPlaceGoogleName="{ item }">
          <div class="d-flex align-center justify-center ga-1">
            <div v-if="item.id === editingPlaceId" class="d-flex align-center ga-1">
              <v-text-field
                v-model="editedGoogleName"
                dense
                outlined
                hide-details
              ></v-text-field>
              <v-icon
                class="f-14 c-gradient-green-start"
                @click="savePlace(item)"
              >
                  mdi-check
              </v-icon>
              <v-icon
                class="f-14 c-red"
                @click="cancelPlaceEditing"
              >
                  mdi-close
              </v-icon>
            </div>
            <div class="d-flex align-center ga-1" v-else>
              <span
                v-if="item.googleBrandPlaceGoogleName"
                class="f-12"
              >
                {{ item.googleBrandPlaceGoogleName }}
              </span>
              <v-icon
                class="f-14"
                @click="editPlace(item)"
              >
                mdi-pencil
              </v-icon>
            </div>
          </div>
        </template>

        <template v-slot:item.nbReviews="{ item }">
          <div class="d-flex align-center justify-center ga-1" v-if="item.googleBrandPlaceGoogleName">
            <span class="f-12">
              {{ item.nbReviews }}
            </span>
            <a
              :href="item.googleBrandPlaceUrl"
              class="link"
              target="_blank"
            >
              <v-icon
                class="f-14"
              >
                mdi-open-in-new
              </v-icon>
            </a>
          </div>
        </template>

        <template v-slot:item.googleBrandAccountHasNotificationsEnabled="{ item }">
          <div v-if="item.googleBrandPlaceGoogleName">
            <div
              class="d-flex justify-center align-center ga-1"
              v-if="item.googleBrandAccountHasNotificationsEnabled"
            >
              <v-icon
                small
                class="c-gradient-green-start"
              >
                mdi-check-circle-outline
              </v-icon>
              <span class="text-no-wrap">{{item.lastNotificationAt}}</span>
            </div>
            <v-icon small class="c-red" v-else>mdi-close-circle-outline</v-icon>
          </div>
        </template>

        <template v-slot:item.fetchedReviewsAt="{item}">
          <div class="d-flex justify-center align-center ga-1" v-if="item.googleBrandPlaceGoogleName">
            <span> {{ item.fetchedReviewsAt }}</span>
            <v-icon class="f-14" @click="refreshReviews(item)">
              mdi-refresh
            </v-icon>
          </div>
        </template>
      </v-data-table>
      <v-skeleton-loader v-show="loading" type="paragraph" />
    </v-card-text>
  </v-card>
</template>
<script>
  import dayjs from 'dayjs'
  import _uniq from 'lodash/uniq'
  import { mapGetters } from 'vuex'

  import exportToExcel from '@shared/helpers/export-to-excel.js'

  export default {
    name: "PlacesTable",
    data() {
      return {
        loading: true,
        placeStatus: null,
        editingPlaceId: null,
        editedGoogleName: null
      }
    },
    computed: {
      ...mapGetters([
        'currentUser',
        'currentLexicon'
      ]),
      placeAvailableStatus() {
        let placeAvailableStatus = [
          {
            value: null,
            text: this.$t('google_brand_place_status_clear'),
            icon: 'mdi-close',
            iconClass: 'c-grey',
            displayCondition: this.placeStatus !== null
          },
          {
            value: 'assigned',
            text: `${this.currentLexicon.translate('places', 'places')} ${this.$t('google_brand_place_status_assigned')} (${this.assignedPlaceCount})`,
            icon: 'mdi-circle',
            iconClass: 'c-gradient-green-start'
          },
          {
            value: 'unasigned',
            text: `${this.currentLexicon.translate('places', 'places')} ${this.$t('google_brand_place_status_unasigned')} (${this.notAssignedPlaceCount})`,
            icon: 'mdi-circle',
            iconClass: 'c-red'
          }
        ]

        return placeAvailableStatus.filter((status) => status.displayCondition === undefined || status.displayCondition)
      },
      assignedPlaceCount() {
        return this.assignedPlaces.length
      },
      notAssignedPlaceCount() {
        return this.notAssignedPlaces.length
      },
      assignedPlaces() {
        return this.places.filter(place => place?.status === 'assigned')
      },
      notAssignedPlaces() {
        return this.places.filter(place => place.status === 'unasigned')
      },
      displayedPlaces() {
        if (this.placeStatus === 'assigned') {
          return this.assignedPlaces
        }
        if (this.placeStatus === 'unasigned') {
          return this.notAssignedPlaces
        }

        return this.places
      },
      headers() {
        return [
          {
            text: this.$t('place_name'),
            value: 'name',
            sortable: false
          },
          {
            text: this.$t('google_brand_place_google_name'),
            value: 'googleBrandPlaceGoogleName',
            align: 'center',
            sortable: false,
            cellClass: 'f-12'
          },
          {
            text: this.$t('google_brand_account_account_name'),
            value: 'googleBrandAccountName',
            sortable: false,
            cellClass: 'f-12'
          },
          {
            text: this.$t('place_nb_reviews'),
            value: 'nbReviews',
            align: 'center',
            sortable: false,
            cellClass: 'f-12'
          },
          {
            text: this.$t('google_brand_account_has_notifications_enabled'),
            value: 'googleBrandAccountHasNotificationsEnabled',
            align: 'center',
            sortable: false,
            cellClass: 'f-12'
          },
          {
            text: this.$t('google_brand_account_fetched_reviews_at'),
            value: 'fetchedReviewsAt',
            sortable: false,
            cellClass: 'f-12'
          },
          {
            text: this.$t('unasigned_google_brand_place_unasigned_reason'),
            value: 'unasignedReason',
            sortable: false,
            cellClass: 'f-12'
          }
        ]
      }
    },
    asyncComputed: {
      places: {
        default: [],
        async get() {
          this.loading = true

          let req = this.$basedRequest().select({
            place_campaigns: [
              { 'place_id': { as: 'id' } },
              { 'MAX_places_name': { as: 'name' } },
              { 'COUNT_DISTINCT_lj_voters_id': { as: 'nbReviews' } }
            ]
          }).where({
            'brands_id': this.currentUser.brandId,
          }).where({
            'google_preferences_mark_question_id': {'is_not_null':{} },
          }).where({
            'google_preferences_free_question_id': {'is_not_null':{} },
          }).group(
            ['place_id']
          )

          let result = (await this.$resolve(req))?.data || []

          const aliasesByPlaceIds = await this.aliasesByPlaceIds()
          const googleBrandPlaces = await this.googleBrandPlaces()
          const unasignedGoogleBrandPlaces = await this.unasignedGoogleBrandPlaces()

          result = Object.values(result).map(place => {
            const googleBrandPlace = googleBrandPlaces.find((googleBrandPlace) => googleBrandPlace.placeId === place.id)
            const unasignedGoogleBrandPlace = unasignedGoogleBrandPlaces.find((unasignedGoogleBrandPlace) => unasignedGoogleBrandPlace.placeId === place.id)
            place.aliases = _uniq(aliasesByPlaceIds[place.id]?.aliases || []).join(',')
            place.status = googleBrandPlace ? 'assigned' : 'unasigned'
            place.lastNotificationAt = null
            place.fetchedReviewsAt = null
            place.unasignedReason = 'no_match'

            let displayedGoogleBrandPlace = googleBrandPlace || unasignedGoogleBrandPlace

            if (displayedGoogleBrandPlace) {
              place.googleBrandPlaceGoogleName = displayedGoogleBrandPlace.googleName
              place.googleBrandPlaceId = displayedGoogleBrandPlace.id
              place.googleBrandAccountName = displayedGoogleBrandPlace.googleBrandAccountName
              place.googleBrandPlaceUrl = displayedGoogleBrandPlace.url
              place.googleBrandAccountHasNotificationsEnabled = displayedGoogleBrandPlace.googleBrandAccountHasNotificationsEnabled
              if (displayedGoogleBrandPlace.lastNotificationAt) {
                place.lastNotificationAt = dayjs(
                  displayedGoogleBrandPlace.lastNotificationAt
                ).format(
                  'DD-MM-YYYY HH:mm:ss'
                )
              }
              if (displayedGoogleBrandPlace.fetchedReviewsAt) {
                place.fetchedReviewsAt = dayjs(
                  displayedGoogleBrandPlace.fetchedReviewsAt
                ).format(
                  'DD-MM-YYYY HH:mm:ss'
                )
              }
              place.unasignedReason = displayedGoogleBrandPlace.unasignedReason

              return place
            }
            return place
          })

          this.loading = false

          return result
        }
      }
    },
    methods: {
      _uniq,
      editPlace(place) {
        this.editingPlaceId = place.id
        this.editedGoogleName = place.googleBrandPlaceGoogleName
      },
      cancelPlaceEditing() {
        this.editingPlaceId = null
      },
      async savePlace(place) {
        try {
          const response = await this.$api.wizville.places.update(
            place.id,
            {
              google_name: this.editedGoogleName
            }
          )

          if (response.status === 'ok') {
            this.$store.dispatch('notifySuccess', {
              message: response.message,
              timeout: 5000,
            })

            location.reload()
          } else {
            this.notifyError(response.message)
          }
        } catch (error) {
          this.notifyError(error.message)
        }
      },
      async googleBrandPlaces() {
        let req = this.$basedRequest().select({
          google_brand_places: [
            { 'place_id': { as: 'placeId' } },
            { 'google_name': { as: 'googleName' } },
            { 'google_brand_accounts_account_name': { as: 'googleBrandAccountName' } },
            { 'url': { as: 'url' } },
            { 'google_brand_accounts_has_notifications_enabled': { as: 'googleBrandAccountHasNotificationsEnabled' } },
            { 'fetched_reviews_at': { as: 'fetchedReviewsAt' } },
            { 'last_notification_at': { as: 'lastNotificationAt' } },
            'id',
          ]
        }).where({
          'brands_id': this.currentUser.brandId,
        })

        const result = (await this.$resolve(req))?.data?.googleBrandPlaces || []

        return result
      },
      async unasignedGoogleBrandPlaces() {
        let req = this.$basedRequest().select({
          unasigned_google_brand_places: [
            { 'place_id': { as: 'placeId' } },
            { 'google_name': { as: 'googleName' } },
            { 'google_brand_accounts_account_name': { as: 'googleBrandAccountName' } },
            { 'url': { as: 'url' } },
            { 'unasigned_reason': { as: 'unasignedReason' } }
          ]
        }).where({
          'brands_id': this.currentUser.brandId,
        })

        const result = (await this.$resolve(req))?.data?.unasignedGoogleBrandPlaces || []

        return result
      },
      exportPlaces() {
        if (this.places && this.places.length) {
          const formatedData = this.places.map((place) => {
            const { id, status, ...filteredPlace } = place

            filteredPlace.status = place.status === 'assigned' ?
              this.$t('google_brand_place_status_assigned') :
              this.$t('google_brand_place_status_unasigned')

            return filteredPlace
          })

          const headers = Object.keys(formatedData[0]).map((key, index) =>
            this.$t(`public_platform_google_places_export_${key}`)
          )

          let sheetName = this.currentLexicon.translate('places', 'places')
          let fileName = `${dayjs().locale(this.$i18n.locale).format('LL LTS')}_${sheetName}`

          exportToExcel(fileName, sheetName, formatedData, headers)
        }
      },
      async aliasesByPlaceIds() {
        let req = this.$basedRequest().select({
          place_campaigns: [
            { 'places_id': { as: 'id' } },
            { 'ARRAY_AGG_place_aliases_alias': { as: 'aliases'} },
          ]
        }).where({
          'brands_id': this.currentUser.brandId,
        }).where({
          'google_preferences_mark_question_id': {'is_not_null':{} },
        }).where({
          'google_preferences_free_question_id': {'is_not_null':{} },
        }).group(
          ['places_id']
        )
        // req = req.where({
        //   name_search: { 'search': this.search }
        // })
        const result = (await this.$resolve(req))?.data || []

        return result
      },
      async refreshReviews(item) {
        try {
          const response = await this.$api.wizville.googleMyBusiness.refreshGoogleBrandPlaceReviews(item.googleBrandPlaceId)
          if (response.status == "ok") {
            const successMessage = this.$t('google_my_business_refresh_launched');
            this.$store.dispatch('notifySuccess', {
              message: successMessage,
              timeout: 5000,
            })
          } else {
            this.notifyError(response.message)
          }
        } catch (error) {
          this.notifyError(error.message)
        }
      }
    }
  }
</script>
