<template>
  <div class="themes-table">
    <v-data-table
      class="v-datatable-no-group-color c-primary"
      :headers="headers"
      :items="formatedTopics"
      :group-by="groupBy === 'themes' ? 'themePosition' : null"
      :options.sync="options"
      :item-class="topicClass"
      @click:row="onRowClick"
      disable-pagination
      hide-default-footer
      mobileBreakpoint="0"
      ref="table"
    >
      <template #group.header="{ group, headers, toggle, isOpen }">
        <td @click="toggle" class="text-align-left pointer d-flex align-center" vertical-align="middle" :class="{ active: zoomedData?.type == 'theme' && zoomedData?.id == themeBy[group].id }">
          <v-btn class="v-btn-square" :icon="zoomedData?.id != themeBy[group].id" :color="zoomedData?.id != themeBy[group].id ? 'primary' : 'secondary'" @click="$emit('zoomOnTheme', themeBy[group])">
            <v-icon small>mdi-eye-outline</v-icon>
          </v-btn>
         
          <div class="nowrap f-13">
            <div class="d-flex align-center">
              <div>
                <v-btn icon :ref="group" style="position: relative; bottom: 1px" :ripple="false">
                  <v-icon small v-if="isOpen">mdi-chevron-up</v-icon>
                  <v-icon small v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </div>
              <div class="lh-15 pl-2">
                <span>
                  <span class="fs-13">{{ themeBy[group].name }}</span>
                  <span class="pl-1 f-13">{{ themeBy[group].topics.length }} {{ (themeBy[group].topics.length > 1 ? $t('topics') : $t('topic')).toLowerCase() }}</span>
                </span>
              </div>
            </div>
          </div>
        </td>
        
        <td class="text-align-left pointer" @click="toggle" :class="{ active: zoomedData?.type == 'theme' && zoomedData?.id == themeBy[group].id }">
          <w-icon icon="smiley-happy" class="f-18 mr-1"/>
          <span v-if="themeBy[group].feelingScore < 1" class="fb-15 c-primary top_1px">{{ "<1%" }}</span>
          <span v-else class="fb-15 c-primary top_1px">{{ themeBy[group].feelingScore | round }}%</span>
          <w-color-sign :value="themeBy[group].feelingScoreEvolution" class="pl-1 bottom_1px" :decimals="0"/>
        </td>

        <td class="text-align-left pointer" @click="toggle" :class="{ active: zoomedData?.type == 'theme' && zoomedData?.id == themeBy[group].id }">
          <v-tooltip top transition="none">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on">
                <v-progress-circular
                  :size="36"
                  :rotate="360"
                  :value="themeBy[group].reviewRate"
                  color="primary"
                >
                  <span v-if="themeBy[group].reviewRate < 1" class="fb-11 c-primary">{{ "<1%" }}</span>
                  <span v-else class="fb-11 c-primary">{{ themeBy[group].reviewRate | round }}%</span>
                </v-progress-circular>
                <w-color-sign v-if="themeBy[group].reviewRateEvolution" :color="$colors.lightgrey" :value="themeBy[group].reviewRateEvolution" class="pl-1" :decimals="1" />
              </span>
            </template>
            <span>{{ themeBy[group].reviewTotal }} <v-icon small>mdi-account</v-icon></span>
          </v-tooltip>
        </td>

        <td class="text-align-left pointer" @click="toggle" :class="{ active: zoomedData?.type == 'theme' && zoomedData?.id == themeBy[group].id }">
          <WRepartitionHorizontalFrieze
            class="flex-shrink-0"
            :nb-promoters="themeBy[group].reviewDistribution.nbPromoters"
            :nb-detractors="themeBy[group].reviewDistribution.nbDetractors"
            :nb-neutrals="themeBy[group].reviewDistribution.nbNeutrals"
            :unit="'%'"
            height="20px"
            min-width="100%"
          />
        </td>
      </template>

      <template #item.topic="{ value, item }">
        <div class="d-flex align-center">
          <div>
            <v-btn class="v-btn-square" :style="{ marginLeft: (groupBy === 'themes' ? '32px' : '0px') }" :icon="zoomedData?.id != item.topic.id" :color="zoomedData?.id != item.topic.id ? 'primary' : 'secondary'" @click="$emit('zoomOnTopic', item.topic)">
              <v-icon small>mdi-eye-outline</v-icon>
            </v-btn>
          </div>
          <div class="lh-15 pl-3">
            <span class="fs-13">
              <span v-if="groupBy === 'topics'" class="pr-1">{{ item.theme.name }}</span>
              <span :class="{ 'f-13': groupBy === 'topics' }">{{ item.topic.name | capitalize }}</span>
            </span>
          </div>
        </div>
      </template>

      <template #item.theme="{ value }">
        <span if="value" class="f-13">{{ value.name }}</span>
      </template>

      <template #item.reviewRate="{ item, value }">
        <v-tooltip top transition="none">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on">
              <v-progress-circular
                :size="36"
                :rotate="360"
                :value="value"
                color="primary"
              >
                <span v-if="value < 1" class="fb-11">{{ "<1%" }}</span>
                <span v-else class="fs-11">{{ value | round }}%</span>
              </v-progress-circular>
              <w-color-sign v-if="item.reviewRateEvolution" :color="$colors.lightgrey" :value="item.reviewRateEvolution" class="pl-1" :decimals="1" />
            </span>
          </template>
          <span>{{ item.reviewTotal }} <v-icon small>mdi-account</v-icon></span>
        </v-tooltip> 
      </template>

      <template #item.feelingScore="{ value, item }">
        <w-icon icon="smiley-happy" class="f-18 mr-1"/>
        <span v-if="value < 1" class="fb-15 c-primary top_1px">{{ "<1%" }}</span>
        <span v-else class="fb-15 c-primary top_1px">{{ value | round }}%</span>
        <w-color-sign :value="item.feelingScoreEvolution" class="pl-1 bottom_1px" :decimals="0"/>
      </template>

      <template #item.feelingRepartition="{ item }"> 
        <WRepartitionHorizontalFrieze
          :nb-promoters="item.reviewDistribution.nbPromoters"
          :nb-detractors="item.reviewDistribution.nbDetractors"
          :nb-neutrals="item.reviewDistribution.nbNeutrals"
          :unit="'%'"
          height="20px"
          min-width="100%"
        />
      </template>

      <template #item.zoom="{ item }"> 
        <v-btn class="v-btn-square" :small="zoomedData?.id == item.topic.id" :icon="zoomedData?.id != item.topic.id" color="secondary" @click="$emit('zoomOnTopic', item.topic)">
          <v-icon small>mdi-eye-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>  
  </div>
</template>

<script>
  import _sortBy from 'lodash/sortBy'

  export default {
    name: "ThemesTable",
    props: [ "topics", "themeById", 'campaign', 'zoomedData', 'groupBy' ],

    data() {
      return {
        options: { sortBy: [], sortDesc: [] },
        groupByTheme: true,
        sortBy: null,
        sortDesc: false
      }
    },
    computed: {
      headers() {
        let headers = [
          {
            text: (this.groupBy == 'topics' ? this.$t('topics') : this.$t('themes')),
            align: 'start',
            value: 'topic',
            sortable: false,
            width: (this.$vuetify.breakpoint.smAndUp ? "33%" : "auto" )
          },
          { text: this.$t('positive_feeling'), value: 'feelingScore', sortable: true, width: "140px" },
          { text: this.$t('part_of_reviews'), value: 'reviewRate', sortable: true, width: "120px" },
          { text: this.$t('feeling'), value: 'feelingRepartition', sortable: false  },
        ]
        
        return headers.filter((header) => header.displayCondition === undefined || header.displayCondition)
      },
      themeBy() {
        const themeBy = {}
        let sortedThemes = []

        if (this.sortBy == 'feelingScore') { 
          sortedThemes = _sortBy(Object.values(this.themeById), (theme) => (theme.feelingScore || 0))
        } else {
          sortedThemes = _sortBy(Object.values(this.themeById), (theme) => -(theme.reviewTotal || 0))
        }

        if (this.sortDesc) {
          sortedThemes = sortedThemes.reverse()
        }

        sortedThemes.forEach((theme, idx) => themeBy[idx] = theme)
        return themeBy
      },
      formatedTopics() {
        if (this.topics) {
          let topics = this.topics
          const sortBy = this.sortBy
          const sortDesc = this.sortDesc
          const themeBy = this.themeBy

          if (this.groupBy === 'themes') {
            topics = topics?.map((item) => ({
              ...item,
              ...{ themePosition: Object.values(themeBy).map((theme) => theme.id).indexOf(item.theme.id)}
            }))
          }

          return topics
        }
      }
    },
    methods: {
      topicClass(topic) {
        const isActive = (this.zoomedData?.type == 'topic' && this.zoomedData?.id == topic?.topic?.id)

        return {
          active: isActive,
          "not-active": !isActive
        }
      },
      onRowClick(topic) {
        this.$emit('zoomOnTopic', topic.topic)
      }
    },
    watch: {
      options: {
        handler (newOptions) {
          this.sortBy = newOptions.sortBy[0]
          this.sortDesc = newOptions.sortDesc[0]
        },
        deep: true,
      }
    },
    mounted () {
      let table = this.$refs.table;
      let keys = Object.keys(table.$vnode.componentInstance.openCache)

      keys.forEach((x, index) => {
        table.$vnode.componentInstance.openCache[x] = (this.zoomedData?.type == 'topic' && this.zoomedData.themeFeelingRank === index ? true : false)
      })
    }
  }
</script>

<style lang="stylus">
  .themes-table
    .v-datatable-no-group-color
      .not-active
        background-color: #F7F9FA !important;
        cursor: pointer
      .active
        background-color: #E4F7F5 !important;
        cursor: pointer
</style>
