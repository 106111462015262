<template>
  <div class="nis-menus">
      <template
        v-for="item in items"
      >
        <v-list-group
          v-if="item.childs"
          :key="item.title"
          :value="item.opened"
          color="grey"
          :prepend-icon="item.icon"
          :ripple="false"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <WSideBarListItem
            v-for="child in item.childs"
            :key="child.title"
            :item="child"
          />
        </v-list-group>

        <WSideBarListItem
          v-else
          :key="item.title"
          :item="item"
        />
      </template>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"

  export default {
    name: "CampaignSettingsMenu",
    components: {
    },
    data() {
      return {
        initialRouteName: this.$route.name
      }
    },
    computed: {
      ...mapGetters(
        [
          'currentCampaignPreferencesCampaignId',
          'currentDashboardType',
          'currentUserIsAdmin',
          'currentCampaignPreferencesCampaignIsProduct'
        ]
      ),
      items() {
        let items = [
          {
            icon: "mdi-bank-outline",
            notifications: 0,
            title: "Smart benchmark",
            show: this.currentUserIsAdmin,
            to: { name: 'SmartBenchmarkPreferences', params: { campaignId: this.currentCampaignPreferencesCampaignId } }
          },
          {
            icon: "mdi-bank-outline",
            notifications: 0,
            title: this.$t('topicNisTableWidgetNis'),
            show: this.currentUserIsAdmin,
            to: { name: "NetImpactScore", params: { dashboardType: this.currentDashboardType} }
          },
          {
            icon: "mdi-bank-outline",
            notifications: 0,
            title: this.$t('dashboard_sidebar_achievements'),
            show: this.currentUserIsAdmin,
            to: { name: "Achievement", params: { dashboardType: this.currentDashboardType} }
          },
          {
            icon: "mdi-bank-outline",
            notifications: 0,
            title: this.$t('text_analysis_preference'),
            show: this.currentUserIsAdmin,
            to: { name: "TextAnalysisPreference", params: { campaignId: this.currentCampaignPreferencesCampaignId } }
          },
          {
            icon: "mdi-bank-outline",
            notifications: 0,
            title: this.$t('dashboard_sidebar_conversation_pre_filled_message_preference'),
            show: this.currentUserIsAdmin,
            to: { name: "AutomatedMessages", params: { campaignId: this.currentCampaignPreferencesCampaignId } }
          },
          {
            icon: "mdi-bank-outline",
            notifications: 0,
            title: this.$t('dashboard_sidebar_smart_answers'),
            show: this.currentUserIsAdmin,
            to: { name: 'SmartAnswers', params: { campaignId: this.currentCampaignPreferencesCampaignId } }
          },
          {
            icon: "mdi-cart-outline",
            notifications: 0,
            title: this.$t('product_settings_sidebar_title'),
            show: (this.currentUserIsAdmin && this.currentCampaignPreferencesCampaignIsProduct),
            to: { name: "ProductImportPreference", params: {
              page: 'settings', campaignId: this.currentCampaignPreferencesCampaignId
            }}
          },
          {
            icon: "mdi-bank-outline",
            notifications: 0,
            title: this.$t('conversation_prefilled_messages_table_headers_translations'),
            show: this.currentUserIsAdmin,
            to: { name: "CampaignTranslations", params: {
              campaignId: this.currentCampaignPreferencesCampaignId
            }}
          },
        ]
        return items
      },
    },
  }
</script>

<style lang="stylus">
  @import '~@theme/colors.styl'

  .nis-menus
    .v-list-item__icon:first-child
      margin-right: 4px !important

    .v-list-item__icon
      .v-icon
        margin-top: 0px !important
        margin-left: 3px
        font-size: 15px

    .active-item
      .v-list-item__icon i, .v-list-item__title
        color: var(--info) !important
</style>
