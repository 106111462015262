<template>
  <WStatsWidget
    :cols="12"
    :empty="empty"
    :help="isLoading ? '' : help"
    :loading="isLoading"
    :subtitle="defaultSubtitle"
    :title="title"
    contentPadding="0.75em"
    contentWidth="100%"
    height="200px"
  >
    <template #content>
      <div class="d-flex flex-column ga-4">
        <div class="d-flex align-center justify-center ga-2">
          <span class="f-22 lh-22">
            {{ emoji }}
          </span>
          <span class="fb-22 lh-22 c-primary">
            {{`+${toEarn} %`}}
          </span>
        </div>
        <div class="d-flex align-center ">
          <span class="text-uppercase f-11 c-lightgrey">
            {{ $t('nis_goal_title')}}
          </span>
          <div class="flex-grow-1" />
          <router-link
            v-if="linkTo"
            :to="linkTo"
          >
            <v-icon
              small
              class="c-primary"
            >
              mdi-chevron-right
            </v-icon>
          </router-link>
        </div>
      </div>
      <v-chip
        label
        class="bg-bodygrey full-width justify-center mt-1"
      >
        <NisSatisfactionGoalCell
          :nisItem="voterProportions"
        />
      </v-chip>
    </template>
  </WStatsWidget>
</template>

<script>
  import NisSatisfactionGoalCell from '@statistics/shared/Table/NisSatisfactionGoalCell'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import NisMixin from '@statistics/shared/nis_mixin'

  export default {
    name: 'TransactionalUnitToEarnWidget',
    components: {
      NisSatisfactionGoalCell
    },
    mixins: [
      WidgetMixin,
      NisMixin
    ],
    props: {
      campaign: { type: Object },
      linkTo: { type: Object },
      summary: { type: Object },
      topic: { type: Object },
      voterProportions: { type: Object },
    },
    computed: {
      empty() {
        return !this.isLoading && (
          !this.voterProportions ||
          !this.summary ||
          isNaN(this.toEarn) ||
          this.toEarn < 0.1
        )
      },
      isLoading() {
        return this.campaign === undefined || this.voterProportions === undefined
      },
      toEarn() {
        return this.nisMixin_transactionalUnitToEarn(this.voterProportions, this.summary)
      },
      goToTopic() {
        topicsMixin_goToTopic(this.campaign, this.topic, 'topics')
      },
      topCompetitorsBasedOn() {
        return this.$t(
          'nis_top_competitors_based_on',
        )
      },
      emoji() {
        return this.nisMixin_transactionalUnit([this.campaign]).emoji
      },
      title() {
        return `${this.$t('transactional_unit_to_earn_widget_title')}`
      },
      help() {
        return this.nisMixin_opportunityHelpMessage([this.campaign])
      }
    }
  }
</script>
