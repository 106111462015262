<template>
  <div class="verbatim-text-analysis-info d-flex align-center c-grey f-12">
    <div
      class="d-flex align-center ga-1"
      v-if="showProcessedAtSection"
    >
      <v-tooltip
        class="d-flex align-center ga-1"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex align-center ga-1 cursor-pointer" v-on="on">
            <v-icon class="f-12">mdi-robot-outline</v-icon>
            <span>{{ processedDateFromNow }}</span>
          </div>
        </template>
        <span>
         {{ processedDate }}
        </span>
      </v-tooltip>
    </div>
    <div
      class="d-flex align-center ga-1"
      v-if="showReclassifiedSection"
    >
      <v-icon class="f-12">mdi-account-check-outline</v-icon>
      <span>{{ $t('verbatim_text_analysis_info_verbatim_reclassified') }}</span>
    </div>
  </div>
</template>

<script>
  import dayjs from 'dayjs'

  export default {
    name: "VerbatimTextAnalysisInfo",
    components: {
    },
    props: {
      processedAt: {
        required: false
      },
      isReclassified: {
        required: false,
        default: false
      }
    },
    data () {
      return {
      }
    },
    computed: {
      showProcessedAtSection() {
        return this.processedAt !== undefined
      },
      showReclassifiedSection() {
        return this.isReclassified
      },
      processedDateFromNow() {
        return this.$helpers.dates.complete(
          dayjs(this.processedAt)
        )
      },
      processedDate() {
        return dayjs(
          this.processedAt
        ).locale(
          this.$i18n.locale
        ).format(
          "DD MMMM YYYY"
        )
      }
    }
  }
</script>

<style lang="stylus">
</style>
