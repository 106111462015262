<template>
  <v-row class="nis-page" no-gutters>
    <v-col>
      <v-row
        v-if="nisMixin_eligibleCampaigns.length > 1"
        no-gutters
        class="px-3 mb-3"
      >
        <v-col cols="12">
          <StatisticsCampaignAutocomplete
            class="d-flex"
            :campaignId="campaignId"
            :eligibleCampaigns="nisMixin_eligibleCampaigns"
          />
        </v-col>
      </v-row>

      <template v-if="campaign">
        <v-row no-gutters>
          <v-col>
            <TopicsRecommendationsTableWidget
              v-if="campaign && campaign.topics.length > 1"
              :key="nisMixin_key"
              :load="loadData()"
              :campaign="campaign"
              :topics="topicsMixin_topics[campaign.id]"
              :selectedTopic="topic"
              :cols="12"
              class="pt-0"
              maxHeight="300px"
              minHeight="300px"
              friezeWidth="300px"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <NisZoom
              v-if="displayNisZoom"
              :key="topic?.id"
              :load="loadData()"
              :campaign="campaign"
              :topic="topic"
              :nisTopicSummary="nisTopicSummary"
            />
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import StatisticsCampaignAutocomplete from '../shared/StatisticsCampaignAutocomplete'
  import TopicsRecommendationsTableWidget from './TopicsRecommendationsTableWidget'
  import NisZoom from './NisZoom'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import NisMixin from '@statistics/shared/nis_mixin'
  import TopicsMixin from '@statistics/shared/topics_mixin'

  export default {
    name: "NisPage",
    props: {
      campaignId: { type: Number, required: true },
      selectedTopicId: { type: Number | String }
    },
    mixins: [
      WidgetMixin,
      NisMixin,
      TopicsMixin,
    ],
    components: {
      StatisticsCampaignAutocomplete,
      TopicsRecommendationsTableWidget,
      NisZoom,
    },
    data() {
      return {
        filterConditionsCampaigns: {
          hasAvgScore: true, withSource: "wizville", withTopics: true
        }
      }
    },
    methods: {
      loadData() {
        this.topicsMixin_loadTopics(this.campaign)
      }
    },
    computed: {
      ...mapGetters([
        'currentDashboardCampaignWithId',
        'currentDashboardScopedCampaigns'
      ]),
      displayNisZoom() {
        return this.nisTopicSummary &&
          this.topic?.nisOpportunity > 0
      },
      campaign() {
        return this.currentDashboardCampaignWithId(this.campaignId)
      },
      resolvedSelectedTopicId() {
        if (!this.topicsMixin_topics[this.campaign.id]) {
          return undefined
        }
        if (this.selectedTopicId == 'default') {
          const sortedTopics =
            this.topicsMixin_topics[this.campaign.id].filter(
              topic => topic.nisRoiSatisfaction > 0 && topic.nisOpportunity > 0
            ).sort(
              (a, b) => b.nisRoiSatisfaction - a.nisRoiSatisfaction
            )

          return sortedTopics[0]?.id || undefined
        }
        return Number(this.selectedTopicId)
      },
      topic() {
        this.resolvedSelectedTopicId

        return this.topicsMixin_topics[this.campaign.id] &&
               this.topicsMixin_topics[this.campaign.id].find(
                 topic => {
                  return topic.id === this.resolvedSelectedTopicId
                 }
               )
      },
      nisTopicSummary() {
        return this.topicsMixin_topics[this.campaign.id] &&
               this.nisMixin_topicSummaries(this.campaign)[this.resolvedSelectedTopicId]
      }
    }
  }
</script>
