<template>
  <div class="reannotation">
    <h4 class="f-16 mb-4 c-primary">Réannotations</h4>
    <ReannotationForm @submited="nbReannotation = nbReannotation + 1" />
    <ReannotationTable :key="`reannotation_table_${nbReannotation}`" :nbReannotation="nbReannotation" />
  </div>
</template>

<script>
  import ReannotationForm from "./ReannotationForm"
  import ReannotationTable from "./ReannotationTable"

  export default {
    name: 'Reannotation',
    data() {
      return {
        nbReannotation: 0
      }
    },
    components: {
      ReannotationForm,
      ReannotationTable
    }
  }
</script>

