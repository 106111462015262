<template>
  <div class="mark-question-answer">
    <WStackedBar
        :height="9"
        :width="$vuetify.breakpoint.mobile ? 250 : 350"
        :values="[
          { width: scorePercent, backgroundColor: scoreColor},
          { width: (100 - scorePercent), backgroundColor: '#EDF1F6'},
        ]"
        class="float-left"
      />

    <span :style="{ color: scoreColor }" class="font-weight-bold pl-2 nowrap">
      {{ score }}
    </span>

    <div class="f-14" v-for="questionCommentAnswer of questionCommentAnswers" :key="`questionCommentAnswers${questionCommentAnswer.id}`">
      <WQuote
        class="mt-3"
        :content="questionCommentAnswer.text"
      />
    </div>
    <VoterPictures v-if="pictures" :pictures="pictures"/>
  </div>
</template>

<script>
  import VoterPictures from "./VoterPictures";
  export default {
    name: "MarkQuestionAnswer",
    components: {
      VoterPictures
    },
    props: {
      answer: { required: true },
      question: { required: true },
      pictures: { required: false }
    },
    computed: {
      questionCommentAnswers() {
        return this.answer?.questionCommentAnswers?.filter(q => q?.text?.length > 0) || []
      },
      score() {
        return this.answer?.markQuestionLabel?.score
      },
      scale() {
        const nbLabel = this.question.markQuestionLabels.length

        if ([5, 6].includes(nbLabel)) {
          return 5
        } else if ([10, 11].includes(nbLabel)) {
          return 10
        } else {
          return 10
        }
      },
      scoreColor() {
        const score = this.answer?.markQuestionLabel?.score

        if (score !== undefined) {
          if (score <= (this.scale === 5 ?  3 : 7)) {
            return "#C86868"
          } else if (score <= (this.scale === 5 ?  4 : 8)) {
            return "#95ADB6"
          } else if (score <= (this.scale === 5 ?  5 : 10)) {
            return "#65C095"
          }
        } else {
          return "#EDF1F6"
        }
      },
      scorePercent() {
        const score =  this.answer?.markQuestionLabel?.score

        if (score !== undefined) {
          return (score === 0 ? 1 : score * (this.scale === 5 ? 20 : 10))
        } else {
          return 0
        }
      }
    }
  }
</script>

<style lang='stylus' scoped>

</style>
