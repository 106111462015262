import { mapGetters } from 'vuex'
import VoterMixin from './voter_mixin'

export default {
  mixins: [
    VoterMixin
  ],
  computed: {
    ...mapGetters([
      'currentUser',
      'currentUserId',
      'currentUserNeedValidationForCampaign',
      'currentDashboardCampaignWithId',
      'customerAnswerRightByCampaign',
    ]),
    canAnswerCustomersDirectly() {
      return this.customerAnswerRightByCampaign[this.voter.campaignId] === 'direct'
    },
    canAnswerCustomers() {
      const customerAnswerRight = this.customerAnswerRightByCampaign[this.voter.campaignId]

      return (['direct', 'need_validation'].includes(customerAnswerRight))
    },
    customerHasAvailableChannel() {
      return !!this.voterMixin_customerPriorityChannel
    },
    canAssignInsatisfaction() {
      return this.insatisfactionNotProcessed &&
             !this.insatisfactionTransferedByCurrentUser &&
             this.currentDashboardCampaignWithId(this.voter.campaignId)?.transferEnabled
    },
    canAssignInsatisfactionToThirdParty() {
      return this.canAssignInsatisfaction &&
             this.currentDashboardCampaignWithId(this.voter.campaignId)?.thirdPartyTransferEnabled
    },
    needValidationOnCampaign() {
      return this.currentUserNeedValidationForCampaign(this.voter.campaignId)
    },
  }
}
