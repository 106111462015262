import Vue from 'vue'
import Vuex from 'vuex'
import layout from '@store/layout.js'
import snackbar from '@store/snackbar.js'
import platformContext from '@store/contexts/platformContext.js'
import userContext from '@store/contexts/userContext.js'
import adminContext from '@store/contexts/adminContext.js'
import campaignPreferencesContext from '@store/contexts/campaignPreferencesContext.js'
import dashboardContext from '@store/contexts/dashboardContext.js'
import voterContext from '@store/contexts/voterContext.js'
import surveyContext from '@store/contexts/surveyContext.js'
import nis from '@store/nis.js'
import voterThirdPartyTransferContext from '@store/contexts/voterThirdPartyTransferContext.js'
import events from './events.js'
import groupSemantics from './groupSemantics.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [],

  modules: {
    layout,
    snackbar,
    platformContext,
    userContext,
    adminContext,
    campaignPreferencesContext,
    dashboardContext,
    voterContext,
    surveyContext,
    nis,
    voterThirdPartyTransferContext,
    events,
    groupSemantics
  },
})

export default store
