<template>
  <WStatsWidget
    class="widget-score"
    :title="title"
    :cols="12"
    height="200px"
    contentWidth="100%"
    :loading="!lrmScore"
  >
    <template #subtitle>
      <HeaderSubtitle/>
    </template>

    <template #options>
      <WidgetSettingsMenu
        :competitorScope="competitorScope"
        :competitorMode="competitorMode"
        :periodType="periodType"
        :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreferenceId"
      />
    </template>

    <template #content>
      <WStatsScore
        v-if="lrmScore"
        :key="periodType"
        :score="lrmScore && lrmScore.avgScore"
        :evolution="lrmScore && lrmScore.avgScoreEvolution"
        :infoMessageReviews="lrmScore.rankMessage"
        :infoLink="urlToBenchmark"
        iconInfoReviewColor="c-yellow-darken"
        :displayEvolution="displayEvolution"
        :isFiveScore="true"
        :nbReview="lrmScore && lrmScore.nbReview"
        :decimals="2"
        source="google"
        class="pb-2"
      />
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import WidgetSettingsMenu from '../WidgetSettingsMenu'
  import HeaderSubtitle from './../shared/HeaderSubtitle'

  export default {
    name: "WidgetScore",
    components: {
      WidgetSettingsMenu,
      HeaderSubtitle
    },
    props: {
      periodType: {
        required: true,
        type: String
      },
      competitorMode: {
        required: true,
        type: String
      },
      competitorScope: {
        required: true,
        type: String
      },
      campaignSmartBenchmarkPreferenceId: {
        required: false
      },
      active: {
        required: false
      }
    },
    data() {
      return {
        urlToBenchmark: 'https://wizville.zendesk.com/hc/fr/articles/7909896871196-Benchmark-Avis-Google-R%C3%A8gles-de-collecte-des-avis',
        loading: true
      }
    },
    asyncComputed: {
      lrmScore: {
        async get() {

          const request = this.lrmDashboardFilterRequest.select({
            [this.currentDashboardBasedTable]: [{
              lrm_score: { params: {
                period: this.periodType,
                place_id: this.placeIdsScope,
                lm_place_id: this.active?.id,
                date_begin: this.datesScope.dateBegin,
                date_end: this.datesScope.dateEnd,
                compared_date_begin: this.datesScope.comparedDateBegin,
                compared_date_end: this.datesScope.comparedDateEnd
              } }
            }]
          })

          const lrmScore = (await this.$resolve(request)).first()?.lrmScore

          return lrmScore

        }, default: undefined
      }
    },
    computed: {
      ...mapGetters([
        "currentDashboardBasedTable",
        "lrmDashboardFilterRequest",
        "dashboardFilterDates",
        "dashboardFilterBase64",
        "placeIdsScope",
        "datesScope",
        "isMonoPlace"
      ]),
      displayEvolution() {
        return this.datesScope.key !== 'genesis' && this.lrmScore?.avgScore !== null
      },
      title() {
        const title = this.$t("competitors_widget_average_score_title")

        if (this.isMonoPlace &&
            this.competitorMode === "local" &&
            this.active?.isUserItem === false
          ) {
          return `${title} - ${this.active.name}`
        }

        return title
      }
    }
  }
</script>

<style lang='stylus'>
  @import '~@theme/constants.styl'

  .widget-score-header
    height: $widget-header-height
  .widget-score-main-title
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

</style>
