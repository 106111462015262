<template>
  <WStatsWidget
    :cols="12"
    :justifyCenter="false"
    :loading="loading"
    :title="$t(title)"
    class="ranking-widget"
    contentWidth="100%"
    height="390px"
  >
    <template #subtitle>
      <HeaderSubtitle/>
    </template>

    <template #options>
      <div class="d-flex">
        <WidgetSettingsMenu
          :competitorScope="competitorScope"
          :competitorMode="competitorMode"
          :periodType="periodType"
          :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreference?.id"
        >
        </WidgetSettingsMenu>
        <w-drop-down-menu v-if="!loading" :items="exportItems" icon="mdi-download">
        </w-drop-down-menu>
      </div>
    </template>

    <template #content>
      <widget-ranking-table
        v-if="!loading"
        :active="active"
        :competitorMode="competitorMode"
        :displayEvolution="displayEvolution"
        :isMonoPlace="isMonoPlace"
        :rows="mappedRows"
        :zoomable="zoomable"
        @onRowClick="onRowClick"
      />
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import WidgetRankingTable from './RankingTable'
  import WidgetSettingsMenu from '../WidgetSettingsMenu'
  import HeaderSubtitle from './../shared/HeaderSubtitle'
  import { exportToExcel,formatExcelValues } from '@shared/helpers/export-to-excel.js'
  import Pdf from '@shared/helpers/exportToPdf/pdf'
  import paginateArray from '@shared/helpers/paginate-array'

  export default {
    name: "WidgetRanking",
    components: {
      WidgetRankingTable,
      WidgetSettingsMenu,
      HeaderSubtitle
    },
    props: {
      competitorMode: {
        required: true,
        type: String
      },
      competitorScope: {
        required: true,
        type: String
      },
      periodType: {
        required: true,
        type: String
      },
      campaignSmartBenchmarkPreference: {
        required: false
      },
      lmBrandIds: {
        required: false
      },
      zoomable: {
        required: false,
        type: Boolean,
        default: false
      },
      active: {
        required: false
      }
    },
    data() {
      return {
        dataLoading: true
      }
    },
    asyncComputed: {
      rows: {
        async get() {
          this.dataLoading = true

          const request = this.lrmDashboardFilterRequest.select({
            [this.currentDashboardBasedTable]: [{
              lrm_ranking: { params: {
                period: this.periodType,
                competitor_mode: this.competitorMode,
                competitor_scope: this.competitorScope,
                place_id: this.placeIdsScope,
                date_begin: this.datesScope.dateBegin,
                date_end: this.datesScope.dateEnd,
                compared_date_begin: this.datesScope.comparedDateBegin,
                compared_date_end: this.datesScope.comparedDateEnd,
                competitor_lm_brand_ids: this.lmBrandIds
              }}
            }]
          })

          const data = (await this.$resolve(request)).first()?.lrmRanking || []

          this.dataLoading = false

          return data
        },
        default: null
      }
    },
    computed: {
      ...mapGetters([
        "currentDashboardBasedTable",
        "lrmDashboardFilterRequest",
        "dashboardFilterDates",
        "dashboardFilterScope",
        "placeIdsScope",
        "datesScope",
        "isMonoPlace",
        'currentDashboard',
        'lmGmbPlaceIdsScope'
      ]),
      lmPlaceActiveId() {
        return this.rows.map(item => item.lmPlaceId).includes(this.active?.id) ?
          this.active?.id :
          this.lmGmbPlaceIdsScope[0]
      },
      mappedRows() {
        this.active
        return this.rows.map(row => {
          return this.mapping(row);
        }) || []
      },
      title() {
        if (this.campaignSmartBenchmarkPreference?.enabled) {
          return `${this.$t('competitors_widget_ranking_title')} - ${this.campaignSmartBenchmarkPreference.name}`
        } else {
          return this.$t('competitors_widget_ranking_title')
        }
      },
      exportItems() {
        return [
          { title: 'PDF', onClick: this.exportToPdf },
          { title: 'Excel', onClick: this.exportToExcel }
        ]
      },
      isLocal() {
        return this.competitorMode === 'local';
      },
      isNational() {
        return this.competitorMode === 'national';
      },
      displayEvolution() {
        return this.datesScope.key !== 'genesis'
      },
      loading() {
        return this.dataLoading || this.rows === null
      }
    },
    methods: {
      onRowClick(item) {
        if (this.isLocal && this.isMonoPlace) {
          this.$router.push(
            {
              params: {
                competitorScope: String(item.lmPlaceId)
              }
            }
          )
        }
      },
      mapping(row) {
        const defaultMapping = {
          rank: row.rank,
          rankEvolution: row.rankEvolution,
          logoUrl: row.logoUrl,
          name: row.name,
          nbReview: row.nbReview,
          rankable: row.rankable,
          rankStatus: row.rankStatus,
          nbPlaces: row.nbPlaces,
          scrapedAt: row.scrapedAt,
          rankMessage: row.rankMessage,
          debugLmPlaceIdWithScrapedAt: row.debugLmPlaceIdWithScrapedAt,
          avgScore: row.avgScore,
          avgScoreEvolution: row.avgScoreEvolution,
          highlighted: row.highlighted
        }

        const localMapping = {
          vicinity: row.vicinity,
          link: row.link,
          lmPlaceId: row.lmPlaceId,
          highlighted: row.lmPlaceId === this.lmPlaceActiveId
        }

        const nationalMapping = {
          nbPlaces: row.nbPlaces
        }

        if (this.isLocal) {
          const mapping = { ...defaultMapping, ...localMapping };

          return this.isMonoPlace ? mapping : { ...mapping, ...{
            relativeRank: row.relativeRank,
            relativeRankEvolution: row.relativeRankEvolution,
            relativeRankOn: row.relativeRankOn,
            topCompetitor: row.topCompetitor,
            topCompetitorName: row.topCompetitor?.name,
            topCompetitorNbReview: row.topCompetitor?.nbReview,
            topCompetitorAvgScore: row.topCompetitor?.avgScore,
            topCompetitorLink: row.topCompetitor?.link,
          }};
        } else {
          return { ...defaultMapping, ...nationalMapping };
        }
      },
      exportFileName() {
        const pageName = this.$t(this.$route.name);
        const campaignName = this.currentDashboard.name;
        const date = this.$date().locale(this.$i18n.locale).format('ddd DD MMM HH_mm_ss');

        return `${pageName} - ${campaignName} - ${date}`;
      },
      exportToExcel() {
        this.$store.dispatch("notifyInfo");

        const fileName = this.exportFileName();

        const pageName = this.$t(this.$route.name);
        const competitorMode = this.$t(`rankingExcelExport.${this.competitorMode}`);
        const sheetName = [pageName, competitorMode].join(' - ');

        let data = this.mappedRows.map((row) => {
          const { lmPlaceId, highlighted, logoUrl, topCompetitor, relativeRankOn, ...filteredMapping } = row;
          if (relativeRankOn) {
            filteredMapping.relativeRank = `${filteredMapping.relativeRank}/${relativeRankOn}`;
          }
          return filteredMapping;
        });

        const formattedDatas = formatExcelValues(data, { score: { round: 1 }, evolution: { round: 2 } })

        if (!this.displayEvolution) {
          data = data.map((row) => {
            const { avgScoreEvolution, rankEvolution, relativeRankEvolution, ...filteredMapping } = row;
            return filteredMapping;
          });
        }

        const headers = Object.keys(data[0]).map((key, index) =>
          this.$t(`rankingExcelExportHeaders.${key}`)
        );

        exportToExcel(fileName, sheetName, data, headers);
      },
      async exportToPdf() {
        const exportPageTitle = () => {
          const pageName = this.$t('rankingPdfExport.pageName');
          const scope = this.dashboardFilterScope.text;
          const competitorMode = this.$t(`rankingExcelExport.${this.competitorMode}`);
          const period = this.dashboardFilterDates.text;
          return [pageName, competitorMode, scope, period].join(' - ');
        }

        const exportHeader = async() => {
          const title = exportPageTitle();

          const campaignName = this.currentDashboard.name;
          const subTitle = campaignName;

          await pdf.addRow({}, async(row) => {
            await row.addCol({}, async(col) => {
              col.addText(title, { fontSize: fontSize, color: blackColor, font: { style: 'bold' }});
            });
          });
          await pdf.addRow({ marginTop: 5 }, async(row) => {
            await row.addCol({}, async(col) => {
              col.addText(subTitle, { fontSize: fontSize - 2, font: { style: 'bold' }});
            });
          });

          await exportTableHeaders(pdf);
        }

        const exportTableHeaders = async(pdf) => {
          await pdf.addRow({ height: 15, borderColor: borderColor, marginTop: 20 }, async(row) => {

            await row.addCol({ width: rankWidth });

            if (this.isNational || (this.isLocal && this.isMonoPlace)) {
              await row.addCol({ width: logoWidth, marginLeft: 10 });
            }

            await row.addCol({ width: infoWidth, marginLeft: 10 }, async(col) => {
              col.addText(this.$t(`rankingExcelExport.${this.competitorMode}`), { fontSize: fontSize - 2 });
            });

            if (this.isLocal && !this.isMonoPlace) {
              await row.addCol({ width: relativeRankWidth, marginLeft: 10 }, async(col) => {
                col.addText(this.$t('rankingTableHeaders.localRank'), { fontSize: fontSize - 2 });
              });
            }

            await row.addCol({ width: avgScoreWidth, marginLeft: 10 }, async(col) => {
              col.addText(this.$t('rankingTableHeaders.avgScore'), { fontSize: fontSize - 2 })
            });

            if (this.isLocal && !this.isMonoPlace) {
              row.addCol({ width: topCompetitorWidth, marginLeft: 10 }, async(col) => {
                col.addText(this.$t('rankingTableHeaders.topCompetitor'), { fontSize: fontSize - 2 });
              });
            }
          });
        }

        const exportEvolution = async(evolution, col, decimals = 0) => {
          let displayedEvolution;
          let evolutionSign;
          let evolutionColor;

          if (evolution && evolution != 0) {
            displayedEvolution = evolution > 0 ? evolution : - evolution;
            evolution = evolution.toFixed(decimals);
            evolutionSign = evolution > 0 ? '▲': '▼';
            evolutionColor = evolution > 0 ? '#65c095': '#c86868';
          } else {
            displayedEvolution = '=';
            evolutionSign = '';
            evolutionColor = '#add8e6';
          }

          await col.addRow({}, async(row) => {
            await row.addCol({ marginLeft: 4 }, async(col) => {
              col.addText(evolutionSign, { color: evolutionColor, fontSize: 4, yOffset: 3 });
            });
            await row.addCol({}, async(col) => {
              col.addText((displayedEvolution).toString(), { color: evolutionColor, fontSize: fontSize });
            });
          });
        }

        const exportRank = async(item, row) => {
          const rank = item.rank?.toString() || 'NC';

          await row.addCol({ width: rankWidth, height: row.height }, async(col) => {
            if (item.rank && item.rank <= 3) {
              const rankColors = ['#FFD333', '#A5C0C4', '#C89C73'];
              const bigCircleColor = rankColors[item.rank - 1] || '#FFF';
              const smallCircleColor = item.highlighted ? '#DFF0F1' : '#FFF'

              col.addCircle({ radius: rankWidth/2 - 2 , baseline: 'middle', align: 'center', color: bigCircleColor });
              col.addCircle({ radius: rankWidth/2 - 4, baseline: 'middle', align: 'center', color: smallCircleColor });
            }

            col.addText(rank, { align: 'center', fontSize: fontSize });

          });
        }

        const exportLogo = async (item, row) => {
          await row.addCol({ width: logoWidth, height: row.height, marginLeft: 10 }, async(col) => {
            await col.addImage(item.logoUrl, { width: logoWidth -4, baseline: 'middle' });
          });
        }

        const exportInfo = async (item, row) => {
          let subInfo;

          if (item.nbPlaces && this.isNational) {
            subInfo = this.$tc('nbPlace', item.nbPlaces);
          } else if (!item.highlighted && (this.isLocal && this.isMonoPlace)) {
            subInfo = item.vicinity;
          }

          await row.addCol({ width: infoWidth, marginLeft: 10 }, async(col) => {
            await col.addRow({ marginTop: subInfo ? marginTopWithSubline : marginTop }, async(row) => {
              await row.addCol({}, async(col) => {
                col.addText(item.name, { maxWidth: infoWidth - 20 , fontSize: fontSize, color: blackColor });
              });
              await row.addCol({}, async(col) => {
                await exportEvolution(item.rankEvolution, col);
              });
            });
            if (subInfo) {
              await col.addRow({}, async(row) => {
                await row.addCol({}, async(col) => {
                  col.addText(subInfo, { maxWidth: infoWidth - 20, fontSize: subLineFontSize });
                });
              });
            }
          });
        }

        const exportRelativeRank = async (item, row) => {
          await row.addCol({ width: relativeRankWidth, marginLeft: 10 }, async(col) => {

            if (item.relativeRank) {
              const relativeRank = item.relativeRank.toString();
              const outOf = this.$options.filters.ordinal(item.relativeRank);

              await col.addRow({ marginTop: marginTop }, async(row) => {
                await row.addCol({}, async(col) => {
                  col.addText(relativeRank, {
                    font: { style: 'bold' },
                    color: '#1e547b',
                    fontSize: fontSize
                  });
                });
                await row.addCol({}, async(col) => {
                  col.addText(outOf, {
                    yOffset: -2,
                    font: { style: 'bold' },
                    color: '#1e547b',
                    fontSize: fontSize - 2
                  });
                });
                await row.addCol({}, async(col) => {
                  col.addText(`/${item.relativeRankOn.toString()}`, {
                    font: { style: 'bold' },
                    color: '#1e547b',
                    fontSize: fontSize
                  });
                });
                await row.addCol({}, async(col) => {
                  await exportEvolution(item.relativeRankEvolution, col);
                });
              });
            } else {
              await col.addRow({ marginTop: marginTop }, async(row) => {
                await row.addCol({}, async(col) => {
                  col.addText(this.$t('nc'), { font: { style: 'bold' }, fontSize: fontSize });
                });
              });
            }
          });
        }

        const exportAvgScore = async (item, row) => {
          if (item.nbReview) {
            await row.addCol({ width: avgScoreWidth, marginLeft: 10 }, async(col) => {
              await col.addRow({ marginTop: marginTopWithSubline }, async(row) => {
                await row.addCol({}, async(col) => {
                  col.addText(`${item.avgScore.toFixed(2)}/5`, { fontSize: fontSize, color: blackColor });
                });
                await row.addCol({}, async(col) => {
                  await exportEvolution(item.avgScoreEvolution, col, 2);
                });
              });
              await col.addRow({}, async(row) => {
                await row.addCol({}, async(col) => {
                  col.addText(
                    `${this.$t('rankingPdfExport.nbReview', { nbReview: item.nbReview })}`,
                    { fontSize: subLineFontSize }
                  );
                });
                await row.addCol({ marginLeft: 2 }, async(col) => {
                  await col.addIcon('mdiGoogle', { color: '#5086EC', width: 8, height: 8 });
                });
              });
            });
          }
        }

        const exportTopCompetitor = async(item, row) => {
          await row.addCol({ width: topCompetitorWidth, marginLeft: 10 }, async(col) => {
            await col.addRow({ marginTop: marginTopWithSubline }, async(row) => {
              await row.addCol({}, async(col) => {
                col.addText(item.topCompetitorName, { maxWidth: topCompetitorWidth - 20, fontSize: fontSize });
              });
            })
            await col.addRow({}, async(row) => {
              await row.addCol({}, async(col) => {
                col.addText(
                  this.$t('rankingPdfExport.topCompetitorNbReview', { nbReview: item.topCompetitorNbReview }),
                  { fontSize: subLineFontSize }
                );
              });
              await row.addCol({ marginLeft: 2 }, async(col) => {
                await col.addIcon('mdiGoogle', { color: '#5086EC', width: 8, height: 8 });
              });
              await row.addCol({}, async(col) => {
                col.addText(
                  this.$t('rankingPdfExport.topCompetitorAvgScore', {
                    avgScore: item.topCompetitorAvgScore.toFixed(2)}
                  ),
                  { fontSize: subLineFontSize }
                );
              });
            });
          });
        }

        const exportFooter = async(pdf) => {
          const title = exportPageTitle();
          const pagination = this.$t('rankingPdfExport.pagination', { page: pdf.pageCount, totalPage: paginatedData.length });
          const footer = [title, pagination].join(' - ');

          await pdf.addRow({}, async(row) => {
            await row.addCol({ width: '12' }, async(col) => {
              col.addText(footer, { fontSize: fontSize - 2, align: 'right' });
            });
          });
        }

        this.$store.dispatch("notifyInfo");

        const rowHeight = 24;
        const rankWidth = 24;
        const logoWidth = 24;
        const infoWidth = (this.isLocal && !this.isMonoPlace) ? 240 : 450;
        const relativeRankWidth = 80;
        const avgScoreWidth = 100;
        const topCompetitorWidth = 220;
        const fontSize = 8;
        const subLineFontSize = 6;
        const marginTop = 6;
        const marginTopWithSubline = 2;
        const blackColor = "#212121";
        const borderColor = "#e0e0e0"
        const fileName = `${this.exportFileName()}.pdf`;
        const data = this.mappedRows.map((row) => {
          const { lmPlaceId, topCompetitor, ...filteredMapping } = row;
          return filteredMapping;
        });

        const pdf = new Pdf({
          defaultBodyMargin: { left: 40, top: 0 },
          defaultHeader: { marginLeft: 40, marginTop: 10, content: exportTableHeaders },
          defaultFooter: { height: 25, marginLeft: 40, content: exportFooter }
        });

        const paginatedData = paginateArray(data, 43, 42);

        await paginatedData.reduce(async (pagePromise, pageDate, pageIndex) => {
          await pagePromise;

          if (pagePromise) {
            await pdf.addPage();
          } else {
            await pdf.addPage({
              header: { marginLeft: 40, marginTop: 20, content: exportHeader }
            });
          }

          await pageDate.reduce(async (itemPromise, item, itemIndex) => {
            await itemPromise;

            let rowParams = { height: rowHeight, borders: ['top', 'bottom'], borderColor: borderColor };

            if (item.highlighted) {
              const highlitedColor = { backgroundColor: '#DFF0F1'};

              rowParams = { ...rowParams, ...highlitedColor };
            }

            await pdf.addRow(rowParams, async(row) => {
              await exportRank(item, row);

              if (this.isNational || (this.isLocal && this.isMonoPlace)) {
                await exportLogo(item, row);
              }

              await exportInfo(item, row);

              if (this.isLocal && !this.isMonoPlace) {
                await exportRelativeRank(item, row);
              }

              await exportAvgScore(item, row);

              if (item.topCompetitorName) {
                await exportTopCompetitor(item, row);
              }
            });

          }, undefined);

        }, undefined);

        pdf.save(fileName);
      }
    }
  }
</script>

<style lang='stylus' scoped>
  @import '~@theme/constants.styl'

  .v-progress-linear--absolute, .v-progress-linear--fixed
    z-index: 10

  .widget-ranking-header
    height: $widget-header-height
</style>
