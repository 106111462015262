<template>
  <div class="ranking-navigation">
    <w-nav-tabs
      :router-links-properties="routerLinksProperties"
      scrollable
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "RankingNavigation",
    props: {
      rankBy: {
        type: String,
        required: true
      },
      rankById: {
        required: true
      },
      scope: {
        type: String,
        required: true
      } 
    },
    data() {
      return {

      }
    },
    computed: {
      ...mapGetters([
        'currentLexicon',
        'currentDashboard',
        'currentDashboardBasedTable',
        'isDashboardMono',
        'currentDashboardRequest',
        'voterInfoRankingOptions',
        'isNetworkMonoPlace'
      ]),
      routerLinksProperties() {
        let routerLinksProperties = []

        if (!this.isNetworkMonoPlace) {
          routerLinksProperties.push(
            {
              title: this.currentLexicon.translate('places', 'places'),
              wicon: 'map-marker',
              route: {
                name: 'Ranking',
                params: { rankBy: "places", rankById: 'brand', scope: this.scope }
              }
            }
          )
          
          for (const groupSemantic of this.groupSemanticRankings) {
            if (!['brand', 'custom'].includes(groupSemantic.value)) {
              routerLinksProperties.push(
                {
                  title: groupSemantic.text,
                  wicon: 'map-markers',
                  route: {
                    name: 'Ranking',
                    params: { rankBy: "group", rankById: groupSemantic.value, scope: this.scope },
                  }
                }
              )
            }
          }
        }

        if (this.voterInfoRankingOptions && this.isDashboardMono) {
          for (const seller of this.voterInfoRankingOptions) {
            routerLinksProperties.push(
              {
                title: seller.text,
                wicon: "user-shape-3",
                route: {
                  name: 'Ranking',
                  params: { rankBy: "voter_info", rankById: seller.value, scope: this.scope },
                }
              }
            )
          }
        }

        return routerLinksProperties
      }
  },
    asyncComputed: {
      groupSemanticRankings: {
        async get() {
          const request = this.$basedRequest().select({ 
            brands: [ 
              { 'group_semantics_with_translations': { 
                params: { dashboard_type: this.currentDashboard.type, dashboard_id: this.currentDashboard.id, no_alone_group: true }
              } }
            ] 
          })
          
          const result = (await this.$resolve(request)).first().groupSemanticsWithTranslations

          return result
        },
        default: []
      }
    }
  }
</script>

<style lang="stylus" scoped>
</style>
