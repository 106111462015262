<template>
  <v-card outlined>
    <v-form
      ref="form"
      :disabled="!campaignNetImpactScorePreference.enabled"
      v-model="isValid"
    >
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row no-gutters>
            <v-card-title>
              <h4 class="f-16 c-primary">{{ $t('nis_settings_activation') }}</h4>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-btn-toggle
                    v-model="campaignNetImpactScorePreference.enabled"
                    mandatory
                    color="info"
                    flat
                    outlined
                  >
                    <v-btn @click="setChange" :value="false" class="v-btn v-size--default ">
                      <span>OFF</span>
                    </v-btn>
                     <v-btn @click="setChange" :value="true" class="v-btn v-size--default">
                      <span>ON</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-select
                    background-color="#eef1f5"
                    v-model="campaignNetImpactScorePreference.periodInMonth"
                    hide-details="auto"
                    :items="selectablePeriods"
                    :label="$t('nis_settings_analysis_period')"
                    outlined
                    dense
                    @input="setChange"
                    :menu-props="selectMenuProps"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-select
                    :disabled="selectableDateTypes && selectableDateTypes.length <= 1"
                    background-color="#eef1f5"
                    v-model="campaignNetImpactScorePreference.dateType"
                    hide-details="auto"
                    :items="selectableDateTypes"
                    :label="$t('nis_settings_date_type')"
                    outlined
                    dense
                    @input="setChange"
                    :menu-props="selectMenuProps"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row no-gutters>
            <v-card-title>
              <h4 class="f-16 c-primary">{{ $t('nis_settings_opportunity_title') }}</h4>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-select
                    v-if="campaignNetImpactScorePreferenceSemantics"
                    background-color="#eef1f5"
                    v-model="campaignNetImpactScorePreference.semantic"
                    :items="campaignNetImpactScorePreferenceSemantics"
                    :label="$t('nis_settings_group')"
                    @input="setChange"
                    hide-details="auto"
                    outlined
                    dense
                    :menu-props="selectMenuProps"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field outlined dense
                    :label="$t('nis_settings_minimum_feedbacks_count')"
                    background-color="#eef1f5"
                    type="number"
                    :rules="numberRules"
                    v-model="campaignNetImpactScorePreference.placeReferencesMinimumFeedbacksCount"
                    class="place-references-feedbacks-input"
                    @input="setChange"
                    hide-details="auto"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    background-color="#eef1f5"
                    v-model="campaignNetImpactScorePreference.placeReferencesPeriodInMonth"
                    :items="selectablePeriods"
                    :label="$t('nis_settings_place_references_period')"
                    outlined
                    hide-details="auto"
                    dense
                    @input="setChange"
                    :menu-props="selectMenuProps"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field outlined dense
                    :label="placeReferencesCountLabel"
                    background-color="#eef1f5"
                    type="number"
                    v-model="campaignNetImpactScorePreference.placeReferencesCount"
                    class="place-references-count-input"
                    required
                    :rules="numberRules"
                    @input="setChange"
                    :disabled="!campaignNetImpactScorePreference.enabled"
                    hide-details="auto"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field outlined dense
                    v-if="campaignNetImpactScorePreference.semantic !== 'network'"
                    :label="subCategoryPlaceReferenceCountLabel"
                    background-color="#eef1f5"
                    type="number"
                    v-model="campaignNetImpactScorePreference.subCategoriesPlaceReferencesCount"
                    class="place-references-count-input"
                    required
                    :rules="numberRules"
                    @input="setChange"
                    :disabled="!campaignNetImpactScorePreference.enabled"
                    hide-details="auto"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card-text>
            <v-btn
              :disabled="!isValid || !hasChanged"
              color="primary"
              @click="editCampaignNetImpactScorePreference"
            >
            {{ $t("save") }}
            </v-btn>
          </v-card-text>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _snakeCase from 'lodash/snakeCase'
  import _uniqBy from 'lodash/uniqBy'
  import _upperFirst from 'lodash/upperFirst'

  const DEFAULT_PREFERENCE = {
    enabled: false,
    placeReferencesMinimumFeedbacksCount: 12,
    periodInMonth: 12,
    dateType: null,
    semantic: 'network',
    placeReferencesPeriodInMonth: 12,
    placeReferencesCount: 3,
    subCategoriesPlaceReferencesCount: 3
  }

  export default {
    name: "Form",
    components: {},
    data() {
      return {
        isValid: false,
        hasChanged: false,
        numberRules: [
          v => !!v || 'Ce champ doit être rempli',
          v => (Number.isInteger(Number(v)) && (v > 0)) || "La valeur doit être de 1 minimum"
        ]
      }
    },
    computed: {
      ...mapGetters([
        'currentUser',
        'currentUserId',
        'currentCampaignPreferencesCampaignId',
        'currentUser',
        'currentLexicon'
      ]),
      selectMenuProps() {
        return {
          offsetY: true
        }
      },
      selectablePeriods() {
        return [
          { text: '1 mois', value: 1 },
          { text: '2 mois', value: 2 },
          { text: '3 mois', value: 3 },
          { text: '6 mois', value: 6 },
          { text: '12 mois', value: 12 },
          { text: '24 mois', value: 24 },
          { text: '36 mois', value: 36 }
        ]
      },
      placeReferencesCountLabel() {
        return `${this.$t('nis_settings_place_references_count')} ${this.networkSemanticLabel}`
      },
      subCategoryPlaceReferenceCountLabel() {
        return `${this.$t('nis_settings_sub_categories_place_references_count')} ${this.currentSemanticLabel}`
      },
      networkSemanticLabel() {
        return this.campaignNetImpactScorePreferenceSemantics.find(
          semantic => semantic.value === 'network'
        )?.text
      },
      currentSemanticLabel() {
        return this.campaignNetImpactScorePreferenceSemantics.find(
          semantic => semantic.value === this.campaignNetImpactScorePreference.semantic
        )?.text
      }
    },
    asyncComputed: {
      selectableDateTypes: {
        async get() {
          const fieldsCustomizations = await this.getFieldsCustomisations()

          const req = this.$basedRequest().select(
            {
              emailing_preferences: [
                {'MAX_purchase_date': { as: 'purchase_date' } },
                {'MAX_service_date': { as: 'service_date' } },
                {'MAX_delivery_date': { as: 'delivery_date' } }
              ],
            }
          ).where(
            {
              emailings_campaign_id: this.currentCampaignPreferencesCampaignId
            }
          )

          const results = (await this.$resolve(req)).first() || {}

          const availableDateTypes = []

          Object.keys(results).forEach(dateType => {
            if (results[dateType] !== null) {
              availableDateTypes.push(_snakeCase(dateType))
            }
          })

          const selectableDateTypes = availableDateTypes.map(dateType => {
            const fieldsCustomization = fieldsCustomizations ? fieldsCustomizations[this.currentUser.locale || 'fr'] : null

            const text = fieldsCustomization && fieldsCustomization[dateType] ? fieldsCustomization[dateType] : this.$t(dateType)

            return {
              text: text,
              value: dateType
            }
          })

          this.campaignNetImpactScorePreference.dateType ||= selectableDateTypes[0].value

          return selectableDateTypes
        },
        default: null
      },
      campaignNetImpactScorePreferenceSemantics: {
        async get() {
          const request = this.$basedRequest().select({
            brands: [
              'group_semantics_with_translations'
            ]
          }).where({
            id: this.currentUser.brandId
          })

          const semantics = (await this.$resolve(request)).first().groupSemanticsWithTranslations

          semantics.unshift({
            text: this.currentLexicon.translate('network', 'network'),
            value: 'network'
          })

          return semantics
        },
        default: [],
      },
      campaignNetImpactScorePreference: {
        async get() {
          const request = this.$basedRequest().select({
            campaign_net_impact_score_preferences: [
              'enabled',
              'period_in_month',
              'date_type',
              'semantic',
              'place_references_minimum_feedbacks_count',
              'place_references_period_in_month',
              'place_references_count',
              'sub_categories_place_references_count',
            ],
          }).where({
            campaign_id: this.currentCampaignPreferencesCampaignId
          })

          const campaignNetImpactScorePreference = (await this.$resolve(request)).first()

          if (campaignNetImpactScorePreference.enabled === null) {
            return DEFAULT_PREFERENCE
          }
          return campaignNetImpactScorePreference
        },
        default: DEFAULT_PREFERENCE
      }
    },
    methods: {
      setChange() {
        this.hasChanged = true
      },
      async getFieldsCustomisations() {
        const req = this.$basedRequest().select({
          emailings_preferences: ['fields_customization'],
        }).where({
          campaign_id: this.currentCampaignPreferencesCampaignId
        })

        const response = (await this.$resolve(req)).first()

        return response?.fieldsCustomization ? JSON.parse(response.fieldsCustomization) : {}
      },
      async editCampaignNetImpactScorePreference() {
        if (confirm("Êtes vous sur de vouloir modifier le paramétrage du NIS ?")) {
          const response = await this.$api.wizville.campaignPreferences.editCampaignNetImpactScorePreference(
            this.currentCampaignPreferencesCampaignId,
            this.campaignNetImpactScorePreference
          )

          if (response.status === "ok") {
            this.$store.dispatch('notifySuccess', {
              message: this.$t('parameters_changed')
            })
            this.hasChanged = false
          }
        }
      },
    },

  }
</script>
