import $api from '@api'

const surveyContext = {
  state: () => ({
    loaded: null,
    digest: null,
    questions: [],
  }),
  getters: {
    currentSurvey(state, getters) {
      if (state.loaded) {
        return state
      } else {
        return null
      }
    },
    freeQuestions(state) {
      return state.questions.filter(q => q.type === 'FreeQuestion')
    },
    freeQuestionOptions(state, getters) {
      return getters.freeQuestions.map(q => ({
        text: q.content,
        value: q.id
      }))
    }
  },
  mutations: {
    setSurveyContext(state, { digest, questions, locales }) {
      state.loaded = Date.now()
      state.digest = digest
      state.questions = questions
      state.locales = locales
    },
  },
  actions: {
    async surveyContext(context, campaignId) {
      const digest = campaignId

      if (digest !== context.state.digest || !context.state.loaded) {
        const ctx = await $api.wizville.contexts.authSurvey(campaignId)

        if (ctx.survey) {
          context.commit('setSurveyContext', { digest, ...ctx.survey })
        }
      }
    },
  },
}

export default surveyContext
