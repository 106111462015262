<template>
  <WStatsWidget
    :cols="cols"
    :empty="!topicsRecommendations?.length"
    :justifyCenter="false"
    :loading="topics === undefined"
    :maxHeight="maxHeight"
    :minHeight="minHeight"
    :subtitle="defaultSubtitle"
    :title="$t('topicsRecommendationsTableWidgetTitle')"
    class="topics-table-widget"
    contentWidth="100%"
    headerEmoji="💪"
  >
    <template v-if="topics !== undefined" #options>
      <w-drop-down-menu
        v-if="nisMixin_canUseNisFeature([campaign])"
        v-model="nisMixin_transactionalUnitsSelectElementValue"
        :items="nisMixin_availableTransactionalUnits([campaign])"
        :title="nisMixin_transactionalUnit([campaign]).label"
        :emoji="nisMixin_transactionalUnit([campaign]).emoji"
        :showGroupTitle="false"
      />
    </template>

    <template #content>
      <v-data-table
        :headers="headers"
        :items="topicsRecommendations"
        :items-per-page="40"
        hide-default-footer
        mobile-breakpoint="0"
        :item-class="rowClasses"
        class="topics-recommendations-table-widget v-datatable-without-y-borders"
        @click:row="topic => topicsMixin_goToTopic(campaign, topic)"
        sort-by="nisRoiSatisfaction"
        sort-desc
      >
        <template v-slot:header.nisRoiSatisfaction="{ header }">
          <HeaderWithTooltip
            :title="header.text"
            :tooltip="header.tooltip"
          />
        </template>
        <template v-slot:header.nisOpportunity="{ header }">
          <HeaderWithTooltip
            :title="header.text"
            :tooltip="header.tooltip"
          />
        </template>

        <template v-slot:item.name="{ item }">
          <div class="d-flex align-center ga-4">
            <w-zoom-button
              v-if="$vuetify.breakpoint.smAndUp"
              :highlighted="item.id === selectedTopic?.id"
              @click="topicsMixin_goToTopic(campaign, item)"
            />
            <span class="name c-primary fs-13">{{ item.name }}</span>
          </div>
        </template>

        <template v-slot:item.nisRoiSatisfaction="{ item }">
          <NisRoiSatisfactionCell
            :nisItem="item"
            :showNbReview="false"
            :barWidth="nisRoiSatisfactionBarWidth"
          />
        </template>
        <template v-slot:item.nisOpportunity="{ item }">
          <NisOpportunityCell
            :nisItem="item"
          />
        </template>
        <template v-slot:item.nisSatisfactionGoal="{ item }">
          <NisSatisfactionGoalCell
            :nisItem="item"
          />
        </template>
      </v-data-table>
    </template>
  </WStatsWidget>
</template>

<script>
  import _upperFirst from 'lodash/upperFirst'

  import HeaderWithTooltip from '../../shared/Table/HeaderWithTooltip'
  import NisSatisfactionGoalCell from '@statistics/shared/Table/NisSatisfactionGoalCell'
  import NisRoiSatisfactionCell from '@statistics/shared/Table/NisRoiSatisfactionCell'
  import NisOpportunityCell from '@statistics/shared/Table/NisOpportunityCell'

  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import NisMixin from '@statistics/shared/nis_mixin'
  import TopicsMixin from '@statistics/shared/topics_mixin'

  export default {
    name: "TopicsRecommendationsTableWidget",
    mixins: [
      WidgetMixin,
      NisMixin,
      TopicsMixin,
    ],
    components: {
      HeaderWithTooltip,
      NisRoiSatisfactionCell,
      NisOpportunityCell,
      NisSatisfactionGoalCell
    },
    props: {
      campaign: { type: Object, required: true },
      cols: { type: Number, default: 6 },
      maxHeight: { type: String, default: '360px' },
      minHeight: { type: String, default: '360px' },
      friezeWidth: { type: String, default: '150px' },
      topics: { type: Array },
      selectedTopic: { type: Object },
    },
    data() {
      return {
        placesLoading: false,
        places: []
      }
    },
    computed: {
      nisRoiSatisfactionBarWidth() {
        return this.$vuetify.breakpoint.mdAndUp ? "200px" : "100px"
      },
      nisRoiSatisfactionColumnWidth() {
        return '300px'
      },
      headers() {
        let headers = [
          {
            text: this.$t('topic'),
            value: 'name',
            align: 'start',
            cellClass: 'name-cell',
            sortable: false
          },
          {
            text: this.nisMixin_satisfactionImpactHeaderTitle([this.campaign]),
            value: 'nisRoiSatisfaction',
            cellClass: 'nis-satisfaction-impact',
            tooltip: this.nisMixin_roiSatisfactionHelpMessage([this.campaign]),
            sortable: false,
            width: this.nisRoiSatisfactionColumnWidth
          },
          {
            text: this.nisMixin_opportunityHeaderTitle([this.campaign]),
            value: 'nisOpportunity',
            align: 'center',
            tooltip: this.nisMixin_opportunityHelpMessage([this.campaign]),
            cellClass: 'nis-opportunity fb-15 c-primary text-no-wrap',
            sortable: false,
            displayCondition: this.$vuetify.breakpoint.smAndUp
          },
          {
            text: this.$t("topicsRecommendationsTableWidgetSatisfactionRates"),
            value: 'nisSatisfactionGoal',
            cellClass: 'nis-satisfaction-goal',
            sortable: false,
            displayCondition: this.$vuetify.breakpoint.mdAndUp
          }
        ]

        return headers.filter((header) => header.displayCondition === undefined || header.displayCondition)
      },
      nisRoiSatisfactionMax() {
        return Math.max(
          ...this.topics.map(topic => {
            let nisTopicSummary = this.nisMixin_topicSummaries(this.campaign)[topic.id]
            if (nisTopicSummary) {
              if (topic.nisOpportunity > 0) {
                return this.nisMixin_roiSatisfaction(topic, nisTopicSummary)
              }
            }
          }).filter(
            value => value
          )
        )
      },
      topicsRecommendations() {
        if (this.topics === undefined) {
          return undefined
        }
        return this.topics
          .map(topic => {
            let nisTopicSummary = this.nisMixin_topicSummaries(this.campaign)[topic.id]
            if (nisTopicSummary) {
              if (topic.nisOpportunity > 0) {
                return {
                  ...topic,
                  nisRoiSatisfactionBarScore: this.getNisRoiSatisfactionBarScore(
                    topic, nisTopicSummary
                  )
                }
              }
            }
            return null
          })
          .filter(topic => topic !== null)
      },
    },
    methods: {
      rowClasses(item) {
        return item.id === this.selectedTopic?.id ? 'item active' : 'item'
      },
      getNisRoiSatisfactionBarScore(topic, nisTopicSummary) {
        const nisRoiSatisfaction = this.nisMixin_roiSatisfaction(topic, nisTopicSummary)
        return this.$options.filters.toPercent(
          nisRoiSatisfaction / this.nisRoiSatisfactionMax
        )
      }
    }
  }
</script>

<style lang="stylus">
  .topics-recommendations-table-widget
    .name-cell
      .name
        text-wrap: initial
</style>
