<template>
  <Table
    :categoryId="categoryId"
    :topics="topics"
    :campaign="campaign"
    :loading="!ready"
    mode="product_categories"
    :totalCount="categoriesTotalCount"
    :items="items"
    v-model="options"
    :onRowClick="onRowClick"
  />
</template>

<script>
  import { mapGetters } from 'vuex'

  import Table from './Table'

  export default {
    name: "CategoriesTable",
    props: {
      categoryId: { type: Number, required: false },
      topics: { type: Array, required: false, default: () => [] },
      campaign: { required: false },
      applyNavigationFilters: { required: false, default: true }
    },
    components: {
      Table
    },
    data() {
      return {
        loading: true,
        options: {
          page: 1,
          itemsPerPage: 50
        }
      }
    },
    methods: {
      onRowClick(item) {
        if (this.applyNavigationFilters) {
          this.$router.push(
            {
              name: 'Products',
              params: {
                mode: 'product_categories',
                productCategoryId: item.id
              }
            }
          )
        }
      },
      async fetchCategories() {
        let request = this.dashboardFilterRequest.select({
          voters: [
            { product_ratio_recommendation: { as: 'recommendation' }},
            { product_category_id: { as: 'categoryId'}},
            { AVG_avg_score: { as: 'score' }},
            { avg_score_with_minimun_nb_reviews: { as: 'rank', minimum_nb_reviews: this.minimalNumberOfFeedbackForRanking } },
            { COUNT_DISTINCT_id: { as: 'nbReview' }},
            { COUNT_DISTINCT_product_id: { as: 'nbProducts' }},
            { MAX_product_categories_category_name: { as: 'categoryName' }}
          ]
        })
        .where({
          campaign_id: this.campaign.id,
          avg_score: { 'is_not_null': {} }
        }).offset(
          (this.options.page - 1) * this.options.itemsPerPage
        ).limit(
          this.options.itemsPerPage
        ).order([
            [
              {
                avg_score_with_minimun_nb_reviews: {
                  minimum_nb_reviews: this.minimalNumberOfFeedbackForRanking
                }
              },
              'desc'
            ],
            [{ avg_score: { as: 'score', mod: 'AVG_ROUND_2' } }, 'desc'],
            ['COUNT_id', 'desc']
          ]
        ).group(['product_category_id'])

        if (this.applyNavigationFilters) {
          request = request.where({
            category_parent_id: this.categoryId
          })
        }

        let result = (await this.$resolve(request)).data.voters || []

        const categoryIds = result.map(
          item => item.categoryId
        )

        const categoriesData = (await this.$resolve(this.voterProductCategoriesRequest(categoryIds))).data || {}

        result = result.map((category) => {
          const categoryData = categoriesData[category.categoryId]

          return {
            ...category,
            nbChildrenCategories: categoryData?.nbChildren
          }
        })

        return result
      },
      async fetchVoterAvgTopicsByProductCategoryId(productCategoryIds) {
        const request = this.dashboardFilterRequest.select({
          voter_avg_topics: [
            { product_category_id: { as: 'categoryId'}},
            { MAX_topic_id: { as: 'topicId' } },
            { AVG_avg_topic: { as: 'score' } },
            { MAX_topics_name: { as: 'topicName' } },
            { nb_review: { as: 'nbReview' } }
          ]
        }).where({
          campaign_id: this.campaign.id,
        }).where({
          product_category_id: productCategoryIds
        }).order(
          [
            ['topic_id', "desc"],
          ]
        ).group(['product_category_id', 'topic_id'])

        const result = (await this.$resolve(request)).data || {}

        return result
      },
      voterProductCategoriesRequest(categoryIds) {
        return this.dashboardFilterRequest.select({
          voters: [
            { COUNT_DISTINCT_product_category_id: { as: 'nbChildren'}},
            { category_parent_id: { as: 'categoryId'}},
          ]
        })
        .where({
          campaign_id: this.campaign.id,
          category_parent_id: categoryIds,
          avg_score: { 'is_not_null': {} }
        }).group(['category_parent_id'])
      },
      buildGlobalHash(item, rank) {
        return {
          name: item?.categoryName,
          id: item?.categoryId,
          rank: rank,
          nbProducts: item?.nbProducts,
          nbChildrenCategories: item?.nbChildrenCategories,
          score: parseFloat(item?.score),
          nbReview: item?.nbReview,
          recommendation: parseFloat(item?.recommendation)
        }
      },
      buildTopicsHash(avgProductTopics) {
        let topicsHash = {}

        if (avgProductTopics) {
          Object.keys(avgProductTopics).forEach((topicId) => {
            const avgProductTopic = avgProductTopics[topicId]

            const topicHash = {
              [`topic-${topicId}`]: {
                score: parseFloat(avgProductTopic.score),
                nbReview: avgProductTopic.nbReview
              }
            }
            topicsHash = {...topicsHash, ...topicHash}
          })
        }
        return topicsHash
      }
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest',
        'minimalNumberOfFeedbackForRanking'
      ]),
      ready() {
        return (
          this.items !== undefined &&
          !this.loading &&
          this.categoriesTotalCount !== undefined
        )
      }
    },
    asyncComputed: {
      items: {
        async get() {
          this.loading = true

          let rank = (this.options.page - 1) * this.options.itemsPerPage + 1

          const categories = await(
            this.fetchCategories()
          )

          const productCategoryIds = categories.map(
            item => item.categoryId
          )

          const voterAvgTopicsByProductCategoryId = await(
            this.fetchVoterAvgTopicsByProductCategoryId(productCategoryIds)
          )

          const items = categories.map(item => {
            const globalHash = this.buildGlobalHash(
              item,
              item.rank !== -1 ? rank : null
            )
            const avgProductTopics = voterAvgTopicsByProductCategoryId?.[item.categoryId]
            const topicsHash = this.buildTopicsHash(avgProductTopics)

            rank += 1

            return {
              ...globalHash,
              ...topicsHash
            }
          })

          this.loading = false

          return items
        }
      },
      categoriesTotalCount: {
        async get() {
          let request = this.dashboardFilterRequest.select({
            voters: [
              { COUNT_DISTINCT_product_category_id: { as: 'totalCount' }},
            ]
          }).where({
            campaign_id: this.campaign.id,
            avg_score: { 'is_not_null': {} }
          })

          if (this.applyNavigationFilters) {
            request = request.where({
              category_parent_id: this.categoryId
            })
          }

          const result = (await this.$resolve(request)).first()?.totalCount || 0

          return result
        }
      },
    }
  }
</script>

<style lang="stylus">
</style>
