<template>
  <div class="main-filter">
    <v-menu
      v-model="open"
      :close-on-content-click="false"
      :class="{ 'v-mobile-menu': $vuetify.breakpoint.xsOnly }"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="w-filter-dropdown d-flex px-2 justify-space-between align-center c-primary"
          :class="{
            'bg-bodygrey': open,
            'bg-palegrey': !open,
            'mobile-filter-menu': $vuetify.breakpoint.xsOnly,
          }"
          v-bind="attrs"
          v-on="on"
        >
          <div class="dropdown-title">
              <slot name="icon">
              </slot>

              <span class="primary--text f-13">
                <slot name="title">
                  <v-tooltip top v-if="title && title.length > 30">
                    <template #activator="{ on }">
                      <span v-on="on">
                        {{ title | truncate30 }}
                      </span>
                    </template>

                    {{ title }}
                  </v-tooltip>
                
                  <template v-else>
                    {{ title }}
                  </template>   
                </slot>
              </span>

              <slot name="badge">
                <v-badge v-if="nbFilters && nbFilters > 0" color="success" :content="nbFilters" class="pl-2">
                </v-badge>
              </slot>   
            </span>
          </div>
          <v-icon
            color="primary"
            size="medium"
          >
            mdi-chevron-down
          </v-icon>
        </div>
      </template>
      <v-list class="py-0" dense>
        <v-col :class="{ 'pa-0': noPadding == true }">
          <slot name="content" :opened="open">
          </slot>
        </v-col>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: "MainFilter",
    props: [ 'title', 'nbFilters', 'noPadding' ],
    data() {
      return {
        open: false
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .w-filter-dropdown
    width 100%
    height: 40px
    border-right 1px solid rgba(0, 0, 0, 0.12)
</style>
