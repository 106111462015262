<template>
  <div
    class="translations d-flex align-center"
    v-if="translations"
  >
    <div
      v-for="locale in Object.keys(translations)"
      :key="locale"
    >
      <v-menu
        open-on-hover
        top
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
          >
            <w-flag-icon
              :locale="locale"
            />
          </div>

        </template>
        <v-card
          width="400"
        >
          <v-card-title>
            <div class="d-flex align-center">
              <w-flag-icon
                :locale="locale"
              />
              <span
                class="fb-14 ml-2"
              >
                {{ translations[locale].title }}
              </span>
            </div>
          </v-card-title>
          <v-card-text>
            <template v-for="(item, index) in translationContent(translations[locale].content)">
              <v-chip
                v-if="index%2"
                label
                x-small
                class="px-1 f-14 mb-1 c-darkergrey"
              >{{ `:${item}` }}</v-chip>
              <span
                v-else
                class="c-darkergrey"
              >
                {{ item }}
              </span>
            </template>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Translations",
    props: {
      translations: {
        required: true
      }
    },
    methods: {
      translationContent(content) {
        return content.split(
          /{{(?<field>\w+) \| else=\(self\)}}/gm
        )
      }
    }
  }
</script>

<style lang="stylus">
  .translations
    margin-left: -8px
</style>
