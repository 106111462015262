<template>
  <WStatsWidget
    class="campaign-table-widget"
    :title="title || $t('statistics_widget_campaigns_nps_ranking_title')"
    :subtitle="displayedSubtitle"
    :height="widgetHeight"
    :cols="cols"
    :loading="isLoading"
    contentWidth="100%"
    :justifyCenter="false"
  >
    <template v-if="!isLoading" #options>
      <div class="d-flex">
        <w-drop-down-menu
          v-if="nisMixin_canUseNisFeature(eligibleCampaigns)"
          v-model="nisMixin_transactionalUnitsSelectElementValue"
          :items="nisMixin_availableTransactionalUnits(eligibleCampaigns)"
          :title="nisMixin_transactionalUnit(eligibleCampaigns).label"
          :emoji="nisMixin_transactionalUnit(eligibleCampaigns).emoji"
          :showGroupTitle="false"
        />
        <w-drop-down-menu
          :items="exportOptions"
          icon="mdi-download"
        />
      </div>
    </template>

    <template #content>
      <v-data-table
        :headers="headers"
        :items="npsData"
        :items-per-page="40"
        hide-default-footer
        mobile-breakpoint="0"
        :item-class= "rowClasses"
        class="campaigns-table v-datatable-without-y-borders"
        @click:row="zoomOnNps"
        :sort-by="sortBy"
        sort-desc
      >
        <template v-slot:header.nisOpportunity="{ header }">
          <HeaderWithTooltip
            :title="header.text"
            :tooltip="header.tooltip"
          />
        </template>

        <template v-slot:item.name="{ item }">
          <div class="d-flex align-center ga-4">
            <w-zoom-button
              v-if="$vuetify.breakpoint.smAndUp"
              :highlighted="item.id === selectedCampaign.id"
              @click="zoomOnNps(item)"
            />
            <span class="fs-13 c-primary">{{ item.name }}</span>
          </div>
        </template>

        <template v-slot:item.score="{ item }">
          <ScoreCell
            :nbReview="item.nbReview"
            :score="item.score.toFixed(0)"
            :evolution="item.evolution"
            :nbPromoters="item.nbPromoters"
            :nbDetractors="item.nbDetractors"
            :nbNeutrals="item.nbNeutrals"
            :repartitionWidth="repartitionWidth"
            :showNbReview="$vuetify.breakpoint.mdAndUp"
          />
        </template>

        <template v-slot:item.nisOpportunity="{ item }">
          <NisOpportunityCell
            :nisItem="item"
          />
        </template>

        <template v-slot:item.nisSatisfactionGoal="{ item }">
          <NisSatisfactionGoalCell
            :nisItem="item"
          />
        </template>
      </v-data-table>
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _upperFirst from 'lodash/upperFirst'

  import exportToExcel from '@shared/helpers/export-to-excel.js'
  import Pdf from '@shared/helpers/exportToPdf/pdf'
  import paginateArray from '@shared/helpers/paginate-array'

  import HeaderWithTooltip from '../../shared/Table/HeaderWithTooltip'
  import ScoreCell from '@statistics/shared/Table/ScoreCell'
  import NisOpportunityCell from '@statistics/shared/Table/NisOpportunityCell'
  import NisSatisfactionGoalCell from '@statistics/shared/Table/NisSatisfactionGoalCell'

  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import NisMixin from '@statistics/shared/nis_mixin'


  export default {
    name: "CampaignTableWidget",
    mixins: [
      WidgetMixin,
      NisMixin,
    ],
    components: {
      HeaderWithTooltip,
      ScoreCell,
      NisOpportunityCell,
      NisSatisfactionGoalCell
    },
    props: {
      eligibleCampaigns: { type: Array, required: true },
      selectedCampaign: { type: Object },
      title: { type: String },
      cols: { type: Number, default: 6 },
      maxHeight: { type: String, default: "360px" },
      minHeight: { type: String, default: "360px" },
      npsData: { type: Array },
    },
    computed: {
      ...mapGetters([
        'currentDashboardScopedCampaigns',
        'dashboardFilterDates',
        'datesScope',
        'dashboardFilterRequest',
        'dashboardFilterReady',
        'placeIdsScope',
      ]),
      isLoading() {
        return Object.values(this.npsData).length == 0
      },
      sortBy() {
        return this.npsData.some(data => !!data.nisOpportunity) ?
               'nisOpportunity' :
               'score'
      },
      repartitionWidth() {
        return (!this.showNisColumns || this.$vuetify.breakpoint.mdAndUp) ? '200px' : "100px"
      },
      widgetHeight() {
        if (this.isLoading) {
          return '150px'
        }

        const headerHeight = 65
        const tableHeaderHeight = 50
        const tableMargins = 16
        const height = headerHeight +
                       tableHeaderHeight +
                       this.npsData.length * 50 +
                       tableMargins

        return height > 310 ? '310px' : `${height}px`
      },
      exportOptions() {
        return [
          { title: 'PDF', onClick: this.exportToPdf },
          { title: 'Excel', onClick: this.exportToExcel }
        ]
      },
      showNisColumns() {
        return this.nisMixin_canUseNisFeature(this.eligibleCampaigns, false)
      },
      scoreColumnWidth() {
        if (!this.showNisColumns) {
          return null
        }

        if (this.$vuetify.breakpoint.mdAndUp) {
          return '300px'
        }

        return '200px'
      },
      headers() {
        let headers = [
          {
            text: _upperFirst(this.$t('campaign')),
            value: 'name',
            align: 'start',
            cellClass: 'name-cell'
          },
          {
            text: this.$t('nps'),
            value: 'score',
            cellClass: 'score-cell',
            sortable: false,
            width: this.scoreColumnWidth
          },
          {
            text: this.nisMixin_opportunityHeaderTitle(this.eligibleCampaigns),
            value: 'nisOpportunity',
            align: 'center',
            tooltip: this.nisMixin_opportunityHelpMessage(this.eligibleCampaigns),
            displayCondition: this.showNisColumns && this.$vuetify.breakpoint.smAndUp
          },
          {
            text: this.$t('campaign_table_widget_goal'),
            value: 'nisSatisfactionGoal',
            sortable: false,
            displayCondition: this.showNisColumns && this.$vuetify.breakpoint.mdAndUp
          }
        ]

        return headers.filter((header) => header.displayCondition === undefined || header.displayCondition)
      }
    },
    methods: {
      zoomOnNps(item) {
        this.$router.push({
          name: 'Statistics',
          params: {
            sectionType: 'nps',
            sectionId: item.id,
            subSectionId: item.id
          }
        })
      },
      exportFileName() {
        const pageName = this.$t(this.$route.name)
        const date = this.$date().locale(this.$i18n.locale).format('ddd DD MMM HH_mm_ss')

        return [pageName, date].join(' - ')
      },
      exportToExcel() {
        this.$store.dispatch("notifyInfo");

        const fileName = this.exportFileName();

        const pageName = this.$t(this.$route.name);
        const sheetName = [pageName].join(' - ');

        const data = this.npsData.map((campaign) => {
          const { id, ...filteredMapping } = campaign;
          return filteredMapping;
        });

        const headers = Object.keys(data[0]).map((key, index) =>
          this.$t(`statistics_widget_nps_ranking_headers_${key}`)
        );

        exportToExcel(fileName, sheetName, data, headers);
      },
      async exportToPdf() {
        const exportHeader = async() => {
          const title = this.title || this.$t('statistics_widget_campaigns_nps_ranking_title')
          await pdf.addRow({}, async(row) => {
            await row.addCol({}, async(col) => {
              col.addText(title, { fontSize: fontSize, color: blackColor, font: { style: 'bold' }})
            })
          })
          await pdf.addRow({ marginTop: 5 }, async(row) => {
            await row.addCol({}, async(col) => {
              col.addText(this.displayedSubtitle, { fontSize: fontSize - 2, font: { style: 'bold' }})
            })
          })

          await exportTableHeaders(pdf)
        }

        const exportTableHeaders = async(pdf) => {
          await pdf.addRow({ height: 15, borderColor: borderColor, marginTop: 20 }, async(row) => {

            await row.addCol({ width: campagnNameWidth }, async(col) => {
              col.addText(this.$t('campagn_name'), { fontSize: fontSize - 2 })
            })

            await row.addCol({ width: repartitionWidth, marginLeft: 10 }, async(col) => {
              col.addText(this.$t('repartition'), { fontSize: fontSize - 2 })
            })
          })
        }

        const exportEvolution = async(evolution, col, decimals = 0) => {
          let displayedEvolution
          let evolutionSign
          let evolutionColor

          if (evolution && evolution != 0) {
            displayedEvolution = evolution > 0 ? evolution : - evolution
            displayedEvolution = displayedEvolution.toFixed(decimals)
            evolutionSign = evolution > 0 ? '▲': '▼'
            evolutionColor = evolution > 0 ? '#65c095': '#c86868'
          } else {
            displayedEvolution = '='
            evolutionSign = ''
            evolutionColor = '#add8e6'
          }

          await col.addRow({ height: 18 }, async(row) => {
            await row.addCol({ height: 18, marginLeft: 4 }, async(col) => {
              col.addText(evolutionSign, { color: evolutionColor, fontSize: 4 })
            })
            await row.addCol({ height: 18 }, async(col) => {
              col.addText(displayedEvolution, { color: evolutionColor, fontSize: fontSize })
            })
          })
        }

        const exportCampaignName = async (item, row) => {
          await row.addCol({ width: campagnNameWidth, height: rowHeight}, async(col) => {
            col.addText(item.name, { fontSize: fontSize, color: blackColor })
          })
        }

        const exportRepartition = async (item, row) => {
          if (item.nbReview) {
            await row.addCol({ width: repartitionWidth, marginLeft: 10 }, async(col) => {
              await col.addRow({ marginTop: marginTopWithSubline }, async(row) => {
                await row.addCol({}, async(col) => {
                  col.addRepartitionBar({ width: 200, height: 18, promoterCount: item.nbPromoters, neutralCount: item.nbNeutrals, detractorCount: item.nbDetractors })
                })
                await row.addCol({ marginLeft: 5, height: 18 }, async(col) => {
                  col.addText(parseInt(item.score).toString(), { fontSize: fontSize, color: blueColor, font: { style: 'bold' }})
                })
                await row.addCol({ height: 18 }, async(col) => {
                  await exportEvolution(item.evolution, col, 2)
                })
              })
              await col.addRow({ marginTop: 2, marginLeft: 2 }, async(row) => {
                await row.addCol({}, async(col) => {
                  col.addText(
                    `${this.$t('rankingPdfExport.nbReview', { nbReview: item.nbReview })}`,
                    { fontSize: subLineFontSize }
                  )
                })
              })
            })
          }
        }

        const exportFooter = async(pdf) => {
          const pagination = this.$t('rankingPdfExport.pagination', { page: pdf.pageCount, totalPage: paginatedData.length })
          const title = this.title || this.$t('statistics_widget_indicators_ranking_title')
          const footer = [title, this.defaultSubtitle].join(' - ')

          await pdf.addRow({}, async(row) => {
            await row.addCol({ width: '12' }, async(col) => {
              col.addText(footer, { fontSize: fontSize - 2, align: 'right' })
            })
          })
        }

        this.$store.dispatch("notifyInfo")

        const rowHeight = 35
        const campagnNameWidth = '7'
        const repartitionWidth = '5'
        const fontSize = 8
        const subLineFontSize = 6
        const marginTop = 6
        const marginTopWithSubline = 4
        const blackColor = "#212121"
        const blueColor = "#1e547b"
        const borderColor = "#e0e0e0"
        const fileName = `${this.exportFileName()}.pdf`

        const pdf = new Pdf({
          defaultBodyMargin: { left: 40, top: 0 },
          defaultHeader: { marginLeft: 40, marginTop: 10, content: exportTableHeaders },
          defaultFooter: { height: 25, marginLeft: 40, content: exportFooter }
        })

        const paginatedData = paginateArray(this.npsData, 43, 42)

        await paginatedData.reduce(async (pagePromise, pageDate, pageIndex) => {
          await pagePromise

          if (pagePromise) {
            await pdf.addPage()
          } else {
            await pdf.addPage({
              header: { marginLeft: 40, marginTop: 20, content: exportHeader }
            })
          }

          await pageDate.reduce(async (itemPromise, item, itemIndex) => {
            await itemPromise

            let rowParams = { height: rowHeight, borders: ['top', 'bottom'], borderColor: borderColor }

            await pdf.addRow(rowParams, async(row) => {
              await exportCampaignName(item, row)
              await exportRepartition(item, row)
            })

          }, undefined)

        }, undefined)

        pdf.save(fileName)
      },
      rowClasses(item) {
        return item.id === this.selectedCampaign.id ? 'item active' : 'item'
      }
    }
 }
</script>

<style lang="stylus">
  .campaign-table-widget
    .name-cell
      .name
        text-wrap: initial

    .sort-margin
      padding-right: 18px
</style>
