<template>
  <div class="collaborators pa-3">
    <div class="alerting-header">
      <CollaboratorsAlerting
        :alertingListLoading="this.alertingListLoading"
        :totalInactiveCollaborator="this.totalInactiveCollaborator"
        :totalEtsWihoutCollaborator="this.totalEtsWihoutCollaborator"
        :activateFilter="activateFilter"
        style="display: none"
      />
    </div>
    <div class="mb-3">
      <keep-alive>
        <CollaboratorsFilters
          :items="this.groupsPlacesList"
          :allPerimeterCount="this.allPerimeterCount"
          @filterPlace="filterPlace"
          @filterUser="filterUser"
          @filterActivated="handleFilterActivation"
          @filterUsergroup="handleFilterUsergroup"
          @toggleRightbarUser="handleRightBarUserToggle"
          :selectedFilterValue="this.selectedFilterValue"
          :exportCollaborators="exportCollaborators"
          :loading="this.groupPlaceLoading"
          :rightBarIsLoading="rightBarIsLoading"
          :userGroupsList="allUserGroupsList"
          :totalInactiveCollaborator="this.totalInactiveCollaborator"
          :totalDeactivatedCollaborators="this.totalDeactivatedCollaborators"
          :totalEtsWihoutCollaborator="this.totalEtsWihoutCollaborator"
          :alertingListLoading="this.alertingListLoading"
        />
      </keep-alive>
    </div>
    <div v-if="!isLoading">
      <v-expansion-panels
        flat
        v-for="item in this.list"
        multiple
        :key="item.placeId + '_panel'"
        accordion
        class="py-1 panel"
        :value="expanded"
      >
        <v-expansion-panel
          expand
          :key="item.place"
          v-if="(selectedPlace === null || selectedPlace === -1) || selectedPlace === item.place_id"
        >
          <v-expansion-panel-header
            @mouseover="hoverRow(item)"
            @mouseleave="unhoverRow()"
            :class="{ 'row-hover rounded': item === hoveredItem  }"
          >
            <template v-slot:actions>
              <div>
                <v-btn small tile icon elevation="1"
                  class="bg-blue-astra expansion-panel-header-btn"
                >
                  <v-icon small color="white">$expand</v-icon>
                </v-btn>
              </div>
            </template>
              <v-row no-gutters class="justify-center align-center">
                <v-col class="place-name fs-14 c-primary" v-if="item.allPlaces">
                  <v-icon size="15" class="bottom_1px c-primary">mdi-web</v-icon>
                  <span class="fs-14">{{ $t('collaborator_page_all_establishment') }}</span>                
                </v-col>
                <v-col class="place-name fs-14 c-primary" v-else>
                  <w-icon :icon="getIconName(item)" class="f-14 bottom_1px mr-1" v-if="item.semantic != 'places'"/>
                  <v-icon size="15" class="bottom_1px c-primary" v-else>mdi-map-marker</v-icon>
                  <span class="fs-14" v-if="item.semantic == 'places'">{{ item.place }} </span>
                  <span class="fs-14" v-else>{{ item.place }}</span> 
                  <span class="f-12" v-if="item.semantic != 'places'">- {{ semanticTranslation(item) }}</span>
                </v-col>
                <v-col :class="{ 'text-align-center': $vuetify.breakpoint.smAndDown }">
                  <span class="fs-12 c-primary">
                    {{ item.collaboratorCount }} {{ $t('collaborator_page_collaborators') }}
                  </span>
                  <span class="fs-12 c-primary" v-if="filterIsPresent"> {{ $t('collaborator_page_corresponding') }} </span>
                  <v-icon v-if="item.usersList.length == 0" class="c-error f-14">mdi-alert</v-icon>
                </v-col>
                <v-col class="pl-4">
                  <span class="fs-12 c-primary">{{ item.placeCount }} {{ $t('collaborator_page_establishment') }}</span>
                </v-col>
                <v-spacer class="flex-shrink-1"></v-spacer>
                <v-col class="d-flex justify-end align-center" cols="auto">
                  <v-btn
                    v-if="!rightBarIsLoading && hasRightEditCollaborators"
                    small tile icon elevation="1"
                    class="mr-2 expansion-panel-header-btn"
                    @click.stop="handleRightBarUserToggle(true, item)"
                  >
                    <v-icon small color="primary">mdi-plus</v-icon>
                  </v-btn>
                </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="collab-panel-content">
            <CollaboratorsList
              :item="item"
              :rightBarIsLoading="rightBarIsLoading"
              @updateUsersList="updateUsersList"
              @toggleRightbarUser="handleRightBarUserToggle"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>      
      </v-expansion-panels>
      <v-card v-if="this.list.length == 0" class="pa-4 c-primary text-align-center" height="50px">
        <p v-if="this.filterUser != null || s.filterUser != ''">
          {{ $t('collaborator_page_no_user_found') }}
        </p>
        <p v-else>
          {{ $t('no_data_label') }}
        </p>
      </v-card>
    </div>
    <div v-else class="py-1">
      <v-skeleton-loader type="paragraph" class="px-4 pb-2 pt-4 panel v-expansion-panel bg-white" />
    </div>
    <div>
      <v-card v-if="parseInt(options.length) > 1" flat class="pa-1 mt-1 d-flex justify-start f-12">
        <v-pagination
          class="f-12"
          color="accent"
          v-model="options.page" :length="parseInt(options.length)"
          :total-visible="parseInt(options.length)"></v-pagination>
      </v-card>
    </div>

    <CollaboratorsEditRightBar
      :rightbarActionData="rightbarActionData"
      :editionUserIdData="editionUserIdData"
      :isRightbarUserOpen="isRightbarUserOpen"
      :userGroupsList="userGroupsWithAccess"
      :placeGroupsList="placeGroupsList"
      :placeGroupId="placeGroupId"
      @toggleRightbarUser="handleRightBarUserToggle"
      @reloadUserList="reloadUserList"
    />
  </div>

</template>
<script>
  import { mapGetters } from 'vuex'
  import _debounce from 'lodash/debounce'
  import CollaboratorsFilters from './CollaboratorsFilters'
  import CollaboratorsList from './CollaboratorsList'
  import CollaboratorsAlerting from './CollaboratorsAlerting'
  import CollaboratorsEditRightBar from './CollaboratorsEditRightBar'
  import CollaboratorsMixin from './collaborators_mixin'

  export default {
    name: "Collaborators",
    components: {
      CollaboratorsAlerting,
      CollaboratorsFilters,
      CollaboratorsList,
      CollaboratorsEditRightBar,
    },
    mixins: [
      CollaboratorsMixin
    ],
    data() {
        return {
          nbUsersCreated: 0,
          totalInactiveCollaborator: null,
          isRightbarUserOpen: false,
          totalDeactivatedCollaborators: null,
          totalEtsWihoutCollaborator: null,
          isLoading: false,
          placeGroupsListLoading: false,
          alertingListLoading: false,
          userGroupsListLoading: false,
          selectedPlace: null,
          allPerimeterCount: 0,
          placeGroupId: null,
          groupsPlacesList: [],
          panel: [0],
          selectedFilterValue: null,
          list: null,
          options: {
            page: 1,
            itemsPerPage: 15,
            length: 1,
          },
          filterWithoutCollaborator: false,
          filterPlaceName: '',
          filterCollaboratorInactive: false,
          filterDeactivatedCollaborators: false,
          userFilter: null,
          usergroupFilter: null,
          filterUsergroup: null,
          hoveredItem: null,
          filterIsPresent: false,
          headers: [
              { text: 'collaborator', value: 'email', class: 'table-header', width: '285px'},
              { text: 'lastConnection', value: 'lastConnection', class: 'table-header', width: '230px'},
              { text: 'perimeters', value: 'establisment', class: 'table-header' },
              { text: 'group', value: 'group', class: 'table-header' },
              { text: '', value: 'actions', class: 'table-header' },
          ],
          no_results_text: this.$t('collaborator_page_empty_table'),
          expanded: [],
          rightbarActionData: null,
          editionUserIdData: null
        }
    },
    computed: {
      ...mapGetters([
        'currentUserIsAdmin',
        'currentDashboard',
        'isDashboardMulti',
        'currentDashboardCampaignIds',
        'currentDashboardCampaignById',
        'currentDashboardBasedTable',
        'currentUser',
        'currentUserId',
        'hasRightEditCollaborators'
      ]),
      rightBarIsLoading() {
        return this.isLoading || this.placeGroupsListLoading
      },
      listByIndex() {
        return this.list.reduce((hash, perimeter, index) => {
          hash[perimeter.placeId] = index
          return hash
        }, {})
      },
      dashboardType() {
        return this.isDashboardMulti ? 'dashboard_multis' : 'dashboard_basics'
      },
    },
    asyncComputed: {
      async placeGroupsList() {
        this.placeGroupsListLoading = true
        const dashboardType = this.dashboardType
        const request = this.$basedRequest().select({
          [`${dashboardType}`]: [ 
            { collaborator_place_group_tree: { as: "tree" } },
            { collaborator_place_group_label_by_id: { as: "labelById" } }
          ]
        }).where(
          { id: this.currentDashboard.id }
        )
        const response = (await this.$resolve(request)).first()
        this.placeGroupsListLoading = false

        return response
      },
      allUserGroupsList: {
        default: [],
        async get() {
          /* Will fetch all perimeters and places with the current user */
          const dashboardType = this.dashboardType
          let allUserGroupsList = [];
          let req = null;
          this.userGroupsListLoading = true;
          let select = {
            [`${dashboardType}`]: [{
              "collaborator_users_groups_list": {
                params: {
                  filterCollaboratorInactive: this.filterCollaboratorInactive,
                  filterDeactivatedCollaborators: this.filterDeactivatedCollaborators,
                  filterPlaceName: this.filterPlaceName,
                  filterWithoutCollaborator: this.filterWithoutCollaborator,
                  filterUser: this.userFilter,
                  filterUsergroup: this.usergroupFilter,
                  offset: (this.options.page - 1) * this.options.itemsPerPage,
                  limit: this.options.itemsPerPage,
                }
              }
            }]
          }
          req = this.$basedRequest().select(select).where({
            id: this.currentDashboard.id
          })
  
          let reqResult = await this.$resolve(req)
          let result = null;
          if (this.isDashboardMulti){
            result = reqResult.data?.dashboardMultis
          }
          else {
            result = reqResult.data?.dashboardBasics
          }
  
          if (typeof result != 'undefined' && result.length > 0) {
            allUserGroupsList = result[0].collaboratorUsersGroupsList;
          }
          this.userGroupsListLoading = false;
          return allUserGroupsList
        },
      },
      async groupAndPlaces() {
        /* Will fetch all perimeters and places with the current user */
        const dashboardType = this.dashboardType
        let req = null;
        this.groupPlaceLoading = true;
        let select = {
          [`${dashboardType}`]: [{
            "collaborators_perimeters_list": {
              params: {
                filterCollaboratorInactive: this.filterCollaboratorInactive,
                filterDeactivatedCollaborators: this.filterDeactivatedCollaborators,
                filterPlaceName: this.filterPlaceName,
                filterWithoutCollaborator: this.filterWithoutCollaborator,
                filterUser: this.userFilter,
                filterUsergroup: this.usergroupFilter,
                offset: (this.options.page - 1) * this.options.itemsPerPage,
                limit: this.options.itemsPerPage,
              }
            }
          }]
        }
        req = this.$basedRequest().select(select).where({
          id: this.currentDashboard.id
        })

        let reqResult = await this.$resolve(req)
        let result = null;
        if (this.isDashboardMulti){
          result = reqResult.data?.dashboardMultis
        }
        else {
          result = reqResult.data?.dashboardBasics
        }

        if (typeof result != 'undefined' && result.length > 0) {
          this.groupsPlacesList = result[0].collaboratorsPerimetersList;
        }
        this.groupPlaceLoading = false;
      },
      async collaboratorTotalsData() {
        const dashboardType = this.dashboardType
        let req = null;
        this.alertingListLoading = true;
        let select = {
          [`${dashboardType}`]: [
            "collaborators_alerting"
          ]
        }
        req = this.$basedRequest().select(select).where({
          id: this.currentDashboard.id
        })

        let reqResult = await this.$resolve(req)
        let result = null;
        if (this.isDashboardMulti){
          result = reqResult.data?.dashboardMultis
        }
        else {
          result = reqResult.data?.dashboardBasics
        }

        if (typeof result != 'undefined' && result.length > 0) {
          this.totalInactiveCollaborator = result[0].collaboratorsAlerting.totalInactifUsers;
          this.totalEtsWihoutCollaborator = result[0].collaboratorsAlerting.totalWithoutUsers;
          this.totalDeactivatedCollaborators = result[0].collaboratorsAlerting.totalDeactivatedUsers;
        }
        this.alertingListLoading = false;
      },
      // pour la listes des utilisateurs
      collaboratorsByUserPlace: {
        async get() {
          const dashboardType = this.dashboardType
          this.isLoading = true
          let request = this.$basedRequest().select({
            [`${dashboardType}`]: [{
                "collaborators_list": {
                  params: {
                    filterCollaboratorInactive: this.filterCollaboratorInactive,
                    filterDeactivatedCollaborators: this.filterDeactivatedCollaborators,
                    filterPlaceName: this.filterPlaceName,
                    filterWithoutCollaborator: this.filterWithoutCollaborator,
                    filterUser: this.userFilter,
                    filterUsergroup: this.usergroupFilter,
                    offset: (this.options.page - 1) * this.options.itemsPerPage,
                    limit: this.options.itemsPerPage,
                  }
                }
              }
            ]
          }).where({
            id: this.currentDashboard.id
          })
          
          let reqResult = await this.$resolve(request)
          let result = null
          if (this.isDashboardMulti){
            result = reqResult.data?.dashboardMultis
          }
          else {
            result = reqResult.data?.dashboardBasics
          }
          this.isLoading = false
          if (typeof result != 'undefined' && result.length > 0) {
            this.list = result[0].collaboratorsList.allData
            let allItems = result[0].collaboratorsList.itemsCount
            if (this.allPerimeterCount == 0) {
              this.allPerimeterCount = result[0].collaboratorsList.itemsCount
            }
            this.options.length = Math.ceil(allItems / this.options.itemsPerPage)
          }
        },
        watch: [
          'nbUsersCreated', 'filterPlaceName', 'filterCollaboratorInactive', 'filterDeactivatedCollaborators',
          'filterWithoutCollaborator', 'userFilter', 'usergroupFilter'
        ],
      },
      userGroupsWithAccess: {
        async get() {
          const dashboardType = this.dashboardType
          const request = this.$basedRequest().select({
            [`${dashboardType}`]: [ 
              { collaborator_user_groups_with_access: { as: "userGroupsWithAccess" } }
            ]
          }).where(
            { id: this.currentDashboard.id }
          )
          const res = (await this.$resolve(request))
          const response = res.first().userGroupsWithAccess
          return response
        },
        default: [],
      },
    },
    methods: {
      reloadUserList(nbUserCreated) {
        this.nbUsersCreated += nbUserCreated
      },
      onOpenSidebar(item) {
        this.isRightbarUserOpen = true
      },
      handleRightBarUserToggle(open, item, action = 'create') {
        this.rightbarActionData = null
        this.editionUserIdData = null
        this.placeGroupId = null

        if (item) {
          if (action == 'create') {
            this.placeGroupId = item.allPlaces ? 'allPlaces' : `${item.semantic}-${item.placeId}`
          } else {
            this.editionUserIdData = item.id
            this.placeGroupId = null
          }
        } else {
          this.placeGroupId = null
        }
        this.rightbarActionData = action
        this.isRightbarUserOpen = open
      },
      handleFilterActivation(selectedValue) {
        this.collaboratorsFilterActivation(selectedValue) 
      },
      activateFilter(filterValue) {
        this.selectedFilterValue = filterValue;
        this.$emit("filterActivated", filterValue);
      },
      filterPlace(selectedValue) {
        if (selectedValue == 'my_perimeters') {
          selectedValue = null;
        }
        this.filterPlaceName = selectedValue;
      },
      collaboratorsFilterActivation(selectedValue) {
        this.filterWithoutCollaborator = false;
        this.filterCollaboratorInactive = false;
        this.filterDeactivatedCollaborators = false

        if (selectedValue) {
          if (this.options.page > 1) {
            this.options.page = 1;
          }
          if (selectedValue == 1) this.filterCollaboratorInactive = true;
          if (selectedValue == 2) this.filterWithoutCollaborator = true;
          if (selectedValue == 3) this.filterDeactivatedCollaborators = true
          
        } else {
          if (this.options.page > 1) {
            this.options.page = 1;
          }
        }
        this.selectedFilterValue = selectedValue;
        this.groupAndPlaces;
      },
      filterUser: _debounce(async function(searchedUser) {
        if (searchedUser != null && searchedUser != '') {
          this.userFilter = searchedUser
        } else {
          this.userFilter = null
        }
      }, 500),
      handleFilterUsergroup(searchedUsergroup) {
        this.usergroupFilter = null;
        if (searchedUsergroup != null && searchedUsergroup != "") {
          this.usergroupFilter = searchedUsergroup;
        }
      },
      updateText(){
        this.no_results_text = this.$t('collaborator_page_empty_table')
      },
      async exportCollaborators() {
        try {
          const fileName = `Export Collaborator list`
          this.$store.dispatch('notifyInfo', { message: fileName + ' en cours' })
          const response = await this.$api.wizville.users.exportCollaboratorCVS(
            this.currentUserId,
            this.currentDashboard.type,
            this.currentDashboard.id,
            this.filterPlaceName,
            this.filterCollaboratorInactive,
            this.filterDeactivatedCollaborators,
            this.selectedUserIds(),
          )

          if (response.csv) {
            this.$textToFile(fileName + '.csv', response.csv)
          }

          this.$store.dispatch('notifySuccess', {
            message: response.message,
            timeout: 5000,
          })
        } catch (e) {
          this.$store.dispatch('notifyError', {
            message: e.message,
            timeout: 5000,
          })
        }
      },
      selectedUserIds() {
        let selectedUserIds = []
        this.list.forEach((perimeter) => {
          perimeter.usersList.forEach((user) => {
            if (user.selected) {
              selectedUserIds.push(user.id);
            }
          })
        })
        return Array.from(new Set(selectedUserIds))
      },
      updateUsersList(newUsersList, perimeter) {
        const indexPerimeter = this.listByIndex[perimeter.placeId]
        this.list[indexPerimeter].usersList = newUsersList
      },
      selectedUserIds() {
        let selectedUserIds = []
        this.list.forEach((perimeter) => {
          perimeter.usersList.forEach((user) => {
            if (user.selected) {
              selectedUserIds.push(user.id);
            }
          })
        })
        return Array.from(new Set(selectedUserIds))
      },
      updateUsersList(newUsersList, perimeter) {
        const indexPerimeter = this.listByIndex[perimeter.placeId]

        this.list[indexPerimeter].usersList = newUsersList
      },
      semanticTranslation(item) {
        if (item.semantic == 'places') {
          return this.$t('collaborator_page_accordeon_establishment');
        } else if ( item.semantic == 'brand') {
          return this.$t('collaborator_page_brand');
        }
        return this.$t(this.collaboratorsMixin_getSemanticTranslation(item));
      },
      getIconName(item) {
        if (item.semantic == "places") {
          return 'map-marker';
        } else {
          return 'map-markers';
        }
      },
      hoverRow(item) {
        this.hoveredItem = item;
      },
      unhoverRow() {
        this.hoveredItem = null;
      },
      expandOrUnexpand(value) {
        if (value) {
          this.expanded = [0];
        } else {
          this.expanded = [];
        }
      },
      filterPresence(value) {
        if (value == null || value == '') {
          this.filterIsPresent = false;
          return false;
        }
        if (typeof value == Boolean){
          if (value) {
            this.filterIsPresent = true;
            return true;
          }
        }
        if (value !== '' || value !== null) {
          this.filterIsPresent = true;
          return true;
        }
      },
    },
    watch: {
      filterPlaceName: function(value) {
        this.expandOrUnexpand(value);
        this.filterPresence(value);
      },
      filterDeactivatedCollaborators: function(value) {
        this.expandOrUnexpand(value);
        this.filterPresence(value);
      },
      filterCollaboratorInactive: function(value) {
        this.expandOrUnexpand(value);
        this.filterPresence(value);
      },
      filterWithoutCollaborator: function(value) {
        this.expandOrUnexpand(value);
        this.filterPresence(value);
      },
      userFilter: function(value) {
        this.expandOrUnexpand(value);
        this.filterPresence(value);
      },
      usergroupFilter: function(value) {
        this.expandOrUnexpand(value);
        this.filterPresence(value);
      }
    }
  }
</script>
<style lang="stylus">
  @import '~@theme/colors.styl'
  .collaborators
    .v-expansion-panel-content__wrap
      padding: 0
    .v-expansion-panel-header
      padding: 0px 16px;
    .icon-style
      min-height: 32px
      max-height: 32px
      min-width: 32px
      max-width: 32px
    .v-expansion-panel-content>>> .v-expansion-panel-content__wrap
        padding: 0 !important;
    .row-hover
      border: 1px solid var(--blue-astra)!important
    .v-sheet.v-card:not(.v-sheet--outlined)
      color: var(--lightgrey)!important
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 1px 5px 0px rgba(0, 0, 0, 0.07)
    .v-expansion-panel-header.v-expansion-panel-header--active
      max-height: 56px!important;
      min-height: 56px!important;
    .expansion-panel-header-btn
      border-radius: 4px
      height: 24px
      width: 24px

</style>
