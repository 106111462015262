import { getEventList, payloadEvent } from "@shared/helpers/eventList"
import Vue from "vue"

const eventList = getEventList({
  voter: {
    payload: [ 'voter' ],
    events: [ 'update']
  }
})

const events = {
  state: () => ({
    events: {}
  }),
  getters: {
    voterEvent(state) { return state.events.voter },
    voterUpdateEvent(state) { return state.events.voterUpdate }
  },
  mutations: {
    events(state, events) {
      for (const [eventName, eventPayload] of Object.entries(events)) {
        if (eventList[eventName]) {
          let payload = null

          for (const eventParentName of Object.values(eventList[eventName].parents)) {
            payload = payloadEvent(eventList, eventParentName, eventPayload)
            
            if (payload) {
              Vue.set(state.events, eventParentName, payload)
            }
          }
          
          payload = payloadEvent(eventList, eventName, eventPayload)
          
          if (payload) {
            Vue.set(state.events, eventName, payload)
          }
        }
      }      
    },
  },
  actions: {
    events(context, events) {
      context.commit('events', events)
    }
  }
}

export default events

