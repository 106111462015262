import BasedQuery from "@api/based/basedQuery"

const preferences = {
  state: () => ({ 
    isLrmEligible: false,
    competitorMode: null,
    rankingRelatedIndicator: 'avg_score',
    minimalNumberOfFeedbackForRanking: 1,
  }),
  getters: {
    isLrmEligible(state, getters) {
      return state.isLrmEligible
    },
    competitorMode(state, getters) {
      return state.competitorMode
    },
    rankingRelatedIndicator(state, getters) {
      return state.rankingRelatedIndicator
    },
    minimalNumberOfFeedbackForRanking(state, getters) {
      return state.minimalNumberOfFeedbackForRanking
    },
  },
  mutations: {
    setDashboardPreferences(state, context) {
      state.rankingRelatedIndicator = context.rankingRelatedIndicator
      state.minimalNumberOfFeedbackForRanking = context.minimalNumberOfFeedbackForRanking
    },
    setDashboardLrmPreferences(state, { isLrmEligible, competitorMode }) {
      state.isLrmEligible = isLrmEligible
      state.competitorMode = competitorMode
    }
  },
  actions: {
    async setDashboardPreferences(context, dashboardPayload) {
      const dashboardBasedRequestName = dashboardPayload.dashboardType == "DashboardBasic" ? "dashboard_basics" : "dashboard_multis"

      const dashboardRequest = (await new BasedQuery({ queryName: "lrm_dashboard_gmb_preferences" })
        .select({
          [dashboardBasedRequestName]: ["lrm_dashboard_gmb_preferences"]
        }).where({
          id: dashboardPayload.dashboardId
        }).resolve("setDashboardPreferences")).data

      const dashboardRequestDatas = Object.values(dashboardRequest)[0][0]

      context.commit("setDashboardLrmPreferences", { ...dashboardRequestDatas.lrmDashboardGmbPreferences }) 
    },
  },
  
}

export default preferences

