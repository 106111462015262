import Vue from 'vue'
import Router from 'vue-router'

import homeRouter from './home'
import dashboardRouter from './dashboard'
import dashboardsRouter from './dashboards'
import settingsRouter from './settings'
import campaignPreferencesRouter from './campaign_preferences'
import voterThirdPartyTransfer from './voterThirdPartyTransfer'

import NotFound from '@shared/errors/notFound.vue'

import { initSessionGuards } from '@shared/router/sessionGuards'
import { initBeforeEachGuards } from '@shared/router/beforeEachGuards'

import store from "@platform/store"
import DashboardsLayout from '@platform/layouts/DashboardsLayout'
import { sendMessageToMobileApp } from "@shared/helpers/mobile-app"

Vue.use(Router)

const router = new Router({
  // This removes # from the route.
  mode: 'history',
  // Always scroll to top on page change.
  scrollBehavior(to, from) {
    // As per the documentation, the next line should work but it's not (for some occult
    // reason). So let's do this by hand.
    // return { top: 0 }
    if (to.path != from.path) {
      window.scrollTo({ top: 0 })
    }
  },
  routes: [
    homeRouter,
    dashboardRouter,
    dashboardsRouter,
    // previousDashboardRouter,
    settingsRouter,
    campaignPreferencesRouter,
    voterThirdPartyTransfer,
    // external,
    {
      component: DashboardsLayout,
      path: '*',
      children: [
        {
          path: '*',
          component: NotFound
        }
      ]
    }
  ]
})

router.afterEach((to, from) => {
  // Send signals that the app is ready.
  if (store.getters.appReady === false) {
    store.dispatch('appReady', true)
    sendMessageToMobileApp('app_loaded')
    store.dispatch("platformContext")
  }
  // Display flash message.
  if (localStorage.flashMessageUponReload) {
    store.dispatch('notifySuccess', {
      message: localStorage.flashMessageUponReload
    })
    localStorage.removeItem('flashMessageUponReload');
  }
})

initBeforeEachGuards(router)
initSessionGuards(router, store, "userContext")

function route() { return router.history.current }

window.vueRouter = router

export { router, route }
