<template>
  <div
    v-if="productsSampleData"
    class="settings-products-table"
    :style="{ overflowX: 'auto' }"
  >
    <v-data-table
      :headers="headers"
      :items="productsSampleData"
      hide-default-footer
      mobile-breakpoint="0"
      :header-props="headerStyles"
      class="v-datatable-without-y-borders"
    >
      <template v-for="(header) in headers" v-slot:[header.headerSlotName]="{ header }">
        <span :class="{'c-green': header.isProductValue }">{{ header.text }}</span>
      </template>
      <template v-for="(item) in headers" v-slot:[item.itemSlotName]="{ value }">
        <div
          v-for="(translation, locale) in value"
          :style="{ width: item.width }"
          class="translations-row"
        >
          <v-tooltip
            :key="locale"
            :maxWidth="450"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="text-truncate">
                <span v-if="locales?.length > 1" class="fs-13 c-primary">
                  {{ `${locale}: ` }}
                </span>
                <span>
                  {{ translation }}
                </span>
              </div>
            </template>
            <span>{{ translation }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </div>
  <v-skeleton-loader v-else type="paragraph" />
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "ProductsTable",
    props: {
      campaignId: {
        type: Number,
        required: true
      },
      productInfoTypes: {
        type: Array,
        required: true
      },
      locales: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        productsSampleData: undefined,
        headerStyles: {
          style: {
            borderTop: '1px solid black',
          }
        }
      }
    },
    methods: {
      async fetchProductsSampleData() {
        try {
          const response = await this.$api.wizville.productImportPreferences.productsSample(
            this.campaignId
          )
          if (response.status === 'ok') {
            return response.productsSample
          } else {
            return false
          }
        } catch (e) {
          this.$store.dispatch('notifyError', {
            message: e.message
          })
          return false
        }
      },
      transformToCamelCase(inputString) {
        return inputString.toLowerCase().replace(/_([a-z]|[1-9])/g, group => {
          return group.toUpperCase().replace('_', '')
        })
      }
    },
    asyncComputed: {
      setProductsSampleData: {
        async get() {
          let productsSampleData = await this.fetchProductsSampleData()
          if (productsSampleData) {
            // id seem's to be a reserved word in v-data-table
            productsSampleData.forEach(product => {
              product.identifier = product.id
              delete product.id
            })
            this.productsSampleData = productsSampleData
          }
        }, watch: ['productInfoTypes']
      }
    },
    computed: {
      headers() {
        const headers = this.productInfoTypes.map((productInfoType, index) => {
          let textTitle = productInfoType.value ? `${productInfoType.value}: ${productInfoType.columnName}` : productInfoType.columnName
          let camelCaseColumnName = this.transformToCamelCase(productInfoType.columnName)
          camelCaseColumnName = camelCaseColumnName === 'id' ? 'identifier' : camelCaseColumnName
          return {
            text: textTitle,
            value: camelCaseColumnName,
            isProductValue: !!productInfoType.value,
            align: 'start',
            sortable: false,
            width: '200px',
            itemSlotName: `item.${camelCaseColumnName}`,
            headerSlotName: `header.${camelCaseColumnName}`,
          }
        })
        return headers
      }
    }
  }
</script>
