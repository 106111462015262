<template>
  <div>
    <div v-if="!loading && !empty">
      <div class="text-align-left" v-if="this.showTitle">
        <div class="fs-12">
          {{ question.content }}
        </div>
      </div>

      <div class="qcm-question-histogram">
        <WWeightRepartition :items="formattedItems" :defaultBarColor="defaultBarColor" :weightUnit="weightUnit" />
      </div>
    </div>

    <v-skeleton-loader v-else-if="loading" type="paragraph" class="mt-3" />

    <div v-else-if="empty" class="mt-3">
      {{ $t('no_data_label') }}
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  const colors = ["#FFB800", "#9FBAB8", "#B27B59", "#DB3572", "#88C788", "#BA0022", "#2E1C4D", "#C48754", "#4EBDB7", "#4F64B6", "#C1CC75", "#DBA951", "#B96785", "#874B8B", "#4BAEE4", "#6F63BA", "#2699A6", "#9D8B52", "#7DBD96", "#278DDA", "#B473BB", "#98BBB8", "#98A255", "#E8DF81", "#BD7953", "#E2BCA4"]

  export default {
    name: "QcmQuestionHistogram",
    props: {
      question: null,
      barColor: null,
      showTitle: {
        default: true
      }
    },
    data() {
      return {
        defaultBarColor:  "red",
        defaultBarColorGreen: "green",
        weightUnit:  "%",
        loading: true,
        totalReview: 0
      }
    },
    methods: {
      async exportToPdf(pdf) {
        const nameWidth = '5'
        const borderColor = "#e0e0e0"
        await pdf.addRow({ height: 15, borderColor: borderColor }, async(row) => {
          await row.addCol({ width: nameWidth }, async(col) => {
            col.addText(this.question.content, { fontSize: 12, font: { style: 'bold' } })
          })
        })
        await pdf.addRow({ height: 5 })
        const baseWeight = this.formattedItems[0].weight
        await this.formattedItems.sort((a, b) => b.weight - a.weight).reduce(async (promise, item, index) => {
          await promise

          const height = pdf.computeTextHeight({
                           text: item.name,
                           fontSize: 12,
                           containerWidth: (pdf.currentPage.getWidth() / 12) * 6
                         }) + 20

          await pdf.addRow(
            { backgroundColor: (index % 2 === 0 ? '#fff' : '#f4f5f7'), height },
            async (row) => {
              await row.addCol({ width: '6', height }, async (col) => {
                col.addText(item.name, { fontSize: 11, overflow: 'linebreak' })
              })
              await row.addCol({ width: '1', height }, async (col) => {
                await col.addRectangle({ width: 30, height: 20, radius: 10, align: 'center', color: '#8a8a8a' })
                await col.addText(item.value, { overflow: 'linebreak', color:'white', align: 'center' })
              })
              await row.addCol({ width: '4', height }, async (col) => {
                const width = item.weight * col.width / baseWeight
                await col.addRectangle({ width, height: 20, radius: 4, color: this.barColor })
              })
              await row.addCol({ width: '1', height }, async (col) => {
                col.addText(`${item.weight}%`, { align: 'center', overflow: 'linebreak', color: '#1e547b', font: { style: 'bold' } } )
              })
            }
          )
        }, undefined)
      }
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest'
      ]),
      qcmQuestionChoicesWithOther() {
        return (
          this.choiceOther ?
          [
            ...this.question.qcmQuestionChoices,
            ...[{ id: this.choiceOther, choice: this.$t('qcm_question_histogram_other') }]
          ] :
          this.question.qcmQuestionChoices
        )
      },
      formattedItems() {
        return this.qcmQuestionChoicesWithOther.map((choice, index) => {
          const nbChoice = this.nbChoices[`nbChoice${choice.id}`] || 0

          return {
            name: choice.choice,
            weight: (this.totalReview > 0 ? Math.round((nbChoice * 100 / this.totalReview)) : 0),
            value: nbChoice,
            barColor: this.barColor || colors[index]
          }
        })
      },
      empty() {
        return (this.formattedItems?.reduce((tot, item) => tot = tot + item.value || 0, 0) || 0) === 0
      },
      choiceOther() {
        return (this.question.choiceOther ? 1 : null)
      }
    },
    asyncComputed: {
      nbChoices: {
        async get() {
          let nbChoices = []
          this.loading = true

          const columns = this.qcmQuestionChoicesWithOther.map((choice) => ({
            nb_qcm_question_answer_choice_: { question_id: this.question.id, choice_id: choice.id, as: `nbChoice${choice.id}` }
          }))

          const request = this.dashboardFilterRequest.select({
            voters: columns
          })

          nbChoices = (await this.$resolve(request)).first()

          const requestBis = this.dashboardFilterRequest.select({
            voters: [
              { 'nb_review_qcm_question_answer_by_question_id': { question_id: this.question.id, as: 'totalReview' } },
            ]
          })
          this.totalReview = (await this.$resolve(requestBis)).first()?.totalReview || 0

          this.loading = false
          return nbChoices
        },
        default: {}
      }
    },
    watch: {
      loading: function(loading) {
        this.$emit('loading', loading)
      }
    }
  }
</script>

<style lang="stylus" scoped>
</style>
