<template>
  <div
    class="call-report"
  >
    <w-timeline-element
      right
    >
      <template v-slot:avatar>
        <CompanyAvatar/>
      </template>

      <template v-slot:title>
        <div class="d-flex">
          <span
            class="text-truncate fb-14 c-primary"
          >
            {{ currentUserBrandName }}
          </span>
          <v-tooltip top>
            <template #activator="{on}">
              <span class="fb-14 c-primary" v-on="on">{{ createdAt }}</span>
            </template>
            {{ createdAtTooltip }}
          </v-tooltip>
        </div>
      </template>

      <template v-slot:actions>
        <w-drop-down-menu
          v-if="actions.length > 0"
          :items="actions"
          icon="mdi-dots-horizontal"
          :showArrow="false"
        />
      </template>

      <template v-slot:content>
        <div
          class="d-flex pa-4 rounded-lg bg-lightpurple"
          :class="{ 'editing': isEditing }"
        >
          <div
            class="d-flex flex-column ga-3"
            :style="{ width: '100%' }"
          >
            <div class="d-flex align-center ga-1">
              <v-icon class="f-16 c-primary">mdi-phone-log</v-icon>
              <span class="c-primary fb-14">
                {{ $t('call_report') }}
              </span>
            </div>
            <pre
              class="c-darkergrey f-14 content"
            >
             {{callReport.content}}
            </pre>

            <v-divider></v-divider>
            <div class="d-flex flex-row align-center internal-memo-footer">
              <div>
                <v-icon small>mdi-account</v-icon>
                <span class="f-12">
                  {{ callReportFrom }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </w-timeline-element>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CompanyAvatar from '../../CompanyAvatar'

  export default {
    name: "call-report",
    props: {
      callReport: { required: true },
      voter: { required: true }
    },
    components: {
      CompanyAvatar
    },
    data () {
      return {}
    },
    computed: {
      ...mapGetters([
        'currentUser',
        'currentVoterEditingCallReport',
        'currentUserBrandName',
        'currentVoterShowActions',
        'currentVoterActionsTab'
      ]),
      createdAt() {
        return this.$t('voter_timeline_element_created_at', { date: this.$helpers.dates.short(this.callReport.createdAt, false) })
      },
      createdAtTooltip() {
        return this.$helpers.dates.short(this.callReport.createdAt, true)
      },
      callReportFrom() {
        return this.callReport.userEmail;
      },
      actions() {
        return [
          { title: this.$t('edit_call_report'), onClick: this.edit, class: 'edit', icon: 'mdi-pencil' },
          { title: this.$t('delete_call_report'), onClick: this.destroy, class: 'delete', icon: 'mdi-trash-can-outline' }
        ]
      },
      isEditing() {
        return this.currentVoterShowActions &&
          this.currentVoterActionsTab === 'write_up' &&
          this.currentVoterEditingCallReport?.uniqueId === this.callReport.uniqueId
      }
    },
    methods: {
      edit() {
        this.$store.dispatch('setEditingCallReport',
          {
            uniqueId: this.callReport.uniqueId,
            calledAt: this.callReport.calledAt,
            content: this.callReport.content,
          }
        )
        this.$store.dispatch('setActionsTab', 'write_up')
        this.$store.dispatch('setShowVoterActions', true)
      },
      async destroy() {
        try {
          const response = await this.$api.wizville.callReports.destroy(
            this.voter.uniqueId,
            this.callReport.uniqueId
          )

          if (response.status === "ok") {
            this.$store.dispatch('notifySuccess', { message: this.$t('flash_message_successfully_deleted_call_report'), timeout: 5000 })
            this.$store.dispatch('events', { voterUpdate: { voter: { uniqueId: this.voter.uniqueId } } })

            this.$store.dispatch('setShowVoterActions', false)
            this.$store.dispatch('setActionsTab', 'cleared')
          } else {
            this.$store.dispatch('notifyError', { message: response.message })
          }
        } catch (error) {
          this.$store.dispatch('notifyError', { message: error.message })
        }
      }
    },
    asyncComputed: {},
  }
</script>

<style lang='stylus'>
  .call-report
    .editing
      border: dashed 2px #0000001f
    .v-badge .v-badge__wrapper
      margin-left: 0px
    .v-card__text
      padding-bottom: 6px !important

    .actions
      height: 28px

    .content
      white-space: pre-line;
</style>
