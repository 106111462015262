<template>
   <v-menu v-model="open" offset-y>
    <template v-slot:activator="{ on, attrs }">

      <div
        class="w-filter-dropdown d-flex px-2 justify-space-between align-center"
        v-bind="attrs"
        v-on="on"
      >
        <div class="dropdown-title">
          <span class="selected-text c-primary d-flex align-center f-18 text-truncate" >
            {{ $t(menuItems.title) }}
          </span>
        </div>
        <v-icon color="primary" class="ml-2" size="medium">mdi-chevron-down</v-icon>
      </div>
    </template>
    <dashboards-form>
    </dashboards-form>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import DashboardsForm from '../shared/DashboardsForm'

export default {
  name: 'DashboardsMenu',
  components: {
    DashboardsForm,
  },
  data() {
    return {
      menuItems: {
        icon: "mdi-clipboard-edit-outline",
        title: "dashboard_navigation_app_bar_dashboard_menu_dashboards"
      },
      open: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentDashboard',
      'currentAdmin'
    ]),
  },
  watch: {
    '$route.query': {
      handler() {
        if(this.currentDashboard.name) this.menuItems.title = this.currentDashboard.name
      },
      immediate: true
    }
  }
}

</script>

<style lang="stylus" scoped>
  .v-menu__content
    max-height: 80vh
    display: flex
    flex-direction: column

  .w-filter-dropdown
    .v-icon
      color: white !important
    padding-left: 20px !important
    height: 100%
    .dropdown-title
      height: 100%
      span
        color: white !important
        height: 100%
</style>
