<template>
  <WAutocomplete
    v-model="dataCampaignIndicator"
    :items="campaignIndicators"
    :key="campaignIndicatorId"
    @change="onChange"
    rounded
  />
</template>

<script>
import { mapGetters } from 'vuex'
import { capitalize } from 'inflection'

export default {
  name: "CampaignIndicatorAutocomplete",
  data() {
    return {
      dataCampaignIndicator: {
        id: this.campaignIndicatorId,
        campaignId: this.campaignId
      }
    }
  },
  props: {
    campaignIndicatorId: { required: false },
    campaignId: { required: false },
    eligibleCampaigns: { required: true }
  },
  computed: {
    ...mapGetters([
      'currentUserCampaignScoreIndicator',
      'currentCampaignIndicatorsByCampaignId'
    ]),
    campaignIndicators() {
      let campaignIndicators = []

      this.eligibleCampaigns.forEach((campaign) => {
        const scoreIndicator = this.currentUserCampaignScoreIndicator(campaign.id)

        if (this.eligibleCampaigns.length > 1) {
          campaignIndicators.push({ header: campaign.name })
        }

        campaignIndicators.push({
          text: scoreIndicator.formulaToName,
          value: {
            id: scoreIndicator.id,
            campaignId: campaign.id
          }
        })
        campaignIndicators = campaignIndicators.concat(campaign.campaignIndicators.map((campaignIndicator) => {
          return {
            text: campaignIndicator.name,
            value: {
              id: campaignIndicator.id,
              campaignId: campaign.id
            }
          }
        }))
        if (this.eligibleCampaigns.length > 1) {
          campaignIndicators.push({ divider: true })
        }
      })

      if (this.eligibleCampaigns.length > 1) {
        campaignIndicators.pop()
      }

      return campaignIndicators
    }
  },
  methods: {
    onChange(campaignIndicator) {
      this.$router.push({
        params: {
          sectionId: campaignIndicator.id,
          subSectionId: campaignIndicator.campaignId
        }
      })
    }
  },
  watch: {
    campaignIndicatorId: function() {
      this.dataCampaignIndicator = {
        id: this.campaignIndicatorId,
        campaignId: this.campaignId
      }
    }
  }
}
</script>
