<template>
  <ScoreWidget
    :title="title"
    :basedRequest="basedRequest"
    :indicatorColumn="indicatorColumn"
    :indicatorAlias="indicatorAlias"
    :decimals="2"
    :cols="cols"
    :linkTo="linkTo"
    :avgScoreScale="{ max: 5 }"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import ScoreWidget from '@statistics/shared/widgets/ScoreWidget'

export default {
  name: 'SocialScoreWidget',
  components: {
    ScoreWidget
  },
  props: {
    source: { required: true },
    cols: { required: false, default: 3 },
  },
  computed: {
    ...mapGetters([
      'dashboardFilterRequest'
    ]),
    linkTo() {
      return {
        name: 'Statistics',
        params: { sectionId: this.source, sectionType: 'social' }
      }
    },
    basedRequest() {
      return this.dashboardFilterRequest.select({
        brand_voters: [
          this.indicatorColumn,
          { nb_review_from_source_: { source: this.source, as: 'nbReview' } },
        ]
      })
    },
    indicatorColumn() {
      return {
        avg_score_from_source_: {
          source: this.source,
          as: this.indicatorAlias
        }
      }
    },
    indicatorAlias() {
      return 'score'
    },
    title() {
      return this.$t(`source_${this.source}`)
    }
  }
}
</script>
