<template>
  <div class="reannotation-form f-14 mb-3">
    <WFormInput>
      <template #label>
        {{ $t('verbatim_from') }}
      </template>

      <template #input>
        <WDatePicker v-model="dateBegin" :max="dateEnd" />
        <span class="px-2"> {{ $t('range_to') }} </span>
        <WDatePicker v-model="dateEnd" />
      </template>
    </WFormInput>

    <WFormInput>
      <template #label>
        Stratégie
      </template>

      <template #input>
        <v-select
          v-model="strategy"
          :items="strategyOptions"
          dense
          outlined
          hide-details
        ></v-select>
      </template>
    </WFormInput>

    <WFormInput>
      <template #label>
        {{ $t('max_verbatim') }}
      </template>

      <template #input>
        <v-text-field
          v-model="limit"
          hide-details
          dense
          type="number"
          placeholder="pas de limite"
        />
      </template>
    </WFormInput>

    <WFormInput>
      <template #label>
        {{ $t('openai_preferences_verbatim_to_reannotate') }}
      </template>

      <template #input>
        {{ nbVerbatimsInScope || 0 }}
      </template>
    </WFormInput>

    <v-btn
      class="mt-3"
      color="secondary"
      @click="submit"
      :loading="loading"
      :disabled="!valid"
    >
      {{ $t('reannotate') }}
    </v-btn>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'ReannotationForm',
    data: () => ({
      strategy: "annotate_all",
      dateBegin: null,
      dateEnd: (new Date).toISOString().split('T')[0],
      active: false,
      loading: false,
      limit: null
    }),
    computed: {
      ...mapGetters(['currentCampaignPreferencesCampaignId']),
      validDates() {
        return this.sqlDateBegin && this.sqlDateEnd
      },
      sqlDateBegin() {
        return this.$helpers.dates.sqlDate(this.dateBegin)
      },
      sqlDateEnd() {
        return this.$helpers.dates.sqlDate(this.dateEnd, "23:59:59")
      },
      valid() {
        return this.validDates && this.nbVerbatimsInScope > 0
      },
      strategyOptions() {
        return [
          { text: "Réannoter tout", value: "annotate_all" },
          { text: "Réannoter seulement les KO", value: "annotate_ko_only" },
          { text: "Réannoter seulement les avis qui n'ont pas encore été annotés", value: "annotate_new_only" },
          { text: "Réannoter seulement les avis google qui n'ont pas encore été annotés", value: "annotate_new_google_only" }
        ]
      }
    },
    methods: {
      togglePicker() {
        this.active = !this.active
      },
      async submit() {
        this.loading = true

        try {
          const result = await this.$api.wizville.verbatimAnnotationBatchs.create(
            this.currentCampaignPreferencesCampaignId,
            this.dateBegin,
            this.dateEnd,
            this.limit,
            this.strategy
          )
          this.dateBegin = null
          this.limit = null
          this.dateEnd = (new Date).toISOString().split('T')[0],
          this.$emit("submited")
          this.strategy = "annotate_all"
          this.$store.dispatch("notifySuccess")
        } catch (e) {
          this.$store.dispatch("notifyError")
        }

        this.loading = false
      }
    },
    asyncComputed: {
      nbVerbatimsInScope:{
        async get() {
          if (this.validDates) {
            let request = this.$basedRequest().select({ voters: [{
              COUNT_DISTINCT_id: { as: "total" }
            }]}).where({
              place_campaigns_campaign_id: this.currentCampaignPreferencesCampaignId,
              created_at: { "[..]": { min: this.sqlDateBegin, max: this.sqlDateEnd} },
              main_verbatim_text_processing_status: { "!=": "ignored" }
            }).scoped([
              'with_main_verbatim_id'
            ])

            if (this.strategy == 'annotate_ko_only') {
              request = request.where({ main_verbatim_text_processing_status: "ko" })
            } else if (this.strategy == 'annotate_new_only') {
              request = request.where({ main_verbatim_text_processing_status: null })
            } else if (this.strategy == 'annotate_new_google_only') {
              request = request.where({
                main_verbatim_text_processing_status: null,
                source: 'google'
              })
            }

            const result = (await this.$resolve(request)).first()
            const total = result?.total || 0

            return ((this.limit && this.limit < total) ? this.limit : total)
          } else {
            return 0
          }
        }, watch: [ "limit", "main_verbatim_text_processing_status", "dateBegin", "dateEnd" ]
      }
    }
  }
</script>
