<template>
  <div class="openai-preferences-prompt-examples mt-6">
    <div class="d-flex align-center">
      <p class="fb-14 mb-0">
        {{ $t('openai_preferences_help_section_examples_title') }}
      </p>
      <div class="d-flex align-center ga-4 ml-auto">
        <a
          class="text-decoration-underline c-dark"
          @click="moreExamples"
        >
          {{ $t('openai_preferences_prompt_examples_more_examples') }}
        </a>
        <a
          class="text-decoration-underline c-dark"
          @click="reset"
        >
          {{ $t('openai_preferences_prompt_examples_reset') }}
        </a>
      </div>

    </div>

    <v-textarea
      v-model="promptExamples"
      class="prompt-examples-textarea f-14"
      :rules="[$required]"
      :hide-details="true"
      name="content"
      dense
      filled
      no-resize
      height="600px"
    />
    <MoreExamplesDialog
      v-model="moreExamplesDialogShow"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import MoreExamplesDialog from "./MoreExamplesDialog"

  export default {
    name: 'PromptExamples',
    components: {
      MoreExamplesDialog,
    },
    data(){
      return {
        moreExamplesDialogShow: false
      }
    },
    props: {
      value: { type: String, required: true },
      defaultPromptExamples: { type: String, required: true }
    },
    computed: {
      ...mapGetters([
      ]),
      promptExamples: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit("input", val)
        }
      }
    },
    asyncComputed: {
    },
    methods: {
      reset() {
        this.promptExamples = this.defaultPromptExamples
      },
      moreExamples() {
        this.moreExamplesDialogShow = true
      }
    }
  }
</script>

