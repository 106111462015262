<template>
  <div>
    <w-drop-down-menu
      v-model="selectedValues"
      :items="items"
      icon="mdi-cog"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "WidgetSettingsMenu",
    props: {
      competitorMode: {
        required: true,
        type: String
      },
      competitorScope: {
        required: true,
        type: String
      },
      periodType: {
        required: true,
        type: String
      },
      campaignSmartBenchmarkPreferenceId: {
        required: false
      }
    },
    computed: {
      ...mapGetters([
        'currentDashboard',
        'currentDashboardBasedTable',
        'dashboardFilterDates',
        'hasAccessToAllDashboardPlaces',
        'defaultCompetitorScope'
      ]),
      selectedValues: {
        get() {
          return [
            this.periodType,
            this.competitorScope
          ]
        },
        set(val) {
          this.$router.push({
            name: "Competitions",
            params: {
              competitorMode: this.competitorMode,
              competitorScope: val[1],
              periodType: val[0],
              campaignSmartBenchmarkPreferenceId: this.campaignSmartBenchmarkPreferenceId
            }
          })
        }
      },
      items() {
        const items = [
          {
            title: this.$t('widget_settings_period_head_title'),
            help: this.$t('widget_settings_period_head_help'),
            children: [
              {
                title: this.$t('widget_settings_period_title', { period: this.dashboardFilterDates.text }),
                value: 'period'
              },
              {
                title: this.$t('widget_settings_genesis_title'),
                value: 'genesis'
              },
            ]
          }
        ];

        if (this.competitorMode === 'national') {
          const countryCodes = this.currentDashboard.countryCodes;
          const competitorScopeChildren = [
            {
              title: this.$t('widget_settings_competitor_scope_catchment_area_title'),
              value: 'local_competitors'
            }
          ];

          if (countryCodes.length > 1) {
            competitorScopeChildren.push({
              title: this.$t('widget_settings_competitor_scope_all_countries_title'),
              value: 'all_countries'
            });
          }
          countryCodes.map((countryCode) => {
            competitorScopeChildren.push({
              title: this.$translateCountryCode(countryCode),
              value: countryCode
            });
          })

          items.push({
            title: this.$t('widget_settings_competitor_scope_head_title'),
            help: this.$t('widget_settings_competitor_scope_head_help'),
            children: competitorScopeChildren,
          });
        }
        return items;
      },
    },
  }
</script>
