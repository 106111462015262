<template>
  <v-row class="feedback-treatment-page" no-gutters>
    <v-col>
      <!-- LATER WE WANT TO ADD THIS -->
      <!-- <v-row no-gutters class="px-3 mb-3" v-if="eligibleCampaigns.length > 1">
        <v-col cols='12'>
          
          <StatisticsCampaignAutocomplete
            class="d-flex"
            :campaignId="campaignId"
            :eligibleCampaigns="eligibleCampaigns"
          />
        </v-col>
      </v-row> -->

      <template v-if="campaign">
        <!-- AND THIS -->
        <!-- <v-row
          no-gutters
        >
          <v-col>
            Add FeedbackTreatmentCampaignTableWidget ?
          </v-col>
        </v-row> -->

        <v-row no-gutters v-if="campaign">
          <v-col cols="12">
            <FeedbackTreatmentZoom
              :campaign="campaign"
            />
          </v-col>
        </v-row>
      </template>
    </v-col> 
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import FeedbackTreatmentZoom from './FeedbackTreatmentZoom'

  export default {
    name: "FeedbackTreatmentPage",
    props: [ 'campaignId' ],
    components: {
      FeedbackTreatmentZoom,
    },
    computed: {
      ...mapGetters([
        'currentDashboardCampaignWithId',
      ]),
      campaign() {
        return this.currentDashboardCampaignWithId(this.campaignId)
      }
    }
  }
</script>
