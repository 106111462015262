export function bootIntercomIfn(currentAdmin, user) {
  if (!currentAdmin && user?.id) {
    const intercomUser = { 
      user_id: String(user.id),
      email: user.mail,
      signed_up_at: user.createdAt,
      companies: [ { id: user.brandId, name: user.brandName } ]
    }

    window.Intercom('shutdown')
    window.Intercom('boot', intercomUser)
  }
}

export function updateIntercomIfn(currentAdmin, user) {
  if (!currentAdmin && user?.id) {
    window.Intercom("update", { last_request_at: parseInt((new Date()).getTime()/1000) })
  }
}
