<template>
  <v-form ref="form" v-model="isValid" class="assign-internally d-flex flex-column">
    <v-row class="flex-grow-0">
      <v-col class="py-0 my-1">
        <v-select
          v-model="selectedUserGroup"
          :items="selectableUserGroups"
          item-value="value"
          item-text="text"
          required
          hide-details="auto"
          background-color="#eef1f5"
          :rules="[$required]"
          class="collaborator-group"
          :label="$t('feedbacks_assign_select_collaborator_group')"
          name="userGroup"
          dense
          filled
          attach
        />
      </v-col>
    </v-row>
    <v-row class="flex-grow-1">
      <v-col class="py-0 my-1">
        <v-textarea
          v-model="content"
          class="content-textarea f-14"
          :label="$t('feedbacks_assign_text_area_comment')"
          background-color="#eef1f5"
          :placeholder="$t('feedbacks_assign_text_area_description')"
          name="content"
          height="100%"
          rows="0"
          dense
          filled
          no-resize
        />
      </v-col>
    </v-row>
    <v-row class="flex-grow-0">
      <v-col class="text-right">
        <v-btn
          class="save"
          @click="assign"
          :disabled="!isValid"
          :ripple="false"
          :loading="loading"
          color="secondary"
        >
          <v-icon left dark>mdi-account-outline</v-icon>
          {{ $t('feedbacks_assign_save_button') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'AssignInternally',
  props: {
    voter: { required: true }
  },
  computed: {
    ...mapGetters([
      'currentVoter',
      'currentUserGroupId',
      'currentUserId',
      'currentDashboardCampaignIds'
    ]),
  },
  data() {
    return {
      isValid: false,
      loading: false,
      content: '',
      selectedUserGroup: null,
    }
  },
  methods: {
    async assign() {
      if (this.isValid) {
        this.loading = true

        try {
          const response = await this.$api.wizville.voterTransfers.create(
            this.voter.uniqueId,
            { transfer_comment: this.content, user_group_id: this.selectedUserGroup.id }
          )

          if (response.status === "ok") {
            this.$store.dispatch('notifySuccess', { message: this.$t('feedbacks_assign_notify_success', { group_name: this.selectedUserGroup.name}), timeout: 5000 })
            this.$store.dispatch('events', { voterUpdate: { voter: { uniqueId: this.voter.uniqueId } } })
          } else {
            this.$store.dispatch('notifyError', { message: response.message })
          }
        } catch (error) {
          this.$store.dispatch('notifyError', { message: error.message })
        }

        this.loading = false
      }
    },
  },
  asyncComputed: {
    selectableUserGroups: {
      async get() {
        let excludedUserGroupIds = []
        if (this.currentUserGroupId !== null) {
          excludedUserGroupIds.push(this.currentUserGroupId)
        }

        excludedUserGroupIds = excludedUserGroupIds.concat(
          (await this.$resolve(
            this.$basedRequest()
              .select({
                user_groups: ['user_group_ids']
              })
              .where(
                {
                  user_group_ids: {
                    without_access_to: {
                      campaign_ids: this.currentDashboardCampaignIds
                    }
                  }
                }
              )
          )).data.userGroups.map(data => data.userGroupIds)
        )

        const userGroupsReq = this.$basedRequest().select({
          places: [{
            user_groups_available_for_insatisfaction_assignation: {
              params: { user_group_id: excludedUserGroupIds }
            }
          }]
        }).where({ id: [this.voter.placeId] })

        const userGroupsResult = (await this.$resolve(userGroupsReq)).first()
        const result = Object.values(
          userGroupsResult.userGroupsAvailableForInsatisfactionAssignation
        ).map((userGroup) => (
          {
            value: {
              id: userGroup.id,
              name: userGroup.name
            },
            text: userGroup.name
          }
        ))

        return result
      },
      default: []
    }
  },
}
</script>
<style lang='stylus'>
  @import '~@theme/medias.styl'

  .assign-internally
    height: 100%
    padding: 20px 10px 20px 10px

    .content-textarea
      height: 100%
      textarea
        line-height: 18px

    .content-textarea .v-input__control
      height: 100%

    .content-textarea .v-input__slot
      margin-bottom: 0px
      s
    +forBreakpoints(mobile, tablet)
      .content-textarea
        min-height: 200px
</style>
