<template>
  <div >
    <v-list-item
      v-if="$vuetify.breakpoint.xsOnly"
      @click="$emit('clicked')"
      class="fb-15 bg-palegrey dashboards-list-menu"
    >
      <v-list-item-content>
        <v-list-item-title>{{ title }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon>
        <v-icon dense>mdi-chevron-right</v-icon>
      </v-list-item-icon>
    </v-list-item>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"

  export default {
    name: "DashboardsListMenu",
    components: {
    },
    data() {
      return {}
    },
    props: {
      title: {
        type: String,
        required: true
      }
    }
  }
</script>

