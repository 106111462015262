<template>
  <v-row
    no-gutters
    class="net-impact-score-summaries"
  >
    <v-col cols="12">
      <v-card
        class="mt-3"
        outlined
      >
        <v-card-title class="justify-left">
          <h4 class="f-16 c-primary">{{ $t('net_impact_score_summary_last_computations') }}</h4>
          <v-btn
            class="ml-auto"
            icon
            @click="downloadComputedData()"
            :loading="downloadLoading"
          >
            <v-icon
              small
            >
              mdi-download
            </v-icon>
          </v-btn>

        </v-card-title>
        <v-card-subtitle v-if="!loading" class="f-12">
          {{ nextComputation }}
        </v-card-subtitle>
        <v-card-text>
          <v-data-table
            ref="table"
            v-show="!loading"
            :headers="headers"
            :items="campaignNetImpactScoreSummaries"
            :items-per-page="-1"
            hide-default-footer
            hide-default-header
            mobile-breakpoint="0"
            group-by="name"
            group-desc
            class="v-datatable-without-y-borders"
          >
            <template v-slot:group.header="{ group, items, toggle, isOpen }">
              <td
                :colspan="headers.length"
              >
                <div class="d-flex align-center py-2">
                  <div
                    class="d-flex flex-column"
                  >
                    <div class="d-flex align-center">
                      <span
                        class="fb-16"
                      >
                        {{ items[0].name }}
                      </span>
                      <v-progress-circular
                        v-if="![0, 1].includes(avgComputationProgressionRate(items))"
                        :rotate="-90"
                        :size="40"
                        :width="5"
                        :value="$options.filters.toPercent(avgComputationProgressionRate(items))"
                        color="secondary"
                        class="f-12 ml-2"
                      >
                        {{ `${$options.filters.toPercent(avgComputationProgressionRate(items))}%` }}
                      </v-progress-circular>
                    </div>

                    <div class="d-flex align-center flex-wrap">
                      <div class="d-flex align-center mr-2">
                        <span class="fs-12 text-no-wrap">{{ $t('net_impact_score_summary_computation_date') }}</span>
                        <span class="f-12 ml-1 text-no-wrap">{{ formatDate(items[0].createdAt) }}</span>
                      </div>

                      <div class="d-flex align-center mr-2">
                        <span class="fs-12 text-no-wrap">{{ $t('net_impact_score_summary_analysis_period') }}</span>
                        <span class="f-12 ml-1 text-no-wrap">{{ computePeriod(items[0]) }}</span>
                      </div>

                      <div class="d-flex align-center">
                        <span class="fs-12 text-no-wrap">{{ $t('net_impact_score_summary_status') }}</span>
                        <span class="f-12 ml-1 text-no-wrap">{{ $t(`net_impact_score_summary_status_${globalStatus(items)}`) }}</span>
                        <v-icon
                          class="ml-1 f-20"
                          v-if="hasError(items)"
                          @click="toggle"
                          ripple="false"
                        >
                         mdi-alert-outline
                        </v-icon>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-center ml-auto ga-2">
                    <v-btn
                      v-if="rawDataUrl(items)"
                      icon
                      @click="downloadRawData(items)"
                    >
                      <v-icon
                        small
                      >
                        mdi-download
                      </v-icon>
                    </v-btn>
                    <v-btn
                      class="ml-2"
                      @click="toggle"
                      small
                      icon
                      :ref="group"
                      :data-open="isOpen"
                    >
                      <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                      <v-icon v-else>mdi-chevron-down</v-icon>
                    </v-btn>
                  </div>
                </div>
              </td>
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:item.groupName="{ item }">
              {{ item.groupName || $t('lexicon_.general_.network')}}
            </template>
            <template v-slot:item.status="{ item }">
              <div class="d-flex align-center">
                <v-tooltip top transition="none">
                  <template #activator="{ on }">
                    <span
                      class="f-13 pt-1"
                      v-on="on"
                    >
                      {{ status(item) }}
                    </span>
                  </template>
                  {{ statusTooltip(item) }}
                </v-tooltip>
                <v-progress-circular
                  v-if="item.status === 'computing'"
                  :rotate="-90"
                  :size="40"
                  :width="5"
                  :value="$options.filters.toPercent(item.computationProgressionRate)"
                  color="secondary"
                  class="f-12 ml-2"
                >
                  {{ `${$options.filters.toPercent(item.computationProgressionRate)}%` }}
                </v-progress-circular>
              </div>
            </template>
          </v-data-table>
          <v-skeleton-loader v-show="loading" type="paragraph" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _camelCase from "lodash/camelCase"
  import _compact from "lodash/compact"
  import dayjs from 'dayjs'

  import exportToExcel from '@shared/helpers/export-to-excel.js'

  export default {
    name: "Summaries",
    data() {
      return {
        loading: true,
        downloadLoading: false,
        polling: null,
        pollingCount: 0,
        shouldCloseGroups: true,
        sortBy: 'name',
        sortDesc: false,
      }
    },
    computed: {
      ...mapGetters([
        'currentUser',
        'currentUserId',
        'currentCampaignPreferencesCampaignId'
      ]),
      nextComputation() {
        if (this.campaignNetImpactScoreSummaries.length === 0) {
          return
        }

        const nextComputationDate = this.formatDate(
          dayjs(this.campaignNetImpactScoreSummaries[0].createdAt).add(1, "month")
        )

        return this.$t(
          'net_impact_score_summary_next_computation_date',
          { date: nextComputationDate }
        )
      },
      headers() {
        let headers = [
          {
            text: this.$t('net_impact_score_summary_name'),
            value: 'name',
            cellClass: 'name-cell text-truncate'
          },
          {
            text: this.$t('net_impact_score_summary_group_name'),
            value: 'groupName',
            cellClass: 'name-cell text-truncate'
          },
          {
            text: this.$t('net_impact_score_summary_status'),
            value: 'status'
          }
        ]

        return headers.filter((header) => header.displayCondition === undefined || header.displayCondition)
      }
    },
    asyncComputed: {
      campaignNetImpactScoreSummaries: {
        async get() {

          const data = await this.getSummaries()

          this.loading = false

          return data
        },
        default: [],
        watch: [ 'pollingCount' ]
      }
    },
    methods: {
      async getSummaries(columns) {
        const requestColumns = columns || [
          'computation_progression_rate',
          'period_in_month',
          'group_name',
          'status',
          'type',
          { 'created_at': { as: 'createdAt', cast: 'date' } },
          'data_export_url'
        ]
        const request = this.$basedRequest().select({
          net_impact_score_topic_summaries: [
            ...requestColumns,
            'topic_name',
          ],
          net_impact_score_avg_campaign_indicator_summaries: [
            ...requestColumns,
            'campaign_indicator_name',
          ],
          net_impact_score_nps_campaign_indicator_summaries: [
            ...requestColumns,
            'campaign_indicator_name',
          ],
          net_impact_score_avg_score_summaries: [
            ...requestColumns
          ],
          net_impact_score_nps_summaries: [
            ...requestColumns
          ]
        }).where({
          campaign_id: this.currentCampaignPreferencesCampaignId,
        }).where({
          status: { '<>': 'deprecated' }
        })

        const result = await this.$resolve(request)
        let data = Object.values(result?.data?.netImpactScoreTopicSummaries || {}).concat(
          Object.values(result?.data?.netImpactScoreAvgCampaignIndicatorSummaries || {}),
          Object.values(result?.data?.netImpactScoreNpsCampaignIndicatorSummaries || {}),
          Object.values(result?.data?.netImpactScoreAvgScoreSummaries || {}),
          Object.values(result?.data?.netImpactScoreNpsSummaries || {}),
        )

        data.forEach(item => {
          let name

          switch (item.type) {
            case 'NetImpactScoreTopicSummary':
              name = `(${this.$t('net_impact_score_summaries_settings_topic_label')}) ${item.topicName}`
              break
            case 'NetImpactScoreAvgCampaignIndicatorSummary':
              name = `(${this.$t('net_impact_score_summaries_settings_campaign_indicator_label')}) ${item.campaignIndicatorName}`
              break
            case 'NetImpactScoreNpsCampaignIndicatorSummary':
              name = `(${this.$t('net_impact_score_summaries_settings_campaign_indicator_label')}) ${item.campaignIndicatorName}`
              break
            case 'NetImpactScoreAvgScoreSummary':
              name = this.$t('net_impact_score_summaries_settings_avg_score_label')
              break
            case 'NetImpactScoreNpsSummary':
              name = this.$t('net_impact_score_summaries_settings_nps_label')
              break
          }

          item.name = name
        })
        // Sort by creation date.
        data = data.sort(
          (a, b) => b.name.localeCompare(a.name)
        )

        return data
      },
      hasError(items) {
        const error = items.filter(
          item => ['no_place_reference', 'no_transactional_unit', 'erroneous'].includes(item.status)
        )
        return error.length === items.length
      },
      globalStatus(items) {
        const status = items.map((item) => item.status)
        const uniqueStatus = [...new Set(status)]

        if (uniqueStatus.length === 1) {
          return uniqueStatus[0]
        }
        else if (uniqueStatus.includes('computing')) {
          return 'computing'
        } else if (uniqueStatus.includes('waiting_for_computation')) {
          return 'waiting_for_computation'
        } else {
          return 'computed'
        }
      },
      avgComputationProgressionRate(items) {
        const computingItems = items.filter(
          item => item.status === 'computing'
        )

        if (computingItems.length) {
          const sum = computingItems.reduce((sum, item) => {
            return sum + item.computationProgressionRate
          }, 0)

          return sum / computingItems.length
        }

        return 0
      },
      pollNetImpactScoreSummaries () {
        this.polling = setInterval(() => {
          this.pollingCount = this.pollingCount + 1
        }, 3000)
      },
      closeGroups() {
        if (this.shouldCloseGroups) {
          let table = this.$refs.table

          if (table) {
            let keys = Object.keys(table.$vnode.componentInstance.openCache)

            keys.forEach(x => {
              table.$vnode.componentInstance.openCache[x] = false;
            })
            this.shouldCloseGroups = false
          }
        }
      },
      status(item) {
        return this.$t(`net_impact_score_summary_status_${item.status}`)
      },
      statusTooltip(item) {
        return this.$t(`net_impact_score_summary_status_tooltip_${item.status}`)
      },
      formatDate(date, format='DD MMMM YYYY') {
        return dayjs(date).locale(this.$i18n.locale).format(format)
      },
      rawDataUrl(items) {
        return _compact(items.map(item => item.dataExportUrl))?.[0]
      },
      downloadRawData(items) {
        window.open(this.rawDataUrl(items), '_blank')
      },
      async downloadComputedData() {
        this.$store.dispatch("notifyInfo")

        this.downloadLoading = true

        const columns = [
          'group_name',
          'status',
          'type',
          'promoters_count',
          'neutrals_count',
          'detractors_count',
          'promoters_average_revenue_with_currency',
          'neutrals_average_revenue_with_currency',
          'detractors_average_revenue_with_currency',
          'nis_average_revenue',
          'promoters_average_basket_price_with_currency',
          'neutrals_average_basket_price_with_currency',
          'detractors_average_basket_price_with_currency',
          'nis_average_basket_price',
          'promoters_average_visit_count',
          'neutrals_average_visit_count',
          'detractors_average_visit_count',
          'nis_average_visit_count',
          'place_references'
        ]

        let data = await this.getSummaries(
          columns
        )
        const title = 'Net impact score summary'
        const date = this.formatDate(dayjs(data[0].createdAt))

        const fileName = [title, date].join(' - ')

        data = data.map((netImpactScoreSummary) => {
          const {
            topicName,
            campaignIndicatorName,
            type,
            ...filteredMapping
          } = netImpactScoreSummary
          filteredMapping.groupName = filteredMapping.groupName || this.$t('lexicon_.general_.network')
          filteredMapping.placeReferences = netImpactScoreSummary.placeReferences.map((placeReference) => {
            return `${placeReference.placeName}: ${placeReference.satisfactionRate}`
          }).join('\n')

          return filteredMapping
        })

        const headerKeys = [
          'name',
          ...columns.filter(
            column => !['type'].includes(column)
          ).map(
            column => _camelCase(column)
          )
        ]

        const headerTexts = headerKeys.map((key, index) =>
          this.$t(`net_impact_score_summaries_export_header_${key}`)
        )

        exportToExcel(fileName, title, data, headerTexts, headerKeys)
        this.downloadLoading = false
      },
      computePeriod(item) {
        const dateBegin = this.formatDate(dayjs(
          item.createdAt
        ).subtract(
          item.periodInMonth, "month"
        ), 'MMMM YYYY')

        const dateEnd = this.formatDate(
          dayjs(item.createdAt), 'MMMM YYYY'
        )

        return `${dateBegin} - ${dateEnd}`
      }
    },
    beforeDestroy () {
      clearInterval(this.polling)
    },
    created() {
      this.pollNetImpactScoreSummaries()
    },
    watch: {
      campaignNetImpactScoreSummaries() {
        this.$nextTick(function () {
          if (this.campaignNetImpactScoreSummaries.length > 0) {
            this.closeGroups()
          }
        })
      }
    }
  }
</script>
