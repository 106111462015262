<template>
  <WStatsWidget
    :cols="cols"
    :empty="!hasData"
    :height="hasData ? widgetHeight : '150px'"
    :justifyCenter="false"
    :loading="isLoading"
    :subtitle="defaultSubtitle"
    :title="title || $t('statistics_widget_topics_ranking_title')"
    class="topics-table-widget"
    contentWidth="100%"
  >
    <template v-if="!isLoading" #options>
      <div class="d-flex">
        <w-drop-down-menu
          v-if="nisMixin_canUseNisFeature([campaign])"
          v-model="nisMixin_transactionalUnitsSelectElementValue"
          :items="nisMixin_availableTransactionalUnits([campaign])"
          :title="nisMixin_transactionalUnit([campaign]).label"
          :emoji="nisMixin_transactionalUnit([campaign]).emoji"
          :showGroupTitle="false"
        />
        <w-drop-down-menu
          v-if="showExportOptions"
          :items="exportOptions"
          icon="mdi-download"
        />
      </div>
    </template>

    <template #content>
      <v-data-table
        :headers="headers"
        :items="topics"
        :items-per-page="40"
        hide-default-footer
        mobile-breakpoint="0"
        :item-class="rowClasses"
        class="v-datatable-without-y-borders"
        @click:row="topic => topicsMixin_goToTopic(campaign, topic, 'topics')"
        :sort-by="sortBy"
        sort-desc
      >
        <template v-slot:header.nisOpportunity="{ header }">
          <HeaderWithTooltip
            :title="header.text"
            :tooltip="header.tooltip"
          />
        </template>
        <template v-slot:item.name="{ item }">
          <div class="d-flex align-center ga-4">
            <w-zoom-button
              v-if="$vuetify.breakpoint.smAndUp"
              :highlighted="item.id === currentTopicId"
              @click="topicsMixin_goToTopic(campaign, item, 'topics')"
            />
            <span class="name fs-13 c-primary">{{ item.name }}</span>
          </div>
        </template>

        <template v-slot:item.score="{ item }">
          <div class="d-flex flex-column align-self-center">
            <ScoreCell
              :nbReview="item.nbReview"
              :score="item.score.toFixed(1)"
              :evolution="item.evolution"
              :nbPromoters="item.nbPromoters"
              :nbDetractors="item.nbDetractors"
              :nbNeutrals="item.nbNeutrals"
              :scoreTooltip="scoreTooltip(item)"
              :showNbReview="showNbReview(item)"
              :repartitionWidth="repartitionWidth"
            />
          </div>
        </template>

        <template v-slot:item.nisOpportunity="{ item }">
          <NisOpportunityCell
            :nisItem="item"
          />
        </template>
        <template v-slot:item.nisSatisfactionGoal="{ item }">
          <NisSatisfactionGoalCell
            :nisItem="item"
          />
        </template>
      </v-data-table>
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { exportToExcel, formatExcelValues } from '@shared/helpers/export-to-excel.js'
  import Pdf from '@shared/helpers/exportToPdf/pdf'
  import paginateArray from '@shared/helpers/paginate-array'

  import HeaderWithTooltip from '../../shared/Table/HeaderWithTooltip'
  import NisOpportunityCell from '@statistics/shared/Table/NisOpportunityCell'
  import ScoreCell from '@statistics/shared/Table/ScoreCell'
  import NisSatisfactionGoalCell from '@statistics/shared/Table/NisSatisfactionGoalCell'

  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import NisMixin from '@statistics/shared/nis_mixin'
  import TopicsMixin from '@statistics/shared/topics_mixin'

  export default {
    name: "TopicsTableWidget",
    mixins: [
      WidgetMixin,
      TopicsMixin,
      NisMixin,
    ],
    components: {
      HeaderWithTooltip,
      NisOpportunityCell,
      ScoreCell,
      NisSatisfactionGoalCell
    },
    props: {
      title: { type: String },
      campaign: { type: Object, required: true },
      topics: { type: Array },
      currentTopicId: { type: Number },
      cols: { type: Number, default: 6 },
      minHeight: { type: String, default: "360px" },
      maxHeight: { type: String, default: "360px" },
      compact: { type: Boolean, default: false },
      showExportOptions: { type: Boolean, default: true },
    },
    computed: {
      ...mapGetters([
        'currentDashboardScopedCampaigns',
        'dashboardFilterDates',
        'datesScope',
        'dashboardFilterRequest',
        'dashboardFilterReady',
        'placeIdsScope',
      ]),
      repartitionWidth() {
        return (!this.compact && this.$vuetify.breakpoint.mdAndUp) ? undefined : "100px"
      },
      isLoading() {
        return this.topics === undefined
      },
      hasData() {
        return !this.isLoading && this.topics.length > 0
      },
      sortBy() {
        return this.topics.some(data => !!data.nisOpportunity) ?
               'nisOpportunity' :
               'score'
      },
      widgetHeight() {
        const headerHeight = 65
        const tableHeaderHeight = 50
        const tableMargins = 16
        const height = headerHeight +
                       tableHeaderHeight +
                       this.topics.length * 50 +
                       tableMargins

        return height > 310 ? '310px' : `${height}px`
      },
      exportOptions() {
        return [
          { title: 'PDF', onClick: this.exportToPdf },
          { title: 'Excel', onClick: this.exportToExcel }
        ]
      },
      showNisColumns() {
        return this.nisMixin_canUseNisFeature([this.campaign], false)
      },
      scoreColumnWidth() {
        if (!this.showNisColumns) {
          return null
        }

        if (this.$vuetify.breakpoint.mdAndUp) {
          return '300px'
        }

        return '200px'
      },
      headers() {
        let headers = [
          {
            text: this.$t('topic'),
            value: 'name',
            align: 'start',
            cellClass: 'name-cell',
          },
          {
            text: this.$t('average_mark'),
            value: 'score',
            width: this.scoreColumnWidth
          },
          {
            text: this.compact ?
                  `💸 ${this.$t('topicsRecommendationsTableWidgetOpportunity')}` :
                  (this.showNisColumns && this.nisMixin_opportunityHeaderTitle([this.campaign])),
            value: 'nisOpportunity',
            align: 'center',
            tooltip: this.showNisColumns && this.nisMixin_opportunityHelpMessage([this.campaign]),
            sortable: !this.compact,
            displayCondition: this.showNisColumns &&
              this.$vuetify.breakpoint.smAndUp
          },
          {
            text: this.$t('topics_table_widget_goal'),
            value: 'nisSatisfactionGoal',
            sortable: false,
            displayCondition: this.showNisColumns &&
              this.$vuetify.breakpoint.mdAndUp &&
              !this.compact
          }
        ]

        return headers.filter((header) => header.displayCondition === undefined || header.displayCondition)
      }
    },
    methods: {
      scoreTooltip(item) {
        if (item.rateLabelsHash) {
          return this.topicsMixin_productLabelsTooltip(item.rateLabelsHash)
        }
      },
      showNbReview(item) {
        return !this.compact &&
          !this.topicsMixin_isDisplayTypeScale(item) &&
          !this.showNisColumns
      },
      exportFileName() {
        const pageName = this.$t(this.$route.name)
        const campaignName = this.campaign.name
        const date = this.$date().locale(this.$i18n.locale).format('ddd DD MMM HH_mm_ss')

        return [pageName, campaignName, date].join(' - ')
      },
      exportToExcel() {
        this.$store.dispatch("notifyInfo");

        const fileName = this.exportFileName();

        const pageName = this.$t(this.$route.name);
        const sheetName = [pageName].join(' - ');

        const data = this.topics.map(topic => {
          const { name, score, evolution, nbReview, nbDetractors, nbNeutrals, nbPromoters } = topic;

          const detractorsPerCent = this.$options.filters.toPercent(nbDetractors / nbReview)
          const neutralsPerCent = this.$options.filters.toPercent(nbNeutrals / nbReview)
          const promotersPerCent = this.$options.filters.toPercent(nbPromoters / nbReview)

          return { name, score, evolution, nbReview, detractorsPerCent, neutralsPerCent,
            promotersPerCent, nbDetractors, nbNeutrals, nbPromoters
          }
        });

        const formattedDatas = formatExcelValues(data, {
          score: { round: 1 },
          evolution: { round: 2, suffix: '%' },
          detractorsPerCent: { suffix: '%' },
          neutralsPerCent: { suffix: '%' },
          promotersPerCent: { suffix: '%' }
        })

        const headers = Object.keys(data[0]).map((key, index) =>
          this.$t(`statistics_widget_topics_ranking_headers_${key}`)
        );

        exportToExcel(fileName, sheetName, formattedDatas, headers);
      },
      async exportToPdf() {
        const exportHeader = async() => {
          const title = this.title || this.$t('statistics_widget_topics_ranking_title')
          await pdf.addRow({}, async(row) => {
            await row.addCol({}, async(col) => {
              col.addText(title, { fontSize: fontSize, color: blackColor, font: { style: 'bold' }})
            })
          })
          await pdf.addRow({ marginTop: 5 }, async(row) => {
            await row.addCol({}, async(col) => {
              col.addText(this.defaultSubtitle, { fontSize: fontSize - 2, font: { style: 'bold' }})
            })
          })

          await exportTableHeaders(pdf)
        }

        const exportTableHeaders = async(pdf) => {
          await pdf.addRow({ height: 15, borderColor: borderColor, marginTop: 20 }, async(row) => {

            await row.addCol({ width: nameWidth }, async(col) => {
              col.addText(this.$t('topic'), { fontSize: fontSize - 2 })
            })

            await row.addCol({ width: scoreWidth, marginLeft: 10 }, async(col) => {
              col.addText(this.$t('average_mark'), { fontSize: fontSize - 2 })
            })

            await row.addCol({ width: repartitionWidth, marginLeft: 10 }, async(col) => {
              col.addText(this.$t('repartition'), { fontSize: fontSize - 2 })
            })
          })
        }

        const exportEvolution = async(evolution, col, decimals = 0) => {
          let displayedEvolution
          let evolutionSign
          let evolutionColor

          if (evolution && evolution != 0) {
            displayedEvolution = evolution > 0 ? evolution : - evolution
            displayedEvolution = displayedEvolution.toFixed(decimals)
            evolutionSign = evolution > 0 ? '▲': '▼'
            evolutionColor = evolution > 0 ? '#65c095': '#c86868'
          } else {
            displayedEvolution = '='
            evolutionSign = ''
            evolutionColor = '#add8e6'
          }

          await col.addRow({}, async(row) => {
            await row.addCol({ marginLeft: 4 }, async(col) => {
              col.addText(evolutionSign, { color: evolutionColor, fontSize: 4, yOffset: 3 })
            })
            await row.addCol({}, async(col) => {
              col.addText(displayedEvolution, { color: evolutionColor, fontSize: fontSize })
            })
          })
        }

        const exportName = async (item, row) => {
          await row.addCol({ width: nameWidth, height: rowHeight}, async(col) => {
            col.addText(item.name, { fontSize: fontSize, color: blackColor })
          })
        }

        const exportScore = async (item, row) => {
          if (item.nbReview) {
            await row.addCol({ width: scoreWidth, marginLeft: 10 }, async(col) => {
              await col.addRow({ marginTop: marginTopWithSubline }, async(row) => {
                await row.addCol({}, async(col) => {
                  await col.addRating(this.scoreForStars(item.score), { size: 10 })
                })
                await row.addCol({ marginLeft: 5 }, async(col) => {
                  col.addText(item.score.toFixed(1), { fontSize: fontSize, color: blackColor })
                })
                await row.addCol({}, async(col) => {
                  await exportEvolution(item.evolution, col, 2)
                })
              })
              await col.addRow({}, async(row) => {
                await row.addCol({ marginLeft: 2 }, async(col) => {
                  col.addText(
                    `${this.$t('rankingPdfExport.nbReview', { nbReview: item.nbReview })}`,
                    { fontSize: subLineFontSize }
                  )
                })
              })
            })
          }
        }

        const exportRepartition = async (item, row) => {
          if (item.nbReview) {
            await row.addCol({ height: rowHeight, marginLeft: 10 }, async(col) => {
              col.addRepartitionBar({ width: 200, height: 20, promoterCount: item.nbPromoters, neutralCount: item.nbNeutrals, detractorCount: item.nbDetractors })
            })
          }
        }

        const exportFooter = async(pdf) => {
          const pagination = this.$t('rankingPdfExport.pagination', { page: pdf.pageCount, totalPage: paginatedData.length })
          const title = this.title || this.$t('statistics_widget_topics_ranking_title')
          const footer = [title, this.defaultSubtitle].join(' - ')

          await pdf.addRow({}, async(row) => {
            await row.addCol({ width: '12' }, async(col) => {
              col.addText(footer, { fontSize: fontSize - 2, align: 'right' })
            })
          })
        }

        this.$store.dispatch("notifyInfo")

        const rowHeight = 30
        const nameWidth = '5'
        const scoreWidth = '3'
        const repartitionWidth = '4'
        const fontSize = 8
        const subLineFontSize = 6
        const marginTop = 6
        const marginTopWithSubline = 4
        const blackColor = "#212121"
        const borderColor = "#e0e0e0"
        const fileName = `${this.exportFileName()}.pdf`

        const pdf = new Pdf({
          defaultBodyMargin: { left: 40, top: 0 },
          defaultHeader: { marginLeft: 40, marginTop: 10, content: exportTableHeaders },
          defaultFooter: { height: 25, marginLeft: 40, content: exportFooter }
        })

        const paginatedData = paginateArray(this.topics, 43, 42)

        await paginatedData.reduce(async (pagePromise, pageDate, pageIndex) => {
          await pagePromise

          if (pagePromise) {
            await pdf.addPage()
          } else {
            await pdf.addPage({
              header: { marginLeft: 40, marginTop: 20, content: exportHeader }
            })
          }

          await pageDate.reduce(async (itemPromise, item, itemIndex) => {
            await itemPromise

            let rowParams = { height: rowHeight, borders: ['top', 'bottom'], borderColor: borderColor }

            await pdf.addRow(rowParams, async(row) => {
              await exportName(item, row)
              await exportScore(item, row)
              await exportRepartition(item, row)
            })

          }, undefined)

        }, undefined)

        pdf.save(fileName)
      },
      scoreForStars(score) {
        if (score === null) {
          return 0
        }

        const displayedsScore = this.campaign.avgScoreScale?.max === 5 ? score : score / 2

        return Math.round(displayedsScore * 2) / 2
      },
      rowClasses(item) {
        return item.id === this.currentTopicId ? 'item active' : 'item'
      }
    }
  }
</script>

<style lang="stylus">
  .topics-table-widget
    .name-cell
      .name
        text-wrap: initial
</style>
