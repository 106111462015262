var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{class:{ 'v-mobile-menu': _vm.$vuetify.breakpoint.xsOnly },attrs:{"close-on-content-click":false,"disabled":!_vm.selectedScope.id || _vm.isMonoAdmin,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.placeLoaded)?_c('div',{staticClass:"w-filter-dropdown d-flex px-2 align-center flex-nowrap c-primary bg-palegrey"},[_c('span',{staticClass:"ml-1 selected-text text-truncate f-13"},[_vm._v("\n          "+_vm._s(_vm.$t('scopeFilter.my_perimeter'))+"\n        ")])]):_c('div',_vm._g(_vm._b({staticClass:"w-filter-dropdown d-flex px-2 align-center flex-nowrap c-primary",class:{
          'bg-bodygrey': _vm.open,
          'bg-palegrey': !_vm.open,
          disabled: _vm.isMonoAdmin,
          'mobile-filter-menu': _vm.$vuetify.breakpoint.xsOnly,
        }},'div',attrs,false),on),[(_vm.mainIcon)?_c('w-icon',{staticClass:"c-accent filter-icon top_0px",attrs:{"icon":_vm.mainIcon}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"ml-1 selected-text text-truncate f-13"},[_vm._v("\n          "+_vm._s(_vm.mainLabel)+"\n        ")]),_vm._v(" "),(!_vm.isMonoAdmin && _vm.selectedScope.id)?_c('v-icon',{staticClass:"ml-auto",attrs:{"color":"primary","size":"medium"}},[_vm._v("\n          mdi-chevron-down\n        ")]):_vm._e()],1)]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_vm._v(" "),(_vm.selectedScope.id)?_c('v-list',{staticClass:"pt-0",attrs:{"dense":""}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_vm._v(" "),_c('v-col',{staticClass:"search-fixed"},[_c('v-text-field',{attrs:{"label":_vm.capitalize(_vm.$t('search')),"append-icon":"mdi-magnify","autofocus":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.debouncedSearch),callback:function ($$v) {_vm.debouncedSearch=$$v},expression:"debouncedSearch"}})],1),_vm._v(" "),(_vm.selectedScope.id && _vm.globalScopeItem)?_c('v-list-item-group',{staticClass:"search-list-item",model:{value:(_vm.selectedScope),callback:function ($$v) {_vm.selectedScope=$$v},expression:"selectedScope"}},[_c('v-list-item',{staticClass:"network-item",class:{ active: _vm.selectedScope.id === _vm.globalScopeItem.id },attrs:{"value":_vm.globalScopeItem,"dense":""}},[_c('w-icon',{staticClass:"fb-12 mr-1",attrs:{"icon":_vm.globalScopeItem.icon}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.globalScopeItem.label))]),_vm._v(" "),(_vm.accessiblePlaces.length)?_c('span',{staticClass:"ml-1",class:_vm.selectedScope.id === _vm.globalScopeItem.id
                ? 'c-white'
                : 'c-lightgrey'},[_vm._v("\n            ("+_vm._s(_vm.accessiblePlaces.length)+")\n          ")]):_vm._e()],1),_vm._v(" "),_vm._l((_vm.filteredGroupSemantics),function(scope){return _c('div',{key:scope.semantic},[_c('v-subheader',[_c('w-icon',{staticClass:"fb-12 mr-1",attrs:{"icon":"map-markers"}}),_vm._v(" "),_c('span',{staticClass:"fb-12 c-accent"},[_vm._v("\n              "+_vm._s(scope.name)+"\n            ")])],1),_vm._v(" "),_vm._l((scope.groups.sort((a, b) => {
              return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base',
              })
            })),function(group){return _c('v-list-item',{key:`group-${group.id}`,staticClass:"f-14",class:{ active: _vm.selectedScope.id === `group-${group.id}` },attrs:{"value":{
              id: `group-${group.id}`,
              label: `${group.name} (${group.nbPlaceId})`,
              icon:'map-markers'
            },"dense":""}},[_c('span',[_vm._v(_vm._s(group.name))]),_vm._v(" "),_c('span',{staticClass:"ml-1",class:_vm.selectedScope.id === `group-${group.id}`
                  ? 'c-white'
                  : 'c-lightgrey'},[_vm._v("\n              ("+_vm._s(group.nbPlaceId)+")\n            ")])])})],2)}),_vm._v(" "),(!_vm.loading)?[_c('v-subheader',[_c('w-icon',{staticClass:"fb-12 mr-1",attrs:{"icon":"map-marker"}}),_vm._v(" "),_c('span',{staticClass:"fb-12 c-accent"},[_vm._v("\n              "+_vm._s(_vm.currentLexicon.translate('places', 'places'))+"\n            ")])],1),_vm._v(" "),_vm._l((this.selectablePlaces.sort((a, b) => {
              return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base',
              })
            })),function(place){return _c('v-list-item',{key:`place-${place.id}`,staticClass:"f-14",class:{ active: _vm.selectedScope.id === `place-${place.id}` },attrs:{"value":{
              id: `place-${place.id}`,
              label: `${place.name}`,
              icon:'map-markers'
            },"dense":""}},[_c('span',[_vm._v(_vm._s(place.name))]),_vm._v(" "),_c('span',{staticClass:"ml-1",class:_vm.selectedScope.id === `place-${place.id}`
                  ? 'c-white'
                  : 'c-lightgrey'})])})]:_vm._e()],2):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }