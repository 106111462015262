<template>
  <div class="product-feeds">
    <div
      v-for="productFeed in productFeeds"
      :key="productFeed.locale"
      class="mt-0"
    >
      <div v-if="productsStatsData" class="d-flex align-center">
        <div class="d-flex flex-column" :style="{ width: languageWidth }">
          <span class="fs-13 c-primary">{{ productFeed.language }}</span>
          <div v-if="productsStatsData[productFeed.locale]">
            <span class="f-10">{{ `${$t('product_feeds_settings_page_nb_translated_products')}: ${productsStatsData[productFeed.locale].nbTranslatedProducts}` }}</span>
            <span class="f-10">{{ `${$t('product_feeds_settings_page_nb_translated_unique_products')}: ${productsStatsData[productFeed.locale].nbTranslatedUniqueProducts}` }}</span>
          </div>
        </div>
        <v-text-field
          v-model="productFeed.urlLengow"
          :label="$t('product_feeds_settings_page_default_language_flow_settings_feed_url_link')"
          :rules="required ? [$required] : []"
          hide-details
          dense
          class="f-13"
          :class="{'mb-3': !productsStatsData[productFeed.locale] }"
        >
          <template v-slot:prepend>
            <w-flag-icon
              :locale="productFeed.locale"
            />
          </template>
        </v-text-field>
      </div>
      <v-skeleton-loader v-else type="list-item-two-line" />
    </div>
  </div>
</template>

<script>
  export default {
    name: "ProductFeeds",
    props: ['productFeeds', 'productsStatsData', 'required'],
    methods: {
      widthFromTexts(texts) {
        let maxTextLength = Math.max.apply(Math, texts.map(text => text.length))
        return (maxTextLength * 7) + 3
      }
    },
    computed: {
      languageWidth() {
        const widthFromText = this.widthFromTexts(this.productFeeds.map(productFeed => productFeed.language || ''))
        if (widthFromText < 70) {
          return '70px'
        } else {
          return `${widthFromText}px`
        }
      },
      localizedLanguageWidth() {
        const widthFromText = this.widthFromTexts(this.localizedLocaleFeeds.map(productFeed => productFeed.language || ''))
        if (widthFromText < 70) {
          return '70px'
        } else {
          return `${widthFromText}px`
        }
      }
    }
  }
</script>
