var render = function render(){var _vm=this,_c=_vm._self._c;return _c('WStatsWidget',{staticClass:"evolution-widget",attrs:{"cols":12,"subtitle":_vm.displayedSubtitle,"title":_vm.title,"contentPadding":"0.75em 0.75em 0 0.75em","contentWidth":"100%"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._t("title",function(){return [_vm._v("\n      "+_vm._s(_vm.title || _vm.$t('widget_evolution_title'))+"\n    ")]})]},proxy:true},{key:"options",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._t("options")],2),_vm._v(" "),_c('div',[_c('w-drop-down-menu',{attrs:{"data-html2canvas-ignore":"","items":_vm.exportOptions,"icon":"mdi-download"}})],1)])]},proxy:true},{key:"content",fn:function(){return [_c('WTimelineChart',{key:_vm.dashboardFilterBase64,ref:"timelineChart",attrs:{"height":"300px","defaultOptions":_vm.defaultOptions,"yAxisLabelsFormatter":_vm.yAxisLabelsFormatter,"decimals":_vm.decimals,"colorLines":[
        {
          linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 1
          },
          stops: _vm.stopColors
        }
      ],"type":"areaspline"}})]},proxy:true},{key:"footer-left",fn:function(){return undefined},proxy:true},{key:"footer-right",fn:function(){return undefined},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }