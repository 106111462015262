<template>
  <WStatsWidget
    v-if="loading || !loading && sources.length"
    :cols="cols"
    :height="widgetHeight"
    :justifyCenter="false"
    :subtitle="defaultSubtitle"
    :title="title || $t('statistics_widget_sources_ranking_title')"
    class="sources-table-widget"
    contentWidth="100%"
    :loading="loading"
  >
    <template #options>
      <w-drop-down-menu
        v-if="showExportOptions && !loading"
        :items="exportOptions"
        icon="mdi-download">
      </w-drop-down-menu>
    </template>
    <template #content>
      <v-data-table
        v-if="!loading"
        :headers="headers"
        :items="sources"
        :items-per-page="40"
        hide-default-footer
        hide-default-header
        mobile-breakpoint="0"
        :item-class= "rowClasses"
        class="sources-table v-datatable-without-y-borders"
        @click:row="zoom"
      >
        <template v-slot:item.name="{ item }">
          <div class="d-flex align-center ga-4">
            <w-zoom-button
              v-if="$vuetify.breakpoint.smAndUp"
              :highlighted="item.name === currentSource"
              @click="zoom(item)"
            />
            <span class="name fs-13 c-primary">{{ $t(`source_${item.name}`) }}</span>
          </div>
        </template>
        <template v-slot:item.score="{ item }">
          <ScoreCell
            :nbReview="item.nbReview"
            :score="item.score.toFixed(1)"
            :evolution="item.evolution"
            :nbPromoters="item.nbPromoters"
            :nbDetractors="item.nbDetractors"
            :nbNeutrals="item.nbNeutrals"
            :repartitionWidth="repartitionWidth"
          />
        </template>
      </v-data-table>
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { sqlDate } from '@vue/plugins/helpers/dates'
  import exportToExcel from '@shared/helpers/export-to-excel.js'
  import Pdf from '@shared/helpers/exportToPdf/pdf'
  import paginateArray from '@shared/helpers/paginate-array'
  import _camelCase from "lodash/camelCase"

  import ScoreCell from '@statistics/shared/Table/ScoreCell'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'


  export default {
    name: "SourcesTableWidget",
    components: {
      ScoreCell
    },
    mixins: [
      WidgetMixin
    ],
    props: {
      title: {
        type: String,
        required: false
      },
      cols: { type: Number, default: 6 },
      maxHeight: { required: false, default: "360px" },
      minHeight: { required: false, default: "360px" },
      currentSource: { required: false },
      showExportOptions: {required: false, default: true }
    },
    data() {
      return {
        loading: true
      }
    },
    computed: {
      ...mapGetters([
        'currentDashboardScopedCampaigns',
        'dashboardFilterDates',
        'datesScope',
        'dashboardFilterRequest',
        'dashboardFilterReady',
        'placeIdsScope',
        'dashboardAvgScale',
        'currentDashboardPublicPlatforms'
      ]),
      repartitionWidth() {
        return this.$vuetify.breakpoint.mdAndUp ? undefined : "100px"
      },
      widgetHeight() {
        if (this.loading) {
          return '150px'
        }

        const headerHeight = 65
        const tableMargins = 16
        const height = headerHeight +
                       this.sources.length * 50 +
                       tableMargins

        return height > 310 ? '310px' : `${height}px`
      },
      exportOptions() {
        return [
          { title: 'PDF', onClick: this.exportToPdf },
          { title: 'Excel', onClick: this.exportToExcel }
        ]
      },
      headers() {
        let headers = [
          {
            text: this.$t('source'),
            value: 'name',
            align: 'start',
            cellClass: 'name-cell'
          },
          {
            text: this.$t('average_mark'),
            value: 'score'
          }
        ]

        return headers.filter((header) => header.displayCondition === undefined || header.displayCondition)
      }
    },
    methods: {
      computeColumn(columnName, min, max, condition, as) {
        return {
          [columnName]: {
            min, max, condition, as
          }
        }
      },
      computeColumns(columnName) {
        return [
          this.computeColumn(columnName, 0, 3, '[..[', 'nbDetractors', this.source),
          this.computeColumn(columnName, 3, 4, '[..[', 'nbNeutrals', this.source),
          this.computeColumn(columnName, 4, 5, '[..]', 'nbPromoters', this.source)
        ]
      },
      zoom(item) {
        this.$router.push(this.linkTo(item))
      },
      exportFileName() {
        const pageName = this.$t(this.$route.name)
        const date = this.$date().locale(this.$i18n.locale).format('ddd DD MMM HH_mm_ss')

        return [pageName, date].join(' - ')
      },
      exportToExcel() {
        this.$store.dispatch("notifyInfo");

        const fileName = this.exportFileName();

        const pageName = this.$t(this.$route.name);
        const sheetName = [pageName].join(' - ');

        const data = this.sources.map((source) => {
          const { id, sourceName, ...filteredMapping } = source
          return filteredMapping
        })

        const headers = Object.keys(data[0]).map((key, index) =>
          this.$t(`statistics_widget_sources_ranking_headers_${key}`)
        );

        exportToExcel(fileName, sheetName, data, headers);
      },
      async exportToPdf() {
        const exportHeader = async() => {
          const title = this.title || this.$t('statistics_widget_sources_ranking_title')
          await pdf.addRow({}, async(row) => {
            await row.addCol({}, async(col) => {
              col.addText(title, { fontSize: fontSize, color: blackColor, font: { style: 'bold' }})
            })
          })
          await pdf.addRow({ marginTop: 5 }, async(row) => {
            await row.addCol({}, async(col) => {
              col.addText(this.defaultSubtitle, { fontSize: fontSize - 2, font: { style: 'bold' }})
            })
          })

          await exportTableHeaders(pdf)
        }

        const exportTableHeaders = async(pdf) => {
          await pdf.addRow({ height: 15, borderColor: borderColor, marginTop: 20 }, async(row) => {

            await row.addCol({ width: nameWidth }, async(col) => {
              col.addText(this.$t('source'), { fontSize: fontSize - 2 })
            })

            await row.addCol({ width: scoreWidth, marginLeft: 10 }, async(col) => {
              col.addText(this.$t('average_mark'), { fontSize: fontSize - 2 })
            })

            await row.addCol({ width: repartitionWidth, marginLeft: 10 }, async(col) => {
              col.addText(this.$t('repartition'), { fontSize: fontSize - 2 })
            })
          })
        }

        const exportEvolution = async(evolution, col, decimals = 0) => {
          let displayedEvolution
          let evolutionSign
          let evolutionColor

          if (evolution && evolution != 0) {
            displayedEvolution = evolution > 0 ? evolution : - evolution
            displayedEvolution = displayedEvolution.toFixed(decimals)
            evolutionSign = evolution > 0 ? '▲': '▼'
            evolutionColor = evolution > 0 ? '#65c095': '#c86868'
          } else {
            displayedEvolution = '='
            evolutionSign = ''
            evolutionColor = '#add8e6'
          }

          await col.addRow({}, async(row) => {
            await row.addCol({ marginLeft: 4 }, async(col) => {
              col.addText(evolutionSign, { color: evolutionColor, fontSize: 4, yOffset: 3 })
            })
            await row.addCol({}, async(col) => {
              col.addText(displayedEvolution, { color: evolutionColor, fontSize: fontSize })
            })
          })
        }

        const exportName = async (item, row) => {
          await row.addCol({ width: nameWidth, height: rowHeight}, async(col) => {
            col.addText(item.name, { fontSize: fontSize, color: blackColor })
          })
        }

        const exportScore = async (item, row) => {
          if (item.nbReview) {
            await row.addCol({ width: scoreWidth, marginLeft: 10 }, async(col) => {
              await col.addRow({ marginTop: marginTopWithSubline }, async(row) => {
                await row.addCol({}, async(col) => {
                  await col.addRating(this.scoreForStars(item.score), { size: 10 })
                })
                await row.addCol({ marginLeft: 5 }, async(col) => {
                  col.addText(item.score.toFixed(1), { fontSize: fontSize, color: blackColor })
                })
                await row.addCol({}, async(col) => {
                  await exportEvolution(item.evolution, col, 2)
                })
              })
              await col.addRow({}, async(row) => {
                await row.addCol({ marginLeft: 2 }, async(col) => {
                  col.addText(
                    `${this.$t('rankingPdfExport.nbReview', { nbReview: item.nbReview })}`,
                    { fontSize: subLineFontSize }
                  )
                })
              })
            })
          }
        }

        const exportRepartition = async (item, row) => {
          if (item.nbReview) {
            await row.addCol({ height: rowHeight, marginLeft: 10 }, async(col) => {
              col.addRepartitionBar({ width: 200, height: 20, promoterCount: item.nbPromoters, neutralCount: item.nbNeutrals, detractorCount: item.nbDetractors })
            })
          }
        }

        const exportFooter = async(pdf) => {
          const pagination = this.$t('rankingPdfExport.pagination', { page: pdf.pageCount, totalPage: paginatedData.length })
          const title = this.title || this.$t('statistics_widget_sources_ranking_title')
          const footer = [title, this.defaultSubtitle].join(' - ')

          await pdf.addRow({}, async(row) => {
            await row.addCol({ width: '12' }, async(col) => {
              col.addText(footer, { fontSize: fontSize - 2, align: 'right' })
            })
          })
        }

        this.$store.dispatch("notifyInfo")

        const rowHeight = 30
        const nameWidth = '5'
        const scoreWidth = '3'
        const repartitionWidth = '4'
        const fontSize = 8
        const subLineFontSize = 6
        const marginTop = 6
        const marginTopWithSubline = 4
        const blackColor = "#212121"
        const borderColor = "#e0e0e0"
        const fileName = `${this.exportFileName()}.pdf`

        const pdf = new Pdf({
          defaultBodyMargin: { left: 40, top: 0 },
          defaultHeader: { marginLeft: 40, marginTop: 10, content: exportTableHeaders },
          defaultFooter: { height: 25, marginLeft: 40, content: exportFooter }
        })

        const paginatedData = paginateArray(this.sources, 43, 42)

        await paginatedData.reduce(async (pagePromise, pageDate, pageIndex) => {
          await pagePromise

          if (pagePromise) {
            await pdf.addPage()
          } else {
            await pdf.addPage({
              header: { marginLeft: 40, marginTop: 20, content: exportHeader }
            })
          }

          await pageDate.reduce(async (itemPromise, item, itemIndex) => {
            await itemPromise

            let rowParams = { height: rowHeight, borders: ['top', 'bottom'], borderColor: borderColor }

            await pdf.addRow(rowParams, async(row) => {
              await exportName(item, row)
              await exportScore(item, row)
              await exportRepartition(item, row)
            })

          }, undefined)

        }, undefined)

        pdf.save(fileName)
      },
      scoreForStars(score) {
        if (score === null) {
          return 0
        }

        const displayedsScore = score

        return Math.round(displayedsScore * 2) / 2
      },
      rowClasses(item) {
        return item.name === this.currentSource ? 'item active' : 'item'
      },
      linkTo(source) {
        return {
          name: 'Statistics',
          params: {
            sectionType: 'social',
            sectionId: source.name
          }
        }
      },
      async previousPeriodScoreBySource() {
        let request = this.dashboardFilterRequest.select({
          voters: [
            { avg_satisfaction: { as: 'score' } }
          ]
        })
        .dateBetween(
          sqlDate(this.datesScope.comparedDateBegin),
          sqlDate(this.datesScope.comparedDateEnd, '23:59:59')
        )
        .group(['source'])
        .where({ source: this.currentDashboardPublicPlatforms })


        if (this.placeIdsScope && this.placeIdsScope.length) {
          request = request.where({
            place_campaigns_place_id: this.placeIdsScope,
          })
        }

        return (await this.$resolve(request))?.data || {}
      },
      async getData() {

        const nbReviewByAvgColumns = this.computeColumns('nb_review_by_avg_satisfaction')

        let request = this.dashboardFilterRequest.select({
          voters: nbReviewByAvgColumns.concat([
            { avg_satisfaction: { as: 'score' } },
            { MAX_source: { as: 'name' } },
            { nb_review: { as: 'nbReview' } }
          ])
        })
        .where({ source: this.currentDashboardPublicPlatforms })
        .dateBetween(
          sqlDate(this.datesScope.dateBegin),
          sqlDate(this.datesScope.dateEnd, '23:59:59')
        )
        .order(
          [
            ['avg_satisfaction', "desc"],
            ['nb_review', "desc"]
          ]
        )
        .group(['source'])


        if (this.placeIdsScope && this.placeIdsScope.length) {
          request = request.where({
            place_campaigns_place_id: this.placeIdsScope,
          })
        }

        return (await this.$resolve(request)).first() || []
      }
    },
    asyncComputed: {
      sources: {
        async get() {
          if (this.dashboardFilterReady) {
            this.loading = true
            const data = await this.getData()

            const previousPeriodScoreBySource = await this.previousPeriodScoreBySource()

            const result = data.map((source) => {
              const previousScore = previousPeriodScoreBySource[_camelCase(source.name)]?.score

              if (previousScore) {
                source.evolution = source.score - previousScore
              }
              return source
            })
            this.loading = false

            return result
          }
        },
        default: []
      }
    }
 }
</script>

<style lang="stylus" scoped>
  .sources-table-widget
    .name-cell
      .name
        text-wrap: initial
</style>
