<template>
  <div>
    <v-row no-gutters class="pa-3">
      <v-col>
        <ConversationPreFilledMessageGroups/>
      </v-col>
    </v-row>
    <v-row no-gutters class="pa-3">
      <v-col>
        <VoterAutomatedMessageTreatments/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ConversationPreFilledMessageGroups from './ConversationPreFilledMessageGroups'
  import VoterAutomatedMessageTreatments from './VoterAutomatedMessageTreatments'

  export default {
    name: 'ConversationPreFilledMessage',
    components: {
      ConversationPreFilledMessageGroups,
      VoterAutomatedMessageTreatments
    }
  }
</script>

<style lang="stylus" scoped>
</style>
