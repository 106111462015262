<template>
  <div>
    <v-menu
      v-if="messages.length || smartAnswersEnabled"
      :attach="!this.$vuetify.breakpoint.smAndDown"
      :bottom="this.$vuetify.breakpoint.smAndDown"
      :close-on-content-click="false"
      :top="!this.$vuetify.breakpoint.smAndDown"
      left
      v-model="open"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="w-filter-dropdown d-flex justify-space-between align-center"
          v-bind="attrs"
          v-on="on"
        >
          <template v-if="smartAnswersEnabled">
            <div
              v-if="smartAnswerLoading"
              class="auto-fix bg-ai-gradient d-flex justify-center align-center pa-2"
            >
              <v-progress-circular
                :size="18"
                color="white"
                indeterminate
              />
            </div>
            <v-tooltip v-else top>
              <template #activator="{on}">
                <div
                  v-on="on"
                  @click.stop="$emit('smart-answer-requested')"
                  class="auto-fix bg-ai-gradient d-flex justify-center align-center pa-2"
                >
                  <WIcon
                    icon="auto-fix"
                    :icon-style="{fontSize: '18px', color: 'white'}"
                  />
                </div>
              </template>
              {{ $t('prefilled_msg_selector_smart_answer_subtitle') }}
            </v-tooltip>
          </template>
          <div class="dropdown-title pa-2">
            <span class="selected-text f-14 c-dark">
              {{ $t('preFilledMessage') }}
            </span>
          </div>
          <v-icon size="medium" color="dark" class="pt-2 pr-2 pb-2">
            {{ open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </div>
      </template>
      <v-slide-x-transition leave-absolute>
        <v-list class="pb-0" v-if="localeChange">
          <v-list-item-group>
            <v-list-item
              v-for="locale in availableLocales"
              class="locale-item"
              :key="locale"
              @click="selectLocale(locale)"
              dense
            >
              <v-list-item-content>
                <v-list-item-title class="d-flex align-center">
                  <w-flag-icon :locale="locale" class="ml-2" />
                  <span>{{ $t(`languages.${locale}`) }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-divider class="mt-1"></v-divider>
          <div
            @click="localeChange = false"
            class="pointer d-flex justify-space-between align-center pa-2"
          >
            <span class="fs-14">
              {{ $t('dateFilter.back') }}
            </span>
            <v-icon>mdi-chevron-right</v-icon>
          </div>
        </v-list>
      </v-slide-x-transition>
      <v-slide-x-reverse-transition leave-absolute>
        <v-list class="pb-0" v-if="!localeChange" dense>
          <v-col>
            <v-text-field
              v-model="search"
              :label="capitalize($t('search'))"
              append-icon="mdi-magnify"
              @keydown.enter.prevent
              autofocus
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-list-item-group>
            <v-list-item
              class="f-14"
              dense
              @click="requestSmartAnswerGeneration"
              v-if="smartAnswersEnabled"
            >
              <WIcon
                :icon-style="{fontSize: '24px'}"
                class="mr-3"
                icon="auto-fix-gradient"
              />
              <v-list-item-content>
                <v-list-item-title class="fs-14 c-dark">
                  {{ $t('prefilled_msg_selector_smart_answer') }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-truncate f-12 c-lightgrey">
                  {{ $t('prefilled_msg_selector_smart_answer_subtitle') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="(msg, idx) in filteredMessages"
              :key="idx"
              @click="selectMsg(msg)"
              class="f-14"
              dense
            >
              <v-list-item-content>
                <v-list-item-title class="fs-14 c-dark">
                  {{ msg.title }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-truncate f-12 c-lightgrey">
                  {{ msg.content }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <template v-if="availableLocales.length > 1">
            <v-divider class="mt-1"></v-divider>
            <div
              @click="localeChange = true"
              class="pointer d-flex align-center pa-2"
            >
              <v-icon>mdi-chevron-left</v-icon>
              <span class="fs-14">{{ $t(`languages.${contentLocale}`) }}</span>
              <w-flag-icon :locale="contentLocale" />
            </div>
          </template>
        </v-list>
      </v-slide-x-reverse-transition>
    </v-menu>
  </div>
</template>

<script>
import dynamicFields from '@shared/helpers/dynamic-fields'
import { mapGetters } from 'vuex'
import { capitalize } from 'inflection'

export default {
  name: "PreFilledMsgSelector",
  props: {
    voter: { required: true },
    smartAnswerLoading: { type: Boolean, default: false },
    prospectFields: { type: Object, default: () => ({}) },
    fieldsCustomization: { type: Object, default: () => ({}) },
    gBrandName: { type: String, default: '' },
    userMessageCount: { type: Number },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      localeChange: false,
      open: false,
      loading: true,
      selectedLocale: null,
      search: null,
    }
  },
  computed: {
    ...mapGetters([
      'currentVoter',
      'currentUser',
      'currentLexicon',
      'currentSurvey',
      'currentUserIsAdmin',
      'currentVoterSmartAnswersEnabled',
      'hasRightSmartAnswer',
    ]),
    smartAnswersEnabled() {
      return this.currentVoterSmartAnswersEnabled && this.userMessageCount == 0 && this.hasRightSmartAnswer
    },
    filteredMessages() {
      if (this.search) {
        return this.localizedMessages.filter((msg) => {
          let regex = new RegExp(
            this.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
            'i'
          )
          return msg.title.match(regex)
        })
      } else {
        return this.localizedMessages
      }
    },
    localizedMessages() {
      return this.messages
        .map((msg, idx) => {
          let title =
            msg.translations[this.$i18n.locale]?.title ||
            msg.translations[this.currentSurvey?.locales?.[0]]?.title ||
            Object.entries(
              msg.translations).map(([key,locale]) => locale.title
            ).filter(Boolean)[0]

          let content = msg.translations[this.contentLocale]?.content

          if (content) {
            content = dynamicFields(
              content,
              true,
              this.prospectFields,
              this.fieldsCustomization,
              this.currentLexicon,
              this.gBrandName,
              this.currentVoter?.placeName,
              this.currentVoter,
              idx == 9
            )
          }

          return { title, content }
        })
        .filter((msg) => msg.content)
    },
    contentLocale() {
      return this.selectedLocale || this.defaultLocale
    },
    defaultLocale() {
      const locales = [
        this.currentVoter.language,
        ...(this.currentSurvey?.locales || []),
        this.currentUser.locale,
        this.$i18n.locale,
        'en',
      ].filter((locale) => this.availableLocales.includes(locale))

      return [...new Set(locales)][0] || this.availableLocales[0]
    },
    availableLocales() {
      return [
        ...new Set(
          this.messages.flatMap((msg) => {
            return Object.keys(msg.translations).filter((locale) => {
              return msg.translations[locale].title
            })
          })
        ),
      ]
    },
  },
  methods: {
    capitalize,
    selectLocale(locale) {
      this.selectedLocale = locale
      this.localeChange = false
    },
    selectMsg(msg) {
      this.$emit('select', msg.content)
      this.close()
    },
    close() {
      this.open = false
    },
    requestSmartAnswerGeneration() {
      this.close()
      this.$emit('smart-answer-requested')
    }
  },
  asyncComputed: {
    messages: {
      default: [],
      async get() {
        this.loading = true
        const req = this.$basedRequest()
          .select({
            conversation_pre_filled_messages: ['translations'],
          })
          .where({ campaign_id: this.voter.campaignId })

        const response = await this.$resolve(req)

        this.loading = false
        return response.data.conversationPreFilledMessages
      }
    },
  },
  watch: {
    open: function(value) {
      this.$emit('opened', value)
    }
  },
}
</script>

<style lang="stylus" scoped>
  @import "~@theme/colors.styl"

  .auto-fix
    border-radius: 4px 0 0 4px
    height: 100%

  .v-menu__content
    max-width: 300px !important

  .locale-item
    padding: 0 !important

    .v-list-item__content
      padding: 0 !important

  .w-filter-dropdown
    background-color: var(--oslo-gray-100)
    border-radius: 4px
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.20)

  .v-list-item__title
    white-space: normal !important

  .v-list-item-group
    max-height: 255px
    overflow: scroll
</style>
