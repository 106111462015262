import { sessionToken, legacyOldUrl } from "@shared/helpers/env-helpers.js"

function initSession() {
  if (sessionToken) {
    localStorage.setItem("wizvilleSessionToken", sessionToken)
  }

  return localStorage.getItem('wizvilleSessionToken')
}

function initSessionGuards(router, store, authContext, loginRoute = { name: "Login" } ) {
  router.beforeEach(
    async (to, from, next) => {
      if (localStorage.getItem('wizvilleSessionToken')) {
        await store.dispatch(authContext)

        if (store.getters.isLoading) {
          return
        }

        if (to.name !== 'Logout' && store.getters.appVersion === 'legacy') {
          window.location = `${legacyOldUrl}/?alk=${store.getters.currentUser.alk}`
        } else if (
          (to.name === 'Statistics' && store.getters.hasAccessToNewPlatformStatisticTab === false) ||
          (to.name === 'Ranking' && store.getters.hasAccessToNewPlatformRankingTab === false) ||
          (to.name === 'Feedbacks' && store.getters.hasAccessToNewPlatformFeedbackTab === false) ||
          (to.name === 'Competitions' && store.getters.hasAccessToNewPlatformLocalInsightTab === false) ||
          (to.name === 'Achievements' && store.getters.hasAccessToNewPlatformAchievementsTab === false)
        ) {
          next({ name: 'Dashboards' })
        } else if (to.meta.authRedirected) {
          next({ name: to.meta.authRedirected })
        } else {
          next()
        }
      } else {
        if (!to.meta.public) {
          next(loginRoute)
        } else {
          next()
        }
      }
    }
  )
}

export { initSession, initSessionGuards }
