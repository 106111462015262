<template>
  <div>
    <AppBar></AppBar>
    <CampaignPreferencesSideBar></CampaignPreferencesSideBar>

    <v-main class="cammpaign-preferences-layout">
      <router-view />
    </v-main>
  </div>
</template>

<script>
  import AppBar from "./navigation/AppBar"
  import { mapGetters } from 'vuex'

  import CampaignPreferencesSideBar from './navigation/sidebars/CampaignPreferencesSideBar'

  export default {
    name: 'CampaignPreferencesLayout',
    components: {
      AppBar,
      CampaignPreferencesSideBar
    },
    data() {
      return {
        drawer: true
      }
    },
    computed: {
      ...mapGetters([]),
    },
  }
</script>

<style lang="stylus" scoped>
</style>
