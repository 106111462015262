<template>
  <v-btn
    class="test-send-message"
    :disabled="!isValid"
    :ripple="false"
    color="accent"
    v-clipboard:copy="content"
    v-clipboard:success="openChannelTab"
  >
    <w-icon
      class="mr-1"
      :class="isValid ? 'c-white' : 'c-lightgrey'"
      :icon="smartChannel"
      :icon-style="{ fontSize: '18px' }"
    />
    {{ btnText }}
    <v-dialog v-model="openDialog" width="50%">
      <v-card>
        <v-card-title
          class="text-h5 grey lighten-2 d-flex justify-space-between"
        >
          <span>
            {{ $t('source_redirect', { source_host: sourceHost }) }}
          </span>
          <v-icon @click="openDialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-progress-linear
          v-model="redirectLoading"
          color="accent"
        >
        </v-progress-linear>
        <v-card-text
          class="pa-5 d-flex justify-space-between align-center"
        >
          <v-row class="align-center">
            <v-col cols="3">
              <v-icon color="success" size="100">
                mdi-clipboard-check-multiple-outline
              </v-icon>
            </v-col>
            <v-col>
              <span
                v-if="sourceRedirectUrl"
                class="f-16"
                v-html="
                  $t('text_copied_subtitle', {
                    redirect_url: sourceRedirectLink,
                  })
                "
              ></span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
export default {
  name: 'BtnSendMessageRedirectModal',
  props: {
    smartChannel: { type: String, required: true },
    isValid: { type: Boolean, required: true },
    content: { type: String, required: false },
    btnText: { type: String, required: true },
    voter: { required: true }
  },
  data() {
    return {
      openDialog: false,
      redirectLoading: 0
    }
  },
  computed: {
    sourceRedirectLink() {
      return `
        <a href="${this.sourceRedirectUrl}" target="_blank">${this.sourceHost}</a>
      `
    },
    sourceHost() {
      return {
        trip_advisor: 'TripAdvisor.com',
        pages_jaunes: 'PagesJaunes.fr',
      }[this.smartChannel]
    },
  },
  asyncComputed: {
    sourceRedirectUrl: {
      async get() {
        if (['pages_jaunes', 'trip_advisor'].includes(this.smartChannel)) {
          const req = this.$basedRequest()
            .select({ voters: ['source_redirect_url'] })
            .where({ unique_id: this.voter.uniqueId })

          const data = (await this.$resolve(req)).first();

          return data.sourceRedirectUrl
        }
      },
      default() {
        return null
      }
    }
  },
  methods: {
    loadRedirect() {
      setTimeout(() => {
        if (this.redirectLoading < 100) {
          this.redirectLoading += 20
          this.loadRedirect()
        } else {
          window.open(this.sourceRedirectUrl, '_blank')
        }
      }, 1000)
    },
    openChannelTab() {
      this.openDialog = true
      this.loadRedirect()
    },
  }
}
</script>

