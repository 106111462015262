import { mapGetters } from 'vuex'

export default {
    data: () => ({
        translatedData: ['all_places', 'my_perimeters', 'brand', 'country', 'zone', 'area', 'region'],
    }),
    methods: {
        collaboratorsMixin_isInArray(value) {
            return this.translatedData.includes(value);
        },
        collaboratorsMixin_getSemanticTranslation(item) {
            if (this.collaboratorsMixin_normalizedPlaceNamePresent(item)){
                return item.semanticNormalized;
            }
            if (this.collaboratorsMixin_isInArray(item.name)) {
                return item.name;
            } else {
                return 'group_semantic.' + item.name
            }
        },
        collaboratorsMixin_normalizedPlaceNamePresent(item) {
            return (item.semanticNormalized != null || item.semanticNormalized != '')
        },
        collaboratorsMixin_normalizedPlaceName(item){
            if (this.collaboratorsMixin_normalizedPlaceNamePresent(item)){
                return item.semanticNormalized;
            }
            return item.name;
        }
    }
}
    