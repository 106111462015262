import Vue from 'vue'
import { mapGetters } from 'vuex'
import { sqlDate } from '@vue/plugins/helpers/dates'
import _cloneDeep from 'lodash/cloneDeep'
import NisMixin from '@statistics/shared/nis_mixin'

export default {
  mixins: [
    NisMixin,
  ],
  computed: {
    ...mapGetters([
      'placeIdsScope',
      'dashboardFilterRequest',
      'datesScope',
      'dashboardFilterBase64',
      'requestedTransactionalUnit',
      'netImpactScoreSummariesLoaded'
    ])
  },
  data() {
    return {
      _baseData: {},
      npsMixin_nps: {},
      _baseData_isLoading: {},
      _nps_isLoading: {},
    }
  },
  watch: {
    dashboardFilterBase64() {
      this.$data._baseData = {}
      this.npsMixin_nps = {}
    },
    requestedTransactionalUnit() {
      this.npsMixin_nps = {}
    },
    netImpactScoreSummariesLoaded(loaded) {
      if (loaded) {
        this.npsMixin_nps = {}
      }
    }
  },
  methods: {
    async _previousPeriodNpsByCampaignId(campaign) {
      let request = this.dashboardFilterRequest
                        .select({
                          voters: [
                            { avg_nps: { as: 'score' } }
                          ]
                        })
                        .where({ campaign_id: campaign.id })
                        .dateBetween(
                          sqlDate(this.datesScope.comparedDateBegin),
                          sqlDate(this.datesScope.comparedDateEnd, '23:59:59')
                        )
                        .group(['campaign_id'])

      if (this.placeIdsScope && this.placeIdsScope.length) {
        request = request.where({
          place_campaigns_place_id: this.placeIdsScope,
        })
      }

      return (await this.$resolve(request)).first()?.score
    },
    async _loadBaseData(campaign) {
      // Data has already been loaded.
      if (this.$data._baseData[campaign.id] !== undefined) {
        return
      }
      // Set lock.
      if (this.$data._baseData_isLoading[campaign.id]) {
        return
      }
      this.$data._baseData_isLoading[campaign.id] = true
      // Prepare request.
      const gradeCondition = [
        { ['nb_review_by_nps']: { min: 0, max: 7, condition: '[..[', as: 'nbDetractors' }},
        { ['nb_review_by_nps']: { min: 7, max: 9, condition: '[..[', as: 'nbNeutrals' }},
        { ['nb_review_by_nps']: { min: 9, max: 10, condition: '[..]', as: 'nbPromoters' }}
      ]
      let request = this.dashboardFilterRequest
                        .select({
                          voters: gradeCondition.concat([
                            { MAX_campaign_id: { as: 'id' } },
                            { MAX_campaign_name: { as: 'name' } },
                            { avg_nps: { as: 'score' } },
                            { nb_review: { as: 'nbReview' } }
                          ])
                        })
                        .where({ campaign_id: campaign.id })
                        .dateBetween(
                          sqlDate(this.datesScope.dateBegin),
                          sqlDate(this.datesScope.dateEnd, '23:59:59')
                        )
                        .order(
                          [
                            ['avg_nps', 'desc'],
                            ['nb_review', 'desc']
                          ]
                        )
      if (this.placeIdsScope && this.placeIdsScope.length) {
        request = request.where({
          place_campaigns_place_id: this.placeIdsScope,
        })
      }
      // Load data.
      let result = (await this.$resolve(request)).first() || {}
      // Formatting.
      result.score = Number(result.score)
      // Load evolution data.
      const previousScore = await this._previousPeriodNpsByCampaignId(campaign)
      if (previousScore) {
        result.evolution = result.score - previousScore
      }
      // Set topics for that campaign.
      Vue.set(
        this.$data._baseData,
        campaign.id,
        result
      )
      // Remove lock.
      this.$data._baseData_isLoading[campaign.id] = false
    },
    async npsMixin_loadNps(campaign) {
      // Data has already been loaded.
      if (this.npsMixin_nps[campaign.id] !== undefined) {
        return
      }
      // Set lock.
      if (this.$data._nps_isLoading[campaign.id]) {
        return
      }
      this.$data._nps_isLoading[campaign.id] = true
      // Load data.
      await this.nisMixin_loadNetImpactScoreSummariesByCampaignId()
      await this._loadBaseData(campaign)
      // The data is not available yet, let's defer.
      if (this.$data._baseData[campaign.id] === undefined) {
        this.$data._nps_isLoading[campaign.id] = false
        setTimeout(
          () => this.npsMixin_loadNps(campaign),
          500
        )
        return
      }
      // Add NIS data.
      const npsData = _cloneDeep(this.$data._baseData[campaign.id])
      if (this.nisMixin_canUseNisFeature([campaign]) && this.nisMixin_npsSummary(campaign)) {
        const nisSummary = this.nisMixin_npsSummary(campaign)

        npsData.nisRoiSatisfaction = this.nisMixin_roiSatisfaction(npsData, nisSummary)
        npsData.nisOpportunity = this.nisMixin_transactionalUnitToEarn(npsData, nisSummary)
        npsData.nisPlaceReferences = this.nisMixin_getPlaceReferences(nisSummary)
        npsData.nisPlaceReferencesGroupName = nisSummary.groupName
        npsData.nisPlaceReferencesGroupCategoryName = nisSummary.groupCategoryName
        npsData.nisEmoji = this.nisMixin_transactionalUnit([campaign]).emoji
        npsData.nisSatisfactionRates = this.nisMixin_satisfactionRates(
          npsData,
          nisSummary
        )
      }
      // Set topics for that campaign.
      Vue.set(
        this.npsMixin_nps,
        campaign.id,
        npsData
      )
      // Remove lock.
      this.$data._nps_isLoading[campaign.id] = false
    }
  }
}
