
<template>
  <WStatsWidget
    :title="title"
    :subtitle="displayedSubtitle"
    class="sentiment-weigth-widget"
    height="200px"
    :cols="12"
  >
    <template #content>
      <div class="d-flex flex-column align-center">
        <v-progress-circular
          :rotate="360"
          :size="90"
          :width="5"
          :value="zoomedData.reviewRate"
          color="info"
        >
          <div class="d-flex flex-column align-center">
            <span class="c-primary fb-24">
              <span v-if="zoomedData.reviewRate < 1.0">{{ "<1%" }}</span>
              <span v-else >{{ zoomedData.reviewRate | round }}%</span>
            </span>
            <w-color-sign :value="zoomedData.reviewRateEvolution" :color="$colors.lightgrey" :decimals="1" v-if="zoomedData.reviewRateEvolution" />
          </div>
        </v-progress-circular>
        <div class="mt-1 c-grey f-12">
          {{ $tc('x_reviews', zoomedData.reviewTotal, { count: `${zoomedData.reviewTotal} ${$t('on')} ${zoomedData.totalVoter}` }) }}
        </div>
      </div>
    </template>

    <template
      #footer-left
      v-if="!hasAccessToAllDashboardPlaces && displayGroupRanking && groupIndicator && groupRankingName"
    >
      <span class="fb-12">
        <WTrunc :text="groupRankingName" :limit="20" /> :
      </span>
      <span class="mr-3">
        <span v-if="groupIndicator < 1">{{ "<1%" }}</span>
        <span v-else >{{ groupIndicator | round }}%</span>
      </span>
    </template>

    <template #footer-right v-if="!hasAccessToAllDashboardPlaces && networkIndicator">
      <span class="fb-12">
        {{ networkRankingName }} :
      </span>
      <span>
        <span v-if="networkIndicator < 1">{{ "<1%" }}</span>
        <span v-else >{{ networkIndicator | round }}%</span>
      </span>
    </template>
  </WStatsWidget>
</template>

<script>
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'

  export default {
    name: "SentimentWeigthWidget",
    mixins: [
      WidgetMixin
    ],
    props: ['subtitle', 'zoomedData', 'groupZoomedData', 'networkZoomedData'],
    computed: {
      title() {
        return this.$t('part_of_reviews')
      },
      groupIndicator() {
        return this.networkZoomedData?.groupZoomedData
      },
      networkIndicator() {
        return this.networkZoomedData?.reviewRate
      }
    }
  }
</script>
