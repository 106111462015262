<template>
  <WLateralSectionLayout class="pl-3 topic-zoom">
    <template #lateral-section>
      <TopicScoreWidget
        :topic="topic"
        :cols="12"
      />
      <TopicPlaceRankingWidget
        v-if="isMonoPlace && !isNetworkMonoPlace"
        :campaign="campaign"
        :topic="topic"
      />
      <TransactionalUnitToEarnWidget
        v-if="campaign && nisMixin_canUseNisFeature([campaign])"
        :campaign="campaign"
        :voterProportions="topic"
        :summary="nisTopicSummary"
        :topic="topic"
      />
      <TopicAchievementActionRecommendationsWidget
        v-if="
          hasAccessToNewPlatformAchievementsTab &&
          currentDashboardAchievementsEnabled &&
          !campaign.hasProductQuestion &&
          topic
        "
        :topic="topic"
      />
    </template>

    <template #main-section>
      <TopicRepartitionWidget
        v-if="campaign && nisMixin_canUseNisFeature([campaign])"
        :campaign="campaign"
        :topic="topic"
        :nisVoterProportions="topic"
        :nisSummary="nisTopicSummary"
      />
      <TopicRepartitionWidget
        v-else-if="topic && !topicsMixin_isDisplayTypeScale(topic)"
        :campaign="campaign"
        :topic="topic"
      />

      <TopicEvolutionWidget
        :campaign="campaign"
        :topic="topic"
      />

      <TopicConditionalQuestionsWidget
        :campaign="campaign"
        :topic="topic"
      />

      <TopicRankingTableWidget
        v-if="
          !isNetworkMonoPlace &&
          hasAccessToNewPlatformRankingTab &&
          !campaign.hasProductQuestion
        "
        :campaign="campaign"
        :topic="topic"
      />
    </template>
  </WLateralSectionLayout>
</template>

<script>
  import { mapGetters } from 'vuex'
  import TopicScoreWidget from './TopicScoreWidget'
  import TopicPlaceRankingWidget from './TopicPlaceRankingWidget'
  import TopicEvolutionWidget from './TopicEvolutionWidget'
  import TopicRankingTableWidget from './TopicRankingTableWidget'
  import TopicRepartitionWidget from './TopicRepartitionWidget'
  import TopicConditionalQuestionsWidget from './TopicConditionalQuestionsWidget'
  import TopicsMixin from '@statistics/shared/topics_mixin'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import NisMixin from '@statistics/shared/nis_mixin'
  import TopicAchievementActionRecommendationsWidget from './TopicAchievementActionRecommendationsWidget'
  import TransactionalUnitToEarnWidget from '../../shared/widgets/TransactionalUnitToEarnWidget'

  export default {
    name: "TopicZoom",
    mixins: [
      TopicsMixin,
      WidgetMixin,
      NisMixin,
    ],
    props: {
      campaign: { type: Object, required: true },
      topic: { type: Object },
    },
    components: {
      TopicEvolutionWidget,
      TopicScoreWidget,
      TopicRankingTableWidget,
      TopicPlaceRankingWidget,
      TopicRepartitionWidget,
      TopicConditionalQuestionsWidget,
      TopicAchievementActionRecommendationsWidget,
      TransactionalUnitToEarnWidget,
    },
    computed: {
      ...mapGetters([
        'isMonoPlace',
        'isNetworkMonoPlace',
        'isDemoUser',
        'hasAccessToNewPlatformRankingTab',
        'hasAccessToNewPlatformAchievementsTab',
        'currentDashboardAchievementsEnabled'
      ]),
      nisTopicSummary() {
        if (!this.topic) return undefined
        return this.nisMixin_topicSummaries(this.campaign)[this.topic.id]
      }
    }
  }
</script>
