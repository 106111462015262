const survey = {
  state: () => ({
    productCategories: null,
    products: null
  }),
  getters: {
    dashboardFilterProduct(state, getters) {
      return state
    },
    dashboardFilterProductCategoryIds(state, getters) {
      return (state.productCategories && state.productCategories.length > 0 ? state.productCategories.map(p => p.id) : null) 
    },
    dashboardFilterProductIds(state, getters) {
      return (state.products && state.products.length > 0 ? state.products.map(p => p.id) : null) 
    },
    dashboardFilterProductCategories(state, getters) {
      return (state.productCategories && state.productCategories.length > 0 ? state.productCategories : null) 
    },
    dashboardFilterProducts(state, getters) {
      return (state.products && state.products.length > 0 ? state.products : null) 
    }
  },
  mutations: {
    setDashboardProductFilters(state, { productCategories, products }) {
      state.productCategories = productCategories
      state.products = products
    }
  },
  actions: {
    setDashboardProductFilters({ commit }, filters) {
      if (filters.product) {
        commit('setDashboardProductFilters', filters.product)
      }
    },
  },
}

export default survey
