<template>
  <WStatsWidget
    ref="widget"
    :title="title"
    :subtitle="displayedSubtitle"
    :height="height"
    :cols="cols"
    :loading="basedRequest ? (data === undefined) : (indicator === undefined)"
    :empty="basedRequest ? (data !== undefined && !data.indicator) : (indicator === null)"
    :linkTo="linkTo"
  >
    <template #content>
      <WStatsScore
        :score="basedRequest ? data.indicator : indicator"
        :evolution="basedRequest ? data.evolution : evolution"
        :displayEvolution="basedRequest ? data.displayEvolution : displayEvolution"
        :isFiveScore="isFiveScore"
        :nbReview="basedRequest ? data.nbReview : nbReview"
        :decimals="decimals"
        :displayStars="displayStars"
        :displayTooltipWithText="displayTooltipWithText"
      />
    </template>

    <template #footer-left v-if="basedRequest && !hasAccessToAllDashboardPlaces">
      <template v-if="basedRequest && displayRank && isMonoPlace && rank">
        <span class="mx-1">{{ rank }}{{ $options.filters.ordinal(rank) }}</span>
      </template>
      <template v-if="displayGroupRanking && groupIndicator && groupRankingName">
        <span class="fb-12">
          <WTrunc :text="groupRankingName" :limit="20" /> :
        </span>
        <span class="mr-3">{{ groupIndicator.toFixed(decimals) }}</span>
      </template>
    </template>

    <template
      #footer-right
      v-if="
        basedRequest &&
        !hasAccessToAllDashboardPlaces &&
        networkIndicator
      "
    >
      <span class="fb-12">
        {{ networkRankingName }} :
      </span>
      <span>{{ networkIndicator.toFixed(decimals) }}</span>
    </template>
  </WStatsWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import VoterScoreMixin from '@statistics/shared/voter_score_mixin'
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'

export default {
  name: 'ScoreWidget',
  mixins: [
    VoterScoreMixin,
    WidgetMixin
  ],
  computed: {
    ...mapGetters([
      'hasAccessToAllDashboardPlaces'
    ])
  },
  props: {
    avgScoreScale: {},
    basedRequest: { type: Object },
    cols: { type: Number, default: 3 },
    decimals: { type: Number, default: 1 },
    displayEvolution: { type: Boolean },
    displayRank: { type: Boolean, default: true },
    displayStars: { type: Boolean, default: true },
    displayTooltipWithText: { type: String, default: '' },
    evolution: { type: Number },
    height: { type: String, default: '200px' },
    indicator: { type: Number },
    indicatorAlias: { type: String },
    indicatorColumn: { type: Object },
    linkTo: {},
    nbReview: { type: Number },
    subtitle: { type: String },
    title: { type: String, required: true },
  }
}
</script>

<style lang="stylus" scoped>
  .widget-link
    display: contents

  .strong
    font-weight: bold;
</style>
