/* eslint-disable */
import _omit from 'lodash/omit'
import { appBaseUrl } from '@shared/helpers/env-helpers'

export function addQueryParamsWithoutReload(route, newParams) {
  const params = { ...route.query, ...newParams }
  const path = route.path +
  '?' +
  Object.keys(params)
    .map(key => {
      return (
        encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      )
    })
    .join('&')

  history.pushState(
    { changed: 1 },
    null,
    path
  )
}

export function removeQueryParamWithoutReload(route, param) {
  const params = _omit(route.query, param)
  const path = route.path +
    '?' +
    Object.keys(params)
      .map(key => {
        return (
          encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        )
      })
      .join('&')

  history.pushState(
    { changed: 1 },
    null,
    path
  )
}

export function removeAlkAndHrefFromUrl() {
  let url = new URL(window.location.href)
  let params = new URLSearchParams(url.search)

  if (params.has('alk') || params.has('href')) {
    params.delete('alk')
    params.delete('href')

    const strParams = params.toString()
    window.history.replaceState({}, "", `${window.location.pathname}${strParams.length > 0 ? '?' : ''}${strParams}`)
  }
}

export function localHost(url) {
  return url.replace(appBaseUrl, "http://app.wizville.localhost:3000")
}