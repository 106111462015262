<template>
  <WStatsWidget
    class="question-condition-widget"
    :cols="cols || 6"
    maxHeight="360px"
    :title="title"
    :subtitle="displayedSubtitle"
    ref="widget"
    contentWidth="100%"
    contentPadding="0.75em"
    :justifyCenter="false"
  >
    <template #options>
      <w-drop-down-menu
        data-html2canvas-ignore
        v-if="!loading"
        :items="exportOptions"
        icon="mdi-download">
      </w-drop-down-menu>
    </template>

    <template #content>
      <QcmQuestionHistogram
        :question="question"
        :barColor="color"
        class="pb-1"
        @loading="onLoading"
        ref="QcmQuestionHistogramRef"
      />
      <QcmQuestionOtherTable
        v-if="question.choiceOther"
        :question="question"
        class="mt-2 pb-1"
        @loading="onLoading"
        ref="QcmQuestionOtherTableRef"
      />
    </template>

    <template #footer-left>
      <QuestionConditionAnswerRate
        :questionCondition="questionCondition"
        :color="color"
        ref="QuestionConditionAnswerRateRef"
      />
    </template>
  </WStatsWidget>
</template>

<script>
  import QcmQuestionHistogram from "@statistics/shared/QcmQuestion/QcmQuestionHistogram"
  import QcmQuestionOtherTable from "@statistics/shared/QcmQuestion/QcmQuestionOtherTable"
  import QuestionConditionAnswerRate from "@statistics/shared/QuestionCondition/QuestionConditionAnswerRate"
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import Pdf from '@shared/helpers/exportToPdf/pdf'

  export default {
    name: "QuestionConditionWidget",
    props: {
      questionCondition: {},
      title: {},
      cols: { type: Number }
    },
    components: {
      QcmQuestionHistogram,
      QcmQuestionOtherTable,
      QuestionConditionAnswerRate
    },
    mixins: [
      WidgetMixin
    ],
    data() {
      return {
        loading: true
      }
    },
    methods: {
      onLoading(loading) {
        this.loading = loading
      },
      exportFileName() {
        const pageName = this.displayedSubtitle
        const date = this.$date().locale(this.$i18n.locale).format('ddd DD MMM HH_mm_ss')

        return [this.title, pageName, date].join(' - ')
      },
      async exportToPdf() {
        this.$store.dispatch("notifyInfo")
        const fileName = `${this.exportFileName()}.pdf`
        const pdf = new Pdf({
          defaultBodyMargin: { left: 40, top: 40 },
        })
        await pdf.addPage()
        await pdf.addRow({ height: 20 }, async(row) => {
          await row.addCol({ }, async(col) => {
            col.addText(this.title, {fontSize: 14, font: { style: 'bold' } })
          })
        })
        await pdf.addRow({ height: 20 }, async(row) => {
          await row.addCol({ }, async(col) => {
            col.addText(this.displayedSubtitle, {color: '#666', fontSize: 10 })
          })
        })
        await this.$refs.QuestionConditionAnswerRateRef.exportToPdf(pdf)
        await pdf.addRow({ height: 20 })
        await this.$refs.QcmQuestionHistogramRef.exportToPdf(pdf)
        await pdf.addRow({ height: 15 })
        await this.$refs.QcmQuestionOtherTableRef.exportToPdf(pdf)
        pdf.save(fileName)
      }
    },
    computed: {
      exportOptions() {
        return [
          { title: 'PDF', onClick: this.exportToPdf },
        ]
      },
      question() {
        return this.questionCondition?.qcmQuestion
      },
      conditionParams() {
        return this.questionCondition?.condition?.params
      },
      color() {
        if ([">", ">="].includes(this.conditionParams?.condition)) {
          return this.$colors.success
        } else {
          return this.$colors.error
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
</style>
