<template>
  <v-main class="home-layout">
    <router-link
      :to="{ name: 'Root' }"
      class="home-layout-logo"
    >
      <img src="https://storage.googleapis.com/wizville-assets-eu/static/branding/wizville_logo_couleur_fond_fonce_height_100.png" />
    </router-link>

    <router-view />
  </v-main>
</template>

<script>
  export default {
    name: 'LoginLayout'
  }
</script>

<!-- eslint-disable max-len -->
<style lang="stylus" scoped>
  .home-layout
    min-width: 100%
    min-height: 100vh
    background-image: url("https://storage.googleapis.com/wizville-assets-eu/static/images/theme_leo/background-homepage-wizville.png")
    background-position: bottom
    background-repeat: no-repeat
    background-size: cover

    .home-layout-logo
      position: absolute
      top: 25px
      left: 25px

      img
        height: 3em
</style>
<!-- eslint-enable max-len -->
