<template>
  <w-menu-select
    v-model="filters.assignation"
    :items="assignationFiltersList"
    :allow-empty="false"
    :loading="assignationFiltersLoading"
    ref="menuSelect"
  >
    <template v-slot:selected-text="{ item }">
      <span>
        {{ $t('assignation_for') }} <strong>{{ item.text }}</strong>
      </span>
    </template>
    <template v-slot:option-text="{ item }">
      <span>
        {{ item.text }}
        <template v-if="item.count !== undefined && item.count !== null">
          ({{ item.count }})
        </template>
      </span>
    </template>
  </w-menu-select>
</template>

<script>
import { mapGetters } from "vuex"

import VoterRequestsMixin from "../../../shared/voter_requests_mixin"

export default {
  mixins: [
    VoterRequestsMixin
  ],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    subStatus: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {
      filters: this.value,
      assignationFiltersList: [
        { text: this.$t('assignation_filter_me'), value: 'me' },
        { text: this.$t('assignation_filter_all'), value: 'all' }
      ],
      assignationFiltersLoading: false
    }
  },
  methods: {
    processingStatusFilter() {
      return {
        new_feedbacks: 'insatisfaction_not_processed',
        new_messages: 'conversation_need_answer'
      }[this.subStatus]
    },
    updateFilters() {
      this.$emit("input", this.filters)
    },
    currentUserItemId() {
      return {
        all_feedbacks: this.currentUser.userGroupId,
        new_messages: this.currentUser.id
      }[this.subStatus]
    },
    async buildOptionsFor(subStatus) {
      if (this.subStatus === 'new_feedbacks') {
        const countGroupedBy =
          await this.getCountGroupedBy('treat', subStatus)

        const data = await this.getGroupedByData(
          Object.keys(countGroupedBy).filter(Boolean),
          subStatus
        )
        const otherGroupsOptions = data.map((group) => {
          return {
            text: group.name || group.mail,
            value: group.id,
            count: countGroupedBy[group.id].countDistinctId,
          }
        })
        const unassignedCount = (countGroupedBy[""] || { countDistinctId: 0 })
        .countDistinctId

        const myGroupCount = (
          countGroupedBy[this.currentUserItemId()] || {
            countDistinctId: 0,
          }
        ).countDistinctId

        const myCount = unassignedCount + myGroupCount

        const totalCount = Object.values(countGroupedBy).length ?
          Object.values(countGroupedBy).map((v) => v.countDistinctId)
            .reduce((a, b) => a + b) : 0

        return [
          { text: this.$t('assignation_filter_me'), value: "me", count: myCount },
          { text: this.$t('assignation_filter_all'), value: 'all', count: totalCount },
          ...otherGroupsOptions
        ]
      } else {
        const myCount = await this.getCount(
          'treat',
          subStatus,
          { assignation: { value: 'me' } }
        )
        const totalCount = await this.getCount('treat', subStatus)

        return [
          { text: this.$t('assignation_filter_me'), value: "me", count: myCount },
          { text: this.$t('assignation_filter_all'), value: 'all', count: totalCount }
        ]
      }
    },
    async refreshList() {
      if (this.dashboardFilterReady) {
        this.assignationFiltersLoading = true

        const options = await this.buildOptionsFor(this.subStatus)

        this.assignationFiltersList = options
        this.assignationFiltersLoading = false
      }
    },
  },
  computed: {
    ...mapGetters([
      'dashboardFilterRequest',
      'dashboardFilterReady',
      'currentUser',
      'voterUpdateEvent',
      'currentDashboardTreatVotersPeriod'
    ]),
  },
  mounted() {
    this.refreshList()
  },
  watch: {
    filters: {
      handler: "updateFilters",
      deep: true,
    },
    subStatus() {
      this.refreshList()
    },
    voterUpdateEvent: function(voterUpdateEvent) {
      this.refreshList()
    },
    currentDashboardTreatVotersPeriod() {
      this.refreshList()
    },
  }
}
</script>

<style lang="stylus" scoped>
.v-input.no-border
  .v-text-field > .v-input__control > .v-input__slot
    &:before
      border-style: none
    &:after
      border-style: none
</style>
