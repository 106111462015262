<template>
  <WStatsWidget
    :title="$t('topicRoiSatisfactionWidgetTitle')"
    :subtitle="defaultSubtitle"
    height="200px"
    :cols="12"
    :help="help"
    :loading="topic === undefined || nisTopicSummary === undefined"
  >
    <template #content>
      <div class="d-flex align-center justify-center">
        <template v-if="roiSatisfaction >= 0">
          <span class="fb-22 c-primary text-no-wrap score">
              {{ `+${roiSatisfaction} %` }}
          </span>

          <div
            class="ml-2 evolution"
            :style="{maxWidth: 400, minWidth: 400}"
          >
            <w-color-sign
              :value="roiSatisfactionEvolution"
              :decimals="1"

            />
          </div>
        </template>
        <template v-else>
          <v-tooltip top transition="none">
            <span>
              {{ $t(`topic_roi_satisfaction_na_help_${nisMixin_transactionalUnit([campaign]).nisColumnName}`) }}
            </span>

            <template #activator="{ on }">
              <span class="c-primary f-25">
                {{ $t('topic_roi_satisfaction_na') }}
              </span>
              <v-icon v-on="on" class="na f-14">
                mdi-help-circle-outline
              </v-icon>
            </template>
          </v-tooltip>
        </template>
      </div>
    </template>
  </WStatsWidget>
</template>

<script>
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
import NisMixin from '@statistics/shared/nis_mixin'

export default {
  name: "TopicRoiSatisfactionWidget",
  mixins: [
    WidgetMixin,
    NisMixin,
  ],
  props: {
    campaign: { type: Object, required: true },
    topic: { type: Object },
    nisTopicSummary: { type: Object },
  },
  data() {
    return {
      decimals: 0
    }
  },
  computed: {
    help() {
      return this.nisMixin_roiSatisfactionHelpMessage([this.campaign])
    },
    roiSatisfaction() {
      return this.nisMixin_roiSatisfaction(this.topic, this.nisTopicSummary)
    },
    roiSatisfactionEvolution() {
      if (this.topic.previousPeriodData) {
        return this.nisMixin_roiSatisfaction(this.topic, this.nisTopicSummary) - this.nisMixin_roiSatisfaction(this.topic.previousPeriodData, this.nisTopicSummary)
      }

      return 0
    }
  }
}
</script>

<style lang="stylus" scoped>
  .na
    position: relative
    top: 2px
    left: 4px
</style>
