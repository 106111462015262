<template>
  <v-rating
    v-if="voterScale === 5 && voterScoreExist"
    class="w-rating"
    background-color="grey lighten-2"
    readonly
    :value="Math.round(voterScore * 2) / 2"
    half-increments
    length="5"
    size="15"
    :color="$colors.gold"
  ></v-rating>
  <span
    v-else
    class="f-13 lh-9"
  >
    <WScore class="fs-14"
      v-if="voterScoreExist"
      :voterScore="voterScore"
      :scale="voterScale"
      :insatisfactionStatus="insatisfactionStatus"
    />
    <span v-else class="fs-14">
      {{ $t('nc') }}
    </span>
  </span>
</template>

<script>
  import VoterMixin from '../../voter_mixin'

  export default {
    name: "AvgScore",
    mixins: [
      VoterMixin
    ],
    props: {
      insatisfactionStatus: {
        type: String,
        required: false
      }
    }
  }
</script>

<style lang='stylus' scoped>
</style>
