<template>
  <div
    class="user-message"
  >
    <w-timeline-element
      right
    >
      <template v-slot:avatar>
        <CompanyAvatar/>
      </template>

      <template v-slot:title>
        <div class="d-flex">
          <span
            class="text-truncate fb-14 c-primary"
          >
            {{ currentVoter.replierLabel }}
          </span>
          <v-tooltip top>
            <template #activator="{on}">
              <span class="fb-14 c-primary" v-on="on">{{ createdAt }}</span>
            </template>
            {{ createdAtTooltip }}
          </v-tooltip>
        </div>
      </template>

      <template v-slot:actions>
        <w-drop-down-menu
          v-if="actions.length > 0"
          :items="actions"
          icon="mdi-dots-horizontal"
          :showArrow="false"
        />
      </template>

      <template v-slot:content>
        <div
          class="d-flex pa-4 rounded-lg bg-sea-green-100"
          :class="{ 'editing': isEditing }"
        >
          <div
            class="d-flex flex-column ga-3"
            :style="{ width: '100%' }"
          >
            <div class="d-flex align-center ga-1">
              <v-icon class="f-16 c-primary">mdi-chat-outline</v-icon>
              <span class="c-primary fb-14">
                {{ messageTitle }}
              </span>
            </div>
            <pre
              class="c-darkergrey f-14 message-content"
            >
             {{messageContent}}
            </pre>

            <v-divider></v-divider>

            <div class="d-flex align-center c-grey">
              <div class="d-flex align-center">
                <div class="d-flex" v-if="isTrustvilleActivated">
                  <v-icon small class="mr-1"> {{ getTrustvilleDisplayIcon(messageTrustvilleDisplay) }} </v-icon>
                  <v-select
                    append-icon="mdi-chevron-down"
                    @change="setUserMessageTrustvilleDisplay"
                    v-if="currentUser.isAdmin && voterTrustvilleReportable && isFirstUserMessage"
                    class="f-12 custom"
                    v-model="messageTrustvilleDisplay"
                    :items="trustvilleDisplaySelectItems"
                    hide-details
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <v-icon small class="mr-1"> {{ getTrustvilleDisplayIcon(item.value) }} </v-icon>
                      <strong v-if="item.value == messageTrustvilleDisplay"> {{ item.text }} </strong>
                      <span v-else> {{ item.text }} </span>
                    </template>
                  </v-select>
                  <div v-else class="mr-2">
                    {{ trustvilleDisplay }}
                  </div>
                </div>
              </div>
              <div
                class="d-flex align-center"
                v-if="!message.writtenByAdmin"
              >
                <v-icon small>mdi-account</v-icon>
                <span
                  class="fb-12 mr-2"
                  v-if="message.voterThirdPartyTransfer"
                >
                  {{ $t('voter_third_party_transfer_third_party') }}
                </span>
                <span class="f-12">
                  {{ messageFrom }}
                </span>
              </div>
              <div class="d-flex align-center ml-auto">
                <span v-if="message.isNeedToValidate">
                  <v-tooltip transition="none" top>
                    <template #activator="{ on }">
                      <v-icon v-on="on" small class="sent">mdi-timer-sand</v-icon>
                    </template>
                    <span class="f-12">
                      {{ $t('submitted_for_validation') }}
                    </span>
                  </v-tooltip>
                </span>
              </div>

              <div class="ml-auto" v-if="!message.isNeedToValidate">
                <v-icon v-if="isEmail && isEmailOpened" class="f-12 read c-primary">mdi-check-all</v-icon>
                <v-icon v-else small class="sent">mdi-check</v-icon>
                <span class="f-12">
                  {{ messageStatus }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div
          v-if="(canAnswerCustomersDirectly && message.isNeedToValidate) || (canAnswerCustomers && !needValidationOnCampaign && message.isNeedToValidate)"
          class="d-flex ga-2 justify-end exclude-click-outside"
        >
          <v-btn
            color="grey darken-2"
            @click="edit"
            outlined
          >
            <v-icon class="mr-1">mdi-pencil</v-icon>
            {{ $t('edit') }}
          </v-btn>
          <BtnSendMessageRedirectModal
            v-if="isPageJaune || isTripAdvisor"
            :smartChannel="message.source"
            :isValid="true"
            :content="message.content"
            :voter="voter"
            :btnText="$t('validate_and_send')"
          />
          <v-btn
            v-else
            color="secondary"
            :loading="sendingMessage"
            @click="sendMessage"
          >
            <v-icon class="mr-1">{{ channelIcon }}</v-icon>
            {{ $t('validate_and_send') }}
          </v-btn>
        </div>
        <div
          v-if="needValidationOnCampaign && message.isNeedToValidate"
          class="d-flex ga-2 justify-end exclude-click-outside"
        >
          <v-btn
            color="grey darken-2"
            @click="destroy"
            outlined
          >
            <v-icon class="mr-1">mdi-trash-can-outline</v-icon>
              {{ $t('delete') }}
          </v-btn>
          <v-btn
            color="secondary"
            @click="edit"
          >
            <v-icon class="mr-1">mdi-pencil</v-icon>
            {{ $t('edit') }}
          </v-btn>
        </div>
      </template>
    </w-timeline-element>
  </div>
</template>

<script>
  import humanizeString from 'humanize-string';
  import _upperFirst from 'lodash/upperFirst'
  import { mapGetters } from 'vuex'
  import VoterMixin from "../../../voter_mixin";
  import CurrentUserCapacityMixin from "../../../current_user_capacity_mixin";
  import BtnSendMessageRedirectModal from '../../shared/BtnSendMessageRedirectModal.vue'
  import CompanyAvatar from '../../CompanyAvatar'

  export default {
    name: "UserMessage",
    mixins: [
      VoterMixin,
      CurrentUserCapacityMixin
    ],
    components: {
      BtnSendMessageRedirectModal,
      CompanyAvatar
    },
    props: {
      message: { required: true },
      voter: { required: true },
      isFirstUserMessage: {
        type: Boolean,
        required: false
      }
    },
    data () {
      return {
        trustvilleDisplaySelectItems: [
          { value: true, text: this.$t('message_trustville_public') },
          { value: false, text: this.$t('message_trustville_private') }
        ],
        messageTrustvilleDisplay: this.message.trustvilleDisplay,
        sendingMessage: false
      }
    },
    computed: {
      ...mapGetters([
        'currentUser',
        'currentVoter',
        'currentVoterEditingMessage',
        'currentVoterShowActions',
        'currentLexicon',
        'voterTrustvilleReportable',
        'mainCampaign',
        'currentVoterActionsTab',
      ]),
      isEditing() {
        return this.currentVoterShowActions &&
          this.currentVoterActionsTab === 'send_message' &&
          this.currentVoterEditingMessage?.id === this.message.id
      },
      createdAt() {
        return this.$t('voter_timeline_element_created_at', { date: this.$helpers.dates.short(this.message.createdAt, false) })
      },
      createdAtTooltip() {
        return this.$helpers.dates.short(this.message.createdAt, true)
      },
      isTrustvilleActivated() {
        return (this.mainCampaign.trustvilleProductPreferenceEnabled || this.isTrustvilleEnabled)
      },
      channelIcon() {
        return {
          email: 'mdi-email-outline',
          sms: 'mdi-message-processing',
          google: 'mdi-google',
          facebook: 'mdi-facebook',
        }[this.message.source]
      },
      isGoogle() {
        return this.message.source === "google"
      },
      isFacebook() {
        return this.message.source === "facebook"
      },
      isTripAdvisor() {
        return this.message.source === "trip_advisor"
      },
      isPageJaune() {
        return this.message.source === "pages_jaunes"
      },
      actions() {
        let actions = []

        if (this.canAnswerCustomersDirectly && (this.isGoogle || this.isFacebook)) {
          actions.push(
            {
              title: this.$t('edit'),
              onClick: this.edit,
              class: 'edit',
              icon: 'mdi-pencil'
            },
          )
        }
        if (this.canAnswerCustomersDirectly && this.needValidationOnCampaign) {
          actions.push(
            {
              title: this.$t('delete'),
              onClick: this.destroy,
              class: 'delete',
              icon: 'mdi-trash-can-outline'
            }
          )
        }
        return actions
      },
      trustvilleDisplay() {
        return this.message.trustvilleDisplay ?
          this.$t('message_trustville_public') :
          this.$t('message_trustville_private');
      },
      messageTitle() {
        return this.message.isNeedToValidate ?
          this.$t('company_message_for_validation') : `${_upperFirst(this.$t('message'))} ${this.currentLexicon.translate('company', 'company').toLowerCase()}`
      },
      messageFrom() {
        const email = this.message.isAutomated ?
          this.$t('message_sent_automatically') :
          this.message.voterThirdPartyTransfer?.email || this.message.userEmail

        return this.$t(
          'company_message_from',
          {
            email: email,
            source: humanizeString(this.message.source).toLowerCase()
          }
        );
      },
      messageContent() {
        const footerSeparator = ' - ';
        const splittedContent = this.message.content.split(footerSeparator);
        const splittedContentLastElement = splittedContent[splittedContent.length - 1];

        return this.message.source === 'sms' && splittedContent.length > 1 && splittedContentLastElement.includes('http') ?
          this.message.content.slice(0, - splittedContent[splittedContent.length - 1].length - footerSeparator.length) :
          this.message.content;
      },
      messageStatus() {
        return (this.isEmail && this.isEmailOpened) ? this.$t('email_message_read') : this.$t('message_sent')
      },
      isEmail() {
        return this.message.source === 'email';
      }
    },
    methods: {
      getTrustvilleDisplayIcon(item) {
        return (item === true ) ? 'mdi-earth' : 'mdi-lock'
      },
      async sendMessage() {
        this.sendingMessage = true

        try {
          const response = await this.$api.wizville.messages.create(
            this.voter.uniqueId,
            {
              content: this.message.content,
              channel: this.message.source,
              is_need_to_validate: false,
              trustville_display: this.messageTrustvilleDisplay
            }
          )

          if (response.status === 'ok') {
            this.$store.dispatch('notifySuccess', {
              message: response.message,
              timeout: 5000,
            })
            this.$store.dispatch('events', {
              voterUpdate: { voter: { uniqueId: this.voter.uniqueId } },
            })

            if (response.insatisfactionProcessed) {
              this.$store.dispatch(
                'voterInsatisfactionStatus',
                response.insatisfactionProcessed
              )
            }

            this.$store.dispatch('setEditingMessage', null)
          } else {
            this.$store.dispatch('notifyError', { message: response.message })
          }
        } catch (error) {
          this.$store.dispatch('notifyError', { message: error.message })
        }

        this.sendingMessage = false

      },
      edit() {
        this.$store.dispatch('setEditingMessage',
          {
            id: this.message.id,
            content: this.message.content
          }
        )
        this.$store.dispatch('setActionsTab', 'send_message')
        this.$store.dispatch('setShowVoterActions', true)
      },
      async destroy() {
        const res = await this.$confirm(
          this.$t('message_deletion_infos'),
          {
            color: 'primary',
            buttonTrueText: this.$t('confirm'),
            buttonFalseText: this.$t('cancel'),
            title: this.$t('message_deletion_title')
          }
        )
        if (res) {
          try {
            const response = await this.$api.wizville.messages.destroy(
              this.voter.uniqueId,
              this.message.id
            )

            if (response.status === "ok") {
              this.$store.dispatch('notifySuccess', { message: this.$t('flash_message_successfully_deleted_message'), timeout: 5000 })
              this.$store.dispatch('events', { voterUpdate: { voter: { uniqueId: this.voter.uniqueId } } })

              this.$store.dispatch('setShowVoterActions', false)
              this.$store.dispatch('deleteMessage', this.message.id)
              this.$store.dispatch('setEditingMessage', null)
              this.$store.dispatch('setActionsTab', 'cleared')
            } else {
              this.$store.dispatch('notifyError', { message: response.message })
            }
          } catch (error) {
            this.$store.dispatch('notifyError', { message: error.message })
          }
        }
      },
      async setUserMessageTrustvilleDisplay() {
        try {
          const response = await this.$api.wizville.messages.updateTrustvilleDisplay(
            this.voter.uniqueId,
            this.message.id,
            this.messageTrustvilleDisplay
          )

          if (response.status === "ok") {
            this.$store.dispatch(
              'notifySuccess',
              {
                message: this.$t('flash_message_succesfully_changed_answer_trustville_visibility'),
                timeout: 5000
              }
            )
          }
        } catch (error) {
          this.$store.dispatch('notifyError', { message: error.message })
        }
      }
    },
    asyncComputed: {
      isEmailOpened: {
        async get() {
          if (this.isEmail) {
            let request = this.$basedRequest()
            .select({
              status_emails: ['opened'],
            })
            .where({
              message_id: [this.message.id],
            })

            const response = (await this.$resolve(request)).first();

            return response?.opened > 0;
          }
          return false;
        },
        default() {
          return false;
        },
      },
      isTrustvilleEnabled: {
        async get() {
          let request = this.$basedRequest()
          .select({
            place_campaigns: ['is_trustville_enabled'],
          })
          .where({
            id: [this.voter.placeCampaignId]
          })

            const response = (await this.$resolve(request)).first();

          return response?.isTrustvilleEnabled;
        },
        default() {
          return false;
        },
      },
    },
  }
</script>

<style lang='stylus'>
  .user-message
    .v-card__text
      padding-bottom: 8px !important
    .editing
      border: dashed 2px #0000001f
    .v-badge .v-badge__wrapper
      margin-left: 0px

    .message-content
      white-space: pre-line

    .v-select.custom
      margin-top: 0px
      .v-input__icon--append
        min-width: 16px
        .v-icon
          font-size: 16px
          padding-top: 1px
      .v-select__selections
        color: #757575
        input
          width: 0px
        .v-select__selection
          margin: 0px
        .v-select__selection--comma
          overflow: unset
          text-overflow: unset
    .custom.v-text-field
      .v-input__append-inner
        padding-left: 0px
        margin-top: 1px
      .v-input__control
        .v-input__slot:before
          border-style: none
        .v-input__slot:after
          border-style: none
</style>
