<template>
  <div class="table">
    <v-data-table
      v-show="!loading"
      :headers="headers"
      :items="items || []"
      @click:row="rowClicked"
      :server-items-length="totalCount"
      :footer-props="{
        'items-per-page-options': [50, 100, 200]
      }"
      :options.sync="options"
      :hide-default-footer="totalCount <= 50"
      mobileBreakpoint="0"
      disable-sort
    >
      <template #item.name="{ item }">
        <div class="d-flex align-center ga-4">
          <w-zoom-button
            v-if="onRowClick && $vuetify.breakpoint.smAndUp"
            @click="rowClicked(item)"
          />
          <WRank
            :rank="item.rank"
            :nrTooltipText="$t('tooltip_need_more_feedbacks_to_be_ranked', { min_number_of_feedback: minimalNumberOfFeedbackForRanking })"
          />
          <div class="d-flex flex-column">
            <span class="f-13">{{ item.name }}</span>
            <span
              v-if="item.code"
              class="f-10 c-lightgrey"
            >
              {{ item.code }}
            </span>
            <div
              v-if="mode === 'product_categories'"
              class="d-flex align-center ga-2"
            >
              <div
                v-if="item.nbProducts > 0"
                class="d-flex align-center ga-1 f-10 c-lightgrey">
                <v-icon class="f-10">mdi-cart-outline</v-icon>
                <span>{{ item.nbProducts }}</span>
              </div>
              <div
                v-if="item.nbChildrenCategories > 0"
                class="d-flex align-center ga-1 f-10 c-lightgrey">
                <v-icon class="f-10">mdi-shape-outline</v-icon>
                <span>{{ item.nbChildrenCategories }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #item.score="{ item }">
        <span class="f-13">
          <WRatingWithStars :value="item.score" :decimals="2">
            <template #ratingDetails>
              <div class="d-flex flex-row">
                <span class="ml-1 flex-nowrap flex-shrink-0 sub-text f-12 c-lightgrey">
                  {{ translateNbReview(item.nbReview) }}
                </span>
              </div>
            </template>
          </WRatingWithStars>
        </span>
      </template>

      <template #item.recommendation="{ item }">
        <span class="f-13">
          <WRatingCircular :value="item.recommendation" />
        </span>
      </template>

      <template
        v-for="(header, index) in dynamicHeaders" v-slot:[header.headerSlotName]="{ header }">
        <HeaderWithTooltip
          v-if="header.displayType === 'scale'"
          :title="header.text"
        >
          <template #tooltip>
            <div class="d-flex flex-column">
              <span
                v-for="(label, index) in header.rateLabels"
                :key="index"
              >
                {{ label }}
              </span>
            </div>
          </template>
        </HeaderWithTooltip>
        <span v-else>{{ header.text }}</span>
      </template>

      <template v-for="header in dynamicHeaders" v-slot:[header.itemSlotName]="{ value }">
        <span class="f-13">
          <WRatingWithStars :value="value.score" :decimals="2" v-if="header.displayType === 'stars' && value">
            <template #ratingDetails>
              <div class="d-flex flex-row">
                <span class="ml-1 flex-nowrap flex-shrink-0 sub-text f-12 c-lightgrey">
                  {{ translateNbReview(value.nbReview) }}
                </span>
              </div>
            </template>
          </WRatingWithStars>
          <WRatingText
            v-else-if="value"
            :value="value.score"
          >
            <template #ratingDetails>
              <div class="d-flex flex-row">
                <span class="ml-1 flex-nowrap flex-shrink-0 sub-text f-12 c-lightgrey">
                  {{ translateNbReview(value.nbReview) }}
                </span>
              </div>
            </template>
          </WRatingText>
        </span>
      </template>
    </v-data-table>
    <v-skeleton-loader v-show="loading" type="paragraph" class="ma-3" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import HeaderWithTooltip from '../../shared/Table/HeaderWithTooltip'

  export default {
    name: "Table",
    props: {
      categoryId: { type: Number, required: false },
      topics: { type: Array, required: false, default: () => [] },
      campaign: { required: false },
      loading: { required: false, default: false },
      mode: { required: true },
      totalCount: { required: true },
      items: { required: true },
      value: { required: true },
      onRowClick: { required: false }
    },
    components: {
      HeaderWithTooltip
    },
    methods: {
      rowClicked(item) {
        if (this.onRowClick) {
          this.onRowClick(item)
        }
      },
      translateNbReview(nbReview) {
        return this.$t('nbReview_without_google_logo', {
          nbReview: nbReview?.toLocaleString(),
        })
      }
    },
    computed: {
      ...mapGetters([
        'minimalNumberOfFeedbackForRanking'
      ]),
      options: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit("input", val)
        }
      },
      dynamicHeaders() {
        if (this.headers) {
          return this.headers.filter(header => header.dynamic).map(header => {
            return {
              ...header,
              ...{
                headerSlotName: `header.${header.value}`,
                itemSlotName: `item.${header.value}`
              }
            }
          })
        }
      },
      ready() {
        return (
          this.items &&
          !this.loading
        )
      },
      nbTopics() {
        if (this.$vuetify.breakpoint.xsOnly) {
          return 0
        } else if (this.$vuetify.breakpoint.smAndDown) {
          return 1
        } else if (this.$vuetify.breakpoint.mdAndDown) {
          return 3
        } else if (this.$vuetify.breakpoint.lgAndDown) {
          return 5
        } else {
          Infinity
        }
      },
      headers() {
        if (this.topics) {
          let headers = [
            {
              text: this.mode === 'product_categories' ?
                this.$t("categories_products_name") :
                this.$t("categories_products_products"),
              value: 'name'
            },
            {
              text: this.$t("categories_products_globale_note"),
              value: 'score'
            },
            {
              text: this.$t("categories_products_percent_recommendation"),
              value: 'recommendation',
              align: 'center',
              displayCondition: this.mode === 'product_categories' || this.$vuetify.breakpoint.smAndUp
            }
          ]

          this.topics.slice(0, this.nbTopics).forEach(topic => {
            const rateLabels = JSON.parse(topic.rateLabels)
            const formatedRateLabels = Object.keys(rateLabels).map(key => {
              const label = rateLabels[key]
              return `${key}/5 = ${label}`
            })

            headers.push({
              text: topic.topicName,
              value: `topic-${topic.topicId}`,
              sortable: true,
              dynamic: true,
              displayType: topic.displayType,
              rateLabels: formatedRateLabels
            })
          })

          return headers.filter((header) => header.displayCondition === undefined || header.displayCondition)
        }
      }
    }
  }
</script>

<style lang="stylus">
</style>
