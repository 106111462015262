<template>
  <span>
  {{ dashboardFilterDates.text }}
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <a :href="urlToBenchmark" target='_blank'>
        <v-icon v-bind="attrs" v-on="on" class="f-12 c-lightgrey" small>mdi-information-outline</v-icon>
      </a>
    </template>
    <span>{{ `${$t('reviewsNextMonthDisplayTooltip')} (${getScrapedDate()})` }}</span>
  </v-tooltip>
  </span>
</template>

<script>

import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: "HeaderSubtitle",
  computed: {
    ...mapGetters([
      'dashboardFilterDates',
    ])
  },
  data() {
    return {
      urlToBenchmark: 'https://wizville.zendesk.com/hc/fr/articles/7909896871196-Benchmark-Avis-Google-R%C3%A8gles-de-collecte-des-avis'
    }
  },
  methods: {
    getScrapedDate() {
      return dayjs().add(1, "month").startOf('month').add(7, "day").format('DD-MM-YYYY')
    }
  }
}
</script>
