import Vue from 'vue'
import { mapGetters } from 'vuex'
import { sqlDate } from '@vue/plugins/helpers/dates'
import _cloneDeep from 'lodash/cloneDeep'
import NisMixin from '@statistics/shared/nis_mixin'

export default {
  mixins: [
    NisMixin,
  ],
  computed: {
    ...mapGetters([
      'dashboardFilterReady',
      'placeIdsScope',
      'dashboardFilterRequest',
      'datesScope',
      'dashboardFilterBase64',
      'requestedTransactionalUnit',
      'netImpactScoreSummariesLoaded'
    ])
  },
  data() {
    return {
      _baseData: {},
      topicsMixin_topics: {},
      _baseData_isLoading: {},
      _topics_areLoading: {}
    }
  },
  watch: {
    dashboardFilterBase64() {
      this.$data._baseData = {}
      this.topicsMixin_topics = {}
    },
    requestedTransactionalUnit() {
      this.topicsMixin_topics = {}
    },
    netImpactScoreSummariesLoaded(loaded) {
      if (loaded) {
        this.topicsMixin_topics = {}
      }
    }
  },
  methods: {
    gradeCondition(campaign) {
      return campaign.avgScoreScale.max === 5 ?
        [
          { ['nb_review_by_avg_topic']: { min: 0, max: 3, condition: '[..[', as: 'nbDetractors' }},
          { ['nb_review_by_avg_topic']: { min: 3, max: 4, condition: '[..[', as: 'nbNeutrals' }},
          { ['nb_review_by_avg_topic']: { min: 4, max: 5, condition: '[..]', as: 'nbPromoters' }}
        ] :
        [
          { ['nb_review_by_avg_topic']: { min: 0, max: 7, condition: '[..[', as: 'nbDetractors' }},
          { ['nb_review_by_avg_topic']: { min: 7, max: 9, condition: '[..[', as: 'nbNeutrals' }},
          { ['nb_review_by_avg_topic']: { min: 9, max: 10, condition: '[..]', as: 'nbPromoters' }}
        ]
    },
    topicsMixin_productLabelsTooltip(rateLabelsHash) {
      const nbLabel = Object.keys(rateLabelsHash).length
      let labels = []

      Object.keys(rateLabelsHash).forEach(score => {
        labels.push(`${score}/${nbLabel} = ${rateLabelsHash[score]}`)
      })

      return this.$i18n.t("topicLabelsWidgetTooltip", {
        labels: labels.join(", "),
        total: nbLabel
      })
    },
    topicsMixin_isDisplayTypeScale(topic) {
      return topic.displayType === 'scale'
    },
    topicsMixin_goToTopic(campaign, topic, sectionType = null) {
      this.$router.push({
        params: {
          sectionType: sectionType ?? this.$route.params.sectionType,
          sectionId: topic.id,
          subSectionId: campaign.id
        }
      })
    },
    async _previousPeriodDataByTopicId(campaign) {
      let request = this.dashboardFilterRequest
                        .select({
                          voter_avg_topics: this.gradeCondition(campaign).concat([
                            { AVG_avg_topic: { as: 'score' } },
                            { nb_review: { as: 'nbReview' } }
                          ])
                        })
                        .where({ campaign_id: campaign.id })
                        .dateBetween(
                          sqlDate(this.datesScope.comparedDateBegin),
                          sqlDate(this.datesScope.comparedDateEnd, '23:59:59')
                        )
                        .group(['topic_id'])

      if (this.placeIdsScope && this.placeIdsScope.length) {
        request = request.where({
          place_campaigns_place_id: this.placeIdsScope,
        })
      }

      return (await this.$resolve(request, '_previousPeriodDataByTopicId'))?.data || {}
    },
    async _loadBaseData(campaign) {
      // Data has already been loaded.
      if (this.$data._baseData[campaign.id] !== undefined) {
        return
      }
      // Set lock.
      if (this.$data._baseData_isLoading[campaign.id]) {
        return
      }
      this.$data._baseData_isLoading[campaign.id] = true
      // Prepare request.
      let request = this.dashboardFilterRequest
                        .select({
                          voter_avg_topics: this.gradeCondition(campaign).concat([
                            { MAX_topics_id: { as: 'id' } },
                            { MAX_topics_name: { as: 'name' } },
                            { AVG_avg_topic: { as: 'score' } },
                            { nb_review: { as: 'nbReview' } }
                          ])
                        })
                        .where({ campaign_id: campaign.id })
                        .dateBetween(
                          sqlDate(this.datesScope.dateBegin),
                          sqlDate(this.datesScope.dateEnd, '23:59:59')
                        )
                        .order(
                          [
                            ['AVG_avg_topic', "desc"],
                            ['nb_review', "desc"]
                          ]
                        )
                        .group(['topic_id'])
      if (this.placeIdsScope && this.placeIdsScope.length) {
        request = request.where({
          place_campaigns_place_id: this.placeIdsScope,
        })
      }
      // Load topics.
      let results = (await this.$resolve(request)).first() || []
      // Load evolution data.
      const previousPeriodDataByTopicId =
        await this._previousPeriodDataByTopicId(campaign)

      results = results.map(topic => {
        const storeTopic = campaign.topics.find(
          storeTopic => storeTopic.id == topic.topicId
        )
        const previousPeriodData = previousPeriodDataByTopicId[topic.id]

        topic.displayType = storeTopic?.displayType
        topic.rateLabelsHash = storeTopic?.rateLabelsHash

        if (previousPeriodData) {
          topic.previousPeriodData = previousPeriodData
          topic.evolution = topic.score - previousPeriodData.score
        }

        return topic
      })
      // Set topics for that campaign.
      Vue.set(
        this.$data._baseData,
        campaign.id,
        results
      )
      // Remove lock.
      this.$data._baseData_isLoading[campaign.id] = false
    },
    async topicsMixin_loadTopics(campaign) {
      // Data has already been loaded.
      if (this.topicsMixin_topics[campaign.id] !== undefined) {
        return
      }
      // Set lock.
      if (this.$data._topics_areLoading[campaign.id]) {
        return
      }
      this.$data._topics_areLoading[campaign.id] = true
      // Load topics.
      await this.nisMixin_loadNetImpactScoreSummariesByCampaignId()
      await this._loadBaseData(campaign)
      // The data is not available yet, let's defer.
      if (this.$data._baseData[campaign.id] === undefined) {
        this.$data._topics_areLoading[campaign.id] = false
        setTimeout(
          () => this.topicsMixin_loadTopics(campaign),
          500
        )
        return
      }
      // Add NIS data to topics.
      let topics = _cloneDeep(this.$data._baseData[campaign.id])
      if (this.nisMixin_canUseNisFeature([campaign])) {
        topics = topics.map(topic => {
          const nisSummary = this.nisMixin_topicSummaries(campaign)[topic.id]
          if (nisSummary) {
            let nisRoiSatisfactionEvolution = null

            if (topic.previousPeriodData) {
              nisRoiSatisfactionEvolution =
                this.nisMixin_roiSatisfaction(topic, nisSummary) -
                this.nisMixin_roiSatisfaction(topic.previousPeriodData, nisSummary)
            }

            return {
              ...topic,
              nisRoiSatisfaction: this.nisMixin_roiSatisfaction(topic, nisSummary),
              nisRoiSatisfactionEvolution: nisRoiSatisfactionEvolution,
              nisOpportunity: this.nisMixin_transactionalUnitToEarn(topic, nisSummary),
              nisPlaceReferences: this.nisMixin_getPlaceReferences(nisSummary),
              nisPlaceReferencesGroupName: nisSummary.groupName,
              nisPlaceReferencesGroupCategoryName: nisSummary.groupCategoryName,
              nisEmoji: this.nisMixin_transactionalUnit([campaign]).emoji,
              nisSatisfactionRates: this.nisMixin_satisfactionRates(
                topic,
                nisSummary
              ),
            }
          }
          return {
            ...topic,
            nisSummary: null,
            emoji: null,
            nisOpportunity: null,
            nisSatisfactionRates: null,
          }
        })
      }
      // Set topics for that campaign.
      Vue.set(
        this.topicsMixin_topics,
        campaign.id,
        topics
      )
      // Remove lock.
      this.$data._topics_areLoading[campaign.id] = false
    }
  }
}
