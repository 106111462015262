import { mapGetters } from 'vuex'
import htmlToPng from '@shared/helpers/html-to-png.js'
import Pdf from '@shared/helpers/exportToPdf/pdf.js'

export default {
  props: {
    subtitle: { type: String, required: false }
  },
  methods: {
    exportToPng() {
      htmlToPng(this.$refs.widget.$el, `${this.exportFilename}.png`, { windowWidth: 1300 })
    },
    async exportToPdf() {
      const pdf = new Pdf({
        defaultBodyMargin: { left: 40, top: 40 }
      })
      const element = this.$refs.widget.$el

      await pdf.addPage()

      await pdf.addRow({}, async (row) => {
        await row.addCol({}, async (col) => {
          await col.addHtmlElement(element, { html2CanvasOptions: { windowWidth: 1000 } })
        })
      })

      pdf.save(`${this.exportFilename}.pdf`)
    },
  },
  computed: {
    ...mapGetters([
      'dashboardFilterDates',
      'currentDashboardScopedCampaigns',
      'hasAccessToAllDashboardPlaces',
      'groupRankingPlaceIds',
      'networkRankingPlaceIds',
      'networkRankingName',
      'groupRankingName'
    ]),
    displayGroupRanking() {
      if (this.groupRankingPlaceIds && this.networkRankingPlaceIds) {
        return this.groupRankingPlaceIds.length !== this.networkRankingPlaceIds.length
      }
    },
    exportFilename() {
      return [this.title, this.displayedSubtitle].join('-')
    },
    exportOptions() {
      return [
        { title: 'PNG', onClick: this.exportToPng },
        { title: 'PDF', onClick: this.exportToPdf }
      ]
    },
    displayedSubtitle() {
      return this.subtitle || this.defaultSubtitle
    },
    defaultSubtitle() {
      return this.currentDashboardScopedCampaigns.length > 1 && this.campaign ?
        `${this.dashboardFilterDates.text} - ${this.campaign.name}` :
        this.dashboardFilterDates.text
    }
  }
}
