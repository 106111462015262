
<template>
  <WStatsWidget
    :title="title"
    :subtitle="displayedSubtitle"
    class="sentiment-score-widget"
    height="200px"
    :cols="12"
  >
    <template #content>
      <v-progress-circular
        :rotate="360"
        :size="90"
        :width="5"
        :value="zoomedData.feelingScore"
        :color="$colors.info"
      >
        <div class="d-flex flex-column align-center">
          <span class="c-primary fb-24">
            <span v-if="zoomedData.feelingScore < 1.0">{{ "<1%" }}</span>
            <span v-else >{{ zoomedData.feelingScore | round }}%</span>
          </span>
          <w-color-sign :value="zoomedData.feelingScoreEvolution" class="pl-1" :decimals="0" v-if="zoomedData.feelingScoreEvolution" ></w-color-sign>
        </div>
      </v-progress-circular>
    </template>

    <template
      #footer-left
      v-if="!hasAccessToAllDashboardPlaces && displayGroupRanking && groupIndicator && groupRankingName"
    >
      <span class="fb-12">
        <WTrunc :text="groupRankingName" :limit="20" /> :
      </span>
      <span class="mr-3">
        <span v-if="groupIndicator < 1">{{ "<1%" }}</span>
        <span v-else >{{ groupIndicator | round }}%</span>
      </span>
    </template>

    <template #footer-right v-if="!hasAccessToAllDashboardPlaces && networkIndicator">
      <span class="fb-12">
        {{ networkRankingName }} :
      </span>
      <span>
        <span v-if="networkIndicator < 1">{{ "<1%" }}</span>
        <span v-else >{{ networkIndicator | round }}%</span>
      </span>
    </template>
  </WStatsWidget>
</template>

<script>
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'

  export default {
    name: "SentimentScoreWidget",
    mixins: [
      WidgetMixin
    ],
    props: ['subtitle', 'zoomedData', 'groupZoomedData', 'networkZoomedData'],
    computed: {
      title() {
        return this.$t('part_of_positive_feeling')
      },
      groupIndicator() {
        return this.networkZoomedData?.feelingScore
      },
      networkIndicator() {
        return this.networkZoomedData?.feelingScore
      }
    }
  }
</script>
