<template>
  <div class="survey d-flex flex-column">
    <QuestionTree
      :voter="voter"
      :prospect-fields="prospectFields"
      :fields-customization="fieldsCustomization"
      :g-brand-name="gBrandName"
      :questions="currentSurvey.questions"
      :answers="selectedVoterSurvey.answers"
      :pictures="selectedVoterSurvey.pictures"
      :topicsVerbatims="selectedVoterSurvey.topicsVerbatims"
    />
  </div>
</template>

<script>
import QuestionTree from './QuestionTree'
import { mapGetters } from 'vuex'
import VoterMixin from '../../voter_mixin'

export default {
  name: 'Survey',
  components: {
    QuestionTree
  },
  mixins: [
    VoterMixin,
  ],
  props: {
    selectedVoterSurvey: { required: false, default: null },
    gBrandName: { required: false },
    fieldsCustomization: { required: false },
    prospectFields: { required: false },
  },
  data: () => ({
    opened: [0],
    voterSurvey: null,

  }),
  computed: {
    ...mapGetters([
      'currentSurvey',
      'currentUserLocale'
    ])
  },
  watch: {
    selectedVoterSurvey() {
      this.voterSurvey = this.selectedVoterSurvey
    }
  }
}
</script>

<style lang="stylus">
.survey
  .v-skeleton-loader__article, .v-skeleton-loader__list-item-three-line, .v-skeleton-loader__paragraph, .v-skeleton-loader__heading
    background-color: var(--palegrey) !important
</style>
