<template>
  <div>
    <AppBar />
    <SettingsSideBar></SettingsSideBar>

    <v-main class="settings-layout">
      <router-view />
    </v-main>
  </div>
</template>

<script>
  import AppBar from "./navigation/AppBar"
  import SettingsSideBar from "./navigation/sidebars/SettingsSideBar"

  export default {
    name: 'SettingsLayout',
    components: {
      AppBar,
      SettingsSideBar
    }
  }
</script>

<style lang="stylus" scoped>
</style>
