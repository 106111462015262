import { i18n } from '@i18n/setup'
import { mdiConsoleNetwork } from '@mdi/js'
import camelCase from 'camelcase'

const METADATA_ELIGIBLE_FOR_VOTER = {
  first_name: "firstName",
  last_name: "lastName",
  email: "email",
  phone_number: "phoneNumber",
  language: "language"
}

const dynamicFields = (
  str,
  isNps,
  prospectFields,
  fieldsCustomization,
  lexicon,
  gBrandName,
  placeName,
  currentVoter,
  log
) => {
  let base = content(
    str,
    isNps,
    fieldsCustomization,
    lexicon,
    gBrandName,
    placeName,
    prospectFields,
    currentVoter,
    log
  )
  return advancedContent(base, prospectFields)
}

const content = (
  str,
  isNps,
  fieldsCustomization,
  lexicon,
  gBrandName,
  placeName,
  prospectFields,
  currentVoter,
  log
) => {
  if (isNps) {
    if (/\{\{([^|\}]+)\}\}/.test(str)) {
      str.match(/\{\{([^|\}]+)\}\}/g).forEach((m) => {
        const field = m.replace(/[\{\}]/g, '').replace('prospects.', '')
        let fieldValue = ''
        if (field === 'place') {
          fieldValue = lexicon.translate('place', 'place')
        } else if (['brand_name', 'token'].includes(field)) {
          fieldValue = i18n.t(field)
        } else if (METADATA_ELIGIBLE_FOR_VOTER[field]) {
          fieldValue = METADATA_ELIGIBLE_FOR_VOTER[field]
        } else if (fieldsCustomization[i18n.locale]) {
          fieldValue =
            fieldsCustomization[i18n.locale][field] ||
            i18n.t(`label.customer.${field}`)
        }

        const value = `[[${fieldValue} | else=(self)]]`

        str = str.replace(m, value)
      })
    }
    if (/\{\{([^\}]+)\}\}/.test(str)) {
      str.match(/\{\{([^\}]+)\}\}/g).forEach((m) => {
        const exp = m.replace(/[\{\}]/g, '')
        const field = exp.split('|')[0].trim()
        const conditions = exp.split('|')[1].trim()

        const conditionValues = {}
        conditions.split(';').forEach((c) => {
          let tuple = c.split('=').map((t) => t.trim())
          conditionValues[tuple[0].toLowerCase()] = tuple[1] || ''
        })

        let finalValue = ''

        let fieldValue = ''
        if (field === 'place') {
          fieldValue = lexicon.translate('place', 'place')
        } else if (['brand_name', 'token'].includes(field)) {
          fieldValue = field
        } else if (METADATA_ELIGIBLE_FOR_VOTER[field]) {
          fieldValue = METADATA_ELIGIBLE_FOR_VOTER[field]
        } else if (fieldsCustomization[i18n.locale]) {
          fieldValue =
            fieldsCustomization[i18n.locale][field] ||
            i18n.t(`label.customer.${field}`)
        }

        const selfValue = {
          ...prospectFields,
          ...currentVoter,
          brandName: gBrandName,
          brand: gBrandName,
          placeName: placeName,
          place: placeName,
        }[camelCase(field)]

        const normalizedValue = selfValue?.trim()?.toLowerCase()

        if (conditionValues['empty'] && !normalizedValue) {
          finalValue = conditionValues['empty']
        } else {
          finalValue =
            conditionValues[normalizedValue] ||
            conditionValues['else'] ||
            selfValue ||
            '...'
        }

        str = str
          .replace(m, finalValue.replace('(self)', selfValue || '...'))
          .replace('<br/>', '')
          .replace('\n', '')
      })
    }
  }

  return str
    .replace(/\[\[Nom de la marque\]\]/i, gBrandName)
    .replace(/\[\[brand_name\]\]/i, gBrandName)
    .replace(/\[\[Brand name\]\]/i, gBrandName)
    .replace(/\[\[Markenname\]\]/i, gBrandName)
    .replace(/\[\[Marca\]\]/i, gBrandName)
    .replace(/\[\[Lieu de vente\]\]/i, placeName || gBrandName)
    .replace(/\[\[Place name\]\]/i, placeName || gBrandName)
}

const advancedContent = (str, prospectFields) => {
  if (/\{\{([^|\}]+)\}\}/.test(str)) {
    str.match(/\{\{([^|\}]+)\}\}/g).forEach((m) => {
      const field = camelCase(m.replace(/[\{\}]/g, ''))
      const value = prospectFields[field] || '...'

      str = str.replace(m, value)
    })
  }
  if (/\{\{([^\}]+)\}\}/.test(str)) {
    str.match(/\{\{([^\}]+)\}\}/g).forEach((m) => {
      const exp = m.replace(/[\{\}]/g, '')
      const field = camelCase(exp.split('|')[0]).trim()
      const conditions = exp.split('|')[1].trim()

      const value = prospectFields[field] || '...'
      const normalizedValue = value.trim().toLowerCase()

      const conditionValues = {}
      conditions.split(';').forEach((c) => {
        let tuple = c.split('=').map((t) => t.trim())
        conditionValues[tuple[0].toLowerCase()] = tuple[1] || ''
      })

      let finalValue = ''
      if (conditionValues['empty'] && !normalizedValue) {
        finalValue = conditionValues['empty']
      } else {
        finalValue = (
          conditionValues[normalizedValue] ||
          conditionValues['else'] ||
          ''
        ).replace('(self)', value)
      }

      str = str.replace(m, finalValue)
    })
  }
  return str
}

export default dynamicFields
