<template>
  <div class="products">
    <w-nav-tabs
      :router-links-properties="routerLinksProperties"
      :class="{ 'mb-3': $vuetify.breakpoint.xsOnly }"
    />
  </div>
</template>

<script>
  export default {
    name: "ProductFeedsNavigations",
    props: {
      page: {
        type: String,
        required: true
      },
      campaignId: {
        type: Number,
        required: true
      }
    },
    computed: {
      routerLinksProperties() {
        return [
          {
            title: this.$t('product_settings_settings_page_tab_title'),
            active: this.page === 'settings',
            route: {
              name: 'ProductImportPreference',
              params: { page: 'settings', campaignId: this.campaignId }
            }
          },
          {
            title: this.$t('product_settings_history_page_tab_title'),
            active: this.page === 'history',
            route: {
              name: 'ProductImportPreference',
              params: { page: 'history', campaignId: this.campaignId }
            }
          },
        ]
      }
    },
  }
</script>
