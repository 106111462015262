<template>
  <v-tooltip top transition="none">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-on="on"
        small
        class="ml-1 c-error"
      >
        mdi-alert
      </v-icon>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
  export default {
    name: "WTooltip",
    props: {
      tooltip: { type: String, required: false, default: "" },
    }
  }
</script>

<style lang="stylus" scoped>

</style>
