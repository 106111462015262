<template>
  <v-dialog
    class="group-dialog"
    v-model="open"
    width="600px"
    @keydown.escape="close"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="close"
          :ripple="false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          v-if="currentGroup"
          ref="form"
          v-model="isValid"
        >
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                background-color="#eef1f5"
                v-model="currentGroup.name"
                :label="$t('conversation_pre_filled_message_group_name')"
                @input="setChange"
                hide-details="auto"
                :rules="[$rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span>{{ $t('automated_message_treatments_conversation_pre_filled_message_group_grade_range') }}</span>
              <div class="d-flex align-center">
                <v-range-slider
                  v-model="range"
                  color="success"
                  track-color="error"
                  :min="rangeMin"
                  :max="rangeMax"
                  :step="1"
                  hide-details
                  thumb-color="success"
                  @change="setChange"
                />
                <span class="fb-14 ml-2">
                  {{ range[0] }} <=< {{ range[1] }}
                </span>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="cancel"
          outlined
        >
          {{ $t('conversation_pre_filled_message_group_cancel') }}
        </v-btn>
        <v-btn
          :disabled="!isValid || !hasChanged"
          color="primary"
          @click="save"
        >
          {{ saveTitle }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "GroupDialog",
    props: {
      value: {
        required: false
      },
      group: {
        required: false
      }
    },
    data() {
      return {
        isValid: true,
        rangeMin: 0,
        rangeMax: 5,
        hasChanged: false,
        currentGroup: null,
        defaultGroup: {
          id: null,
          name: null,
          automated: true,
          gradeMin: 0,
          gradeMax: 5
        }
      }
    },
    computed: {
      ...mapGetters([
        'currentCampaignPreferencesCampaignId'
      ]),
      range: {
        get() {
          return [this.currentGroup.gradeMin, this.currentGroup.gradeMax]
        },
        set(val) {
          this.currentGroup.gradeMin = val[0]
          this.currentGroup.gradeMax = val[1]
        }
      },
      open: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val);
        }
      },
      title() {
        return this.group ?
          this.$t('conversation_pre_filled_message_group_update_title', { groupName: this.group.name }) :
          this.$t('conversation_pre_filled_message_group_create_title')
      },
      saveTitle() {
        return this.group ?
          this.$t('conversation_pre_filled_message_group_save') :
          this.$t('conversation_pre_filled_message_group_create')
      }
    },
    watch: {
      value(val) {
        if (val) {
          this.currentGroup = this.group ?
            { ...this.group } :
            { ...this.defaultGroup }
        } else {
          this.close()
        }
      }
    },
    methods: {
      close() {
        this.open = false
      },
      cancel() {
        this.close()
      },
      async save() {
        if (this.group) {
          await this.update()
        } else {
          await this.create()
        }
      },
      async create() {
        try {
          const response = await this.$api.wizville.conversationPreFilledMessageGroups.create(
            {
              campaign_id: this.currentCampaignPreferencesCampaignId,
              name: this.currentGroup.name,
              automated: this.currentGroup.automated,
              grade_min: this.currentGroup.gradeMin,
              grade_max: this.currentGroup.gradeMax
            }
          )

          if (response.status === 'ok') {
            this.$store.dispatch('notifySuccess', { message: this.$t('flash_message_successfully_created_conversation_pre_filled_message_group'), timeout: 5000 })
            this.$emit('groupCreated', response.conversationPreFilledMessageGroup)
            this.close()
          } else {
            this.notifyError(response.message)
          }
        } catch (error) {
          this.notifyError(error.message)
        }
      },
      async update() {
        try {
          const response = await this.$api.wizville.conversationPreFilledMessageGroups.update(
            this.currentGroup.id,
            {
              name: this.currentGroup.name,
              automated: this.currentGroup.automated,
              grade_min: this.currentGroup.gradeMin,
              grade_max: this.currentGroup.gradeMax
            }
          )

          if (response.status === 'ok') {
            this.$store.dispatch('notifySuccess', { message: this.$t('flash_message_successfully_updated_conversation_pre_filled_message_group'), timeout: 5000 })
            this.$emit('groupUpdated', response.conversationPreFilledMessageGroup)
            this.close()
          } else {
            this.notifyError(response.message)
          }
        } catch (error) {
          this.notifyError(error.message)
        }
      },
      setChange() {
        this.hasChanged = true
      },
    }
  }
</script>

<style lang="stylus">
  .group-dialog
    .slider
      width: 200px
    .v-slider__thumb-label
      font-size: 14px !important
</style>
