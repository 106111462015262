<template>
  <WStatsWidget
    class="widget-lmbrand-score-repartition"
    :title="$t(title)"
    :subtitle="dashboardFilterDates.text"
    :cols="12"
  >
    <template #content>
      repartition
    </template>
  </WStatsWidget>
</template>

<script>
  import CompetitorModeTypeNav from '../navigation/CompetitorModeTypeNav'
  import { mapGetters } from 'vuex'

  export default {
    name: "WidgetScoreRepartition",
    components: {
      CompetitorModeTypeNav,
    },
    props: [ ],
    data() {
      return {
        title: "Répartition des notes",
        loading: false
      }
    },
    computed: {
      ...mapGetters([
        'dashboardFilterDates'
      ])
    }
  }
</script>
