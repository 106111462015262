const survey = {
  state: () => ({
    sources: null,
  }),
  getters: {
    dashboardFilterSurveySources(state, getters) {
      if (state.sources) {
        const sources = []

        state.sources?.forEach(source => {
          if (source == "wizville") {
            getters.wizvilleSources.forEach(wizSource => {
              sources.push(wizSource)
            })
          } else {
            sources.push(source)
          }
        })

        return (sources.length > 0 ? sources : null)
      }  
    },
    wizvilleSources() {
      return ["mono", "normal", "mailing", "sms", "sms_pull", "qrcode", "url", "web", "pull_web", "push_web"]
    },
    surveyFilterSources(state, getters) {
      return state?.sources
    },
    surveyFilterSourcesLength(state, getters) {
      if (state?.sources) {
        return state?.sources.includes('wizville') ? state?.sources.length - 1 : state?.sources.length
      }
    }
  },
  mutations: {
    setDashboardSurveyFilters(state, { sources }) {
      state.sources = sources
    },
  },
  actions: {
    setDashboardSurveyFilters({ commit }, filters) {
      if (filters.survey) {
        commit('setDashboardSurveyFilters', filters.survey)
      }
    },
  },
}

export default survey
