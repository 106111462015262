<template>
  <div v-if="currentUserId">
    <AppBar></AppBar>
    <DashboardsSideBar></DashboardsSideBar>

    <v-main class="dashboards-layout">
      <router-view />
    </v-main>
  </div>
</template>

<script>
  import AppBar from "./navigation/AppBar"
  import DashboardsSideBar from './navigation/sidebars/DashboardsSideBar'
  import { mapGetters } from 'vuex'

  export default {
    name: 'DashboardsLayout',
    components: {
      AppBar,
      DashboardsSideBar
    },
    data() {
      return {
        drawer: true
      }
    },
    computed: {
      ...mapGetters(['currentUserId']),
    },
  }
</script>

<style lang="stylus" scoped>
</style>
