<template>
  <div class="qcm-table-question-answer">
    <v-simple-table dense class="f-14 bg-transparent c-grey">
      <template v-slot:default>
        <thead>
          <tr>
            <th></th>
            <th class="text-left" v-for="qcmQuestionTableChoice of question.qcmQuestionTableChoices" :key="qcmQuestionTableChoice.id">
              <span class="title-label">{{ qcmQuestionTableChoice.choice }}</span>
            </th>
          </tr>
        </thead>
        <tbody>

          <tr v-for="qcmTableQuestionLabel of question.qcmTableQuestionLabels" :key="qcmTableQuestionLabel.id">
            <td  class="pl-0 text-left">
              {{ qcmTableQuestionLabel.label }}
            </td>

            <td class="text-left" v-for="qcmQuestionTableChoice of question.qcmQuestionTableChoices" :key="qcmQuestionTableChoice.id">
              <v-radio-group hide-details class="text-center" :value="activeChoiceId(qcmTableQuestionLabel.id)" dense>
                <v-radio
                  :value="qcmQuestionTableChoice.id"
                  :name="`qcm_table_question_answer_label_${ qcmTableQuestionLabel.id}_choice_${qcmQuestionTableChoice.id}`"
                  :disabled="!isActiveChoice(qcmTableQuestionLabel.id, qcmQuestionTableChoice.id)"
                  class="qcm-question-answer__radio text-center"
                  :ripple="false"

                />
              </v-radio-group>
            </td>
          </tr>

        </tbody>
      </template>
    </v-simple-table>
    <VoterPictures v-if="pictures" :pictures="pictures"/>
  </div>
</template>

<script>
  import VoterPictures from "./VoterPictures";

  export default {
    name: "QcmTableQuestionAnswer",
    components: {
      VoterPictures
    },
    props: {
      answers: { required: true, type: Array },
      question: { required: true },
      pictures: { required: false },
      test: 32
    },
    methods: {
      isActiveChoice(label_id, choice_id) {
        return this.answers.some((a) => {
          return (a.qcmTableQuestionLabel.id === label_id && a.qcmQuestionChoice.id === choice_id)
        })
      },
      activeChoiceId(label_id) {
        return this.answers.find((a) => (a.qcmTableQuestionLabel.id === label_id))?.qcmQuestionChoice?.id
      }
    }
  }
</script>

<style lang='stylus'>
  .qcm-table-question-answer
    .title-label
      padding-left: 2px

    .v-input--selection-controls
      margin-top: 0px !important
      padding: 10px 0px !important
</style>
