<template>
  <div class="lm-brands mt-2 mt-sm-0" id="widget-lmbrand-country-ranking">
    <WLateralSectionLayout class="pl-3" v-if="active" :fullScreenMode="fullScreenMode">
      <template #lateral-section>
        <WidgetLmBrandScore
          :key="`${activeLmBrandId}-${dashboardFilterBase64}`"
          :lmBrandIds="activeLmBrandId ? [ activeLmBrandId ] : lmBrandIds" :countryCode="countryCode" 
          :active="active" 
        />
      </template>

      <template #main-section>
        <template>
          <WidgetLmBrandRanking
            :lmBrandIds="lmBrandIds"
            :countryCode="countryCode"
            :active="active"
            :minimalNumberOfFeedbackForRanking="minimalNumberOfFeedbackForRanking"
            :key="`${dashboardFilterBase64}`"
          />
            
          <VerbatimTopicRankings
            :countryCode="countryCode"
            :active="active"
            :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreference.id"
            :key="`${activeLmBrandId}-${dashboardFilterBase64}`"
          />
        </template>
      </template>
    </WLateralSectionLayout>
  </div>
</template>

<script>
  import WidgetLmBrandRanking from '../widgets/LmBrandRanking'
  import WidgetScoreRepartition from '../widgets/ScoreRepartition'
  import WidgetLmBrandScore from '../widgets/LmBrandScore'
  import VerbatimTopicRankings from '../widgets/VerbatimTopicRankings'
  import { mapGetters } from 'vuex'

  export default {
    name: "LmBrands",
    components: {
      WidgetLmBrandRanking,
      WidgetScoreRepartition,
      WidgetLmBrandScore,
      VerbatimTopicRankings
    },
    props: ['countryCode', 'activeLmBrandId', 'campaignSmartBenchmarkPreference'],
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters([
        'currentDashboard',
        'lmGmbPlaceIdsScope',
        'dashboardFilterBase64',
        'fullScreenMode'
      ]),
      minimalNumberOfFeedbackForRanking() {
        return this.currentDashboard?.minimalNumberOfFeedbackForRanking
      },
      lmBrandIds() {
        return [...this.currentDashboard.lmBrandIds, ...this.campaignSmartBenchmarkPreference?.lmBrandIds]
      },
      topicIds() {
        return this.campaignSmartBenchmarkPreference?.topicIds
      },
      nbMonthForTopicWeight() {
        return this.campaignSmartBenchmarkPreference?.nbMonthForTopicWeight
      }
    },
    asyncComputed: {
      active: {
        async get() {
          if (this.activeLmBrandId) {
            const req = this.$basedRequest().where({
              id: this.activeLmBrandId
            }).select({
              lm_brands: [ 'name' ]
            })

            const result = (await this.$resolve(req)).first()

            return {
              type: 'lmBrand',
              id: Number(this.activeLmBrandId),
              name: result?.name,
              isUserItem: this.currentDashboard.lmBrandIds.includes(Number(this.activeLmBrandId))
            }
          }
        }
      }
    }
  }
</script>
