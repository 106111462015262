<template>
  <v-row class="empty-page">
    <v-col>

    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: "EmptyPage",
    data() {
      return {

      }
    },
  }
</script>

<style lang="stylus" scoped>
 
</style>
