<template>
  <w-autocomplete
    v-model="userId"
    label="Se connecter en tant que"
    :items="selectItems"
    :border="false"
    backgroundColor="primary"
    color="white"
    theme="light"
    fixTopOffset
    outlined
    rounded
    solo
    :loading="isLoading"
    :search-input.sync="search"
  />
</template>

<script>  
  import deepmerge from '@shared/helpers/deepmerge';
  import _debounce from "lodash/debounce"

  export default {
    name: 'AppBar',
    data: () => ({
      search: "",
      users: [],
      isLoading: false,
      userId: null
    }),
    computed: {
      selectItems() {
        const choices = []

        if (this.users) {
          for (let [brandName, brandUsers] of Object.entries(this.users)) {
            choices.push({ header: brandName.toUpperCase()})

            Object.values(brandUsers).forEach((user) => {
              choices.push({ value: user.id, text: user.login, group: brandName })
            })
          }
        }

        return choices
      }
    },
    watch: {
      search(val) {
        if (this.search && this.search.length > 2) {
          this.refreshUsers()
        }
      },
      userId() {
        if (this.userId) {
          this.logAs()
        }
      }
    },
    methods: {
      async logAs() {
        const sessionToken = await this.$api.wizville.sessions.logAs(this.userId)

        if (sessionToken) {
          if (window.location.host.match(/.*admin.*/) || window.location.host.match(/^localhost/)) {
            let host = window.location.host.replace("admin", "app")
            
            if (host.match(/^localhost/)) {
              host = 'app.wizville.' + host
            }

            window.open(`${window.location.protocol}//${host}?utok=${sessionToken}`, '_blank')
          } else {
            if (window.location.pathname.match(/.*[0-9].*/)) {
              window.location = `${window.location.protocol}//${window.location.host}?utok=${sessionToken}`
            } else {
              window.location = `${window.location.protocol}//${window.location.host}${window.location.pathname}?utok=${sessionToken}`
            }
          }
        }
      },
      refreshUsers: _debounce(async function() {
        this.isLoading = true
        const usersRequest = this.$adminBasedRequest().select({
          user_user_groups: [ 
            { MAX_users_login: { as: "login" } }, 
            { MAX_users_id: { as: "id" } }
          ]
        }).where({
          brand_group_user_mail_login: { 'icontain': this.search }
        }).group([
          { brand_and_group_name: { mod: "UPPER" } },
          "users_id"
        ]).order(
          ["MAX_brand_and_group_name", "asc"],
          ["MAX_users_mail", "asc"]
        )
        
        const users = await this.$resolve(usersRequest)

        const superAdminsRequest = this.$adminBasedRequest().select({
          super_admins: [
            { MAX_login: { as: "login" } }, 
            { MAX_id: { as: "id" } }
          ]
        }).where({
          login: { 'icontain': this.search }
        }).group(
          [ "group_name", "id" ]
        )
        
        const superAdmins = await this.$resolve(superAdminsRequest)

        this.users = deepmerge(superAdmins.data, users.data)
        this.isLoading = false
      }, 275, { leading: true })
    }
  }
</script>

<style lang="stylus" scoped>

</style>
