<template>
  <WStatsWidget
    v-if="campaign === undefined || campaignIndicator === undefined"
    :title="$t('ranking')"
    :cols="12"
    height="200px"
    :loading="true"
    :empty="false"
  />
  <PlaceRankingWidget
    v-else
    :title="title"
    :subtitle="displayedSubtitle"
    :campaign="campaign"
    :basedRequest="basedRequest"
    :indicatorColumn="indicatorColumn"
    :indicatorAlias="indicatorAlias"
    :cols="12"
    :displayRank="false"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import PlaceRankingWidget from '@statistics/shared/widgets/PlaceRankingWidget'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'

  export default {
    name: "CampaignIndicatorPlaceRankingWidget",
    components: {
      PlaceRankingWidget,
    },
    mixins: [
      WidgetMixin,
    ],
    props: {
      campaign: { type: Object },
      campaignIndicator: { type: Object },
    },
    computed: {
      ...mapGetters([
        'groupRankingName',
        'groupRankingPlaceIds',
        'networkRankingName',
        'networkRankingPlaceIds',
        'dashboardFilterReady',
        'dashboardFilterRequest',
        'datesScope',
        'placeIdsScope',
        'currentDashboardScopedCampaigns',
        'dashboardFilterDates',
        'placeIds'
      ]),
      title() {
        return `${this.$t('ranking')} - ${this.campaignIndicator.name}`
      },
      basedRequest() {
        let request =  this.dashboardFilterRequest

        if (this.campaign) {
          request = request.where({ campaign_id: this.campaign.id })
        }

        if (this.campaignIndicator.indicatorType == 'campaignScoreIndicator') {
          request = request.select({
            brand_voters: [
              this.indicatorColumn,
              { nb_review: { as: 'nbReview' } },
              { place_campaigns_place_id: { as: 'placeId' } },
              { MAX_place_name: { as: 'placeName' } }
            ]
          })
          if (this.campaignIndicator.formulaToIdKey) {
            request = request.where({
              [this.campaignIndicator.formulaToIdKey]: this.campaignIndicator.formulaToId
            })
          }
        } else {
          request = request.select({
            brand_voters: [
              this.indicatorColumn,
              { 'nb_review_avg_campaign_indicator_': { campaign_indicator_id: this.campaignIndicator.id, as: 'nbReview' } },
              { place_campaigns_place_id: { as: 'placeId' } },
              { MAX_place_name: { as: 'placeName' } }
            ]
          })
        }

        return request
      },
      indicatorColumn() {
        if (this.campaignIndicator.indicatorType == 'campaignScoreIndicator') {
          return { [this.campaignIndicator.formulaToColumn]: { as: this.indicatorAlias } }
        } else {
          return { [`avg_score_campaign_indicator_`]: { campaign_indicator_id: this.campaignIndicator.id, as: this.indicatorAlias } }
        }
      },
      indicatorAlias() {
        return 'score'
      },
      title() {
        const indicatorName = this.campaignIndicator.indicatorType == 'campaignScoreIndicator' ?
                              this.campaignIndicator.formulaToName :
                              this.campaignIndicator?.name

        return `${this.$t('ranking')} - ${indicatorName}`
      }
    }
  }
</script>
