<template>
  <WStatsWidget
    v-if="topic === undefined"
    height="200px"
    :cols="12"
    :title="title"
    :subtitle="subtitle"
    :loading="true"
    :empty="this.emptyActions"
  />
  <WStatsWidget
    v-else
    class="topic-achievement-action-recommendations-widget"
    height="200px"
    :cols="12"
    :title="title"
    :subtitle="subtitle"
    :loading="loading"
    :empty="this.emptyActions"
  >
    <template #content>
      <div class="d-flex flex-column align-start mt-4">
        <v-chip
          class="mt-1 cursor-pointer"
          v-for="(action, index) in displayedActions"
          :key="index"
          color="primary"
          :outlined="isDefaultActions"
          @click="openModal(action)"
        >
          <div class="d-flex">
            <span class="mr-1" v-if="action.emoji">
              {{ action.emoji }}
            </span>
            <span
              class="name text-truncate f-12"
            >
              {{ action.name }}
            </span>
            <span
              v-if="action.achievementCount"
              class="text-uppercase ml-1 lh-22 f-8"
            >
              {{ $t('achievement_count', { achievementCount: action.achievementCount }) }}
            </span>
          </div>
        </v-chip>
      </div>
      <v-dialog
        v-model="modalShown"
        max-width="800px"
        :retain-focus="false"
      >
        <v-card class="pa-5">
          <div class="d-flex">
            <div class="f-18 mb-3" v-html="modalTitle" />
            <v-btn
              icon
              color="black"
              plain
              @click="modalShown = false"
              class="ml-auto"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <AchievementTable
            :topicId="topic.id"
            :achievementActionTemplateId="selectedAction?.achievementActionTemplateId"
          />
        </v-card>
      </v-dialog>
    </template>
  </WStatsWidget>
</template>

<script>
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import AchievementTable from '../../../Achievements/AchievementTable'
  export default {
    name: "TopicAchievementActionRecommendationsWidget",
    components: {
      AchievementTable
    },
    props: {
      topic: { type: Object }
    },
    mixins: [
      WidgetMixin
    ],
    data() {
      return {
        loading: true,
        modalShown: false,
        opacityRange: { min: 0.5, max: 1 },
        selectedAction: null,
      }
    },
    asyncComputed: {
      defaultActions: {
        async get() {
          const defaultActionsReq = this.$basedRequest().select({
              achievement_action_templates: ["name", "emoji"]
            }).order(
              [['name', 'asc']]
            ).limit(3)
          const defaultActions = (await this.$resolve(defaultActionsReq))?.data?.achievementActionTemplates || []
          return defaultActions
        },
        default: []
      },
      actions: {
        async get() {
          this.loading = true
          const actionsReq = this.$basedRequest().select({
            achievement_actions: [
              { total_count: { as: 'achievementCount' } },
              { achievement_action_templates_id: { as: 'achievementActionTemplateId' } },
              { achievement_action_templates_name: { as: 'name' } },
              { achievement_action_templates_emoji: { as: 'emoji' } },
            ]
          }).where({
            topics_id: this.topic.id,
            place_campaign_achievements_claimed_at: { 'is_not_null': {} }
          }).group(
            'achievement_action_templates_id'
          ).order(
            [['total_count', 'desc']]
          ).limit(3)
          const actions = (await this.$resolve(actionsReq))?.data?.achievementActions || []
          this.loading = false
          return actions
        },
        default: []
      }
    },
    computed: {
      displayedActions() {
        return this.isDefaultActions ? this.defaultActions : this.actions
      },
      title() {
        return this.topic === undefined ?
               this.$t('topic_achievement_action_recommendations_loading') :
               this.$t(
                 'topic_achievement_action_recommendations',
                 { topic_name: this.topic.name }
               )
      },
      emptyActions() {
        return !this.loading && (this.isDefaultActions ? this.defaultActions.length === 0 : this.actions.length == 0)
      },
      achievementCountRange() {
        return this.isDefaultActions ?
          null :
          {
            min: (this.actions[this.actions.length - 1]?.achievementCount || 1),
            max: (this.actions[0]?.achievementCount || 1)
          }
      },
      modalTitle() {
        return this.$t(
          'statistics_achievements_modal_title',
          {
            topicName: this.topic.name,
            actionEmoji: this.selectedAction?.emoji,
            actionName: this.selectedAction?.name,
            achievementCount: this.selectedAction?.achievementCount,
          }
        )
      }
    },
    methods: {
      openModal(action) {
        this.selectedAction = action
        this.modalShown = true
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .cursor-pointer
    cursor: pointer
</style>
