<template>
  <div class="d-flex align-center collaborators-filters">
    <v-menu
      v-model="open"
      class="input-filter"
      :close-on-content-click="false"
      flat
      offset-y
      min-width="260"
      :class="{ 'v-mobile-menu': $vuetify.breakpoint.xsOnly }"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex px-2 flex-nowrap c-primary align-center input-user-scope"
          :class="{
            'bg-white': !open,
            'mobile-filter-menu': $vuetify.breakpoint.xsOnly,
          }"
          v-bind="attrs"
          v-on="on"
        >
          <w-icon
            v-if="mainIcon"
            class="c-accent filter-icon top_0px"
            :icon="mainIcon"
          />
          <span class="ml-1 selected-text text-truncate f-14" v-if="selectedPlace == ''">
            {{ $t('collaborators_all_my_perimeters') }}
          </span>
          <span class="ml-1 selected-text text-truncate f-14" v-else :class="{'selectedPlace': selectedPlace == ''}">
            {{ selectedPlace }}
          </span>
          <span class="ml-1 text-truncate f-14 pl-1 c-accent" v-if="selectedPlace != ''">
            <w-icon
              class="align-end"
              icon="close"
              @click="removeSelectedPlace"
            />
          </span>

          <v-icon
            color="black"
            size="medium"
            class="ml-auto"
          >
            mdi-chevron-down
          </v-icon>
        </div>
      </template>
      <v-list dense>
        <v-text-field
          v-model="search"
          class="pb-0 pt-1 px-3"
          @input="debouncedQueryUpdate($event)"
          :label="capitalize($t('search'))"
          append-icon="mdi-magnify"
          autofocus
          outlined
          dense
          hide-details
        />
      </v-list>
      <v-list v-if="selectedScope.id" dense>
        <v-list-item
          class="px-0"
          v-for="(item, i) in filteredItems"
          :key="i"
        >
          <v-list-item class="py-0 my-0 px-2 px-0 c-accent fb-12" v-if="item?.name && !item?.allPlaces" :class="classNameForSelectedItem(item)">
            <v-list-item-content>
              <v-list-item-title v-if="item.name == 'places'" >
               <w-icon icon="map-marker" class="mr-1" /> {{ $t('collaborator_page_establishment') }}
              </v-list-item-title>
              <v-list-item-title v-else-if="item.name == 'brand'">
                <w-icon icon="map-markers" class="mr-1" />{{ $t('collaborator_page_brand') }}
              </v-list-item-title>
              <v-list-item-title v-else-if="!collaboratorsMixin_isInArray(item.semantic)"">
                <w-icon icon="map-markers" class="mr-1" />{{ $t(collaboratorsMixin_getSemanticTranslation(item)) }}
              </v-list-item-title>
              <v-list-item-title v-else>
               <w-icon icon="map-markers" /> {{ collaboratorsMixin_normalizedPlaceName(item) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else-if="!item?.allPlaces" class="px-0 py-0 perimeter-name" @click="handlePlaceClick(item)">
            <v-list-item-content class="py-0">
              <v-list-item-title 
                :class="classNameForSelectedItem(item)" 
                class="f-13 pl-4 pr-3 py-2"
              >
                {{ item.place }} <span class="ml-1" :class="{ 'c-lightgrey': !(selectedPlace == item.place || (item.semantic == 'my_perimeters' && selectedPlace == '')) }">({{ item.placeCount }})</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item>
      </v-list>
    </v-menu>
    
    <v-select
      class="input-filter input-user-status ml-2"
      v-model="selectedFilterValue"
      :items="filterChoices"
      hide-details
      dense
      solo
      flat
      clearable
      :placeholder="computedPlaceholder"
      :menu-props="{ bottom: true, offsetY: true }"
    >
      <template v-slot:item="{ item }">
        <v-skeleton-loader type="list-item" v-if="alertingListLoading" class="px-4 pb-2 pt-4 panel v-expansion-panel bg-white">
        </v-skeleton-loader>
        <div v-else class="row d-flex align-center">
          <span class="col col-10"> {{ item.text }} </span>
          <span class="col col-2" v-if="item.value == 1"> {{ totalInactiveCollaborator }} </span>
          <span class="col col-2" v-if="item.value == 2"> {{ totalEtsWihoutCollaborator }} </span>
          <span class="col col-2" v-if="item.value == 3"> {{ totalDeactivatedCollaborators }} </span>
        </div>
      </template>
    </v-select>

    <v-autocomplete
      class="input-filter input-user-group ml-2"
      v-if="this.currentUserIsAdmin"
      v-model="selectedGroup"
      :items="userGroupsList"
      item-value="id"
      item-text="name"
      :menu-props="{maxHeight: 'none !important'}"
      hide-details
      dense
      solo
      flat
      clearable
      :placeholder="$t('collaborators_group')"
    />
    <v-spacer />
    <v-text-field
      class="input-filter input-user-email ml-2"
      dense
      solo
      flat
      hide-details
      v-model="searchUser"
      :placeholder="$t('collaborators_search')"
      append-icon="mdi-magnify"
    >
    </v-text-field>

    <v-btn small tile icon elevation="1"
      v-if="!rightBarIsLoading && hasRightEditCollaborators"
      class="add-user-btn bg-info ml-2"
      @click="handleRightbarUserOpen()"
    >
      <v-icon small color="white">mdi-plus</v-icon>
    </v-btn>

    <w-drop-down-menu
      class="c-white"
      :items="exportItems"
      icon="mdi-download"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { capitalize } from 'inflection'
import { debounce } from 'lodash'
import CollaboratorsMixin from '../collaborators_mixin'

export default {
  name: "CollaboratorsFilter",
  props: {
    items: {
      type: Array,
      required: false,
    },
    userGroupsList: {
      type: Array,
      required: false,
    },
    loading:{
      type: Boolean,
      required: true,
    },
    selectedFilterValue: null,
    allPerimeterCount: Number,
    totalInactiveCollaborator: Number,
    totalEtsWihoutCollaborator: Number,
    totalDeactivatedCollaborators: Number,
    alertingListLoading: Boolean,
    exportCollaborators: {
      type: Function,
      required: true,
    },
    rightBarIsLoading: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [
    CollaboratorsMixin
  ],
  data() {
    return {
      open: false,
      activated: false,
      selectedScope: { id: 'all' },
      searchUser: "",
      selectedPlace: '',
      selectedGroup: '',
      search: "",
      filterChoices: [
        { text: this.$t('collaborator_page_inactif_collaborators'), value: 1, counter: this.totalInactiveCollaborator },
        { text: this.$t('collaborator_page_perimeters_without_collaborators'), value: 2, counter: this.totalEtsWihoutCollaborator },
        { text: this.$t('collaborator_page_deactivated_collaborator'), value: 3, counter: this.totalDeactivatedCollaborators }
      ],
      firstPlacesIds: [],
    };
  },
  created() {
    this.debouncedQueryUpdate = debounce(this.updateQuery, 200);
    this.updatePlaceholder(this.selectedFilterValue);
  },
  methods: {
    capitalize,
    handleRightbarUserOpen() {
      this.$emit('toggleRightbarUser', true, null)
    },
    onChange() {
      this.$emit('change', this.selectedPlace);
    },
    updatePlaceholder(selectedValue) {
      if (selectedValue !== null) {
        this.placeholderText = this.$t('collaborator_filter_selected');
      } else {
        this.placeholderText = this.$t('collaborator_page_filters');
      }
    },
    handlePlaceClick(item) {
      let placeName = item.place;
      if (item?.place_id == null && typeof item?.placeId == 'undefined' || item?.semantic == 'my_perimeters'){
        placeName = "";
      }
      this.selectedPlace = placeName; 
      this.$emit('filterPlace', placeName);
      this.open = false
    },
    exportCollaboratorsList() {
      this.exportCollaborators();
    },
    updateQuery(newValue) {
      this.search = newValue;
    },
    removeSelectedPlace() {
      this.selectedPlace = '';
    },
    classNameForSelectedItem(item) {
      if (this.selectedPlace == item.place || (item.semantic == 'my_perimeters' && this.selectedPlace == '')) {
        return'bg-secondary c-white'
      }
      
      return 'bg-white c-black'
    }
  },
  computed: {
    computedPlaceholder() {
      if (this.selectedFilterValue !== null) {
        return this.$t(this.selectedFilterValue.text);
      }
      return this.$t('collaborator_page_filters');
    },
    ...mapGetters([
      'scopeLabel',
      'scopeIcon',
      'currentUserIsAdmin',
      'hasRightEditCollaborators'
    ]),
      debouncedSearch: {
        get() {
          return this.search
        },
        set: debounce(function (val) {
          this.search = val
        }, 100),
      },
      placeholderTextWithCount() {
        let count = 0;
        if (this.selectedFilterValue){
          return this.$t('collaborator_page_filters') + `(${count})`
        }
        return count > 0 ? this.$t('collaborator_page_filters') + `(${count})` : this.$t('collaborator_page_filters');
      },
      mainLabel() {
        if (this.selectedScope?.id == 'all') {
          return this?.globalScopeItem?.label
        } else {
          return this.scopeLabel
        }
      },
      mainIcon() {
        if (this.selectedScope?.id == 'all') {
          return this?.globalScopeItem?.icon
        } else {
          return this.scopeIcon
        }
      },
      groupedItems() {
        if (!this.items || !Array.isArray(this.items)) {
          return [];
        }

        const grouped = [{
          isGroup: false,
          place: this.$t('collaborators_all_my_perimeters'),
          placeId: null,
          semantic: 'my_perimeters',
          placeCount: this.allPerimeterCount,
          users_list: [],
          inactiveCollaboratorCount: 0,
          collaboratorCount: 0
        }];
        let currentGroup = null;

        this.items.forEach(item => {
          // prevent the all places displaying
          if (item.allPlaces == false) {
            if (currentGroup !== item.semantic && typeof item.semantic != 'undefined') {
              grouped.push({ isGroup: true, name: this.$t(item.semantic), semantic: item.semantic, semanticNormalized: item.semanticNormalized });
              currentGroup = item.semantic;
            }
            grouped.push(item);
          }
        });

        return grouped;
      },
      filteredItems() {
        if (this.search === "") {
          return this.groupedItems; // return all items if search is empty
        }
        return this.groupedItems.filter((item) => {
          if (item.isGroup) {
            return false;
          }
          return item.place.toLowerCase().includes(this.search.toLowerCase());
        });
      },
      exportItems() {
        return [
          { title: 'CSV', onClick: this.exportCollaboratorsList }
        ]
      },
  },
  watch: {
    selectedPlace: function (value) {
      this.$emit("filterPlace", value);
    },
    selectedFilterValue(value) {
      this.updatePlaceholder(value)
      this.$emit("filterActivated", value);
    },
    searchUser: function (value) {
      if (value.length > 2 || value.length == 0) {
        this.$emit("filterUser", value)
      }
    },
    selectedGroup: function (value) {
      this.$emit("filterUsergroup", this.selectedGroup);
    },
    open() {
      if (this.open && !this.activated) {
        this.activated = true
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
  @import '~@theme/colors.styl'
  
  .input-user-status, .input-user-group, .input-user-email
    max-width: 240px
    cursor: pointer

  .input-user-email
    min-width: 100px

  .input-user-scope
    height: 38px
    min-width: 260px
    border-radius: 4px

  .user-scope-menu
    .v-menu
      align-self: center;

  .disabled
    cursor default

  .v-list
    max-height 50vh
    overflow scroll
    .v-subheader
      text-transform uppercase
    .v-list-item
      height: 30px !important;
      min-height: 30px !important;
      .wiz-icon
        color var(--accent)
      &.active
        background-color var(--secondary) !important
        color white !important
        .wiz-icon
          color white
      &.network-item
        height 30px !important
        min-height 30px !important
        padding-left 8px !important

  .collaborators-filters
    .add-user-btn
      border-radius: 4px

    
</style>
