<template>
  <WLateralSectionLayout class="pl-3 nis-zoom">
    <template #lateral-section>
      <TopicRoiSatisfactionWidget
        :campaign="campaign"
        :topic="topic"
        :nisTopicSummary="nisTopicSummary"
      />
      <TransactionalUnitToEarnWidget
        :campaign="campaign"
        :linkTo="{
          name: 'Statistics',
          params: {
            sectionType: 'topics',
            sectionId: topic.id,
            subSectionId: campaign.id
          }
        }"
        :summary="nisTopicSummary"
        :topic="topic"
        :voterProportions="topic"
      />
    </template>

    <template #main-section>
      <TopicTransactionalUnitRepartitionWidget
        :campaign="campaign"
        :topic="topic"
        :nisTopicSummary="nisTopicSummary"
      />
      <TopicRoiSatisfactionEvolutionWidget
        :campaign="campaign"
        :topic="topic"
        :nisTopicSummary="nisTopicSummary"
      />
    </template>
  </WLateralSectionLayout>
</template>

<script>
  import TopicRoiSatisfactionEvolutionWidget from './TopicRoiSatisfactionEvolutionWidget'
  import TopicRoiSatisfactionWidget from './TopicRoiSatisfactionWidget'
  import TopicTransactionalUnitRepartitionWidget from './TopicTransactionalUnitRepartitionWidget'
  import TransactionalUnitToEarnWidget from '../../shared/widgets/TransactionalUnitToEarnWidget'
  import NisMixin from '@statistics/shared/nis_mixin'

  export default {
    name: "NisZoom",
    props: {
      campaign: { type: Object, required: true },
      topic: { type: Object },
      nisTopicSummary: { type: Object },
    },
    mixins: [
      NisMixin,
    ],
    components: {
      TopicRoiSatisfactionEvolutionWidget,
      TopicRoiSatisfactionWidget,
      TopicTransactionalUnitRepartitionWidget,
      TransactionalUnitToEarnWidget,
    },
    computed: {
      optionId() {
        return `${this.campaign?.id}_${this.campaignIndicator?.id}`
      }
    }
  }
</script>
