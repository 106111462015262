<template>
  <v-navigation-drawer
    v-resize="onResize"
    :value="drawer"
    :permanent="$vuetify.breakpoint.smAndUp"
    :expand-on-hover="$vuetify.breakpoint.sm || $vuetify.breakpoint.md"
    :mini-variant.sync="mini"
    clipped
    dense
    flat
    app
    :height="windowHeight"
    class="sidebar settings-sidebar"
    v-if="!$inIframe"
    :class="{ 'bg-palegrey': !$vuetify.breakpoint.xsOnly }"
    @input="setDrawer"
  >
    <v-hover v-slot="{ hover }" >
      <v-layout class="d-flex flex-column" fill-height>
        <header-menu
          v-if="$vuetify.breakpoint.xsOnly"
          @closeMenuClicked="closeMenu"
        ></header-menu>

        <div class="d-flex flex-column flex-section">
          <v-slide-x-transition
            leave-absolute
            v-if="$vuetify.breakpoint.xsOnly && showDashboardList"
          >
            <dashboards-list @hideDashboardList="showDashboardList = false"></dashboards-list>
          </v-slide-x-transition>

          <v-slide-x-reverse-transition leave-absolute>
            <v-list v-if="!showDashboardList" class="pa-0 d-flex flex-column flex-section">

              <top-menu
                v-if="$vuetify.breakpoint.smAndUp"
                :hovered="hover"
                @toogleMiniClicked="mini = !mini"
                :title="$t('local_reputation_monitoring_sidebar_preferences')"
                icon="mdi-account"
              ></top-menu>

              <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

              <div class="overflow-section flex-grow-1">
                <dashboards-list-menu
                  :title="$t('dashboard_sidebar_dashboard_default_title')"
                  @clicked="showDashboardList = true"
                />

                <dashboards-list-menu
                  :title="$t('dashboard_sidebar_dashboard_default_title')"
                  @clicked="showDashboardList = true"
                />


                <template v-for="(menuItem, index) in menuItems">
                  <v-list-item
                    color="info"
                    :class="{'v-list-item--active active-item': menuItem.active}"
                    v-if="menuItem.show"
                    :to="menuItem.to"
                    :ripple="false">
                    <v-list-item-icon>
                      <v-icon class="c-primary dense">{{ menuItem.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="fs-15 c-primary">
                      {{ menuItem.title }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <company-menu v-if="$vuetify.breakpoint.xsOnly && currentUser.isAdmin"></company-menu>
              </div>
            </v-list>
          </v-slide-x-reverse-transition>
        </div>
        <div class="mt-auto">
          <WFooter v-if="!mini"/>
          <footer-menu v-if="$vuetify.breakpoint.xsOnly" ></footer-menu>
        </div>
      </v-layout>
    </v-hover>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex"

import HeaderMenu from "./shared/HeaderMenu"
import TopMenu from "./shared/TopMenu"
import FooterMenu from "./shared/FooterMenu"
import DashboardMenus from "./shared/DashboardMenus"
import DashboardsList from "./shared/DashboardsList"
import DashboardsListMenu from "./shared/DashboardsListMenu"
import CompanyMenu from "./shared/CompanyMenu"

export default {
  name: 'SettingsdSideBar',
  data() {
    return {
      mini: false,
      drawer: false,
      windowHeight: window.innerHeight,
      showDashboardList: false
    }
  },
  components: {
    HeaderMenu,
    TopMenu,
    FooterMenu,
    DashboardMenus,
    DashboardsList,
    DashboardsListMenu,
    CompanyMenu
  },
  computed: {
    ...mapGetters(
      [
        'sidebarShowed',
        'currentUser',
        'lrmBrandPreferenceEnabled',
        'currentUserIsAdmin',
        'currentLexicon'
      ]
    ),
    menuItems() {
      return [
        {
          title: this.$t('settings_my_account'),
          show: true,
          to: { name: 'settings_my_account' },
          icon: 'mdi-key',
          active: this.$route.name === "settings_my_account"
        },
        {
          show: this.currentUserIsAdmin,
          title: this.currentLexicon.translate('places', 'places'),
          to: { name: 'PlacesSettings' },
          icon: 'mdi-home-group',
          active: this.$route.name === "PlacesSettings"
        },
        {
          show: this.currentUserIsAdmin,
          title: this.$t('lexicon_settings'),
          to: { name: 'lexicons' },
          icon: 'mdi-alphabet-latin',
          active: this.$route.name === "lexicons"
        },
        {
          title: this.$t('local_reputation_monitoring_sidebar_my_competitors'),
          show: this.currentUserIsAdmin || this.lrmBrandPreferenceEnabled,
          to: { name: this.currentUserIsAdmin ? 'local_reputation_monitoring_gmb_overview' : 'local_reputation_monitoring_gmb_assignment' },
          icon: "mdi-map-marker",
          active: [
            "local_reputation_monitoring_gmb_overview",
            "local_reputation_monitoring_gmb_settings",
            "local_reputation_monitoring_gmb_assignment"
          ].includes(this.$route.name)
        },
        {
          title: this.$t('settings_public_platforms'),
          show: this.currentUserIsAdmin,
          to: {
            name: 'PublicPlatforms', params: { platform: 'google' }
          },
          icon: "mdi-connection"
        }
      ]
    }
  },
  mounted() {
    if(this.$vuetify.breakpoint.smOnly ||
      this.$vuetify.breakpoint.mdOnly
    ) {
      this.mini = true
    }
  },
  watch: {
    sidebarShowed: function() {
      this.drawer = this.sidebarShowed;
    }
  },
  methods: {
    closeMenu() {
      this.$store.dispatch('updateShowSidebar', false);
    },
    setDrawer(e) {
      this.$store.dispatch('updateShowSidebar', e);
      this.showDashboardList = false;
    },
    onResize () {
      this.windowHeight = window.innerHeight
      if (this.$vuetify.breakpoint.smAndUp) {
        this.$store.dispatch('updateShowSidebar', false);
      }
    }
  }
}
</script>

<style lang="stylus">
  @import '~@theme/colors.styl'

  .settings-sidebar
    .v-list-item__icon:first-child
      margin-right: 4px !important

    .v-list-item__icon
      .v-icon
        margin-top: 0px !important
        margin-left: 3px
        font-size: 15px
    .sidebar
      max-width: 400px

    .active-item
      .v-list-item__icon i, .v-list-item__title
        color: var(--info) !important
</style>
