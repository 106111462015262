<template>
  <v-card
    :height="cardHeight"
    class="elevation-1"
  >
    <VoterSectionNav
      :voter="voter"
    />
    <span v-show="section === 'review'">
      <VoterReviewSection
        :voter="voter"
        :gBrandName="dynamicFieldsMixin_gBrandName"
        :fieldsCustomization="dynamicFieldsMixin_fieldsCustomization"
        :prospectFields="dynamicFieldsMixin_prospectFields"
        :actions="actions"
        :section="section"
      />
    </span>

    <span v-show="section === 'customer_information'">
      <VoterCustomerInformationSection
        :voter="voter"
      />
    </span>
    <MobileAction
      v-if="$vuetify.breakpoint.smAndDown"
      :actions="actions"
    />
    <MobileActionPanel
      v-if="$vuetify.breakpoint.smAndDown"
      :voter="voter"
      :actions="actions"
      :gBrandName="dynamicFieldsMixin_gBrandName"
      :fieldsCustomization="dynamicFieldsMixin_fieldsCustomization"
      :prospectFields="dynamicFieldsMixin_prospectFields"
      :smartAnswer="fetchSmartAnswer"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import VoterSectionNav from "./VoterSectionNav"

import MobileAction from './MobileAction'
import MobileActionPanel from './MobileActionPanel'

import VoterReviewSection from './VoterReviewSection'
import VoterCustomerInformationSection from './VoterCustomerInformationSection'

import VoterMixin from '../voter_mixin'
import InsatisfactionMixin from '../insatisfaction_mixin'
import CurrentUserCapacityMixin from '../current_user_capacity_mixin'
import DynamicFieldsMixin from '../dynamic_fields_mixin'

export default {
  name: 'VoterDetail',
  components: {
    VoterSectionNav,
    MobileAction,
    MobileActionPanel,
    VoterReviewSection,
    VoterCustomerInformationSection
  },
  mixins: [
    VoterMixin,
    InsatisfactionMixin,
    CurrentUserCapacityMixin,
    DynamicFieldsMixin,
  ],
  props: {
    section: {
      type: String,
      default: 'review'
    },
  },
  data: () => ({
    opened: 0,
    tab: '',
    showOtherVotersLink: true
  }),
  computed: {
    ...mapGetters([
      'currentUserIsAdmin',
      'currentDashboardInsatisfactionNotificationEnabled',
      'currentDashboardType',
      'currentSurvey',
      'currentUser',
      'currentUserBrandName',
      'currentUserFeedbackContent',
      'currentUserLocale',
      'currentVoter',
      'currentVoterIsActionSubMenuOpened',
      'currentVoterShowedActions',
      'mainCampaign',
      'voterTrustvilleReportable',
      'voterUpdateEvent',
      'currentVoterSmartAnswersEnabled',
    ]),
    cardHeight() {
      return this.$vuetify.breakpoint.mdAndUp ?
        'calc(100vh - 107px)' :
        'calc(100vh - 65px)'
    },
    actions() {
      return [
        {
          id: 'send_message',
          title: this.$t('answer'),
          icon: 'mdi-chat-outline',
          displayCondition: this.canAnswerCustomers && this.customerHasAvailableChannel,
          tabClassNames: 'c-secondary send-message-tab',
          tabIconClassNames: 'c-secondary',
          fabButtonClassNames: 'bg-white c-secondary',
          fabIconClassNames: 'bg-secondary c-white'
        },
        {
          id: 'write_up',
          title: this.$t('call'),
          icon: 'mdi-phone-log',
          displayCondition: (
            this.canAnswerCustomers &&
            this.customerHasAvailableChannel &&
            this.voterMixin_voterCanBeCalled
          ),
          tabClassNames: 'c-violette call-report-tab',
          tabIconClassNames: 'c-violette',
          fabButtonClassNames: 'bg-white c-violette',
          fabIconClassNames: 'bg-violette c-white'
        },
        {
          id: 'assign',
          title: this.$t('assign'),
          icon: 'mdi-account-outline',
          displayCondition: (
            this.canAssignInsatisfaction &&
            this.customerHasAvailableChannel
          ),
          tabClassNames: 'assign-tab',
          fabButtonClassNames: 'bg-white c-dark',
          fabIconClassNames: 'bg-bodygrey c-dark'
        },
        {
          id: 'send_internal_memo',
          title: this.$t('internal_memo'),
          icon: 'mdi-file-edit-outline',
          tabClassNames: 'internal-memo-tab',
          fabButtonClassNames: 'bg-white c-dark',
          fabIconClassNames: 'bg-bodygrey c-dark'
        },
        {
          id: 'send_feedback',
          title: this.$t('send_feedback'),
          icon: 'mdi-email-outline',
          tabClassNames: 'send-feedback-tab',
          fabButtonClassNames: 'bg-white c-dark',
          fabIconClassNames: 'bg-bodygrey c-dark'
        },
        {
          id: 'close_feedback',
          title: this.$t('close_feedback'),
          icon: 'mdi-check',
          displayCondition: this.currentUser.isAdmin || this.canAnswerCustomers,
          tabClassNames: 'close-feedback-tab',
          fabButtonClassNames: 'bg-white c-dark',
          fabIconClassNames: 'bg-bodygrey c-dark'
        },
        {
          id: 'trustville_report',
          title: this.$t('trustville_report'),
          icon: 'mdi-flag-outline',
          displayCondition: this.voterTrustvilleReportable,
          tabClassNames: 'trustville-report-tab',
          fabButtonClassNames: 'bg-white c-dark',
          fabIconClassNames: 'bg-bodygrey c-dark'
        }
      ]
    }
  },
  created() {
    window.addEventListener("scroll", this.onWindowScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onWindowScroll);
  },
  methods: {
    onOtherVotersLinkClicked() {
      window.scrollTo({ top: 100, behavior: 'smooth' })
    },
    onWindowScroll(event) {
      this.showOtherVotersLink = window.scrollY === 0
    },
    onResize() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.opened = 0
      }
    }
  },
  watch: {
    currentVoter() {
      this.opened = 0
    }
  }
}
</script>

<style lang="stylus" scoped>
  .voter-detail
    .spacer
      height: 40px
      background-color: blue
    .v-item-group
      z-index: 1
    .card.v-card > *:first-child, .no-radius
      border-top-left-radius: 0px
      border-top-right-radius: 0px
    .content
      height: 100%

    .overflow
      overflow-y: scroll
      overflow-x: hidden
      scroll-behavior: smooth
    .other-voters
      min-height: 20px
      .link
        text-transform: unset
        text-decoration: underline
</style>
