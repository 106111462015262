<template>
  <WStatsWidget
    class="nps-negative-topics-widget"
    title="Résumé commentaires négatifs"
    :subtitle="displayedSubtitle"
    :loading="campaign === undefined"
    :cols="6"
  >
    <template #content>
      <WWeightRepartition
        class="pb-3"
        :items="items"
      />
    </template>

    <template #options>
      <w-drop-down-menu :items="exportItems" icon="mdi-download" />
    </template>

    <template #footer-left>
    </template>

    <template #footer-right>
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'

  export default {
    name: "NpsNegativeTopicsWidget",
    mixins: [
      WidgetMixin
    ],
    computed: {
      ...mapGetters([
        'isMonoPlace',
        'hasAccessToAllDashboardPlaces',
        'dashboardFilterDatesText'
      ]),
      exportItems() {
        return [
          { title: 'PNG', onClick: "#" },
          { title: 'PDF', onClick: "#'" }
        ]
      },
      items() {
        let values = []

        if (this.isMonoPlace) {
          values = [{ name: "Passage en caisse", value: 12, weight: 26 }, { name: "Choix", value: 8, weight: 17 }, { name: "Prix", value: 4, weight: 9 }]
        } else if (this.hasAccessToAllDashboardPlaces) {
          values = [{ name: "Passage en caisse", value: 90, weight: 7 }, { name: "Equipe", value: 77, weight: 6 }, { name: "Choix", value: 64, weight: 5 }, { name: "Prix", value: 51, weight: 4 }, { name: "Rayon vélo", value: 39, weight: 3 }]
        } else {
          values = [{ name: "Passage en caisse", value: 70, weight: 22 }, { name: "Choix", value: 64, weight: 20 }, { name: "Prix", value: 29, weight: 9 }]
        }

        return values.map(item => {
          return {
            name: item.name,
            value: item.value,
            weight: item.weight,
            barColor: this.$colors.error,
            link: "#"
          }
        })
      }
    }
  }
</script>
