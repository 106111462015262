<template>
  <div>
    <v-menu
      v-if="selectedFilter"
      v-model="open"
      :close-on-content-click="false"
      :class="{ 'v-mobile-menu': $vuetify.breakpoint.xsOnly }"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="
            w-filter-dropdown
            d-flex
            px-2
            align-center
            flew-nowrap
          "
          :class="{
            'bg-bodygrey': open,
            'bg-palegrey': !open,
            'mobile-filter-menu': $vuetify.breakpoint.xsOnly,
          }"
          v-bind="attrs"
          v-on="on"
        >
          <w-icon icon="calendar" class="c-accent filter-icon top_0px" />
          <span class="ml-1 selected-text c-primary text-truncate f-13">
            {{ selectedFilter.text }}
          </span>
          <v-icon class="ml-auto" color="primary" size="medium">mdi-chevron-down</v-icon>
        </div>
      </template>
      <v-slide-x-transition leave-absolute>
        <v-list class="pb-0" v-if="customMode">
          <v-date-picker
            v-model="customDates"
            :locale="$i18n.locale"
            :max="$date().format('YYYY-MM-DD')"
            color="secondary"
            first-day-of-week="1"
            no-title
            range
          />

          <div class="d-flex justify-center">
            <v-text-field
              :value="customDateBegin"
              @change="customDateBegin = $event"
              class="ma-1 date-input"
              :label="$t('date_from')"
              outlined
              dense
              hide-details
            />
            <v-text-field
              :value="customDateEnd"
              @change="customDateEnd = $event"
              class="ma-1 date-input"
              :label="$t('date_to')"
              outlined
              dense
              hide-details
            />
          </div>

          <v-divider class="mt-1"></v-divider>
          <div @click="customMode = false" class="custom-link pa-2">
            <v-icon>mdi-chevron-left</v-icon>
            <span class="fs-14">
              {{ $t('dateFilter.back') }}
            </span>
          </div>
        </v-list>
      </v-slide-x-transition>
      <v-slide-x-reverse-transition leave-absolute>
        <v-list class="pb-0" v-if="!customMode" dense>
          <v-list-item-group v-model="selectedFilter">
            <v-list-item
              class="f-14"
              :class="{ active: selectedFilter.value.key === 'genesis' }"
              :value="genesisItem"
              dense
            >
              {{ $t('dateFilter.genesis') }}
            </v-list-item>
            <v-list-item
              class="f-14"
              :class="{ active: selectedFilter.value.key === 'last_12_months' }"
              :value="last12MonthsItem"
              dense
            >
              {{ $t('dateFilter.last_12_months') }}
            </v-list-item>
            <div
              v-for="(itemGroup, index) in selectableItemGroups"
              :key="`itemGroup-${index}`"
            >
              <v-subheader>
                <span class="fb-12 c-accent">
                  {{ itemGroup.text }}
                </span>
              </v-subheader>
              <v-list-item
                v-for="(item, index) in itemGroup.items"
                class="f-14"
                :key="item.key"
                :class="{
                  active:
                    selectedFilter.value.key === item.value.key &&
                    selectedFilter.value.offset === item.value.offset,
                }"
                :value="item"
                dense
              >
                <span>{{ item.text }}</span>
              </v-list-item>
            </div>
            <v-divider class="mt-1"></v-divider>
            <div
              @click="customMode = true"
              class="custom-link d-flex justify-space-between pa-2"
              :class="{ active: selectedFilter.value.key === 'custom' }"
            >
              <span class="fs-14">
                {{ $t('dateFilter.custom') }}
              </span>

              <v-icon>mdi-chevron-right</v-icon>
            </div>
          </v-list-item-group>
        </v-list>
      </v-slide-x-reverse-transition>
    </v-menu>
  </div>
</template>

<script>
import { capitalize } from 'inflection'
import { mapGetters } from 'vuex'
import _isEqual from 'lodash/isEqual'

export default {
  name: 'DateFilter',
  components: {},
  data() {
    return {
      loading: false,
      open: false,
      selectedFilter: null,
      customDates: [],
      customDateBegin: null,
      customDateEnd: null,
      customMode: false,
    }
  },
  computed: {
    ...mapGetters(['dashboardFilterDates', 'dashboardFilterReady', 'currentUser']),
    weekSelection() {
      return {
        text: this.$t('dateFilter.weeks'),
        items: ['this_week', 'last_week'].map((title, offset) => {
          const date = this.$date().subtract(offset, 'w')
          const dateBegin = date.startOf('w')
          const dateEnd = this.$date.min(date.endOf('w'), this.$date())
          return {
            text: this.$t(`dateFilter.${title}`),
            value: {
              key: 'weeks',
              dateBegin: dateBegin.format('YYYY-MM-DD'),
              dateEnd: dateEnd.format('YYYY-MM-DD'),
              offset,
            },
          }
        }),
      }
    },
    monthSelection() {
      return {
        text: this.$t('dateFilter.months'),
        items: [0, 1, 2].map((offset) => {
          const date = this.$date().subtract(offset, 'M')
          const dateBegin = date.startOf('M')
          const dateEnd = this.$date.min(date.endOf('M'), this.$date())
          return {
            text: capitalize(
              date.locale(this.$i18n.locale).format('MMMM YYYY')
            ),
            value: {
              key: 'months',
              dateBegin: dateBegin.format('YYYY-MM-DD'),
              dateEnd: dateEnd.format('YYYY-MM-DD'),
              offset,
            },
          }
        }),
      }
    },
    quarterSelection() {
      return {
        text: this.$t('dateFilter.quarters'),
        items: ['this_quarter', 'last_quarter'].map((titleKey, offset) => {
          const title = this.$t(`dateFilter.${titleKey}`)
          const date = this.$date()
          const quarterOffset = this.currentUser.quarterOffset % 3

          const dateBegin = date.subtract(3 * offset + quarterOffset, 'month').startOf('Q').add(quarterOffset, 'month')
          const dateEnd = dateBegin.add(2, 'month').endOf('M')

          return {
            text: `${title} (${capitalize(
              dateBegin.locale(this.$i18n.locale).format('MMMM')
            )} - ${capitalize(
              dateEnd.locale(this.$i18n.locale).format('MMMM YYYY')
            )})`,
            value: {
              key: 'quarters',
              dateBegin: dateBegin.format('YYYY-MM-DD'),
              dateEnd: this.$date
                .min(dateEnd, this.$date())
                .format('YYYY-MM-DD'),
              offset,
            },
          }
        }),
      }
    },
    yearSelection() {
      return {
        text: this.$t('dateFilter.years'),
        items: ['this_year', 'last_year'].map((titleKey, offset) => {
          const title = this.$t(`dateFilter.${titleKey}`)
          const date = this.$date().subtract(offset, 'y')
          const dateBegin = date.startOf('y')
          const dateEnd = this.$date.min(date.endOf('y'), this.$date())
          return {
            text: `${title} (${dateBegin
              .locale(this.$i18n.locale)
              .format('YYYY')})`,
            value: {
              key: 'years',
              dateBegin: dateBegin.format('YYYY-MM-DD'),
              dateEnd: dateEnd.format('YYYY-MM-DD'),
              offset,
            },
          }
        }),
      }
    },
    selectableItemGroups() {
      return [
        this.weekSelection,
        this.monthSelection,
        this.quarterSelection,
        this.yearSelection,
      ]
    },
    last12MonthsItem() {
      return {
        text: this.$t('dateFilter.last_12_months'),
        value: {
          key: 'last_12_months',
          dateBegin: this.$date().subtract(12, 'months').startOf('M').format('YYYY-MM-DD'),
          dateEnd: this.$date().format('YYYY-MM-DD'),
        },
      }
    },
    genesisItem() {
      return {
        text: this.$t('dateFilter.genesis'),
        value: {
          key: 'genesis',
          dateBegin: '1970-01-01',
          dateEnd: this.$date().format('YYYY-MM-DD'),
        },
      }
    },
    customDateItem() {
      if (this.customDateBegin && this.customDateEnd) {
        return {
          text: `${this.customDateBegin} - ${this.customDateEnd}`,
          value: {
            key: 'custom',
            dateBegin: this.customDateBegin,
            dateEnd: this.customDateEnd,
          },
        }
      } else {
        return null
      }
    },
  },
  methods: {
    updateFilterFromStore() {
      const key =
        this.dashboardFilterDates?.key || this.dashboardFilterDates?.value?.key
      const offset =
        this.dashboardFilterDates?.offset ||
        this.dashboardFilterDates?.value?.offset

      if (key) {
        switch (key) {
          case 'custom':
            this.customMode = true
            this.customDateBegin = this.dashboardFilterDates.value.dateBegin
            this.customDateEnd = this.dashboardFilterDates.value.dateEnd
            this.updateCustomDates()
            this.selectedFilter = this.customDateItem
            break
          case 'genesis':
            this.selectedFilter = this.genesisItem
            break
          case 'last_12_months':
            this.selectedFilter = this.last12MonthsItem
            break
          default:
            this.selectedFilter = this.findInSelectableItemGroups(key, offset)
              || this.findInSelectableItemGroups("years", null)

        }
      }
    },
    findInSelectableItemGroups(key, offset) {
      return this.selectableItemGroups.flatMap((g) => g.items).find((i) => {
        return (
          i.value.key === key && (!offset || i.value.offset === offset)
        )
      })
    },
    updateDashboardFilter(newVal, oldVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.selectedFilter = oldVal
        })
        return
      }
      if (!_isEqual(this.selectedFilter, this.dashboardFilterDates)) {
        this.$store.dispatch('updateDashboardFilters', {
          dates: this.selectedFilter,
        })
      }

      if (this.customDateBegin !== this.selectedFilter.value.dateBegin) {
        this.customDateBegin = this.selectedFilter.value.dateBegin
        this.open = false
      }
      if (this.customDateEnd !== this.selectedFilter.value.dateEnd) {
        this.customDateEnd = this.selectedFilter.value.dateEnd
        this.open = false
      }
      this.updateCustomDates()
    },
    updateCustomDates() {
      if (this.customDateBegin) {
        let dateBegin = this.$date(this.customDateBegin, 'YYYY-MM-DD')
        if (!dateBegin.isValid()) {
          dateBegin = this.$date()
        }

        dateBegin = dateBegin.format('YYYY-MM-DD')
        if (dateBegin !== this.customDates[0]) {
          this.$nextTick(() => {
            this.$set(this.customDates, 0, dateBegin)
          })
        }
      }

      if (this.customDateEnd) {
        let dateEnd = this.$date(this.customDateEnd, 'YYYY-MM-DD')
        if (!dateEnd.isValid()) {
          dateEnd = this.$date()
        }
        dateEnd = dateEnd.format('YYYY-MM-DD')
        if (dateEnd !== this.customDates[1]) {
          this.$nextTick(() => {
            this.$set(this.customDates, 1, dateEnd)
          })
        }
      }
    },
  },
  watch: {
    dashboardFilterDates: {
      handler: 'updateFilterFromStore',
      deep: true,
      immediate: true,
    },
    selectedFilter: {
      handler: 'updateDashboardFilter',
      deep: true,
      immediate: true,
    },
    customDateBegin: {
      handler: 'updateCustomDates',
    },
    customDateEnd: {
      handler: 'updateCustomDates',
    },
    customDates(newVal, oldVal) {
      if (this.customDates[0] && !this.customDates[1]) {
        const cDateBegin = this.$date(this.customDates[0], 'YYYY-MM-DD')

        this.customDateBegin = cDateBegin.format('YYYY-MM-DD')
        this.customDateEnd = null
      } else if (this.customDates[0] && this.customDates[1]) {
        const cDateBegin = this.$date(this.customDates[0], 'YYYY-MM-DD')
        const cDateEnd = this.$date(this.customDates[1], 'YYYY-MM-DD')

        let customDateBegin = this.$date
          .min(cDateBegin, cDateEnd)
          .format('YYYY-MM-DD')
        let customDateEnd = this.$date.max(cDateBegin, cDateEnd)
        customDateEnd = this.$date.min(customDateEnd, this.$date())

        this.customDateBegin = customDateBegin
        this.customDateEnd = customDateEnd.format('YYYY-MM-DD')

        if (
          this.customDateBegin !== this.selectedFilter.value.dateBegin ||
          this.customDateEnd !== this.selectedFilter.value.dateEnd
        ) {
          this.selectedFilter = this.customDateItem
        }
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@theme/colors.styl'

.v-list
  max-height 65vh
  overflow scroll
  .date-input
    max-width 100px
  .v-subheader
    height 25px !important
    text-transform uppercase
  .v-list-item
    height 25px !important
    min-height 25px !important
    &.active
      background-color var(--secondary) !important
      color white !important
  .custom-link
    cursor pointer
    &:hover
      background-color rgba(0, 0, 0, 0.12)
    &.active
      background-color var(--secondary) !important
      color white !important
.w-filter-dropdown
  width 100%
  height: 40px
  border-right 1px solid rgba(0, 0, 0, 0.12)
</style>
