<template>
    <WStatsSection class="pl-3 mt-2 mb-6 px-2 stat-section">
        <template #content>
            <InactiveCollaborators
                :alertingListLoading="alertingListLoading"
                :totalInactiveCollaborator="totalInactiveCollaborator"
                :activateFilter="activateFilter"
            />
            <PerimeterWithoutCollaborator
                :alertingListLoading="alertingListLoading"
                :totalEtsWihoutCollaborator="totalEtsWihoutCollaborator"
                :activateFilter="activateFilter"
            />            
        </template>
    </WStatsSection>
</template>
<script>
import InactiveCollaborators from './InactiveCollaborators'
import PerimeterWithoutCollaborator from './PerimeterWithoutCollaborator'
export default {
  name: "CollaboratorsAlerting",
  components: {
    InactiveCollaborators,
    PerimeterWithoutCollaborator,
  },
  props: {
    alertingListLoading:{
      type: Boolean,
      required: true,
    },
    totalInactiveCollaborator: {
        type: Number,
        required: false
    },
    totalEtsWihoutCollaborator: {
        type: Number,
        required: false
    },
    activateFilter: {
      type: Function,
      required: true,
    }
  },
  methods: {
    alertingFilterActivation(value) {
        this.activateFilter(value);
    }
  }
}
</script>