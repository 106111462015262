<template>
  <div class="question-zoom bg-white pa-3">
    <h1 class="fs-13 c-primary mobile-pointer" @click="goToQuestionList">
      <v-icon color="primary" class="pr-1" v-if="$vuetify.breakpoint.smAndDown">mdi-arrow-left</v-icon> {{ question.content }}
    </h1>
    <QcmQuestionZoom :question="question" :showTitle="this.showTitle" v-if="question.type == 'QcmQuestion'" />
  </div>
</template>

<script>  
  import QcmQuestionZoom from "./QcmQuestionZoom"

  export default {
    name: "QuestionZoom",
    props: {
      question: null, 
      showTitle: {
        default: true
      }
    },
    data() {
      return {
      }
    },
    components: {
      QcmQuestionZoom
    },
    methods: {
      goToQuestionList() {
        if (this.$vuetify.breakpoint.smAndDown) {
          this.$router.push({ params: {  sectionId: 'all' } })
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
 
</style>
