<template>
  <span  v-if="$staging" class="ml-3">
    <span class="f-22">{{ databaseData }}</span>
  </span>
</template>

<script>
import { database } from "@shared/helpers/env-helpers"

export default {
  name: 'EnvMenu',
  data() {
    return {
      databaseData: database
    }
  },
}

</script>
