<template>
  <div class="openai-preferences-prompt-help-section">
    <span>
      <span>
        {{ $t('openai_preferences_help_section_main_instruction') }}
      </span>
      <a class="text-decoration-underline c-dark" @click="open = !open">
        {{ helpTitle }}
      </a>
    </span>

    <div v-if="open">
      <p class="fb-14 mb-1 mt-2">
        {{ $t('openai_preferences_help_section_first_paragraph_title') }}
      </p>

      <p class="mb-1">
        {{ $t('openai_preferences_help_section_first_paragraph_instructions') }}
      </p>

      <ul>
        <li>
          <span>
            <span>
              Reviews ➜ « Google Reviews about {type d’établissement} »
            </span>
            <span>
              {{ $t('openai_preferences_help_section_first_paragraph_instructions_reviews') }}
            </span>
          </span>
        </li>
        <li>
          <span>
            <span>
              Customers ➜ « Patients »
            </span>
            <span>
              {{ $t('openai_preferences_help_section_first_paragraph_instructions_customers') }}
            </span>
          </span>
        </li>
        <li>
          <span>
            <span>
              Visit ➜ « Purchase »
            </span>
            <span>
              {{ $t('openai_preferences_help_section_first_paragraph_instructions_visits') }}
            </span>
          </span>
        </li>
        <li>
          <span>
            <span>
              Physical establishment & establishment ➜ « Shop »
            </span>
            <span>
              {{ $t('openai_preferences_help_section_first_paragraph_instructions_places') }}
            </span>
          </span>
        </li>
      </ul>

      <p class="mt-2 mb-1">
        {{ $t('openai_preferences_help_section_first_paragraph_grade_verbatim_review_title') }}
      </p>

      <p>
        {{ $t('openai_preferences_help_section_first_paragraph_grade_verbatim_review_example') }}
      </p>

      <p class="fb-14 mt-2 mb-1">
        {{ $t('openai_preferences_help_section_examples_title') }}
      </p>

      <p class="mb-1">
        {{ $t('openai_preferences_help_section_examples_instructions') }}
      </p>

      <span>
        <a class="text-decoration-underline c-dark"
          v-clipboard:copy="defaultPrompt"
        >
          {{ $t('openai_preferences_help_section_copy_default_prompt') }}
        </a>
        <span> - </span>
        <a
          class="text-decoration-underline c-dark"
          v-clipboard:copy="defaultPrompt"
        >
          {{ $t('openai_preferences_help_section_copy_example_prompt') }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'PromptHelpSection',
    components: {
    },
    data() {
      return {
        open: false,
        defaultPrompt: 'Hey je suis le default prompt',
        examplePrompt: 'Hey je suis le example prompt'
      }
    },
    computed: {
      ...mapGetters([
      ]),
      helpTitle() {
        return this.open ?
          this.$t('openai_preferences_help_section_main_instruction_link_close') :
          this.$t('openai_preferences_help_section_main_instruction_link_open')
      }
    }
  }
</script>
