<template>
  <v-row class="topics-page" no-gutters>
    <v-col>
      <template>
        <v-row
          no-gutters
        >
          <v-col>
            <SourcesTableWidget
              v-if="shouldDisplaySourcesTable"
              :cols="12"
              class="pt-0"
              :currentSource="source"
            />
          </v-col>
        </v-row>

        <v-row no-gutters class="px-3 mb-3" v-if="shouldDisplaySourceAutocomplete">
          <v-col cols='12'>
            <SourceAutocomplete
              class="d-flex"
              :source="source"
            />
          </v-col>
        </v-row>

        <v-row no-gutters v-if="source">
          <v-col cols="12">
            <SourceZoom
              class="pt-0"
              :source="source"
              :key="source"
            />
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import SourcesTableWidget from './SourcesTableWidget'
  import SourceAutocomplete from './SourceAutocomplete'
  import SourceZoom from './SourceZoom'

  export default {
    name: "SocialPage",
    props: ['source'],
    components: {
      SourceAutocomplete,
      SourcesTableWidget,
      SourceZoom
    },
    methods: {
      scrollToSourceZoom() {
        // this.$nextTick(function () {
        //   const scrollToElement = this.$refs?.sourceAutocomplete?.$el

        //   if (scrollToElement) {
        //     const topPos = scrollToElement.offsetTop - 10

        //     window.scrollTo({ top: topPos, behavior: 'smooth' })
        //   }
        // })
      }
    },
    computed: {
      ...mapGetters([
        'currentDashboardPublicPlatforms'
      ]),
      shouldDisplaySourcesTable() {
        return this.currentDashboardPublicPlatforms.length > 1
      },
      shouldDisplaySourceAutocomplete() {
        return this.currentDashboardPublicPlatforms.length > 1
      },
    },
    mounted() {
      this.$nextTick(function () {
        this.scrollToSourceZoom()
      })
    },
    watch: {
      source: function() {
        this.scrollToSourceZoom()
      }
    }
  }
</script>

<style lang="stylus" scoped>

</style>
