<template>
  <div>
    <v-data-table
      :server-items-length="voterAutomatedMessageTreatmentTotalCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100] }"
      :options.sync="options"
      v-show="!loading"
      :headers="headers"
      :items="voterAutomatedMessageTreatments"
      mobile-breakpoint="0"
      class="v-datatable-without-y-borders"
      :sort-by="['createdAt']"
      :sort-desc="[true]"
    >
      <template v-slot:item.placeName="{ item }">
        <div class="d-flex align-center">
          <v-icon class="f-14 c-grey">mdi-map-marker</v-icon>
          <span class="f-13">
            {{ item.placeName }}
          </span>
        </div>
      </template>
      <template v-slot:item.voterAuthor="{ item }">
        <div class="d-flex align-center">
          <v-icon small class="f-14 c-grey">mdi-account</v-icon>
          <span class="f-13">
            {{ item.voterAuthor }}
          </span>
          <v-icon @click="goToVoter(item)" small class="f-14 c-grey ml-2">mdi-open-in-new</v-icon>
        </div>
      </template>
      <template v-slot:item.voterVoteAt="{ item }">
        <div
          class="f-13"
          v-if="item.voterVoteAt"
        >
          {{ formatDate(item.voterVoteAt) }}
        </div>
      </template>
      <template v-slot:item.voterLanguage="{ item }">
        <div class="f-13">
          <w-flag-icon
            :locale="item.voterLanguage"
          />
        </div>
      </template>
      <template v-slot:item.voterAvgScore="{ item }">
        <div class="f-13">
          {{ item.voterAvgScore }}
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="d-flex align-center">
          <v-chip
            class="f-13"
            :color="statusColor(item.status)"
            label
            x-small
          >
            {{ item.status }}
          </v-chip>
          <v-tooltip
            v-if="item.status === 'canceled'"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-2"
                small
                v-bind="attrs"
                v-on="on"
              >
                mdi-information
              </v-icon>
            </template>
            <span>{{ item.cancelReason }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.conversationPreFilledMessageGroupName="{ item }">
        <div class="f-13">
          {{ item.conversationPreFilledMessageGroupName }}
        </div>
      </template>
      <template v-slot:item.messageContent="{ item }">
        <div class="f-13">
          {{ item.messageContent }}
        </div>
      </template>
      <template v-slot:item.processedAt="{ item }">
        <div
          class="f-13"
          v-if="item.processedAt"
        >
          {{ formatDate(item.processedAt) }}
        </div>
      </template>
    </v-data-table>
    <v-skeleton-loader v-show="loading" type="paragraph" />
  </div>
</template>
<script>
  import dayjs from 'dayjs'
  import { mapGetters } from 'vuex'
  import _debounce from 'lodash/debounce'

  import { sqlDate } from '@vue/plugins/helpers/dates'
  import AutomatedGrades from './../AutomatedGrades'

  export default {
    name: "Table",
    props: {
      filters: { required: true },
    },
    data() {
      return {
        options: {
          page: 1,
          itemsPerPage: 25,
        },
        loading: true,
        voterAutomatedMessageTreatments: [],
        voterAutomatedMessageTreatmentTotalCount: 0
      }
    },
    computed: {
      ...mapGetters([
        'currentCampaignPreferencesCampaignId',
        'currentCampaignPreferencesCampaignDashboardId',
        'currentLexicon'
      ]),
      headers() {
        return [
          {
            text: this.currentLexicon.translate('place', 'place'),
            value: 'placeName',
            cellClass: 'name-cell text-truncate',
            sortable: false
          },
          {
            text: this.$t('automated_message_treatments_voter_author'),
            value: 'voterAuthor',
            sortable: false
          },
          {
            text: this.$t('automated_message_treatments_voter_vote_at'),
            value: 'voterVoteAt',
            sortable: false
          },
          {
            text: this.$t('automated_message_treatments_voter_language'),
            value: 'voterLanguage',
            sortable: false
          },
          {
            text: this.$t('automated_message_treatments_voter_avg_score'),
            value: 'voterAvgScore',
            sortable: false
          },
          {
            text: this.$t('automated_message_treatments_status'),
            value: 'status',
            sortable: false
          },
          {
            text: this.$t('automated_message_treatments_conversation_pre_filled_message_group_name'),
            value: 'conversationPreFilledMessageGroupName',
            sortable: false
          },
          {
            text: this.$t('automated_message_treatments_message_content'),
            value: 'messageContent',
            sortable: false
          },
          {
            text: this.$t('automated_message_treatments_processed_at'),
            value: 'processedAt',
            sortable: false
          }
        ]
      }
    },
    methods: {
      goToVoter(item) {
        const url = `${window.location.protocol}//${window.location.host}/dashboard/mono/${this.currentCampaignPreferencesCampaignDashboardId}/feedbacks/all/all/${item.voterUniqueId}/review`

        window.open(url, '_blank')
      },
      async getVoterAutomatedMessageTreatmentTotalCount() {
        let req = this.$basedRequest()
          .select({
            voter_automated_message_treatments: [
              'total_count'
            ]
          })

        req = this.applyFilters(req)

        const voterAutomatedMessageTreatmentTotalCount = (await this.$resolve(req))?.first()?.totalCount || 0

        return voterAutomatedMessageTreatmentTotalCount
      },
      async getVoterAutomatedMessageTreatments() {
        let req = this.$basedRequest()
          .select({
            voter_automated_message_treatments: [
              'processed_at',
              'status',
              'cancel_reason',
              { 'places_name': { as: 'placeName' } },
              { 'lj_voters_author': { as: 'voterAuthor' } },
              { 'voters_unique_id': { as: 'voterUniqueId' } },
              { 'voters_vote_at': { as: 'voterVoteAt' } },
              { 'voters_language': { as: 'voterLanguage' } },
              { 'voters_avg_score': { as: 'voterAvgScore' } },
              { 'conversation_pre_filled_message_groups_name': { as: 'conversationPreFilledMessageGroupName' } },
              { 'messages_content': { as: 'MessageContent' } }
            ]
          }).offset(
            (this.options.page - 1) * this.options.itemsPerPage
          ).limit(
            this.options.itemsPerPage
          ).order([
            ['voters_vote_at', 'desc']
          ])

        req = this.applyFilters(req)

        const voterAutomatedMessageTreatments = (await this.$resolve(req))?.data?.voterAutomatedMessageTreatments || []

        return voterAutomatedMessageTreatments
      },
      refreshData: _debounce(async function() {
        this.loading = true
        this.voterAutomatedMessageTreatments = await this.getVoterAutomatedMessageTreatments()
        this.voterAutomatedMessageTreatmentTotalCount = await this.getVoterAutomatedMessageTreatmentTotalCount()
        this.loading = false
      }, 600),
      statusColor(status) {
        if (status === 'ok') {
          return 'success'
        }

        if (status === 'canceled') {
          return 'error'
        }

        return 'default'
      },
      formatDate(date) {
        return dayjs(date).locale(this.$i18n.locale).format('LL LTS')
      },
      applyFilters(request) {
        if (this.filters?.place) {
          request = request.where({
            'places_id': this.filters?.place?.id
          })
        }

        if (this.filters?.voterAuthor) {
          request = request.where({
            'lj_voters_author': { 'icontain': this.filters?.voterAuthor }
          })
        }

        if (this.filters?.status) {
          request = request.where({
            'status': this.filters?.status
          })
        }

        if (this.filters?.conversationPreFilledMessageGroup) {
          request = request.where({
            'conversation_pre_filled_message_groups_id': this.filters?.conversationPreFilledMessageGroup?.id
          })
        }

        request = request.where({
          campaigns_id: this.currentCampaignPreferencesCampaignId
        })

        request = request.dateBetween(
          dayjs().startOf('month').subtract(3, 'month'),
          sqlDate(this.$date().format('YYYY-MM-DD'), '23:59:59')
        )

        return request
      }
    },
    mounted() {
      this.refreshData()
    },
    watch: {
      filters: {
        handler: function (val) {
          this.options.page = 1
          this.refreshData()
        },
        deep: true
      },
      options: {
        handler: function (val) {
          this.refreshData()
        },
        deep: true
      }
    }
  }
</script>
