<template>
  <div
    class="voter-message"
  >
    <w-timeline-element
      left
    >
      <template v-slot:avatar>
        <VoterAvatar
          :voter="voter"
        />
      </template>

      <template v-slot:title>
        <div class="d-flex">
          <span
            v-if="voterName !== 'anonymized'"
            class="text-truncate fb-14 c-primary"
          >
            {{ voterName }}
          </span>
          <span
            v-else
            class="text-truncate fb-14 c-primary">
            xxxxx
          </span>
          <v-tooltip top>
            <template #activator="{on}">
              <span class="fb-14 c-primary" v-on="on">{{ createdAt }}</span>
            </template>
            {{ createdAtTooltip }}
          </v-tooltip>
        </div>
      </template>

      <template v-slot:actions>
        <w-drop-down-menu
          v-if="actions.length > 0"
          :items="actions"
          icon="mdi-dots-horizontal"
          :showArrow="false"
        />
      </template>

      <template v-slot:content>
        <div
          class="d-flex pa-4 rounded-lg bg-bodygrey"
        >
          <pre
            class="c-darkergrey message-content f-14"
          >
           {{messageContent}}
          </pre>
        </div>
      </template>

      <template v-slot:footer>
        <div
          v-if="isLastVoterMessage"
          class="d-flex ga-2 exclude-click-outside"
          dense
        >
          <v-btn
            color="grey darken-2"
            @click="closeFeedback"
            outlined
          >
            <v-icon class="mr-1">mdi-check</v-icon>
            {{ $t('close_feedback') }}
          </v-btn>
          <v-btn
            color="secondary"
            @click="answerFeedback"
          >
            <v-icon class="mr-1">mdi-chat-outline</v-icon>
            {{ $t('answer') }}
          </v-btn>
        </div>
      </template>
    </w-timeline-element>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import VoterMixin from "../../../voter_mixin";
  import VoterAvatar from '../../VoterAvatar'

  export default {
    name: "message",
    mixins: [
      VoterMixin
    ],
    components: {
      VoterAvatar
    },
    props: {
      message: { required: true },
      voter: { required: true },
      isLastVoterMessage: {
        type: Boolean,
        required: false
      },
    },
    data () {
      return {
        conversationBlockStatus: null
      }
    },
    mounted() {
      this.conversationBlockStatus = this.voter.conversation.blocked
    },
    computed: {
      ...mapGetters([
        'currentUser',
        'currentVoterEditingMessage',
        'currentUserNeedValidationForCampaign',
        'currentVoterShowActions',
        'currentLexicon',
        'voterTrustvilleReportable',
        'mainCampaign',
        'currentUserBrandName'
      ]),
      createdAt() {
        return this.$t('voter_timeline_element_created_at', { date: this.$helpers.dates.short(this.message.createdAt, false) })
      },
      createdAtTooltip() {
        return this.$helpers.dates.short(this.message.createdAt, true)
      },
      blockConversationLabel() {
        return this.conversationBlockStatus ? this.$t('unblock_conversation') : this.$t('block_conversation')
      },
      blockConversationClass() {
        return this.conversationBlockStatus ? 'blocked' : 'unblocked'
      },
      actions() {
        let actions = []

        if (this.isLastVoterMessage) {
          actions.push(
            {
              title: this.blockConversationLabel,
              onClick: this.blockConversation,
              class: this.blockConversationClass,
              icon: 'mdi-block-helper'
            }
          )
        }
        return actions
      },
      messageContent() {
        const footerSeparator = ' - ';
        const splittedContent = this.message.content.split(footerSeparator);
        const splittedContentLastElement = splittedContent[splittedContent.length - 1];

        return this.message.source === 'sms' && splittedContent.length > 1 && splittedContentLastElement.includes('http') ?
          this.message.content.slice(0, - splittedContent[splittedContent.length - 1].length - footerSeparator.length) :
          this.message.content;
      }
    },
    methods: {
      async blockConversation() {
        try {
          const response = await this.$api.wizville.conversations.update(
            this.voter.uniqueId,
            this.voter.conversation.id,
            { blocked: !this.conversationBlockStatus }
          )

          if (response.status === "ok") {
            this.conversationBlockStatus = response.blocked;
            this.$store.dispatch(
              'notifySuccess',
              {
                message: this.conversationBlockStatus ? this.$t('flash_message_succesfully_blocked_conversation') : this.$t('flash_message_succesfully_unblocked_conversation'),
                timeout: 5000
              }
            )
          }
        } catch (error) {
          this.$store.dispatch('notifyError', { message: error.message })
        }
      },
      answerFeedback() {
        this.$store.dispatch('setActionsTab', 'send_message')
        this.$store.dispatch('setShowVoterActions', true)
      },
      closeFeedback() {
        this.$store.dispatch('setActionsTab', 'close_feedback')
        this.$store.dispatch('setShowVoterActions', true)
      }
    }
  }
</script>

<style lang='stylus'>
  .voter-message
    .message-content
      white-space: pre-line
</style>
