<template>
  <WStatsWidget
    class="categories-product-widget"
    :title="$t('categories_products_widget_title')"
    :cols="12"
    contentWidth="100%"
    :justifyCenter="false"
  >
    <template #options>
      <w-drop-down-menu
        v-if="currentModeMenuItem"
        v-model="currentMode"
        :items="modeMenuItems"
        :title="currentModeMenuItem.title"
        :icon="currentModeMenuItem.icon"
      />
    </template>
    <template #title>
      <div class="d-flex">
        <Breadcrumb
          v-if="categoryId"
          :productCategoriesById="productCategoriesById"
          :campaign="campaign"
          :mode="currentMode"
          :categoryId="categoryId"
          :applyNavigationFilters="applyNavigationFilters"
        />
        <span v-else>{{ $t('categories_products_widget_title') }}</span>
      </div>
    </template>

    <template #subtitle>
      <div class="d-flex align-center ga-2">
        <span>{{displayedSubtitle}}</span>
        <div class="d-flex align-center ga-1">
          <v-icon class="f-10">mdi-shape-outline</v-icon>
          <span>{{ displayedCurrentCategoryNbChildren }}</span>
        </div>
        <div class="d-flex align-center ga-1">
          <v-icon class="f-10">mdi-cart-outline</v-icon>
          <span>{{ displayedCurrentCategoryNbProducts }}</span>
        </div>
      </div>
    </template>

    <template #content>
      <CategoriesTable
        class="pb-1"
        v-if="currentMode === 'product_categories'"
        :topics="topics"
        :categoryId="categoryId"
        :campaign="campaign"
        :applyNavigationFilters="applyNavigationFilters"
      />
      <ProductsTable
        v-if="currentMode === 'products'"
        :categoryId="categoryId"
        :topics="topics"
        :campaign="campaign"
        :applyNavigationFilters="applyNavigationFilters"
      />
    </template>
  </WStatsWidget>
</template>

<script>
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import Breadcrumb from './Breadcrumb'
  import CategoriesTable from './CategoriesTable'
  import ProductsTable from './ProductsTable'
  import { mapGetters } from 'vuex'
  import _keyBy from 'lodash/keyBy'

  export default {
    name: "CategoriesProductsWidget",
    mixins: [
      WidgetMixin
    ],
    props: {
      mode: { type: String, required: false },
      campaign: { required: true },
      categoryId: { type: Number, required: false }
    },
    components: {
      Breadcrumb,
      CategoriesTable,
      ProductsTable
    },
    data() {
      return {
        zoomedCategory: null,
        zoomedCategoryProducts: null,
        loadingTopics: true
      }
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest',
        'dashboardFilterProductCategoryIds',
        'dashboardFilterProductIds'
      ]),
      displayedCurrentCategoryNbChildren() {
        return this.$tc(
          'categories_product_widget_current_category_nb_children',
          this.currentCategoryNbChildren
        )
      },
      displayedCurrentCategoryNbProducts() {
        return this.$tc(
          'categories_product_widget_current_category_nb_products',
          this.currentCategoryNbProducts
        )
      },
      productCategoriesById() {
        return _keyBy(this.productCategories, i => i.categoryId)
      },
      currentModeMenuItem() {
        return this.modeMenuItems.find(item => item.value === this.currentMode)
      },
      currentMode: {
        get() {
          return this.currentCategoryNbChildren == undefined ? 'none' : 
            this.currentCategoryNbChildren > 0 ? this.mode : 'products'
        },
        set(val) {
          this.$router.push({
            name: "Products",
            params: {
              mode: val,
              productCategoryId: this.categoryId
            }
          })
        }
      },
      applyNavigationFilters() {
        return this.dashboardFilterProductCategoryIds === null &&
          this.dashboardFilterProductIds === null
      },
      modeMenuItems() {
        const items = [
          {
            title: this.$t('categories_products_widget_product_categories_mode_title'),
            value: 'product_categories',
            icon: 'mdi-shape-outline',
            displayCondition: this.currentCategoryNbChildren > 0
          },
          {
            title: this.$t('categories_products_widget_products_mode_title'),
            value: 'products',
            icon: 'mdi-cart-outline'
          }
        ]

        return items.filter((item) => item.displayCondition === undefined || item.displayCondition)
      }
    },
    asyncComputed: {
      currentCategoryNbChildren: {
        async get() {
          let request = this.dashboardFilterRequest.select({
            voters: [
              { COUNT_DISTINCT_product_category_id: { as: 'nbChildren'}}
            ]
          }).where({
            campaign_id: this.campaign.id,
            avg_score: { 'is_not_null': {} }
          })

          if (this.applyNavigationFilters) {
            request = request.where({
              category_parent_id: this.categoryId || null,
            })
          }

          let res = (await this.$resolve(request))?.first()?.nbChildren || 0

          return res
        }
      },
      currentCategoryNbProducts: {
        async get() {
          let request = this.dashboardFilterRequest.select({
            voters: [
              { COUNT_DISTINCT_product_id: { as: 'nbProducts' }}
            ]
          }).where({
            campaign_id: this.campaign.id,
            avg_score: { 'is_not_null': {} }
          })

          if (this.applyNavigationFilters) {
            if (this.categoryId == null || this.categoryId == undefined) {
              request = request.where({ product_category_level: 0 })
            } else {
              request = request.where({
                product_category_id: this.categoryId
              })
            }
          }

          return (await this.$resolve(request))?.first()?.nbProducts || 0
        }
      },
      productCategories: {
        async get() {
          const request = this.$basedRequest().select({
            product_categories: [
              'category_id',
              'category_name',
              'category_parent_id',
              'category_type_level'
            ]
          }).where({
            campaign_id: this.campaign.id
          })

          const result = (await this.$resolve(request))?.data?.productCategories || []

          return result
        }
      },
      topics: {
        async get() {
          this.loadingTopics = true
          const request = this.$basedRequest().select({
            product_sub_questions: [
              { MAX_topic_name: { as: 'topicName' }},
              { topic_id: { as: 'topicId' }},
              { MAX_display_type: { as: 'displayType' }},
              { MAX_rate_labels: { as: 'rateLabels' }}
            ]
          }).group(
            ['topic_id']
          ).where({
            campaign_id: this.campaign.id
          }).order(
            [
              ['topic_id', "desc"],
            ]
          )

          const result = (await this.$resolve(request)).first() || []

          this.loadingTopics = false

          return result
        }
      }
    }
  }
</script>

<style lang="stylus">
</style>
