<template>
  <div>
    <WStatsWidget
      :cols="12"
      contentWidth="100%"
      :justifyCenter="false"
    >
      <template #content>
        <AchievementTable
          :user-id="userId"
          :height="'calc(100vh - 160px)'"
        />
      </template>
    </WStatsWidget>
  </div>
</template>

<script>
  import AchievementTable from '../AchievementTable'

  export default {
    name: 'MyAchievementsPage',
    props: [ 'userId' ],
    components: { AchievementTable }
  }
</script>
