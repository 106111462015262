import $api from '@api'
import { i18n } from '@i18n/setup'
import { setTimeZone } from '@vue/plugins/helpers/dates'
import { Lexicon } from '@i18n/lexicon'

const userContext = {
  state: () => ({
    loading: false,
    loaded: null,
    id: null,
    digest: null,
    timeZone: null,
    brandId: null,
    shouldSetupPassword: false,
    brandName: null,
    createdAt: null,
    quarterOffset: 0,
    locale: null,
    alk: null,
    mail: null,
    isAdmin: false,
    userGroupId: null,
    userLogo: null,
    dashboards: [],
    presenceManagementPreference: null,
    lrmBrandPreferenceEnabled: false,
    customerAnswerRightByCampaign: {},
    scoreIndicatorByCampaigns: {},
    dashboardPreference: { priorityForDashboardMulti: false, rankingPlacesVisibility: 'mine' },
    feedbackContent: null,
    rightPreference: {},
    isBrandNeedValidation: false,
    useStrongPassword: false,
    appVersion: null,
    lexicon: null
  }),
  getters: {
    isLoading(state) {
      return state.loading
    },
    currentUserNeedValidationForCampaign(state) {
      return (campaignId) => {
        return state.customerAnswerRightByCampaign[campaignId] === 'need_validation'
      }
    },
    isCurrentUserBrandNeedValidation(state) {
      return state.isBrandNeedValidation
    },
    currentUserCustomerAnswerRightByCampaign(state) {
      return state.customerAnswerRightByCampaign
    },
    currentUserCampaignScoreIndicator(state, getters) {
      return (campaignId) => {
        const indicator = state.scoreIndicatorByCampaigns[campaignId]

        if (["avg_satisfaction"].includes(indicator?.formulaToColumn)) {
          return indicator
        } else {
          return {
            id: 'satisfaction',
            indicatorType: 'campaignScoreIndicator',
            formulaToColumn:  "avg_satisfaction",
            formulaToName: (getters.currentDashboardCampaignWithId(campaignId)?.avgScoreName || i18n.t('average_note')),
          }
        }
      }
    },
    lrmBrandPreferenceEnabled(state) {
      return state.lrmBrandPreferenceEnabled
    },
    currentUser(state) {
      return state
    },
    currentUserLogoUrl(state) {
      return state.logoUrl
    },
    currentUserUseStrongPassword(state) {
      return state.useStrongPassword
    },
    currentUserId(state) {
      return state.id
    },
    currentUserIsAdmin(state) {
      return state.isAdmin
    },
    currentUserShouldSetupPassword(state) {
      return state.shouldSetupPassword
    },
    currentUserLocale(state) {
      return state.locale
    },
    currentUserBrandName(state) {
      return state.brandName
    },
    currentUserGroupId(state) {
      return state.userGroupId
    },
    currentUserFeedbackContent(state) {
      return state.feedbackContent
    },
    priorityForDashboardMulti(state) {
      return (state?.dashboardPreference?.priorityForDashboardMulti || false)
    },
    isDemoUser(state) {
      return state.mail?.match(/(\+demo)|(demo\+)/)
    },
    appVersion(state) {
      return state.appVersion
    },
    dashboards(state) {
      return state.dashboards
    },
    lrmDashboards(state) {
      return state.dashboards.filter(
        (dashboard) =>
          dashboard.type === 'DashboardBasic' && dashboard.isLrmEligible
      )
    },
    presenceManagementPreference(state) {
      return state.presenceManagementPreference
    },
    activeDashboardBasics(state, getters) {
      const activeDashboardBasics = state.dashboards.filter(
        (dashboard) => dashboard.type === 'DashboardBasic' && dashboard.active
      )
      return activeDashboardBasics
    },
    inactiveDashboardBasics(state, getters) {
      const inactiveDashboardBasics = state.dashboards.filter(
        (dashboard) => dashboard.type === 'DashboardBasic' && !dashboard.active
      )
      return inactiveDashboardBasics
    },
    activeDashboardMultis(state, getters) {
      const activeDashboardMultis = state.dashboards.filter(
        (dashboard) => dashboard.type === 'DashboardMulti' && dashboard.active
      )
      return activeDashboardMultis
    },
    hasAccessToNewPlatformFeedbackTab(state, getters) {
      return !!(state.isAdmin || getters.currentAdmin !== null || state?.rightPreference?.accessToNewPlatformFeedbackTab)
    },
    hasAccessToNewPlatformLocalInsightTab(state, getters) {
      return !!(state.isAdmin || getters.currentAdmin !== null || state?.rightPreference?.accessToNewPlatformLocalInsightTab)
    },
    hasAccessToNewPlatformRankingTab(state, getters) {
      return !!(state.isAdmin || getters.currentAdmin !== null || state?.rightPreference?.accessToNewPlatformRankingTab)
    },
    hasRightEditAllCampaigns(state, getters) {
      return !!(state.isAdmin || getters.currentAdmin !== null || state?.rightPreference?.rightEditAllCampaigns)
    },
    hasRightToShareAchievements (state, getters) {
      return !!(state.isAdmin || getters.currentAdmin !== null || state?.rightPreference?.rightToShareAchievements)
    },
    hasAccessToNewPlatformStatisticTab(state, getters) {
      return !!(state.isAdmin || getters.currentAdmin !== null || state?.rightPreference?.accessToNewPlatformStatisticTab)
    },
    hasAccessToNewPlatformAchievementsTab(state, getters) {
      return !!(state.isAdmin || getters.currentAdmin !== null || state?.rightPreference?.accessToNewPlatformAchievementsTab)
    },
    hasAccessToNewPlatformStatisticVerbatimAnalysis(state, getters) {
      return !!(state.isAdmin || getters.currentAdmin !== null || state?.rightPreference?.accessToNewPlatformStatisticVerbatimAnalysis)
    },
    hasAccessToCollaboratorManagementTab(state, getters) {
      return !!(state.isAdmin || getters.currentAdmin !== null || state?.rightPreference.rightManageCollaborators)
    },
    hasRightEditCollaborators(state, getters) {
      return !!(getters.currentAdmin !== null || state?.rightPreference?.rightEditCollaborators)
    },
    hasAccessToNewPlatformNisFeature(state, getters) {
      return !!(
        state.isAdmin ||
        getters.currentAdmin !== null ||
        state?.rightPreference?.accessToNewPlatformNisFeature
      )
    },
    ssoOnly(state) {
      return state?.rightPreference?.ssoOnly
    },
    hasRightUseFilters(state, getters) {
      return !!(state.isAdmin || getters.currentAdmin !== null || state?.rightPreference?.rightUseFilters)
    },
    hasRightRedirectToNewPlatform(state, getters) {
      return !!(state.isAdmin || getters.currentAdmin !== null || state?.rightPreference?.redirectToNewPlatform)
    },
    hasAccessToOldPlatform(state, getters) {
      return !!(state.isAdmin || getters.currentAdmin !== null || state?.rightPreference?.accessToNewPlatform)
    },
    hasRightClassifyVerbatims(state, getters) {
      return !!(state.isAdmin || getters.currentAdmin !== null || state?.rightPreference?.rightClassifyVerbatims)
    },
    hasRightSmartAnswer(state, getters) {
      return !!(state.isAdmin || getters.currentAdmin !== null || state?.rightPreference?.rightSmartAnswer)
    },
    customerAnswerRightByCampaign(state) {
      return state?.customerAnswerRightByCampaign
    },
    rightAnswerCustomersChannels(state) {
      return state?.rightPreference?.rightAnswerCustomersChannels
    },
    rankingPlacesVisibilityRestricted(state) {
      return !!(state.dashboardPreference?.rankingPlacesVisibility === 'mine')
    },
    brandLexicon(state) {
      return new Lexicon(state.lexicon)
    },
    currentLexicon(state, getters) {
      return getters.dashboardLexicon ?? getters.brandLexicon
    },
    isLcl(state) {
      return state.brandId === 777
    }
  },
  mutations: {
    setUserContext(state, data) {
      state.loaded = Date.now()
      state.id = data.id
      state.digest = data.digest
      state.mail = data.mail
      state.timeZone = data.timeZone
      setTimeZone(data.timeZone)
      state.locale = data.locale
      state.brandId = data.brandId
      state.brandName = data.brandName
      state.createdAt = data.createdAt
      state.shouldSetupPassword = data.shouldSetupPassword
      state.useStrongPassword = data.useStrongPassword
      state.alk = data.alk
      state.userGroupId = data.userGroupId
      state.isAdmin = data.isAdmin
      state.quarterOffset = data.quarterOffset
      state.userLogo = data.userLogo
      state.dashboards = data.dashboards
      state.rightPreference = data.rightPreference
      state.dashboardPreference = data.dashboardPreference
      state.presenceManagementPreference = data.presenceManagementPreference
      state.lrmBrandPreferenceEnabled = data.lrmBrandPreferenceEnabled
      state.customerAnswerRightByCampaign = data.customerAnswerRightByCampaign
      state.feedbackContent = data.feedbackContent
      state.isBrandNeedValidation = data.isBrandNeedValidation
      state.scoreIndicatorByCampaigns = data.scoreIndicatorByCampaigns
      state.lexicon = data.lexicon
      state.appVersion = data.appVersion
    },
    setFeedbackContent(state, value) {
      state.feedbackContent = value
    },
    setLoading(state, loading) {
      state.loading = loading
    },
  },
  actions: {
    async userContext(context) {
      const digest = localStorage.getItem('wizvilleSessionToken')

      if (digest !== context.state.digest && !context.state.loading) {
        context.commit('setLoading', true)
        const ctx = await $api.wizville.contexts.authUser()

        if (ctx?.admin) {
          context.commit('setAdminContext', ctx.admin)
        }

        if (ctx?.user) {
          context.commit('setUserContext', { digest, ...ctx.user })
          context.dispatch('setCurrentGroupSemantic', ctx.user.brandId)
        }
        context.commit('setLoading', false)
      }
    },
    setFeedbackContent(context, value) {
      context.commit('setFeedbackContent', value)
    },
  },
}

export default userContext

