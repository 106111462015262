import ApiCall from '@api/apiCall.js'

async function updateTranslation(translationId, value, locale) {
  const response = await new ApiCall().post(
    '/api/wizville/translations_admin/update_translation',
    { translation_id: translationId, value, locale }
  )
  return response.data
}

export { updateTranslation }
