<template>
  <v-row no-gutters class="feedbacks px-bloc mt-4">
    <v-col class="pb-bloc feedbacks">
      <v-row
        no-gutters
        v-show="!$vuetify.breakpoint.smAndDown || !currentVoter || currentVoter.collapsed"
        class="feedbacks-nav-tab"
        :style="{ borderBottom: ($vuetify.breakpoint.smAndDown ? '1px solid #fff' : 'none') }"
      >
        <v-col cols="12">

        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
          v-show="!(currentVoter && !currentVoter.collapsed && $vuetify.breakpoint.smAndDown)"
        >
          <VoterList
            :status="status"
            :subStatus="subStatus"
          />
        </v-col>

        <v-col
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 8"
          class="pl-md-4"
          v-if="currentVoter && !currentVoter.collapsed"
        >
          <VoterDetail
            :voter="currentVoter"
            :key="currentVoter.uniqueId"
            :section="section"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import VoterList from './VoterList'
  import VoterDetail from './VoterDetail'

  import { mapGetters } from 'vuex'

  export default {
    name: "FeedbacksPage",
    components: {
      VoterList,
      VoterDetail
    },
    props: {
      status: {
        type: String,
        default: 'all'
      },
      subStatus: {
        type: String,
        default: 'all'
      },
      section: {
        type: String,
        default: 'review'
      },
    },
    computed: {
      ...mapGetters([
        'currentVoter',
      ]),
    },
  }
</script>
