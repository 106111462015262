import dayjs from 'dayjs'
import { sqlDate } from '@vue/plugins/helpers/dates'

const dates = {
  state: () => ({
    selectedDates: {},
  }),
  getters: {
    dashboardFilterDates(state, getters) {
      return state.selectedDates
    },
    dashboardFilterDatesText(state, getters) {
      return state.selectedDates?.text
    },
    dashboardFilterUnit(state, getters) {
      return extractKeyUnit(
        state?.selectedDates?.value?.key,
        state?.selectedDates?.value?.dateBegin,
        state?.selectedDates?.value?.dateEnd
      )
    },
    dashboardFilterUnits(state, getters) {
      return getters.dashboardFilterUnit + 's'
    },
    sqlDateBegin(state, getters) {
      return sqlDate(state?.selectedDates?.value?.dateBegin)
    },
    sqlDateEnd(state, getters) {
      return sqlDate(state?.selectedDates?.value?.dateEnd, "23:59:59")
    },
    sqlComparedDateBegin(state, getters) {
      return (getters?.datesScope?.key === 'genesis' ? null : sqlDate(getters?.datesScope?.comparedDateBegin))
    },
    sqlComparedDateEnd(state, getters) {
      return (getters?.datesScope?.key === 'genesis' ? null : sqlDate(getters?.datesScope?.comparedDateEnd, "23:59:59"))
    },
    sqlDatesScope(state, getters) {
      return {
        key: getters?.datesScope?.key,
        dateBegin: getters?.sqlDateBegin,
        dateEnd: getters?.sqlDateEnd,
        comparedDateBegin: getters?.sqlComparedDateBegin,
        comparedDateEnd: getters?.sqlComparedDateEnd
      }
    },
    datesScope(state, getters) {
      const dateBegin = state?.selectedDates?.value?.dateBegin
      const dateEnd = state?.selectedDates?.value?.dateEnd
      const key = state?.selectedDates?.value?.key
      let comparedDateBegin = null
      let comparedDateEnd = null

      if (dateBegin && dateEnd) {
        const date1 = dayjs(dateBegin, 'YYYY-MM-DD')
        const date2 = dayjs(dateEnd, 'YYYY-MM-DD')
        const unit = getters.dashboardFilterUnit
        const units = getters.dashboardFilterUnits

        const diff = date2.diff(date1.subtract(1, 'day'), unit)
        const smartDiff = diff == 0 && date1 != date2 ? 1 : diff

        if (key === 'last_12_months') {
          comparedDateBegin = date1.subtract(smartDiff, units).format('YYYY-MM-DD')
          comparedDateEnd = date2.subtract(smartDiff, units).subtract(1, 'day').format('YYYY-MM-DD')
        } else {
          comparedDateBegin = date1.subtract(smartDiff, units).format('YYYY-MM-DD')
          comparedDateEnd = date1.subtract(1, 'day').format('YYYY-MM-DD')
        }
      }

      return {
        key: key,
        dateBegin: dateBegin,
        dateEnd: dateEnd,
        comparedDateBegin: comparedDateBegin,
        comparedDateEnd: comparedDateEnd,
      }
    },
  },
  mutations: {
    setDashboardDatesFilters(state, dates) {
      state.selectedDates = { ...dates }
    },
  },
  actions: {
    async setDashboardDatesFilters({ commit }, filters) {
      if (filters.dates) {
        commit('setDashboardDatesFilters', filters.dates)
      }
    },
  },
}

function extractKeyUnit(key, dateBegin, dateEnd) {
  if (key?.match(/year/)) {
    return 'year'
  } else if (key?.match(/quarter/)) {
    return 'quarter'
  } else if (key?.match(/month/)) {
    return 'month'
  } else if (key?.match(/week/)) {
    return 'week'
  } else if (key?.match(/day/)) {
    return 'day'
  } else {
    if (!dateBegin || !dateEnd) {
      return 'month'
    }

    const date1 = dayjs(dateBegin, 'YYYY-MM-DD')
    const date2 = dayjs(dateEnd, 'YYYY-MM-DD')
    const diff = date2.diff(date1, 'day')

    if (diff < 15) {
      return 'day'
    } else if (diff < 31) {
      return 'week'
    } else if (diff < 365) {
      return 'month'
    } else {
      return 'year'
    }
  }
}

export default dates
