<template>
  <PlaceRankingWidget
    :title="title"
    :subtitle="displayedSubtitle"
    :campaign="campaign"
    :basedRequest="basedRequest"
    :indicatorColumn="indicatorColumn"
    :indicatorAlias="indicatorAlias"
    :cols="cols"
    :displayRank="false"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import PlaceRankingWidget from '@statistics/shared/widgets/PlaceRankingWidget'

  export default {
    name: "NpsPlaceRankingWidget",
    components: {
      PlaceRankingWidget
    },
    mixins: [
      WidgetMixin
    ],
    props: {
      campaign: { type: Object },
      cols: { type: Number, default: 12 },
    },
    computed: {
      ...mapGetters([
        'groupRankingName',
        'groupRankingPlaceIds',
        'networkRankingName',
        'networkRankingPlaceIds',
        'dashboardFilterReady',
        'dashboardFilterRequest',
        'datesScope',
        'placeIdsScope',
        'currentDashboardScopedCampaigns',
        'dashboardFilterDates',
        'placeIds'
      ]),
      title() {
        return `${this.$t('ranking')}`
      },
      basedRequest() {
        return this.dashboardFilterRequest.select({
          brand_voters: [
            this.indicatorColumn,
            { nb_review: { as: 'nbReview' } },
            { place_campaigns_place_id: { as: 'placeId' } },
            { MAX_place_name: { as: 'placeName' } }
          ]
        })
      },
      indicatorColumn() {
        return { avg_nps: { as: this.indicatorAlias } }
      },
      indicatorAlias() {
        return 'score'
      }
    }
  }
</script>
