<template>
  <v-list class="pt-0 d-flex flex-column flex-section" dense>
    <v-progress-linear v-if="loading" indeterminate color="primary" />

    <v-col class="flex-grow-0">
      <v-text-field
        v-model="debouncedSearch"
        :label="$t('search')"
        append-icon="mdi-magnify"
        autofocus
        outlined
        dense
        hide-details
      />
    </v-col>
    <v-list-item-group class="overflow-section flex-grow-1">
      <v-list-item
        v-for="item in filteredSearch"
        :key="item.text"
        class="f-15"
        :href=item.href
        dense
      >
        <span class="text-truncate">{{ item.title }}</span>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'

import _debounce from 'lodash/debounce'
import _chunk from 'lodash/chunk'

export default {
  name: 'DashboardsForm',
  components: {},
  data() {
    return {
      menuItems: {
        icon: "mdi-clipboard-edit-outline",
        title: "dashboard_navigation_app_bar_dashboard_menu_dashboards",
        children: []
      },
      search: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'currentDashboard',
      'currentAdmin',
      'currentUser',
      'activeDashboardBasics',
      'activeDashboardMultis'
    ]),
    debouncedSearch: {
      get() {
        return this.search
      },
      set: _debounce(function (val) {
        this.search = val
      }, 200),
    },
    filteredSearch: function() {
      var self = this
      const filtered = this.menuItems.children.filter(item => self.search == '' || item.title.toLowerCase().indexOf(self.search.toLowerCase()) >= 0);
      return filtered;
    }
  },
  async created() {
    let items = []
    let childrenItems = []

    if (this.activeDashboardMultis.length > 0) {
      const multiLabel = this.$t("dashboard_navigation_app_bar_dashboard_menu_dashboard_multi")

      items.push({ header: multiLabel })
      this.activeDashboardMultis.forEach(dashboard => {
        let path = `multi/${dashboard.id}`
        items.push({
          text: dashboard.name,
          value: path,
          group: multiLabel
        })

        childrenItems.push({
          title: dashboard.name,
          href: `/dashboard/${path}?href=_self`
        })
      })
    }

    if (this.activeDashboardBasics.length > 0) {
      const monoLabel = this.$t("dashboard_navigation_app_bar_dashboard_menu_dashboard_mono")

      if (this.activeDashboardMultis.length > 0) {
        items.push({ header: monoLabel })
      }

      this.activeDashboardBasics.forEach(dashboard => {
        let path = `mono/${dashboard.id}`
        items.push({
          text: dashboard.name,
          value: path,
          group: monoLabel
        })

        childrenItems.push({
          title: dashboard.name,
          href: `/dashboard/${path}?href=_self`
        })
      })
    }
    this.menuItems.children = childrenItems

    return items
  },
}

</script>

<style lang="stylus" scoped>
  .flex-section
    flex: 0 1 auto
    min-height: 0px
  .overflow-section
      overflow-y: auto
</style>
