<template>
  <div class="d-flex align-center">
    <v-combobox
      v-model="value.place"
      :items="availablePlaces"
      item-text="name"
      item-value="id"
      :label="this.currentLexicon.translate('place', 'place')"
      chips
      outlined
      dense
    >
      <template v-slot:selection="data">
        <v-chip
          label
          small
          :key="JSON.stringify(data.item)"
          v-bind="data.attrs"
          :input-value="data.selected"
          :disabled="data.disabled"
          @click:close="data.parent.selectItem(data.item)"
        >
          <v-icon class="f-14 c-grey">mdi-map-marker</v-icon>
          {{ data.item.name }}
        </v-chip>
      </template>
      <template v-slot:item="{ index, item }">
        <v-icon class="f-14 c-grey">mdi-map-marker</v-icon>
          {{ item.name }}
      </template>
    </v-combobox>
    <v-text-field
      class="ml-2"
      v-model="value.voterAuthor"
      :label="$t('automated_message_treatments_voter_author')"
      outlined
      dense
    />
    <v-combobox
      v-model="value.status"
      class="ml-2"
      :items="['scheduled', 'canceled', 'ok']"
      :label="$t('automated_message_treatments_status')"
      chips
      outlined
      dense
    >
      <template v-slot:selection="data">
        <v-chip
          label
          small
          :color="statusColor(data.item)"
          :key="JSON.stringify(data.item)"
          v-bind="data.attrs"
          :input-value="data.selected"
          :disabled="data.disabled"
          @click:close="data.parent.selectItem(data.item)"
        >
          {{ data.item }}
        </v-chip>
      </template>
      <template v-slot:item="{ index, item }">
        <v-chip
          label
          small
          :color="statusColor(item)"
        >
          {{ item }}
        </v-chip>
      </template>
    </v-combobox>
    <v-combobox
      v-model="value.conversationPreFilledMessageGroup"
      class="ml-2"
      :items="availableConversationPreFilledMessageGroups"
      item-text="name"
      item-value="id"
      :label="$t('automated_message_treatments_conversation_pre_filled_message_group_name')"
      chips
      outlined
      dense
    >
      <template v-slot:selection="data">
        <v-chip
          label
          small
          :key="JSON.stringify(data.item)"
          v-bind="data.attrs"
          :input-value="data.selected"
          :disabled="data.disabled"
          @click:close="data.parent.selectItem(data.item)"
        >
          <v-icon class="f-14 c-grey">mdi-view-sequential</v-icon>
          {{ data.item.name }}
        </v-chip>
      </template>
      <template v-slot:item="{ index, item }">
        <v-icon class="f-14 c-grey">mdi-view-sequential</v-icon>
          {{ item.name }}
      </template>
    </v-combobox>
  </div>
</template>
<script>
  import dayjs from 'dayjs'
  import { mapGetters } from 'vuex'

  export default {
    name: "Filters",
    computed: {
      ...mapGetters([
        'currentCampaignPreferencesCampaignId',
        'currentLexicon'
      ])
    },
    props: {
      value: {
        required: true
      }
    },
    asyncComputed: {
      availablePlaces: {
        default: [],
        async get() {
          const request = this.$basedRequest()
          .select({
            place_campaigns: [
              { 'place_id': { as: 'id' } },
              { 'places_name': { as: 'name' } }
            ],
          })
          .where({
            campaigns_id: this.currentCampaignPreferencesCampaignId
          }).order([
            ['places_name', 'asc'],
          ])

          const availablePlaces = (await this.$resolve(request))?.data?.placeCampaigns || []

          return availablePlaces
        }
      },
      availableConversationPreFilledMessageGroups: {
        default: [],
        async get() {
          const request = this.$basedRequest().select({
            conversation_pre_filled_message_groups: [
              'id',
              'name'
            ]
          }).where({
            campaign_id: this.currentCampaignPreferencesCampaignId
          }).order([
            ['name', 'asc'],
          ])

          const conversationPreFilledMessageGroups = (await this.$resolve(request))?.data?.conversationPreFilledMessageGroups || []

          return conversationPreFilledMessageGroups
        }
      }
    },
    methods: {
      statusColor(status) {
        if (status === 'ok') {
          return 'success'
        }

        if (status === 'canceled') {
          return 'error'
        }

        return 'default'
      }
    }
  }
</script>
