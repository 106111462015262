import { GroupSemantic } from '@i18n/groupSemantic'
import BasedQuery from '@api/based/basedQuery'

const groupSemantics = {
  name: 'groupSemantics',
  state: () => ({
    groupSemantic: {},
  }),
  getters: {
    currentGroupSemantic(state) {
      return new GroupSemantic(state.groupSemantic)
    },
  },
  mutations: {
    SET_GROUP_SEMANTIC(state, groupSemantic) {
      state.groupSemantic = { ...groupSemantic }
    },
  },
  actions: {
    async setCurrentGroupSemantic({ commit }, brandId) {
      let req = new BasedQuery().select({
        brands: ['group_semantics_translations'],
      }).where({ id: brandId})

      const response = (await req.resolve('setCurrentGroupSemantic')).first()
      commit('SET_GROUP_SEMANTIC', response.groupSemanticsTranslations)
    },
  },
}

export default groupSemantics
