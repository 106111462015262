<template>
  <div class="d-flex align-center flex-wrap voter-header d-flex">
    <div
      v-if="$vuetify.breakpoint.smAndDown"
      class="d-flex flex-column mr-2"
    >
      <span class="c-primary fb-13">
        {{ voterName }}
      </span>
    </div>

    <v-tooltip
      v-if="$vuetify.breakpoint.mdAndUp"
      top
    >
      <template #activator="{on}">
        <span v-on="on" class="fb-13 c-primary mr-2">
          {{ voterTitle }}
        </span>
      </template>
      {{ voteAtTooltip }}
    </v-tooltip>

    <div class="d-flex align-center ga-1">
      <AvgScore
        class="align-self-center c-grey"
        :insatisfactionStatus="insatisfactionStatus"
        :voter="voter"
      />

      <span class="d-flex align-center flex-shrink-1 flex-grow-0">
        <VoterProcessingStatusBadge
          v-if="currentDashboardInsatisfactionNotificationEnabled"
          :voterNeedsProcessing="voterNeedsProcessing"
          :voterProcessingStatus="voterProcessingStatus"
          :isInsatisfactionForCurrentGroup="isInsatisfactionForCurrentGroup"
          :transferedToUserGroupName="insatisfactionTransferedToUserGroupName"
          :transferedToThirdPartyEmail="transferedToThirdPartyEmail"
          :currentUserNeedValidationForCampaign="currentUserNeedValidationForCampaign(voter.campaignId)"
          :voterConversationManagerEmail="voterConversationManagerEmail"
          :isConversationManagedByCurrentUser="isConversationManagedByCurrentUser"
          class="processing-status"
        />
      </span>

      <v-tooltip :disabled="icon.text === ''" top>
        <template #activator="{on}">
          <div v-on="on">
            <w-icon
              :icon="icon.icon"
              :icon-style="{fontSize: '16px', color: icon.color}"
            />
            <span v-if="$vuetify.breakpoint.smAndDown" class="f-13 c-lightgrey">{{ $i18n.t(icon.text) }}</span>
          </div>
        </template>
        {{ $i18n.t(icon.text) }}
      </v-tooltip>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import PushMobileInsatisfactionTest from './PushMobileInsatisfactionTest'
  import VoterMixin from '../../voter_mixin'
  import InsatisfactionMixin from '../../insatisfaction_mixin'
  import AvgScore from './AvgScore'
  import VoterProcessingStatusBadge from './VoterProcessingStatusBadge'

  export default {
    name: "VoterHeader",
    props: {
    },
    mixins: [
      InsatisfactionMixin,
      VoterMixin
    ],
    components: {
      PushMobileInsatisfactionTest,
      AvgScore,
      VoterProcessingStatusBadge
    },
    computed: {
      ...mapGetters([
        'currentAdmin',
        'currentUserNeedValidationForCampaign',
        'voterConversationManagerEmail',
        'currentDashboardInsatisfactionNotificationEnabled',
        'mainCampaign',
        'currentVoter',
        'currentVoterVoterThirdPartyTransfer'
      ]),
      voterTitle() {
        const voterName =
          this.voterName !== 'anonymized' ?
          this.voterName :
          'xxxxx'

        return `${voterName}${this.voteAt}`
      },
      voteAt() {
        return this.$t(
          'voter_timeline_element_created_at',
          { date: this.$helpers.dates.short(this.voter.createdAt, false) }
        )
      },
      voteAtTooltip() {
        const timestamp = this.$helpers.dates.short(this.voter.createdAt, true)
        if (this.voter.complete === false) {
          return `${timestamp} (${this.$t('incomplete_review')})`
        }
        return timestamp
      },
      transferedToThirdPartyEmail() {
        if (this.currentVoterVoterThirdPartyTransfer?.messageId) {
          return
        }

        return this.currentVoterVoterThirdPartyTransfer?.email
      }
    },
  }
</script>
