import { render, staticRenderFns } from "./TransactionalUnitToEarnWidget.vue?vue&type=template&id=a95d2118&"
import script from "./TransactionalUnitToEarnWidget.vue?vue&type=script&lang=js&"
export * from "./TransactionalUnitToEarnWidget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports