<template>
  <w-nav-tabs
    :router-links-properties="routerLinksProperties"
    scrollable
    :replaceTitle="false"
    :class="{ 'mb-3': !$vuetify.breakpoint.smAndUp }"
  />
</template>

<script>
  import { mapGetters } from 'vuex'

  export default{
    name: "CompetitorModeTypeNav",
    props: ['campaignSmartBenchmarkPreferences'],
    computed: {
      ...mapGetters([
        'isDemoUser',
        'currentAdmin',
        'smartBenchmarkEnabled',
        'currentDashboardCampaignIds',
        'currentDashboard',
        'isDashboardMono',
        'defaultCompetitorScope',
        'smartBenchmarkEnabled'
      ]),
      routerLinksProperties() {
        let routerLinksProperties = []

        const period = ['genesis', 'period'].includes(
          this.$route?.params?.periodType || 'period'
        ) ? this.$route?.params?.periodType : 'period'

        if (this.currentDashboard.preferences.isLrmEligible) {
          routerLinksProperties.push(
            this.competitorsNavigation(
              this.$t('lm_places'),
              'local',
              period,
              this.defaultCompetitorScope
            )
          )

          if (this.currentDashboard.preferences.competitorMode === 'national') {
            routerLinksProperties.push(
              this.competitorsNavigation(
                this.$t('competitors_filter_national'),
                'national',
                period,
                this.defaultCompetitorScope
              )
            )

            if (this.smartBenchmarkEnabled) {
              const competitorScope = this.currentDashboard?.lmBrandIds?.length ?
                                      this.currentDashboard.lmBrandIds[0] :
                                      'default'

              const countriesMetadata = []
              for (const countryCode of this.currentDashboard.lmCountryCodes) {
                countriesMetadata.push({
                  competitorScope,
                  countryCode,
                  country: this.$t(`countries.${countryCode}`),
                })
              }

              countriesMetadata
                .sort((a, b) => a.country.localeCompare(b.country))
                .forEach(metadata => {
                  routerLinksProperties.push(
                    this.competitorsNavigation(
                      metadata.country,
                      'country',
                      metadata.countryCode,
                      metadata.competitorScope
                    )
                  )
                })
            }
          }
        }
        return routerLinksProperties
      }
    },
    methods: {
      competitorsNavigation(title, competitorMode, periodType, competitorScope) {
        let childNav

        if (this.smartBenchmarkEnabled) {
          if (this.campaignSmartBenchmarkPreferences.length > 1) {
            childNav = this.campaignSmartBenchmarkPreferences.map((campaignSmartBenchmarkPreference) => {
              return this.competitorsLink(title, competitorMode, periodType, competitorScope, campaignSmartBenchmarkPreference)
            })
          }

          return this.competitorsLink(title, competitorMode, periodType, competitorScope, null, childNav)
        }

        return this.competitorsLink(title, competitorMode, periodType, competitorScope)
      },
      competitorsLink(title, competitorMode, periodType, competitorScope, campaignSmartBenchmarkPreference, childNav) {
        const active = competitorMode === 'country' ?
          this.$route.params.competitorMode === competitorMode && this.$route.params.periodType === periodType :
          this.$route.params.competitorMode === competitorMode

        const link = {
          title: title,
          className: `${competitorMode}-${periodType}-${competitorScope}-competitors-link`,
          active: active,
          route: {
            name: 'Competitions',
            params: {
              competitorMode: competitorMode,
              periodType: periodType,
              competitorScope: competitorScope
            }
          }
        }

        if (this.smartBenchmarkEnabled) {
          if (childNav) {
            link.childNav = childNav
          }

          if (campaignSmartBenchmarkPreference) {
            link.title = campaignSmartBenchmarkPreference.name
            link.className = `${competitorMode}-${periodType}-${competitorScope}-${campaignSmartBenchmarkPreference.id}-competitors-link`
            link.default = campaignSmartBenchmarkPreference.isDefault
            link.route.params = {
              ...link.route.params,
              ...{ campaignSmartBenchmarkPreferenceId: String(campaignSmartBenchmarkPreference.id) }
            }
          }
        }

        return link
      }
    }
  }
</script>
