<template>
  <v-form ref="form" v-model="isValid" class="close-feedback d-flex flex-column">
    <v-row class="flex-grow-1">
      <v-col class="py-0 mt-1">
        <v-textarea
          v-model="content"
          class="content-textarea f-14"
          :label="$t('closeFeedback.content')"
          background-color="#eef1f5"
          name="content"
          height="100%"
          rows="0"
          dense
          filled
          no-resize
        />
      </v-col>
    </v-row>
    <v-row class="flex-grow-0">
      <v-col class="text-right py-0 my-1">
        <v-btn
          class="close"
          @click="closeFeedback()"
          :disabled="!isValid"
          :ripple="false"
          :loading="loading"
          color="secondary"
        >
          <v-icon left dark>mdi-check</v-icon>
          {{ $t('close_feedback') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'
  import moment from 'moment'

  export default {
    name: "CloseFeedback",
    props: {
      voter: { required: true }
    },
    computed: {
      ...mapGetters([
        'currentVoter'
      ]),
    },
    data() {
      return {
        isValid: false,
        loading: false,
        content: ''
      }
    },
    methods: {
      async closeFeedback() {
        if (this.isValid) {
          this.loading = true

          try {
            const response = await this.$api.wizville.voterClosing.create(
              this.voter.uniqueId,
              { content: this.content }
            )

            if (response.status === "ok") {
              this.$store.dispatch(
                'notifySuccess',
                { message: this.$t('flash_message_successfully_closed_feedback'), timeout: 5000 }
              )
              this.$store.dispatch(
                'events',
                {
                  voterUpdate: {
                    voter: {
                      uniqueId: this.voter.uniqueId
                    }
                  }
                }
              )

              if (response.insatisfactionProcessed) {
                this.$store.dispatch(
                  'voterInsatisfactionStatus',
                  response.insatisfactionProcessed
                )
              }

            } else {
              this.notifyError(response.message)
            }
          } catch (error) {
            this.notifyError(error.message)
          }

          this.loading = false
        }
      }
    }
  }
</script>

<style lang='stylus'>
  @import '~@theme/medias.styl'
  .close-feedback
    height: 100%
    padding: 20px 10px 20px 10px
    .content-textarea
      height: 100%
      textarea
        line-height: 18px
    .content-textarea .v-input__control
      height: 100%
    .content-textarea .v-input__slot
      margin-bottom: 0px
    +forBreakpoints(mobile, tablet)
      .content-textarea
        min-height: 200px
</style>

