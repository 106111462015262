<template>
  <div>
    <WAutocomplete
      v-model="selectedTopicIds"
      :items="availableTopics"
      label="Topic"
      multiple
      v-if="availableTopics"
      :style="{ maxWidth: '400px' }"
      outlined
      flat
      rounded
    />
    <v-data-table
      v-model="verbatimIds"
      item-key="verbatimId"
      show-select
      ref="table"
      v-if="verbatims"
      :headers="headers"
      :items="verbatims"
      mobile-breakpoint="0"
      class="v-datatable-without-y-borders mt-4"
      height="200px"
      :items-per-page="-1"
      hide-default-footer
      fixed-header
    >
      <template v-slot:item.content="{ item }">
        <div class="f-13">
          {{ item.content }}
        </div>
      </template>
      <template v-slot:item.topicsVerbatims="{ item }">
        <div class="d-flex align-center ga-4">
          <v-chip
            v-for="topicsVerbatim of item.topicsVerbatims"
            :key="`${item.id}-${topicsVerbatim.publicName}`"
            label
            small
            outlined
          >
            <WFeelingIcon :small="true" class="pr-2" :feeling="topicsVerbatim.feeling"/>
            {{ _upperFirst(topicsVerbatim.publicName) }}
          </v-chip>
        </div>
      </template>
    </v-data-table>
    <v-skeleton-loader v-else type="paragraph" />
  </div>
</template>

<script>
  import _groupBy from 'lodash/groupBy'
  import _uniqBy from 'lodash/uniqBy'
  import _upperFirst from 'lodash/upperFirst'
  import { mapGetters } from 'vuex'
  import { sqlDate } from '@vue/plugins/helpers/dates'
  import dayjs from 'dayjs'

  export default {
    name: 'TopicsVerbatimReclassificationsSelector',
    components: {
    },
    props: {
      value: { type: Array, required: true }
    },
    data() {
      return {
        selectedTopicIds: []
      }
    },
    methods: {
      _upperFirst,
    },
    computed: {
      ...mapGetters([
        'currentCampaignPreferencesCampaignId'
      ]),
      verbatimIds: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit("input", val)
        }
      },
      headers() {
        let headers = [
          {
            text: this.$t('openai_text_analysis_topics_verbatim_reclassifications_selector_content_header'),
            value: 'content'
          },
          {
            text: this.$t('openai_text_analysis_topics_verbatim_reclassifications_selector_topics_verbatims_header'),
            value: 'topicsVerbatims',
          }
        ]

        return headers.filter((header) => header.displayCondition === undefined || header.displayCondition)
      },
      show: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit("input", val)
        }
      }
    },
    asyncComputed: {
      availableTopics: {
        async get() {
          const request = this.$basedRequest().select({
            campaigns: [
              { 'text_analysis_preferences_taxonomy': { as: 'taxonomy' } },
            ]
          }).where({
            id: this.currentCampaignPreferencesCampaignId
          })

          const taxonomy = (await this.$resolve(request))?.first()?.taxonomy || []

          return taxonomy.map(item => {
            return {
              value: item.topicId,
              text: _upperFirst(item.topicPublicName)
            }
          })
        }
      },
      verbatims: {
        async get() {
          let request = this.$basedRequest().select({
            topics_verbatims: [
              'id',
              'verbatim_id',
              { 'verbatims_content': { as: 'verbatimContent'} },
              'feeling',
              { 'topic_public_name': { as: 'textAnalysisTopicPublicName'} },
              { 'text_analysis_topics_text_analysis_theme_id': { as: 'textAnalysisTopicTextAnalysisThemeId'} }
            ]
          }).where({
            'campaigns_id': this.currentCampaignPreferencesCampaignId,
            'topics_verbatim_reclassifications_action': { '<>': 'destroy' },
            'topics_verbatim_reclassifications_created_at': {
              '>=': sqlDate(
                dayjs().subtract(1, "year").startOf('year').format(`YYYY-MM-DD 00:00:00 Z`)
              )
            }
          }).order(
            [['topics_verbatim_reclassifications_created_at', 'desc']]
          )

          if (this.selectedTopicIds.length) {
            request = request.where({
              'text_analysis_topics_id': {
                'in': this.selectedTopicIds
              }
            })
          }

          const topicsVerbatims = (await this.$resolve(request)).data?.topicsVerbatims || []

          const topicsVerbatimsByVerbatimId = _groupBy(
            topicsVerbatims,
            topicsVerbatim => topicsVerbatim.verbatimId
          )

          return Object.keys(topicsVerbatimsByVerbatimId).map(verbatimId => {
            const topicsVerbatims = _uniqBy(topicsVerbatimsByVerbatimId[verbatimId],
              (item) => item.textAnalysisTopicTextAnalysisThemeId
            )

            return {
              verbatimId: verbatimId,
              content: topicsVerbatims[0].verbatimContent,
              topicsVerbatims: topicsVerbatims.map(topicsVerbatim => {
                return {
                  id: topicsVerbatim.id,
                  feeling: topicsVerbatim.feeling,
                  publicName: topicsVerbatim.textAnalysisTopicPublicName
                }
              })
            }
          })
        }
      }
    }
  }
</script>

