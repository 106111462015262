<template>
  <v-form ref="form" v-model="isValid" class="write-up d-flex flex-column">
    <v-row class="flex-grow-0 align-center">
      <v-col cols="12" sm="6" class="py-0 my-1">
        <w-date-time-picker-field
          @opened="datePickerOpened"
          ref="dateTimePicker"
          v-model="calledAt"
          :minDateTime="minDateTime"
          :maxDateTime="maxDateTime"
          :menuProps="menuProps"
          :datePickerProps="datePickerProps"
          :dateTimeFieldProps="dateTimeFieldProps"
          :initDateTime="initDateTime"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="py-0 my-1"
        v-if="voter.phoneNumber"
      >
        <v-chip
          label
          class="bg-catskill-white"
        >
          <div class="d-flex align-center ga-2">
            <v-icon small class="c-primary">mdi-phone</v-icon>
            <span class="c-primary">{{ $t('client_phone_number')}}</span>
            <a :href="`tel:${voter.phoneNumber}`"class="c-primary fb-14 text-decoration-underline">{{ voter.phoneNumber}}</a>
            <w-copy-button
              :value="voter.phoneNumber"
            />
          </div>
        </v-chip>
      </v-col>
    </v-row>

    <v-row class="flex-grow-1">
      <v-col class="py-0 mt-1">
        <v-textarea
          v-model="content"
          class="content-textarea f-14"
          :placeholder="$t('writeUp.content_placeholder')"
          persistent-placeholder
          :rules="[$required]"
          :label="$t('writeUp.content')"
          background-color="#eef1f5"
          hide-details="auto"
          name="content"
          height="100%"
          rows="0"
          dense
          filled
          no-resize
        />
      </v-col>
    </v-row>
    <v-row class="flex-grow-0">
      <v-col class="py-0 mb-1">
        <v-icon class="f-14">mdi-lock-outline</v-icon>
        <span class="f-14">{{ $t('callReport.access-warning')}}</span>
      </v-col>
    </v-row>

    <v-row class="flex-grow-0">
      <v-col class="text-right py-0 my-1">
        <v-btn
          v-if="currentVoterEditingCallReport"
          outlined
          text
          class="cancel-edit mr-2"
          @click="cancelEdit"
          :ripple="false"
          color="bodygrey"
        >
          {{ $t('writeUp.cancel') }}
        </v-btn>

        <v-btn
          class="save"
          @click="currentVoterEditingCallReport ? update() : create()"
          :disabled="!isValid"
          :ripple="false"
          :loading="loading"
          color="secondary"
        >
          <v-icon left dark>mdi-phone-log</v-icon>
          {{ currentVoterEditingCallReport ? $t('writeUp.modify') : $t('writeUp.save') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'
  import moment from 'moment'

  export default {
    name: "WriteUp",
    props: {
      voter: { required: true }
    },
    computed: {
      ...mapGetters([
        'currentVoter',
        'currentVoterEditingCallReport',
        'currentVoterShowedActions'
      ]),
      minDateTime() {
        return moment(this.currentVoter.createdAt).add(1, 'minutes').format()
      },
      maxDateTime() {
        return moment().format()
      },
      menuProps() {
        return {
          bottom: this.$vuetify.breakpoint.smAndDown,
          top: !this.$vuetify.breakpoint.smAndDown,
          attach: !this.$vuetify.breakpoint.smAndDown
        }
      },
      datePickerProps() {
        return {
          locale: this.$i18n.locale
        }
      },
      dateTimeFieldProps() {
        return {
          name: "call-date"
        }
      }
    },
    mounted() {
      this.$nextTick(function () {
        if (this.currentVoterEditingCallReport) {
          this.initDateTime = this.currentVoterEditingCallReport.calledAt
          this.content = this.currentVoterEditingCallReport.content
        }
      })
    },
    data() {
      return {
        isValid: false,
        loading: false,
        calledAt: null,
        content: '',
        initDateTime: null
      }
    },
    methods: {
      cancelEdit() {
        this.$store.dispatch('setShowVoterActions', false)
        this.$store.dispatch('setActionsTab', 'cleared')
        this.$store.dispatch('setEditingCallReport', null)
        this.$store.dispatch('notifySuccess', { message: this.$t('flash_message_successfully_cancel_call_report_edit'), timeout: 5000 })
      },
      datePickerOpened(value) {
        this.$store.dispatch('setIsActionSubMenuOpened', value)
      },
      async create() {
        if (this.isValid) {
          this.loading = true

         // try {
            const response = await this.$api.wizville.callReports.create(
              this.voter.uniqueId,
              { called_at: this.calledAt, content: this.content }
            )

            if (response.status === "ok") {
              this.$store.dispatch('notifySuccess', { message: this.$t('flash_message_successfully_created_call_report'), timeout: 5000 })
              this.$store.dispatch('events', { voterUpdate: { voter: { uniqueId: this.voter.uniqueId } } })

              if (response.insatisfactionProcessed) {
                this.$store.dispatch('voterInsatisfactionStatus', response.insatisfactionProcessed)
              }
            } else {
              this.$store.dispatch('notifyError', { message: response.message })
            }
         // } catch (error) {
        //    this.$store.dispatch('notifyError', { message: error.message })
         // }

          this.loading = false
        }
      },
      async update() {
        if (this.isValid) {
          this.loading = true

          try {
            const response = await this.$api.wizville.callReports.update(
              this.voter.uniqueId,
              this.currentVoterEditingCallReport.uniqueId,
              { called_at: this.calledAt, content: this.content }
            )

            if (response.status === "ok") {
              this.$store.dispatch('notifySuccess', { message: this.$t('flash_message_successfully_updated_call_report'), timeout: 5000 })
              this.$store.dispatch('events', { voterUpdate: { voter: { uniqueId: this.voter.uniqueId, timelineELementUniqueId: this.currentVoterEditingCallReport.uniqueId } } })

              this.$store.dispatch('setEditingCallReport', null)

            } else {
              this.$store.dispatch('notifyError', { message: response.message })
            }
          } catch (error) {
            this.$store.dispatch('notifyError', { message: error.message })
          }

          this.loading = false
        }
      }
    },
    watch: {
      currentVoterEditingCallReport: function (value) {
        if (value) {
          this.initDateTime = this.currentVoterEditingCallReport.calledAt
          this.content = this.currentVoterEditingCallReport.content
        }
      },
      currentVoterShowedActions: function (value) {
        if (!value) {
          this.$refs.dateTimePicker.clear()
        }
      }
    }
  }
</script>

<style lang='stylus'>
  @import '~@theme/medias.styl'
  .write-up
    height: 100%
    padding: 20px 10px 20px 10px
    .content-textarea
      height: 100%
      textarea
        line-height: 1rem
    .content-textarea .v-input__control
      height: 100% !important
    +forBreakpoints(mobile, tablet)
      .content-textarea
        min-height: 200px

</style>

