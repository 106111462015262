<template>
  <div
    class="text-center"
  >
    <v-dialog
      v-if="!loading"
      v-model="isOpenClaimAchievementModal"
      max-width="700"
      content-class="claim-achievement-modal"
      persistent
    >
      <div>
        <template>
          <v-stepper
            v-model="step"
            vertical
            class="achievement-stepper"
            min-height="470"
          >
            <v-row class="justify-space-between">
              <v-col cols="9">
                <div class="fs-16 mx-5 mt-3">
                  {{ `${$t('achievementsModalTitle', {
                    network: currentLexicon.translate('network', 'network').toLowerCase()
                  })} 🥳` }}
                </div>
                <div class="f-13 mx-5 mb-3">
                  {{ $t('achievementsModalSubtitle') }}
                </div>
              </v-col>
              <v-col align="right">
                <v-btn
                  icon
                  color="black"
                  plain
                  @click="closeModal"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-stepper-step
              :complete="step > 1"
              step="1"
              class="pt-2 f-15"
            >
              {{ $t('achievementsModalStepSelectSuccessesTitle') }}
            </v-stepper-step>

            <v-stepper-content class="pt-0" step="1">
              <v-card
                class="mb-3 px-2"
                outlined
                elevation="2"
                rounded
              >
                <v-chip-group
                  v-model="achievementsModel"
                  column
                  multiple
                >
                  <v-chip
                    v-if="achievements"
                    v-for="achievement in achievements"
                    :key="achievement.id"
                    :value="achievement.id"
                    :disabled="isAchievementChipDisabled(achievement.id)"
                    class="f-13"
                    color="primary"
                    :text-color="isAchievementChipActive(achievement.id) ? 'white' : 'primary'"
                    :outlined="!isAchievementChipActive(achievement.id)"
                  >
                    <div class="d-flex f-13 text-truncate">
                      <span class="text-truncate">{{ achievement.topicsName }}</span>
                      <w-color-sign
                        class="mx-1 f-12"
                        :value="achievement.score"
                        :decimals="2"
                      />
                      <span
                        class="text-uppercase f-9 chip-date"
                        :class="isAchievementChipActive(achievement.id) ? 'c-verylightgrey' : 'c-grey'"
                      >
                        {{ $helpers.dates.shortMonthAndYear(achievement.month) }}
                      </span>
                    </div>
                  </v-chip>
                </v-chip-group>
              </v-card>
              <div class="mb-4">
                <span v-if="achievements" class="f-13 c-textgrey" v-html="$t(
                    'achievementsModalStepSelectSuccessesWhatIsASuccess',
                    { topProgressionThresholdsPerCent }
                  )">
                </span>
              </div>
              <v-btn
                color="primary"
                @click="step = 2"
                :disabled="!achievementsModel.length"
              >
                {{ $t('achievementsModalNextButton') }}
              </v-btn>
              <v-btn
                text
                @click="closeModal"
              >
                {{ $t('achievementsModalCancelButton') }}
              </v-btn>
            </v-stepper-content>

            <v-stepper-step
              :complete="step > 2"
              step="2"
              class="f-15"
            >
              {{ $t('achievementsModalStepActionsTitle') }}
            </v-stepper-step>

            <v-stepper-content class="pt-0" step="2">
              <span class="f-13">{{ $t('achievementsModalStepActionsWhichActions') }}</span>
              <v-card
                class="mb-3 px-2"
                outlined
                elevation="1"
                rounded
              >
                <v-chip-group
                  v-model="achievementActionTemplatesModel"
                  column
                  max="3"
                  multiple
                >
                  <v-chip
                    v-for="achievementActionTemplate in achievementActionTemplates"
                    :key="achievementActionTemplate.id"
                    :value="achievementActionTemplate.id"
                    color="primary"
                    :text-color="isAchievementActionTemplateChipActive(achievementActionTemplate.id) ? 'white' : 'primary'"
                    :outlined="!isAchievementActionTemplateChipActive(achievementActionTemplate.id)"
                  >
                    <div class="d-flex f-13 text-truncate">
                      <span v-if="achievementActionTemplate.emoji">
                        {{ `${achievementActionTemplate.emoji}&nbsp;` }}
                      </span>
                      <span class="text-truncate">
                        {{ achievementActionTemplate.name }}
                      </span>
                    </div>
                  </v-chip>
                </v-chip-group>
              </v-card>
              <v-btn
                color="primary"
                @click="step = 3"
                :disabled="!achievementActionTemplatesModel.length"
              >
                {{ $t('achievementsModalNextButton') }}
              </v-btn>
              <v-btn
                text
                @click="step = 1"
              >
                {{ $t('achievementsModalPreviousButton') }}
              </v-btn>
            </v-stepper-content>

            <v-stepper-step
              :complete="step > 3"
              step="3"
              class="f-15"
            >
              {{ $t('achievementsModalStepDetailsTitle') }}
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-textarea
                solo
                outlined
                elevation="1"
                v-model="actionDescriptionModel"
                class="f-13"
              >
                <template v-slot:label>
                  <span class="f-13">{{ $t('achievementsModalStepDetailsExplain') }}</span>
                </template>
              </v-textarea>
              <v-btn
                color="primary"
                :disabled="isSharing"
                @click="shareAchievementActions();"
              >
                {{ $t('achievementsModalShareButton') }}
              </v-btn>
              <v-btn
                text
                @click="step = 2"
              >
                {{ $t('achievementsModalPreviousButton') }}
              </v-btn>
            </v-stepper-content>
          </v-stepper>
        </template>
      </div>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import confetti from 'canvas-confetti'
  import _uniq from "lodash/uniq"
  import AchievementsMixin from './shared/achievements_mixin'

  export default {
    name: 'AchievementsModal',
    mixins: [
      AchievementsMixin
    ],
    data () {
      return {
        step: 1,
        isSharing: false,
        isEditingMode: false,
        achievementsModel: [],
        achievementActionTemplatesModel: [],
        actionDescriptionModel: "",
        openAchievementModalFromAnchor: false
      }
    },
    computed: {
      ...mapGetters([
        'currentLexicon',
        'currentEditingPlaceCampaignAchievementId',
        'isOpenClaimAchievementModal'
      ]),
      topProgressionThresholdsPerCent() {
        return _uniq(
          this.achievements.map(
            achievement => `${this.$options.filters.toPercent(achievement.topProgressionThresholdRate)}%`
          )).join(', ')
      }
    },
    methods: {
      isAchievementChipActive(achievementId) {
        return this.achievementsModel.includes(achievementId)
      },
      isAchievementActionTemplateChipActive(achievementActionTemplateId) {
        return this.achievementActionTemplatesModel.includes(achievementActionTemplateId)
      },
      isAchievementChipDisabled(achievementId) {
        if (this.currentEditingPlaceCampaignAchievementId) {
          return !(this.currentEditingPlaceCampaignAchievementId === achievementId)
        }
        return false
      },
      resetModal() {
        this.achievementsModel = []
        this.achievementActionTemplatesModel = []
        this.actionDescriptionModel = ""
        this.isSharing = false
        this.isEditingMode = false
        this.step = 1
        this.$store.dispatch('setCurrentEditingPlaceCampaignAchievementId', null)
      },
      runConfettis() {
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.7 }
        });
      },
      closeModal() {
        this.$store.dispatch('setIsOpenClaimAchievementModal', false)
        this.resetModal()
      },
      openModal() {
        if (this.openAchievementModalFromAnchor) {
          if (this.nbAchievements > 0) {
            this.$store.dispatch('setIsOpenClaimAchievementModal', true)
          } else {
            this.$store.dispatch('notifyInfo', {
              message: this.$t('achievementModalNoAchievementMessage'),
              timeout: 6000 
            })
          }
          this.openAchievementModalFromAnchor = false
        }
      },
      reloadAchievements(responseDate) {
        this.$store.dispatch('triggerAchievementsUpdate', responseDate)
      },
      closeAndReload(responseDate) {
        this.reloadAchievements(responseDate)
        this.closeModal()
      },
      async shareAchievementActions() {
        this.isSharing = true
        let response;

        try {
          if (this.isEditingMode) {
            response = await this.$api.wizville.placeCampaignAchievementsBatch.update(
              this.achievementsModel,
              this.achievementActionTemplatesModel,
              this.actionDescriptionModel
            )
          } else {
            response = await this.$api.wizville.placeCampaignAchievementsBatch.create(
              this.achievementsModel,
              this.achievementActionTemplatesModel,
              this.actionDescriptionModel
            )
          }

          if (response.status === 'ok') {
            this.closeAndReload(response?.claimedAt || response?.editedAt)

            if (!this.isEditingMode) {
              this.runConfettis()
            }

            const myAchievementsLink = this.$router.resolve({
              name: 'Achievements',
              params: {
                page: 'my_achievements'
              }
            }).href

            const message = this.isEditingMode ?
              `
                🥳 ${this.$t('achievementsModalSuccessFlashMessageShared',
                { network: this.currentLexicon.translate('network', 'network').toLowerCase() })}
              ` :
              `
                🥳 ${this.$t('achievementsModalSuccessFlashMessageShared',
                { network: this.currentLexicon.translate('network', 'network').toLowerCase() })}
                 <a class="c-white text-decoration-underline" href="${myAchievementsLink}">${this.$t('achievementsModalSuccessFlashMessageLinkToSuccess')}</a>
              `
            const timeout = this.isEditingMode ? 5000 : 30000

            this.$store.dispatch('notifySuccess', {
              message: message,
              timeout: timeout
            })
          }
        } catch (error) {
          this.$store.dispatch('notifyError', { message: error.message })
        }
        this.isSharing = false
      },
      async getAchievementActionTemplates() {
        let request = this.$basedRequest().select({
          achievement_action_templates: ["id", "name", "emoji"]
        }).where({
          campaign_id: this.currentDashboardCampaignIds
        }).order(['name', 'asc'])

        return (await this.$resolve(request))
      },
      async initializeSubmitMode() {
        if (this.currentEditingPlaceCampaignAchievementId) {

          const request = this.$basedRequest().select({
            place_campaign_achievements: [
              'id',
              'topic_id',
              { 'ARRAY_AGG_achievement_action_templates_id': { as: 'achievementActionTemplates' } },
              'actions_description'
            ]
          }).where({
            campaign_id: this.currentDashboardCampaignIds,
            id: this.currentEditingPlaceCampaignAchievementId
          }).group('id')

          const currentEditingPlaceCampaignAchievement = (await this.$resolve(request)).first()

          this.isEditingMode = true
          this.achievementsModel.push(this.currentEditingPlaceCampaignAchievementId)
          this.achievementActionTemplatesModel = currentEditingPlaceCampaignAchievement.achievementActionTemplates
          this.actionDescriptionModel = currentEditingPlaceCampaignAchievement.actionsDescription
          this.step = 2
        } else {
          this.isEditingMode = false
          this.step = 1
        }
      }
    },
    asyncComputed: {
      achievementActionTemplates: {
        async get() {
          this.loading = true
          const result = await this.getAchievementActionTemplates()
          this.loading = false

          return result.data.achievementActionTemplates
        },
        default: undefined
      }
    },
    watch: {
      currentEditingPlaceCampaignAchievementId() {
        this.initializeSubmitMode()
      },
      achievements(newVal, oldVal) {
        if (oldVal == undefined && newVal !== undefined) {
          this.openModal()
        }
      }
    },
    // When we click on the success button from an email,
    // The url is rewritten by the application so we lose the anchor.
    // Then we have to set the openAchievementModalFromAnchor in the mounted hook.
    mounted() {
      this.openAchievementModalFromAnchor = !!this.$route.hash?.includes("openAchievementModal")
    }
  }
</script>

<style lang="stylus">
  .claim-achievement-modal.v-dialog:not(.v-dialog--fullscreen)
    max-height: 80vh !important

  .achievement-stepper
    .theme--light.v-chip--active::before
      opacity: 0 !important

    .v-text-field__slot
      .theme--light.v-label
        font-size: 13px

    .v-chip__content .chip-date
      position: relative
      top: 1px
</style>
