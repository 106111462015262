<template>
  <v-navigation-drawer
    v-if="show"
    :value="currentVoterShowActions"
    app
    right
    clipped
    dense
    flat
    hide-overlay
    class="voter-mobile-action-panel"
    width="100%"
    disable-route-watcher
  >
    <v-container>
      <v-row class="header bg-secondary">
        <v-col
          class="d-flex align-center"
        >
          <a href="#" @click="close">
            <v-icon color="white">mdi-arrow-left</v-icon>
          </a>
          <span class="ml-2 fs-18 c-white text-truncate">{{ title }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ActionTabs
            :voter="voter"
            :gBrandName="gBrandName"
            :fieldsCustomization="fieldsCustomization"
            :prospectFields="prospectFields"
            :smartAnswer="smartAnswer"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ActionTabs from '../ActionTabs'
  import VoterMixin from '../../voter_mixin'

  export default {
    name: "MobileActionPanel",
    components: {
      ActionTabs,
    },
    mixins: [
      VoterMixin
    ],
    props: {
      voter: { required: true },
      actions: { type: Array, required: true },
      gBrandName: { required: true },
      fieldsCustomization: { required: true },
      prospectFields: { required: true },
      smartAnswer: { type: String },
    },
    methods: {
      close() {
        this.$store.dispatch('setShowVoterActions', false);
      },
      onResize() {
        this.windowHeight = window.innerHeight
      },
    },
    computed: {
      ...mapGetters([
        'currentVoterShowActions',
        'currentVoterActionsTab',
        'voterUpdateEvent'
      ]),
      title() {
        if (this.currentVoterActionsTab === 'send_message') {
          return this.voterName !== 'anonymized' ?
            this.$t('answer_to', { voterName: this.voterName }) :
            this.$t('answer')
        }

        const action = this.actions.filter((action) => action.id === this.currentVoterActionsTab)[0]
        return action ? action.title : null
      },
      show() {
        return this.currentVoterActionsTab != 'cleared' &&
          this.currentVoterActionsTab != null &&
          this.currentVoterShowActions
      }
    },
    watch: {
      voterUpdateEvent: function (voterUpdateEvent) {
        this.$nextTick(function () {
          this.$store.dispatch('setShowVoterActions', false)
          this.$store.dispatch('setActionsTab', 'cleared')
        })
      }
    }
  }
</script>

<style lang="stylus">
  .voter-mobile-action-panel
    top: 48px !important
    .header
      height: 60px
</style>
