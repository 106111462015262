<template>
  <div>
    <v-menu
      v-model="open"
      :close-on-content-click="false"
      :disabled="!selectedScope.id || isMonoAdmin"
      :class="{ 'v-mobile-menu': $vuetify.breakpoint.xsOnly }"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-if="placeLoaded"
          class="w-filter-dropdown d-flex px-2 align-center flex-nowrap c-primary bg-palegrey"
        >
          <span class="ml-1 selected-text text-truncate f-13">
            {{ $t('scopeFilter.my_perimeter') }}
          </span>  
        </div>
        <div
          v-else
          class="w-filter-dropdown d-flex px-2 align-center flex-nowrap c-primary"
          :class="{
            'bg-bodygrey': open,
            'bg-palegrey': !open,
            disabled: isMonoAdmin,
            'mobile-filter-menu': $vuetify.breakpoint.xsOnly,
          }"
          v-bind="attrs"
          v-on="on"
        >
          <w-icon
            v-if="mainIcon"
            class="c-accent filter-icon top_0px"
            :icon="mainIcon"
          />
          <span class="ml-1 selected-text text-truncate f-13">
            {{ mainLabel }}
          </span>

          <v-icon
            v-if="!isMonoAdmin && selectedScope.id"
            color="primary"
            size="medium"
            class="ml-auto"
          >
            mdi-chevron-down
          </v-icon>
        </div>
      </template>
      <v-list v-if="selectedScope.id" class="pt-0" dense>
        <v-progress-linear v-if="loading" indeterminate color="primary" />
        <v-col class="search-fixed">
          <v-text-field
            v-model="debouncedSearch"
            :label="capitalize($t('search'))"
            append-icon="mdi-magnify"
            autofocus
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-list-item-group
          v-if="selectedScope.id && globalScopeItem"
          v-model="selectedScope"
          class="search-list-item"
        >
          <v-list-item
            class="network-item"
            :class="{ active: selectedScope.id === globalScopeItem.id }"
            :value="globalScopeItem"
            dense
          >
            <w-icon :icon="globalScopeItem.icon" class="fb-12 mr-1" />
            <span>{{ globalScopeItem.label }}</span>
            <span
              class="ml-1"
              v-if="accessiblePlaces.length"
              :class="
                selectedScope.id === globalScopeItem.id
                  ? 'c-white'
                  : 'c-lightgrey'
              "
            >
              ({{ accessiblePlaces.length }})
            </span>
          </v-list-item>
          <div v-for="scope in filteredGroupSemantics" :key="scope.semantic">
            <v-subheader>
              <w-icon icon="map-markers" class="fb-12 mr-1" />

              <span class="fb-12 c-accent">
                {{ scope.name }}
              </span>
            </v-subheader>
            <v-list-item
              v-for="group in scope.groups.sort((a, b) => {
                return a.name.localeCompare(b.name, undefined, {
                  numeric: true,
                  sensitivity: 'base',
                })
              })"
              class="f-14"
              :key="`group-${group.id}`"
              :class="{ active: selectedScope.id === `group-${group.id}` }"
              :value="{
                id: `group-${group.id}`,
                label: `${group.name} (${group.nbPlaceId})`,
                icon:'map-markers'
              }"
              dense
            >
              <span>{{ group.name }}</span>
              <span
                class="ml-1"
                :class="
                  selectedScope.id === `group-${group.id}`
                    ? 'c-white'
                    : 'c-lightgrey'
                "
              >
                ({{ group.nbPlaceId }})
              </span>
            </v-list-item>
          </div>
          <template v-if="!loading">
            <v-subheader>
              <w-icon icon="map-marker" class="fb-12 mr-1" />

              <span class="fb-12 c-accent">
                {{ currentLexicon.translate('places', 'places') }}
              </span>
            </v-subheader>

            <v-list-item
              v-for="place in this.selectablePlaces.sort((a, b) => {
                return a.name.localeCompare(b.name, undefined, {
                  numeric: true,
                  sensitivity: 'base',
                })
              })"
              class="f-14"
              :key="`place-${place.id}`"
              :class="{ active: selectedScope.id === `place-${place.id}` }"
              :value="{
                id: `place-${place.id}`,
                label: `${place.name}`,
                icon:'map-markers'
              }"
              dense
            >
              <span>{{ place.name }}</span>
              <span
                class="ml-1"
                :class="
                  selectedScope.id === `place-${place.id}`
                    ? 'c-white'
                    : 'c-lightgrey'
                "
              >
              </span>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { capitalize } from 'inflection'
import _debounce from 'lodash/debounce'

export default {
  name: 'ScopeFilter',
  components: {},
  data() {
    return {
      groupsLoading: false,
      placesLoading: false,
      search: '',
      open: false,
      activated: false,
      selectedScope: { id: 'all' },
    }
  },
  computed: {
    ...mapGetters([
      'currentDashboard',
      'currentDashboardBasedTable',
      'currentGroupSemantic',
      'currentLexicon',
      'dashboardFilterScope',
      'currentUser',
      'scopeLabel',
      'scopeIcon',
    ]),
    debouncedSearch: {
      get() {
        return this.search
      },
      set: _debounce(function (val) {
        this.search = val
      }, 200),
    },
    placeLoaded() {
      return this.placesLoading
    },
    loading() {
      return this.groupsLoading || this.placesLoading
    },
    selectablePlaces() {
      const allMatch =
        !this.search ||
        this.currentLexicon
          .translate('places', 'places')
          .toLowerCase()
          .includes(this.search.toLowerCase())

      if (allMatch) {
        return this.accessiblePlaces
      } else {
        return this.accessiblePlaces.filter((p) => {
          return p.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }
    },
    filteredGroupSemantics() {
      let groups = this.userScopeByGroupSemantic.map((g) => {
        return {
          ...g,
          groups: g.groups.filter((grp) => {
            return grp.nbPlaceId !== this.accessiblePlaces.length
          }),
        }
      })

      if (this.search) {
        groups = groups.map((g) => {
          const name = g.name

          if (name.toLowerCase().includes(this.search.toLowerCase())) {
            return g
          } else {
            return {
              ...g,
              groups: g.groups.filter((grp) =>
                grp.name.toLowerCase().includes(this.search.toLowerCase())
              ),
            }
          }
        })
      }

      return groups
        .filter((g) => g.groups.length)
        .sort((a, b) => {
          return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        })
    },
    accessiblePlaces() {
      const accessiblePlaces = []

      this.placesData?.userAccessiblePlaceCampaignsList.forEach((placeCampaign) => {
        const placeIndex = accessiblePlaces.findIndex((place) => place.id === placeCampaign.placeId)
        if (placeIndex === -1) {
          accessiblePlaces.push({
            id: placeCampaign.placeId,
            name: placeCampaign.name
          })
        }
      })

      return accessiblePlaces.sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      })
    },
    mainLabel() {
      if (this.selectedScope?.id == 'all') {
        return this?.globalScopeItem?.label
      } else {
        return this.scopeLabel
      }
    },
    mainIcon() {
      if (this.selectedScope?.id == 'all') {
        return this?.globalScopeItem?.icon
      } else {
        return this.scopeIcon
      }
    },
    isMonoAdmin() {
      return (this.accessiblePlaces.length === 1)
    },
  },
  asyncComputed: {
    globalScopeItem: {
      async get() {
        if (this.placesData?.totalPlaces > 0) {
          if (this.isMonoAdmin) {
            return {
              id: 'all',
              label: `${ this.accessiblePlaces[0]?.name}`,
              icon: 'map-marker'
            }
          } else if (this.accessiblePlaces.length !== this.placesData?.totalPlaces) {
            const request = this.$basedRequest().select({ users: ['associated_groups'] }).where({ id: this.currentUser.id })
            const associatedGroups = (await this.$resolve(request)).first()?.associatedGroups

            if (associatedGroups?.length === 1) {
              return {
                id: 'all',
                label: `${associatedGroups.map((g) => g.name).join(', ')}`,
                icon: 'map-markers'
              }
            } else {
              return {
                id: 'all',
                label: `${this.accessiblePlaces.length} ${this.currentLexicon.translate('places', 'places').toLowerCase()}`,
                icon: 'map-markers'
              }
            }
          } else {
            return {
              id: 'all',
              label: `${this.currentLexicon.translate('network', 'network')}`,
              icon: 'network'
            }
          }
        }
      },
      default() {
        return null
      },
    },
    placesData: {
      async get() {
        this.placesLoading = true
        const request = this.$basedRequest()
          .select({
            [this.currentDashboardBasedTable]: [
              'user_accessible_place_campaigns_list',
              'total_places'
            ],
          })
          .where({
            id: this.currentDashboard.id,
          })
          .group(['id'])

        const data = (await this.$resolve(request)).first()

        this.placesLoading = false
        return data
      },
      default() {
        return { userAccessiblePlaceCampaignsList: [], totalPlaces: 0 }
      },
    },
    userScopeByGroupSemantic: {
      async get() {
        if (this.activated) {
          this.groupsLoading = true
          const request = this.$basedRequest()
            .select({
              [this.currentDashboardBasedTable]: [
                'user_scope_by_group_semantic',
              ],
            })
            .where({
              id: this.currentDashboard.id,
            })

          const scope = (await this.$resolve(request)).first()
          this.groupsLoading = false
          return scope.userScopeByGroupSemantic
        } else {
          return []
        }
      },
      default() {
        return []
      },
    },
  },
  methods: {
    capitalize,
    filterDashboard() {
      if (this.selectedScope?.id) {
        this.$store.commit('setIconAndLabel', {
          label: this.selectedScope.label,
          icon: this.selectedScope.icon
        })

        this.$store.dispatch('updateDashboardFilters', {
          scope: { id: this.selectedScope.id },
        })
      }
      this.open = false
      this.search = ''
    },
    updateFilterFromScope() {
      if (this.dashboardFilterScope) {
        this.selectedScope = this.dashboardFilterScope
      }
    },
  },
  watch: {
    dashboardFilterScope: {
      handler: 'updateFilterFromScope',
      immediate: true,
      deep: true,
    },
    selectedScope(newVal, oldVal) {
      if (newVal?.id !== this.dashboardFilterScope?.id) {
        this.filterDashboard()
      }
    },
    open() {
      if (this.open && !this.activated) {
        this.activated = true
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@theme/colors.styl'

.disabled
  cursor default
.v-list
  max-height 50vh
  overflow scroll
  .v-subheader
    height 25px !important
    text-transform uppercase
  .v-list-item
    height 25px !important
    min-height 25px !important
    .wiz-icon
      color var(--accent)
    &.active
      background-color var(--secondary) !important
      color white !important
      .wiz-icon
        color white
    &.network-item
      height 30px !important
      min-height 30px !important
      padding-left 8px !important
.w-filter-dropdown
  width 100%
  height: 40px
  border-right 1px solid rgba(0, 0, 0, 0.12)

.search-fixed
  position: absolute
  z-index: 300
  background-color: white
  overflow-x: hidden

.search-list-item
  margin-top: 65px
</style>
