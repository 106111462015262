<template>
  <div
    class="other-voters d-flex flex-column ga-3"
    v-if="voterMixin_currentVoterOtherVotersUniqueIds.length > 1"
  >
    <span
      class="c-primary fb-14"
    >
      {{ `${$t('voter_customer_information_linked_voters')} (${voterMixin_currentVoterOtherVotersUniqueIds.length})` }}
    </span>
    <div
      class="d-flex flex-column ga-2"
      v-if="!loading"
    >
      <Voter
        v-for="otherVoter in voters"
        :key="otherVoter.uniqueId"
        :otherVoter="otherVoter"
        :voter="voter"
        :icon="icon"
        :isCurrent="voter.uniqueId === otherVoter.uniqueId"
      />
    </div>
    <v-skeleton-loader v-else type="paragraph"/>
    <v-div
      class="d-flex align-center justify-end border-mercury"
      v-if="voterMixin_currentVoterOtherVotersUniqueIds.length > pageSize"
    >
      <v-pagination
        class="f-12"
        color="accent"
        v-model="page"
        :length="Math.ceil(voterMixin_currentVoterOtherVotersUniqueIds.length / pageSize)"
        :total-visible="8"
      />
    </v-div>
  </div>
</template>

<script>
import VoterMixin from '../../voter_mixin'
import Voter from './Voter'

export default {
  name: 'OtherVoters',
  components: {
    Voter
  },
  data: () => ({
    pageSize: 5,
    page: 1,
    loading: true
  }),
  mixins: [
    VoterMixin,
  ],
  asyncComputed: {
    voters: {
      async get() {
        this.loading = true

        const voters = await this.voterMixin_currentVoterPaginatedOtherVoters(this.pageSize, (this.page - 1) * this.pageSize)

        this.loading = false
        return voters
      }
    }
  }
}
</script>

<style lang="stylus">
</style>
