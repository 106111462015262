import { render, staticRenderFns } from "./ExpectationTopicRankingTable.vue?vue&type=template&id=a1fdad26&"
import script from "./ExpectationTopicRankingTable.vue?vue&type=script&lang=js&"
export * from "./ExpectationTopicRankingTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports