<template>
   <WAutocomplete
      v-if="campaignTopicsList.length > 1"
      v-model="dataTopicId"
      :items="campaignTopicsList"
      :key="`${campaignId}_${topicId}`"
      :label="label"
      @change="onChange"
      backgroundColor="white"
      rounded
    />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "TopicAutocomplete",
  data() {
    return {
      dataTopicId: this.topicId
    }
  },
  props: {
    topicId: { required: false },
    campaignId: { required: false },
    label: { required: false }
  },
  computed: {
    ...mapGetters([
      'currentTopicsByCampaignId',
    ]),
    campaignTopicsList() {
      const list = (this.currentTopicsByCampaignId[this.campaignId] || [])
      return this.$sortByAlpha(list.map((item) => {
        return {
          text: item.name,
          value: item.id
        }
      }), 'text')
    },
  },
  methods: {
    onChange() {
      this.$router.push({
        params: { sectionId: this.dataTopicId }
      })
    },
  },
  watch: {
    topicId: function() {
      this.dataTopicId = this.topicId
    }
  }
}
</script>
