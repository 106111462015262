<template>
  <div class="qcm-question-zoom">
    <QcmQuestion :question="question" :showTitle="this.showTitle" />
  </div>
</template>

<script>
  import QcmQuestion from "@statistics/shared/QcmQuestion"

  export default {
    name: "QcmQuestionZoom",
    props: {
      question: null, 
      showTitle: {
        default: true
      }
    },
    components: {
      QcmQuestion
    }
  }
</script>

<style lang="stylus" scoped>
 
</style>
