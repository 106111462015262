<template>
  <div class="question-answer">
    <div v-if="answers.length > 0">
      <template v-if="answers.some((a) => !a.notSpecified)">
        <MarkQuestionAnswer v-if="question.type === 'MarkQuestion'" :answer="answer" :question="question" :pictures="pictures" />
        <QcmQuestionAnswer v-else-if="question.type === 'QcmQuestion'" :answers="answers" :question="question" :pictures="pictures" />
        <FreeQuestionAnswer v-else-if="question.type === 'FreeQuestion'"
          :answer="answer"
          :question="question"
          :pictures="pictures"
          :topicsVerbatims="topicsVerbatims"
          :voter="voter"
        />
        <QcmTableQuestionAnswer v-else-if="question.type === 'QcmTableQuestion'" :answers="answers" :question="question" :pictures="pictures" />
      </template>

      <div v-else>
        {{ $t('question_answer.no_opinion_on_that') }}
      </div>
    </div>
  </div>
</template>

<script>
  import MarkQuestionAnswer from "./MarkQuestionAnswer"
  import QcmQuestionAnswer from "./QcmQuestionAnswer"
  import FreeQuestionAnswer from "./FreeQuestionAnswer"
  import QcmTableQuestionAnswer from "./QcmTableQuestionAnswer"

  export default {
    name: "QuestionAnswer",
    components: {
      MarkQuestionAnswer,
      QcmQuestionAnswer,
      FreeQuestionAnswer,
      QcmTableQuestionAnswer
    },
    props: {
      answers: { required: false, type: Array, default: [] },
      pictures: { required: false, type: Array },
      question: { required: true },
      topicsVerbatims: {
        required: false,
        type: Array,
        default: () => []
      },
      voter: {
        required: false,
        type: Object,
        default: () => {}
      }
    },
    computed: {
      answer() {
        return (this.answers ? this.answers[0] : null)
      }
    }
  }
</script>

<style lang='stylus' scoped>

</style>
