import ApiCall from '@api/apiCall.js'

export async function translate_in_hashes(data, key, fromLocale, toLocale) {
  if (!fromLocale) throw new Error("Empty 'from' parameter")
  if (!toLocale) throw new Error("Empty 'to' parameter")

  const response = await new ApiCall().post(
    '/api/wizville/translations/translate_in_hashes',
    { data, key, from_locale: fromLocale, to_locale: toLocale }
  )

  return response.data
}
