<template>
  <div class="source-filter-content">
    <p class="fs-12 mb-0">{{ $t('review_source') }}</p>

    <v-checkbox
      v-for="sourceOption in sourceOptions"
      :key="sourceOption.value"
      :value="sourceOption.value"
      v-model="sources"
      @change="onChange"
      :ripple="false"
      hide-details
      dense
    >
      <template v-slot:label>
        <w-source-icon
          class="bottom_1px pr-1"
          :source="sourceOption.icon"
          iconSize="f-14"
          :autoColor="false"
        />

        <span class="f-12">
          {{ sourceOption.label }}
        </span>
      </template>
    </v-checkbox>

    <v-checkbox
      class="ml-4"
      v-for="wizvilleSourceOption in wizvilleSourceOptions"
      :key="wizvilleSourceOption.value"
      :value="wizvilleSourceOption.value"
      v-model="sources"
      @change="onChange"
      :ripple="false"
      hide-details
      dense
    >
      <template v-slot:label>
        <span :style="sourceFilterStyle" class="f-12">
          {{ wizvilleSourceOption.label }}
        </span>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
  import _uniq from "lodash/uniq"
  import _remove from "lodash/remove"
  import { mapGetters } from 'vuex'
  import store from '@platform/store'

  export default {
    name: "SourceFilterContent",
    props: ['availableSources', 'availableWizvilleSources'],
    data() {
      return {
        sources: [],
        question: null,
        wizville: false
      }
    },
    computed: {
      ...mapGetters(['wizvilleSources']),
      sourceFilterStyle() {
        return {
          position: 'relative',
          bottom: '1px',
          left: '5px'
        }
      },
      sourceOptions() {
        return this.availableSources.map(source => {
          return {
            label: this.$t(`source.${source}`),
            value: source,
            icon: source
          }
        })
      },
      wizvilleSourceOptions() {
        return this.availableWizvilleSources.map(source => {
          return {
            label: this.$t(`source.${source}`),
            value: source
          }
        })
      }
    },
    methods: {
      onChange() {
        this.$store.dispatch("setDashboardSurveyFilters", { survey: { sources: _uniq(this.sources) } })        
      }
    },
    watch: {
      sources(sources, oldSources) {
        if (sources.includes('wizville') && !oldSources.includes('wizville')) {
          this.availableWizvilleSources.forEach(source => _uniq(sources.push(source)))
        } else if (!sources.includes('wizville') && oldSources.includes('wizville')) {
          sources = _remove(sources, source => !this.wizvilleSources.includes(source))
        } else if (!sources.includes('wizville') && sources.find(source => this.wizvilleSources.includes(source))) {
          sources.push('wizville')
        }

        if (this.sources.length !== _uniq(sources).length) {
          this.sources = _uniq(sources)
        }
      }
    },
    created() {
      this.sources = _uniq(store.getters.surveyFilterSources || [])
    }
  }
</script>