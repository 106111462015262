<template>
  <v-list-group
    class="border-bodygrey company-menu"
    key="companyMenu"
    :value="open"
    :ripple="false"
  >
    <template v-slot:activator>
      <v-list-item-icon>
        <v-icon class="c-primary" dense>mdi-domain</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="title">
        <v-list-item-title class="fs-15 c-primary">{{ $t("dashboard_sidebar_company") }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-slot:appendIcon>
      <v-list-item-icon>
        <v-icon class="c-primary" dense>mdi-chevron-down</v-icon>
      </v-list-item-icon>
    </template>

    <v-list-item
      class="pl-12"
      v-for="item in items"
      :key="item.title"
      :to="item.to"
      :ripple="false"
    >
      <v-list-item-title class="f-14  c-darkergrey">
        {{ item.title }}
      </v-list-item-title>
    </v-list-item>
  </v-list-group>
</template>

<script>
  import { mapGetters } from "vuex"

  export default {
    name: "CompanyMenu",
    components: {},
    data() {
      return {
        open: false,
        items: [
          {
            title: this.$t("dashboard_sidebar_places"),
            to: { name: 'OldPlatformPlaces' }
          },
          {
            title: this.$t("dashboard_sidebar_roles"),
            to: { name: 'OldPlatformUserRoles' }
          },
          {
            title: this.$t("dashboard_sidebar_brands"),
            to: { name: 'OldPlatformBrands' }
          }
        ]
      }
    }
  }
</script>

<style lang="stylus">
  @import '~@theme/colors.styl'

  .company-menu
    .v-list-item__icon:first-child
      margin-right: 4px !important

    .v-list-item__icon
      .v-icon
        margin-top: 0px !important
        margin-left: 3px
        font-size: 15px

    .v-list-group__header
      background-color: var(--palegrey)

    .v-list-group__header__append-icon
      justify-content: flex-start !important
    .title
      flex: 0 1 auto

</style>

