<template>
  <MainFilter :title="title" :nbFilters="nbFilters" class="product-filter" :noPadding="true">
    <template #icon>
      <w-icon icon="cart" class="c-accent filter-icon" />
    </template>

    <template #content="{ opened }">
      <div>
        <v-text-field
          class="pa-2"
          outlined
          hide-details
          dense
          clearable
          prepend-inner-icon="mdi-magnify"
          v-model="search[tab]"
          style="width: 450px"
        >
        </v-text-field>

        <template v-if="nbFilters > 0">
          <hr class="border-bodygrey" />

          <ProductFilterChips 
            :selectedProductCategories="selectedProductCategories" 
            :selectedProducts="selectedProducts" 
            @removeSelectedProductCategoryId="removeSelectedProductCategoryId"
            @removeSelectedProductId="removeSelectedProductId"
          />

          <div class="f-12 c-primary ml-2 pointer" @click="selectedProductCategories = selectedProducts = null">
            {{ $t('reset') }}
          </div>
        </template>

        <hr class="border-bodygrey" />

        <v-tabs dense v-model="tab">
          <v-tab value="filter-category">
            <span class="f-11">
              <v-icon small>mdi-shape</v-icon>
              {{ $t('categories') }}
            </span>
          </v-tab>

          <v-tab value="filter-product">
            <span class="f-11">
              <v-icon small>mdi-cart</v-icon>
            {{ $t('products') }}{{ nbProductsLabel }}
            </span>
          </v-tab>
        </v-tabs>

        <hr class="border-bodygrey"  />

        <v-tabs-items v-model="tab" v-if="opened" class="bg-white" style="max-height: 55vh; overflow-y: scroll">
          <v-tab-item>
            <ProductCategoryTree 
              v-model="selectedProductCategories"
              :campaign="campaign"
              :searchValue="search[tab]"
            />
          </v-tab-item>

          <v-tab-item>
            <ProductsList
              v-model="selectedProducts"
              :campaign="campaign"
              :searchValue="search[tab]"
              :selectedProductCategories="selectedProductCategories"
              :isExistingTranslations="isExistingTranslations"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </template>
  </MainFilter>
</template>

<script>
import { mapGetters } from 'vuex'
import MainFilter from '../MainFilter'
import ProductFilterChips from './ProductFilterChips'
import ProductsList from './ProductsList'
import ProductCategoryTree from './ProductCategoryTree'
import _debounce from "lodash/debounce" 

export default {
  name: "ProductFilter",
  props: [ 'productCategories', 'products', 'campaign' ],
  data() {
    return {
      selectedProductIds: this.products?.map(p => p.id) || [],
      selectedProductCategories: this.productCategories || [],
      selectedProducts: this.products || [],
      search: [],
      tab: 0,
      limit: 100
    }
  },
  components: {
    MainFilter,
    ProductFilterChips,
    ProductCategoryTree,
    ProductsList
  },
  computed: {
    ...mapGetters([
      "dashboardFilterProductCategoryIds"
    ]),
    nbFilters() {
      return (this.selectedProductCategories?.length || 0) + (this.selectedProducts?.length || 0)
    },
    title() {
      return this.$t('products')
    },
    nbProductsLabel() {
      return (this.nbProducts >= this.limit ? " (100+)" : (this.nbProducts ? ` (${this.nbProducts})` : ''))
    },
    isExistingTranslations() {
      return this.nbProductInfoValues > 0
    }
  },
  asyncComputed: {
    async nbProducts() {
      if (this.selectedProductCategories?.length > 0 || this.search[0]?.length > 2) {
        let request = this.$basedRequest().select({
          products: [ { 'COUNT_DISTINCT_id': { as: "nbProducts" } } ]
        }).where({
          campaign_id: this.campaign.id
        })

        if (this.selectedProductCategories?.length > 0) {
          request = request.where({ product_category_id: this.selectedProductCategories.map(c => c.id) })
        } else if (this.search[0]) {
          request = request.where({ code_or_name: { search: this.search[0] } })
        }
    
        const nbProducts = (await this.$resolve(request))?.first()?.nbProducts

        return nbProducts || 0
      }
    },
    async nbProductInfoValues() {
      let request = this.$basedRequest().select({
        product_info_values: ['total_product_info_values']
      }).where({
        campaign_id: this.mainCampaignId,
      })

      const totalProductInfoValues = (await this.$resolve(request))?.first()?.totalProductInfoValues
      
      return totalProductInfoValues || 0
    }
  },
  watch: {
    tab(val) {
      if (this.tab == 1) {
        if (this.selectedProductCategories?.length == 0) {
          this.search[1] = this.search[0]
        } else {
          this.search[1] = ""
        }
      }
    },
    selectedProductCategories(newValue, oldValue) {
      this.$store.dispatch('updateDashboardFilters', {
         product: { products: this.selectedProducts, productCategories: this.selectedProductCategories }
      })
    },
    selectedProducts(newValue, oldValue) {
      this.$store.dispatch('updateDashboardFilters', {
         product: { products: this.selectedProducts, productCategories: this.selectedProductCategories }
      })
    }
  },
  methods: {
    removeSelectedProductCategoryId(productCategoryId) {
      this.selectedProductCategories = this.selectedProductCategories.filter(selectedProductCategory => selectedProductCategory.id !== productCategoryId)
    },
    removeSelectedProductId(productId) {
      this.selectedProducts = this.selectedProducts.filter(selectedProduct => selectedProduct.id !== productId)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .product-filter
    max-width: 601px
  .w-filter-dropdown
    width 100%
    height: 40px
    border-right 1px solid rgba(0, 0, 0, 0.12)
</style>
