<template>
  <EvolutionWidget
    :title="title"
    :basedRequest="basedRequest"
    :indicatorAlias="indicatorAlias"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import _capitalize from "lodash/capitalize"
  import EvolutionWidget from '@statistics/shared/widgets/EvolutionWidget'

  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'

  export default {
    name: "SocialEvolution",
    components: {
      EvolutionWidget
    },
    props: {
      source: { type: String, required: true, default: 'google' }
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest'
      ]),
      basedRequest() {
        return this.dashboardFilterRequest.select(
          { voters: [ { 'AVG_avg_score': { as: this.indicatorAlias } } ] }
        ).where({
          source: this.source
        })
      },
      indicatorAlias() {
        return 'score'
      },
      title() {
        const sourceTitle = this.$t(`source_${this.source}`)

        return `${this.$t('widget_evolution_title')} - ${sourceTitle}`
      }
    }
  }
</script>

<style lang="stylus" scoped>
 
</style>
