<template>
  <div class="voter-section-nav">
    <div class="d-flex align-center ga-2 px-2">
      <v-icon
        @click.prevent="$store.dispatch('collapseVoter')"
        class="f-18"
        v-if="$vuetify.breakpoint.smAndDown"
      >
        mdi-arrow-left
      </v-icon>
      <div class="d-flex flex-grow-1">
        <w-nav-tabs
          :router-links-properties="routerLinksProperties"
          :switchToMobile="false"
        />
      </div>
      <div class="ml-auto">
        <OtherVotersNavigation
          :voter="voter"
        />
      </div>
    </div>
    <v-divider class="divider"></v-divider>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import OtherVotersNavigation from './OtherVoters/Navigation'

export default{
  name: "VoterSectionNav",
  components: {
    OtherVotersNavigation
  },
  props: {
    voter: {
      required: true
    },
  },
  computed: {
    ...mapGetters([
    ]),
    routerLinksProperties() {
      return [
        {
          title: 'review',
          className: 'review py-2',
          route: {
            name: 'Feedbacks',
            params: {
              status: this.$route.params.status,
              subStatus: this.$route.params.subStatus,
              voterUniqueId: this.$route.params.voterUniqueId,
              section: 'review'
            }
          },
        },
        {
          title: this.$t('customer_information_section_title', {
            client: this.$store.getters.currentLexicon.translate('customer', 'customer')?.toLowerCase()
          }),
          className: 'customer_information py-2',
          route: {
            name: 'Feedbacks',
            params: {
              status: this.$route.params.status,
              subStatus: this.$route.params.subStatus,
              voterUniqueId: this.$route.params.voterUniqueId,
              section: 'customer_information'
            }
          }
        }
      ]
    }
  }
}
</script>
<style lang="stylus">
  .voter-section-nav
    .divider
      margin-top: -2px
</style>
