<template>
  <v-form ref="form" v-model="isValid" class="send-internal-memo d-flex flex-column">
    <v-row class="flex-grow-1">
      <v-col class="py-0 mt-1">
        <v-textarea
          v-model="content"
          class="content-textarea f-14"
          :rules="[$required]"
          :label="$t('internalMemo.content')"
          background-color="#eef1f5"
          name="content"
          height="100%"
          rows="0"
          dense
          filled
          no-resize
        />
      </v-col>
    </v-row>
    <v-row class="flex-grow-0">
      <v-col class="py-0 mb-1">
        <v-icon class="f-14">mdi-lock-outline</v-icon>
        <span class="f-14">{{ $t('callReport.access-warning')}}</span>
      </v-col>
    </v-row>
    <v-row class="flex-grow-0">
      <v-col class="text-right py-0 my-1">
        <v-btn
          v-if="currentVoterEditingInternalMemo"
          outlined
          text
          class="cancel-edit mr-2"
          @click="cancelEdit"
          :ripple="false"
          color="bodygrey"
        >
          {{ $t('internalMemo.cancel') }}
        </v-btn>

        <v-btn
          class="save"
          @click="currentVoterEditingInternalMemo ? update() : create()"
          :disabled="!isValid"
          :ripple="false"
          :loading="loading"
          color="secondary"
        >
          <v-icon left dark>mdi-file-edit-outline</v-icon>
          {{ currentVoterEditingInternalMemo ? $t('internalMemo.modify') : $t('internalMemo.save') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'
  import moment from 'moment'

  export default {
    name: "SendInternalMemo",
    props: {
      voter: { required: true }
    },
    computed: {
      ...mapGetters([
        'currentVoter',
        'currentVoterEditingInternalMemo'
      ]),
    },
    mounted() {
      this.$nextTick(function () {
        if (this.currentVoterEditingInternalMemo) {
          this.content = this.currentVoterEditingInternalMemo.content
        }
      })
    },
    data() {
      return {
        isValid: false,
        loading: false,
        content: ''
      }
    },
    methods: {
      clearVoterActions() {
        this.$store.dispatch('setShowVoterActions', false)
        this.$store.dispatch('setActionsTab', 'cleared')
      },
      cancelEdit() {
        this.clearVoterActions()
        this.$store.dispatch('setEditingInternalMemo', null)
        this.$store.dispatch(
          'notifySuccess',
          { message: this.$t('flash_message_successfully_cancel_internal_memo_edit'), timeout: 5000 }
        )
      },
      async create() {
        if (this.isValid) {
          this.loading = true

          try {
            const response = await this.$api.wizville.internalMemos.create(
              this.voter.uniqueId,
              { content: this.content }
            )

            if (response.status === "ok") {
              this.$store.dispatch(
                'notifySuccess',
                { message: this.$t('flash_message_successfully_created_internal_memo'), timeout: 5000 }
              )
              this.$store.dispatch('events', { voterUpdate: { voter: { uniqueId: this.voter.uniqueId } } })

            } else {
              this.notifyError(response.message)
            }
          } catch (error) {
            this.notifyError(error.message)
          }

          this.loading = false
        }
      },
      async update() {
        if (this.isValid) {
          this.loading = true

          try {
            const response = await this.$api.wizville.internalMemos.update(
              this.voter.uniqueId,
              this.currentVoterEditingInternalMemo.uniqueId,
              { content: this.content }
            )

            if (response.status === "ok") {
              this.$store.dispatch('notifySuccess', { message: this.$t('flash_message_successfully_updated_internal_memo'), timeout: 5000 })
              this.$store.dispatch('events', { voterUpdate: { voter: { uniqueId: this.voter.uniqueId, timelineELementUniqueId: this.currentVoterEditingInternalMemo.uniqueId } } })

              this.$store.dispatch('setEditingInternalMemo', null)

            } else {
              this.notifyError(response.message)
            }
          } catch (error) {
            this.notifyError(error.message)
          }

          this.loading = false
        }
      }
    },
    watch: {
      currentVoterEditingInternalMemo: function (value) {
        if (value) {
          this.content = this.currentVoterEditingInternalMemo.content
        }
      },
    },
  }
</script>

<style lang='stylus'>
  @import '~@theme/medias.styl'
  .send-internal-memo
    height: 100%
    padding: 20px 10px 20px 10px
    .content-textarea
      height: 100%
      textarea
        line-height: 18px
    .content-textarea .v-input__control
      height: 100%
    .content-textarea .v-input__slot
      margin-bottom: 0px
    +forBreakpoints(mobile, tablet)
      .content-textarea
        min-height: 200px
</style>

