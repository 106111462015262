import VoterThirdPartyTransferLayout from '@platform/layouts/VoterThirdPartyTransferLayout'
import Vue from 'vue'

import store from '@platform/store'


async function loadVoterThirdPartyTransfer(to, from, next) {
  if (to.params.voterThirdPartyTransferUniqueId) {
    await store.dispatch("voterThirdPartyTransferContext", to.params.voterThirdPartyTransferUniqueId)
  } else {
    await store.dispatch("destroyVoterThirdPartyTransferContext")
  }

  next()
}

export default {
  meta: {
    beforeEach: loadVoterThirdPartyTransfer,
  },
  path: '/',
  component: VoterThirdPartyTransferLayout,
  children: [
    {
      path: '/voterThirdPartyTransfer/:voterThirdPartyTransferUniqueId',
      name: 'VoterThirdPartyTransfer',
      meta: { public: true },
      component: () => import('@platform/pages/voterThirdPartyTransfer'),
    }
  ]
}
