import $api from '@api'

const voterThirdPartyTransferContext = {
  state: () => ({
    uniqueId: null,
    backgroundImageUrl: null,
    brandId: null,
    digest: null,
    legalNotice: null,
    logoUrl: null,
    message: null,
    primaryColor: null,
    trustvilleCgu: null,
    uniqueId: null,
    voterAuthor: null,
    voterAvgScore: null,
    voterVerbatim: null,
    voterConversation: null,
    voterVoteAt: null,
    voterPlaceName: null,
    isEditingAnswer: false
  }),
  getters: {
    currentVoterThirdPartyTransfer(state, getters) {
      return state
    },
    currentVoterThirdPartyTransferBackgroundImageUrl(state, getters) {
      return state.backgroundImageUrl
    },
    currentVoterThirdPartyTransferIsEditingAnswer(state, getters) {
      return state.isEditingAnswer
    },
    currentVoterThirdPartyTransferAnswer(state, getters) {
      return state.message?.content
    },
  },
  mutations: {
    setVoterThirdPartyTransferContext(state, data) {
      state.uniqueId = data.uniqueId
      state.backgroundImageUrl = data.backgroundImageUrl
      state.digest = data.digest
      state.legalNotice = data.legalNotice
      state.logoUrl = data.logoUrl
      state.message = data.message
      state.primaryColor = data.primaryColor
      state.trustvilleCgu = data.trustvilleCgu
      state.voterAuthor = data.voterAuthor
      state.voterAvgScore = data.voterAvgScore
      state.voterVerbatim = data.voterVerbatim
      state.voterVoteAt = data.voterVoteAt
      state.voterPlaceName = data.voterPlaceName
      state.brandId = data.brandId
    },
    setMessage(state, value) {
      state.message = value
    },
    setIsEditingAnswer(state, value) {
      state.isEditingAnswer = value
    },
  },
  actions: {
    async voterThirdPartyTransferContext(context, voterThirdPartyTransferUniqueId) {
      const digest = voterThirdPartyTransferUniqueId

      if (digest !== context.state.digest || !context.state.loaded) {
        const ctx = await $api.wizville.contexts.voterThirdPartyTransfer(voterThirdPartyTransferUniqueId)

        if (ctx.voterThirdPartyTransfer) {
          context.commit('setVoterThirdPartyTransferContext', { digest, ...ctx.voterThirdPartyTransfer })

          context.dispatch('setCurrentLexicon', { brandId: ctx.voterThirdPartyTransfer.brandId })
        }
      }
    },
    setVoterThirdPartyTransferMessage(context, message) {
      context.commit('setMessage', message)
    },
    setIsEditingAnswer(context, isEditingAnswer) {
      context.commit('setIsEditingAnswer', isEditingAnswer)
    }
  }
}

export default voterThirdPartyTransferContext
