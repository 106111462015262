<template>
  <div class="score-cell">
    <div v-if="nbReview" class="d-flex align-center ga-2">
      <v-tooltip
        top
        max-width="300"
        :disabled="scoreTooltip === undefined"
      >
        <template #activator="{ on }">
          <span v-on="on">
            <span class="fb-15 c-primary score">
              {{ score }}
            </span>
          </span>

        </template>
        <span v-if="scoreTooltip">
          {{ scoreTooltip }}
        </span>
      </v-tooltip>
      <div class="evolution">
        <w-color-sign
          :value="evolution"
          :decimals="2"
        />
      </div>
      <div v-if="showRepartition">
        <WRepartitionHorizontalFrieze
          height="15px"
          :nbPromoters="nbPromoters"
          :nbNeutrals="nbNeutrals"
          :nbDetractors="nbDetractors"
          :flagOnly="true"
          :min-width="repartitionWidth"
          :max-width="repartitionWidth"
        />
      </div>
      <span
        v-if="showNbReview"
        class="f-12 lh-12 c-lightgrey text-no-wrap"
      >
        {{ translatedNbReview }}
      </span>
    </div>
    <span v-else class="f-12 c-lightgrey">
      {{ $t('nil') }}
    </span>
  </div>
</template>

<script>
  export default {
    name: "ScoreCell",
    props: {
      score: { type: String },
      nbReview: { type: Number },
      evolution: { type: Number },
      nbPromoters: { type: Number },
      nbNeutrals: { type: Number },
      nbDetractors: { type: Number },
      showRepartition: { type: Boolean, default: true },
      repartitionWidth: { type: String, default: '300px' },
      showNbReview: { type: Boolean, default: true },
      scoreTooltip: { type: String }
    },
    computed: {
      translatedNbReview() {
        return this.$t('nbReview_without_google_logo', {
          nbReview: this.nbReview?.toLocaleString(),
        })
      }
    }
  }
</script>

<style lang="stylus">
  .score-cell
    .score
      min-width: 30px
      display: flex
    .evolution
      min-width: 40px
</style>
