<template>
  <WLateralSectionLayout class="pl-3 nps-page">
    <template #lateral-section>
      <NpsScoreWidget
        :campaign="campaign"
        :subtitle="widgetSubtitle"
        :cols="12"
      />
      <NpsPlaceRankingWidget
        v-if="isMonoPlace && !isNetworkMonoPlace"
        :campaign="campaign"
        :subtitle="widgetSubtitle"
        :cols="12"
      />
      <TransactionalUnitToEarnWidget
        v-if="campaign && nisMixin_canUseNisFeature([campaign])"
        :campaign="campaign"
        :voterProportions="nisVoterProportions"
        :summary="nisSummary"
      />
    </template>

    <template #main-section>
      <NpsRepartitionWidget
        v-if="campaign && nisMixin_canUseNisFeature([campaign])"
        :campaign="campaign"
        :subtitle="widgetSubtitle"
        :nisVoterProportions="nisVoterProportions"
        :nisSummary="nisSummary"
      />
      <NpsRepartitionWidget
        v-else
        :campaign="campaign"
        :subtitle="widgetSubtitle"
      />

      <NpsEvolution
        :campaign="campaign"
        :subtitle="widgetSubtitle"
      />

      <v-row
        no-gutters
        v-if="
          (
            campaign?.hasTextAnalysisEnabled ||
            campaign?.hasVerbatimAnalysisEnabled
          ) &&
          hasAccessToNewPlatformStatisticVerbatimAnalysis
        "
      >
        <TopicsVerbatimTopicsWidget
          :positive="true"
          :indicator="{ type: 'nps' }"
          :campaign="campaign"
        />
        <TopicsVerbatimTopicsWidget
          :positive="false"
          :indicator="{ type: 'nps' }"
          :campaign="campaign"
        />
      </v-row>
      <v-row no-gutters v-else-if="isDemoUser">
        <NpsPositiveTopicsWidget :campaign="campaign" :subtitle="widgetSubtitle" />
        <NpsNegativeTopicsWidget :campaign="campaign" :subtitle="widgetSubtitle" />
      </v-row>

      <NpsRankingTable
        v-if="!isNetworkMonoPlace && hasAccessToNewPlatformRankingTab"
        :campaign="campaign"
        :subtitle="widgetSubtitle"
      />
    </template>
  </WLateralSectionLayout>
</template>

<script>
  import { mapGetters } from 'vuex'
  import NpsScoreWidget from './NpsScoreWidget'
  import NpsPlaceRankingWidget from './NpsPlaceRankingWidget'
  import NpsRepartitionWidget from './NpsRepartitionWidget'
  import NpsEvolution from './NpsEvolution'
  import NpsPositiveTopicsWidget from './NpsPositiveTopicsWidget'
  import NpsNegativeTopicsWidget from './NpsNegativeTopicsWidget'
  import NpsRankingTable from "./NpsRankingTable"
  import TopicsVerbatimTopicsWidget from "@statistics/shared/widgets/TopicsVerbatimTopicsWidget"
  import TransactionalUnitToEarnWidget from '../../shared/widgets/TransactionalUnitToEarnWidget'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import NisMixin from '@statistics/shared/nis_mixin'

  export default {
    name: "NpsZoom",
    mixins: [
      WidgetMixin,
      NisMixin,
    ],
    props: {
      campaign: { type: Object },
      widgetSubtitle: { type: String },
      nisVoterProportions: { type: Object },
      nisSummary: { type: Object },
    },
    components: {
      NpsScoreWidget,
      NpsPlaceRankingWidget,
      NpsRepartitionWidget,
      NpsEvolution,
      NpsRankingTable,
      NpsPositiveTopicsWidget,
      NpsNegativeTopicsWidget,
      TransactionalUnitToEarnWidget,
      TopicsVerbatimTopicsWidget,
    },
    computed: {
      ...mapGetters([
        'isMonoPlace',
        'isNetworkMonoPlace',,
        'isDemoUser',
        'hasAccessToNewPlatformRankingTab',
        'hasAccessToNewPlatformStatisticVerbatimAnalysis'
      ])
    }
  }
</script>
