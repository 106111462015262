<template>
  <v-row class="topics-page" no-gutters>
    <v-col>
      <v-row v-if="eligibleCampaigns.length > 1" no-gutters class="px-3 mb-3">
        <v-col cols="12">
          <StatisticsCampaignAutocomplete
            :campaignId="campaignId"
            :eligibleCampaigns="eligibleCampaigns"
            class="d-flex"
          />
        </v-col>
      </v-row>

      <template v-if="campaign">
        <v-row no-gutters>
          <v-col>
            <TopicsTableWidget
              v-if="displayTopicsTable"
              :key="nisMixin_key"
              :load="loadData()"
              :campaign="campaign"
              :currentTopicId="topic?.id"
              :topics="topicsMixin_topics[campaign.id]"
              :cols="12"
              class="pt-0"
            />
          </v-col>
        </v-row>

        <v-row v-if="shouldDisplayTopicAutocomplete" no-gutters class="px-3 mb-3">
          <v-col cols="12">
            <TopicAutocomplete
              :topicId="topic?.id"
              :campaignId="campaignId"
              class="d-flex"
            />
          </v-col>
        </v-row>

        <v-row
          v-if="topicsMixin_topics[campaign.id] === undefined || topic"
          no-gutters
        >
          <v-col cols="12">
            <TopicZoom
              :key="topic?.id"
              :load="loadData()"
              :campaign="campaign"
              :topic="topic"
              class="pt-0"
            />
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import TopicsTableWidget from './TopicsTableWidget'
  import StatisticsCampaignAutocomplete from '../shared/StatisticsCampaignAutocomplete'
  import TopicAutocomplete from './TopicAutocomplete'
  import TopicZoom from './TopicZoom'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import NisMixin from '@statistics/shared/nis_mixin'
  import TopicsMixin from '@statistics/shared/topics_mixin'

  export default {
    name: "TopicsPage",
    props: {
      campaignId: { type: Number, required: true },
      selectedTopicId: { type: Number | String }
    },
    mixins: [
      WidgetMixin,
      NisMixin,
      TopicsMixin,
    ],
    components: {
      StatisticsCampaignAutocomplete,
      TopicAutocomplete,
      TopicsTableWidget,
      TopicZoom
    },
    watch: {
      campaignId(value) {
        this.$router.push({
          params: { sectionId: (this.currentTopicsByCampaignId[value] || [])[0]?.id }
        })
      }
    },
    methods: {
      loadData() {
        this.topicsMixin_loadTopics(this.campaign)
      }
    },
    computed: {
      ...mapGetters([
        'currentDashboardScopedCampaigns',
        'currentTopicsByCampaignId',
        'currentDashboardCampaignWithId'
      ]),
      displayTopicsTable() {
        return this.campaign && this.campaign.topics.length > 1
      },
      campaign() {
        return this.currentDashboardCampaignWithId(this.campaignId)
      },
      shouldDisplayTopicAutocomplete() {
        return this.campaign.topics.length > 1
      },
      resolvedSelectedTopicId() {
        const topics = this.topicsMixin_topics[this.campaign.id]

        if (this.selectedTopicId == 'default') {
          if (topics?.length) {
            const sortedTopics = topics.sort((a, b) => {
              if ('nisOpportunity' in a) {
                return b.nisOpportunity - a.nisOpportunity
              }
              return b.score - a.score
            })
            return sortedTopics[0].id
          }
          return undefined
        }
        return Number(this.selectedTopicId)
      },
      topic() {
        const selectedTopicId = this.resolvedSelectedTopicId

        return this.topicsMixin_topics[this.campaign.id] !== undefined ?
               this.topicsMixin_topics[this.campaign.id].find(
                 topic => topic.id === selectedTopicId
               ) :
               undefined
      },
      eligibleCampaigns() {
        return this.currentDashboardScopedCampaigns.filter(
          campaign => campaign.hasAvgScore && campaign.topics.length >= 1
        )
      }
    }
  }
</script>
