<template>
  <div class="qcm-question-answer">
    <template v-if="question.multiAnswer === true">
      <div class="mt-1">
        <v-checkbox
          v-for="qcmQuestionChoice in question.qcmQuestionChoices"
          v-if="!qcmQuestionChoice.hidden"
          :key="qcmQuestionChoice.id"
          :input-value="isActiveChoice(qcmQuestionChoice.id)"
          :ripple="false"
          :disabled="!isActiveChoice(qcmQuestionChoice.id)"
          class="pt-0 mt-0 qcm-question-answer__checkbox"
          readonly
          hide-details
          dense
        >
          <template #label>
            <div @click.stop style="width: 100%; cursor: initial;">
              {{ qcmQuestionChoice.choice }}
            </div>
          </template>
        </v-checkbox>

        <v-checkbox
          v-if="otherChoiceAnswer"
          :input-value="true"
          :ripple="false"
          class="pt-0 mt-0 qcm-question-answer__checkbox"
          readonly
          hide-details
          dense
        >
          <template #label>
            <div @click.stop style="width: 100%; cursor: initial;">
              {{ otherChoiceAnswerContent }}
            </div>
          </template>
        </v-checkbox>
      </div>
    </template>

    <template v-else>
      <v-radio-group v-model="activeChoiceId" hide-details dense class="pt-0 mt-1">
        <v-radio
          v-for="qcmQuestionChoice in question.qcmQuestionChoices"
          v-if="!qcmQuestionChoice.hidden"
          :key="qcmQuestionChoice.id"
          :value="qcmQuestionChoice.id"
          :name="'qcm_question_answer_question_' + question.id"
          :disabled="qcmQuestionChoice.id !== activeChoiceId"
          class="py-0 mb-0 qcm-question-answer__radio"
          :ripple="false"
        >
          <template #label>
            <div @click.stop style="width: 100%; cursor: initial;">
              {{ qcmQuestionChoice.choice }}
            </div>
          </template>
        </v-radio>

        <v-radio
          v-if="otherChoiceAnswer"
          :value="1"
          :label="otherChoiceAnswerContent"
          :name="'qcm_question_answer_question_' + question.id"
          class="py-0 mb-0 qcm-question-answer__radio"
          :ripple="false"
        >
          <template #label>
            <div @click.stop style="width: 100%; cursor: initial;">
              {{ otherChoiceAnswerContent }}
            </div>
          </template>
        </v-radio>
      </v-radio-group>
    </template>

    <VoterPictures v-if="pictures" :pictures="pictures"/>

  </div>
</template>

<script>
  import VoterPictures from "./VoterPictures";

  export default {
    name: "QcmQuestionAnswer",
    components: {
      VoterPictures
    },
    props: {
      answers: { required: true, type: Array },
      question: { required: true },
      pictures: { required: false }
    },
    data() {
      return {
        activeChoiceId: this.answers[0]?.qcmQuestionChoice?.id
      }
    },
    computed: {
      otherChoiceAnswer() {
        return (this.answers.filter((a) => a.qcmQuestionChoice.id === 1)[0])
      },
      otherChoiceAnswerContent() {
        const otherChoiceText = this.otherChoiceAnswer?.questionCommentAnswers?.find(Boolean)?.text

        return this.$t('other') + (otherChoiceText ? `: ${otherChoiceText}` : '')
      }
    },
    methods: {
      isActiveChoice(choiceId) {
        return (this.answers.some((a) => a.qcmQuestionChoice.id === choiceId))
      },
    },
    watch: {
      answers() {
        this.activeChoiceId = this.answers[0]?.qcmQuestionChoice?.id
      }
    }
  }
</script>

<style lang='stylus' scoped>
</style>
