<template>
  <div class="automated-grades d-flex align-center slider">
    <v-range-slider
      :value="[gradeMin, gradeMax]"
      color="success"
      readonly
      track-color="error"
      :min="rangeMin"
      :max="rangeMax"
      :step="1"
      hide-details
      thumb-color="success"
    />
    <span class="fb-14 ml-2">
      {{ gradeMin }} <=< {{ gradeMax }}
    </span>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "AutomatedGrades",
    data() {
      return {
        rangeMin: 0,
        rangeMax: 5
      }
    },
    props: {
      gradeMin: {
        type: Number
      },
      gradeMax: {
        type: Number
      }
    },
  }
</script>

<style lang="stylus">
  .automated-grades
    width: 200px
</style>
