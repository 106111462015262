<template>
  <RankingTableWidget
    :title="title"
    :customRankingRelatedIndicator="customRankingRelatedIndicator"
    :staticColumns="staticColumns"
    :campaign="campaign"
  />
</template>

<script>
  import RankingTableWidget  from '@statistics/shared/widgets/RankingTableWidget'

  import { mapGetters } from 'vuex'
  
  export default {
    name: "NpsRankingTableWidget",
    props: [ 'campaign' ],
    components: {
      RankingTableWidget
    },
    computed: {
      ...mapGetters([
        'dashboardFilterDatesText',
        'groupRankingName',
        'currentLexicon',
        'isLcl'
      ]),
      title() {
        return `${this.$t('competitors_widget_ranking_title')} ${this.currentLexicon.translate('places', 'places') } - ${this.isLcl ? 'IRC' : 'NPS'}` || `${this.$t('widget_place_ranking_title')} - ${this.isLcl ? 'IRC' : 'NPS'}`
      },
      customRankingRelatedIndicator() {
        return 'nps_score'
      },
      staticColumns() {
        return {
          nps_score: {}
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
 
</style>
