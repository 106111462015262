<template>
  <div class="voter-string-info-filter-content">
    <template v-if="fieldType == 'checkboxes'">
      <v-checkbox
        v-for="filterOption in filterOptions"
        :key="filterOption"
        v-model="selectedFilterOptions"
        :value="filterOption"
        :ripple="false"
        @change="onFilterOptionUpdated"
        hide-details
        dense
      >
        <template v-slot:label>
          <span :style="sourceFilterStyle" class="f-12">
            {{ filterOption }}
          </span>
        </template>
      </v-checkbox>
    </template>

    <template v-else-if="fieldType == 'multiselect'">
      <v-autocomplete
        class="pt-0"
        v-model="selectedFilterOptions"
        :items="filterOptions"
        @change="onFilterOptionUpdated"
        placeholder="Votre sélection"
        outlined
        chips
        deletable-chips
        hide-details
        multiple
        dense
      />
    </template>

    <template v-else-if="fieldType == 'textfield'">
      <v-combobox
        class="pt-0 no-carret"
        v-model="selectedFilterOptions"
        hide-selected
        @change="onFilterOptionUpdated"
        prepend-inner-icon="mdi-magnify"
        :hint="$t('press_enter_to_add')"
        outlined
        dense
        multiple
        persistent-hint
        deletable-chips
        small-chips
        clearable
      />
    </template>
  </div>
</template>

<script>
  import _debounce from "lodash/debounce"
  import store from '@platform/store'

  export default {
    name: "VoterStringInfoFilterContent",
    props: [ 'filterKey', 'filterOptions' ],
    data() {
      return {
        selectedFilterOptions: []
      }
    },
    computed: {
      fieldType() {
        if (this.filterOptions?.length <= 10) {
          return 'checkboxes'
        } else if (this.filterOptions?.length < 100) {
          return 'multiselect'
        } else {
          return 'textfield'
        }
      },
      sourceFilterStyle() {
        return {
          position: 'relative',
          bottom: '1px',
          left: '5px'
        }
      },
    },
    methods: {
      onFilterOptionUpdated: _debounce(function (val) {
        this.$store.dispatch("setDashboardVoterStringInfoFilter", { filterKey: this.filterKey, filterValues: this.selectedFilterOptions })
      }, 100),
    },
    created() {
      this.selectedFilterOptions = store.getters.stringInfoFilters[this.filterKey] || []
    }
  }
</script>

<style lang="stylus">
  .voter-string-info-filter-content
    .no-carret
      max-width: 500px

      .v-input__icon.v-input__icon--append
        display: none !important
</style>
