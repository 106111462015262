<template>
  <v-row class="satisfaction-page" no-gutters>
    <v-col>
      <template>
        <v-row no-gutters>
          <v-col>
            <CampaignIndicatorsTableWidget
              v-if="
                eligibleCampaigns.length > 1 ||
                eligibleCampaigns[0].campaignIndicators.length > 0
              "
              :key="nisMixin_key"
              :load="loadData()"
              :campaignScoreIndicator="campaignIndicatorsMixin_campaignScoreIndicatorByCampaign"
              :campaignIndicators="campaignIndicatorsMixin_campaignIndicatorsByCampaign"
              :eligibleCampaigns="eligibleCampaigns"
              :selectedCampaignIndicator="selectedCampaignIndicator"
              :cols="12"
            />
          </v-col>
        </v-row>

        <v-row
          v-if="
            eligibleCampaigns.length > 1 ||
            eligibleCampaigns[0].campaignIndicators.length > 0
          "
          no-gutters
          class="px-3 mb-3"
        >
          <v-col cols="12">
            <CampaignIndicatorAutocomplete
              :key="`${campaignId}_${selectedCampaignIndicator?.id}`"
              class="d-flex"
              :eligibleCampaigns="eligibleCampaigns"
              :campaignId="campaign?.id"
              :campaignIndicatorId="selectedCampaignIndicator?.id"
              ref="campaignIndicatorAutocomplete"
            />
          </v-col>
        </v-row>

        <v-row
          v-if="
            selectedCampaignIndicator === undefined ||
            selectedCampaignIndicator.id
          "
          no-gutters
        >
          <v-col cols="12">
            <CampaignIndicatorZoom
              :key="selectedCampaignIndicator?.id"
              :load="loadData()"
              :campaign="campaign"
              :campaignIndicator="selectedCampaignIndicator"
              :widgetSubtitle="widgetSubtitle"
              :nisVoterProportions="selectedCampaignIndicator"
              :nisSummary="selectedCampaignIndicator?.nisSummary"
            />
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CampaignIndicatorAutocomplete from './CampaignIndicatorAutocomplete'
  import CampaignIndicatorsTableWidget from './CampaignIndicatorsTableWidget'
  import CampaignIndicatorZoom from './CampaignIndicatorZoom'
  import CampaignIndicatorsMixin from '../shared/campaign_indicators_mixin'
  import NisMixin from '../shared/nis_mixin'

  export default {
    name: "SatisfactionPage",
    props: {
      campaignId: { type: Number | String },
      selectedCampaignIndicatorId: { type: Number | String },
    },
    components: {
      CampaignIndicatorAutocomplete,
      CampaignIndicatorsTableWidget,
      CampaignIndicatorZoom,
    },
    mixins: [
      CampaignIndicatorsMixin,
      NisMixin,
    ],
    methods: {
      loadData() {
        this.eligibleCampaigns.forEach(campaign => {
          this.campaignIndicatorsMixin_loadCampaignScoreIndicator(campaign)
          this.campaignIndicatorsMixin_loadCampaignIndicators(campaign)
        })
      }
    },
    computed: {
      ...mapGetters([
        'currentDashboardScopedCampaigns',
        'currentCampaignIndicatorsByCampaignId',
        'currentDashboardCampaignWithId',
        'campaignIdsScope',
        'currentDashboardScopedCampaigns',
        'dashboardFilterDates'
      ]),
      campaign() {
        return !isNaN(parseInt(this.campaignId)) ?
               this.currentDashboardCampaignWithId(Number(this.campaignId)) :
               this.selectedCampaignIndicator?.campaign
      },
      eligibleCampaigns() {
        return this.currentDashboardScopedCampaigns.filter(
          campaign => campaign.hasAvgScore && campaign.sources.includes('wizville')
        )
      },
      selectedCampaignIndicator() {
        const campaignScoreIndicators =
          this.campaignIndicatorsMixin_campaignScoreIndicatorByCampaign
        const campaignIndicators =
          this.campaignIndicatorsMixin_campaignIndicatorsByCampaign
        // We're asked to select the default indicator (currently, this is the first
        // one of the list displayed in CampaignIndicatorsTableWidget).
        if (this.selectedCampaignIndicatorId == 'default') {
          const indicatorList = []
          for (const indicator of Object.values(campaignScoreIndicators)) {
            indicatorList.push(indicator)
          }
          for (const indicators of Object.values(campaignIndicators)) {
            for (const indicator of Object.values(indicators)) {
              indicatorList.push(indicator)
            }
          }
          const sortedIndicatorList = indicatorList.sort((a, b) => {
            if ('nisOpportunity' in a) {
              return b.nisOpportunity - a.nisOpportunity
            }
            return b.score - a.score
          })
          return sortedIndicatorList[0]
        }
        // Formatting.
        const campaignId = Number(this.campaignId)
        const selectedCampaignIndicatorId = Number(this.selectedCampaignIndicatorId)
        // The selected indicator is a campaign indicator.
        if (campaignIndicators[campaignId] !== undefined &&
            campaignIndicators[campaignId][selectedCampaignIndicatorId] !== undefined
        ) {
          return campaignIndicators[campaignId][selectedCampaignIndicatorId]
        }
        // The selected indicator is the `avg_score` of the campaign.
        if (campaignScoreIndicators[campaignId] !== undefined) {
          return campaignScoreIndicators[campaignId]
        }
        // Nothing is selected (this probably should not happen).
        return undefined
      },
      widgetSubtitle() {
        return this.eligibleCampaigns.length > 1 && this.campaign ?
               `${this.dashboardFilterDates.text} - ${this.campaign.name}` :
               this.dashboardFilterDates.text
      }
    }
  }
</script>
