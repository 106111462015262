<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row no-gutters
        class="pb-3 pr-3"
        v-if="eligibleCampaigns.length > 1"
        v-show="!$vuetify.breakpoint.smAndDown || !hasActiveQuestion"
      >
        <v-col cols="12" class="pl-3">
          <StatisticsCampaignAutocomplete
            class="d-flex"
            :campaignId="campaignId"
            :key="campaignId"
            :eligibleCampaigns="eligibleCampaigns"
          />
        </v-col>
      </v-row>

      <v-row class="questions-page px-3" no-gutters>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" v-show="!$vuetify.breakpoint.smAndDown || !hasActiveQuestion">
          <QuestionsList v-model="question" :questionId="questionId" :campaign="campaign" v-if="campaign" />
        </v-col>

        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 8" :class="{ 'pl-3': !$vuetify.breakpoint.smAndDown }">
          <QuestionZoom :question="question" :showTitle=false v-if="hasActiveQuestion" :key="question.id" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import QuestionsList from "./QuestionsList"
  import QuestionZoom from "./QuestionZoom"
  import StatisticsCampaignAutocomplete from '../shared/StatisticsCampaignAutocomplete'
  
  export default {
    name: "QuestionsPage",
    props: [ 'questionId', 'campaignId' ],
    data() {
      return {
        question: null
      }
    },
    components: {
      QuestionsList,
      QuestionZoom,
      StatisticsCampaignAutocomplete
    },
    computed: {
      ...mapGetters([
        'currentDashboardCampaignWithId',
        'currentDashboardScopedCampaigns'
      ]),
      campaign() {
        return this.currentDashboardCampaignWithId(this.campaignId)
      },
      eligibleCampaigns() {
        return this.currentDashboardScopedCampaigns
          .filter((campaign) => campaign.hasQcmQuestion)
      },
      hasActiveQuestion() {
        return this.question && this.questionId
      }
    }
  }
</script>

<style lang="stylus" scoped>
 
</style>
