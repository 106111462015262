
<template>
  <WStatsWidget
    :cols="12"
    :subtitle="displayedSubtitle"
    :title="title"
    class="sentiment-repartition-widget"
    contentPadding="0.75em"
    contentWidth="100%"
    height="200px"
  >
    <template #content>
      <WRepartitionHorizontalFrieze
        :nb-promoters="zoomedData.reviewDistribution.nbPromoters"
        :nb-detractors="zoomedData.reviewDistribution.nbDetractors"
        :nb-neutrals="zoomedData.reviewDistribution.nbNeutrals"
        :promoters-legend="`${$t('part_of_feeling')} ${$t('positives')}`"
        :neutrals-legend="`${$t('part_of_feeling')} ${$t('neutrals').toLowerCase()}`"
        :detractors-legend="`${$t('part_of_feeling')} ${$t('negatives')}`"
        unit="%"
        height="62px"
        max-width="100%"
        :emoticons="true"
      />
    </template>
  </WStatsWidget>
</template>

<script>
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'

  export default {
    name: "SentimentRepartitionWidget",
    components: {
    },
    mixins: [
      WidgetMixin
    ],
    props: ['zoomedData'],
    computed: {
      title() {
        return this.$t('feeling_repartition')
      }
    }
  }
</script>
