<template>
  <div class="achievements-navigation">
    <w-nav-tabs
      :router-links-properties="routerLinksProperties"
      scrollable
      :class="{ 'mb-3': $vuetify.breakpoint.xsOnly }"
    />
  </div>
</template>

<script>
  export default {
    name: "AchievementsNavigation",
    props: {
      page: {
        type: String,
        required: true
      }
    },
    computed: {
      routerLinksProperties() {
        return [
          {
            title: this.$t('achievements_leaderboard_tab_title'),
            active: this.page === 'leaderboard',
            route: {
              name: 'Achievements',
              params: { page: 'leaderboard' }
            }
          },
          {
            title: this.$t('achievements_my_achievements_tab_title'),
            active: this.page === 'my_achievements',
            route: {
              name: 'Achievements',
              params: { page: 'my_achievements' }
            }
          },
        ]
      }
    }
  }
</script>
